import { useMemo } from 'react';
import { Permission } from '../types';
import { PermissionModel } from '@scout/types';
import { useCurrentUser } from './useCurrentUser';

// This gives us a union of all available permissions
type ExtractPermissionsResult = { [key in Permission]: boolean };

// Go through array of permissions and make an object so they're flattened.
// So if one role has `viewDemandForecast` and one doesn't then the result
// would include `{viewDemandForecast: true}` because the user has at least
// one role with that permission.
const extractPermissions = (rolePermissions: PermissionModel[]): ExtractPermissionsResult =>
  rolePermissions.reduce((all, { id, __typename, roles, ...rest }) => {
    return {
      ...all,
      ...Object.entries(rest).reduce<{ [key: string]: boolean }>((all, [key, value]) => {
        if (value || all[key] == null) {
          return {
            ...all,
            [key]: value,
          };
        }

        return all;
      }, {}),
    };
  }, {} as ExtractPermissionsResult);

export interface UsePermission {
  hasAnyOf(permission: Permission[]): boolean;
}

const usePermission = (): UsePermission => {
  const currentUser = useCurrentUser();

  const permissions = useMemo(() => {
    // Go through each role and put the permissions from all into one array
    const rolePermissions = currentUser.roles.reduce<PermissionModel[]>(
      (all, role) => [...all, ...role.permissions],
      [],
    );

    return extractPermissions(rolePermissions);
  }, [currentUser]);

  return {
    hasAnyOf: requestedPermission => requestedPermission.some(permission => permissions[permission]),
  };
};

export { usePermission };
