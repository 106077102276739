import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Loader } from '../../../components/Loader';
import { DefaultLayout } from '../../../layouts';
import { UserModel } from '@scout/types';

const USERS_QUERY = gql`
  query users {
    users {
      emailAddress
    }
  }
`;

const UserPage: React.SFC<{}> = () => {
  return (
    <DefaultLayout>
      <Query<{ users: UserModel[] }> query={USERS_QUERY}>
        {({ data, loading, error }) => {
          if (loading || error) {
            return <Loader error={error ? `Error ${error.message}` : undefined} />;
          }

          if (!data) {
            return <div>No users found</div>;
          }
          return (
            <div>
              {data.users.map((user: UserModel, index: number) => (
                <div key={index}>{user.emailAddress}</div>
              ))}
            </div>
          );
        }}
      </Query>
    </DefaultLayout>
  );
};

export { UserPage };
