import moment from 'moment';
import { ContractDealType, GetContractsQuery } from '@scout/types';
import { getShipmentTypeName } from '../../../utils/getters';

export interface TableDataItem {
  customer: {
    code: string;
    salesOrganisation: string;
    name: string;
  };
  dealId: string;
  end: string;
  loadedDate: string;
  basePlantName: string;
  shipmentType: string;
  start: string;
  stascoId: string;
  totalVolume: string;
  type: ContractDealType;
  year: string;
}

export interface TableData {
  rows: TableDataItem[];
}

export const createTableData = (contracts: GetContractsQuery['contracts']['results']) => {
  const initialData: TableData = { rows: [] };

  const tableData = contracts.reduce((previous, current) => {
    return {
      ...previous,
      rows: [
        ...previous.rows,
        {
          customer: {
            code: current.customer.code,
            name: current.customer.name,
            salesOrganisation: current.customer.salesOrganisation,
          },
          dealId: current.dealId,
          end: moment(current.end).format('DD/MM/YYYY'),
          loadedDate: moment(current.updatedAt).format('DD/MM/YYYY'),
          basePlantName: current.plant.shortName || '-',
          shipmentType: getShipmentTypeName(current.shipmentType),
          start: moment(current.start).format('DD/MM/YYYY'),
          stascoId: current.stascoId || '-',
          totalVolume: `${(current.totalVolume / 1000).toLocaleString('en-GB', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}Kt`,
          type: current.type,
          year: current.dealYear,
        },
      ],
    };
  }, initialData);

  return tableData;
};
