import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../../components/Loader';
import { useHistory } from 'react-router-dom';

const LoaderContainer = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  flex: 1;
  background-color: ${p => (p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.b)};
`;

interface Props {
  error?: string;
  currentUserTheme?: boolean;
}

export const EastModalLoader: React.FC<Props> = ({ error, currentUserTheme }) => {
  return (
    <LoaderContainer currentUserTheme={currentUserTheme}>
      <Loader type="section" error={error} />
    </LoaderContainer>
  );
};
