import { vesselCategories } from '../../pages/InMonth/vesselCategories';
import { PopupState } from './Mapbox';
import { TransportQueryResult, TransportsQueryResult, VesselModel } from '@scout/types';

export const getPopupVessel = (popup: PopupState, vessel: TransportQueryResult) => {
  if (popup.vessel && popup.vessel.id === vessel.id) {
    return null;
  }
  return vessel;
};

export const handleEmpty = (value: string | undefined | null) => value || 'No info';

const VesselGroupSortOrder: {
  [key: string]: number;
} = {
  Tarbit: 1,
  Jaegers: 2,
  'Time Charter': 3,
  'Non-Contracted': 4,
};

export interface VesselGroup {
  category: string;
  vessels: TransportsQueryResult;
}

export const groupAndSortVessels = (vessels: TransportsQueryResult): VesselGroup[] => {
  const groupedVessels = vessels.reduce((groups: VesselGroup[], vessel: TransportQueryResult) => {
    const foundVesselData = vesselCategories.find(vesselData => {
      if (!vessel.shipName) {
        return false;
      }

      return vesselData.shipName.toUpperCase() === vessel.shipName.toUpperCase();
    });

    const foundVesselCategory = foundVesselData ? foundVesselData.Category : 'Other';
    const existingCategory = groups.find(group => group.category === foundVesselCategory);

    if (existingCategory) {
      return groups.map(group => {
        if (group.category === existingCategory.category) {
          return {
            category: group.category,
            vessels: [...group.vessels, vessel],
          };
        }

        return group;
      });
    }

    return [
      ...groups,
      {
        category: foundVesselCategory,
        vessels: [vessel],
      },
    ];
  }, []);

  return groupedVessels
    .sort((g1, g2) => VesselGroupSortOrder[g1.category] - VesselGroupSortOrder[g2.category])
    .map(({ category, vessels }) => ({
      category,
      vessels: vessels.sort((v1, v2) => {
        if (!v1.shipName || !v2.shipName) {
          return -1;
        }

        return v2.shipName < v1.shipName ? 1 : -1;
      }),
    }));
};

export const getVesselEta = (vessel: VesselModel | null) => {
  if (vessel?.etaCalc) {
    return vessel.etaCalc;
  }

  return vessel?.eta;
};

export const getReportedVesselEta = (vessel: VesselModel | null) => {
  if (vessel?.eta) {
    return vessel.eta;
  }
  return '';
};

export const getCalculatedVesselEta = (vessel: VesselModel | null) => {
  if (vessel?.etaCalc) {
    return vessel.etaCalc;
  }
  return '';
};
