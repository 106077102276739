import { createGlobalStyle } from 'styled-components';
import { config } from './config';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir Next';
    font-weight: normal;
    src: url('${config.PUBLIC_URL}/fonts/AvenirNext-Regular.woff2') format('woff2');
    src: url('${config.PUBLIC_URL}/fonts/AvenirNext-Regular.woff') format('woff');
    src: url('${config.PUBLIC_URL}/fonts/AvenirNext-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Avenir Next';
    font-weight: 600;
    src: url('${config.PUBLIC_URL}/fonts/AvenirNext-DemiBold.woff2') format('woff2');
    src: url('${config.PUBLIC_URL}/fonts/AvenirNext-DemiBold.woff') format('woff');
    src: url('${config.PUBLIC_URL}/fonts/AvenirNext-DemiBold.ttf') format('truetype');
  }

  body, html, div[id=root] {
    height: 100%;
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  body {
    background-color: ${theme.base.dark.b};
    color: ${theme.secondary.light.c};
  }

  * {
    font-family: 'Avenir Next';
    box-sizing: border-box;

    :focus {
      outline: none;
    }
  }
  .ReactModal__Overlay {
    z-index: 9999;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
      opacity: 1;
  }

  .ReactModal__Overlay--before-close {
      opacity: 0;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }

  strong {
    font-weight: 600;
  }

  ul {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  th {
    font-weight: normal;
  }
`;

export { GlobalStyle };
