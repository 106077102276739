import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useToast } from '../../../../components/Toast/Toast';
import { URLS } from '../../../../constants';
import { getPreviousUrl, getUrlWithPreviousSearch } from '../../../../utils';
import { getUrlFromMovementInput, parseMovementInput } from '../utils';
import { MovementForm } from './MovementForm';
import { SectionLoader } from './SectionLoader';
import {
  CREATE_MOVEMENT,
  GET_NEW_MOVEMENT_FORM_DATA,
  GetNewFormDataQuery,
  CreateMovementMutation,
  CreateMovementMutationVariables,
} from '@scout/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NewMovementView: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { data, loading, error } = useQuery<GetNewFormDataQuery>(GET_NEW_MOVEMENT_FORM_DATA, {
    fetchPolicy: 'no-cache',
  });
  const { push } = useToast();
  const [createMovement, createMovementResult] = useMutation<CreateMovementMutation, CreateMovementMutationVariables>(
    CREATE_MOVEMENT,
  );

  if (loading || error || !data || !data.locations || !data.grades || !data.transports) {
    return <SectionLoader title="New Movement" error={error ? 'Something went wrong' : undefined} />;
  }

  return (
    <Container>
      <MovementForm
        loading={createMovementResult.loading}
        type="new"
        locations={data.locations}
        grades={data.grades}
        transports={data.transports}
        onCancel={() => {
          history.push(getPreviousUrl(location, URLS.IN_MONTH));
        }}
        onSubmit={async (input, formActions, action) => {
          try {
            const parsedInput = parseMovementInput(input, data.locations);

            await createMovement({ variables: { input: parsedInput } });

            if (action === 'addAnother') {
              formActions.resetForm();
            } else {
              history.push(getUrlWithPreviousSearch(getUrlFromMovementInput(parsedInput), location));
            }

            push({ type: 'SUCCESS', text: 'Movement created successfully' });
          } catch (error) {
            push({ type: 'ERROR', text: 'Something went wrong. Movement could not be saved. Please try again' });
          }
        }}
        onAddAnother={() => {
          history.push(`${URLS.IN_MONTH}/new`);
        }}
      />
    </Container>
  );
};
