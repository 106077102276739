import { ShipmentType, Customer, Region, Plant } from '@scout/types';

export enum DemandType {
  Spot = 'Spot',
  Fixed = 'Fixed',
  Formula = 'Formula',
  Rolling = 'Rolling',
}

export interface MonthlyForecast {
  date: Date;
  volume: number;
  price: string;
}

export interface DashboardRowType {
  id: string;
  contractId: string;
  demandType: DemandType;
  shipmentType: ShipmentType;
  customer: Pick<Customer, 'id' | 'name' | 'code' | 'salesOrganisation' | 'amFirstName' | 'amLastName'>;
  shipTo: Pick<Customer, 'id' | 'name'> | null;
  region: Pick<Region, 'id' | 'name'> | null;
  primaryPenPlant?: Pick<Plant, 'id' | 'shortName'>;
  primaryPremiumPlant?: Pick<Plant, 'id' | 'shortName'>;
  totalVolume: number;
  monthlyForecasts: MonthlyForecast[];
  // this will be always true for contract demands
  complete: boolean;
  editLink: string;
}

export interface DashboardTableDataType {
  filteredDemands: DashboardRowType[];
  totalVolume: number;
  monthlyTotalVolumes: Array<{ month: string; volume: number }>;
}

export interface MonthlyVolumeType {
  month: string;
  number: string;
  volume: number;
}
