import { createContext } from 'react';
import { CurrentUserQuery } from '@scout/types';

const CurrentUserContext = createContext<CurrentUserQuery['currentUser']>(
  // This is a hack to get around the types for `useContext` requiring a default value
  (undefined as unknown) as CurrentUserQuery['currentUser'],
);
CurrentUserContext.displayName = 'CurrentUserContext';

export { CurrentUserContext };
