import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import moment, { Moment } from 'moment';
import { Field, FieldProps, getIn, FastField, Formik, Form } from 'formik';
import { TextInput } from '../../../../../components/TextInput';

import { Calendar } from '../../../../../components/Calendar';
import { Checkbox } from '../../../../../components/Checkbox';
import { Col, Row, Grid } from '../../../../../components/Grid';
import { SingleDropdown } from '../../../../../components/SelectDropdown/Single';

import { getEastGradeItems } from '../../utils';

import {
  GradeModelFragmentFragment,
  CREATE_PRODUCTION_OR_SALE,
  CreateProductionOrSaleMutation,
  CreateProductionOrSaleMutationVariables,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  UpdateScheduleManagerByIdMutation,
  UpdateScheduleManagerByIdMutationVariables,
  UPDATE_SCHEDULEMANAGER_BY_ID,
} from '@scout/types';
import { useToast } from '../../../../../components/Toast/Toast';

import { Required, RequiredText, SaleGradeContainer } from './EastMovementFormsHelper';
import {
  checkEventPresent,
  CopyContainer,
  InputContainer,
  InputContainerDays,
  Label,
  MovementValidation,
  NewButton,
  PlanningContainer,
  RefineryProductionSalesTabValues,
  Root,
  Spaceinbetween,
  StyledTextArea,
  EventValidationErrorMessage,
  validationSchema,
  today,
  InsideCalendar,
  AdjustmentValidationMessage,
  AdjustmentGrade,
} from './EastProductionSalesMovementHelper';
import { getModifiedValue } from './EastMovementsHelper';
import { isInclusivelyBeforeDay } from 'react-dates';

interface DepotSalesContainerFormProps {
  grades: GradeModelFragmentFragment[];
  currentLocationName: string;
  cancelModal: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  selectedMovement: SchedulingManagerType | null;
  // tslint:disable-next-line
  salesSelectedValues: any;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

const PortContainer = styled.div`
  margin-top: 5px;
  width: 100%;
`;

const DateContainer = styled.div`
  margin-buttom: 10px;
`;

const StylePortTextBox = styled.div`
  width: 250px;
`;

const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  float: right;
  margin-right: 30px;
`;
const SalePlanningNotesContainer = styled.div`
  margin-top: 2px;
`;

const SaleRequiredTextContainer = styled.div`
  margin-top: 50px;
  margin-left: 13px;
`;
const VolumeFieldStyle = styled.div`
  width: 75px;
`;
today.setHours(0, 0, 0, 0);
export const EastDepotSalesContainer: React.FunctionComponent<DepotSalesContainerFormProps> = ({
  grades,
  currentLocationName,
  cancelModal,
  SMdata,
  selectedMovement,
  salesSelectedValues,
  currentUserTheme,
  onClickSimulationEvent,
  dataFetch,
}) => {
  const [plannedStartDate, setStartDate] = useState<Moment | null>();
  const [plannedEndDate, setEndDate] = useState<Moment | null>();
  const [isChecked, setisChecked] = useState(salesSelectedValues ? salesSelectedValues.skipWeekends : false);
  const { push } = useToast();
  const [buttonActive, setButtonActive] = useState(true);
  const [isEventPresent, setisEventPresent] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);
  const [adjustmentValidation, setAdjustmentValidation] = useState(false);
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [createProductionOrSale, setCreateProductionOrSale] = useMutation<
    CreateProductionOrSaleMutation,
    CreateProductionOrSaleMutationVariables
  >(CREATE_PRODUCTION_OR_SALE, {
    context: {
      headers,
    },
  });
  const [updateProductionOrSale, setUpdateProductionOrSale] = useMutation<
    UpdateScheduleManagerByIdMutation,
    UpdateScheduleManagerByIdMutationVariables
  >(UPDATE_SCHEDULEMANAGER_BY_ID, {
    context: {
      headers,
    },
  });
  const defaultValues = {
    productType: 'Sale',
    grade: 'Bitumen',
    plannedStartDate: '',
    plannedVolume: 0,
    port: currentLocationName,
    plannedEndDate: '',
    planningNotes: '',
    skipWeekends: false,
  };

  // Detecting click outside component
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleClickOutside = (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (!myRef.current.contains(target)) {
      setClickedOutside(true);
    }
  };
  const handleClickInside = () => setClickedOutside(false);
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  });

  const handleOnSubmit = async (values: RefineryProductionSalesTabValues) => {
    if (selectedMovement !== null) {
      const input = {
        id: selectedMovement.id,
        ...values,
        productType: selectedMovement.productType,
      };
      try {
        await updateProductionOrSale({
          variables: {
            input,
          },
        });
        push({ type: 'SUCCESS', text: 'Movement Updated successfully' });
        setDisabledSaveButton(false);
        cancelModal();
        onClickSimulationEvent(!dataFetch);
      } catch (error) {
        push({ type: 'ERROR', text: 'Movement Update Failed' });
      }
    } else {
      const momentOfPlannedStartDate = moment(values.plannedStartDate);
      const momentOfPlannedEndDate = moment(values.plannedEndDate);
      const saleCreationOnWeekend =
        values.skipWeekends === true &&
        (momentOfPlannedStartDate.isoWeekday() === 6 || momentOfPlannedStartDate.isoWeekday() === 7) &&
        (momentOfPlannedEndDate.isoWeekday() === 6 || momentOfPlannedEndDate.isoWeekday() === 7) &&
        (momentOfPlannedEndDate.diff(momentOfPlannedStartDate, 'days') === 1 ||
          momentOfPlannedEndDate.diff(momentOfPlannedStartDate, 'days') === 0)
          ? true
          : false;
      if (saleCreationOnWeekend === true) {
        push({ type: 'ERROR', text: 'Skip Weekends is checked.You cannot create movements on weekends...' });
        cancelModal();
      } else {
        try {
          await createProductionOrSale({
            variables: {
              input: values,
            },
          });
          push({ type: 'SUCCESS', text: 'Depot Sale created successfully' });
          setDisabledSaveButton(false);
          cancelModal();
          onClickSimulationEvent(!dataFetch);
        } catch (error) {
          push({ type: 'ERROR', text: 'Depot Sale Failed' });
        }
      }
    }
  };

  return (
    <Formik
      initialValues={selectedMovement ? salesSelectedValues : defaultValues}
      validationSchema={validationSchema}
      validate={values => {
        const allRequiredFields = values.plannedStartDate && values.plannedEndDate && values.grade;
        const adjustmentValidation =
          salesSelectedValues.grade === AdjustmentGrade && values.grade !== AdjustmentGrade ? true : false;
        setAdjustmentValidation(adjustmentValidation);
        if (allRequiredFields && !adjustmentValidation) {
          setButtonActive(false);
        } else {
          setButtonActive(true);
        }
      }}
      onSubmit={async (values, formActions) => {
        const changedValue = getModifiedValue(values, salesSelectedValues);
        const eventvalidation = await checkEventPresent(values, SMdata, setisEventPresent);
        if (!eventvalidation) {
          setDisabledSaveButton(true);
          await handleOnSubmit(changedValue);
          formActions.setSubmitting(false);
        }
      }}
      render={formProps => {
        {
          const isSubmitted = formProps.submitCount > 0 ? true : false;
          return (
            <>
              <Form>
                {isEventPresent && <MovementValidation>{EventValidationErrorMessage}</MovementValidation>}
                {adjustmentValidation && <MovementValidation>{AdjustmentValidationMessage}</MovementValidation>}
                <Root currentUserTheme={currentUserTheme}>
                  <InsideCalendar ref={myRef} onClick={handleClickInside}>
                    <Grid>
                      <Row>
                        <Col size={6}>
                          <SaleGradeContainer>
                            <Row>
                              <InputContainer>
                                <Label currentUserTheme={currentUserTheme}>
                                  Grade<Required>*</Required>
                                </Label>
                                <Field name="grade">
                                  {({
                                    field,
                                    form: { errors, setFieldValue },
                                  }: FieldProps<RefineryProductionSalesTabValues>) => (
                                    <SingleDropdown
                                      darkTheme={currentUserTheme ? false : true}
                                      width="200px"
                                      error={getIn(errors, field.name)}
                                      items={getEastGradeItems(grades)}
                                      name={field.name}
                                      onItemClick={item => {
                                        setFieldValue('grade', item.label);
                                      }}
                                      selected={field.value}
                                      showClearIndicator={false}
                                      isClearable={false}
                                      placeholder="Bitumen"
                                    />
                                  )}
                                </Field>
                              </InputContainer>
                              <InputContainer>
                                <Label currentUserTheme={currentUserTheme}>
                                  Volume (Kt)<Required>*</Required>
                                </Label>
                                <Field name="plannedVolume">
                                  {({
                                    field,
                                    form: { errors, touched },
                                  }: FieldProps<RefineryProductionSalesTabValues>) => (
                                    <VolumeFieldStyle>
                                      <TextInput
                                        type="number"
                                        align="right"
                                        error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                        name={field.name}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                          const newValue = e.target.value;
                                          if (newValue === '-0') {
                                            e.target.value = '0';
                                          } else if (newValue == null) {
                                            return;
                                          }

                                          field.onChange(e);
                                        }}
                                        placeholder="0"
                                        showErrorIcon={false}
                                        showErrorMessage={true}
                                        themeStyle={currentUserTheme ? 'light' : 'dark'}
                                        value={field.value != null ? field.value : ''}
                                      />
                                    </VolumeFieldStyle>
                                  )}
                                </Field>
                              </InputContainer>
                            </Row>
                          </SaleGradeContainer>
                          <Row>
                            <SalePlanningNotesContainer>
                              <PlanningContainer>
                                <InputContainer>
                                  <Label currentUserTheme={currentUserTheme}>Planning Note</Label>
                                  <FastField
                                    name="planningNotes"
                                    render={({ field }: FieldProps<string>) => (
                                      <StyledTextArea
                                        {...field}
                                        maxLength={500}
                                        tabIndex={1000}
                                        data-testid="planning-notes"
                                        themeStyle={currentUserTheme ? 'light' : 'dark'}
                                        placeholder="Please leave your thoughts…"
                                        rows={1}
                                      />
                                    )}
                                  />
                                </InputContainer>
                              </PlanningContainer>
                            </SalePlanningNotesContainer>
                          </Row>
                          <Row>
                            <SaleRequiredTextContainer>
                              <RequiredText>* Required Fields</RequiredText>
                            </SaleRequiredTextContainer>
                          </Row>
                        </Col>
                        <Spaceinbetween />

                        <Col size={6}>
                          <PortContainer>
                            <Label currentUserTheme={currentUserTheme}>Port</Label>

                            <Field name="port">
                              {({ field, form: { errors, touched } }: FieldProps<RefineryProductionSalesTabValues>) => (
                                <StylePortTextBox>
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      const newValue = e.target.value;

                                      if (newValue == null) {
                                        return;
                                      }

                                      field.onChange(e);
                                    }}
                                    placeholder=""
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={currentUserTheme ? 'light' : 'dark'}
                                    disabled={true}
                                    value={currentLocationName}
                                  />
                                </StylePortTextBox>
                              )}
                            </Field>
                          </PortContainer>

                          <DateContainer>
                            <Row>
                              <InputContainer>
                                <Label currentUserTheme={currentUserTheme}>
                                  Planned Start Date<Required>*</Required>
                                </Label>
                                <Field name="plannedStartDate">
                                  {({
                                    field,
                                    form: { errors, setFieldTouched, setFieldValue },
                                  }: FieldProps<RefineryProductionSalesTabValues>) => (
                                    <Calendar
                                      closeCalendar={clickedOutside}
                                      disabled={selectedMovement ? true : false}
                                      name={`plannedStartDate`}
                                      error={isSubmitted ? getIn(errors, 'plannedStartDate') : undefined}
                                      placeholder="Select Date"
                                      width="250px"
                                      align="left"
                                      value={
                                        field.value
                                          ? moment(field.value)
                                          : plannedStartDate
                                          ? moment(plannedStartDate, 'YYYY/MM/DD')
                                          : null
                                      }
                                      onChange={date => {
                                        setFieldValue(`plannedStartDate`, date.format());
                                        setFieldTouched(`plannedStartDate`, true);
                                        setStartDate(date);
                                      }}
                                      themeStyle={currentUserTheme ? 'light' : 'dark'}
                                      showErrorMessage={isSubmitted ? true : false}
                                      isOutsideRange={(day: Moment) => {
                                        if (plannedEndDate !== undefined) {
                                          return (
                                            !isInclusivelyBeforeDay(
                                              day,
                                              moment(plannedEndDate ? plannedEndDate : moment(), 'YYYY/MM/DD'),
                                            ) ||
                                            day.isBefore(
                                              moment()
                                                .subtract(8, 'months')
                                                .format('YYYY/MM/DD'),
                                            )
                                          );
                                        } else {
                                          return (
                                            day.isBefore(
                                              moment()
                                                .subtract(8, 'months')
                                                .format('YYYY/MM/DD'),
                                            ) ||
                                            day.isAfter(
                                              moment()
                                                .add(10, 'months')
                                                .format('YYYY/MM/DD'),
                                            )
                                          );
                                        }
                                      }}
                                      region="east"
                                      currentUserTheme={currentUserTheme}
                                    />
                                  )}
                                </Field>
                              </InputContainer>
                            </Row>
                          </DateContainer>
                          <CopyContainer>
                            <Row>
                              <InputContainerDays>
                                <Label currentUserTheme={currentUserTheme}>
                                  Planned End Date<Required>*</Required>
                                </Label>
                                <Field name="plannedEndDate">
                                  {({
                                    field,
                                    form: { errors, setFieldTouched, setFieldValue },
                                  }: FieldProps<RefineryProductionSalesTabValues>) => (
                                    <Calendar
                                      closeCalendar={clickedOutside}
                                      disabled={selectedMovement ? true : false}
                                      name={`plannedEndDate`}
                                      error={isSubmitted ? getIn(errors, 'plannedEndDate') : undefined}
                                      placeholder="Select Date"
                                      width="250px"
                                      align="left"
                                      value={
                                        field.value
                                          ? moment(field.value)
                                          : plannedEndDate
                                          ? moment(plannedEndDate, 'YYYY/MM/DD')
                                          : null
                                      }
                                      onChange={date => {
                                        setFieldValue(`plannedEndDate`, date.format());
                                        setFieldTouched(`plannedEndDate`, true);
                                        setEndDate(date);
                                      }}
                                      themeStyle={currentUserTheme ? 'light' : 'dark'}
                                      showErrorMessage={isSubmitted ? true : false}
                                      isOutsideRange={(day: Moment) => {
                                        if (plannedStartDate !== undefined) {
                                          return (
                                            day.isBefore(
                                              moment(
                                                moment(plannedStartDate ? plannedStartDate : moment(), 'YYYY/MM/DD'),
                                              ).startOf('day'),
                                            ) ||
                                            day.isAfter(
                                              moment()
                                                .add(10, 'months')
                                                .format('YYYY/MM/DD'),
                                            )
                                          );
                                        } else {
                                          return (
                                            day.isBefore(
                                              moment()
                                                .subtract(8, 'months')
                                                .format('YYYY/MM/DD'),
                                            ) ||
                                            day.isAfter(
                                              moment()
                                                .add(10, 'months')
                                                .format('YYYY/MM/DD'),
                                            )
                                          );
                                        }
                                      }}
                                      region="east"
                                      currentUserTheme={currentUserTheme}
                                    />
                                  )}
                                </Field>
                              </InputContainerDays>
                            </Row>
                          </CopyContainer>
                          <Row>
                            <Field name="skipWeekends">
                              {({ form: { setFieldValue } }: FieldProps<RefineryProductionSalesTabValues>) => (
                                <Checkbox
                                  disabled={selectedMovement ? true : false}
                                  isEast={true}
                                  name={`skipWeekends`}
                                  label="Skip Weekends"
                                  checked={isChecked}
                                  onChange={checkedValue => {
                                    setisChecked(Boolean(checkedValue));
                                    setFieldValue(`skipWeekends`, Boolean(checkedValue));
                                  }}
                                  onBlur={checkedValue => {
                                    setFieldValue(`skipWeekends`, Boolean(checkedValue));
                                  }}
                                  currentUserTheme={currentUserTheme}
                                />
                              )}
                            </Field>
                          </Row>

                          <BtnStyledRow>
                            <Row>
                              <NewButton
                                variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                                onClick={async () => {
                                  cancelModal();
                                }}
                              >
                                Cancel
                              </NewButton>

                              <NewButton
                                variant="primary"
                                type="submit"
                                disabled={disabledSaveButton ? true : buttonActive}
                                currentUserTheme={currentUserTheme}
                              >
                                Save
                              </NewButton>
                            </Row>
                          </BtnStyledRow>
                        </Col>
                      </Row>
                    </Grid>
                  </InsideCalendar>
                </Root>
              </Form>
            </>
          );
        }
      }}
    />
  );
};
