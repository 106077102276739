import {
  CurrentUserQuery,
  GET_TRANSPORTS,
  GetTransportsQuery,
  LocationsQuery,
  GET_LOCATIONS,
  EastProductInformation,
  GET_ALL_TANKS_BY_DATES,
  GET_ALL_PLANTSTOCK_INFORMATION,
} from '@scout/types';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { config } from '../../config';

import { useQuery } from 'react-apollo';
import styled, { createGlobalStyle } from 'styled-components';
import { Loader } from '../../components/Loader';
import { usePermission } from '../../hooks/usePermission';
import { InMonthLayout } from '../../layouts';
import { Map } from './Map';
import { MapProvider } from './MapContext';
import { markers } from './markers';
import { useHistory } from 'react-router-dom';
import { URLS } from '../../constants';
import Marquee from 'react-fast-marquee';
import { Col, Grid, Row } from '../../components/Grid';
import {
  getEuropeProductInformation,
  getEastProductInformation,
} from '../../pages/InMonth/Scheduler/LocationView/ProductInformationBanner';
import { TankMetaData } from '../../pages/InMonth/Stocks/TankMeta';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const GlobalReset = createGlobalStyle`body {overflow: hidden}`;

const SubContainerMarquee = styled.div`
  position: fixed;
  bottom: -10px;
  background: #373746;
  left: 0;
  padding-top: 6px;
  z-index: 5;
  width: 100% !important;
  font-size: 12px !important;
`;
export const StockUllageTextColor = styled.span`
  color: #ff6060;
`;
const MarqueeBody = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 35px;
  font-size: 12px;
`;
const Spacer = styled.div`
  margin: 5px;
  border-left: 1px solid ${p => p.theme.greys.dark.border};
`;

const MarginLeft = styled.div`
  margin-left: 35px;
`;
const RefinaryIcon = styled(Button).attrs({ variant: 'ghost-dark' })`
  height: 75%;
  position: absolute;
  top: 0;
  margin-left: -23px;
  background-size: 75%;
  border: 0px;
  background-repeat: no-repeat;
  background-image: url(${config.PUBLIC_URL}/images/marker-refinery.svg);
`;

const DepotIcon = styled(Button).attrs({ variant: 'ghost-dark' })`
  height: 75%;
  position: absolute;
  top: 0;
  margin-left: -23px;
  background-size: 75%;
  border: 0px;
  background-repeat: no-repeat;
  background-image: url(${config.PUBLIC_URL}/images/marker-depot.svg);
`;
export interface InMonthPageProps {
  currentUser: CurrentUserQuery['currentUser'];
}

export interface StockInfoProps {
  isPiStock: boolean;
  maxVolume?: number | null;
  openingSpecification?: string | null;
  openingAmount?: number | null;
  meta?: TankMetaData[];
}
export interface EuropeLocationPros {
  plantId: string;
  name: string;
}

export interface ProductStockInfo {
  date: string;
  location_id: string;
  stock: number;
  ullage: number;
  ullageFlag: number;
  id: string;
}

export interface EuropeProductStockInfo {
  name: string;
  Ullage: number;
  Stock: number;
  id: string;
}

export interface StockInfoValue {
  code: string;
  data: StockInfoProps | null;
}

export const InMonthPage: React.FC<InMonthPageProps> = () => {
  const { hasAnyOf } = usePermission();
  const { data, loading, error, refetch } = useQuery<GetTransportsQuery>(GET_TRANSPORTS, {
    skip: !hasAnyOf(['viewFleetManagement', 'editEastSchedulingManager', 'eastDepotUser']),
    fetchPolicy: 'no-cache',
  });

  /* tslint:disable */
  const tanks = useQuery<any>(GET_ALL_TANKS_BY_DATES, {
    variables: {},
    pollInterval: 60000,
    fetchPolicy: 'no-cache',
  });

  const eastData = useQuery<EastProductInformation>(GET_ALL_PLANTSTOCK_INFORMATION, {
    variables: {},
    pollInterval: 60000,
  });

  const locationsObj = useQuery<LocationsQuery>(GET_LOCATIONS);
  const locationObj = locationsObj.data ? locationsObj.data.locations : [];
  const currentUser = useCurrentUser();
  const currentUserRole = currentUser.roles[0].name;
  const { listen } = useHistory();
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);
  // Refresh transports just case the movement status has changed
  useEffect(() => {
    return listen(location => {
      if (previousUrl?.includes(`${URLS.IN_MONTH}/edit`) || previousUrl?.includes(`${URLS.IN_MONTH}/new`)) {
        refetch();
      }
      setPreviousUrl(location.pathname);
    });
  }, [previousUrl]);

  let europeProductInformation = getEuropeProductInformation(tanks, locationObj);
  let eastProductInformation = getEastProductInformation(eastData, locationObj);

  return (
    <InMonthLayout>
      <GlobalReset />
      <MapProvider>
        {loading || error ? (
          <Loader error={error ? 'Vessel data is currently unavailable' : undefined} />
        ) : (
          <Map
            center={[markers[0].lng, markers[0].lat]}
            markers={markers}
            vessels={data ? data.transports || [] : []}
          />
        )}
      </MapProvider>
      <SubContainerMarquee>
        {currentUserRole === 'east_depot_user' || currentUserRole === 'east_supply_user' ? (
          <Marquee pauseOnHover={true} gradient={false}>
            {eastProductInformation.map((item: ProductStockInfo) => {
              return (
                <MarqueeBody color="bodyDark" key={item.id}>
                  <Grid>
                    <Row>
                      <MarginLeft />
                      <Col>
                        {item.location_id === 'Bukom' ? <RefinaryIcon /> : <DepotIcon />}
                        {item.location_id?.toUpperCase()} {`: Stock : `}
                        {item.ullageFlag === 2 && item.ullageFlag !== undefined ? (
                          <StockUllageTextColor>{item.stock.toFixed(2) + ' Kt'}</StockUllageTextColor>
                        ) : item.stock ? (
                          item.stock.toFixed(2) + ' Kt'
                        ) : (
                          'N/A'
                        )}
                      </Col>
                      <Spacer />
                      <Col>
                        {`Ullage : `}
                        {item.ullageFlag === 1 && item.ullageFlag !== undefined ? (
                          <StockUllageTextColor>{item.ullage.toFixed(2) + ' Kt'}</StockUllageTextColor>
                        ) : item.ullage ? (
                          item.ullage.toFixed(2) + ' Kt'
                        ) : (
                          'N/A'
                        )}
                      </Col>
                    </Row>
                  </Grid>
                </MarqueeBody>
              );
            })}
          </Marquee>
        ) : (
          <Marquee pauseOnHover={true} gradient={false}>
            {europeProductInformation?.map((item: EuropeProductStockInfo) => {
              return (
                <MarqueeBody color="bodyDark" key={item.id}>
                  <Grid>
                    <Row>
                      <MarginLeft />
                      <Col>
                        {item.name?.toUpperCase()} {`: Stock : `}
                        {item.Stock !== undefined ? item.Stock : item.Stock ? item.Stock : 'N/A'}
                      </Col>
                      <Spacer />
                      <Col>
                        {`Ullage : `}
                        {item.Ullage !== undefined ? item.Ullage : item.Ullage ? item.Ullage : 'N/A'}
                      </Col>
                    </Row>
                  </Grid>
                </MarqueeBody>
              );
            })}
          </Marquee>
        )}
      </SubContainerMarquee>
    </InMonthLayout>
  );
};
