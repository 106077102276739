import React from 'react';
import styled from 'styled-components';
import { FontColors } from '../../theme';
import { Typography } from '../Typography';

const Root = styled.div<{ hasTotalText?: boolean }>`
  align-items: center;
  background-color: ${props => props.theme.base.light.c};
  display: flex;
  height: 100%;
  ${({ hasTotalText }) =>
    hasTotalText &&
    `
  min-width: 220px;
  padding: 8px 6px;
  `}
`;

const Totals = styled.div`
  align-items: center;
  flex: 1;
  height: 100%;
`;

const CurrentTotal = styled.div`
  align-items: center;
  background-color: ${props => props.theme.base.light.b};
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 7px 6px;
  width: 96px;
`;

const CurrentTotalText = styled.div`
  flex: 1;
  margin-right: 15px;
`;

const Unit = styled.div`
  display: inline;
  margin-left: 4px;
`;

const Equals = styled.div`
  display: inline;
  margin-right: 6px;
`;

const TargetTotal = styled.div`
  padding: 3px 6px 0 0;
  text-align: right;
`;

interface Props {
  fixedTarget?: number;
  showFixedTarget?: boolean;
  /**
   * The ideal value. If `total` matches this it will be rendered
   * in valid mode.
   */
  rangeTargetIdeal?: number;
  /**
   * Maximum acceptable value. If `total` is above this it will
   * be rendered in error mode.
   */
  rangeTargetMax?: number;
  /**
   * Minimum acceptable value. If `total` is below this it will
   * be rendered in error mode.
   */
  rangeTargetMin?: number;

  targetType: 'Fixed' | 'Range';
  /**
   * The current total
   */
  total: number;
  /**
   * Label text to refer to the total
   * @default 'Total'
   */
  totalText?: string;
  unit: string;
}

const Total = ({
  fixedTarget,
  rangeTargetIdeal,
  rangeTargetMax,
  rangeTargetMin,
  showFixedTarget,
  targetType,
  total,
  totalText,
  unit,
}: Props) => {
  let hasError = false;
  let targetMet = false;

  if (targetType === 'Fixed') {
    hasError = fixedTarget != null && fixedTarget > 0 && total > 0 && total !== fixedTarget;
    targetMet = fixedTarget != null && !hasError && total === fixedTarget;
  }

  if (targetType === 'Range') {
    hasError = (rangeTargetMin != null && rangeTargetMin > total) || (rangeTargetMax != null && total > rangeTargetMax);
    targetMet = rangeTargetIdeal != null && total === rangeTargetIdeal;
  }

  // prettier-ignore
  const totalColour: FontColors = hasError ? 'errorLight' : (targetMet ? 'validLight' : 'bodyLight');

  return (
    <Root hasTotalText={totalText != null}>
      {totalText != null && (
        <CurrentTotalText>
          <Typography align="right">{totalText}</Typography>
        </CurrentTotalText>
      )}
      <Totals>
        <CurrentTotal>
          <Typography bold={true} color={totalColour} inline={true} tag="span">
            {total}
          </Typography>
          <Unit>
            <Typography bold={true} color="hintLight" inline={true} tag="span">
              {unit}
            </Typography>
          </Unit>
        </CurrentTotal>
        {showFixedTarget && (
          <TargetTotal>
            <Equals>
              <Typography inline={true} tag="span">
                =
              </Typography>
            </Equals>
            <Typography inline={true} tag="span">
              {fixedTarget}
            </Typography>
            <Unit>
              <Typography color="hintLight" inline={true} tag="span">
                {unit}
              </Typography>
            </Unit>
          </TargetTotal>
        )}
      </Totals>
    </Root>
  );
};

export { Total };
