import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '../../../../components/CloseButton';
import { HeaderTitle } from '../../HeaderTitle';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 66px;
  padding: 16px 24px;
  background-color: ${p => p.theme.base.dark.c};
`;

const PullRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

interface MovementHeaderProps {
  title: string;
  onClose: () => void;
}

export const MovementHeader: React.FC<MovementHeaderProps> = ({ title, onClose }) => {
  return (
    <Header>
      <HeaderTitle text={title} />
      <PullRight>
        <CloseButton onClick={onClose} />
      </PullRight>
    </Header>
  );
};
