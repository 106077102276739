import { Location, LocationState } from 'history';
import { DropdownItemOptions } from './components/SelectDropdown/Single';

export const sortDropdownItemsAlphabetically = <T extends DropdownItemOptions>(items: T[]) =>
  items.sort((a, b) => {
    if (a.label === b.label) {
      return 0;
    }

    if (a.label === null) {
      return 1;
    }

    if (b.label === null) {
      return -1;
    }

    return a.label > b.label ? 1 : -1;
  }) as T[];

export const getPreviousUrl = (location: Location, defaultUrl: string) => {
  /**
   * @todo This is a workaround to remove a type error that is discussed here:
   *
   *  {@link https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674}
   */
  const state: LocationState = location;
  const previousPathname = state ? state.previousPathname : null;
  const previousSearch = state ? state.previousSearch : null;

  if (!previousPathname) {
    return defaultUrl;
  }

  if (!previousSearch) {
    return previousPathname;
  }

  return `${previousPathname}${previousSearch}`;
};

export const getPreviousStateParams = (pathname: string, location: Location) => {
  return { pathname, state: { previousPathname: location.pathname, previousSearch: location.search } };
};

export const getUrlWithPreviousSearch = (url: string, location: Location) => {
  /**
   * @todo This is a workaround to remove a type error that is discussed here:
   *
   *  {@link https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674}
   */
  const state: LocationState = location;
  const previousSearch = state ? state.previousSearch : null;

  if (!previousSearch) {
    return url;
  }

  return `${url}${previousSearch}`;
};

export const filterByLabel = (option: { label: string }, inputValue: string) =>
  option.label.toLowerCase().includes(inputValue.toLowerCase());
