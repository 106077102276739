import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const Spacer = styled.div<{ size?: number }>`
  height: ${props => 20 * (props.size || 1)}px;
`;

export interface Props {
  showModal: boolean;
  onConfirm: () => void;
  currentUserTheme?: boolean;
}

const DemandNotSavedModal: React.FunctionComponent<Props> = props => {
  return (
    <Modal
      data-testid="demand-not-saved-modal"
      onConfirm={props.onConfirm}
      isOpen={props.showModal}
      confirmText="OK"
      confirmBtnVariant="primary"
      currentUserTheme={props.currentUserTheme}
    >
      <Spacer />
      <Typography variant="heading5" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center">
        This new demand can't be saved yet
      </Typography>
      <Spacer size={2} />

      <Typography variant="body3" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center">
        Please enter at least the grade split or monthly volumes.
      </Typography>
    </Modal>
  );
};

export { DemandNotSavedModal };
