import * as React from 'react';
import { Typography } from '../Typography';
import styled from 'styled-components';

const Root = styled.div<{ addHeight?: boolean; 'data-testid': string }>`
  height: ${props => (props.addHeight ? '1.5rem' : 'auto')};
  margin-top: 5px;
`;

interface FormErrorProps {
  message?: string;
  withSpaceHolder?: boolean;
}

const FormError = (props: FormErrorProps) => (
  <Root addHeight={props.withSpaceHolder} data-testid="form-error">
    <Typography color="errorLight" variant="body3">
      {props.message}
    </Typography>
  </Root>
);

export { FormError };
``;
