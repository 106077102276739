import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  height: 100%;
  max-width: ${props => props.theme.containerWidth};
  margin: 0 auto;
`;

const StyledRow = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  width: 100%;
`;

type ColNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const StyledCol = styled.div<{ size?: ColNumber; pullRight?: boolean }>`
  ${props => props.size && `flex-basis: ${(100 / 12) * props.size}%;`}
  ${props => props.pullRight && `margin-left: auto;`}
  
  flex-direction: column;
`;

const Grid: React.FC = ({ children }) => {
  return <Root>{children}</Root>;
};

interface RowProps {
  isFullHeight?: boolean;
}

const Row: React.FC<RowProps> = ({ children, isFullHeight }) => {
  return <StyledRow isFullHeight={isFullHeight}>{children}</StyledRow>;
};

interface ColProps {
  size?: ColNumber;
  pullRight?: boolean;
}

const Col: React.FC<ColProps> = ({ children, size, pullRight, ...rest }) => {
  return (
    <StyledCol size={size} pullRight={pullRight} {...rest}>
      {children}
    </StyledCol>
  );
};

export { Grid, Row, Col };
