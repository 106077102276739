import moment from 'moment';
import { FontColors } from '../../../../theme';
import { MovementStagesQueryResult } from '@scout/types';
import { getVesselEta } from '../../../../components/Map/helpers';

interface EtaStatus {
  text: string;
  color: FontColors;
}

interface MovementStage {
  location?: {
    name: string;
    country?: string | null;
    locationMarineNames: Array<{
      name: string;
    }>;
  } | null;
}

export const hasMatchedPortName = (
  movementStage: MovementStage,
  marineTrafficPortName: string | undefined,
  marineTrafficPortCountry: string | undefined,
) => {
  const locationCountry = movementStage.location?.country?.toLowerCase();
  const locationName = movementStage.location?.name.toLowerCase();

  if (!locationName || !locationCountry || !marineTrafficPortName || !marineTrafficPortCountry) {
    return false;
  }

  if (locationCountry !== marineTrafficPortCountry?.toLowerCase()) {
    return false;
  }

  if (locationName === marineTrafficPortName?.toLowerCase()) {
    return true;
  }

  return movementStage.location?.locationMarineNames.some(
    locationMappingName => locationMappingName.name?.toLowerCase() === marineTrafficPortName?.toLowerCase(),
  );
};

export const getMovementStageStatus = (movementStage: MovementStagesQueryResult[0]): EtaStatus => {
  const transport = movementStage.movement.transport;
  const nextPortName = transport?.nextPortName?.toLowerCase();
  const nextPortCountry = transport?.nextPortCountry?.toLowerCase();
  const currentPortName = transport?.currentPort?.toLowerCase();
  const currentPortCountry = transport?.currentPortCountry?.toLowerCase();
  const lastPortName = transport?.lastPort?.toLowerCase();
  const lastPortCountry = transport?.lastPortCountry?.toLowerCase();
  const eta = getVesselEta(transport);

  if (movementStage.completed) {
    return {
      text: 'Completed',
      color: 'bodyDark',
    };
  }

  if (movementStage.movement.cancelled) {
    return {
      text: 'Cancelled',
      color: 'errorDark',
    };
  }

  if (!transport) {
    return {
      text: '',
      color: 'bodyDark',
    };
  }

  const previousMovementStage = movementStage.movement.movementStages.find(
    stage => stage.index === movementStage.index - 1,
  );

  // logic when the previous stage is being completed

  if (previousMovementStage) {
    // en route to previous port logic
    if (hasMatchedPortName(previousMovementStage, nextPortName, nextPortCountry)) {
      return {
        text: `En route to ${previousMovementStage.location?.name}`,
        color: 'bodyDark',
      };
    }

    // in previous port logic
    if (hasMatchedPortName(previousMovementStage, currentPortName, currentPortCountry)) {
      return {
        text: `In port ${previousMovementStage.location?.name}`,
        color: 'bodyDark',
      };
    }
  }

  // logic when the current stage is being completd

  // before load arrival - next port logic
  // TODO: reuse hasMatchedPortName
  if (hasMatchedPortName(movementStage, nextPortName, nextPortCountry) && eta) {
    const transportEtaCalc = moment(eta).startOf('day');
    const movementStageDate = moment(movementStage.date).startOf('day');

    if (transportEtaCalc.isSame(movementStageDate)) {
      return {
        text: `ETA ${moment(eta).format('HH:mm')}`,
        color: 'validLight',
      };
    }

    if (transportEtaCalc.isBefore(movementStageDate)) {
      return {
        text: `Early ${moment(eta).format('DD MMM')}`,
        color: 'errorDark',
      };
    }

    if (transportEtaCalc.isAfter(movementStageDate)) {
      return {
        text: `Late ${moment(eta).format('DD MMM')}`,
        color: 'errorDark',
      };
    }
  }

  // on load arrival - current port logic
  if (hasMatchedPortName(movementStage, currentPortName, currentPortCountry)) {
    const systemDate = moment().startOf('day');
    const movementStageDate = moment(movementStage.date).startOf('day');

    if (systemDate.isBefore(movementStageDate)) {
      return {
        text: 'Early in port',
        color: 'errorDark',
      };
    }

    if (systemDate.isSame(movementStageDate)) {
      return {
        text: 'In port',
        color: 'validLight',
      };
    }
  }

  // on load depart - last port logic
  if (hasMatchedPortName(movementStage, lastPortName, lastPortCountry) && transport.lastPortTime) {
    const transportLastPortTime = moment(transport.lastPortTime).startOf('day');
    const movementStageDate = moment(movementStage.date).startOf('day');

    if (transportLastPortTime.isSame(movementStageDate)) {
      return {
        text: `Depart ${moment(transport.lastPortTime).format('HH:mm')}`,
        color: 'bodyDark',
      };
    }

    if (transportLastPortTime.isAfter(movementStageDate)) {
      const differenceInDays = transportLastPortTime.diff(movementStageDate, 'days');
      return {
        text: `Depart ${moment(transport.lastPortTime).format('HH:mm')} +${differenceInDays}`,
        color: 'bodyDark',
      };
    }
  }

  // no eta
  if (!hasMatchedPortName(movementStage, nextPortName, nextPortCountry)) {
    return {
      text: 'NO ETA',
      color: 'warning',
    };
  }

  return {
    text: '',
    color: 'bodyDark',
  };
};
