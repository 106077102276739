import React from 'react';
import { Header } from '../components/Header';

interface IDefaultLayout {
  headerContent?: React.ReactNode;
}

const DefaultLayout: React.FC<IDefaultLayout> = ({ children, headerContent }) => (
  <>
    <Header>{headerContent}</Header>
    <main>{children}</main>
  </>
);

export { DefaultLayout };
