import { createGlobalStyle, GlobalStyleComponent } from 'styled-components';
import { theme, IThemeType } from '../../theme';

interface Props {
  themeStyle: 'dark' | 'light';
  focusedInput: boolean;
}

const themeColors = {
  dark: {
    background: theme.base.dark.c,
    cellBackground: theme.base.dark.d,
    color: theme.secondary.light.c,
    borderColor: theme.greys.dark.border,
  },
  light: {
    background: theme.base.light.a,
    cellBackground: theme.base.light.c,
    color: theme.base.dark.b,
    borderColor: theme.greys.light.border,
  },
};

export const DatePickerStyle: GlobalStyleComponent<Props, IThemeType> = createGlobalStyle`
.CalendarMonthGrid_month__horizontal .CalendarMonth,
.DayPicker_transitionContainer .CalendarMonthGrid,
.DateRangePickerInput .DayPicker__horizontal,
.DateRangePicker_picker,
.DayPicker_transitionContainer__horizontal {
  background: ${p => themeColors[p.themeStyle].background};
  border-radius: 2px;
}

.DayPicker .CalendarDay__default {
  background: ${p => themeColors[p.themeStyle].cellBackground};
  color: ${theme.base.light.a};
  border-color: ${p => themeColors[p.themeStyle].background};
  border-radius: 2px;
}

.DayPicker .CalendarDay__default:hover {
  background: ${theme.primary.b};
  border: inherit;
}

.DayPicker .CalendarMonth_caption {
  color: ${p => themeColors[p.themeStyle].color};
}

.DayPicker .CalendarDay__selected {
  background: ${theme.primary.b};
  color: ${p => themeColors[p.themeStyle].background};
}

.DateRangePicker .CalendarDay__selected_span,
.DateRangePicker .CalendarDay__hovered_span:hover,
.DateRangePicker .CalendarDay__hovered_span {
  background: ${theme.primary.b}${theme.opacity[0.1]};
  color: ${theme.secondary.light.c};
}

.DateRangePicker .DateRangePickerInput,
.DateRangePickerInput .DateInput,
.DateRangePickerInput .DateInput_1,
.DateRangePickerInput .DateInput_input_1,
.DateRangePickerInput .DateInput_input__focused,
.DateRangePickerInput .DateRangePickerInput__withBorder {
  background: ${theme.base.dark.a};
  border: none;
  color: ${theme.secondary.light.c};
  text-align: center;
  font-size: 14px;

  &::placeholder {
    color: ${theme.greys.dark.border};
  }
}

.DateRangePickerInput .DateInput_input  {
  border: 1px solid ${theme.greys.dark.border};
  height: 40px;

  ${p => p.focusedInput === true && `border-color: ${theme.primary.b}`}
}

.DateRangePickerInput input[id="startDate"] {
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.DateRangePickerInput input[id="endDate"] {
  border-left: 0;
  border-radius: 0 2px 2px 0;
}

.DateRangePickerInput .DateRangePickerInput_arrow {
  height: 40px;
  line-height: 2.5;
  border-top: 1px solid ${theme.greys.dark.border};
  border-bottom: 1px solid ${theme.greys.dark.border};

  ${p => p.focusedInput === true && `border-color: ${theme.primary.b}`}
}

.DayPickerNavigation .DayPickerNavigation_button {
  background: transparent;
  border-radius: 2px;
  border: 1px solid ${p => themeColors[p.themeStyle].color};
  padding: 6px;
}

.DateRangePickerInput .DateRangePickerInput_arrow_svg,
.DayPickerNavigation .DayPickerNavigation_button svg {
  fill: ${theme.base.light.a};
}


.DateInput_fang .DateInput_fangStroke {
  stroke: ${p => themeColors[p.themeStyle].background};
  fill:  ${p => themeColors[p.themeStyle].background};
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.CalendarMonth .CalendarMonth_table.CalendarMonth_table_1 {
  margin-top: .5rem;
}

.DayPicker_weekHeader .DayPicker_weekHeader_ul  {
  border-top: 1px solid ${theme.base.dark.d};
  font-size: 1rem;
  padding: .5rem 0;
}

::placeholder {
  color: ${theme.base.light.a};
}
`;
