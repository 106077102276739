import * as React from 'react';
import styled, { css } from 'styled-components';
import { FontColors } from '../../theme';
import { InputProps } from '../InputBase/InputBase';
import { Typography } from '../Typography';

type CheckboxProps = Omit<InputProps, 'value' | 'onChange' | 'onBlur'> & {
  label?: string;
  color?: FontColors;
  checked?: boolean;
  isEast?: boolean;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  currentUserTheme?: boolean;
};

const Root = styled.div`
  position: relative;
  margin-right: 5px;
  margin-top: -3px;
`;

const CheckmarkMixin = css`
  height: 20px;
  width: 20px;
`;

const Input = styled.input<{ disabled?: boolean }>`
  ${CheckmarkMixin}
  margin: 0;
  visibility: hidden;
  margin-right: 0.2rem;

  &:checked + span {
    border-radius: 2px;
    background: ${props => (props.disabled ? props.theme.greys.light.disabled : props.theme.primary.b)};
    &:after {
      display: block;
    }
  }
`;

const errorCheckmarkMixin = css`
  border: 1px solid ${props => props.theme.secondary.light.a};
`;

const disabledCheckmarkMixin = css`
  border: 1px solid ${props => props.theme.base.light.a};
  cursor: default;
  &:after {
    border-color: ${props => props.theme.base.light.a};
  }
`;

const Checkmark = styled.span<{ disabled?: boolean; error?: boolean; currentUserTheme?: boolean; isEast?: boolean }>`
  ${CheckmarkMixin}
  background :${props =>
    props.disabled
      ? props.currentUserTheme
        ? props.theme.base.light.d
        : props.isEast
        ? props.theme.base.light.e
        : props.theme.base.light.a
      : 'transparent'};
  border: 1px solid ${props => props.theme.greys.dark.border};
  border-radius: 2px;
  top: 2px;
  cursor: pointer;
  left: 0;
  position: absolute;

  &:after {
    border: 2px solid ${props => props.theme.base.light.a};
    border-width: 0 3px 3px 0;
    border-radius: 2px;
    content: '';
    display: none;
    height: 10px;
    left: 5px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg);
    width: 5px;
  }
  ${props => props.disabled && disabledCheckmarkMixin}
  ${props => props.error && errorCheckmarkMixin}
`;

const Label = styled.label`
  display: flex;
  margin: 16px 0;
`;

export const InputCheckbox: React.FunctionComponent<CheckboxProps> = props => (
  <Root>
    <Input
      type="checkbox"
      {...props}
      onChange={() => props.onChange(props.checked ? '' : '1')}
      onBlur={() => props.onChange(props.checked ? '' : '1')}
    />
    <Checkmark
      disabled={props.disabled}
      error={Boolean(props.error)}
      currentUserTheme={props.currentUserTheme}
      isEast={props.isEast}
    />
  </Root>
);

const Checkbox: React.FunctionComponent<CheckboxProps> = ({ label, ...rest }) => (
  <Root>
    <Label>
      <InputCheckbox {...rest} />
      {label && (
        <Typography
          tag="span"
          variant="body4"
          color={
            rest.isEast === true
              ? rest.currentUserTheme
                ? 'bodyLight'
                : 'disabledBgLight'
              : rest.disabled
              ? 'disabledBgLight'
              : 'bodyLight'
          }
          bold={rest.checked && !rest.disabled}
        >
          {label}
        </Typography>
      )}
    </Label>
  </Root>
);

export { Checkbox };
