import { GetScheduleManagerByPortQuery } from '@scout/types/dist';
import moment from 'moment';

export const getThirdpartyRefineryMovementsOnDate = (
  movementList: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  queryDate: string,
  currentPort: string,
) =>
  movementList.filter(movementStage => {
    if (movementStage.productType === 'Lifting' && movementStage.dragLoadportstartdate === queryDate) {
      return true;
    }

    return false;
  });

export const getThirdpartyCustomerMovementsOnDate = (
  movementList: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  queryDate: string,
  currentPort: string,
) =>
  movementList.filter(movementStage => {
    if (movementStage.productType === 'Replenishment' && movementStage.dragDischargePortStartDate === queryDate) {
      return true;
    }

    return false;
  });
export const getRefineryMovementsOnDate = (
  movementList: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  queryDate: string,
  currentPort: string,
) =>
  movementList.filter(movementStage => {
    if (
      (movementStage.productType === 'Production' && movementStage.plannedStartDate === queryDate) ||
      (movementStage.productType === 'Lifting' && movementStage.dragLoadportstartdate === queryDate) ||
      (movementStage.productType === 'Stock' && movementStage.movementDate === queryDate)
    ) {
      return true;
    }

    return false;
  });

export const getDepotMovementsOnDate = (
  movementList: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  queryDate: string,
  currentPort: string,
) =>
  movementList.filter(movementStage => {
    if (
      (movementStage.productType === 'Sale' && movementStage.plannedStartDate === queryDate) ||
      (movementStage.productType === 'Replenishment' && movementStage.dragDischargePortStartDate === queryDate) ||
      (movementStage.productType === 'Stock' && movementStage.movementDate === queryDate)
    ) {
      return true;
    }

    return false;
  });
const dateFormat = 'YYYY-MM-DD';
export const getEventsOndate = (
  eventNotes: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  queryDate: string | undefined,
) => {
  const filteredData: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] = eventNotes.filter(
    eventNote =>
      eventNote.productType === 'Event' &&
      eventNote.eventStartDate &&
      eventNote.eventEndDate &&
      moment(queryDate, dateFormat).isBetween(
        moment(eventNote.eventStartDate, dateFormat),
        moment(eventNote.eventEndDate, dateFormat),
        'days',
        '[]',
      ),
  );
  return filteredData;
};
