import { Formik } from 'formik';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { CloseButton } from '../../../../components/CloseButton';
import { Container } from '../../../../components/Container';
import { Col, Grid, Row } from '../../../../components/Grid';
import { Tagged } from '../../../../components/Tagged';
import { Typography } from '../../../../components/Typography';
import { mapContractTypeToString } from '../../../../helpers';
import { ContractDealType, ShipmentType } from '@scout/types';
import { getShipmentTypeName } from '../../../../utils/getters';
import { HeaderValues } from '../Steps/types';
import { HeaderCloseModal } from './CloseModal';
import { HeaderEditModal } from './EditModal';
import moment from 'moment';
import { UserThemeContext } from '../../../../App';

const Root = styled.div<{ currentUserTheme?: boolean }>`
  background: ${props => (props.currentUserTheme ? props.theme.secondary.light.c : props.theme.base.dark.d)};
  height: 4.125rem;
  position: fixed;
  width: 100%;
  z-index: 100;
`;

const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Line = styled.div`
  border-bottom: 4px solid ${p => p.theme.primary.b};
  height: 1px;
  margin-top: 8px;
  width: 32px;
`;

const Spacer = styled.div<{ currentUserTheme?: boolean }>`
  border-left: 1px solid ${p => (p.currentUserTheme ? p.theme.greys.light.border : p.theme.greys.dark.border)};
  height: 100%;
  margin: 13px 17px 12px 17px;
  width: 1px;
`;

const SpacedTagWithContent = styled(Tagged)`
  margin-left: 5px;
`;

const RestrictedLabel = styled(Typography)`
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DateLabel = styled(Typography)`
  margin-left: 6px;
  color: ${props => props.theme.greys.light.border};
  font-size: 12px;
  line-height: 14px;
`;

const DateLabelWraper = styled.div`
  display: flex;
  align-items: baseline;
`;

interface Props {
  contractType: ContractDealType;
  ownerName: string;
  auditDate?: string;
  creatorName?: string;
  updaterName?: string;
  customerCode: string;
  customerName: string;
  customerSalesOrganisation: string;
  dealId?: string | null;
  onCancel?: () => void;
  onEdit?: () => void;
  onSubmit: (values: HeaderValues) => void;
  shipmentType: ShipmentType;
  stascoId?: string | null;
}

const Header: React.FunctionComponent<Props> = props => {
  const { currentUserTheme } = useContext(UserThemeContext);

  const [isClosing, setIsClosing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const contractIdLabel = props.stascoId != null ? 'STASCO ID:' : 'Deal ID:';
  const contractId = props.stascoId ?? props.dealId ?? '-';

  return (
    <Root currentUserTheme={currentUserTheme}>
      <HeaderCloseModal isOpen={isClosing} onDismiss={() => setIsClosing(false)} onConfirm={props.onCancel} />
      <Container hasVerticalPadding={false}>
        <Grid>
          <Row isFullHeight={true}>
            <CenteredCol>
              <Typography
                data-testid="header-title"
                variant="heading5"
                color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                bold={true}
              >
                {mapContractTypeToString(props.contractType)}
              </Typography>
              <Line />
            </CenteredCol>
            <CenteredCol>
              <Spacer currentUserTheme={currentUserTheme} />
            </CenteredCol>
            <CenteredCol size={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  color={currentUserTheme ? 'hintDark' : 'hintLight'}
                  inline={true}
                  tag="span"
                  variant="labels1"
                >
                  Sold to:
                </Typography>
                <SpacedTagWithContent tag={props.customerSalesOrganisation}>{props.customerCode}</SpacedTagWithContent>
              </div>
              <Typography
                color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                inline={true}
                overflowHidden={true}
                tag="span"
                variant="body3"
              >
                {props.customerName}
              </Typography>
            </CenteredCol>
            <CenteredCol>
              <Spacer currentUserTheme={currentUserTheme} />
            </CenteredCol>
            <CenteredCol>
              <Typography
                color={currentUserTheme ? 'hintDark' : 'hintLight'}
                inline={true}
                tag="span"
                variant="labels1"
              >
                {contractIdLabel}
              </Typography>
              <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} inline={true} tag="span" variant="body3">
                {contractId}
              </Typography>
            </CenteredCol>
            <CenteredCol>
              <Spacer currentUserTheme={currentUserTheme} />
            </CenteredCol>
            <CenteredCol>
              <Typography
                color={currentUserTheme ? 'hintDark' : 'hintLight'}
                inline={true}
                tag="span"
                variant="labels1"
              >
                Shipment type:
              </Typography>
              <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} inline={true} tag="span" variant="body3">
                {getShipmentTypeName(props.shipmentType)}
              </Typography>
            </CenteredCol>
            <CenteredCol>
              <Spacer currentUserTheme={currentUserTheme} />
            </CenteredCol>
            <CenteredCol size={2}>
              <Typography
                color={currentUserTheme ? 'hintDark' : 'hintLight'}
                inline={true}
                tag="span"
                variant="labels1"
              >
                Owner:
              </Typography>
              <RestrictedLabel
                color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                inline={true}
                tag="span"
                variant="body3"
              >
                {props.ownerName}
              </RestrictedLabel>
            </CenteredCol>
            <CenteredCol>
              <Spacer currentUserTheme={currentUserTheme} />
            </CenteredCol>

            {(props.updaterName || props.creatorName) && props.auditDate && (
              <>
                <CenteredCol size={2}>
                  <Typography
                    color={currentUserTheme ? 'hintDark' : 'hintLight'}
                    inline={true}
                    tag="span"
                    variant="labels1"
                  >
                    {props.updaterName ? 'Last edited' : 'Created'} by:
                  </Typography>
                  <DateLabelWraper>
                    <RestrictedLabel
                      color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                      inline={true}
                      tag="span"
                      variant="body3"
                    >
                      {props.updaterName || props.creatorName}
                    </RestrictedLabel>
                    <DateLabel
                      color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                      inline={true}
                      tag="span"
                      variant="labels1"
                    >
                      {moment(props.auditDate).format('DD/MM/YYYY')}
                    </DateLabel>
                  </DateLabelWraper>
                </CenteredCol>
                <CenteredCol>
                  <Spacer />
                </CenteredCol>
              </>
            )}

            <CenteredCol>
              <Button
                type="button"
                variant={currentUserTheme ? 'icon-light' : 'link-dark'}
                onClick={() => setIsEditing(true)}
                currentUserTheme={currentUserTheme}
              >
                Edit
              </Button>
            </CenteredCol>
            <CenteredCol pullRight={true}>
              <CloseButton hasBorder={true} onClick={() => setIsClosing(true)} currentUserTheme={currentUserTheme} />
            </CenteredCol>
          </Row>
        </Grid>
      </Container>

      <Formik
        initialValues={{ shipmentType: props.shipmentType }}
        onSubmit={props.onSubmit}
        render={formProps => {
          return (
            <HeaderEditModal
              contractId={contractId}
              contractIdLabel={contractIdLabel}
              contractType={props.contractType}
              customerCode={props.customerCode}
              customerName={props.customerName}
              customerSalesOrganisation={props.customerSalesOrganisation}
              isOpen={isEditing}
              shipmentType={props.shipmentType}
              onConfirm={() => {
                formProps.submitForm();
                setIsEditing(false);
              }}
              onDismiss={() => setIsEditing(false)}
              currentUserTheme={currentUserTheme}
            />
          );
        }}
      />
    </Root>
  );
};

export { Header };
