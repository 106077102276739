import * as React from 'react';
import { MdCheck, MdCheckCircle, MdClose, MdErrorOutline, MdWarning } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';
import { Typography } from '../Typography';

export type AlertTypes = 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO';
export type AlertSizes = 'SMALL' | 'LARGE';
export type AlertBoxStyles = 'NORMAL' | 'FLAT';

const colorType = {
  ERROR: theme.secondary.light.a,
  SUCCESS: theme.secondary.light.b,
  WARNING: theme.primary.a,
  INFO: theme.primary.b,
};

const iconType = {
  ERROR: MdWarning,
  SUCCESS: MdCheckCircle,
  WARNING: MdErrorOutline,
  INFO: MdCheck,
};

const flatStyleMixin = css<{ type: AlertTypes }>`
  box-shadow: none;
  border: 1px solid ${p => colorType[p.type]};
`;

const Box = styled.div<{ size: AlertSizes; type: AlertTypes; boxStyle: AlertBoxStyles; dark: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${p => (p.dark ? p.theme.secondary.light.d : p.theme.base.light.a)};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  min-width: 432px;
  margin: 20px 0;
  height: ${p => (p.size === 'SMALL' ? '48px' : '82px')};
  ${p => p.boxStyle === 'FLAT' && flatStyleMixin}
`;

const IconBox = styled.div`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TypographyBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

const Indicator = styled.div`
  height: 100%;
  width: 4px;
  background-color: ${p => p.color};
  border-radius: 2px 0 0 2px;
`;

interface Props {
  className?: string;
  text: string;
  type?: AlertTypes;
  size?: AlertSizes;
  boxStyle?: AlertBoxStyles;
  dark?: boolean;
  onClose?: () => void;
}

export const Alert: React.FunctionComponent<Props> = ({
  className,
  text,
  type = 'INFO',
  size = 'SMALL',
  boxStyle = 'NORMAL',
  dark = false,
  onClose,
}) => {
  const alertColor = colorType[type];
  const Icon = iconType[type];

  return (
    <Box
      data-testid="alert-box"
      className={className}
      size={size}
      color={alertColor}
      type={type}
      boxStyle={boxStyle}
      dark={dark}
    >
      {boxStyle === 'NORMAL' && <Indicator color={alertColor} />}
      <IconBox>
        <Icon color={alertColor} size={22} />
      </IconBox>
      <TypographyBox>
        <Typography color={dark ? 'bodyDark' : 'bodyLight'}>{text}</Typography>
      </TypographyBox>
      {onClose && (
        <IconBox onClick={onClose}>
          <MdClose color={theme.base.light.d} size={20} />
        </IconBox>
      )}
    </Box>
  );
};
