import {
  GET_SPOT_PRICES,
  FuelOilQuoteQuery,
  ShipmentType,
  SpotPricesQuery,
  SpotPricesQueryVariables,
} from '@scout/types';
import { FieldArray, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { UserThemeContext } from '../../../../App';
import { Button } from '../../../../components/Button';
import { Col, Grid, Row } from '../../../../components/Grid';
import { HelpText } from '../../../../components/HelpText';
import { LabelValue } from '../../../../components/LabelValue';
import { Typography } from '../../../../components/Typography';
import { formatRoundedNumber } from '../../../../helpers';
import { Cluster, VolumePricingItem } from '../../../../types';
import { HorizontalDivider } from '../HorizontalDivider';
import { TextField } from '../TextField';
import { DistributeVolumeModal } from './DistributeVolumeModal';
import { getVolumeDistribution } from './volumeDistribution';
import { TotalRow } from './VolumeTotalRow';

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  margin-left: auto;
  justify-content: space-between;
`;

const Title = styled(Typography)<{ margin?: string }>`
  line-height: 3;
  margin-left: ${p => p.margin || '0px'};
`;

const LabelWrapper = styled.div`
  width: 175px;
  padding: 0 30px 0 50px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const rowHeights = {
  first: '90px',
  middle: '60px',
  last: '50px',
};

const MonthColumn = styled.div<{ isFirst: boolean }>`
  display: flex;
  width: 50px;
  justify-content: flex-start;
  align-items: center;
  height: ${p => (p.isFirst ? rowHeights.first : rowHeights.last)};
`;

const MonthText = styled(Typography)<{ isFirst: boolean }>`
  margin-top: ${p => (p.isFirst ? '15px' : '0')};
`;

const Label = styled(Typography)`
  flex: 1;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.greys.light.disabled};
`;

type RowPosition = keyof typeof rowHeights;

const SolidLine = styled.div<{ rowPosition: RowPosition }>`
  border-left: 1px solid ${p => p.theme.greys.light.border};
  margin-left: 50px;
  padding-left: 50px;
  display: flex;
  height: ${p => rowHeights[p.rowPosition]};
`;

const DashedLine = styled.div<{ rowPosition: RowPosition }>`
  border-left: 1px dashed ${p => p.theme.greys.light.border};
  margin-left: 50px;
  display: flex;
  height: ${p => rowHeights[p.rowPosition]};
`;

const Space15 = styled.div`
  width: 15px;
  height: 48px;
`;

const Header = styled(Typography)`
  margin-bottom: 10px;
  white-space: nowrap;
`;

export interface MonthYearValue {
  month: string;
  year: string;
}

export type ForecastMonth = VolumePricingItem & MonthYearValue;

export interface VolumePricingContainerProps {
  spotDemandId: string;
  volumeItems: VolumePricingItem[];
  formProps: FormikProps<{ volumePrices: VolumePricingItem[] }>;
  cluster: Cluster;
  isActive: boolean;
  isEditing?: boolean;
  fuelOilQuote: FuelOilQuoteQuery['fuelOilQuote'];
  shipmentType: ShipmentType;
  customerCode: string;
  countryCode?: string;
  regionId?: string;
  plantId: string;
  year: number;
}

const VolumePricingContainer: React.FunctionComponent<VolumePricingContainerProps> = ({
  spotDemandId,
  volumeItems,
  formProps,
  cluster,
  isActive,
  isEditing,
  fuelOilQuote,
  shipmentType,
  customerCode,
  countryCode,
  regionId,
  plantId,
  year,
}) => {
  const { currentUserTheme } = React.useContext(UserThemeContext);
  const [showModal, setModalVisibility] = React.useState(!isEditing);

  const handleDistributeVolume = React.useCallback(
    formValues => {
      if (!formValues.volume || !formValues.dateRange || !formValues.dateRange.from || !formValues.dateRange.to) {
        return;
      }

      const distributionValues = getVolumeDistribution({
        fromString: formValues.dateRange.from.value,
        toString: formValues.dateRange.to.value,
        volume: formValues.volume,
        volumeItems,
        distribution: formValues.distribution,
        cluster,
      });

      distributionValues.forEach(distributionValue => {
        if (distributionValue) {
          formProps.setFieldValue(
            `volumePrices.${distributionValue.index}.volume`,
            parseFloat(distributionValue.value),
          );
        }
      });

      setModalVisibility(false);
    },
    [volumeItems, cluster],
  );

  const { data: spotPricesData, loading: spotPricesLoading } = useQuery<SpotPricesQuery, SpotPricesQueryVariables>(
    GET_SPOT_PRICES,
    {
      skip: shipmentType === ShipmentType.Pickup && !plantId,
      variables: {
        spotDemandId,
        dates: volumeItems.map(volumeItem => volumeItem.date),
        shipmentType,
        clusterId: cluster.id,
        customerCode,
        countryCode,
        regionId,
        plantId,
      },
    },
  );

  const volumePrices = formProps.values.volumePrices;
  const volumePricesCount = volumePrices.length;

  if (!isActive) {
    return null;
  }

  const getSpotPrice = (date: moment.Moment) => {
    const priceForecast =
      spotPricesData &&
      spotPricesData.spotPrices &&
      spotPricesData.spotPrices.find(e => moment(e.date).isSame(date, 'months'));

    return priceForecast ? priceForecast.price.toFixed(2) : 'N/A';
  };

  return (
    <>
      <DistributeVolumeModal
        cluster={cluster}
        volumeItems={volumeItems}
        isOpen={showModal}
        onSubmit={handleDistributeVolume}
        onCancel={() => {
          setModalVisibility(false);
        }}
      />
      <Grid>
        <Row>
          <Col size={12}>
            <Row>
              <Button
                variant="ghost-light"
                onClick={() => {
                  setModalVisibility(!showModal);
                }}
              >
                Distribute Volume
              </Button>
              <LabelContainer>
                <LabelValue label="Cluster Currency:" value={cluster.currency} />
                <LabelValue
                  label="M-1 Avg. FO"
                  value={formatRoundedNumber(fuelOilQuote.avgPrice)}
                  optionalText={`As of ${fuelOilQuote.date}`}
                />
              </LabelContainer>
            </Row>

            <HorizontalDivider />
          </Col>
        </Row>

        <Row>
          <Col size={3}>
            <Title variant="body1" bold={true}>
              {`${year} forecast`}
            </Title>
          </Col>
          <Col size={5}>
            <Title margin="50px" variant="body1" bold={true}>
              Forecasted price vs FO
            </Title>
          </Col>
          <Col size={4}>
            <Title variant="body1" bold={true}>
              Optional tranche
            </Title>
          </Col>
        </Row>

        <FieldArray
          name="volumePrices"
          render={() =>
            volumePrices.map((volumePrice, index) => {
              const date = moment(volumePrice.date);
              const month = date.format('MMM');
              const rowPosition: RowPosition =
                index === 0 ? 'first' : index === volumePrices.length - 1 ? 'last' : 'middle';
              const isFirst = rowPosition === 'first';
              return (
                <div key={`volume-${index}`}>
                  <Row>
                    <Col size={3}>
                      <Row>
                        <MonthColumn isFirst={isFirst}>
                          <MonthText isFirst={isFirst} variant="body3">
                            {month}
                          </MonthText>
                        </MonthColumn>
                        <InputWrapper>
                          {isFirst && (
                            <Header variant="body3" align="right">
                              Volume
                            </Header>
                          )}
                          <TextField
                            tabIndex={index + 1}
                            disabled={volumePrice.readonly}
                            name={`volumePrices.${index}.volume`}
                            suffix="t"
                            useFastField={false}
                            showErrorMessage={false}
                            min={0}
                          />
                        </InputWrapper>
                        <DashedLine rowPosition={rowPosition} />
                      </Row>
                    </Col>
                    <Col size={5}>
                      <Row>
                        <LabelWrapper>
                          {isFirst && (
                            <Header variant="body3" align="right">
                              Pricing team
                            </Header>
                          )}
                          <Label variant="body3">
                            <span data-testid="spot-pricing-forecast-label">
                              {spotPricesLoading ? 'Fetching...' : getSpotPrice(date)}
                            </span>
                          </Label>
                        </LabelWrapper>
                        <InputWrapper>
                          {isFirst && (
                            <HelpText text="AM Override" align="right">
                              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                                This AM override option has been provided for instances where Sales want to enter a
                                different spot price for the forecasted volume. Please enter a seperate sales price, if
                                you disagree with the prediction coming from the pricing team. Your price should be
                                relative to fuel oil in your cluster currency.
                              </Typography>
                              <br />
                              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                                If you insert a price here, it will override the prediction coming from the pricing team
                                and your price will be going into Netsim optimisation cycle. At each month end, pricing
                                and supply teams will review these entries to optimise the spot price prediction logic.
                              </Typography>
                            </HelpText>
                          )}
                          <TextField
                            tabIndex={index + volumePricesCount + 1}
                            name={`volumePrices.${index}.price`}
                            suffix=""
                            disabled={volumePrice.readonly}
                            useFastField={false}
                            showErrorMessage={false}
                            allowNegative={true}
                          />
                        </InputWrapper>
                        <SolidLine rowPosition={rowPosition} />
                      </Row>
                    </Col>
                    <Col size={4}>
                      <Row>
                        <InputWrapper>
                          {isFirst && (
                            <HelpText text="Tranche Volume" align="right">
                              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                                Tranche volume option has been provided so that sales can capture additional spot
                                forecasts on the top of the base forecast. If you see any additional tranche opportunity
                                for a month, please enter both your tranche volume and the premium or discount over the
                                main forecasted price. If you have entered an AM Override price, the system will take
                                that into account and apply the premium or discount onto it.
                              </Typography>
                              <br />
                              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                                Please note that all demands (incl. main forecast & optional tranche) will have no order
                                of priority and will be optimised all together in Netsim, depending on their netback
                                price value.
                              </Typography>
                            </HelpText>
                          )}
                          <TextField
                            tabIndex={index + 2 * volumePricesCount + 1}
                            disabled={volumePrice.readonly}
                            name={`volumePrices.${index}.incrementalVolume`}
                            suffix="t"
                            useFastField={false}
                            showErrorMessage={false}
                            min={0}
                          />
                        </InputWrapper>
                        <Space15 />
                        <InputWrapper>
                          {isFirst && (
                            <Header variant="body3" align="right">
                              Premium/Discount
                            </Header>
                          )}
                          <TextField
                            tabIndex={index + 3 * volumePricesCount + 1}
                            disabled={volumePrice.readonly}
                            name={`volumePrices.${index}.discount`}
                            allowNegative={true}
                            suffix=""
                            useFastField={false}
                            showErrorMessage={false}
                          />
                        </InputWrapper>
                      </Row>
                    </Col>
                  </Row>
                  <TotalRow index={index} values={volumePrices} />
                </div>
              );
            })
          }
        />
      </Grid>
    </>
  );
};

export { VolumePricingContainer };
