import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/Header';
import { NavLink } from '../components/NavLink';
import { URLS } from '../constants';

const Main = styled.div`
  flex: 1;
`;

const InMonthLayout: React.FC = ({ children }) => (
  <>
    <Header>
      {location.pathname.includes(URLS.SIMULATION) ? (
        <NavLink to={location.pathname}>Simulation</NavLink>
      ) : (
        <NavLink to={URLS.IN_MONTH}>Supply map</NavLink>
      )}
    </Header>
    <Main>{children}</Main>
  </>
);

export { InMonthLayout };
