import * as React from 'react';
import { DateRangePicker as DatePicker, DateRangePickerShape } from 'react-dates';
import { Moment } from 'moment';
import { DatePickerStyle } from './DatePickerStyles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

type FocusProps = 'startDate' | 'endDate' | null;

interface DateProps {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface DateRangePickerProps extends Partial<DateRangePickerShape> {
  initialStartDate: Moment | null;
  initialEndDate: Moment | null;
  onChange: (value: DateProps) => void;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({
  initialStartDate,
  initialEndDate,
  onChange,
  ...rest
}) => {
  const [startDate, setStartDate] = React.useState<Moment | null>(initialStartDate);
  const [endDate, setEndDate] = React.useState<Moment | null>(initialEndDate);
  const [focusedInput, setFocusedInput] = React.useState<FocusProps>(null);

  return (
    <>
      <DatePickerStyle themeStyle="dark" focusedInput={focusedInput !== null} />
      <DatePicker
        isOutsideRange={() => false}
        minimumNights={0}
        startDatePlaceholderText="From"
        endDatePlaceholderText="To"
        startDateId="startDate"
        endDateId="endDate"
        displayFormat="DD/MM/YYYY"
        firstDayOfWeek={1}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={({ startDate, endDate }: DateProps) => {
          setStartDate(startDate);
          setEndDate(endDate);
          onChange({ startDate, endDate });
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: FocusProps) => {
          setFocusedInput(focusedInput);
        }}
        {...rest}
      />
    </>
  );
};

export { DateRangePicker };
