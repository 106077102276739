interface VesselCategory {
  shipName: string;
  Type: string;
  Category: string;
  imoNumber: string;
  mmsiNumber: number;
}

const vesselCategories: VesselCategory[] = [
  {
    shipName: 'Bit Hero',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9786645',
    mmsiNumber: 244150081,
  },
  {
    shipName: 'Bit Eco',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9786633',
    mmsiNumber: 244110414,
  },
  {
    shipName: 'Bitfjord',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9753844',
    mmsiNumber: 244090224,
  },
  {
    shipName: 'Bit Redo',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9439175',
    mmsiNumber: 245198000,
  },
  {
    shipName: 'Stella Maris',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9297101',
    mmsiNumber: 246315000,
  },
  {
    shipName: 'Stella Orion',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9265251',
    mmsiNumber: 246553000,
  },
  {
    shipName: 'Stella Virgo',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9265249',
    mmsiNumber: 244267000,
  },
  {
    shipName: 'Bitflower',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9263930',
    mmsiNumber: 266009000,
  },
  {
    shipName: 'Bithav',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9211535',
    mmsiNumber: 246697000,
  },
  {
    shipName: 'Stella Polaris',
    Type: 'Vessel',
    Category: 'Tarbit',
    imoNumber: '9187057',
    mmsiNumber: 245911000,
  },
  {
    shipName: 'Eiltank 1',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04014950',
    mmsiNumber: 211790480,
  },
  {
    shipName: 'Eiltank 2',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN02310027',
    mmsiNumber: 211202310,
  },
  {
    shipName: 'Eiltank 3',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04402090',
    mmsiNumber: 211516130,
  },
  {
    shipName: 'Eiltank 5',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04402100',
    mmsiNumber: 211516140,
  },
  {
    shipName: 'Eiltank 10',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04401310',
    mmsiNumber: 211486210,
  },
  {
    shipName: 'Eiltank 24',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04502720',
    mmsiNumber: 211490510,
  },
  {
    shipName: 'Eiltank 43',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04606090',
    mmsiNumber: 211511520,
  },
  {
    shipName: 'Eiltank 230',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN04029350',
    mmsiNumber: 211476020,
  },
  {
    shipName: 'Edgar Jaegers',
    Type: 'Barge',
    Category: 'Jaegers',
    imoNumber: 'EN02337557',
    mmsiNumber: 211129800,
  },
  {
    shipName: 'Ning Hai Wan',
    Type: 'Vessel',
    Category: 'Time Charter',
    imoNumber: '9578385',
    mmsiNumber: 371078000,
  },
  {
    shipName: 'Poestella',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9803235',
    mmsiNumber: 563008900,
  },
  {
    shipName: 'Seapower',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9546162',
    mmsiNumber: 248180000,
  },
  {
    shipName: 'San Beato',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9531765',
    mmsiNumber: 269032000,
  },
  {
    shipName: 'Bituma 1',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9159282',
    mmsiNumber: 355947000,
  },
  {
    shipName: 'Palanca Cadiz',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9748007',
    mmsiNumber: 538007108,
  },
  {
    shipName: 'The Deputy',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9287883',
    mmsiNumber: 256122000,
  },
  {
    shipName: 'Iver Agile',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9286437',
    mmsiNumber: 247103700,
  },
  {
    shipName: 'Iver Brilliant',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9616761',
    mmsiNumber: 244308000,
  },
  {
    shipName: 'The Prospector',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9492323',
    mmsiNumber: 538005016,
  },
  {
    shipName: 'Skypower',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9418963',
    mmsiNumber: 249703000,
  },
  {
    shipName: 'Starpower',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9546150',
    mmsiNumber: 249705000,
  },
  {
    shipName: 'Iver Action',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9507984',
    mmsiNumber: 236629000,
  },
  {
    shipName: 'Atlantic Asphalt',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9798428',
    mmsiNumber: 538007471,
  },
  {
    shipName: 'Asphalt Eagle',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9288382',
    mmsiNumber: 241055000,
  },
  {
    shipName: 'Black Shark',
    Type: 'Vessel',
    Category: 'Non-Contracted',
    imoNumber: '9480655',
    mmsiNumber: 247288900,
  },
];

export { vesselCategories };
