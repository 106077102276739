const colors = {
  primary: {
    a: '#F4B02D',
    b: '#1C73FF',
  },
  secondary: {
    light: {
      a: '#FF5951',
      b: '#13BC86',
      c: '#EBEBEB',
      d: '#0C0C0C',
    },
    dark: {
      a: '#FF9590',
      b: '#5AD0AA',
    },
  },
  base: {
    dark: {
      a: '#09101B',
      b: '#121720',
      c: '#282F39',
      d: '#303641',
      e: '#959595',
    },
    light: {
      a: '#FFFFFF',
      b: '#F8F8F8',
      c: '#EDEFF2',
      d: '#E3E7EC',
      e: '#64676b',
      f: '#E7E7E7',
    },
  },
  greys: {
    light: {
      border: '#AFAFB3',
      disabled: '#D3D3D5',
    },
    dark: {
      border: '#76808F',
      disabled: '#535B68',
    },
  },
  tertiary: {
    chart1: '#318483',
    chart2: '#4FB0C6',
    chart3: '#4F86C6',
    chart4: '#6C49B8',
    chart5: '#9B51E0',
    chart6: '#BB6BD9',
    chart7: '#D96BA0',
    chart8: '#D96B6B',
    chart9: '#0089FF',
  },
  errors: {
    red1: '#DB4052',
    red2: '#FF5951',
  },
};

const opacity = {
  1: 'FF',
  0.95: 'F2',
  0.9: 'E6',
  0.85: 'D9',
  0.8: 'CC',
  0.75: 'BF',
  0.7: 'B3',
  0.65: 'A6',
  0.6: '99',
  0.55: '8C',
  0.5: '80',
  0.45: '73',
  0.4: '66',
  0.35: '59',
  0.3: '4D',
  0.25: '40',
  0.2: '33',
  0.15: '26',
  0.1: '1A',
  0.05: '0D',
  0: '00',
};

const theme = {
  ...colors,
  opacity,
  containerWidth: '1124px',
  borderLightDashed: '1px dashed #C8C8C8',
  inputReadonlyBackground: '#E3E3E3',
  backgroundTertiary10: 'rgba(28, 115, 255, 0.1)',
  shadowTertiary: '0 4px 6px 0 rgba(28,115,255,0.39)',
  inputBackground: 'rgba(12, 12, 12, 0.2)',
  typography: {
    variants: {
      display1: {
        family: 'Avenir Next',
        size: 96,
        lineHeight: '131px',
      },
      display2: {
        family: 'Avenir Next',
        size: 88,
        lineHeight: '120px',
      },
      display3: {
        family: 'Avenir Next',
        size: 72,
        lineHeight: '72px',
      },
      display4: {
        family: 'Avenir Next',
        size: 56,
        lineHeight: '76px',
      },
      heading1: {
        family: 'Avenir Next',
        size: 40,
        lineHeight: '55px',
      },
      heading2: {
        family: 'Avenir Next',
        size: 32,
        lineHeight: '44px',
      },
      heading3: {
        family: 'Avenir Next',
        size: 28,
        lineHeight: '38px',
      },
      heading4: {
        family: 'Avenir Next',
        size: 24,
        lineHeight: '33px',
      },
      heading5: {
        family: 'Avenir Next',
        size: 22,
        lineHeight: '30px',
      },
      heading6: {
        family: 'Avenir Next',
        size: 18,
        lineHeight: '25px',
      },
      heading7: {
        family: 'Avenir Next',
        size: 16,
        lineHeight: '22px',
      },
      body1: {
        family: 'Avenir Next',
        size: 18,
        lineHeight: '25px',
      },
      body2: {
        family: 'Avenir Next',
        size: 16,
        lineHeight: '22px',
      },
      body3: {
        family: 'Avenir Next',
        size: 14,
        lineHeight: '19px',
      },
      body4: {
        family: 'Avenir Next',
        size: 14,
        lineHeight: '19px',
      },
      button: {
        family: 'Avenir Next',
        size: 16,
        lineHeight: '22px',
      },
      labels1: {
        family: 'Avenir Next',
        size: 12,
        lineHeight: '16px',
      },
      labels2: {
        family: 'Avenir Next',
        size: 12,
        lineHeight: '14.06px',
      },
    },
    colors: {
      hintLight: colors.greys.light.border,
      bodyLight: colors.base.dark.d,
      disabledBtnLight: colors.greys.dark.border,
      disabledBgLight: colors.greys.light.border,
      hintDark: colors.greys.dark.border,
      bodyDark: colors.secondary.light.c,
      disabledBtnDark: colors.greys.light.border,
      disabledBgDark: colors.greys.dark.border,

      textBlue: colors.tertiary.chart9,

      errorLight: colors.secondary.light.a,
      errorDark: colors.secondary.dark.a,
      validLight: colors.secondary.light.b,
      validDark: colors.secondary.dark.b,
      warning: colors.primary.a,
      highlight: colors.primary.b,
    },
  },
};

export const lgBreakPoint = 1366;
export const mdBreakPoint = 768;
export const smBreakPoint = 414;

export type FontVariants = keyof typeof theme.typography.variants;
export type FontColors = keyof typeof theme.typography.colors;
export type IThemeType = typeof theme;
export { theme };
