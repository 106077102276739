import { Formik, FormikValues } from 'formik';
import React, { useCallback, useContext, useRef } from 'react';
import { Modal } from '../../components/Modal';
import { DemandLocation } from '@scout/types';
import { DemandModalForm, FormValues } from './Form';
import { DemandModalLoader } from './Loader';
import { UserThemeContext } from '../../App';

export interface DemandModalProps {
  clusterId: string;
  isOpen: boolean;
  onSubmit: (values: FormikValues) => void;
  onCancel: () => void;
  ownerId: string;
  type: 'new' | 'edit';
  formValues?: Partial<FormValues>;
}

const DemandModal: React.FunctionComponent<DemandModalProps> = ({
  clusterId,
  isOpen,
  onSubmit,
  onCancel,
  ownerId,
  type,
  formValues,
}) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const formRef = useRef<Formik<FormValues>>(null);

  const handleConfirm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  return (
    <Modal
      confirmText={type === 'new' ? 'Continue' : 'Save'}
      isOpen={isOpen}
      minWidth={400}
      onConfirm={handleConfirm}
      onDismiss={onCancel}
      title={type === 'new' ? 'New spot demand' : 'Edit demand'}
      currentUserTheme={currentUserTheme}
    >
      <DemandModalLoader clusterId={clusterId} ownerId={ownerId}>
        {({ cluster }) => (
          <DemandModalForm
            customers={cluster?.user?.customers ?? []}
            demandLocation={cluster?.demandLocation ?? DemandLocation.Region}
            formRef={formRef}
            formValues={formValues}
            regions={cluster?.regions ?? []}
            onSubmit={onSubmit}
          />
        )}
      </DemandModalLoader>
    </Modal>
  );
};

export { DemandModal };
