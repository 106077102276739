import { NetworkStatus } from 'apollo-boost';
import { FormikValues } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DemandModal } from '../../../components/DemandModal';
import { Loader } from '../../../components/Loader';
import { SecondaryFilterOptionType } from '../../../components/SecondaryFilter';
import { SingleDropdown } from '../../../components/SelectDropdown/Single';
import { Table } from '../../../components/Table';
import { Typography } from '../../../components/Typography';
import { makeURL, URLS } from '../../../constants';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { MidTermLayout } from '../../../layouts';
import {
  GET_ALL_SPOT_PRICES,
  GET_AM_DASHBOARD_DATA,
  AllSpotPricesQuery,
  AllSpotPricesQueryVariables,
  DashboardDataQuery,
  DashboardDataQueryVariables,
  Facet,
} from '@scout/types';
import {
  getSelected,
  getSelectedOrFirst,
  removeViewPrefix,
  VIEW_CLUSTER_PREFIX,
  VIEW_USER_PREFIX,
  YEAR_PREFIX,
} from '../../../utils/facets';
import { createPricingVariables } from './createPricingVariables';
import { filterAndSortDemands } from './filterAndSortDemands';
import { getMonthlyTotals } from './getMonthlyTotals';
import { NoResultsPlaceholder } from './NoResultsPlaceholder';
import { normaliseContractDemands } from './normaliseContractDemands';
import { normaliseSpotDemands } from './normaliseSpotDemands';
import { SecondaryFilterBar } from './SecondaryFilterBar';
import { filterDates } from './spotDemandFilters';
import { TableBody } from './Table/TableBody';
import { TableHeader } from './Table/TableHeader';
import { DashboardRowType } from './types';
import { type } from 'os';
import { UserThemeContext } from '../../../App';
const TableContainer = styled.div`
  overflow: auto;
  position: absolute;
  top: 202px;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FilterLabel = styled(Typography).attrs(props => ({ color: props.currentUserTheme ? 'bodyLight' : 'bodyDark' }))<{
  currentUserTheme?: boolean;
}>`
  margin-left: 20px;
  margin-right: 10px;
  white-space: nowrap;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-start;
  align-items: center;
`;

const PrimaryFilterBar = styled(FilterWrapper)<{ currentUserTheme?: boolean }>`
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  border-bottom: 1px solid ${p => (p.currentUserTheme ? p.theme.base.light.d : p.theme.base.dark.d)};
  padding: 25px 0;
  height: 66px;
`;

const Content = styled(Typography)`
  max-width: 100px;
  margin-right: 15px;
`;

const Stat = styled.div`
  margin-left: 24px;
  display: flex;
  justify-content: row;
  padding-left: 17px;
  border-left: 1px solid ${props => props.theme.greys.dark.border};
`;

const goToCreateSpot = (
  clusterId: string,
  ownerId: string,
  year: string,
  values: FormikValues,
  history: RouteComponentProps['history'],
) => {
  const searchParams: Record<string, string> = {
    ownerId,
    year,
    customerCode: values.customerCode,
    customerId: values.customer,
    shipmentType: values.shipmentType,
    from: history.location.pathname + history.location.search,
  };

  if (values.shipTo) {
    searchParams.shipToId = values.shipTo;
  }

  if (values.region) {
    searchParams.regionId = values.region;
  }

  history.push({
    pathname: makeURL(URLS.MID_TERM_CREATE_SPOT, { clusterId }),
    search: '?' + new URLSearchParams(searchParams).toString(),
  });
};

type Props = RouteComponentProps<{ clusterId: string }>;

const Dashboard: React.FunctionComponent<Props> = ({ match }) => {
  const currentUser = useCurrentUser();
  const { currentUserTheme } = useContext(UserThemeContext);
  const history = useHistory();
  const currentCluster = currentUser.clusters.find(cluster => cluster.id === match.params.clusterId);
  const searchParams = new URLSearchParams(history.location.search);

  const dateFilter = searchParams.get('date');
  const yearFilter = searchParams.get('year');
  const viewFilter = searchParams.get('view');

  const soldToFilter: null | undefined | string = sessionStorage.getItem('soldTo');
  const plantFilter: null | undefined | string = sessionStorage.getItem('plant');
  const typeFilter: null | undefined | string = sessionStorage.getItem('type');

  const [showDemandModel, toggleDemandModel] = React.useState(false);
  const [topFilter, setTopFilter] = React.useState<{
    plant: SecondaryFilterOptionType[] | null | undefined;
    soldTo: SecondaryFilterOptionType[] | null | undefined;
    type: SecondaryFilterOptionType[] | null | undefined;
  }>({
    soldTo: !soldToFilter ? null : JSON.parse(soldToFilter),
    plant: !plantFilter ? null : JSON.parse(plantFilter),
    type: !typeFilter ? null : JSON.parse(typeFilter),
  });
  const handleUpdatePlant = useCallback(
    (plant: SecondaryFilterOptionType[] | null | undefined) => {
      setTopFilter(prev => ({ ...prev, plant }));
      sessionStorage.setItem('plant', JSON.stringify(plant));
    },
    [setTopFilter],
  );
  const handleUpdateSoldTo = useCallback(
    (soldTo: SecondaryFilterOptionType[] | null | undefined) => {
      setTopFilter(prev => ({ ...prev, soldTo }));
      sessionStorage.setItem('soldTo', JSON.stringify(soldTo));
    },
    [setTopFilter],
  );
  const handleUpdateType = useCallback(
    (type: SecondaryFilterOptionType[] | null | undefined) => {
      setTopFilter(prev => ({ ...prev, type }));
      sessionStorage.setItem('type', JSON.stringify(type));
    },
    [setTopFilter],
  );

  const dashboardResult = useQuery<DashboardDataQuery, DashboardDataQueryVariables>(GET_AM_DASHBOARD_DATA, {
    fetchPolicy: 'no-cache',
    variables: currentCluster
      ? {
          clusterId: currentCluster.id,
          clusterCurrency: currentCluster.currency,
          filters: [dateFilter, yearFilter, viewFilter].filter((x): x is string => x != null),
        }
      : undefined,
    skip: !currentCluster,
  });

  const dashboardData = dashboardResult.data;

  let spotPricingVariables: AllSpotPricesQueryVariables | undefined;
  let spotPricingMonths: Array<{ month: string; number: string }> | undefined;

  // We need to wait for the facets to come back from API as that's the source of
  // truth for what facets are selected. We can then prepare the variables
  // needed to retrieve spot prices.
  if (dashboardData != null && currentCluster != null) {
    const selectedDateFacet = getSelected(dashboardData.spotDemands.facets.date)!;
    const selectedYearFacet = getSelected(dashboardData.spotDemands.facets.year)!;
    spotPricingMonths = filterDates(selectedDateFacet.name);

    spotPricingVariables = createPricingVariables({
      spotPricingMonths,
      clusterId: currentCluster.id,
      dateFacet: selectedDateFacet,
      year: Number(selectedYearFacet.name.replace(YEAR_PREFIX, '')),
      spotDemands: dashboardData.spotDemands.results,
    });
  }

  // Skip this until the demands are retrieved
  const pricingRequest = useQuery<AllSpotPricesQuery, AllSpotPricesQueryVariables>(GET_ALL_SPOT_PRICES, {
    fetchPolicy: 'no-cache',
    skip: spotPricingVariables == null,
    variables: spotPricingVariables,
  });

  const handleOpenDemandModal = useCallback(() => toggleDemandModel(true), [toggleDemandModel]);

  const handleCancelDemandModal = useCallback(() => {
    toggleDemandModel(false);
  }, [toggleDemandModel]);

  const handleSubmitDemandModal = useCallback(
    (values: FormikValues) => {
      let ownerId = currentUser.id;
      let year = '';

      if (dashboardData != null) {
        // if we have data there will always be a selected "viewing as"
        const selectedViewer = getSelected(dashboardData.spotDemands.facets.view) as Facet;

        // Only want to get the ID from selected "viewing as" if not a cluster
        if (selectedViewer.id.startsWith(VIEW_USER_PREFIX)) {
          ownerId = removeViewPrefix(selectedViewer.id);
        }

        const selectedYear = getSelected(dashboardData.spotDemands.facets.year) as Facet;
        year = selectedYear.name;
      }

      goToCreateSpot(match.params.clusterId, ownerId, year, values, history);
    },
    [currentUser.id, dashboardData, match.params.clusterId, history],
  );

  let pricingData: AllSpotPricesQuery['allSpotPrices'];
  if (pricingRequest.error) {
    console.error('Pricing error: ', pricingRequest.error);
    pricingData = [];
  } else {
    pricingData = pricingRequest?.data?.allSpotPrices ?? [];
  }

  // This memo deals with preparing the API response and putting together demands
  // into one array. This is separated from the filtering of the demands so
  // that this only gets done when there is a new response from the API
  const { demands, selectedDateItem, selectedYearItem, selectedViewItem, selectedYearAsNumber } = useMemo(() => {
    if (currentCluster == null || dashboardData == null) {
      return {
        demands: [] as DashboardRowType[],
        selectedDateItem: null,
        selectedYearItem: null,
        selectedViewItem: null,
        selectedYearAsNumber: null,
      };
    }

    const selectedDateItem = getSelectedOrFirst(dashboardData.spotDemands.facets.date);
    const selectedYearItem = getSelectedOrFirst(dashboardData.spotDemands.facets.year);
    const selectedViewItem = getSelectedOrFirst(dashboardData.spotDemands.facets.view);
    const selectedYearAsNumber = Number(selectedYearItem.name.replace(YEAR_PREFIX, ''));

    const demands = [
      ...normaliseContractDemands({
        currentClusterId: currentCluster.id,
        cluster: dashboardData?.cluster,
        clusterCurrency: currentCluster.currency,
        dates: spotPricingMonths!,
      }),
      ...normaliseSpotDemands({
        pricingData,
        clusterCurrency: currentCluster.currency,
        currentClusterId: currentCluster.id,
        dates: spotPricingMonths!,
        demands: dashboardData?.spotDemands.results,
        year: selectedYearAsNumber,
      }),
    ];

    return {
      demands,
      selectedDateItem,
      selectedYearItem,
      selectedViewItem,
      selectedYearAsNumber,
    };
  }, [currentCluster, dashboardData, pricingData]);

  // This memo deals with the cilent-side filtering of the demands from
  // the API using the secondary filters. Splitting these cuts down
  // thte amount of work needed when filtering
  const filteredDemands = useMemo(() => {
    if (
      selectedDateItem == null ||
      selectedYearItem == null ||
      selectedViewItem == null ||
      selectedYearAsNumber == null
    ) {
      return [] as DashboardRowType[];
    }

    const filteredDemands = filterAndSortDemands({
      demands,
      filters: {
        demandType: topFilter.type?.map(type => type.value),
        plant: topFilter.plant?.map(plant => plant.value),
        soldTo: topFilter.soldTo?.map(soldTo => soldTo.value),
      },
    });

    return filteredDemands;
  }, [demands, selectedDateItem, selectedYearItem, selectedViewItem, selectedYearAsNumber, pricingData, topFilter]);

  if (currentCluster == null) {
    return <Redirect to={URLS.MID_TERM} />;
  }

  if (
    (dashboardResult.loading && dashboardResult.networkStatus === NetworkStatus.loading) ||
    dashboardResult.error ||
    dashboardData == null ||
    selectedDateItem == null ||
    selectedYearItem == null ||
    selectedViewItem == null ||
    selectedYearAsNumber == null
  ) {
    return <Loader error={dashboardResult.error && 'Something went wrong'} />;
  }

  const yearlyVolumeTotal = demands
    .filter(demand => {
      if (moment(demand.monthlyForecasts[0].date).format('YYYY') === selectedYearItem.name) {
        return demand;
      }
    })
    .reduce((acc, cur) => acc + cur.totalVolume, 0);

  const volumeTotal = Math.round(
    filteredDemands
      .filter(demand => {
        if (moment(demand.monthlyForecasts[0].date).format('YYYY') === selectedYearItem.name) {
          return demand;
        }
      })
      .reduce((acc, cur) => acc + cur.totalVolume, 0),
  );

  const monthlyVolumeTotals = getMonthlyTotals({
    demands: filteredDemands,
    dates: spotPricingMonths ?? [],
    selectedYear: selectedYearAsNumber,
  });

  // Stop Create Spot Demand being created when a cluster or a previous year is selected
  const isNewDemandDisabled =
    selectedViewItem.id.startsWith(VIEW_CLUSTER_PREFIX) || selectedYearAsNumber < moment.utc().get('year');

  return (
    <MidTermLayout>
      {showDemandModel && (
        <DemandModal
          clusterId={match.params.clusterId}
          isOpen={showDemandModel}
          onSubmit={handleSubmitDemandModal}
          onCancel={handleCancelDemandModal}
          ownerId={removeViewPrefix(selectedViewItem.id)}
          type="new"
        />
      )}
      <PrimaryFilterBar data-testid="filter-primary-bar" currentUserTheme={currentUserTheme}>
        <FilterLabel currentUserTheme={currentUserTheme}>Viewing as:</FilterLabel>
        <SingleDropdown
          isClearable={false}
          width="200px"
          name="view"
          placeholder=""
          onItemClick={item => {
            item.value &&
              history.push({
                ...history.location,
                search: new URLSearchParams({
                  date: selectedDateItem.id,
                  view: item.value,
                  year: selectedYearItem.id,
                }).toString(),
              });
          }}
          selected={selectedViewItem.id}
          items={dashboardData.spotDemands.facets.view.map(view => ({
            label: view.name,
            value: view.id,
          }))}
          darkTheme={currentUserTheme ? false : true}
          showValidation={false}
          showClearIndicator={false}
        />
        <FilterLabel currentUserTheme={currentUserTheme}>Year:</FilterLabel>
        <SingleDropdown
          isClearable={false}
          width="100px"
          name="year"
          placeholder=""
          onItemClick={item => {
            item.value &&
              history.push({
                ...history.location,
                search: new URLSearchParams({
                  date: selectedDateItem.id,
                  year: item.value,
                  view: selectedViewItem.id,
                }).toString(),
              });
          }}
          selected={selectedYearItem.id}
          items={dashboardData.spotDemands.facets.year.map(year => ({
            label: year.name,
            value: year.id,
          }))}
          darkTheme={currentUserTheme ? false : true}
          showValidation={false}
          showClearIndicator={false}
        />
        <FilterLabel currentUserTheme={currentUserTheme}>Date:</FilterLabel>
        <SingleDropdown
          width="150px"
          name="date"
          placeholder=""
          onItemClick={item => {
            item.value &&
              history.push({
                ...history.location,
                search: new URLSearchParams({
                  date: item.value,
                  view: selectedViewItem.id,
                  year: selectedYearItem.id,
                }).toString(),
              });
          }}
          selected={selectedDateItem.id}
          items={dashboardData.spotDemands.facets.date.map(date => ({
            label: date.name,
            value: date.id,
          }))}
          darkTheme={currentUserTheme ? false : true}
          showValidation={false}
          showClearIndicator={false}
          isClearable={false}
        />

        <Stat>
          <Content variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
            Full year <br /> SDF total:
          </Content>
          <div>
            <Typography variant="labels1" color="hintDark" tag="label">
              Forecast volume
            </Typography>
            <Typography variant="heading5" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} tag="span" bold={true}>
              {Math.round(yearlyVolumeTotal / 1000)}Kt
            </Typography>
          </div>
        </Stat>
      </PrimaryFilterBar>
      <SecondaryFilterBar
        demands={demands}
        fuelOilQuoteAverageDate={dashboardData.fuelOilQuote.date}
        fuelOilQuoteAveragePrice={dashboardData.fuelOilQuote.avgPrice}
        isNewDemandDisabled={isNewDemandDisabled}
        itemCount={filteredDemands.length}
        onNewSpotDemandClick={handleOpenDemandModal}
        plant={topFilter.plant}
        soldTo={topFilter.soldTo}
        type={topFilter.type}
        updatePlant={handleUpdatePlant}
        updateSoldTo={handleUpdateSoldTo}
        updateType={handleUpdateType}
        currentUserTheme={currentUserTheme}
      />

      {dashboardResult.networkStatus === NetworkStatus.setVariables ? (
        <Loader />
      ) : (
        <TableContainer>
          <Table data-testid="am-dashboard-table">
            <TableHeader
              isUKIrelandCluster={currentCluster.isUKIrelandCluster}
              filteredDates={spotPricingMonths ?? []}
              year={selectedYearAsNumber}
              currentUserTheme={currentUserTheme}
            />
            <TableBody
              clusterId={match.params.clusterId}
              year={selectedYearAsNumber}
              emptyComponent={
                <NoResultsPlaceholder
                  isNewDemandDisabled={isNewDemandDisabled}
                  onShowDemandModal={handleOpenDemandModal}
                  currentUserTheme={currentUserTheme}
                />
              }
              isUKIrelandCluster={currentCluster.isUKIrelandCluster}
              tableRows={filteredDemands}
              monthlyVolumeTotals={monthlyVolumeTotals}
              volumeTotal={volumeTotal}
              currentUserTheme={currentUserTheme}
            />
          </Table>
        </TableContainer>
      )}
    </MidTermLayout>
  );
};

export { Dashboard };
