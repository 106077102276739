import React, { CSSProperties, useCallback } from 'react';
import Select, {
  IndicatorProps,
  OptionsType,
  OptionTypeBase,
  Props as SelectProps,
  SingleValueProps,
} from 'react-select';
import styled from 'styled-components';
import { CloseIcon } from '../Icons/CloseIcon';
import { fontMixin } from '../Mixins';
import { Option } from '../SelectDropdown/Single/Option';
import { Typography } from '../Typography';

const ClearButton = styled.div`
  cursor: pointer;
  padding: 4px 7px 4px 5px;
  background: #2e5ff6;
  border-radius: 0 15px 15px 0;
`;

const CloseIconWrapper = styled.div`
  width: 20px;
`;

const ClearIndicator = ({ innerProps: { ref, ...restInnerProps } }: IndicatorProps<OptionTypeBase>) => (
  <ClearButton {...restInnerProps} ref={ref}>
    <CloseIconWrapper>
      <CloseIcon />
    </CloseIconWrapper>
  </ClearButton>
);

const SelectedState = styled(Typography)`
  background: ${p => p.theme.primary.b};
  border-radius: 15px 0 0 15px;
  border: none;
  color: ${p => p.theme.secondary.light.c};
  cursor: pointer;
  font-weight: 600;
  margin: 0;
  max-width: 269px;
  overflow: hidden;
  padding: 8px 10px 8px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SingleValue = (props: SingleValueProps<OptionTypeBase>) => {
  return (
    <SelectedState variant="labels1" title={props.data.label} color="bodyDark">
      {props.data.label}
    </SelectedState>
  );
};

export interface SecondaryFilterOptionType extends OptionTypeBase {
  id?: string;
  label: string;
  tag?: string;
  value: string;
}

interface Props {
  id?: string;
  name?: string;
  onChange: (value: SecondaryFilterOptionType[] | null | undefined) => void;
  options: OptionsType<SecondaryFilterOptionType>;
  placeholder: SelectProps<SecondaryFilterOptionType>['placeholder'];
  value: SelectProps<SecondaryFilterOptionType>['value'];
  width?: CSSProperties['width'];
  currentUserTheme?: boolean;
}

const StyledSelect = styled(Select)<Select<SecondaryFilterOptionType> & Props>`
  & .${p => p.classNamePrefix}__control {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  & .${p => p.classNamePrefix}__indicator-separator {
    display: none;
  }

  & .${p => p.classNamePrefix}__menu {
    background: ${props => (props.currentUserTheme ? props.theme.base.light.a : props.theme.base.dark.c)};
    color: ${p => (p.currentUserTheme ? p.theme.base.dark.a : p.theme.secondary.light.c)};
    margin: 0;
    width: ${({ width }) => width ?? '145px'};
    z-index: 10;
  }

  & .${p => p.classNamePrefix}__option {
    ${fontMixin}

    border-radius: 2px;
    color: ${p => (p.currentUserTheme ? p.theme.base.dark.a : p.theme.secondary.light.c)};
    display: flex;
    align-items: center;
  }

  & .${p => p.classNamePrefix}__option--is-selected {
    background-color: ${p => p.theme.primary.b};

    p {
      color: ${p => p.theme.secondary.light.c};
    }
  }

  & .${p => p.classNamePrefix}__option--is-focused {
    background-color: ${p => p.theme.primary.b};
    p {
      color: ${p => p.theme.secondary.light.c};
    }
  }

  & .${p => p.classNamePrefix}__value-container {
    padding: 0;
    position: initial;
    & > div {
      margin: 0;
    }
  }

  & .${div => div.classNamePrefix}__multi-value {
    background: ${p => p.theme.primary.b};
    border-radius: 15px 15px 15px 15px;
    border: none;
    color: ${p => p.theme.secondary.light.c};
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    max-width: 200px;
    overflow: hidden;
    padding: 2px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    & > div {
      margin: 0;
    }
  }

  & .${div => div.classNamePrefix}__multi-value__label {
    background: ${p => p.theme.primary.b};
    border-radius: 15px 15px 15px 15px;
    border: none;
    color: ${p => p.theme.secondary.light.c};
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    max-width: 200px;
    overflow: hidden;
    padding: 2px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    & > div {
      margin: 0;
    }
  }

  & .${div => div.classNamePrefix}__multi-value__remove:hover {
    cursor: pointer;
    padding: 4px 7px 4px 5px;
    background: #2e5ff6;
    color: ${p => p.theme.secondary.light.c};
    border-radius: 15px 15px 15px 15px;
    & > div {
      margin: 0;
    }
  }

  & .${p => p.classNamePrefix}__input {
    padding: 0;
    margin: 0;

    & > input {
      width: 0 !important;
    }
  }

  & .${p => p.classNamePrefix}__placeholder {
    border-radius: 15px;
    border: 1px solid ${p => (p.currentUserTheme ? p.theme.base.dark.a : p.theme.secondary.light.c)};
    color: ${p => (p.currentUserTheme ? p.theme.base.dark.a : p.theme.secondary.light.c)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 8px;
    position: relative;
    transform: none;
  }
`;

export const SecondaryFilter = ({
  id,
  name,
  onChange,
  options,
  placeholder,
  value,
  width,
  currentUserTheme,
}: Props) => {
  const handleChange = useCallback(
    (value: SecondaryFilterOptionType[] | null | undefined) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <StyledSelect
      classNamePrefix="secondary-filter"
      components={{
        ClearIndicator,
        Option,
        SingleValue,
        DropdownIndicator: () => null,
      }}
      darkTheme={currentUserTheme ? false : true}
      id={id}
      isClearable={false}
      isMulti={true}
      isMenuOpen={true}
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      value={value}
      width={width}
      currentUserTheme={currentUserTheme}
    />
  );
};
