import React, { useContext, useEffect, useState } from 'react';
import { StocksSidebar } from './Stocks/StocksSidebar';
import { Mapbox, MarkerType, MapProps } from '../../components/Map/Mapbox';
import { Drawer } from '../../components/Drawer/Drawer';
import { Scheduler } from './Scheduler/Scheduler';
import { UpdateUserThemeMutation, UpdateUserThemeMutationVariables, UPDATEUSERTHEME } from '@scout/types';
import { useMutation } from 'react-apollo';
import { UserThemeContext } from '../../App';

export type InMonthMapProps = Pick<MapProps, 'center' | 'markers' | 'vessels'>;

const Map: React.FC<InMonthMapProps> = ({ center, markers, vessels }) => {
  const [selectedPlant, setSelectedPlant] = useState<MarkerType | null>(null);
  const [fullViewActive, setFullViewActive] = useState<boolean>(false);
  const toggleFullHalfView = (value: boolean) => {
    setFullViewActive(value);
  };
  const { currentUserTheme, setCurrentUserTheme } = useContext(UserThemeContext);

  const [updateUserTheme, setUpdateUserTheme] = useMutation<UpdateUserThemeMutation, UpdateUserThemeMutationVariables>(
    UPDATEUSERTHEME,
  );
  const toggleTheme = () => {
    setCurrentUserTheme(!currentUserTheme);
  };
  return (
    <Drawer
      drawerContent={closeDrawer => (
        <StocksSidebar
          onCloseRequest={() => {
            closeDrawer();
            setSelectedPlant(null);
          }}
          plant={selectedPlant}
          currentUserTheme={currentUserTheme}
        />
      )}
    >
      {openRightHandDrawer => (
        <Drawer
          drawerContent={closeDrawer => (
            <Scheduler
              onCloseRequest={closeDrawer}
              toggleFullHalfView={toggleFullHalfView}
              fullViewActive={fullViewActive}
              currentUserTheme={currentUserTheme}
              toggleTheme={toggleTheme}
            />
          )}
          side="left"
          isFullScreen={fullViewActive}
          isOverlay={false}
        >
          {(openLeftHandDrawer, drawerIsOpened) => (
            <Mapbox
              center={center}
              markers={markers}
              vessels={vessels}
              selectPlant={plant => {
                openRightHandDrawer();
                setSelectedPlant(plant);
              }}
              selectedPlant={selectedPlant}
              openScheduler={openLeftHandDrawer}
              schedulerIsOpened={drawerIsOpened}
              fullViewActive={fullViewActive}
              currentUserTheme={currentUserTheme}
            />
          )}
        </Drawer>
      )}
    </Drawer>
  );
};

export { Map };
