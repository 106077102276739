import { FormikProps } from 'formik';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Col, Grid, Row } from '../../../../components/Grid';
import { Typography } from '../../../../components/Typography';
import { SpotFormValuesProps } from '../Form';
import { PlantOptions } from '../types';
import { Cluster } from '../../../../types';
import { GradeInput } from './GradeInputs';
import { PlantsSelection } from './PlantsSelection';
import { getUsedGrades } from './utils';

const Divider = styled.hr`
  border: 0.5px solid ${props => props.theme.greys.light.border};
  margin: 20px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightAligner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
`;

const SuffixText = styled(Typography)`
  margin-left: 5px;
`;

const Container = styled.div<{ border?: boolean }>`
  position: relative;
  padding: 0 40px;
  border-right: ${props => (props.border ? `1px dashed ${props.theme.greys.light.border}` : '')};
`;

const PercentageTotal = styled(Typography)`
  margin-bottom: 20px;
`;

const Heading = styled(Typography)`
  margin-bottom: 8px;
`;

interface GradePlantContainerProps {
  isActive: boolean;
  formProps: FormikProps<SpotFormValuesProps>;
  shipmentType: string;
  premiumPlants: PlantOptions[];
  penPlants: PlantOptions[];
  cluster: Cluster;
  initialValues?: SpotFormValuesProps;
  regionId?: string;
  customerId?: string;
  shipToId?: string;
}

const PercentageSuffix = () => (
  <SuffixText variant="body3" color="hintLight" tag="span" inline={true}>
    %
  </SuffixText>
);

export const sortPlants = (plants: PlantOptions[]) => {
  return plants.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
};

const GradePlantContainer: React.FunctionComponent<GradePlantContainerProps> = ({
  isActive,
  formProps,
  premiumPlants,
  penPlants,
  shipmentType,
  cluster,
  ...props
}) => {
  const hardGradeTotal = useMemo(() => {
    let total = 0;
    for (const key in formProps.values.grades.hard) {
      if (formProps.values.grades.hard.hasOwnProperty(key)) {
        total += Number(formProps.values.grades.hard[key].percentage);
      }
    }

    return total;
  }, [formProps.values.grades.hard]);

  const softGradeTotal = useMemo(() => {
    let total = 0;
    for (const key in formProps.values.grades.soft) {
      if (formProps.values.grades.soft.hasOwnProperty(key)) {
        total += Number(formProps.values.grades.soft[key].percentage);
      }
    }

    return total;
  }, [formProps.values.grades.soft]);

  const premiumGradeTotal = useMemo(() => {
    let total = 0;
    for (const key in formProps.values.grades.premium) {
      if (formProps.values.grades.premium.hasOwnProperty(key)) {
        total += Number(formProps.values.grades.premium[key].percentage);
      }
    }

    return total;
  }, [formProps.values.grades.premium]);

  useEffect(() => {
    const totalGrades = hardGradeTotal + softGradeTotal + premiumGradeTotal;

    if (totalGrades !== 100) {
      formProps.setFieldValue('penPlantPrimary', '');
      formProps.setFieldValue('penPlantSecondary', '');
      formProps.setFieldValue('premiumPlantPrimary', '');
      formProps.setFieldValue('premiumPlantSecondary', '');
    }

    formProps.setFieldValue('totalGrades', totalGrades);
  }, [hardGradeTotal, softGradeTotal, premiumGradeTotal, formProps.values.grades, formProps.setFieldValue]);

  const [selectedPenGrades, selectedPremiumGrades] = useMemo(() => {
    const selectedPenGrades = getUsedGrades({
      ...formProps.values.grades.hard,
      ...formProps.values.grades.soft,
    });

    const selectedPremiumGrades = getUsedGrades(formProps.values.grades.premium);
    return [selectedPenGrades, selectedPremiumGrades];
  }, [formProps.values.grades]);

  if (!isActive) {
    return null;
  }

  return (
    <Grid>
      <Row>
        <Col size={6}>
          <Container border={true}>
            <Heading bold={true} variant="heading5">
              Step 1. Enter your grade split
            </Heading>
            <Typography variant="body3">
              Complete your full grade split before selecting your preferred plants.
            </Typography>

            <RightAligner>
              <Typography variant="body3">Total</Typography>
              <Typography variant="heading6" bold={true}>
                {formProps.values.totalGrades}
                <PercentageSuffix />
              </Typography>

              {formProps.submitCount > 0 && (
                <Typography variant="body3" color="errorLight">
                  {formProps.errors.totalGrades}
                </Typography>
              )}
            </RightAligner>
            <Divider />
            <Header>
              <Typography variant="heading6" bold={true}>
                Hard pen
              </Typography>
              <PercentageTotal variant="heading6" bold={true}>
                {hardGradeTotal}
                <PercentageSuffix />
              </PercentageTotal>
            </Header>

            {Object.keys(formProps.values.grades.hard)
              .map(key => ({ name: `hard.${key}`, label: formProps.values.grades.hard[key].name }))
              .map((item: { name: string; label: string }, index: number) => (
                <GradeInput data-testid={`grade-hard-${index}`} key={`grade-hard-${index}`} item={item} />
              ))}
            <Divider />
            <Header>
              <Typography variant="heading6" bold={true}>
                Soft pen
              </Typography>
              <PercentageTotal variant="heading6" bold={true}>
                {softGradeTotal}
                <PercentageSuffix />
              </PercentageTotal>
            </Header>
            {Object.keys(formProps.values.grades.soft)
              .map(key => ({ name: `soft.${key}`, label: formProps.values.grades.soft[key].name }))
              .map((item: { name: string; label: string }, index: number) => (
                <GradeInput data-testid={`grade-soft-${index}`} key={`grade-soft-${index}`} item={item} />
              ))}

            <Divider />
            {Object.keys(formProps.values.grades.premium).length > 0 && (
              <Header>
                <Typography variant="heading6" bold={true}>
                  Premium
                </Typography>
                <PercentageTotal variant="heading6" bold={true}>
                  {premiumGradeTotal}
                  <PercentageSuffix />
                </PercentageTotal>
              </Header>
            )}

            {Object.keys(formProps.values.grades.premium)
              .map(key => ({ name: `premium.${key}`, label: formProps.values.grades.premium[key].name }))
              .map((item: { name: string; label: string }, index: number) => (
                <GradeInput key={`grade-premium-${index}`} item={item} />
              ))}
          </Container>
        </Col>
        <Col size={6}>
          <Container>
            <PlantsSelection
              values={formProps.values}
              shipmentType={shipmentType}
              penPlants={sortPlants(penPlants)}
              premiumPlants={sortPlants(premiumPlants)}
              selectedPenGrades={selectedPenGrades}
              selectedPremiumGrades={selectedPremiumGrades}
              onBlur={(name: string) => formProps.setFieldTouched(name, true)}
              onChange={(name, value) => formProps.setFieldValue(name, value)}
              disablePlants={formProps.values.totalGrades !== 100}
              errors={formProps.submitCount > 0 ? formProps.errors : undefined}
              touched={formProps.touched}
              cluster={cluster}
              regionId={props.regionId}
              customerId={props.customerId}
              shipToId={props.shipToId}
            />
          </Container>
        </Col>
      </Row>
    </Grid>
  );
};

export { GradePlantContainer };
