import React, { ReactElement } from 'react';
import { useQuery } from 'react-apollo';
import { GET_DEMAND_MODAL_DATA, DemandModalDataQuery, DemandModalDataQueryVariables } from '@scout/types';
import { Loader } from '../Loader';

interface Props {
  children: (data: DemandModalDataQuery) => ReactElement;
  clusterId: string;
  ownerId: string;
}

const DemandModalLoader: React.FC<Props> = ({ children, clusterId, ownerId }) => {
  const { loading, data, error } = useQuery<DemandModalDataQuery, DemandModalDataQueryVariables>(
    GET_DEMAND_MODAL_DATA,
    {
      variables: {
        clusterId,
        userId: ownerId,
      },
    },
  );

  if (loading || error || data == null || data.cluster == null) {
    return <Loader type="section" error={error ? 'Something went wrong' : undefined} />;
  }

  return children(data);
};

export { DemandModalLoader };
