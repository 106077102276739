import { RegionModel, ShipmentType, ShippingLocation } from '@scout/types';

export enum CreateContractDemandStep {
  Demands,
  GradeSplit,
  MonthlyVolumes,
}

export interface DemandsStepVolumeRow {
  id: string;
  isFixed?: boolean;
  plant?: string;
  location?: string;
  volume?: string;
}

export interface HeaderValues {
  shipmentType: ShipmentType;
}

/**
 * Used internally by Demands step to represent form structure
 */
export interface DemandsStepValues {
  penRows: DemandsStepVolumeRow[];
  premiumRows: DemandsStepVolumeRow[];
}

/**
 * Used internally by Grade Split step to represent form structure
 */

export interface GradeSplitStepGrade {
  id: string;
  gradeId: string;
  gradeName: string;
  percentage: string;
}

export interface GradeSplitStepDemandRow {
  id: string;
  hardGrades: GradeSplitStepGrade[];
  premiumGrades: GradeSplitStepGrade[];
  softGrades: GradeSplitStepGrade[];
  nonBitumenGrades: GradeSplitStepGrade[];
  plantName: string;
  locationName: string;
}

export interface GradeSplitStepValues {
  penRows: GradeSplitStepDemandRow[];
  premiumRows: GradeSplitStepDemandRow[];
}

export interface GradeSplitStepDemandRow {}

export enum Month {
  jan = 'jan',
  feb = 'feb',
  mar = 'mar',
  apr = 'apr',
  may = 'may',
  jun = 'jun',
  jul = 'jul',
  aug = 'aug',
  sep = 'sep',
  oct = 'oct',
  nov = 'nov',
  dec = 'dec',
}

export interface MonthlyVolumesStepValues {
  rows: MonthlyVolumesStepValuesRow[];
}

export interface MonthlyVolumesStepValuesRow {
  id: string;
  /**
   * Represents whether the row values can be edited.
   */
  isEditable?: boolean;
  plantName: string;
  location: string;
  targetTotal: number;
  volumes: { [month in Month]: string };
  type?: string;
}

export type VolumeRowName = keyof Pick<DemandsStepValues, 'penRows' | 'premiumRows'>;

export type CustomerItem = Pick<ShippingLocation, 'id' | 'code' | 'name' | 'salesOrganisation'>;
export interface PlantItem {
  id: string;
  name: string;
}
export type RegionItem = Pick<RegionModel, 'id' | 'name'>;
