import { Cluster, ClusterNames, VolumePricingItem } from '../../../../types';
import { Distribution } from '../types';

const seasonalityBenefrux = [0.02, 0.05, 0.08, 0.1, 0.09, 0.11, 0.09, 0.07, 0.12, 0.12, 0.09, 0.06];
const seasonalityDach = [0.01, 0.02, 0.02, 0.1, 0.11, 0.11, 0.11, 0.11, 0.13, 0.12, 0.12, 0.04];

const getSeasonalDistribution = ({
  cluster,
  fromDate,
  toDate,
  volume,
  volumeItems,
}: {
  cluster: Cluster;
  fromDate: number;
  toDate: number;
  volume: number;
  volumeItems: VolumePricingItem[];
}) => {
  const seasonality = cluster.name === ClusterNames.Benefrux ? seasonalityBenefrux : seasonalityDach;
  // Represents how much distribution is used in case some months are missing
  let distributionUsed: number = 0;

  const foundItems = volumeItems.reduce<VolumePricingItem[]>((previousValue, currentValue) => {
    const found = Date.parse(currentValue.date) >= fromDate && Date.parse(currentValue.date) <= toDate;

    if (!found) {
      return previousValue;
    }

    const month = new Date(currentValue.date).getMonth();
    distributionUsed += seasonality[month];

    return previousValue.concat(currentValue);
  }, []);

  return volumeItems.map((item, index) => {
    if (!foundItems.includes(item)) {
      return null;
    }

    const month = new Date(item.date).getMonth();
    // If months are missing this will refactor the ratio based on what is used
    const seasonalityForMonth = seasonality[month] / distributionUsed;
    const value = Math.round(volume * seasonalityForMonth).toString();

    return {
      index,
      value,
    };
  });
};

const getEvenDistribution = ({
  fromDate,
  toDate,
  volume,
  volumeItems,
}: {
  fromDate: number;
  toDate: number;
  volume: number;
  volumeItems: VolumePricingItem[];
}) => {
  const foundItems = volumeItems.filter(
    volumeItem => Date.parse(volumeItem.date) >= fromDate && Date.parse(volumeItem.date) <= toDate,
  );

  return volumeItems.map((item, index) => {
    if (!foundItems.includes(item)) {
      return null;
    }

    const value = Math.round(volume / foundItems.length).toString();

    return {
      index,
      value,
    };
  });
};

const getVolumeDistribution = ({
  fromString,
  toString,
  volume,
  volumeItems,
  distribution,
  cluster,
}: {
  fromString: string;
  toString: string;
  volume: number;
  volumeItems: VolumePricingItem[];
  distribution: Distribution;
  cluster: Cluster;
}): Array<{ index: number; value: string } | null> => {
  const fromDate = Date.parse(fromString);
  const toDate = Date.parse(toString);

  return distribution === Distribution.Seasonal
    ? getSeasonalDistribution({
        cluster,
        fromDate,
        toDate,
        volume,
        volumeItems,
      })
    : getEvenDistribution({
        fromDate,
        toDate,
        volume,
        volumeItems,
      });
};

export { getVolumeDistribution };
