import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '../Typography';

interface SelectProps {
  children: React.ReactNode;
  items: Array<{ id: string; label: string }>;
  onItemClick: (id: string) => void;
  selected: string;
  isEast?: boolean;
  currentUserTheme?: boolean;
  spotForm?: boolean;
  pricing?: boolean;
}

const List = styled.ul<{ east?: boolean; currentUserTheme?: boolean; spotForm?: boolean }>`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.east
      ? props.currentUserTheme
        ? props.theme.base.light.b
        : props.theme.base.dark.d
      : props.currentUserTheme && props.spotForm
      ? props.theme.secondary.light.c
      : props.theme.base.dark.c};
`;

const ListItem = styled.li<{
  selected: boolean;
  east: boolean;
  eastStock: boolean;
  currentUserTheme?: boolean;
  pricing?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom:${props =>
    props.selected && props.east
      ? `1.5px solid ${props.theme.tertiary.chart9}`
      : `1.5px solid ${props.theme.greys.dark.border}`} ;
  background-color: ${props =>
    !props.east
      ? props.selected
        ? props.theme.base.light.b
        : props.theme.base.dark.b
      : props.eastStock
      ? props.selected
        ? props.currentUserTheme
          ? props.theme.base.light.a
          : props.theme.base.dark.a
        : props.currentUserTheme
        ? props.theme.base.light.a
        : props.theme.base.dark.b
      : props.currentUserTheme
      ? props.theme.base.light.b
      : props.selected
      ? props.theme.base.dark.d
      : props.theme.base.dark.d};
  cursor: pointer;
  width: ${props => (props.eastStock ? '200px' : props.pricing ? '281px' : '375px')}
  height: 59px;
  text-align: center;
  font-size: 0.87rem;
  border-radius: 2px 2px 0 0;
  height: ${props => (props.selected ? '55px' : '55px')};
  &:not(:last-child) {
    margin-right: ${props => (props.selected ? 0 : '2px')};
  }
`;

const Content = styled.div`
  background: ${props => props.theme.base.light.b};
  color: ${props => props.theme.base.dark.d};
`;

const Tabs: React.FunctionComponent<SelectProps> = ({
  items,
  onItemClick,
  selected,
  children,
  isEast,
  currentUserTheme,
  spotForm,
  pricing,
}) => (
  <>
    <List data-testid="tab-control" east={isEast} currentUserTheme={currentUserTheme} spotForm={spotForm}>
      {items.map(item => (
        <ListItem
          data-testid={`tab-${item.id}`}
          key={`tab-${item.id}`}
          onClick={() => onItemClick(item.id)}
          selected={selected === item.id}
          east={
            item.id === 'easttanks' ||
            item.id === 'eastproducts' ||
            item.id === 'Production' ||
            item.id === 'Lifting' ||
            item.id === 'Event' ||
            item.id === 'Sale' ||
            item.id === 'Replenishment' ||
            item.id === 'Standard Report' ||
            item.id === 'Custom Report'
          }
          eastStock={item.id === 'easttanks' || item.id === 'eastproducts'}
          currentUserTheme={currentUserTheme}
          pricing={pricing}
        >
          <Typography
            bold={true}
            variant="heading6"
            color={
              currentUserTheme && selected === item.id
                ? 'textBlue'
                : currentUserTheme
                ? spotForm
                  ? 'bodyDark'
                  : 'bodyLight'
                : item.id === 'easttanks' ||
                  item.id === 'eastproducts' ||
                  item.id === 'Production' ||
                  item.id === 'Lifting' ||
                  item.id === 'Event' ||
                  item.id === 'Sale' ||
                  item.id === 'Replenishment' ||
                  item.id === 'Standard Report' ||
                  item.id === 'Custom Report'
                ? 'bodyDark'
                : selected === item.id
                ? 'bodyLight'
                : 'bodyDark'
            }
          >
            {item.label}
          </Typography>
        </ListItem>
      ))}
    </List>
    <Content>{children}</Content>
  </>
);

export { Tabs };
