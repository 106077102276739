import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { DateRangePicker } from '../../../../components/DateRangePicker';
import { Modal } from '../../../../components/Modal';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { TextArea } from '../../../../components/TextArea';
import { Typography } from '../../../../components/Typography';
import { LocationNotesQueryResult, LocationNoteType } from '@scout/types';
import { filterByLabel } from '../../../../utils';
import { getLocationItems, getNoteTypeItems } from '../utils';
import { useDeleteLocationNote, useLoadLocationData, useUpsertLocationNote } from './hooks';

const Spacer = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${p => p.size || '28px'};
`;

const Label = styled(Typography).attrs({ variant: 'body3', color: 'bodyDark' })`
  margin-bottom: 5px;
`;

const StyledTextArea = styled(TextArea)`
  padding: 8px;
  resize: vertical;
`;

const CharacterCounter = styled(Typography).attrs(() => ({ variant: 'labels1', color: 'hintDark' }))`
  margin-left: auto;
`;

const DeleteButton = styled(Button).attrs({ icon: 'MdDelete', variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
`;

export interface LocationNotesModalProps {
  selectedLocationNote: LocationNotesQueryResult[0] | null;
  showModal: boolean;
  onConfirm: (locationId: string) => void;
  onDismiss: () => void;
}

export interface LocationNoteFormValues {
  fromDate: Moment | null;
  toDate: Moment | null;
  locationId: string | null;
  noteType: LocationNoteType | null;
  note: string;
}

const defaultFormValues = {
  fromDate: null,
  toDate: null,
  locationId: null,
  noteType: null,
  note: '',
};

const getInitialFormValues = (selectedLocationNote: LocationNotesModalProps['selectedLocationNote']) =>
  selectedLocationNote
    ? {
        fromDate: moment(selectedLocationNote.fromDate, 'YYYY-MM-DD'),
        toDate: moment(selectedLocationNote.toDate, 'YYYY-MM-DD'),
        locationId: selectedLocationNote.locationId || selectedLocationNote.locationType,
        noteType: selectedLocationNote.noteType,
        note: selectedLocationNote.note,
      }
    : defaultFormValues;

const LocationNotesModal: React.FC<LocationNotesModalProps> = props => {
  const { isDataError, data } = useLoadLocationData();
  const editing = props.selectedLocationNote !== null;
  const [formData, setFormData] = useState<LocationNoteFormValues>(getInitialFormValues(props.selectedLocationNote));
  const { upsertLocationNote, isUpsertLoading } = useUpsertLocationNote(
    formData,
    props.selectedLocationNote,
    data?.locations || [],
  );
  const { deleteLocationNote, isDeleteLoading } = useDeleteLocationNote(props.selectedLocationNote);

  const isActionLoading = isUpsertLoading || isDeleteLoading;
  return (
    <Modal
      disabled={isActionLoading || isDataError}
      justifyButtons="space-between"
      data-testid="location-notes-modal"
      onConfirm={async () => {
        const locationId = await upsertLocationNote();

        if (locationId) {
          props.onConfirm(locationId);
        }
      }}
      isOpen={props.showModal}
      confirmText={editing ? 'Save' : 'Add'}
      confirmBtnVariant="primary"
      minWidth={350}
      onClose={props.onDismiss}
      renderLeftButton={() =>
        editing && (
          <DeleteButton
            disabled={isActionLoading || isDataError}
            onClick={async () => {
              const locationId = await deleteLocationNote();

              if (locationId) {
                props.onConfirm(locationId);
              }
            }}
          />
        )
      }
    >
      <Typography variant="heading5" color="bodyDark" align="center" bold={true}>
        {editing ? 'Edit location note' : 'New location note'}
      </Typography>
      <Spacer />
      {isDataError ? (
        <Typography color="bodyDark">Error loading location note. Please refresh and try again.</Typography>
      ) : (
        <>
          <Spacer>
            <Label>Display dates</Label>
            <DateRangePicker
              readOnly={true}
              initialStartDate={formData.fromDate}
              initialEndDate={formData.toDate}
              numberOfMonths={1}
              onChange={value => {
                setFormData({ ...formData, fromDate: value.startDate, toDate: value.endDate });
              }}
            />
          </Spacer>
          <Spacer>
            <Label>Location</Label>
            <SingleDropdown
              disabled={props.selectedLocationNote !== null}
              darkTheme={true}
              showValidation={false}
              width="100%"
              name="locationId"
              placeholder="Select location"
              showClearIndicator={false}
              items={getLocationItems(data?.locations || [])}
              onItemClick={item => {
                setFormData({ ...formData, locationId: item.value });
              }}
              selected={formData.locationId}
              filterOption={filterByLabel}
            />
          </Spacer>
          <Spacer>
            <Label>Note type</Label>
            <SingleDropdown
              disabled={props.selectedLocationNote !== null}
              darkTheme={true}
              showValidation={false}
              width="100%"
              name="noteType"
              placeholder="Select note type"
              showClearIndicator={false}
              items={getNoteTypeItems()}
              onItemClick={item => {
                setFormData({ ...formData, noteType: item.value as LocationNoteType });
              }}
              selected={formData.noteType}
              filterOption={filterByLabel}
            />
          </Spacer>
          <Spacer size="0">
            <Label>Planning note</Label>
            <StyledTextArea
              maxLength={500}
              name="notes"
              onChange={(e: React.ChangeEvent<{ value: string }>) => {
                setFormData({ ...formData, note: e.target.value });
              }}
              value={formData.note}
              tabIndex={1000}
              themeStyle="dark"
              placeholder="Enter planning note"
              showErrorMessage={false}
            />
            <CharacterCounter>{`${formData.note.length}/500 Characters`}</CharacterCounter>
          </Spacer>
        </>
      )}
    </Modal>
  );
};

export { LocationNotesModal };
