import { InMonth } from '@scout/domains';
import { ContextProps } from '../../../../components/Toast/Toast';
export const SCHEDULE_MANAGER_REPORT_URL = '/api/reports/in-month-multi';

const createDownloadInMonthMultiFn = (date: string, toast: ContextProps) => {
  return async () => {
    try {
      const dates = InMonth.toMonthDaysOrNext30Days(date);
      const response = await fetch(SCHEDULE_MANAGER_REPORT_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fromDate: dates[0],
          toDate: dates[dates.length - 1],
        }),
      });

      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const contentTypeExtension = 'xlsx';
      const charSet = 'UTF-8';
      const fileName = `inMonth-multi-${date}.${contentTypeExtension}`;

      const fileBuffer = await response.arrayBuffer();
      const blob = new Blob([fileBuffer], {
        type: `${contentType}${charSet ? `;charset=${charSet}` : ''}`,
      });
      saveAs(blob, fileName);

      toast.push({ type: 'SUCCESS', text: 'Plan generated.' });
    } catch (err) {
      toast.push({ type: 'ERROR', text: 'Error downloading plan. Please try again.' });
    }
  };
};

export { createDownloadInMonthMultiFn };
