import Select from 'react-select';
import styled, { css } from 'styled-components';
import { colorMixin, fontMixin } from '../../Mixins';

const darkThemeMixin = () => css`
  background: ${p => p.theme.base.dark.a};
  color: ${p => p.theme.secondary.light.c};
`;

const disabledMixin = () => css`
  background: ${p => p.theme.greys.light.disabled};
  color: ${p => p.theme.typography.colors.hintDark};
`;

export const StyledSelect = styled(Select)<{
  darkTheme?: boolean;
  error?: string;
  eastLocationDropdown?: boolean;
}>`
  & .${p => p.classNamePrefix}__value-container {
    padding: 0px 8px;
  }
   
  & .${p => p.classNamePrefix}__control {
    ${fontMixin}
    line-height: ${p => (p.eastLocationDropdown ? '16px' : '14px')}
    border: ${p => {
      let border = '1px solid ';
      if (p.isDisabled) {
        border = `1px solid ${p.theme.greys.light.disabled}`;
      } else if (p.error) {
        border += p.theme.secondary.light.a;
      } else if (p.menuIsOpen) {
        border += p.theme.primary.b;
      } else {
        border += p.darkTheme ? p.theme.greys.dark.border : p.theme.greys.light.border;
      }
      return border;
    }};

    border-radius: 2px;
    cursor: pointer;
    color: ${p => p.theme.base.dark.d};

    ${p => p.isDisabled && disabledMixin}
    ${p => p.darkTheme && darkThemeMixin}

    &:hover {
      color: ${p => p.theme.primary.b};
      border: 1px solid ${p => p.theme.primary.b};
    }
  }

  & .${p => p.classNamePrefix}__placeholder {
    color: ${p => (p.darkTheme ? p.theme.typography.colors.hintDark : p.theme.typography.colors.hintLight)};
    ${p => p.isDisabled && disabledMixin}
  }

  & .${p => p.classNamePrefix}__indicator-separator {
    background-color: transparent;
  }

  & .${p => p.classNamePrefix}__dropdown-indicator {
    color: ${p => p.theme.base.dark.c};
  }

  & .${p => p.classNamePrefix}__input {
    color: ${p => (p.darkTheme ? p.theme.secondary.light.c : p.theme.base.dark.d)};
  }

  & .${p => p.classNamePrefix}__menu {
    z-index: 10;
    background: ${p => (p.darkTheme ? p.theme.base.dark.c : p.theme.base.light.a)};
    color: ${p => p.theme.secondary.light.c};
    padding: 8px;
    border-radius: 2px;
    box-shadow: none;
    margin-top: 10px;
    border-top: 0;
    box-shadow: 0px 20px 40px ${p => (p.darkTheme ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.2)')};

    &::after {
      position: absolute;
      top: -10px;
      right: 8px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 10px 10px 10px;
      border-color: transparent transparent ${p => (p.darkTheme ? p.theme.base.dark.c : p.theme.base.light.a)}
        transparent;
    }
  }

  & .${p => p.classNamePrefix}__single-value {
    background-color: ${p => (p.darkTheme ? p.theme.base.dark.a : p.theme.base.light.a)};
    color: ${p => (p.darkTheme ? p.theme.secondary.light.c : p.theme.base.dark.d)};
    ${p => p.isDisabled && disabledMixin}
  }

  & .${p => p.classNamePrefix}__multi-value {
    background-color: ${p => (p.darkTheme ? p.theme.base.dark.a : p.theme.primary.b)};
    color: ${p => p.theme.secondary.light.c};
  }

  & .${p => p.classNamePrefix}__multi-value__label {
    color: ${p => p.theme.secondary.light.c};
  }

  & .${p => p.classNamePrefix}__multi-value__remove:hover {
    background-color: ${p => p.theme.primary.b};
    color: ${p => p.theme.secondary.light.c};
    cursor: pointer;
  }

  & .${p => p.classNamePrefix}__option {
    ${fontMixin}
    ${colorMixin}

    border-radius: 2px;
    display: flex;
    align-items: center;
  }

  & .${p => p.classNamePrefix}__option--is-selected {
    background-color: ${p => p.theme.primary.b};

    p {
      color: ${p => p.theme.secondary.light.c};
    }
  }

  & .${p => p.classNamePrefix}__option--is-focused {
    background-color: ${p => p.theme.primary.b};
    p {
      color: ${p => p.theme.secondary.light.c};
    }
  }

  & .${p => p.classNamePrefix}__control--is-disabled {
    ${p =>
      p.darkTheme &&
      css`
        background: ${p.theme.greys.dark.disabled};
        border: ${p.theme.greys.dark.disabled};
      `}
  }

  & .${p => p.classNamePrefix}__single-value--is-disabled {
    ${p =>
      p.darkTheme &&
      css`
        background: ${p.theme.greys.dark.disabled};
        border: ${p.theme.greys.dark.disabled};
      `}
  }

  & .${p => p.classNamePrefix}__menu-list {
    max-height: 500px;
  }
`;
