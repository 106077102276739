import { formatRoundedNumber } from '../../../helpers';
import { PiDataStockModel, TankModel } from '@scout/types';
import { StockInfoGroups, StockInfoProps, StockInfoValue } from './StocksContainer';
import { TankMetaData, TankMetaDataLabel } from './TankMeta';

export type TankGroup = 'hard-pen' | 'soft-pen' | 'raw-material' | 'none';
export enum TankGroupLabels {
  HardPen = 'Hard Pen',
  SoftPen = 'Soft Pen',
  RawMaterial = 'Raw Material',
  NonBitumen = 'Non Bitumen',
  Other = 'Other',
  Bitumen = 'Bitumen',
}

export const TankGroupOrder: { [group: string]: number } = {
  'soft-pen': 1,
  'hard-pen': 2,
  'raw-material': 3,
  none: 4,
};

export const tankGroupNames: { [group: string]: TankGroupLabels } = {
  'hard-pen': TankGroupLabels.HardPen,
  'soft-pen': TankGroupLabels.SoftPen,
  'raw-material': TankGroupLabels.RawMaterial,
  none: TankGroupLabels.Other,
};

export const hardGrades = ['20/30', '35/50', '40/60', '50/70', '85/25', '95/40', 'SBA'];
export const softGrades = ['70/100', '160/220', '250/330', '330/430'];
export const rawGrades = ['BFS', 'Component', 'M100', 'HVR', 'HVR Exp.', 'SR'];

export const getTankGroup = ({ code, stocks, piStock }: TankModel): TankGroup => {
  const stock = stocks ? stocks[0] : piStock;
  const openingSpecification = stock ? stock.openingSpecification : null;
  const productCode = openingSpecification || code;

  if (productCode.endsWith('dmm')) {
    const numberValue = parseFloat(productCode);
    return numberValue < 50 ? 'hard-pen' : 'soft-pen';
  }

  if (hardGrades.includes(productCode)) {
    return 'hard-pen';
  }

  if (softGrades.includes(productCode)) {
    return 'soft-pen';
  }

  if (rawGrades.includes(productCode)) {
    return 'raw-material';
  }

  return 'none';
};

const getTankMetaData = (piStock: PiDataStockModel, meta?: string | null) => {
  const metaToDisplay: TankMetaData[] = [];

  const tankMeta = meta && JSON.parse(meta);

  if (tankMeta && tankMeta.product) {
    metaToDisplay.push({ label: TankMetaDataLabel.PRODUCT, value: tankMeta.product, eventtime: tankMeta.eventtime });
  }

  if (piStock.pen && piStock.pen.value) {
    metaToDisplay.push({ label: TankMetaDataLabel.PEN, value: piStock.pen.value, eventtime: piStock.pen.eventTime });
  }

  if (piStock.softPoint && piStock.softPoint.value) {
    metaToDisplay.push({
      label: TankMetaDataLabel.SOFTENING_POINT,
      value: piStock.softPoint.value,
      eventtime: piStock.softPoint.eventTime,
    });
  }

  if (piStock.temperature && piStock.temperature.value) {
    metaToDisplay.push({
      label: TankMetaDataLabel.TEMPERATURE,
      value: piStock.temperature.value,
      eventtime: piStock.temperature.eventTime,
    });
  }

  if (piStock.viscosity && piStock.viscosity.value) {
    metaToDisplay.push({
      label: TankMetaDataLabel.VISCOSITY,
      value: piStock.viscosity.value,
      eventtime: piStock.viscosity.eventTime,
    });
  }

  return metaToDisplay;
};

export const getStockInfo = ({ maxVolume, stocks, piStock, meta, level }: TankModel): StockInfoProps | null => {
  const stock = stocks && stocks[0];
  if (stock) {
    return {
      isPiStock: false,
      maxVolume,
      openingAmount: stock.openingAmount,
      openingSpecification: stock.openingSpecification,
    };
  }

  if (piStock) {
    return {
      isPiStock: true,
      maxVolume,
      level,
      openingAmount: piStock.openingAmount,
      openingSpecification: piStock.openingSpecification,
      meta: getTankMetaData(piStock, meta),
    };
  }

  return null;
};

export const stockInfoSorter = (stockInfos: StockInfoValue[]) => {
  const sortedStockInfos = stockInfos.sort((s1, s2) => {
    const s1OpeningSpecification = s1.data && s1.data.openingSpecification;
    const s1Code = s1.code;
    const s2OpeningSpecification = s2.data && s2.data.openingSpecification;
    const s2Code = s2.code;

    if (s1OpeningSpecification && !s2OpeningSpecification) {
      return -1;
    } else if (!s1OpeningSpecification && s2OpeningSpecification) {
      return 1;
    } else {
      if (s1OpeningSpecification && s2OpeningSpecification) {
        if (s1OpeningSpecification > s2OpeningSpecification) {
          return 1;
        }

        if (s1OpeningSpecification < s2OpeningSpecification) {
          return -1;
        }
      }

      if (s1Code > s2Code) {
        return 1;
      }

      if (s1Code < s2Code) {
        return -1;
      }

      return 0;
    }
  });

  return sortedStockInfos;
};

export const getStockInfoGroups = (tanks: TankModel[]) =>
  tanks.reduce((acc: StockInfoGroups, tank: TankModel) => {
    const group = getTankGroup(tank);
    const key = group || 'none';
    const previousStockInfos = acc[key] || [];

    return {
      ...acc,
      [key]: [
        ...previousStockInfos,
        {
          code: tank.code,
          data: getStockInfo(tank),
        },
      ],
    };
  }, {});

/* tslint:disable */
export const groupBy = (items = [], key: string) =>
  items.reduce(
    (result: { [x: string]: any }, item: { [x: string]: string | number }) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

/* tslint:disable */
export const mergeTwoArrays = (arr1 = [], arr2: any[]) => {
  return arr1.map((v: any) => ({ ...v, ...arr2.find((res: { plantId: any }) => res.plantId === v.plantId) }));
};
export const formatMetaValue = ({ label, value }: TankMetaData): string => {
  if (typeof value === 'string') {
    return value.toString();
  }

  switch (label) {
    case TankMetaDataLabel.SOFTENING_POINT:
    case TankMetaDataLabel.TEMPERATURE:
      return `${formatRoundedNumber(value)} °C`;
    case TankMetaDataLabel.PEN:
      return `${formatRoundedNumber(value)} dmm`;
    case TankMetaDataLabel.VISCOSITY:
      return `${formatRoundedNumber(value)} cSt`;
    default:
      return formatRoundedNumber(value);
  }
};

export const getProgress = ({
  maxVolume,
  openingAmount,
}: {
  maxVolume?: number | null;
  openingAmount?: number | null;
}): number => {
  if (!maxVolume || !openingAmount || openingAmount <= 0) {
    return 0;
  }

  return parseInt(((openingAmount / maxVolume) * 100).toFixed(0), 10);
};

export const formatKt = (amount?: number | null): string => (amount ? `${(amount / 1000).toFixed(2)}Kt` : '0');

export enum PlantIds {
  RHINELAND_BARGE = 'D024',
  WESSELING = 'D025',
  PCK = 'D028',
  MIRO = 'D029',
  WILLINGHUSEN = 'D059',
  TIB_MANNHEIM = 'D061',
  TEUCHERN = 'D062',
  HEIDE = 'D137',
  ALTENO = 'D203',
  COLOGNE = 'D204',
  RHINELAND_TRUCK = 'D229',
  DGADORTMUND = 'D545',
  COLLET = 'F364',
  BAYONNE = 'F465',
  NANTES = 'F466',
  VENISSIEUX = 'F481',
  FMC_GRAVESEND = 'G075',
  STANLOW_PMB = 'G114',
  ERL = 'G116',
  PERNIS = 'N489',
  JPB = 'N572',
  LATEXFALT = 'N573',
  ARKEL_ILLBRUCK = 'N574',
  BTTB = 'N575',
  BUKOM = 'S858',
}

export enum PlantRegions {
  RHINELAND_BARGE = 'Europe',
  WESSELING = 'Europe',
  PCK = 'Europe',
  MIRO = 'Europe',
  WILLINGHUSEN = 'Europe',
  TIB_MANNHEIM = 'Europe',
  TEUCHERN = 'Europe',
  HEIDE = 'Europe',
  ALTENO = 'Europe',
  COLOGNE = 'Europe',
  RHINELAND_TRUCK = 'Europe',
  DGADORTMUND = 'Europe',
  COLLET = 'Europe',
  BAYONNE = 'Europe',
  NANTES = 'Europe',
  VENISSIEUX = 'Europe',
  FMC_GRAVESEND = 'Europe',
  STANLOW_PMB = 'Europe',
  ERL = 'Europe',
  PERNIS = 'Europe',
  JPB = 'Europe',
  LATEXFALT = 'Europe',
  ARKEL_ILLBRUCK = 'Europe',
  BTTB = 'Europe',
  VILLANUEVA = 'Europe',
  EUROPE = 'Europe',
}
