import React from 'react';
import * as Icons from 'react-icons/md';
import styled, { css } from 'styled-components';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IThemeType } from '../../theme';
import { fontMixin, FontProps } from '../Mixins';

/**
 * https://www.figma.com/file/CGp1KVHm3orcL4GpWclAO4LY/Sushi-03---Buttons
 */
export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'danger'
  // Ghost button on dark theme
  | 'ghost-dark'
  // Ghost button on light theme
  | 'ghost-light'
  // Link button on dark theme
  | 'link-dark'
  // Icon button on light theme
  | 'icon-light'
  // Icon button on dark theme
  | 'icon-dark';

export type IconTypes = keyof typeof Icons;

interface ButtonProps extends React.PropsWithoutRef<JSX.IntrinsicElements['button']> {
  className?: string;
  'data-testid'?: string;
  variant?: ButtonVariants;
  icon?: IconTypes;
  currentUserTheme?: boolean;
}

type StyledButtonProps = FontProps & {
  theme: IThemeType;
  btnVariant?: ButtonVariants;
  showIcon?: boolean;
  disabled?: boolean;
  currentUserTheme?: boolean;
};

const IconContainer = styled.div<{ hasChildren: boolean }>`
  margin-top: 5px;
  ${({ hasChildren }) => (hasChildren ? 'margin-left: 10px' : '')};
`;

const createBackgroundColor = (props: StyledButtonProps) => {
  switch (props.btnVariant) {
    case 'primary':
      return props.theme.primary.a;
    case 'secondary':
      return props.theme.primary.b;
    case 'danger':
      return props.theme.secondary.light.a;
    default:
      return 'transparent';
  }
};

const createColor = (props: StyledButtonProps) => {
  switch (props.btnVariant) {
    case 'ghost-dark':
    case 'icon-dark':
    case 'danger':
    case 'link-dark':
    case 'secondary':
      return props.theme.secondary.light.c;
    default:
      return props.theme.base.dark.d;
  }
};

const createBorder = (props: StyledButtonProps) => {
  switch (props.btnVariant) {
    case 'ghost-dark':
    case 'icon-dark':
      return `solid 1px ${props.theme.secondary.light.c}`;
    case 'ghost-light':
    case 'icon-light':
      if (props.currentUserTheme) {
        return `solid 1px ${props.theme.secondary.light.c}`;
      } else {
        return `solid 1px ${props.theme.base.dark.d}`;
      }
    default:
      return 'none';
  }
};

const hoverMixin = (props: StyledButtonProps) => {
  if (props.btnVariant === 'ghost-dark' || props.btnVariant === 'ghost-light') {
    return css`
      border-color: ${props.theme.primary.b};
      color: ${props.theme.primary.b};
    `;
  }

  return 'filter: brightness(0.7);';
};

const DisabledMixin = css`
  background: ${props => props.theme.greys.dark.disabled};
  color: ${props => props.theme.greys.light.border};
  cursor: default;
`;

const LightThemeDisabledMixin = css`
  background: ${props => props.theme.secondary.light.c};
  color: ${props => props.theme.greys.light.border};
  cursor: default;
`;

const StyledButton = styled.button<StyledButtonProps>`
  ${fontMixin}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${p => (p.showIcon ? 'space-between' : 'center')};
  height: 40px;
  cursor: pointer;
  border-radius: 2px;
  border: ${createBorder};
  background: ${createBackgroundColor};
  color: ${createColor};
  padding: ${({ btnVariant }) => (btnVariant && ['icon-dark', 'icon-light'].includes(btnVariant) ? '0 7px' : '0 15px')};
  margin: 0;
  position: relative;
  user-select: none;

  &:hover {
    ${props => !props.disabled && hoverMixin}
  }

  ${props => (props.currentUserTheme ? props.disabled && LightThemeDisabledMixin : props.disabled && DisabledMixin)}
`;

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  'data-testid': dataTestId,
  disabled = false,
  icon,
  onClick,
  variant = 'primary',
  currentUserTheme,
  ...props
}: ButtonProps) => {
  const Icon = icon ? Icons[icon] : null;
  return (
    <StyledButton
      align="center"
      bold={true}
      btnVariant={variant}
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
      showIcon={Icon !== null}
      type={props.type}
      variant="body2"
      currentUserTheme={currentUserTheme}
    >
      {children}
      {Icon && (
        <IconContainer hasChildren={Boolean(children)}>
          <Icon size={24} />
        </IconContainer>
      )}
    </StyledButton>
  );
};

export { Button };
