import React from 'react';
import styled from 'styled-components';
import { Col } from '../../components/Grid';
import { PlusIcon } from '../Icons/PlusIcon';
import { MinusIcon } from '../Icons/MinusIcon';

const Container = styled.div`
  position: absolute;
  display: inline-block;
  bottom: 36px;
  left: 18px;
  z-index: 9999;
`;

const Button = styled.div<{ bottom?: true }>`
  background: ${p => p.theme.base.dark.a};
  width: 40px;
  height: 40px;

  border: 2px solid ${p => p.theme.base.dark.d};
  box-sizing: border-box;
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${p => p.bottom && 'margin-top: -2px;'}
  ${p => p.bottom && 'border-radius: 0 0 2px 2px;'}
`;

interface ZoomContolProps {
  onClickPlus: () => void;
  onClickMinus: () => void;
}

const ZoomContol = ({ onClickPlus, onClickMinus }: ZoomContolProps) => (
  <Container>
    <Col>
      <Button onClick={onClickPlus}>
        <PlusIcon />
      </Button>
      <Button onClick={onClickMinus} bottom={true}>
        <MinusIcon />
      </Button>
    </Col>
  </Container>
);

export { ZoomContol };
