import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../Typography';
import { groupAndSortVessels } from './helpers';
import { TransportsQueryResult } from '@scout/types';
import { TextInput } from '../TextInput';
import { MdClose, MdSearch } from 'react-icons/md';
import { FilterIcon } from '../../components/Icons/FilterIcon';

const ScrollingContainer = styled.div`
  overflow-y: auto;
  height: 85%;
  padding: 8px;
`;

const List = styled.ul``;
const ListItem = styled.li<{
  selected?: boolean;
}>`
  height: 40px;
  width: 100%;
  padding: 0 16px;
  border-radius: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.theme.primary.b};
  }
  ${p => p.selected && `background-color: ${p.theme.primary.b};`}
`;

const GroupHeading = styled.div`
  height: 40px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
`;

const NoResultContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchWrapper = styled.div`
  flex: 20%;
  display: flex;
  align-items: top;
  position: fixed;
  bottom: 60px;
  margin-left: 10px;
  width: 16%;
`;

const SearchInput = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0.5rem;
`;

const IconMixin = css`
  height: 40px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 0;
`;

const IconMixinLightTheme = css`
  height: 40px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  color: ${p => p.theme.base.dark.a};
`;

const NotFoundSearchIconMixin = css`
  height: 20%;
  width: 15%;
  position: absolute;
  //right: 10px;
  top: 30%;
`;

const SearchIcon = styled(MdSearch)`
  ${IconMixin}
`;

const SearchIconLightTheme = styled(MdSearch)`
  ${IconMixinLightTheme}
`;

const CloseIcon = styled(MdClose)`
  ${IconMixin}
  position: absolute;
  color: ${props => props.theme.primary.b};
`;

const NotFoundSearchIcon = styled(MdSearch)`
  ${NotFoundSearchIconMixin}
`;

export const VesselSelectList: React.FC<{
  selectedVesselId: string | null;
  vessels: TransportsQueryResult;
  onSelectVessel: (vesselId: string) => void;
  onCloseList: () => void;
  currentUserTheme?: boolean;
}> = ({ selectedVesselId, vessels, onSelectVessel, onCloseList, currentUserTheme }) => {
  const [vesselFilter, setVesselFilter] = useState('');

  const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setVesselFilter(event.target.value);
  };

  const lowercasedFilter = vesselFilter.toLowerCase();
  const filteredData = vessels.filter(item => {
    return Object.keys(item).some(key => item?.shipName?.toLowerCase().includes(lowercasedFilter));
  });

  if (filteredData.length === 0) {
    return (
      <>
        <NoResultContainer>
          <NotFoundSearchIcon />
          <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="body4" userSelect={false}>
            No Vessel found
          </Typography>
        </NoResultContainer>

        <SearchWrapper>
          <SearchInput>
            <TextInput
              themeStyle={currentUserTheme ? 'light' : 'dark'}
              name="search"
              placeholder="Search by vessel name"
              onChange={handleChange}
              value={vesselFilter}
            />
            {vesselFilter ? <CloseIcon onClick={() => setVesselFilter('')} /> : <SearchIcon />}
          </SearchInput>
        </SearchWrapper>
      </>
    );
  }

  const groupedAndSortedVessels = groupAndSortVessels(vessels);

  return (
    <>
      <ScrollingContainer>
        {/* {groupedAndSortedVessels.map(({ category, vessels }) => ( */}
        <React.Fragment>
          <GroupHeading>
            <Typography color="hintDark" variant="body4" userSelect={false}>
              Select Vessel
            </Typography>
          </GroupHeading>
          <List>
            {filteredData.map(vessel => {
              const isSelected = vessel.id === selectedVesselId;
              return (
                <ListItem
                  data-testid="vessel-select-list-item"
                  // key={`${category}-${vessel.id}`}
                  key={`${vessel.id}`}
                  selected={isSelected}
                  onClick={() => {
                    onSelectVessel(vessel.id);
                    setTimeout(() => {
                      onCloseList();
                    }, 100);
                  }}
                >
                  <Typography
                    color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                    variant="body4"
                    userSelect={false}
                    bold={isSelected}
                  >
                    {vessel.shipName}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </React.Fragment>
        {/* ))} */}
      </ScrollingContainer>
      <SearchWrapper>
        <SearchInput>
          <TextInput
            themeStyle={currentUserTheme ? 'light' : 'dark'}
            name="search"
            placeholder="Search by vessel name"
            onChange={handleChange}
            value={vesselFilter}
          />
          {vesselFilter ? (
            <CloseIcon onClick={() => setVesselFilter('')} />
          ) : currentUserTheme ? (
            <SearchIconLightTheme />
          ) : (
            <SearchIcon />
          )}
        </SearchInput>
      </SearchWrapper>
    </>
  );
};
