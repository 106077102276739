import { GradeModel, PlantModel, SpotDemandByIdGrade } from '@scout/types';
import { GradeValues, PlantOptions } from '../types';

export const sortGrades = (grades: AllGrades) => {
  return grades.sort((v1, v2) => {
    // Adding 0 so that 70/200 is evaluated as 070/200 and will be
    // considered less than 100/200
    const name1 = v1.name.split('/')[0].length < 3 ? '0' + v1.name : v1.name;
    const name2 = v2.name.split('/')[0].length < 3 ? '0' + v2.name : v2.name;
    return name1 > name2 ? 1 : -1;
  });
};

type AllGrades = Array<Pick<SpotDemandByIdGrade, 'id' | 'name' | 'type'>>;
type SelectedGrades = Array<Pick<SpotDemandByIdGrade, 'id' | 'name' | 'percentage' | 'type'>>;

export const formatGradeItems = (allGrades: AllGrades, selectedGrades?: SelectedGrades): GradeValues => {
  const sortedItems = sortGrades(allGrades);
  const data: GradeValues = {};

  sortedItems.forEach(item => {
    const selectedGrade = (selectedGrades || []).find(grade => grade.id === item.id);
    data[item.id] = {
      name: item.name,
      type: item.type,
      percentage: selectedGrade && selectedGrade.percentage > 0 ? `${selectedGrade.percentage}` : '',
    };
  });

  return data;
};

type FormatPlantsDropdownInput = Array<
  Pick<PlantModel, 'id' | 'shortName'> & {
    grades: Array<Pick<GradeModel, 'id'>>;
  }
>;

export const formatPlantsDropdown = (plants: FormatPlantsDropdownInput) => {
  return plants.reduce((sum: PlantOptions[], plant) => {
    if (plant.shortName) {
      return [
        ...sum,
        {
          value: plant.id,
          label: plant.shortName,
          grades: plant.grades.map(grade => grade.id),
        },
      ];
    }

    return sum;
  }, []);
};

export const getUsedGrades = (grades: GradeValues): string[] =>
  Object.entries(grades).reduce<string[]>((prev, [key, value]) => {
    if (value.percentage === '') {
      return prev;
    }

    const percentage = Number(value.percentage);
    if (isNaN(percentage) || percentage === 0) {
      return prev;
    }

    return [...prev, key];
  }, []);
