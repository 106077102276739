import moment from 'moment';

import { DashboardRowType, MonthlyVolumeType } from './types';

export const getMonthlyTotals = ({
  demands,
  dates,
  selectedYear,
}: {
  dates: Array<{
    month: string;
    number: string;
  }>;
  demands: DashboardRowType[];
  selectedYear: number;
}) => {
  return dates.reduce<MonthlyVolumeType[]>((allDates, date) => {
    const totalVolume = demands.reduce((monthlyTotalVolume, demand) => {
      const forecast = demand.monthlyForecasts.find(e => {
        return moment(e.date).isSame(
          moment()
            .year(selectedYear)
            .month(date.month),
          'month',
        );
      });

      if (forecast && forecast.volume) {
        return monthlyTotalVolume + forecast.volume;
      }

      return monthlyTotalVolume;
    }, 0);

    return [...allDates, { ...date, volume: totalVolume }];
  }, []);
};
