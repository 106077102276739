import moment, { Moment } from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';
import { CircleTickIcon } from '../../../../../components/Icons/CircleTickIcon';
import { colorMixin } from '../../../../../components/Mixins';
import { FontColors } from '../../../../../theme';
import {
  LocationNoteModel,
  LocationNotesQueryResult,
  LocationNoteType,
  MovementStageQueryResult,
  MovementStagesQueryResult,
  TransportQueryResult,
  TransportType,
} from '@scout/types';
import { isToday, prettyDate } from '../dates';
import { getMovementStageStatus } from '../dynamicEtaUtils';
import { LocationNoteTypeNames } from '../LocationNoteTypeNames';
import { LocationNoteIcon } from './LocationNoteIcon';
import { MovementDescription } from './MovementDescription';
import { TableColumn, TableRow } from './TableElements';
import { TransferSummary } from './TransferSummary';
import { TransferTypeIcon } from './TransferTypeIcon';

const getLocationNoteColor = (locationNoteType: LocationNoteType): FontColors => {
  switch (locationNoteType) {
    case LocationNoteType.StockOutRisk:
      return 'errorDark';
    case LocationNoteType.NotEnoughUllageRisk:
      return 'warning';
    default:
      return 'bodyDark';
  }
};

const getLocationNoteDescription = (locationNote: LocationNoteModel) => {
  if (locationNote.noteType === LocationNoteType.SupplyDisruption) {
    return locationNote.note;
  }

  return `[${LocationNoteTypeNames[locationNote.noteType]}] ${locationNote.note}`;
};

const NotesTextField = styled.div`
  width: 300px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  ${colorMixin}
`;

const TextField = styled.div<{ underline?: boolean }>`
  min-width: 85px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  ${colorMixin}
  ${p =>
    p.underline &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const StatusField: React.FC<{ color: FontColors }> = ({ children, color }) => {
  if (children === 'Completed') {
    return (
      <TextField color={color}>
        <CircleTickIcon />

        {children}
      </TextField>
    );
  }
  return <TextField color={color}>{children}</TextField>;
};

interface MovementRowsProps {
  isThirdParty?: boolean;
  date: string;
  movementStages: MovementStagesQueryResult;
  locationNotes: LocationNotesQueryResult;
  onEditLocationNote: (locationNote: LocationNotesQueryResult[0]) => void;
  onClickTransport: (transport: TransportQueryResult) => void;
}

const getIncomingTransfer = (movementStage: MovementStageQueryResult) =>
  movementStage.productTransfers.filter(transfer => transfer.volume > 0);
const getOutgoingTransfer = (movementStage: MovementStageQueryResult) =>
  movementStage.productTransfers.filter(transfer => transfer.volume < 0);

const MovementRows: React.FunctionComponent<MovementRowsProps> = ({
  isThirdParty,
  date,
  movementStages,
  locationNotes,
  onEditLocationNote,
  onClickTransport,
}) => {
  return (
    <>
      {movementStages.map((movementStage, index) => {
        const incomingTransfer = getIncomingTransfer(movementStage);
        const outgoingTransfer = getOutgoingTransfer(movementStage);
        const { text, color } = getMovementStageStatus(movementStage);
        const otherColumnColor = text === 'Cancelled' ? color : 'bodyDark';
        const transportName = movementStage.movement.transport?.shipName ?? movementStage.movement.transportType;
        const isVessel = !!movementStage.movement.transport;

        return (
          <TableRow data-testid={`movement-row-${date}`} key={movementStage.id}>
            <TableColumn stickyLeft={0} isDefault={false} isToday={isToday(date)}>
              {index === 0 ? prettyDate(date) : ''}
            </TableColumn>
            <TableColumn stickyLeft={100}>
              <TransferTypeIcon
                movementStage={movementStage}
                incomingTransfer={incomingTransfer}
                outgoingTransfer={outgoingTransfer}
              />
            </TableColumn>
            <TableColumn>{incomingTransfer.length > 0 && <TransferSummary transfers={incomingTransfer} />}</TableColumn>
            <TableColumn>{outgoingTransfer.length > 0 && <TransferSummary transfers={outgoingTransfer} />}</TableColumn>
            <TableColumn>
              <MovementDescription color={otherColumnColor} isThirdParty={isThirdParty} movementStage={movementStage} />
            </TableColumn>
            <TableColumn>
              <TextField
                color={otherColumnColor}
                underline={isVessel}
                onClick={() => isVessel && onClickTransport(movementStage.movement.transport as TransportQueryResult)}
              >
                {transportName === TransportType.None ? '' : transportName}
              </TextField>
            </TableColumn>
            <TableColumn>
              <StatusField color={color}>{text}</StatusField>
            </TableColumn>
            <TableColumn>
              <NotesTextField color={otherColumnColor}>{movementStage.movement.planningNotes}</NotesTextField>
            </TableColumn>
          </TableRow>
        );
      })}
      {locationNotes.map((locationNote, index) => {
        return (
          <TableRow data-testid={`location-note-row-${date}`} key={locationNote.id}>
            <TableColumn stickyLeft={0} isDefault={false} isToday={isToday(date)}>
              {index === 0 && movementStages.length === 0 ? prettyDate(date) : ''}
            </TableColumn>
            <TableColumn stickyLeft={100}>
              <LocationNoteIcon
                locationNoteType={locationNote.noteType}
                onClick={() => onEditLocationNote(locationNote)}
              />
            </TableColumn>
            <TableColumn />
            <TableColumn />
            <TableColumn>
              <NotesTextField color={getLocationNoteColor(locationNote.noteType)}>
                {getLocationNoteDescription(locationNote)}
              </NotesTextField>
            </TableColumn>
            <TableColumn>
              <TextField />
            </TableColumn>
            <TableColumn>
              <TextField />
            </TableColumn>
            <TableColumn>
              <TextField />
            </TableColumn>
          </TableRow>
        );
      })}
    </>
  );
};

export { MovementRows };
