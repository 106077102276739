import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../../../components/Grid';
import { Cell, Row as TableRow } from '../../../../components/Table';
import { Tag } from '../../../../components/Tag/Tag';
import { Typography } from '../../../../components/Typography';
import { getShipmentTypeName } from '../../../../utils/getters';
import { DashboardRowType } from '../types';
import { DemandNames } from '../constants';

const OwnerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background-color: ${p => p.theme.tertiary.chart1};
  border-radius: 100%;
  width: 34px;
  height: 34px;
`;

const CustomerName = styled.div`
  width: 286px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SoldToCode = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-top: 4px;
`;

const IncompleteTag = styled(Tag).attrs({
  color: 'warning',
  fixedWidth: false,
})<{ currentUserTheme?: boolean }>`
  margin-top: 4px;
  font-weight: ${p => (p.currentUserTheme ? 'bold' : 'none')};
`;

const SalesOrgTag = styled(Tag).attrs({
  color: 'highlight',
  fixedWidth: false,
})`
  margin-top: 4px;
  margin-right: 12px;
`;

interface Props {
  item: DashboardRowType;
  isUKIrelandCluster: boolean;
  onClick?: (spotDemandId: string) => void;
  currentUserTheme?: boolean;
}

const DashboardTableRow: React.FC<Props> = ({
  item: {
    id,
    contractId,
    region,
    customer,
    shipTo,
    primaryPremiumPlant,
    primaryPenPlant,
    totalVolume,
    demandType,
    shipmentType,
    complete,
    monthlyForecasts,
    editLink,
  },
  isUKIrelandCluster,
  onClick,
  currentUserTheme,
}) => {
  const handleRowClick = useCallback(() => {
    if (onClick != null) {
      onClick(editLink);
    }
  }, [onClick, editLink]);

  const locationName = (isUKIrelandCluster ? shipTo?.name : region?.name) ?? '-';
  const owner = `${customer.amFirstName[0]}${customer.amLastName[0]}`.toUpperCase();

  return (
    <TableRow data-testid={`table-row-${id}`} onClick={handleRowClick} currentUserTheme={currentUserTheme}>
      <Cell
        data-testid="dashboard-owner-column"
        sticky="left"
        border="small"
        justify="center"
        currentUserTheme={currentUserTheme}
      >
        <OwnerIcon>
          <Typography variant="body3" color="bodyDark" bold={true} align="center">
            {owner}
          </Typography>
        </OwnerIcon>
      </Cell>
      <Cell sticky="left" left={75} border="small" currentUserTheme={currentUserTheme}>
        <Col>
          <Row>
            <CustomerName title={customer.name} data-testid="dashboard-customer-name">
              <Typography
                tag="div"
                variant="body3"
                color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                bold={true}
                overflowHidden={true}
              >
                {customer.name}
              </Typography>
            </CustomerName>
          </Row>
          <Row>
            <SoldToCode data-testid="dashboard-customer-code">
              <Typography tag="div" variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                {customer.code}
              </Typography>
            </SoldToCode>
            <SalesOrgTag data-testid="dashboard-customer-salesorg">{customer.salesOrganisation}</SalesOrgTag>
            {complete === false && <IncompleteTag currentUserTheme={currentUserTheme}>Incomplete</IncompleteTag>}
          </Row>
        </Col>
      </Cell>
      <Cell
        data-testid="dashboard-type-column"
        text={DemandNames[demandType]}
        sticky="left"
        left={392}
        border="large"
        rightBorderShadow={true}
        currentUserTheme={currentUserTheme}
      />
      <Cell data-testid="dashboard-id-column" text={contractId} border="small" currentUserTheme={currentUserTheme} />
      <Cell
        data-testid="dashboard-location-column"
        text={locationName}
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="dashboard-shipment-type-column"
        text={getShipmentTypeName(shipmentType)}
        border="large"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="dashboard-pen-plant-column"
        text={primaryPenPlant?.shortName ?? '-'}
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="dashboard-premium-plant-column"
        text={primaryPremiumPlant?.shortName ?? '-'}
        border="large"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="volume-total-column"
        text={`${totalVolume}t`}
        justify="flex-end"
        border="large"
        currentUserTheme={currentUserTheme}
      />
      {monthlyForecasts.map(({ volume, price, date }) => {
        const forecastMonth = date.getMonth();
        const formattedVolume = volume.toLocaleString('en-GB', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        return (
          <React.Fragment key={`${forecastMonth}-${id}`}>
            <Cell
              data-testid={`${id}-volume-${forecastMonth}`}
              text={formattedVolume}
              justify="flex-end"
              border="small"
              currentUserTheme={currentUserTheme}
            />
            <Cell
              data-testid={`${id}-pricing-${forecastMonth}`}
              text={price.toString()}
              justify="flex-end"
              border="small"
              currentUserTheme={currentUserTheme}
            />
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

export { DashboardTableRow };
