import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  TableHead,
  TableRow,
  TableHeadColumn,
  TableContainer,
  TableBody,
  TableColumn,
} from '../Table/EastSchedulingManager/EastTableElements';

const Container = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  font-size: 12px;
`;

const CenteredTextField = styled.span`
  min-width: 55px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
`;

export const EastTableWrapper: React.FC<{
  isThirdParty?: boolean;
  currentLocation?: string;
  currentUserTheme?: boolean;
}> = ({ currentLocation, isThirdParty, children, currentUserTheme }) => {
  return (
    <>
      <Container>
        <TableContainer>
          <TableHead currentUserTheme={currentUserTheme}>
            <TableRow currentUserTheme={currentUserTheme}>
              <TableRow currentUserTheme={currentUserTheme}>
                <TableHeadColumn width="60px" stickyLeft={0} currentUserTheme={currentUserTheme}>
                  <CenteredTextField>Date</CenteredTextField>
                </TableHeadColumn>
                <TableHeadColumn width="125px" currentUserTheme={currentUserTheme}>
                  <CenteredTextField>Product</CenteredTextField>
                </TableHeadColumn>
                <TableHeadColumn width="100px" currentUserTheme={currentUserTheme}>
                  <CenteredTextField>Planned</CenteredTextField>
                </TableHeadColumn>
                <TableHeadColumn width="115px" currentUserTheme={currentUserTheme}>
                  <CenteredTextField>Actual</CenteredTextField>
                </TableHeadColumn>
                {!isThirdParty && (
                  <TableHeadColumn width="80px" currentUserTheme={currentUserTheme}>
                    Stock
                  </TableHeadColumn>
                )}
                {!isThirdParty && (
                  <TableHeadColumn width="100px" currentUserTheme={currentUserTheme}>
                    <CenteredTextField>Ullage</CenteredTextField>
                  </TableHeadColumn>
                )}
                <TableHeadColumn width="165px" currentUserTheme={currentUserTheme}>
                  <CenteredTextField>Transport info</CenteredTextField>
                </TableHeadColumn>
                <TableHeadColumn width="150px" currentUserTheme={currentUserTheme}>
                  <CenteredTextField>
                    {currentLocation === 'refinery' || currentLocation === 'ThirdPartyRef'
                      ? 'Discharge Laycan'
                      : currentLocation === 'depot' || currentLocation === 'ThirdPartyCus'
                      ? 'LoadPort Laycan'
                      : ''}
                  </CenteredTextField>
                </TableHeadColumn>
                <TableHeadColumn width="170px" currentUserTheme={currentUserTheme}>
                  Vessel
                </TableHeadColumn>
                <TableHeadColumn width="155px" currentUserTheme={currentUserTheme}>
                  <CenteredTextField>Buyer info</CenteredTextField>
                </TableHeadColumn>
                <TableHeadColumn width="285px" currentUserTheme={currentUserTheme}>
                  Marine Traffic ETA
                </TableHeadColumn>
                <TableHeadColumn width="240px" currentUserTheme={currentUserTheme}>
                  Smart Notification
                </TableHeadColumn>
                <TableHeadColumn width="200px" currentUserTheme={currentUserTheme}>
                  Freight price (Internal)
                </TableHeadColumn>
                <TableHeadColumn width="200px" currentUserTheme={currentUserTheme}>
                  Freight Price (Customer)
                </TableHeadColumn>
                <TableHeadColumn width="200px" currentUserTheme={currentUserTheme}>
                  Purchase Contract
                </TableHeadColumn>
                <TableHeadColumn width="200px" currentUserTheme={currentUserTheme}>
                  Sales Contract
                </TableHeadColumn>
                <TableHeadColumn width="200px" currentUserTheme={currentUserTheme}>
                  Planning Notes
                </TableHeadColumn>
              </TableRow>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </TableContainer>
      </Container>
    </>
  );
};
