import React from 'react';
import { TransportMovementStatus } from '@scout/types';
import { VesselIcon } from '../Icons/VesselIcon';

export enum VesselStatus {
  UNDER_WAY = 0,
  AT_ANCHOR = 1,
  MOORED = 5,
  TOWED = 11,
}
export interface VesselProps {
  status: VesselStatus;
  heading?: number;
  isSelected?: boolean;
  movementStatus: TransportMovementStatus;
  capacity?: number;
}

export const Vessel = ({ status, heading = 0, isSelected = false, movementStatus, capacity }: VesselProps) => {
  return (
    <VesselIcon
      data-testid="vessel-icon"
      status={status}
      heading={heading}
      isSelected={isSelected}
      movementStatus={movementStatus}
      capacity={capacity}
    />
  );
};
