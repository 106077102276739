import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { InfoIcon } from '../../../components/Icons/InfoIcon';
import { Typography } from '../../../components/Typography';
import { theme } from '../../../theme';

const NoInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
`;

const NoInfoInner = styled.div`
  max-width: 186px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 175px;
`;

const Seperator = styled.div`
  width: 100%;
  border-top: 1px dashed ${() => theme.base.dark.d};
  margin-bottom: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

const DateButton = styled(Button).attrs({ variant: 'ghost-dark', size: 'small' })`
  margin: 0 4px;
`;

const DateButtonLightTheme = styled(Button).attrs({ variant: 'ghost-light', size: 'small' })`
  margin: 0 4px;
`;
const InfoIconContainer = styled.div`
  margin-bottom: 8px;
`;

export interface SidebarProps {
  onDateChange: (date: string) => void;
  nearestStockDates: string[];
  currentUserTheme?: boolean;
}

const NoInfoPanel: React.FunctionComponent<SidebarProps> = ({ onDateChange, nearestStockDates, currentUserTheme }) => {
  return (
    <NoInfoContainer data-testid="no-info-panel">
      <NoInfoInner>
        <InfoIconContainer>
          <InfoIcon />
        </InfoIconContainer>

        <Typography variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center">
          No <strong>stock</strong> information reported on this date
        </Typography>
      </NoInfoInner>
      <Seperator />
      {nearestStockDates.length > 0 && (
        <>
          <Typography variant="body4" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
            These are the nearest dates with stock information:
          </Typography>
          <ButtonContainer>
            {nearestStockDates.map(date => (
              <React.Fragment key={date}>
                {currentUserTheme ? (
                  <DateButtonLightTheme
                    onClick={() => {
                      onDateChange(date);
                    }}
                  >
                    {moment(date).format('D/M/YYYY')}
                  </DateButtonLightTheme>
                ) : (
                  <DateButton
                    onClick={() => {
                      onDateChange(date);
                    }}
                  >
                    {moment(date).format('D/M/YYYY')}
                  </DateButton>
                )}
              </React.Fragment>
            ))}
          </ButtonContainer>
        </>
      )}
    </NoInfoContainer>
  );
};

export { NoInfoPanel };
