import moment, { Moment } from 'moment';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { Loader } from '../../../components/Loader';
import { MarkerType } from '../../../components/Map/Mapbox';
import {
  GET_TANKS_BY_PLANT_ID,
  TanksByPlantIdQuery,
  TanksByPlantIdQueryVariables,
  GET_LOCATION_PRODUCT_STOCK,
  GetStockByLocationQuery,
  GetStockByLocationQueryVariables,
  TankModel,
} from '@scout/types';
import { StocksPanel } from './StocksPanel';
import { calculateUllage } from './TankInfo';
import { TankMetaData } from './TankMeta';
import { PlantIds, PlantRegions } from './helpers';
import { LocationTypes, YYYYMMDD } from '../../../constants';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100vh - 135px);
`;

export interface StockInfoProps {
  isPiStock: boolean;
  maxVolume?: number | null;
  openingSpecification?: string | null;
  openingAmount?: number | null;
  meta?: TankMetaData[];
  level?: Date;
}

export interface StockInfoValue {
  code: string;
  data: StockInfoProps | null;
}

export interface StockInfoGroups {
  [group: string]: StockInfoValue[];
}

export const calculateTotalStock = (stockInfo: StockInfoValue[]) =>
  stockInfo.reduce((sum, stockInfo) => {
    if (!stockInfo.data || !stockInfo.data.openingAmount) {
      return sum;
    }

    return sum + stockInfo.data.openingAmount;
  }, 0);

export const calculateTotalUllage = (stockInfoValue: StockInfoValue[]) =>
  stockInfoValue.reduce((sum, stockInfo) => {
    if (!stockInfo.data) {
      return sum;
    }

    const ullage = calculateUllage({
      maxVolume: stockInfo.data.maxVolume,
      openingAmount: stockInfo.data.openingAmount,
    });

    if (!ullage) {
      return sum;
    }

    return sum + ullage;
  }, 0);

export interface SidebarProps {
  plant: MarkerType;
  filterDate: Moment;
  onDateChange: (moment: Moment) => void;
  currentUserTheme?: boolean;
}

const StockContainer: React.FunctionComponent<SidebarProps> = ({
  plant,
  onDateChange,
  filterDate,
  currentUserTheme,
}) => {
  const { data, loading, error } = useQuery<TanksByPlantIdQuery, TanksByPlantIdQueryVariables>(GET_TANKS_BY_PLANT_ID, {
    skip: plant.region !== PlantRegions.EUROPE && plant.type !== LocationTypes.Refinery,
    variables: { plantId: plant.id, filterDate: filterDate.format(YYYYMMDD) },
    fetchPolicy: 'no-cache',
  });

  const stockList = useQuery<GetStockByLocationQuery, GetStockByLocationQueryVariables>(GET_LOCATION_PRODUCT_STOCK, {
    variables: {
      location: plant.name,
      date: filterDate.format(YYYYMMDD),
      locationType: plant.type,
      plantId: plant.id,
    },
    fetchPolicy: 'no-cache',
  });

  if (
    !(plant.region !== PlantRegions.EUROPE && plant.type !== LocationTypes.Refinery) &&
    (loading || error || !data || !data.tanksByPlantId)
  ) {
    return (
      <LoadingContainer>
        <Loader type="section" error={error && 'Something went wrong'} />
      </LoadingContainer>
    );
  }

  if (stockList.loading || stockList.error || !stockList.data || !stockList.data.getStockByLocation) {
    return (
      <LoadingContainer>
        <Loader type="section" error={error && 'Something went wrong'} />
      </LoadingContainer>
    );
  }

  let tanks: TankModel[] = [];
  let nearestDates: string[] = [];
  if (data !== undefined && data !== null && data.tanksByPlantId !== undefined && data.tanksByPlantId !== null) {
    // @ts-ignore
    tanks = data.tanksByPlantId.tanks;
    nearestDates = data.tanksByPlantId.nearestDates;
  }
  return (
    <StocksPanel
      plantId={plant.id as PlantIds}
      region={plant.region as PlantRegions}
      plantType={plant.type}
      tanks={tanks}
      stockList={stockList.data.getStockByLocation}
      nearestStockDates={nearestDates}
      onDateChange={date => onDateChange(moment(date))}
      filterDate={filterDate}
      currentUserTheme={currentUserTheme}
    />
  );
};

export { StockContainer };
