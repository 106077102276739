import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../../components/Typography';
import { Month } from '../types';
import { sumVolumes } from './calculate';

const BodyCell = styled.td<{ isNumberic?: boolean }>`
  text-align: ${props => props.isNumberic && 'right'};
`;

const BodyCellContent = styled(Typography)`
  border-bottom: 1px solid ${props => props.theme.greys.light.border};
  margin: 10px;
  margin-left: 0;
  min-width: 50px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RadioButton = styled.input`
  margin-right: 5px;
  cursor: pointer;
`;

interface MonthlyVolumesStepValuesRow {
  id: string;
  isEditable?: boolean;
  plantName: string;
  location: string;
  targetTotal: number;
  volumes: { [month in Month]: string };
  type?: string;
  onSelectRow: (rowId: string) => void;
  setSelectedRow?: string;
  disabled?: boolean;
}

const Row = ({
  id,
  plantName,
  location,
  volumes,
  type,
  onSelectRow,
  setSelectedRow,
  disabled,
}: MonthlyVolumesStepValuesRow) => {
  const handleRowSelection = () => {
    onSelectRow(id);
  };

  const monthEntries = Object.entries(volumes);

  return (
    <tr>
      {type === 'formula' && (
        <td>
          <RadioButton
            type="radio"
            name="selectedRow"
            value={id}
            checked={setSelectedRow === id}
            onChange={handleRowSelection}
            disabled={disabled}
          />
        </td>
      )}
      <td>
        <BodyCellContent variant="body3" inline={true} title={plantName}>
          {plantName}
        </BodyCellContent>
      </td>
      <td>
        <BodyCellContent variant="body3" inline={true} title={location}>
          {location}
        </BodyCellContent>
      </td>
      {monthEntries.map(([monthName, monthValue]) => (
        <BodyCell isNumberic={true} key={`${id}-${monthName}`}>
          <BodyCellContent variant="body3" align="right" inline={true}>
            {monthValue}
            <Typography tag="span" color="hintLight" inline={true}>
              t
            </Typography>
          </BodyCellContent>
        </BodyCell>
      ))}

      <BodyCell isNumberic={true}>
        <BodyCellContent variant="body3" align="right" inline={true}>
          {sumVolumes(volumes)}
          <Typography tag="span" color="hintLight" inline={true}>
            t
          </Typography>
        </BodyCellContent>
      </BodyCell>
    </tr>
  );
};

export { Row };
