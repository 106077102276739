import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Typography } from '../Typography';
import { MdArrowForward, MdDateRange } from 'react-icons/md';
import { FormError } from '../FormError/FormError';
import { SelectList, SelectOptions } from '../SelectDropdown/SelectList';
import { fontMixin, FontProps } from '../Mixins';
import { UserThemeContext } from '../../App';
import { theme } from '../../theme';

enum RangeValues {
  From = 'From',
  To = 'To',
}

const SelectContainer = styled.div<{ error?: string } & FontProps>`
  ${fontMixin}
  padding: 0 15px;
  border: 1px solid ${props => (props.error ? props.theme.secondary.light.a : props.theme.greys.light.border)};
  background: ${props => props.theme.inputBackground};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
`;

const Arrow = styled(MdArrowForward)`
  width: 15px;
  height: 15px;
`;

const Label = styled(Typography).attrs({ align: 'center' })<{ onClick: () => void }>`
  flex: 1;
  cursor: pointer;
`;

const Calendar = styled(MdDateRange)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const MonthList = styled(SelectList)<{ type: RangeValues }>`
  width: 184px;
  margin-top: 2px;
  position: absolute;
  ${p => p.type === RangeValues.To && 'right: 0;'}
`;

const ListContainer = styled.div`
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

export interface SelectedItem {
  from: SelectOptions | null;
  to: SelectOptions | null;
}

export interface MonthItem {
  readonly: boolean;
  date: string;
}

interface Item {
  label: string;
  value: string;
}

const createLabel = (monthItem: MonthItem) => `${moment(new Date(monthItem.date)).format('MMM YYYY')}`;

const getMonths = ({
  type,
  selectedMonth,
  monthItems,
  limit,
}: {
  type: RangeValues;
  selectedMonth: SelectOptions;
  monthItems: MonthItem[];
  limit?: number;
}) => {
  if (type === RangeValues.From) {
    return monthItems
      .filter(item => !item.readonly)
      .map(item => {
        const label = createLabel(item);

        return {
          label,
          value: item.date,
        };
      });
  }

  return monthItems.reduce((previousValues: Item[], current: MonthItem) => {
    const label = createLabel(current);

    if (current.readonly || !selectedMonth) {
      return [];
    }

    if (label === selectedMonth.label || previousValues.length) {
      if (limit && previousValues.length === limit) {
        return previousValues;
      }
      return [
        ...previousValues,
        {
          label: createLabel(current),
          value: current.date,
        },
      ];
    }

    return [];
  }, []);
};

interface Props {
  onItemClick: (item: SelectedItem) => void;
  selected: SelectedItem;
  monthItems: MonthItem[];
  error?: string;
  limit?: number;
}

const MonthRangeSelect = ({ onItemClick, selected, monthItems, error, limit }: Props) => {
  const [showToMenu, toggleToMenu] = React.useState(false);
  const [showFromMenu, toggleFromMenu] = React.useState(false);
  const { currentUserTheme } = React.useContext(UserThemeContext);
  const selectedFrom: SelectOptions =
    selected && selected.from
      ? selected.from
      : { value: RangeValues.From.toString(), label: RangeValues.From.toString() };
  const selectedTo: SelectOptions =
    selected && selected.to ? selected.to : { value: RangeValues.To.toString(), label: RangeValues.To.toString() };

  const handleShowFrom = React.useCallback(() => {
    toggleToMenu(false);
    toggleFromMenu(!showFromMenu);
  }, [showFromMenu, toggleFromMenu, toggleToMenu]);

  const handleShowTo = React.useCallback(() => {
    if (selectedFrom.label !== 'From') {
      toggleFromMenu(false);
      toggleToMenu(!showToMenu);
    }
  }, [selectedFrom, showToMenu, toggleFromMenu, toggleToMenu]);

  const handleBackgroundClick = React.useCallback(() => {
    toggleFromMenu(false);
    toggleToMenu(false);
  }, [toggleFromMenu, toggleToMenu]);

  React.useEffect(() => {
    if (!showFromMenu && !showToMenu) {
      return;
    }

    const callback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggleFromMenu(false);
        toggleToMenu(false);
      }
    };

    document.addEventListener('keydown', callback);

    return () => {
      if (!showFromMenu && !showToMenu) {
        return;
      }
      document.removeEventListener('keydown', callback);
    };
  }, [showFromMenu, showToMenu, toggleFromMenu, toggleToMenu]);
  return (
    <>
      <SelectContainer variant="body3" error={error}>
        <Label
          data-testid="month-range-from-label"
          color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
          onClick={handleShowFrom}
        >
          {selectedFrom.label}
        </Label>
        <Arrow color={currentUserTheme ? theme.base.dark.a : theme.base.light.a} />
        <Label
          data-testid="month-range-to-label"
          color={selectedFrom ? 'hintDark' : 'hintLight'}
          onClick={handleShowTo}
        >
          {selectedTo.label}
        </Label>
        <Calendar onClick={handleShowTo} color={currentUserTheme ? theme.base.dark.a : theme.base.light.a} />
      </SelectContainer>
      {error && <FormError message={error} />}
      <ListContainer>
        <Inner>
          {(showFromMenu || showToMenu) && <Background onClick={handleBackgroundClick} />}
          {showFromMenu && (
            <MonthList
              type={RangeValues.From}
              onItemClick={(value, label) => onItemClick({ to: selected ? selected.to : null, from: { label, value } })}
              items={getMonths({ type: RangeValues.From, selectedMonth: selectedFrom, monthItems })}
              placeholder=""
              toggleMenu={toggleFromMenu}
              selectedItem={selectedFrom}
            />
          )}
          {showToMenu && (
            <MonthList
              type={RangeValues.To}
              onItemClick={(value, label) =>
                onItemClick({ to: { label, value }, from: selected ? selected.from : null })
              }
              items={getMonths({ type: RangeValues.To, selectedMonth: selectedFrom, monthItems, limit })}
              placeholder=""
              toggleMenu={toggleToMenu}
              selectedItem={selectedTo}
            />
          )}
        </Inner>
      </ListContainer>
    </>
  );
};

export { MonthRangeSelect, getMonths, RangeValues };
