import React from 'react';
import { Cell } from '../../../../components/Table';
import { Typography } from '../../../../components/Typography';
import { MonthlyVolumeType } from '../types';

interface Props {
  year: number;
  monthlyVolumeTotals: MonthlyVolumeType[];
  volumeTotal: number;
  isVisible: boolean;
  currentUserTheme?: boolean;
}

const parseVolume = (volume: number) => `${parseFloat(volume.toString()) / 1000}Kt`;
export const TableFooter = ({ monthlyVolumeTotals, volumeTotal, year, isVisible, currentUserTheme }: Props) => {
  return isVisible ? (
    <tr>
      <Cell colSpan={3} sticky="bottomLeft" justify="flex-end" currentUserTheme={currentUserTheme}>
        <Typography variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} overflowHidden={true}>
          <strong>Total</strong> (filtered):
        </Typography>
      </Cell>
      <Cell sticky="bottom" currentUserTheme={currentUserTheme} />
      <Cell sticky="bottom" currentUserTheme={currentUserTheme} />
      <Cell sticky="bottom" currentUserTheme={currentUserTheme} />
      <Cell sticky="bottom" currentUserTheme={currentUserTheme} />
      <Cell sticky="bottom" currentUserTheme={currentUserTheme} />
      <Cell sticky="bottom" text={parseVolume(volumeTotal)} justify="flex-end" currentUserTheme={currentUserTheme} />
      {monthlyVolumeTotals.map(monthTotal => (
        <React.Fragment key={`${year}-${monthTotal.month}-01-footer-group`}>
          <Cell
            sticky="bottom"
            text={parseVolume(monthTotal.volume)}
            justify="flex-end"
            currentUserTheme={currentUserTheme}
          />
          <Cell sticky="bottom" text="-" justify="flex-end" currentUserTheme={currentUserTheme} />
        </React.Fragment>
      ))}
    </tr>
  ) : null;
};
