import { GetCountriesListQuery, GET_COUNTRY_LIST } from '@scout/types/dist';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { UserThemeContext } from '../../../App';
import { Loader } from '../../../components/Loader';
import { DefaultLayout } from '../../../layouts';
import { VesselFlowAnalysisForm } from './VesselFlowAnalysisForm';

const Container = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 82px);
  background-color: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
`;

export const VesselFlowAnalysis: React.FC = () => {
  const { currentUserTheme } = useContext(UserThemeContext);

  const { data, error, loading } = useQuery<GetCountriesListQuery>(GET_COUNTRY_LIST);

  if (loading || error) {
    return <Loader type="section" error={error ? 'Something went wrong' : undefined} />;
  }

  return (
    <DefaultLayout>
      <Container currentUserTheme={currentUserTheme}>
        <VesselFlowAnalysisForm currentUserTheme={currentUserTheme} countryList={data ? data.getCountriesList : []} />
      </Container>
    </DefaultLayout>
  );
};
