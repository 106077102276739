import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import moment, { Moment } from 'moment-timezone';
import { Container } from '../../../components/Container';
import { Col, Grid, Row } from '../../../components/Grid';
import { Tag } from '../../../components/Tag/Tag';
import { Typography } from '../../../components/Typography';
import {
  GET_CUSTOMER_BY_ID,
  GET_SHIPPING_LOCATIONS_BY_CUSTOMER_ID,
  GET_REGION_BY_ID,
  CustomerByIdQuery,
  CustomerByIdQueryVariables,
  CustomerModel,
  RegionByIdQuery,
  RegionByIdQueryVariables,
  RegionModel,
  ShipmentType,
  ShippingLocationsByCustomerIdQuery,
  ShippingLocationsByCustomerIdQueryVariables,
  UserModel,
} from '@scout/types';
import { UserThemeContext } from '../../../App';

const Root = styled.div<{ currentUserTheme?: boolean }>`
  background: ${props => (props.currentUserTheme ? props.theme.secondary.light.c : props.theme.base.dark.c)};
`;

const Inner = styled.div`
  margin: 1.5rem 0;
`;

const Label = styled(Typography)`
  margin: 0 0 5px;
`;

const EditLink = styled(Typography)<{ onClick?: () => void }>`
  align-self: center;
  border-left: 1px solid ${p => p.theme.greys.dark.border};
  cursor: pointer;
  margin-left: 15px;
  padding-left: 15px;
`;

const Line = styled.div`
  width: 32px;
  height: 1px;
  border-bottom: 4px solid ${p => p.theme.primary.b};
  margin-top: 6px;
  margin-bottom: 15px;
`;

const CustomerName = styled(Typography)`
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Spacer = styled.div`
  width: 1px;
  margin-left: 25px;
  padding-left: 25px;
  margin-top: 15px;
  border-left: 1px solid ${p => p.theme.greys.dark.border};
`;

const StyledTag = styled(Tag)`
  margin-left: 5px;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 5px;
`;

interface HeaderProps {
  initialValues?: {
    soldTo?: Pick<CustomerModel, 'id' | 'name' | 'code' | 'salesOrganisation'>;
    shipTo?: Pick<CustomerModel, 'id' | 'name' | 'code' | 'salesOrganisation'>;
    region?: Pick<RegionModel, 'id' | 'name'>;
    createdBy?: Pick<UserModel, 'id' | 'emailAddress'>;
    updatedBy?: Pick<UserModel, 'id' | 'emailAddress'>;
    createdAt?: Pick<UserModel, 'createdAt'> | 'string';
    updatedAt?: Pick<UserModel, 'updatedAt'> | 'string';
  };
  customerId?: string;
  regionId?: string;
  shipToId?: string;
  shipmentType: string;
  onEdit?(): void;
}

const TaggedContent: React.FunctionComponent<{ title: string; tag: string }> = props => {
  return (
    <TagWrapper>
      <Typography variant="labels1" color="hintLight">
        {props.title}
      </Typography>
      <StyledTag color="highlight">{props.tag}</StyledTag>
    </TagWrapper>
  );
};
const Header: React.FunctionComponent<HeaderProps> = ({
  initialValues = {},
  customerId,
  regionId,
  shipToId,
  shipmentType,
  onEdit,
}) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const soldToRequest = useQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(GET_CUSTOMER_BY_ID, {
    variables: { id: customerId || '' },
    skip: !Boolean(customerId) || Boolean(initialValues.soldTo),
  });

  const shipToRequest = useQuery<ShippingLocationsByCustomerIdQuery, ShippingLocationsByCustomerIdQueryVariables>(
    GET_SHIPPING_LOCATIONS_BY_CUSTOMER_ID,
    {
      variables: { id: shipToId || '' },
      skip: !Boolean(shipToId) || Boolean(initialValues.shipTo),
    },
  );

  const regionRequest = useQuery<RegionByIdQuery, RegionByIdQueryVariables>(GET_REGION_BY_ID, {
    variables: { id: regionId || '' },
    skip: !Boolean(regionId) || Boolean(initialValues.region),
  });

  let soldToData = initialValues.soldTo;
  const createdByEmail = initialValues.createdBy;
  const updatedByEmail = initialValues.updatedBy;
  const createdAtDate = initialValues.createdAt;
  const updatedAtDate = initialValues.updatedAt;
  if (!soldToData && soldToRequest.data && soldToRequest.data.customerById) {
    soldToData = soldToRequest.data.customerById;
  }

  let shipToData = initialValues.shipTo;

  if (!shipToData && shipToRequest.data && shipToRequest.data.customerById) {
    shipToData = shipToRequest.data.customerById;
  }

  let regionData = initialValues.region;

  if (!regionData && regionRequest.data && regionRequest.data.regionById) {
    regionData = regionRequest.data.regionById;
  }
  const dateFormat = 'YYYY/MM/DD hh:mm A';
  const localDateTime = (date: moment.MomentInput) => {
    return moment
      .utc(date)
      .local()
      .format(dateFormat);
  };

  return (
    <Root currentUserTheme={currentUserTheme}>
      <Container>
        <Inner>
          <Grid>
            <Row>
              <Col>
                <Row>
                  <Typography
                    data-testid="header-title"
                    variant="heading5"
                    color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                    bold={true}
                  >
                    {`Spot - ${shipmentType === ShipmentType.Delivery ? 'Delivered' : 'Pick up'}`}
                  </Typography>
                  {onEdit && (
                    <EditLink
                      color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                      onClick={onEdit}
                      tag="a"
                      data-testid="header-edit"
                    >
                      Edit
                    </EditLink>
                  )}
                </Row>
                <Row>
                  <Line />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  {soldToData && (
                    <Col data-testid="header-sold-to">
                      <Label variant="labels1" color="hintLight">
                        Sold-to:
                      </Label>
                      <CustomerName
                        variant="body4"
                        color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                        title={soldToData.name}
                      >
                        {soldToData.name}
                      </CustomerName>
                      <TaggedContent title={soldToData.code} tag={soldToData.salesOrganisation} />
                    </Col>
                  )}

                  <Spacer />
                  {shipToData && (
                    <Col data-testid="header-ship-to">
                      <Label variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                        Ship-to:
                      </Label>
                      <CustomerName
                        variant="body4"
                        color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                        title={shipToData.name}
                      >
                        {shipToData.name}
                      </CustomerName>
                      <TaggedContent title={shipToData.code} tag={shipToData.salesOrganisation} />
                    </Col>
                  )}
                  {regionData && (
                    <Col data-testid="header-region">
                      <Label variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                        Region:
                      </Label>
                      <Typography variant="body4" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                        {regionData.name}
                      </Typography>
                    </Col>
                  )}
                  {regionData && <Spacer />}
                  {createdByEmail && (
                    <Col data-testid="header-region">
                      <Label variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                        Created By:
                      </Label>
                      <Typography variant="body4" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                        {createdByEmail.emailAddress}
                      </Typography>
                      <Label variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                        {localDateTime(createdAtDate?.toString())}
                      </Label>
                    </Col>
                  )}
                  {updatedByEmail && <Spacer />}
                  {updatedByEmail && (
                    <Col data-testid="header-region">
                      <Label variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                        Updated By:
                      </Label>
                      <Typography variant="body4" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                        {updatedByEmail.emailAddress}
                      </Typography>
                      <Label variant="labels1" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
                        {localDateTime(updatedAtDate?.toString())}
                      </Label>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Grid>
        </Inner>
      </Container>
    </Root>
  );
};

export { Header };
