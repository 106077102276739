import React from 'react';
import styled from 'styled-components';
import { Alert, AlertTypes, AlertSizes, AlertBoxStyles } from '../Alert/Alert';

export interface ContextProps {
  push: (toastProps: ToastProps) => void;
}

const ToastContext = React.createContext<ContextProps>({
  push: () => undefined,
});

let toastCount = 0;

const ToastBox = styled.div`
  position: fixed;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
  z-index: 99999;
`;

const ToastAlertBox = styled(Alert)`
  margin-bottom: 10px;
`;

export interface ToastProps {
  text: string;
  type?: AlertTypes;
  size?: AlertSizes;
  boxStyle?: AlertBoxStyles;
  dark?: boolean;
}

type ToastAlertProps = ToastProps & {
  id: number;
  text: string;
  onPopToast: (id: number) => void;
  expiry?: number;
};

const DEFAULT_TOAST_EXPIRY = 3000;

const ToastAlert = (props: ToastAlertProps) => {
  React.useEffect(() => {
    const timerID = setTimeout(() => {
      props.onPopToast(props.id);
    }, props.expiry || DEFAULT_TOAST_EXPIRY);

    return () => {
      clearTimeout(timerID);
    };
  }, []);

  return <ToastAlertBox {...props} onClose={() => props.onPopToast(props.id)} />;
};

type ToastState = ToastProps & {
  id: number;
};

export const Toaster = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = React.useState<ToastState[]>([]);

  const toastRef = React.useRef(toasts);
  React.useEffect(() => {
    toastRef.current = toasts;
  });

  const push = (toastProps: ToastProps) => {
    const toast: ToastState = {
      id: toastCount++,
      ...toastProps,
    };

    if (!toasts.find(existingToast => existingToast.text === toast.text)) {
      setToasts([...toasts, toast]);
    }
  };

  const handlePopToast = (id: number) => {
    setToasts(toastRef.current.filter(t => t.id !== id));
  };

  return (
    <ToastContext.Provider value={{ push }}>
      {children}
      <ToastBox>
        {toasts.map(({ id, ...rest }) => (
          <ToastAlert key={id} id={id} onPopToast={handlePopToast} {...rest} />
        ))}
      </ToastBox>
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
