import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const PowerBIIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: 'luminosity' }}>
      <mask
        id="mask0_6614_42797"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="0"
        width="48"
        height="62"
      >
        <path
          d="M33.5833 2.58333C33.5833 1.1566 34.74 0 36.1667 0H51.6667C53.0934 0 54.25 1.1566 54.25 2.58333V59.4167C54.25 60.8434 53.0934 62 51.6667 62H10.3333C8.90662 62 7.75 60.8434 7.75 59.4167V33.5833C7.75 32.1566 8.90662 31 10.3333 31H20.6667V18.0833C20.6667 16.6566 21.8234 15.5 23.2501 15.5H33.5833V2.58333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6614_42797)">
        <path d="M54.2499 0V62H33.5833V0H54.2499Z" fill="url(#paint0_linear_6614_42797)" />
        <g filter="url(#filter0_f_6614_42797)">
          <path
            d="M41.3334 18.3421V62.2588H20.6667V15.7588H38.7501C40.1768 15.7588 41.3334 16.9154 41.3334 18.3421Z"
            fill="black"
            fillOpacity="0.2"
          />
        </g>
        <g filter="url(#filter1_f_6614_42797)">
          <path
            d="M41.3334 19.3753V63.292H20.6667V16.792H38.7501C40.1768 16.792 41.3334 17.9486 41.3334 19.3753Z"
            fill="black"
            fillOpacity="0.18"
          />
        </g>
        <path
          d="M41.3334 18.0833V62H20.6667V15.5H38.7501C40.1768 15.5 41.3334 16.6566 41.3334 18.0833Z"
          fill="url(#paint1_linear_6614_42797)"
        />
        <path
          d="M7.75 31V62H28.4167V33.5833C28.4167 32.1566 27.26 31 25.8333 31H7.75Z"
          fill="url(#paint2_linear_6614_42797)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_6614_42797"
        x="19.8667"
        y="14.9588"
        width="22.2667"
        height="48.1"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_6614_42797" />
      </filter>
      <filter
        id="filter1_f_6614_42797"
        x="12.6667"
        y="8.79199"
        width="36.6667"
        height="62.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_6614_42797" />
      </filter>
      <linearGradient
        id="paint0_linear_6614_42797"
        x1="30.1388"
        y1="-3.39e-07"
        x2="57.3921"
        y2="57.7676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6AD10" />
        <stop offset="1" stopColor="#C87E0E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6614_42797"
        x1="20.6651"
        y1="15.5"
        x2="43.5847"
        y2="59.9503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6D751" />
        <stop offset="1" stopColor="#E6AD10" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6614_42797"
        x1="7.74832"
        y1="31"
        x2="20.4137"
        y2="61.0167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E589" />
        <stop offset="1" stopColor="#F6D751" />
      </linearGradient>
    </defs>
  </svg>
));
