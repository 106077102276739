import moment from 'moment';
import React from 'react';
import ReactMapboxGl, { Cluster, Map, Marker, Popup } from 'react-mapbox-gl';
import styled from 'styled-components';
import { config } from '../../config';
import { LocationTypes } from '../../constants';
import { TransportQueryResult, TransportsQueryResult } from '@scout/types';
import { Col, Row } from '../Grid';
import { RequirePermission } from '../RequirePermission';
import { Typography } from '../Typography';
import { ClusterMarker } from './ClusterMarker';
import { getPopupVessel, handleEmpty, getVesselEta, getCalculatedVesselEta, getReportedVesselEta } from './helpers';
import { LocationMarker } from './LocationMarker';
import { PlanningButton } from './PlanningButton';
import { Vessel } from './Vessel';
import { VesselSelect } from './VesselSelect';
import { ZoomContol } from './ZoomControl';
import { useMapContext } from '../../pages/InMonth/MapContext';
import { WeatherLink } from '../WeatherLink';
import { DEFAULT_ID } from '../../pages/InMonth/markers';

const MarkerRoot = styled.div`
  position: relative;
  cursor: pointer;
`;

const Root = styled.div`
  position: relative;
`;

const VesselShipName = styled.div`
  background: '#303641';
`;

const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const MoreInfoLink = styled.a`
  color: ${p => p.theme.base.dark.d};
`;

export interface MarkerType {
  id: string;
  type: LocationTypes;
  lat: number;
  lng: number;
  name: string;
  active: boolean;
  isPiStock?: true;
  region: string;
}

export interface MapProps {
  selectPlant: (plant: MarkerType) => void;
  center: [number, number];
  markers: MarkerType[];
  vessels: TransportsQueryResult;
  selectedPlant: MarkerType | null;
  openScheduler: () => void;
  schedulerIsOpened: boolean;
  fullViewActive: boolean;
  currentUserTheme?: boolean;
}

const MapComponent = ReactMapboxGl({
  accessToken: config.MAP_TOKEN,
  touchZoomRotate: false,
  doubleClickZoom: false,
});

export interface PopupState {
  vessel: TransportQueryResult | null;
  marker?: MarkerType;
}

interface Map {
  getCenter: () => { lng: number; lat: number };
  getZoom: () => number;
}

const Mapbox = ({
  markers,
  fullViewActive,
  vessels,
  selectPlant,
  selectedPlant,
  openScheduler,
  schedulerIsOpened,
  currentUserTheme,
}: MapProps) => {
  const {
    state: { popup, viewport },
    action: { togglePopup, setViewport },
  } = useMapContext();

  // const eta = getVesselEta(popup?.vessel);
  const reportedEta = getReportedVesselEta(popup?.vessel);
  const calcEta = getCalculatedVesselEta(popup?.vessel);
  const popupSettings = {
    offset: {
      'bottom-left': [12, -38],
      bottom: [0, -25],
      'bottom-right': [-12, -38],
    },
  };
  return (
    <Root>
      <ZoomContol
        onClickMinus={() =>
          setViewport({
            ...viewport,
            zoom: viewport.zoom - 1,
          })
        }
        onClickPlus={() =>
          setViewport({
            ...viewport,
            zoom: viewport.zoom + 1,
          })
        }
      />
      <RequirePermission anyOf={['editFleetManagement']}>
        {!schedulerIsOpened && <PlanningButton onClick={openScheduler} user="europe" />}
      </RequirePermission>
      <RequirePermission anyOf={['editEastSchedulingManager', 'eastDepotUser']}>
        {!schedulerIsOpened && <PlanningButton onClick={openScheduler} user="east" />}
      </RequirePermission>

      <RequirePermission anyOf={['viewFleetManagement']}>
        <VesselSelect
          popupSelectedVessel={popup.vessel}
          vessels={vessels}
          onSelectVessel={vesselId => {
            const foundVessel = vessels.find(vessel => vessel.id === vesselId) || null;
            togglePopup({ vessel: foundVessel });

            if (foundVessel) {
              setViewport({
                center: [foundVessel.lon, foundVessel.lat],
                zoom: 9,
              });
            }
          }}
        />
      </RequirePermission>
      <RequirePermission anyOf={['editEastSchedulingManager', 'eastDepotUser']}>
        {!schedulerIsOpened && (
          <VesselSelect
            popupSelectedVessel={popup.vessel}
            vessels={vessels}
            onSelectVessel={vesselId => {
              const foundVessel = vessels.find(vessel => vessel.id === vesselId) || null;
              togglePopup({ vessel: foundVessel });

              if (foundVessel) {
                setViewport({
                  center: [foundVessel.lon, foundVessel.lat],
                  zoom: 9,
                });
              }
            }}
            currentUserTheme={currentUserTheme}
          />
        )}
        {fullViewActive && (
          <VesselSelect
            popupSelectedVessel={popup.vessel}
            vessels={vessels}
            onSelectVessel={vesselId => {
              const foundVessel = vessels.find(vessel => vessel.id === vesselId) || null;
              togglePopup({ vessel: foundVessel });

              if (foundVessel) {
                setViewport({
                  center: [foundVessel.lon, foundVessel.lat],
                  zoom: 9,
                });
              }
            }}
            currentUserTheme={currentUserTheme}
          />
        )}
      </RequirePermission>

      <MapComponent
        key={`mapComponent-${schedulerIsOpened}`}
        center={viewport.center}
        zoom={[viewport.zoom]}
        onZoomEnd={(map: Map) => {
          const { lng, lat } = map.getCenter();
          const zoom = map.getZoom();
          setViewport({ zoom, center: [lng, lat] });
        }}
        onClick={() => togglePopup({ vessel: null })}
        style={config.MAP_THEME}
        containerStyle={{
          height: 'calc(100vh - 78px)',
          width: '100%',
        }}
      >
        <>
          {vessels.map((vessel, index) => (
            <Marker
              key={`vessel-${index}`}
              coordinates={[vessel.lon, vessel.lat]}
              onClick={() => togglePopup({ vessel: getPopupVessel(popup, vessel) })}
              anchor="center"
            >
              <MarkerRoot>
                <Vessel
                  isSelected={popup.vessel?.id === vessel.id}
                  status={vessel.status}
                  heading={vessel.heading}
                  movementStatus={vessel.movementStatus}
                  capacity={vessel?.capacity ? vessel.capacity : undefined}
                />
              </MarkerRoot>
            </Marker>
          ))}
          <Cluster ClusterMarkerFactory={ClusterMarker} zoomOnClick={true} radius={30} zoomOnClickPadding={100}>
            {markers.map((marker, markerIndex) => {
              const isMarkerSelected = selectedPlant && selectedPlant.name === marker.name ? true : false;
              return (
                <Marker
                  key={`marker-${markerIndex}`}
                  onClick={() => (marker.active ? selectPlant(marker) : togglePopup({ marker, vessel: null }))}
                  coordinates={[marker.lng, marker.lat]}
                  anchor="center"
                >
                  <LocationMarker marker={marker} isSelected={isMarkerSelected} />
                </Marker>
              );
            })}
          </Cluster>
          {popup.vessel && (
            <>
              <Popup coordinates={[popup.vessel.lon, popup.vessel.lat]} {...popupSettings} style={{ minWidth: '19%' }}>
                <Col data-testid="vessel-popup">
                  <Row>
                    {/* prettier-ignore */}
                    <Typography variant="labels1" bold={true}>
                    <VesselShipName>
                      {`${handleEmpty(popup.vessel.shipName)} (${handleEmpty(popup.vessel.flag)})`}
                    </VesselShipName>
                    </Typography>
                  </Row>

                  <Row>
                    <Col size={6}>
                      <Row>
                        <Typography variant="labels1">Next port:</Typography>
                      </Row>
                      <Row>
                        <Typography variant="labels1" bold={true}>
                          {handleEmpty(popup.vessel.nextPortName)}
                        </Typography>
                      </Row>
                    </Col>

                    <hr style={{ width: '1', marginLeft: '-8%', marginRight: '1%' }} />

                    <Col size={11}>
                      <Row>
                        <Typography variant="labels1">ETA:</Typography>
                      </Row>
                      <Row>
                        <Typography variant="labels1" bold={true}>
                          {reportedEta ? 'R -' + moment(reportedEta).format('D MMM YY | h:mm A') : 'R - No info'}
                        </Typography>
                      </Row>
                      <Row>
                        <Typography variant="labels1" bold={true}>
                          {calcEta ? 'C -' + moment(calcEta).format('D MMM YY | h:mm A') : 'C - No info'}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>

                  <hr />
                  <Row>
                    <Typography variant="labels1">East Last update:</Typography>
                  </Row>
                  <Row>
                    <SpaceBetweenContainer>
                      <Typography variant="labels1" bold={true}>
                        {moment(popup.vessel.updatedAt).format('D MMM YY | h:mm A')}
                      </Typography>
                      <MoreInfoLink
                        href={`https://www.marinetraffic.com/en/ais/details/ships/mmsi:${popup.vessel.id}`}
                        target="_blank"
                        onClick={e => e.stopPropagation()}
                      >
                        More Info
                      </MoreInfoLink>
                    </SpaceBetweenContainer>
                  </Row>
                </Col>
              </Popup>
            </>
          )}
          {popup.marker && (
            <>
              <Popup coordinates={[popup.marker.lng, popup.marker.lat]} {...popupSettings} style={{ minWidth: '9%' }}>
                <Col data-testid="inactive-plant-popup">
                  <Row>
                    <Col size={12}>
                      <Row>
                        <Typography variant="labels1" bold={true}>
                          {popup.marker.name}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>

                  <hr />
                  <Row>
                    <Typography variant="labels1">
                      <WeatherLink port={popup.marker.id === DEFAULT_ID ? popup.marker.name : popup.marker.id}>
                        Weather Info
                      </WeatherLink>
                    </Typography>
                  </Row>
                </Col>
              </Popup>
            </>
          )}
        </>
      </MapComponent>
    </Root>
  );
};

export { Mapbox };
