import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../components/Typography';
import { StockInfoProps } from './StocksContainer';
import { TankMeta } from './TankMeta';
import { formatKt, getProgress } from './helpers';
import { useHover } from '../../../hooks/useHover';
import moment, { Moment } from 'moment';
const TankInfoContainer = styled.div`
  margin: 0 10px;
  margin-bottom: 24px;
  display: inline-flex;
  width: 100px;
  align-items: center;
  justify-content: center;
`;

const Root = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
`;

const TankMetaOverlay = styled.div`
  right: -10px;
  left: -10px;
  top: 25px;
  align-items: center;
  position: absolute;
  background-color: white;
  color: black;
  min-height: 40px;
  width: max-content;
  padding: 12px;

  border-radius: 2px;
  box-shadow: 0px 10px 38px 0px rgba(0, 0, 0, 0.75);
  z-index: 9999;

  &:after {
    right: 8px;
    left: 8px;
    bottom: calc(100% - 2px);
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${p => p.theme.base.light.a};
    border-width: 10px;
  }
`;
const Row = styled.div`
  padding: 5px 0;
  display: flex;
`;

const Line = styled.div<{ currentUserTheme?: boolean }>`
  margin: 6px 0;
  height: 1px;
  background: ${props => (props.currentUserTheme ? 'lightGrey' : props.theme.base.dark.d)};
`;

const Block = styled.div`
  flex: 1;
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BarOuter = styled.div<{ isPiStock: boolean; currentUserTheme?: boolean }>`
  width: ${({ isPiStock }) => (isPiStock ? 'calc(100% - 20px)' : '100%')};
  height: 14px;
  background: ${props => (props.currentUserTheme ? 'none' : props.theme.base.dark.d)};
  border-radius: 100px;
`;

const BarInner = styled.div<{ currentUserTheme?: boolean }>`
  width: 96%;
  margin: 0;
  height: 10px;
  border: 2px solid ${props => (props.currentUserTheme ? 'grey' : props.theme.base.dark.b)};
  background: ${props => (props.currentUserTheme ? props.theme.secondary.light.c : props.theme.base.dark.d)};
  border-radius: 100px;
  position: relative;
  top: 2px;
  left: 2px;
  display: flex;
  cursor: pointer;
`;

const BarProgress = styled.div<{ percentage: number }>`
  height: 100%;
  width: ${p => p.percentage}%;
  background: ${props => props.theme.primary.b};
`;

export const calculateUllage = ({
  maxVolume,
  openingAmount,
}: {
  maxVolume?: number | null;
  openingAmount?: number | null;
}) => (maxVolume && openingAmount ? maxVolume - openingAmount : null);

const NoTankInfo = (props: { code: string; currentUserTheme?: boolean }) => (
  <Root>
    <Row>
      <Block>
        <Typography variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}>
          <span data-testid="tank-code">{props.code}</span>
        </Typography>
      </Block>
    </Row>
    <Line />
    <Row>
      <Block>
        <Typography variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
          <span data-testid="tank-opening-amount">No info</span>
        </Typography>
      </Block>
    </Row>
  </Root>
);

const TankInfo = (props: { code: string; data: StockInfoProps | null; currentUserTheme?: boolean }) => {
  const stockInfo = props.data;
  const isEmptyTank = !stockInfo || !stockInfo.openingAmount;
  if (isEmptyTank) {
    return (
      <TankInfoContainer>
        <NoTankInfo code={props.code} currentUserTheme={props.currentUserTheme} />
      </TankInfoContainer>
    );
  }

  if (!stockInfo) {
    return <NoTankInfo code={props.code} currentUserTheme={props.currentUserTheme} />;
  }

  const ullage = calculateUllage({ maxVolume: stockInfo.maxVolume, openingAmount: stockInfo.openingAmount });
  const [hovered, hoverEvents] = useHover();
  return (
    <TankInfoContainer>
      <Root>
        <Row>
          <Block>
            <Typography variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}>
              <span data-testid="tank-code">{props.code}</span>
            </Typography>
          </Block>
          <Typography variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
            <span data-testid="tank-opening-spec">{stockInfo.openingSpecification || props.code}</span>
          </Typography>
        </Row>
        <Line currentUserTheme={props.currentUserTheme} />
        <Row>
          <Block>
            <Typography variant="labels1" color="highlight" bold={true}>
              <span data-testid="tank-opening-amount">{formatKt(stockInfo.openingAmount)}</span>
            </Typography>
          </Block>
          <div>
            <Typography variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
              <span data-testid="tank-ullage">{formatKt(ullage)}</span>
            </Typography>
          </div>
        </Row>
        <BarWrapper>
          <BarOuter isPiStock={stockInfo.isPiStock} currentUserTheme={props.currentUserTheme}>
            <BarInner {...hoverEvents} currentUserTheme={props.currentUserTheme}>
              <BarProgress
                percentage={getProgress({ maxVolume: stockInfo.maxVolume, openingAmount: stockInfo.openingAmount })}
              />
              {hovered && stockInfo.level && (
                <TankMetaOverlay>
                  <Typography variant="labels1">
                    <span>Last Updated:</span>
                  </Typography>
                  <Typography variant="labels1" bold={true}>
                    <span data-testid="tank-meta-value-update">
                      {moment(stockInfo.level).format('DD-MM-YYYY HH:mm:ss')}
                    </span>
                  </Typography>
                </TankMetaOverlay>
              )}
            </BarInner>
          </BarOuter>
          {stockInfo.isPiStock && stockInfo.meta && <TankMeta meta={stockInfo.meta} />}
        </BarWrapper>
      </Root>
    </TankInfoContainer>
  );
};

export { TankInfo };
