import * as React from 'react';
import styled from 'styled-components';
import { FontColors, FontVariants } from '../../theme';
import { colorMixin, fontMixin } from '../Mixins';

interface TypographyProps extends React.PropsWithoutRef<JSX.IntrinsicElements['span']> {
  color?: FontColors;
  variant?: FontVariants;
  bold?: boolean;
  children: React.ReactNode;
  tag?: 'p' | 'span' | 'strong' | 'i' | 'label' | 'div' | 'a';
  align?: 'left' | 'center' | 'right' | 'justify';
  inline?: boolean;
  title?: string;
  overflowHidden?: boolean;
  userSelect?: boolean;
  currentUserTheme?: boolean;
}

export const StyledText = styled.p<TypographyProps>`
  ${fontMixin}
  ${colorMixin}
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  user-select: ${p => (p.userSelect === false ? 'none' : 'auto')};
`;

export const Typography = ({ children, tag = 'p', userSelect = true, ...rest }: TypographyProps) => (
  <StyledText as={tag} {...rest}>
    {children}
  </StyledText>
);
