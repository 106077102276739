import React from 'react';
import { withTheme } from 'styled-components';

export const TankMetaIcon: React.FC = withTheme(({ theme }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.500488 7.99951C0.500488 3.85951 3.86049 0.499512 8.00049 0.499512C12.1405 0.499512 15.5005 3.85951 15.5005 7.99951C15.5005 12.1395 12.1405 15.4995 8.00049 15.4995C3.86049 15.4995 0.500488 12.1395 0.500488 7.99951ZM8.75049 7.24951V11.7495H7.25049V7.24951H8.75049ZM8.00118 13.9992C4.69368 13.9992 2.00118 11.3067 2.00118 7.99917C2.00118 4.69167 4.69368 1.99917 8.00118 1.99917C11.3087 1.99917 14.0012 4.69167 14.0012 7.99917C14.0012 11.3067 11.3087 13.9992 8.00118 13.9992ZM8.75049 4.24917V5.74917H7.25049V4.24917H8.75049Z"
      fill={theme.greys.dark.border}
    />
  </svg>
));
