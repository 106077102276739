import React from 'react';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { useHover } from '../../../../../hooks/useHover';
import { LocationNoteType } from '@scout/types';
import { NoteWarningIcon } from '../../../../../components/Icons/NoteWarningIcon';
import { NoteInfoIcon } from '../../../../../components/Icons/NoteInfoIcon';
import { theme } from '../../../../../theme';

const Badge: React.FC<{ locationNoteType: LocationNoteType }> = ({ locationNoteType }) => {
  switch (locationNoteType) {
    case LocationNoteType.StockOutRisk:
      return <NoteWarningIcon isCritical={true} />;

    case LocationNoteType.NotEnoughUllageRisk:
      return <NoteWarningIcon isCritical={false} />;

    default:
      return <NoteInfoIcon />;
  }
};

const EditLink = styled.div<{ color: string }>`
  height: 14px;
  width: 14px;
  font-size: 14px;
  vertical-align: middle;
  color: ${p => p.color};
`;

const Wrapper = styled.div`
  display: inline-block;
  height: 14px;
  width: 14px;
  cursor: pointer;
`;

const Outer = styled.div`
  display: inline-block;
  vertical-align: bottom;
`;

const Inner = styled.div`
  height: 14px;
  width: 14px;
`;

const getEditLinkColor = (locationNoteType: LocationNoteType) => {
  switch (locationNoteType) {
    case LocationNoteType.StockOutRisk:
      return theme.secondary.light.a;
    case LocationNoteType.NotEnoughUllageRisk:
      return theme.primary.a;
    default:
      return theme.greys.light.border;
  }
};

interface Props {
  locationNoteType: LocationNoteType;
  onClick: () => void;
}

const LocationNoteIcon: React.FC<Props> = ({ locationNoteType, onClick }) => {
  const [hovered, hoverEvents] = useHover();

  return (
    <Wrapper {...hoverEvents}>
      <Outer>
        <Inner>
          {hovered ? (
            <EditLink color={getEditLinkColor(locationNoteType)} onClick={onClick}>
              <MdEdit />
            </EditLink>
          ) : (
            <Badge locationNoteType={locationNoteType} />
          )}
        </Inner>
      </Outer>
    </Wrapper>
  );
};

export { LocationNoteIcon };
