import * as React from 'react';
import styled, { withTheme, css } from 'styled-components';
import { MinusIcon } from '../../../../components/Icons/MinusIcon';
import { PlusIcon } from '../../../../components/Icons/PlusIcon';
import { IThemeType } from '../../../../theme';

const ButtonHoverMixin = css`
  cursor: pointer;
  &:hover {
    border-color: ${p => p.theme.primary.b};

    path {
      fill: ${p => p.theme.primary.b};
    }
  }
`;

const AddButton = styled.button<{ enabled: boolean }>`
  padding-top: 3px;
  width: 40px;
  height: 40px;
  border: 1px solid ${p => (p.enabled ? p.theme.base.dark.d : p.theme.greys.light.disabled)};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => (p.enabled ? 'tranparent' : p.theme.greys.light.disabled)};
  ${p => p.enabled && ButtonHoverMixin}
`;

interface AddRemoveButtonProps {
  type: 'add' | 'remove';
  enabled?: boolean;
  theme?: IThemeType;
  onClick: () => void;
}
export const AddRemoveButton: React.FC<AddRemoveButtonProps> = withTheme(({ type, theme, enabled = true, onClick }) => (
  <AddButton
    data-testid={`${type}-product-transfer-button`}
    enabled={enabled}
    onClick={() => {
      if (enabled) {
        onClick();
      }
    }}
  >
    {type === 'add' ? <PlusIcon fill={theme.base.dark.d} /> : <MinusIcon fill={theme.base.dark.d} />}
  </AddButton>
));
