import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FilterIcon } from '../../../components/Icons/FilterIcon';
import { SecondaryFilter, SecondaryFilterOptionType } from '../../../components/SecondaryFilter';
import {
  createCustomerDropdownItems,
  createDemandTypeDropdownItems,
  createPlantDropdownItems,
} from './createDropdownItems';
import { DashboardSortBar } from './SortBar';
import { DashboardRowType } from './types';

const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-start;
  padding: 0 15px;
`;

const FilterRoot = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-start;
  padding: 0 0 0 10px;
`;

const SecondaryFilterContainer = styled.div`
  margin-right: 10px;
`;

interface Props {
  demands: DashboardRowType[];
  fuelOilQuoteAverageDate: string;
  fuelOilQuoteAveragePrice: number;
  isNewDemandDisabled: boolean;
  itemCount: number;
  onNewSpotDemandClick: () => void;
  plant: SecondaryFilterOptionType[] | null | undefined;
  soldTo: SecondaryFilterOptionType[] | null | undefined;
  type: SecondaryFilterOptionType[] | null | undefined;
  updatePlant: (plant: SecondaryFilterOptionType[] | null | undefined) => void;
  updateSoldTo: (SoldTo: SecondaryFilterOptionType[] | null | undefined) => void;
  updateType: (Type: SecondaryFilterOptionType[] | null | undefined) => void;
  currentUserTheme?: boolean;
}

// These will never change so we can do it once and forget
const typeItems = createDemandTypeDropdownItems();

const SecondaryFilterBar = ({
  demands,
  fuelOilQuoteAverageDate,
  fuelOilQuoteAveragePrice,
  isNewDemandDisabled,
  itemCount,
  onNewSpotDemandClick,
  plant,
  soldTo,
  type,
  updatePlant,
  updateSoldTo,
  updateType,
  currentUserTheme,
}: Props) => {
  // This only needs to be done when updated demands are
  // fetched from API.
  const { plantItems, soldToItems } = useMemo(
    () => ({
      plantItems: createPlantDropdownItems(demands),
      soldToItems: createCustomerDropdownItems(demands),
    }),
    [demands],
  );

  return (
    <Root data-testid="secondary-filter-bar">
      <FilterIcon currentUserTheme={currentUserTheme} />
      <FilterRoot>
        <SecondaryFilterContainer>
          <SecondaryFilter
            data-testid="sold-to-filter"
            placeholder="Sold to"
            onChange={updateSoldTo}
            options={soldToItems}
            value={soldTo}
            width="269px"
            currentUserTheme={currentUserTheme}
          />
        </SecondaryFilterContainer>
        <SecondaryFilterContainer>
          <SecondaryFilter
            placeholder="Type"
            onChange={updateType}
            options={typeItems}
            value={type}
            currentUserTheme={currentUserTheme}
          />
        </SecondaryFilterContainer>
        <SecondaryFilterContainer>
          <SecondaryFilter
            placeholder="Primary plant"
            onChange={updatePlant}
            options={plantItems}
            value={plant}
            currentUserTheme={currentUserTheme}
          />
        </SecondaryFilterContainer>
      </FilterRoot>
      <DashboardSortBar
        fuelOilAverageDate={fuelOilQuoteAverageDate}
        fuelOilAveragePrice={fuelOilQuoteAveragePrice}
        isNewDemandDisabled={isNewDemandDisabled}
        itemCount={itemCount}
        onNewSpotDemandClick={onNewSpotDemandClick}
        currentUserTheme={currentUserTheme}
      />
    </Root>
  );
};

export { SecondaryFilterBar };
