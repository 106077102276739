import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { config } from '../../config';
import { URLS } from '../../constants';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Button } from '../Button';
import { MoreActionsModal } from '../MoreActions';
import { RequirePermission } from '../RequirePermission';
import { Typography } from '../Typography';

const HeaderContainer = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 48px;
  background: ${p => p.theme.base.dark.d};
  padding: 0 25px;
  border-bottom: 1px solid ${p => p.theme.base.dark.b};
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  padding: 0 24px;
  flex: 1;
`;

const Logo = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const LogoLink = styled(Link)`
  display: flex;
  text-decoration: none;
`;
const MoreActions = styled(Button).attrs({ variant: 'ghost-light' })`
border:none;
width: 22px;
justify-content: center;
margin:0px; !important;
height:22px;
padding:4px;
background-image:url(${config.PUBLIC_URL}/images/more_vert_white.svg);
left: 12px;
`;
const MoreActionsWrapper = styled.div`
  z-index: 999;
`;
const Header: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMoreActionsOpen, setIsMoreActionsOpen] = React.useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent | TouchEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMoreActionsOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsMoreActionsOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMoreActionsOpen]);

  const currentUser = useCurrentUser();
  return (
    <HeaderContainer>
      {!location.pathname.includes(URLS.SIMULATION) ? (
        <LogoLink to={URLS.ROOT}>
          <Logo alt="Home" src={`${config.PUBLIC_URL}/images/shell-logo.svg`} />
        </LogoLink>
      ) : (
        <Logo alt="Home" src={`${config.PUBLIC_URL}/images/shell-logo.svg`} />
      )}
      <Content>{children}</Content>
      <Typography color="bodyDark" variant="body2">
        {currentUser.emailAddress}
      </Typography>
      <RequirePermission
        anyOf={[
          'editFleetManagement',
          'editDemandForecast',
          'editPricing',
          'editStockManagement',
          'viewDemandForecast',
          'viewFleetManagement',
          'viewPricing',
          'viewStockManagement',
          'deleteDemandForecast',
          'downloadNetsim',
        ]}
      >
        <MoreActionsWrapper ref={ref}>
          <MoreActions onClick={() => setIsMoreActionsOpen(!isMoreActionsOpen)} />
          {isMoreActionsOpen && <MoreActionsModal />}
        </MoreActionsWrapper>
      </RequirePermission>
    </HeaderContainer>
  );
};

export { Header };
