import React, { useState, ChangeEvent } from 'react';
import { useMutation } from 'react-apollo';
import { Field, Formik, FieldProps, getIn, FastField, Form } from 'formik';
import { TextInput } from '../../../../../components/TextInput';
import { Col, Row, Grid } from '../../../../../components/Grid';
import { Calendar } from '../../../../../components/Calendar';
import moment, { Moment } from 'moment';
import { Checkbox } from '../../../../../components/Checkbox';
import { isInclusivelyBeforeDay } from 'react-dates';
import { useToast } from '../../../../../components/Toast/Toast';
import {
  CREATE_EAST_EVENT,
  CreateEastEventMutation,
  CreateEastEventMutationVariables,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  UpdateScheduleManagerByIdMutation,
  UpdateScheduleManagerByIdMutationVariables,
  UPDATE_SCHEDULEMANAGER_BY_ID,
} from '@scout/types';
import { Required, RequiredText } from './EastMovementFormsHelper';
import {
  BtnStyledRow,
  CheckboxContainer,
  EventValidationErrorMessage,
  getDefaultEventValues,
  InputContainer,
  Label,
  MovementValidation,
  NewButton,
  PlanningContainer,
  PlanningNotesContainer,
  RefineryDepotEventValues,
  Root,
  Spaceinbetween,
  StyledTextArea,
  today,
  validationSchema,
  WidthAdjustmentContainer,
} from './EastEventsMovementFormHelper';

interface EastDepotEventContainerProps {
  currentLocationName: string;
  cancelModal: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  selectedMovement: SchedulingManagerType | null;
  // tslint:disable-next-line
  eventDepotSelectedValues: any;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}
export const EastDepotEventContainer: React.FunctionComponent<EastDepotEventContainerProps> = ({
  currentLocationName,
  cancelModal,
  SMdata,
  selectedMovement,
  eventDepotSelectedValues,
  currentUserTheme,
  onClickSimulationEvent,
  dataFetch,
}) => {
  const [eventStartDate, setEventStartDate] = useState<Moment | null>();
  const [eventEndDate, setEventEndDate] = useState<Moment | null>();
  const [isChecked, setisChecked] = useState(eventDepotSelectedValues ? eventDepotSelectedValues.blockDates : false);
  const [submitBtnDisable, setSubmitBtnDisable] = useState(true);
  const [isEventPresent, setisEventPresent] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const { push } = useToast();
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [createEastEvent, setCreateEastEventResult] = useMutation<
    CreateEastEventMutation,
    CreateEastEventMutationVariables
  >(CREATE_EAST_EVENT, {
    context: {
      headers,
    },
  });
  const [updateEvent, setupdateEvent] = useMutation<
    UpdateScheduleManagerByIdMutation,
    UpdateScheduleManagerByIdMutationVariables
  >(UPDATE_SCHEDULEMANAGER_BY_ID, {
    context: {
      headers,
    },
  });

  const handleOnSubmit = async (values: RefineryDepotEventValues) => {
    if (selectedMovement !== null) {
      const input = {
        id: selectedMovement.id,
        ...values,
      };
      try {
        await updateEvent({
          variables: {
            input,
          },
        });
        push({ type: 'SUCCESS', text: 'Movement Updated successfully' });
        setDisabledSaveButton(false);
        cancelModal();
        onClickSimulationEvent(!dataFetch);
      } catch (error) {
        push({ type: 'ERROR', text: 'Movement Update Failed' });
      }
    } else {
      try {
        await createEastEvent({
          variables: {
            input: values,
          },
        });
        onClickSimulationEvent(!dataFetch);
        push({ type: 'SUCCESS', text: 'Event created successfully' });
        setDisabledSaveButton(false);
        cancelModal();
      } catch (error) {
        push({ type: 'ERROR', text: 'Event Failed' });
      }
    }
  };

  const checkEventPresent = async (values: RefineryDepotEventValues) => {
    const EventStartDate = moment(values.eventStartDate).format('YYYY-MM-DD');
    const EventEndDate = moment(values.eventEndDate).format('YYYY-MM-DD');
    let foundData = SMdata?.getScheduleManagerByPort.find(item => {
      const LRlaycan = item.dragLoadportstartdate ? moment(item.dragLoadportstartdate).format('YYYY-MM-DD') : '';
      const productionPlanningLaycan = item.plannedStartDate ? moment(item.plannedStartDate).format('YYYY-MM-DD') : '';
      return (
        ((item.productType === 'Lifting' || item.productType === 'Replenishment') &&
          values.blockDates === true &&
          item.loadPort === values.port &&
          (item.dragLoadportstartdate
            ? moment(LRlaycan).isBetween(EventStartDate, EventEndDate, undefined, '[]')
            : '')) ||
        (item.productType === 'Production' &&
          values.blockDates === true &&
          item.port === values.port &&
          (item.plannedStartDate
            ? moment(productionPlanningLaycan).isBetween(EventStartDate, EventEndDate, undefined, '[]')
            : ''))
      );
    });
    const events = SMdata?.getScheduleManagerByPort.filter(item => {
      const EventfromAPIstartDate = item.eventStartDate ? moment(item.eventStartDate).format('YYYY-MM-DD') : '';
      return (
        item.productType === 'Event' &&
        (item.eventStartDate && item.eventEndDate
          ? moment(EventfromAPIstartDate).isBetween(EventStartDate, EventEndDate, undefined, '[]')
          : '')
      );
    });
    if (!values.blockDates) {
      foundData = events?.find(item => {
        return item.port === values.port && item.blockDates === true;
      });
    } else {
      const blockedEvent = events?.find(item => {
        return item.port === values.port && item.blockDates === true;
      });
      if (blockedEvent) {
        setisEventPresent(true);
        return true;
      }
    }
    if (foundData) {
      setisEventPresent(true);
      return true;
    } else {
      setisEventPresent(false);
      return false;
    }
  };

  today.setHours(0, 0, 0, 0);

  return (
    <>
      <Formik
        initialValues={selectedMovement ? eventDepotSelectedValues : getDefaultEventValues(currentLocationName)}
        validate={values => {
          const disBtn = values.eventStartDate && values.eventEndDate && values.eventType.trim().length > 0;
          if (disBtn) {
            setSubmitBtnDisable(false);
          } else {
            setSubmitBtnDisable(true);
          }
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, formActions) => {
          const eventvalidation = await checkEventPresent(values);

          if (!eventvalidation) {
            setDisabledSaveButton(true);
            await handleOnSubmit(values);
            formActions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {isEventPresent && <MovementValidation>{EventValidationErrorMessage}</MovementValidation>}
            <Root currentUserTheme={currentUserTheme}>
              <Grid>
                <Row>
                  <Col size={6}>
                    <Row>
                      <PlanningContainer>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>
                            Event Start Date <Required>*</Required>
                          </Label>
                          <Field name="eventStartDate">
                            {({
                              field,
                              form: { errors, touched, setFieldTouched, setFieldValue },
                            }: FieldProps<EastDepotEventContainerProps>) => (
                              <Calendar
                                disabled={selectedMovement ? true : false}
                                name={`eventStartDate`}
                                error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                placeholder="Select Date"
                                width="250px"
                                align="left"
                                value={
                                  field.value
                                    ? moment(field.value)
                                    : eventStartDate
                                    ? moment(eventStartDate, 'YYYY/MM/DD')
                                    : null
                                }
                                onChange={date => {
                                  setFieldValue(`eventStartDate`, date.format());
                                  setFieldTouched(`eventStartDate`, true);
                                  setEventStartDate(date);
                                }}
                                themeStyle={currentUserTheme ? 'light' : 'dark'}
                                showErrorMessage={true}
                                isOutsideRange={(day: Moment) => {
                                  if (eventEndDate !== undefined) {
                                    return (
                                      day.isBefore(moment(moment().add(-1, 'days'), 'YYYY/MM/DD')) ||
                                      !isInclusivelyBeforeDay(
                                        day,
                                        moment(eventEndDate ? eventEndDate : moment(), 'YYYY/MM/DD'),
                                      )
                                    );
                                  } else {
                                    return day.isBefore(moment(today, 'YYYY/MM/DD'));
                                  }
                                }}
                                region="east"
                                currentUserTheme={currentUserTheme}
                              />
                            )}
                          </Field>
                        </InputContainer>
                      </PlanningContainer>
                    </Row>

                    <Row>
                      <PlanningContainer>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>
                            Event Type <Required>*</Required>
                          </Label>
                          <Field name="eventType">
                            {({
                              field,
                              form: { errors, touched, setFieldTouched },
                            }: FieldProps<EastDepotEventContainerProps>) => (
                              <WidthAdjustmentContainer widthToSet="255px">
                                <TextInput
                                  align="left"
                                  placeholder="Enter Event Type"
                                  error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                  name={field.name}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const newValue = e.target.value;
                                    if (newValue == null) {
                                      return;
                                    }
                                    field.onChange(e);
                                    setFieldTouched(`eventType`, true);
                                  }}
                                  showErrorIcon={false}
                                  showErrorMessage={true}
                                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                                  value={field.value != null && field.value.trimLeft().trimRight() ? field.value : ''}
                                />
                              </WidthAdjustmentContainer>
                            )}
                          </Field>
                        </InputContainer>
                      </PlanningContainer>
                    </Row>
                  </Col>
                  <Spaceinbetween />
                  <Col size={6}>
                    <Row>
                      <PlanningContainer>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>
                            Event End Date <Required>*</Required>
                          </Label>
                          <Field name="eventEndDate">
                            {({
                              field,
                              form: { errors, touched, setFieldTouched, setFieldValue },
                            }: FieldProps<EastDepotEventContainerProps>) => (
                              <Calendar
                                disabled={selectedMovement ? true : false}
                                name={`eventEndDate`}
                                error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                placeholder="Select Date"
                                width="250px"
                                align="left"
                                value={
                                  field.value
                                    ? moment(field.value)
                                    : eventEndDate
                                    ? moment(eventEndDate, 'YYYY/MM/DD')
                                    : null
                                }
                                onChange={date => {
                                  setFieldValue(`eventEndDate`, date.format());
                                  setFieldTouched(`eventEndDate`, true);
                                  setEventEndDate(date);
                                }}
                                themeStyle={currentUserTheme ? 'light' : 'dark'}
                                showErrorMessage={true}
                                isOutsideRange={(day: Moment) => {
                                  return day.isBefore(
                                    moment(moment(eventStartDate ? eventStartDate : moment(), 'YYYY/MM/DD')).startOf(
                                      'day',
                                    ),
                                  );
                                }}
                                region="east"
                                currentUserTheme={currentUserTheme}
                              />
                            )}
                          </Field>
                        </InputContainer>
                      </PlanningContainer>
                    </Row>
                    <Row>
                      <PlanningContainer>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>
                            Port <Required>*</Required>
                          </Label>
                          <Field name="port">
                            {({ field, form: { errors, touched } }: FieldProps<EastDepotEventContainerProps>) => (
                              <WidthAdjustmentContainer widthToSet="250px">
                                <TextInput
                                  align="left"
                                  error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                  name={field.name}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const newValue = e.target.value;

                                    if (newValue == null) {
                                      return;
                                    }

                                    field.onChange(e);
                                  }}
                                  placeholder=""
                                  showErrorIcon={false}
                                  showErrorMessage={true}
                                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                                  disabled={true}
                                  value={currentLocationName}
                                  currentUserTheme={currentUserTheme}
                                />
                              </WidthAdjustmentContainer>
                            )}
                          </Field>
                        </InputContainer>
                      </PlanningContainer>
                    </Row>
                  </Col>
                </Row>

                <Col size={6}>
                  <Row>
                    <PlanningNotesContainer>
                      <InputContainer>
                        <Label currentUserTheme={currentUserTheme}>Planning Note</Label>
                        <FastField
                          name="planningNotes"
                          render={({ field }: FieldProps<string>) => (
                            <StyledTextArea
                              {...field}
                              maxLength={500}
                              tabIndex={1000}
                              data-testid="planning-notes"
                              themeStyle={currentUserTheme ? 'light' : 'dark'}
                              placeholder="Please leave your thoughts…"
                              rows={1}
                            />
                          )}
                        />
                      </InputContainer>
                    </PlanningNotesContainer>
                  </Row>

                  <Row>
                    <CheckboxContainer>
                      <InputContainer>
                        <Field name="blockDates">
                          {({ form: { setFieldValue } }: FieldProps<EastDepotEventContainerProps>) => (
                            <Checkbox
                              disabled={selectedMovement ? true : false}
                              isEast={true}
                              name={`blockDates`}
                              label="Block Dates"
                              checked={isChecked}
                              onChange={checkedValue => {
                                setisChecked(Boolean(checkedValue));
                                setFieldValue(`blockDates`, Boolean(checkedValue));
                              }}
                              onBlur={checkedValue => {
                                setFieldValue(`blockDates`, Boolean(checkedValue));
                              }}
                              currentUserTheme={currentUserTheme}
                            />
                          )}
                        </Field>
                      </InputContainer>
                    </CheckboxContainer>
                  </Row>
                  <Row>
                    <RequiredText>* Required Fields</RequiredText>
                  </Row>

                  <BtnStyledRow>
                    <NewButton
                      variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                      onClick={async () => {
                        cancelModal();
                      }}
                    >
                      Cancel
                    </NewButton>

                    <NewButton
                      variant="primary"
                      type="submit"
                      disabled={disabledSaveButton ? true : submitBtnDisable}
                      currentUserTheme={currentUserTheme}
                    >
                      Save
                    </NewButton>
                  </BtnStyledRow>
                </Col>
              </Grid>
            </Root>
          </Form>
        )}
      </Formik>
    </>
  );
};
