import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import { Container } from '../Container';
import { Typography } from '../Typography';

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state = { error: null };

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <ThemeProvider theme={theme}>
          <Container>
            <Typography color="bodyDark" variant="heading3" align="center">
              Something went wrong. Please try refresh and try again.
            </Typography>
            <Typography color="bodyDark" align="center">{`Message: ${error}`}</Typography>
          </Container>
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
