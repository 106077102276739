import { printIntrospectionSchema } from 'graphql';
import React from 'react';
import styled, { css } from 'styled-components';

export const TableContainer = styled.table`
  height: 100%;
  width: 100%;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  overflow: auto;
`;

export const TableHead = styled.thead<{ currentUserTheme?: boolean }>`
  background: ${props => (props.currentUserTheme ? props.theme.base.light.b : props.theme.base.dark.c)};
  position: sticky;
  top: 0;
  z-index: 3;
`;

export const TableRow = styled.tr<{
  blockDates?: boolean;
  condition?: boolean;
  selectedColor?: boolean;
  deselectedColor?: boolean;
  currentUserTheme?: boolean;
}>`
  width: 100%;
  background: ${props =>
    props.blockDates === true && props.currentUserTheme
      ? props.theme.secondary.light.c
      : props.blockDates === true
      ? props.theme.greys.dark.disabled
      : props.deselectedColor
      ? props.theme.base.dark.d
      : props.selectedColor === true && props.currentUserTheme
      ? 'darkGrey'
      : props.selectedColor
      ? '#1F2E46'
      : props.currentUserTheme
      ? props.theme.base.light.a
      : ''};
  tbody > tr &:hover > td {
    background: ${props => props.theme.base.dark.d};
  }
  opacity: ${props =>
    (props.currentUserTheme && props.condition) === true ? '0.5' : props.condition === true ? '0.4' : ''};
`;

export const TH = styled.th<{ width?: string; stickyLeft?: number; currentUserTheme?: boolean }>`
  background: ${props =>
    props.currentUserTheme === true ? `${props.theme.secondary.light.c}` : `${props.theme.base.dark.c}`};
  top: 0;
  z-index: 2;
  position: sticky;
  font-weight: normal;
  text-align: left;
  color: ${props => (props.currentUserTheme === true ? props.theme.base.dark.a : props.theme.secondary.light.c)};

  ${props => (props.width ? `min-width: ${props.width};` : '')}
  ${props =>
    props.stickyLeft !== undefined &&
    css`
    left: ${props.stickyLeft}px;
    z-index: 3; 
  `}
`;

const THInner = styled.div<{ stickyLeft?: number }>`
  display: flex;
  position: relative;
  height: 40px;
  align-items: center;
  padding-left: ${p => (p.stickyLeft === 0 ? '8px' : '5px')};
  padding-right: 8px;
`;

const Border = styled.div`
  width: 1px;
  position: absolute;
  background: ${props => props.theme.greys.dark.border};
`;

export const TableHeadColumn: React.FC<{ width?: string; stickyLeft?: number; currentUserTheme?: boolean }> = ({
  children,
  ...rest
}) => (
  <TH {...rest}>
    <THInner stickyLeft={rest.stickyLeft}>
      {children} <Border />
    </THInner>
  </TH>
);

export const TD = styled.td<{ stickyLeft?: number; isDefault?: boolean; width?: string; currentUserTheme?: boolean }>`
  color: ${props =>
    props.isDefault
      ? props.theme.greys.dark.disabled
      : props.currentUserTheme === true
      ? props.theme.base.dark.d
      : props.theme.secondary.light.c};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
  width: ${props => props.width};

  ${p =>
    p.stickyLeft !== undefined &&
    css`
      position: sticky;
      left: ${p.stickyLeft}px;
      background-color: ${p.currentUserTheme === true ? 'white' : p.theme.base.dark.b};
      z-index: 1;
    `}
`;

const Indicator = styled.div<{ isDefault?: boolean; isToday?: boolean }>`
  height: calc(100% - 4px);
  width: 2px;
  position: absolute;
  left: 0;
  background: ${props => (props.isToday ? props.theme.primary.b : props.theme.secondary.light.c)};
`;

export const TDInner = styled.div<{ stickyLeft?: number; width?: string; height?: string }>`
  display: flex;
  position: relative;
  height: 30px;
  align-items: center;
  align-text: center;
  height: ${props => (props.height ? props.height : '20px')};
  padding-left: ${p => (p.stickyLeft === 0 ? '8px' : '8px')};
  padding-right: 8px;
  width: ${props => (props.width ? props.width : '125px')};
`;

export const TableColumn: React.FC<{
  stickyLeft?: number;
  isDefault?: boolean;
  isToday?: boolean;
  width?: string;
  height?: string;
  currentUserTheme?: boolean;
}> = ({ children, stickyLeft, isDefault, isToday, width, height, currentUserTheme }) => {
  return (
    <TD stickyLeft={stickyLeft} isDefault={isDefault} width={width} currentUserTheme={currentUserTheme}>
      <TDInner stickyLeft={stickyLeft} width={width} height={height}>
        {children}
      </TDInner>
    </TD>
  );
};
