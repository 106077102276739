import React from 'react';
import styled from 'styled-components';
import { Total } from '../../../../../components/Total';
import { Typography } from '../../../../../components/Typography';
import { StepTitle } from '../StepTitle';

const ContractVolume = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  currentTotal: number;
  targetTotal: number;
}

const TitleSection: React.FC<Props> = ({ targetTotal, currentTotal }) => (
  <div>
    <StepTitle description="Split your contract into demands" number={1} title="Demands" />
    <ContractVolume>
      <Typography bold={true} variant="heading5">
        Contract volume
      </Typography>
      <Total
        fixedTarget={targetTotal}
        showFixedTarget={true}
        targetType="Fixed"
        total={currentTotal}
        unit="t"
        totalText="Grand Total"
      />
    </ContractVolume>
  </div>
);

export { TitleSection };
