import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';
import { TransportMovementStatus } from '@scout/types';
import { VesselStatus } from '../Map/Vessel';

export const getRotation = ({ status, heading }: { status: VesselStatus; heading: number }) => {
  if (status === VesselStatus.AT_ANCHOR || status === VesselStatus.MOORED) {
    return 0;
  }

  return heading;
};

// export const getColor = (movementStatus: TransportMovementStatus, theme: IThemeType) => {
//   switch (movementStatus) {
//     case TransportMovementStatus.OnTime:
//       return theme.secondary.light.b;
//     case TransportMovementStatus.Warning:
//       return theme.primary.a;
//     case TransportMovementStatus.Late:
//       return theme.secondary.dark.a;

//     default:
//       return theme.primary.b;
//   }
// };

export const getColor = (theme: IThemeType, capacity?: number) => {
  if (capacity) {
    if (capacity > 5000 && capacity <= 10000) {
      return theme.primary.a;
    } else if (capacity > 10000) {
      return theme.secondary.light.b;
    } else {
      return theme.primary.b;
    }
  } else {
    return theme.primary.b;
  }
};

export const ANCHORED_PATH =
  'M6.76777 12.0607C6.18198 11.4749 6.18198 10.5251 6.76777 9.93934L9.93934 6.76777C10.5251 6.18198 11.4749 6.18198 12.0607 6.76777L15.2322 9.93934C15.818 10.5251 15.818 11.4749 15.2322 12.0607L12.0607 15.2322C11.4749 15.818 10.5251 15.818 9.93934 15.2322L6.76777 12.0607Z';
export const MOVING_PATH =
  'M19.5728 11.8673L10.4569 17.1303L11.6422 12.8153L11.7763 12.3271L11.2864 12.1991L6.95687 11.0681L16.0728 5.80509L20.8923 7.06403L19.5728 11.8673Z';

export const VesselIcon = withTheme<
  React.ComponentType<{
    theme: IThemeType;
    status: VesselStatus;
    heading: number;
    isSelected: boolean;
    movementStatus: TransportMovementStatus;
    capacity?: number;
  }>
>(({ theme, status, heading, isSelected, movementStatus, capacity }) => {
  return (
    <svg
      data-testid={`${isSelected ? 'Selected' : 'Unselected'} vessel status ${VesselStatus[
        status
      ]?.toLocaleLowerCase()}`}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d={status === VesselStatus.AT_ANCHOR || status === VesselStatus.MOORED ? ANCHORED_PATH : MOVING_PATH}
          fill={getColor(theme, capacity)}
          stroke={getColor(theme, capacity)}
          fillOpacity={isSelected ? 1 : 0.5}
          transform={`rotate(${getRotation({ status, heading })}, 12, 12)`}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
});
