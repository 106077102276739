import { getConfigEnv } from './getConfigEnv';

interface Config {
  PUBLIC_URL: string;
  MAP_TOKEN: string;
  MAP_THEME: string;
  SENTRY_UI_DSN: string;
  ENV: string;
}

const config: Config = {
  PUBLIC_URL: process.env.PUBLIC_URL,
  // @TODO create a shared account on https://account.mapbox.com/auth/signup/
  MAP_THEME: 'mapbox://styles/sushi1/ckceno1cu13w01intrhv14vq5',
  MAP_TOKEN: 'pk.eyJ1Ijoic3VzaGkxIiwiYSI6ImNqemk2NGp6NzEyYWkzY3FrbGNveGtlNjIifQ.ZjIWRelrspcqwIcrmO1Shg',
  // SENTRY_UI_DSN: 'https://2eb1d9ccdc40443381834f0bd1962bf5@sentry.io/1729048',
  SENTRY_UI_DSN: '',
  ENV: getConfigEnv(),
};

export { config };
