import { FormikHandlers } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../../components/Typography';
import { DemandLocation } from '@scout/types';
import { MonthlyVolumesStepValues } from '../types';
import { EditableRow } from './EditableRow';
import { Row } from './Row';

const Table = styled.table<{ removeBorderSpacing?: boolean }>`
  border-spacing: 0;
  border-top: 1px solid ${props => props.theme.greys.light.border};
  margin-top: 16px;
  padding-top: 16px;
  width: 100%;
`;

const HeadingCellContent = styled(Typography)<{ isLast?: boolean }>`
  border-right: ${props => !props.isLast && `1px solid ${props.theme.greys.light.border}`};
  padding-right: 10px;
  margin-bottom: 16px;
`;

interface VolumesTableProps {
  demandLocation: DemandLocation;
  onChange: FormikHandlers['handleChange'];
  rows: MonthlyVolumesStepValues['rows'];
  type?: string;
  onSelectRow: (rowId: string) => void;
  setSelectedRow?: string;
  disabled?: boolean;
}

const VolumesTable: React.FC<VolumesTableProps> = ({
  demandLocation,
  onChange,
  rows,
  type,
  onSelectRow,
  setSelectedRow,
  disabled,
}) => {
  return (
    <Table>
      <thead>
        <tr>
          {type === 'formula' && <th />}
          <th>
            <HeadingCellContent variant="labels1">Plant</HeadingCellContent>
          </th>
          <th>
            <HeadingCellContent variant="labels1">
              {demandLocation === DemandLocation.Region ? 'Region' : 'Ship To'}
            </HeadingCellContent>
          </th>
          {moment.monthsShort().map(month => (
            <th key={`table-head-month-${month}`}>
              <HeadingCellContent variant="labels1" align="right">
                {month}
              </HeadingCellContent>
            </th>
          ))}
          <th>
            <HeadingCellContent variant="labels1" align="right" isLast={true}>
              Volume
            </HeadingCellContent>
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) =>
          row.isEditable ? (
            <EditableRow {...row} key={row.id} name={`rows.${index}`} onChange={onChange} />
          ) : (
            <Row
              {...row}
              key={row.id}
              type={type}
              onSelectRow={onSelectRow}
              setSelectedRow={setSelectedRow}
              disabled={disabled}
            />
          ),
        )}
      </tbody>
    </Table>
  );
};

export { VolumesTable };
