import { CurrentUserQuery, GET_CURRENT_USER } from '@scout/types';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { UserThemeContext } from '../App';
import { Loader } from '../components/Loader';
import { Error } from '../components/Loader/Error';
import { CurrentUserContext } from '../contexts/currentUser';

const CurrentUserProvider: React.FC = ({ children }) => {
  const { data, error, loading } = useQuery<CurrentUserQuery>(GET_CURRENT_USER);
  const { setCurrentUserTheme } = useContext(UserThemeContext);
  useEffect(() => {
    if (!data) {
      return;
    }
    if (setCurrentUserTheme) {
      setCurrentUserTheme(!!data.currentUser.userTheme);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  if (error || data == null) {
    return <Error message="Error retrieving user details" />;
  }

  return <CurrentUserContext.Provider value={data.currentUser}>{children}</CurrentUserContext.Provider>;
};

export { CurrentUserProvider };
