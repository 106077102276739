import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { DeleteIcon } from '../../../components/Icons/DeleteIcon';
import { Loader } from '../../../components/Loader';
import { Modal } from '../../../components/Modal';
import { useToast } from '../../../components/Toast/Toast';
import { Typography } from '../../../components/Typography';
import { URLS } from '../../../constants';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { MidTermLayout } from '../../../layouts';
import {
  DELETE_SPOT_DEMAND,
  GET_EDIT_SPOT_DEMAND_DATA,
  UPDATE_SPOT_DEMAND,
  CreateSpotDemandInput,
  EditSpotDemandDataQuery,
  EditSpotDemandDataQueryVariables,
  ShipmentType,
  UpdateSpotDemandMutation,
  UpdateSpotDemandMutationVariables,
} from '@scout/types';
import { Form } from './Form';
import { formatGradeItems, formatPlantsDropdown } from './GradePlantContainer/utils';
import { Header } from './Header';
import { GradeTypes } from './types';
import { makeReturnURL } from './url';
import { createInitialVolumePrices } from './VolumePricingContainer/createInitialVolumePrices';
import { UserThemeContext } from '../../../App';

export const UPDATE_SPOT_SUCCESS_MESSAGE = 'Spot demand updated.';
export const UPDATE_SPOT_FAILURE_MESSAGE = 'Error updating spot demand.';
export const DELETE_SPOT_SUCCESS_MESSAGE = 'Demand deleted successfully.';
export const DELETE_SPOT_FAILURE_MESSAGE = 'Error deleting spot demand.';

type Props = RouteComponentProps<{ clusterId: string; id: string }>;

const DeleteRoot = styled.div`
  background: ${props => props.theme.base.light.b};
  margin-bottom: 70px;
`;

const DeleteLink = styled(Typography)<{ onClick: () => void }>`
  border-top: 1px solid ${props => props.theme.greys.light.border};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 18px 0;
  margin-bottom: 100px;
`;

const EditSpotForm: React.FunctionComponent<Props> = ({ history, match }) => {
  const [deleteModal, setDeleteModal] = React.useState(false);
  const currentUser = useCurrentUser();
  const { currentUserTheme } = useContext(UserThemeContext);
  const currentCluster = currentUser.clusters.find(cluster => cluster.id === match.params.clusterId);

  const urlParams = new URLSearchParams(history.location.search);
  const from = urlParams.get('from');

  const { push } = useToast();
  const [setSpotUpdate] = useMutation<UpdateSpotDemandMutation, UpdateSpotDemandMutationVariables>(UPDATE_SPOT_DEMAND);
  const { data, loading, error } = useQuery<EditSpotDemandDataQuery, EditSpotDemandDataQueryVariables>(
    GET_EDIT_SPOT_DEMAND_DATA,
    {
      fetchPolicy: 'no-cache',
      skip: currentCluster == null,
      variables: {
        clusterCurrency: currentCluster?.currency ?? '',
        clusterId: currentCluster?.id ?? '',
        spotDemandId: match.params.id,
      },
    },
  );

  const [deleteSpotDemand] = useMutation<string, { spotDemandId: string }>(DELETE_SPOT_DEMAND);

  const onCancel = useCallback(() => {
    if (currentCluster == null) {
      return;
    }
    const url = makeReturnURL({ from, currentClusterId: currentCluster.id });

    history.push(url);
  }, [currentCluster, from, history]);

  const onDelete = async () => {
    if (currentCluster == null) {
      return;
    }

    try {
      await deleteSpotDemand({
        variables: {
          spotDemandId: match.params.id,
        },
      });

      push({
        text: DELETE_SPOT_SUCCESS_MESSAGE,
      });
      const url = makeReturnURL({ from, currentClusterId: currentCluster.id });
      history.push(url);
    } catch (error) {
      push({
        text: DELETE_SPOT_FAILURE_MESSAGE,
        type: 'ERROR',
      });
    }
  };

  if (currentCluster == null) {
    return <Redirect to={URLS.MID_TERM} />;
  }

  if (loading || error || !data) {
    return <Loader error={error && 'Something went wrong'} />;
  }

  const grades = {
    hard: formatGradeItems(
      data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.HARD),
      data.spotDemandById.grades,
    ),
    soft: formatGradeItems(
      data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.SOFT),
      data.spotDemandById.grades,
    ),
    premium: formatGradeItems(
      data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.PREMIUM),
      data.spotDemandById.grades,
    ),
    non_bitumen: formatGradeItems(
      data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.NON_BITUMEN),
      data.spotDemandById.grades,
    ),
  };

  const onSubmit = async (payload: Omit<CreateSpotDemandInput, 'ownerId'>) => {
    const id = data.spotDemandById.id;
    const input = {
      id,
      ...payload,
    };

    try {
      await setSpotUpdate({
        variables: {
          input,
        },
      });
      push({
        text: UPDATE_SPOT_SUCCESS_MESSAGE,
      });

      const url = makeReturnURL({ from, currentClusterId: currentCluster.id });

      history.push(url);
    } catch (error) {
      console.error('Failed mutation at ', error);
      push({
        text: UPDATE_SPOT_FAILURE_MESSAGE,
        type: 'ERROR',
      });
    }
  };

  const penPlantPrimaryData = data.spotDemandById.plants.find(plant => plant.isPrimary && !plant.isPremium);
  const penPlantSecondaryData = data.spotDemandById.plants.find(plant => !plant.isPrimary && !plant.isPremium);

  const premiumPlantPrimaryData = data.spotDemandById.plants.find(plant => plant.isPrimary && plant.isPremium);
  const premiumPlantSecondaryData = data.spotDemandById.plants.find(plant => !plant.isPrimary && plant.isPremium);

  // We can get the Spot Demand year by looking at any forecast as they'll all
  // be for the same year.
  const year = moment.utc(data.spotDemandById.forecasts[0].date).get('year');

  return (
    <MidTermLayout>
      <Modal
        data-testid="delete-demand-modal"
        title="Delete demand"
        onConfirm={onDelete}
        confirmBtnVariant="danger"
        onDismiss={() => setDeleteModal(false)}
        isOpen={deleteModal}
        minWidth={500}
        confirmText="Delete"
        currentUserTheme={currentUserTheme}
      >
        <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
          Are you sure you want to delete this spot demand?
        </Typography>
      </Modal>
      <Header
        initialValues={{
          soldTo: data.spotDemandById.customer,
          shipTo: data.spotDemandById.shipTo || undefined,
          region: data.spotDemandById.region || undefined,
          createdBy: data.spotDemandById.createdBy || undefined,
          updatedBy: data.spotDemandById.updatedBy || undefined,
          createdAt: data.spotDemandById.createdAt || undefined,
          updatedAt: data.spotDemandById.updatedAt || undefined,
        }}
        shipmentType={data.spotDemandById.shipmentType}
      />
      <Form
        initialValues={{
          totalGrades: 0,
          grades,
          penPlantPrimary: penPlantPrimaryData
            ? { id: penPlantPrimaryData.id, plantId: penPlantPrimaryData.plantId }
            : { plantId: '' },
          penPlantSecondary: penPlantSecondaryData
            ? { id: penPlantSecondaryData.id, plantId: penPlantSecondaryData.plantId }
            : { plantId: '' },
          premiumPlantPrimary: premiumPlantPrimaryData
            ? { id: premiumPlantPrimaryData.id, plantId: premiumPlantPrimaryData.plantId }
            : { plantId: '' },
          premiumPlantSecondary: premiumPlantSecondaryData
            ? { id: premiumPlantSecondaryData.id, plantId: premiumPlantSecondaryData.plantId }
            : { plantId: '' },
          plantSelectionReasonTechnical: data.spotDemandById.plantSelectionReasonTechnical,
          plantSelectionReasonOperational: data.spotDemandById.plantSelectionReasonOperational,
          plantSelectionReasonContractual: data.spotDemandById.plantSelectionReasonContractual,
          plantSelectionNote: data.spotDemandById.plantSelectionNote || '',
          volumePrices: createInitialVolumePrices(year, data.spotDemandById.forecasts),
        }}
        onCancel={onCancel}
        onSubmit={onSubmit}
        premiumPlants={formatPlantsDropdown(
          data.spotDemandFormData.plants.filter(plant =>
            plant.grades.find(grade => grade.type === GradeTypes.PREMIUM && plant.shortName),
          ),
        )}
        penPlants={formatPlantsDropdown(
          data.spotDemandFormData.plants.filter(plant =>
            plant.grades.find(grade => grade.type !== GradeTypes.PREMIUM && plant.shortName),
          ),
        )}
        spotDemandId={match.params.id}
        isEditing={true}
        shipmentType={data.spotDemandById.shipmentType as ShipmentType}
        customerCode={data.spotDemandById.customer.code}
        customerId={data.spotDemandById.customerId}
        regionId={data.spotDemandById.regionId || undefined}
        shipToId={data.spotDemandById.shipTo != null ? data.spotDemandById.shipTo.id : undefined}
        cluster={currentCluster}
        fuelOilQuote={data.fuelOilQuote}
        countryCode={data.spotDemandById.customer.countryCode}
        year={year}
      />

      <DeleteRoot>
        <Container>
          <DeleteLink tag="a" onClick={() => setDeleteModal(true)} data-testid="delete-button">
            <DeleteIcon />
            <Typography tag="span" inline={true}>
              Delete Demand
            </Typography>
          </DeleteLink>
        </Container>
      </DeleteRoot>
    </MidTermLayout>
  );
};

export { EditSpotForm };
