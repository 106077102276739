import { saveAs } from 'file-saver';
import { FormikValues } from 'formik';
import moment from 'moment';

export const NETSIM_REPORT_URL = '/api/reports/netsim';

export const DATE_RANGE_ERROR = 'Please select a valid date range';
export const EXPORT_ERROR = 'Could not export SDF';

export const downloadNetsim = async ({ clusterId, dateRange: { to, from }, sdfFields }: FormikValues) => {
  if (!to || !from) {
    throw Error(DATE_RANGE_ERROR);
  }
  try {
    const response = await fetch(NETSIM_REPORT_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clusterId,
        dateFrom: moment
          .utc(from.value)
          .startOf('month')
          .toDate(),
        dateTo: moment
          .utc(to.value)
          .endOf('month')
          .toDate(),
        sdfFields,
      }),
    });

    const fileBuffer = await response.arrayBuffer();
    const blob = new Blob([fileBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(blob, `sdf_export_${moment().format('DD_MM_YYYY')}.xlsx`);
  } catch (err) {
    throw Error(err);
  }
};
