import { UPDATE_PRICING_ADJUSTMENT_REGION, UpdateRegionMutation, UpdateRegionMutationVariables } from '@scout/types';
import React from 'react';
import { useMutation } from 'react-apollo';
import { MdClose, MdDone, MdModeEdit } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { useToast } from '../../components/Toast/Toast';
import { Typography } from '../../components/Typography';
import { config } from '../../config';
import { TextField } from './TextField';

const Root = styled.div`
  margin-bottom: 20px;
`;

const ArrowIcon = styled.img<{ isOpen: boolean }>`
  transform: ${props => props.isOpen && 'rotate(90deg)'};
  transition: all 0.4s ease;
`;

const DropdownButton = styled.button`
  background: ${props => props.theme.base.dark.d};
  padding: 0.5rem;
  border: 0;
  margin: 0 22px 0 0;
  border-radius: 2px 0 0 2px;
  width: 45px;
  height: 45px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background: ${props => props.theme.primary.b};
  }
`;

const Header = styled.div`
  background: ${props => props.theme.base.dark.c};
  display: flex;
  align-items: stretch;
`;

const InputWrapper = styled.div`
  flex: 1;
  align-self: center;
`;

const BaseButtonMixin = css`
  border: none;
  padding: 5px 13px;
  transition: all 0.2s ease;
  cursor: pointer;
`;

const BaseIconButtonMixin = css`
  width: 20px;
  height: 20px;
`;

const SubmitButton = styled.button`
  ${BaseButtonMixin}
  background: ${props => props.theme.secondary.light.b};
`;

const EditButton = styled.button`
  ${BaseButtonMixin}
  background: ${props => props.theme.base.dark.b};

  cursor: pointer;
  &:hover {
    background: ${props => props.theme.primary.b};
  }
`;

const CancelButton = styled.button`
  background: transparent;
  border: none;
  display: inline-block;
  margin-left: 10px;
  padding: 0;
  cursor: pointer;
`;

const SubmitButtonIcon = styled(MdDone)`
  ${BaseIconButtonMixin}
  color: ${props => props.theme.base.light.a};
`;

const EditButtonIcon = styled(MdModeEdit)`
  ${BaseIconButtonMixin}
  color: ${props => props.theme.base.light.a};
`;

const CancelButtonIcon = styled(MdClose)`
  ${BaseIconButtonMixin}
  color: ${props => props.theme.base.light.a};
  vertical-align: bottom;
  margin-left: 10px;
`;

export const UPDATE_PRICING_ADJUSTMENT_REGION_SUCCESS_MESSAGE = 'Region updated.';
export const UPDATE_PRICING_ADJUSTMENT_REGION_FAILURE_MESSAGE = 'Error updating region.';

interface AccordionProps {
  children: React.ReactNode;
  title: string;
  value: string;
  regionId: string;
  onSubmit: (value: string) => void;
}

const Accordion: React.FunctionComponent<AccordionProps> = props => {
  const { push } = useToast();
  const [isActive, setIsActive] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState(false);
  const [regionName, setRegionName] = React.useState(props.value);
  const [setPricingAdjustmentRegionUpdate] = useMutation<UpdateRegionMutation, UpdateRegionMutationVariables>(
    UPDATE_PRICING_ADJUSTMENT_REGION,
  );

  const onUpdate = async () => {
    try {
      await setPricingAdjustmentRegionUpdate({
        variables: { input: { id: props.regionId, name: regionName } },
      });
      props.onSubmit(regionName);
      push({
        text: UPDATE_PRICING_ADJUSTMENT_REGION_SUCCESS_MESSAGE,
      });
    } catch (error) {
      console.error('Failed mutation at ', error);
      push({
        text: UPDATE_PRICING_ADJUSTMENT_REGION_FAILURE_MESSAGE,
        type: 'ERROR',
      });
    }
    setIsEditing(false);
  };

  return (
    <Root>
      <Header>
        <DropdownButton type="button" onClick={() => setIsActive(!isActive)} data-testid="accordion-toggle-button">
          <ArrowIcon src={`${config.PUBLIC_URL}/images/arrow-right-white.svg`} isOpen={isActive} alt="arrow" />
        </DropdownButton>
        <InputWrapper>
          {isEditing ? (
            <>
              <TextField
                value={regionName}
                onChange={(e: React.ChangeEvent<{ value: string }>) => {
                  setRegionName(e.target.value);
                }}
                name="region.name"
                autoFocus={true}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onUpdate();
                  }
                }}
              />
              <CancelButton
                onClick={() => {
                  setRegionName(props.value);
                  setIsEditing(false);
                }}
              >
                <Typography tag="span" variant="body3" color="bodyDark" inline={true}>
                  Cancel
                </Typography>
                <CancelButtonIcon />
              </CancelButton>
            </>
          ) : (
            <Typography color="bodyDark">{props.title}</Typography>
          )}
        </InputWrapper>

        {isEditing ? (
          <SubmitButton type="button" onClick={onUpdate} data-testid="edit-region-button">
            <SubmitButtonIcon />
          </SubmitButton>
        ) : (
          <EditButton type="button" onClick={() => setIsEditing(true)} data-testid="edit-region-button">
            <EditButtonIcon />
          </EditButton>
        )}
      </Header>
      {isActive && <div data-testid="accordion-content">{props.children}</div>}
    </Root>
  );
};

export { Accordion };
