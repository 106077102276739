import React from 'react';
import styled from 'styled-components';
import { Total } from '../../../../../components/Total';
import { Typography } from '../../../../../components/Typography';
import { GradeSplitStepDemandRow } from '../types';
import { GradeSplitGradeType } from './GradeType';
import { sumGradeSplitRow } from './validation';

const DemandsContainer = styled.div``;

const Header = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
`;

const PlantName = styled.div`
  margin-right: auto;
`;

const DemandTotal = styled.div`
  margin-left: auto;
`;

const GradeTypeContainer = styled.div`
  margin-bottom: 3px;
`;

interface Props {
  name: string;
  row: GradeSplitStepDemandRow;
}

const GradeSplitDemand = ({ name, row }: Props) => (
  <DemandsContainer>
    <Header>
      <PlantName>
        <Typography bold={true} variant="heading6">
          {`${row.plantName} - ${row.locationName}`}
        </Typography>
      </PlantName>
      <DemandTotal>
        <Total
          rangeTargetIdeal={100}
          rangeTargetMax={100}
          rangeTargetMin={0}
          targetType="Range"
          total={sumGradeSplitRow(row)}
          totalText="Total"
          unit="%"
        />
      </DemandTotal>
    </Header>
    {row.hardGrades.length > 0 && (
      <GradeTypeContainer>
        <GradeSplitGradeType grades={row.hardGrades} label="Hard pen" name={`${name}.hardGrades`} />
      </GradeTypeContainer>
    )}
    {row.softGrades.length > 0 && (
      <GradeTypeContainer>
        <GradeSplitGradeType grades={row.softGrades} label="Soft pen" name={`${name}.softGrades`} />
      </GradeTypeContainer>
    )}
    {row.premiumGrades.length > 0 && (
      <GradeTypeContainer>
        <GradeSplitGradeType grades={row.premiumGrades} label="Premium" name={`${name}.premiumGrades`} />
      </GradeTypeContainer>
    )}
    {row.nonBitumenGrades.length > 0 && (
      <GradeTypeContainer>
        <GradeSplitGradeType grades={row.nonBitumenGrades} label="Non bitumen" name={`${name}.nonBitumenGrades`} />
      </GradeTypeContainer>
    )}
  </DemandsContainer>
);

export { GradeSplitDemand };
