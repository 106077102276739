import React from 'react';
import styled, { css } from 'styled-components';

export const TableContainer = styled.table`
  height: 100%;
  width: 100%;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  overflow: auto;
`;

export const TableHead = styled.thead`
  background: ${props => props.theme.base.dark.c};
`;

export const TableRow = styled.tr`
  tbody &:hover > td {
    background: ${props => props.theme.base.dark.d};
  }
`;

export const TH = styled.th<{ width?: string; stickyLeft?: number }>`
  background: ${props => `${props.theme.base.dark.c}`};
  top: 0;
  z-index: 2;
  position: sticky;
  font-weight: normal;
  text-align: left;
  color: ${props => props.theme.secondary.light.c};
  ${props => (props.width ? `min-width: ${props.width};` : '')}
  ${props =>
    props.stickyLeft !== undefined &&
    css`
      left: ${props.stickyLeft}px;
      z-index: 3;
    `}
`;

const THInner = styled.div<{ stickyLeft?: number }>`
  display: flex;
  position: relative;
  height: 40px;
  align-items: center;
  padding-left: ${p => (p.stickyLeft === 0 ? '8px' : '5px')};
  padding-right: 8px;
`;

const Border = styled.div`
  width: 1px;
  position: absolute;
  height: 14px;
  right: 0;
  background: ${props => props.theme.greys.dark.border};
`;

export const TableHeadColumn: React.FC<{ width?: string; stickyLeft?: number }> = ({ children, ...rest }) => (
  <TH {...rest}>
    <THInner stickyLeft={rest.stickyLeft}>
      {children} <Border />
    </THInner>
  </TH>
);

export const TD = styled.td<{ stickyLeft?: number; isDefault?: boolean }>`
  color: ${props => (props.isDefault ? props.theme.greys.dark.disabled : props.theme.secondary.light.c)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;

  ${p =>
    p.stickyLeft !== undefined &&
    css`
      position: sticky;
      left: ${p.stickyLeft}px;
      background-color: ${p => p.theme.base.dark.b};
      z-index: 1;
    `}
`;

const Indicator = styled.div<{ isDefault?: boolean; isToday?: boolean }>`
  height: calc(100% - 4px);
  width: 2px;
  position: absolute;
  left: 0;
  background: ${props => (props.isToday ? props.theme.primary.b : props.theme.secondary.light.c)};
`;

const TDInner = styled.div<{ stickyLeft?: number }>`
  display: flex;
  position: relative;
  height: 32px;
  align-items: center;
  padding-left: ${p => (p.stickyLeft === 0 ? '8px' : '5px')};
  padding-right: 8px;
`;

export const TableColumn: React.FC<{ stickyLeft?: number; isDefault?: boolean; isToday?: boolean }> = ({
  children,
  stickyLeft,
  isDefault,
  isToday,
}) => {
  return (
    <TD stickyLeft={stickyLeft} isDefault={isDefault}>
      <TDInner stickyLeft={stickyLeft}>
        {stickyLeft === 0 && <Indicator isDefault={isDefault} isToday={isToday} />}

        {children}
        <Border />
      </TDInner>
    </TD>
  );
};
