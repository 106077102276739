// to add common functions to east movement forms
import moment from 'moment';
import { SchedulingManagerType } from '@scout/types';

export const getProductionSalesSelectedValues = (selectedMovement: SchedulingManagerType | null) => {
  const ProductionSalesSelectedValues = {
    port: selectedMovement?.port,
    productType: selectedMovement?.productType,
    grade: selectedMovement?.grade,
    plannedStartDate: selectedMovement?.plannedStartDate,
    plannedVolume: selectedMovement?.plannedVolume,
    plannedEndDate: selectedMovement?.plannedEndDate,
    planningNotes: selectedMovement?.planningNotes,
    skipWeekends: selectedMovement?.skipWeekends,
  };
  return ProductionSalesSelectedValues;
};

export const getLiftingReplenishmentSelectedValues = (selectedMovement: SchedulingManagerType | null) => {
  const liftingReplenishmentSelectedValues = {
    id: selectedMovement?.id,
    port: selectedMovement?.port,
    productType: selectedMovement?.productType,
    grade: selectedMovement?.grade,
    plannedVolume: selectedMovement?.plannedVolume,
    actualVolume: selectedMovement?.actualVolume,
    basisPrice: selectedMovement?.basisPrice,
    fobPriceInternal: selectedMovement?.fobPriceInternal,
    fobPriceCustomer: selectedMovement?.fobPriceCustomer,
    internalFreightPrice: selectedMovement?.internalFreightPrice,
    customerFreightActualPrice: selectedMovement?.customerFreightActualPrice,
    purchaseContract: selectedMovement?.purchaseContract,
    salesContract: selectedMovement?.salesContract,
    buyerInfo: selectedMovement?.buyerInfo,
    planningNotes: selectedMovement?.planningNotes,
    vesselName: selectedMovement?.vesselName,
    incoterm: selectedMovement?.incoterm,
    loadPort: selectedMovement?.loadPort,
    loadPortLaycanStartDate: selectedMovement?.loadPortLaycanStartDate,
    loadPortLaycanEndDate: selectedMovement?.loadPortLaycanEndDate,
    plannedLoadMonth: selectedMovement?.plannedLoadMonth,
    BLDate: selectedMovement?.BLDate,
    dischargePort: selectedMovement?.dischargePort,
    country: selectedMovement?.country,
    dischargePortLaycanStartDate: selectedMovement?.dischargePortLaycanStartDate,
    dischargePortLaycanEndDate: selectedMovement?.dischargePortLaycanEndDate,
    autoETA: selectedMovement?.autoETA,
    movementCloneId: selectedMovement?.movementCloneId,
    depotActualVolume: selectedMovement?.depotActualVolume,
    dischargeCompletionDate: selectedMovement?.dischargeCompletionDate,
    dragDischargePortStartDate: selectedMovement?.dragDischargePortStartDate,
    dragLoadportstartdate: selectedMovement?.dragLoadportstartdate,
  };
  return liftingReplenishmentSelectedValues;
};
export const getEventsSelectedValues = (selectedMovement: SchedulingManagerType | null) => {
  const eventsSelectedValues = {
    productType: selectedMovement?.productType,
    eventStartDate: selectedMovement?.eventStartDate,
    eventEndDate: selectedMovement?.eventEndDate,
    eventType: selectedMovement?.eventType,
    port: selectedMovement?.port,
    planningNotes: selectedMovement?.planningNotes,
    blockDates: selectedMovement?.blockDates,
  };
  return eventsSelectedValues;
};

export const getModifiedValue = (
  changedValues: { [s: string]: unknown } | ArrayLike<unknown>,
  currentValues: { [x: string]: unknown },
) => {
  // tslint:disable:no-any
  const result = Object.entries(changedValues).reduce((acc: any, [key, value]) => {
    const hasChanged = currentValues[key] !== value;
    if (hasChanged) {
      acc[key] = value;
    }
    return acc;
  }, {});
  return result;
};
