import React, { useState, useEffect } from 'react';
import { Menu } from './Menu';
import { EastMenu } from './EastMenu';
import { Table, LoaderContainer } from './Table';
import { useQuery } from 'react-apollo';
import { GET_LOCATIONS, LocationNotesQueryResult, LocationsQuery, SchedulingManagerType } from '@scout/types';
import { useSchedulerLocationId } from './hooks';
import { Loader } from '../../../../components/Loader';

import { RequirePermission } from '../../../../components/RequirePermission/index';
import { EastTable } from './EastTable';
import { usePermission } from '../../../../hooks/usePermission';

export interface LocationViewProps {
  onCloseRequest: () => void;
  toggleFullHalfView: (value: boolean) => void;
  fullViewActive: boolean | null;
  currentUserTheme?: boolean;
  toggleTheme: () => void;
  simulation?: boolean;
}
export interface LastUpdatedUserObject {
  updatedBy: string | undefined | null;
  updatedAt: string | undefined | null;
}

export const LocationView: React.FunctionComponent<LocationViewProps> = ({
  onCloseRequest,
  toggleFullHalfView,
  fullViewActive,
  currentUserTheme,
  toggleTheme,
  simulation,
}) => {
  const [locationId, setLocationId] = useSchedulerLocationId();
  const [isNotesModalVisible, setShowNotesModal] = useState(false);
  const [isNewMovementModalVisible, setNewMovementModal] = useState(false);
  const [isSpreedsheetDownloadModalVisible, setSpreedsheetDownload] = useState(false);
  const [selectedLocationNote, setSelectedLocationNote] = useState<LocationNotesQueryResult[0] | null>(null);
  const [selectedMovement, setSelectedMovement] = useState<SchedulingManagerType | null>(null);
  const [isDeleteNewMovementModalVisible, setDeleteNewMovementModal] = useState(false);
  const { data, error, loading } = useQuery<LocationsQuery>(GET_LOCATIONS);
  const [isUndoModalVisible, setUndoModal] = useState(false);
  const [disableUndoButton, setDisableUndoButton] = useState(true);
  const [isEastMoreActionsModalVisible, setEastMoreActionsModal] = useState(false);
  const [isSimulationCloseModalVisible, setSimulationCloseModalVisible] = useState(false);

  const [lastUpdatedUserLog, setLastUpdatedUserLog] = useState<LastUpdatedUserObject | null>(null);
  const { hasAnyOf } = usePermission();

  if (hasAnyOf(['editEastSchedulingManager', 'viewEastSchedulingManager'])) {
    useEffect(() => {
      // adding bukom as default location only for east scheduling manager
      const bukomLocation = data?.locations.find(location => location.name === 'Bukom');
      if (!locationId && bukomLocation) {
        setLocationId(bukomLocation.id);
      }
    }, [data]);
  } else {
    if (hasAnyOf(['eastDepotUser'])) {
      useEffect(() => {
        // adding bukom as default location only for east scheduling manager
        const tabangaoLocation = data?.locations.find(location => location.name === 'Tabangao');
        if (!locationId && tabangaoLocation) {
          setLocationId(tabangaoLocation.id);
        }
      }, [data]);
    } else {
      useEffect(() => {
        const pernisLocation = data?.locations.find(location => location.name === 'PERNIS');

        // If there is no location selected default to pernis for europe
        if (!locationId && pernisLocation) {
          setLocationId(pernisLocation.id);
        }
      }, [data]);
    }
  }

  if (loading || error) {
    return (
      <LoaderContainer>
        <Loader type="section" error={error ? 'Something went wrong' : undefined} />
      </LoaderContainer>
    );
  }

  return (
    <>
      <RequirePermission anyOf={['editFleetManagement']}>
        <Menu
          locations={data?.locations || []}
          onShowNotesModal={() => {
            setSelectedLocationNote(null);
            setShowNotesModal(true);
          }}
          onCloseRequest={onCloseRequest}
        />
        <Table
          onEditLocationNote={() => setShowNotesModal(true)}
          isNotesModalVisible={isNotesModalVisible}
          onHideNotesModal={() => setShowNotesModal(false)}
          selectedLocationNote={selectedLocationNote}
          setSelectedLocationNote={setSelectedLocationNote}
        />
      </RequirePermission>

      <RequirePermission anyOf={['editEastSchedulingManager', 'eastDepotUser']}>
        <EastMenu
          locations={data?.locations || []}
          onShowEastNewMovementModal={() => {
            setNewMovementModal(true);
            setSelectedMovement(null);
          }}
          onShowEastSpreadsheet={() => {
            setSpreedsheetDownload(true);
          }}
          onCloseRequest={onCloseRequest}
          toggleFullHalfView={toggleFullHalfView}
          fullViewActive={fullViewActive}
          onShowEastUndoModal={() => {
            setUndoModal(true);
          }}
          onShowEastMoreActionsModal={() => {
            setEastMoreActionsModal(true);
          }}
          lastUpdatedUserLog={lastUpdatedUserLog}
          disableUndoButton={disableUndoButton}
          currentUserTheme={currentUserTheme}
          simulation={simulation}
          onCloseSimulationModal={() => setSimulationCloseModalVisible(true)}
        />
        <EastTable
          selectedMovement={selectedMovement}
          setSelectedMovement={setSelectedMovement}
          locations={data?.locations || []}
          isNewMovementModalVisible={isNewMovementModalVisible}
          isSpreedsheetDownloadModalVisible={isSpreedsheetDownloadModalVisible}
          onHideEastNewMovementModal={() => setNewMovementModal(false)}
          onEditMovementModal={() => {
            setNewMovementModal(true);
          }}
          onHideDownloadExcelModal={() => setSpreedsheetDownload(false)}
          onDeleteMovementModal={() => setDeleteNewMovementModal(true)}
          isDeleteNewMovementModalVisible={isDeleteNewMovementModalVisible}
          onHideEastDeleteNewMovementModal={() => setDeleteNewMovementModal(false)}
          isUndoModalVisible={isUndoModalVisible}
          onShowEastUndoModal={() => {
            setUndoModal(true);
          }}
          onHideEastUndoModal={() => setUndoModal(false)}
          onUndoModal={() => setUndoModal(true)}
          isEastMoreActionsModalVisible={isEastMoreActionsModalVisible}
          onShowEastMoreActionsModal={() => {
            setEastMoreActionsModal(true);
          }}
          onHideEastMoreActionsModal={() => setEastMoreActionsModal(false)}
          onEastMoreActionsModal={() => setEastMoreActionsModal(true)}
          lastUpdatedUserLog={value =>
            setLastUpdatedUserLog({ updatedBy: value.updatedBy, updatedAt: value.updatedAt })
          }
          disableUndoButton={value => setDisableUndoButton(value)}
          currentUserTheme={currentUserTheme}
          toggleTheme={toggleTheme}
          simulation={simulation}
          onCloseSimulationModal={() => setSimulationCloseModalVisible(true)}
          onCancelSimulationModal={() => setSimulationCloseModalVisible(false)}
          isSimulationCloseModalVisible={isSimulationCloseModalVisible}
        />
      </RequirePermission>
    </>
  );
};
