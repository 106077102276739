import React from 'react';
import styled, { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon: React.FC<{
  theme?: IThemeType;
  currentUserTheme?: boolean;
}> = withTheme(({ theme, currentUserTheme }) => (
  <CloseIconContainer>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill={currentUserTheme ? theme.base.dark.a : theme.secondary.light.c}
      />
    </svg>
  </CloseIconContainer>
));
