import { Form, Formik, FormikActions } from 'formik';
import React, { Ref, useCallback, useState } from 'react';
import styled from 'styled-components';
import { LabelValue } from '../../../../../components/LabelValue';
import { useToast } from '../../../../../components/Toast/Toast';
import { Typography } from '../../../../../components/Typography';
import { Contract, ContractDealType, DemandLocation } from '@scout/types';
import { ContractDemandState } from '../../types';
import { StepTitle } from '../StepTitle';
import { MonthlyVolumesStepValues, MonthlyVolumesStepValuesRow } from '../types';
import { createValues, sumVolumes } from './calculate';
import { VolumesTable } from './Table';
import { formatStats } from './formatStats';
import { Button } from '../../../../../components/Button';

const Wrapper = styled.div`
  max-width: 1124px;
  margin: 0 auto;
  padding: 30px 0;
`;

const StyleWrapper = styled.div`
  max-width: 1124px;
  margin: 0 auto;
  padding: 20px 0;
  overflow-y: auto;
  max-height: 300px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SpotHeader = styled.div`
  max-width: 1124px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
`;

const TotalVolumeText = styled(Typography)`
  flex: 1;
`;

const HeaderStats = styled.div`
  display: flex;
`;

const HeaderStatsContent = styled.div`
  margin-left: 24px;
`;

interface StyledButtonProps {
  variant?: string;
}

const StyledButtonLightTheme = styled(Button).attrs<StyledButtonProps>(props => ({
  variant: 'ghost-light',
  ...props,
}))<StyledButtonProps>`
  width: 9%;
`;
const ButtonHeader = styled.div`
  display: flex;
  justify-content: end;
`;
const Styles = styled.div`
  display: flex;
`;

interface Props {
  contract: Pick<
    Contract,
    | 'end'
    | 'fuelOil'
    | 'sellingPrice'
    | 'start'
    | 'totalVolume'
    | 'type'
    | 'jan'
    | 'feb'
    | 'mar'
    | 'apr'
    | 'may'
    | 'jun'
    | 'jul'
    | 'aug'
    | 'sep'
    | 'oct'
    | 'nov'
    | 'dec'
  >;
  clusterCurrency: string;
  formRef: Ref<Formik<MonthlyVolumesStepValues>>;
  onSubmit: (values: MonthlyVolumesStepValues) => void;
  showSellingPrice?: boolean;
  state: ContractDemandState;
  // tslint:disable-next-line
  spotDemands?: any;
  handleOffset: () => void;
  onSelectRow: (rowId: string) => void;
  setSelectedRow?: string;
  disabled?: boolean;
}

const areValuesValid = (values: MonthlyVolumesStepValues, state: ContractDemandState): boolean =>
  values.rows.every(row => {
    const rowTotal = sumVolumes(row.volumes);
    const demand = state.demands.find(d => d.id === row.id)!;

    return rowTotal === demand.totalVolume;
  });

const MonthlyVolumesStep: React.FC<Props> = ({
  formRef,
  onSubmit,
  showSellingPrice,
  state,
  contract,
  clusterCurrency,
  spotDemands,
  handleOffset,
  onSelectRow,
  setSelectedRow,
  disabled,
}) => {
  const { push } = useToast();

  const handleSubmit = useCallback(
    (values, formikActions: FormikActions<MonthlyVolumesStepValues>) => {
      const valid = areValuesValid(values, state);

      if (!valid) {
        formikActions.setSubmitting(false);
        push({
          text: 'Each of your demands need to equal the total volume you set in Step 1',
          size: 'LARGE',
          type: 'ERROR',
        });
        return;
      }

      onSubmit(values);
    },
    [onSubmit, push, state],
  );
  const [disableBtn, setDisableBtn] = useState<boolean | undefined>(disabled);
  const initialValues = createValues({ contract, demands: state.demands });
  const offSetValues = () => {
    handleOffset();
    setSelectedRow && setSelectedRow.length > 0 && setDisableBtn(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ref={formRef}
      render={({ handleChange, values }) => {
        return (
          <>
            <Form>
              <Wrapper>
                <StepTitle number={3} title="Monthly volumes" />
                <Header>
                  <TotalVolumeText variant="heading6">
                    Total Contract volume: <strong>{contract.totalVolume}</strong> t
                  </TotalVolumeText>

                  <HeaderStats>
                    <HeaderStatsContent>
                      <LabelValue label="Cluster Currency" value={clusterCurrency} />
                    </HeaderStatsContent>
                    <HeaderStatsContent>
                      <LabelValue label="Fuel oil price" value={formatStats(contract.fuelOil)} />
                    </HeaderStatsContent>
                    {showSellingPrice && (
                      <HeaderStatsContent>
                        <LabelValue label="Selling price" value={formatStats(contract.sellingPrice)} />
                      </HeaderStatsContent>
                    )}
                  </HeaderStats>
                </Header>
                <VolumesTable
                  demandLocation={state.demandLocation}
                  onChange={handleChange}
                  rows={values.rows}
                  onSelectRow={onSelectRow}
                />
              </Wrapper>
            </Form>
            {spotDemands && spotDemands.length > 0 && contract.type === ContractDealType.Formula && (
              <>
                <SpotHeader>
                  <TotalVolumeText variant="heading6">Select Spot Demand To Offset</TotalVolumeText>
                </SpotHeader>
                <StyleWrapper>
                  <Form>
                    <VolumesTable
                      demandLocation={state.demandLocation}
                      onChange={handleChange}
                      rows={spotDemands}
                      type="formula"
                      onSelectRow={onSelectRow}
                      setSelectedRow={setSelectedRow}
                      disabled={disabled}
                    />
                  </Form>
                  <ButtonHeader>
                    <StyledButtonLightTheme onClick={offSetValues} disabled={disableBtn}>
                      Offset
                    </StyledButtonLightTheme>
                  </ButtonHeader>
                </StyleWrapper>
              </>
            )}
          </>
        );
      }}
    />
  );
};

export { MonthlyVolumesStep };
