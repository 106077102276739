import { createGlobalStyle, GlobalStyleComponent, css } from 'styled-components';
import { theme, IThemeType } from '../../theme';

interface Props {
  disablePrevNavigation: boolean;
  disableNextNavigation: boolean;
  themeStyle: 'dark' | 'light';
}

const themeColors = {
  dark: {
    background: theme.base.dark.c,
    cellBackground: theme.base.dark.d,
    color: theme.secondary.light.c,
    borderColor: theme.greys.dark.border,
  },
  light: {
    background: theme.base.light.a,
    cellBackground: theme.base.light.c,
    color: theme.base.dark.b,
    borderColor: theme.greys.light.border,
  },
};

export const SingleDatePickerStyles: GlobalStyleComponent<Props, IThemeType> = createGlobalStyle`
.CalendarMonthGrid_month__horizontal .CalendarMonth,
.DayPicker.DayPicker__horizontal,
.DayPicker.DayPicker_transitionContainer__horizontal,
.DayPicker .CalendarMonthGrid {
  background: ${p => themeColors[p.themeStyle].background};
  border-radius: 2px;
}

.DayPicker.DayPicker__withBorder {
  box-shadow: initial;
}

.DayPicker .CalendarDay__default {
  background: ${p => themeColors[p.themeStyle].cellBackground};
  color: ${p => themeColors[p.themeStyle].color};
  border-color: ${p => themeColors[p.themeStyle].background};
  border-radius: 2px;
}

.DayPicker .CalendarDay__default:hover {
  background: ${theme.primary.b};
  border: inherit;
}

.DayPicker .CalendarMonth_caption {
  color: ${p => themeColors[p.themeStyle].color};
}

.DayPicker .CalendarDay__selected {
  background: ${theme.primary.b};
  color: ${p => themeColors[p.themeStyle].background};
}

.DateRangePicker .CalendarDay__selected_span,
.DateRangePicker .CalendarDay__hovered_span:hover,
.DateRangePicker .CalendarDay__hovered_span {
  background: ${theme.primary.b}${theme.opacity[0.1]};
  color: ${theme.secondary.light.c};
}

.DateRangePicker .DateRangePickerInput,
.DateRangePickerInput .DateInput,
.DateRangePickerInput .DateInput_1,
.DateRangePickerInput .DateInput_input_1,
.DateRangePickerInput .DateInput_input__focused,
.DateRangePickerInput .DateRangePickerInput__withBorder {
  background: transparent;
  border: none;
  color: ${theme.secondary.light.c};
  text-align: center;
}

.DateRangePickerInput .DateInput_input  {
  border: 1px solid ${theme.secondary.light.c};
}

.DateRangePickerInput input[id="startDate"] {
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.DateRangePickerInput input[id="endDate"] {
  border-left: 0;
  border-radius: 0 2px 2px 0;
}

.DateRangePickerInput .DateInput_input.DateInput_input__focused {
  border-bottom: 3px solid ${theme.primary.b};
  padding: 9px;
}

.DateRangePickerInput .DateRangePickerInput_arrow {
  line-height: 2.8;
  border-top: 1px solid ${theme.secondary.light.c};
  border-bottom: 1px solid ${theme.secondary.light.c};
}

.DayPickerNavigation .DayPickerNavigation_button {
  background: transparent;
  border-radius: 2px;
  border: 1px solid ${p => themeColors[p.themeStyle].color};
  padding: 6px;
}

.DayPickerNavigation .DayPickerNavigation_button__default:active,
.DayPickerNavigation .DayPickerNavigation_button__default:focus {
  border-color: ${p => themeColors[p.themeStyle].color};
  color: ${p => themeColors[p.themeStyle].color};
  background: transparent;
}

.DayPickerNavigation .DayPickerNavigation_button__default:hover {
  border-color: ${theme.primary.b};

  svg {
    fill: ${theme.primary.b};
  }
}

.DayPickerNavigation .DayPickerNavigation_leftButton__horizontalDefault {
  ${p =>
    p.disablePrevNavigation &&
    css`
      pointer-events: none;
      display: none;
    `}
}

.DayPickerNavigation .DayPickerNavigation_rightButton__horizontalDefault {
  ${p =>
    p.disableNextNavigation &&
    css`
      pointer-events: none;
      display: none;
    `}
}

.DateRangePickerInput .DateRangePickerInput_arrow_svg,
.DayPickerNavigation .DayPickerNavigation_button svg {
  fill: ${p => themeColors[p.themeStyle].color}
}

.DateInput_fang .DateInput_fangStroke {
  stroke: ${p => themeColors[p.themeStyle].background};
  fill: ${p => themeColors[p.themeStyle].background};
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.CalendarMonth .CalendarMonth_table.CalendarMonth_table_1 {
  margin-top: .5rem;
}

.DayPicker_weekHeader .DayPicker_weekHeader_ul  {
  color: ${p => themeColors[p.themeStyle].color};
  border-top: 1px solid ${p => themeColors[p.themeStyle].borderColor};
  font-size: 1rem;
  padding: .5rem 0;
}

::placeholder {
  color: ${theme.secondary.light.c};
}

.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    z-index: 2;
    right: 10px;
    left: initial;
}

.DayPicker .CalendarDay__blocked_out_of_range,
.DayPicker  .CalendarDay__blocked_out_of_range:hover {
  background-color: ${p => (p.themeStyle === 'light' ? p.theme.greys.light.disabled : p.theme.greys.dark.disabled)};
  color: ${p => (p.themeStyle === 'light' ? p.theme.typography.colors.hintLight : p.theme.typography.colors.hintDark)}

}
`;
