import * as React from 'react';
import { createFilter } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import { FormError } from '../../FormError/FormError';
import { ClearIndicator } from '../Common/ClearIndicator';
import { DropdownIndicator } from '../Common/DropdownIndicator';
import { StyledSelect } from '../Common/StyledSelect';
import { SelectOptions } from '../SelectList';
import { MenuList } from './MenuList';
import { Option } from './Option';

const defaultFilter = createFilter({});

export interface DropdownItemOptions extends SelectOptions {
  tag?: string;
  id?: string;
}

interface SelectOption {
  label: string;
  value: string;
  data: DropdownItemOptions;
}

export interface SingleDropdownProps {
  placeholder?: string;
  name: string;
  items: DropdownItemOptions[];
  selected: string | null;
  onItemClick: (item: DropdownItemOptions) => void;
  disabled?: boolean;
  onBlur?: () => void;
  error?: string;
  renderOption?: (renderOptions: OptionProps<{}>) => React.ReactNode;
  width?: string;
  darkTheme?: boolean;
  showClearIndicator?: boolean;
  showValidation?: boolean;
  lineNumber?: number;
  isClearable?: boolean;
  tabIndex?: number;
  filterOption?: (option: SelectOption, inputValue: string) => boolean;
  eastLocationDropdown?: boolean;
}

const customFilterOption = (option: SelectOption, inputValue: string) => {
  const defaultResult = defaultFilter(option, inputValue);
  const idResult = option.data.id ? option.data.id.toLowerCase().includes(inputValue.toLowerCase()) : false;

  return defaultResult || idResult;
};

const SingleDropdown = ({
  placeholder,
  items,
  onItemClick,
  selected,
  disabled,
  onBlur,
  error,
  name,
  width,
  darkTheme,
  showClearIndicator = true,
  showValidation = true,
  lineNumber = 1,
  isClearable = true,
  filterOption,
  eastLocationDropdown,
  ...props
}: SingleDropdownProps) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const handleOnMenuOpen = React.useCallback(() => setMenuIsOpen(true), []);
  const handleOnMenuClose = React.useCallback(() => setMenuIsOpen(false), []);
  return (
    <div data-testid={`${name}-single-dropdown`}>
      <StyledSelect
        tabIndex={props.tabIndex}
        lineNumber={lineNumber}
        menuPlacement="auto"
        error={error}
        components={{ DropdownIndicator, Option: props.renderOption || Option, MenuList, ClearIndicator }}
        menuIsOpen={menuIsOpen}
        onMenuOpen={handleOnMenuOpen}
        onMenuClose={handleOnMenuClose}
        styles={{
          control: (base: object) => ({
            ...base,
            boxShadow: 'none',
            width,
          }),
        }}
        isDisabled={disabled}
        value={!selected ? '' : items.find(item => item.value === selected)}
        placeholder={placeholder}
        variant="body3"
        noOptionsMessage={() => 'No results found'}
        name={name}
        classNamePrefix="single-dropdown"
        options={items}
        isClearable={isClearable}
        isSearchable={true}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        onChange={(item: DropdownItemOptions | null) => onItemClick(item ? item : { label: null, value: null })}
        onBlur={onBlur}
        filterOption={filterOption || customFilterOption}
        darkTheme={darkTheme}
        showClearIndicator={showClearIndicator}
        eastLocationDropdown={eastLocationDropdown}
      />
      {showValidation && <FormError message={error} withSpaceHolder={true} />}
    </div>
  );
};

export { SingleDropdown };
