import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../components/Loader';
import { MovementHeader } from './MovementHeader';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
`;

interface Props {
  title: string;
  error?: string;
}

export const SectionLoader: React.FC<Props> = ({ title, error }) => {
  const history = useHistory();

  return (
    <Container>
      <MovementHeader title={title} onClose={() => history.goBack()} />
      <LoaderContainer>
        <Loader type="section" error={error} />
      </LoaderContainer>
    </Container>
  );
};
