import * as React from 'react';
import styled, { css } from 'styled-components';
import { fontMixin, FontProps } from '../Mixins';
import { FormError } from '../FormError/FormError';

export interface SelectOptionProps {
  value: string;
  label: string;
}

interface SelectProps {
  selected?: string;
  onItemClick: (value: string) => void;
  items: SelectOptionProps[];
  disabled?: boolean;
  themeStyle?: 'dark' | 'light';
  error?: string;
  'data-testid'?: string;
}

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 40px;
`;

const SelectedItemMixin = css`
  background: ${props => props.theme.backgroundTertiary10};
  color: ${props => props.theme.primary.b};

  &:first-of-type {
    border-color: ${props => props.theme.primary.b};
  }

  border-color: ${props => props.theme.primary.b};
`;

const DisabledItemMixin = css`
  color: ${props => props.theme.greys.light.disabled};
  border-color: ${props => props.theme.greys.light.border};
  cursor: auto;
`;

const LightThemeMixin = css`
  color: ${props => props.theme.secondary.light.d};
  border: 1px solid ${props => props.theme.greys.dark.border};

  &:first-of-type {
    border-left-color: ${props => props.theme.greys.dark.border};
  }
`;

const DarkThemeMixin = css`
  color: ${props => props.theme.secondary.light.c};
  border: 1px solid ${props => props.theme.greys.light.border};

  &:first-of-type {
    border-left-color: ${props => props.theme.greys.light.border};
  }
`;

const ErrorMixin = css`
  &:first-of-type {
    border-left-color: ${props => props.theme.secondary.light.a};
  }
  border-color: ${props => props.theme.secondary.light.a};
`;

const ListItem = styled.li<
  { themeStyle?: string; selected: boolean; width: number; disabled: boolean; error?: string } & FontProps
>`
  ${fontMixin}
  background: transparent;
  border-left: none;
  cursor: pointer;
  flex-grow: 1;
  padding: 0.6rem;
  width: ${p => p.width}%;

  &:last-of-type {
    border-radius: 0 3px 3px 0;
  }

  &:first-of-type {
    border-left: 1px solid ${props => props.theme.greys.light.border};
    border-radius: 3px 0 0 3px;
  }

  ${props => (props.themeStyle === 'dark' ? DarkThemeMixin : LightThemeMixin)}
  ${props => props.selected && SelectedItemMixin}
  ${props => props.disabled && DisabledItemMixin}
`;

const ErrorContainer = styled.div`
  height: 2rem;
`;

const Select: React.FunctionComponent<SelectProps> = ({
  themeStyle = 'dark',
  items,
  onItemClick,
  selected,
  disabled = false,
  error,
  'data-testid': testId,
}) => {
  return (
    <>
      <List>
        {items.map((item, index) => (
          <ListItem
            data-testid={`${testId}-${item.value.replace(' ', '-').toLowerCase()}`}
            themeStyle={themeStyle}
            width={items.length / 100}
            variant="body3"
            align="center"
            key={`tab-${index}`}
            onClick={() => !disabled && onItemClick(item.value)}
            selected={selected === item.value}
            disabled={disabled}
            error={error}
          >
            {item.label}
          </ListItem>
        ))}
      </List>
      <ErrorContainer>{error && <FormError message={error} />}</ErrorContainer>
    </>
  );
};

export { Select };
