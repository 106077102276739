import React, { useContext, useState } from 'react';
import { UserThemeContext } from '../../../App';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { VolumePricingItem } from '../../../types';
import { ConfirmationModal } from './ConfirmationModal';
import { DemandNotSavedModal } from './DemandNotSavedModal';

interface Props {
  onConfirmSecondary: () => void;
  onSubmit: () => void;
  values: { totalGrades: number; volumePrices: VolumePricingItem[] };
  isSaving: boolean;
  isEditing?: boolean;
  isDirty: boolean;
}

const Footer: React.FC<Props> = ({ onConfirmSecondary, values, isSaving, isEditing, isDirty, ...props }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const [showQuitModal, setShowQuitModal] = useState(false);
  const [showNotSavedModal, setShowNotSavedModal] = useState(false);

  return (
    <>
      {showQuitModal && (
        <ConfirmationModal
          showQuitModal={showQuitModal}
          onConfirm={() => {
            setShowQuitModal(false);
            props.onSubmit();
          }}
          onDismiss={() => {
            setShowQuitModal(false);

            onConfirmSecondary();
          }}
          currentUserTheme={currentUserTheme}
        />
      )}
      {showNotSavedModal && (
        <DemandNotSavedModal
          showModal={showNotSavedModal}
          onConfirm={() => setShowNotSavedModal(false)}
          currentUserTheme={currentUserTheme}
        />
      )}
      <ConfirmationPrompt
        onConfirmPrimary={() => {
          const hasEmptyTotalGrades = values.totalGrades === 0;
          const hasEmptyVolumePrices = values.volumePrices.every(volumePrice => volumePrice.volume === '');

          if (hasEmptyTotalGrades && hasEmptyVolumePrices) {
            setShowNotSavedModal(true);
          } else {
            props.onSubmit();
          }
        }}
        primaryText={isSaving ? 'Saving...' : 'Save & close'}
        onConfirmSecondary={() => (isDirty ? setShowQuitModal(true) : onConfirmSecondary())}
        secondaryText={isEditing ? 'Return to dashboard' : 'Cancel'}
        currentUserTheme={currentUserTheme}
      />
    </>
  );
};

export { Footer };
