import React from 'react';
import { ContractDealType } from '@scout/types';
import { TableDataItem } from '../createTableData';
import { TableRow } from './TableRow';

interface Props {
  emptyComponent: React.ReactElement;
  onRowClick: (dealId: string, type: ContractDealType) => void;
  rowItems: TableDataItem[];
  currentUserTheme?: boolean;
}

const TableBody: React.FC<Props> = ({ emptyComponent, onRowClick, rowItems, currentUserTheme }) => (
  <tbody data-testid="contracts-table-body">
    {rowItems.length === 0 ? (
      <tr>{emptyComponent}</tr>
    ) : (
      rowItems.map(row => (
        <TableRow item={row} key={row.dealId} onRowClick={onRowClick} currentUserTheme={currentUserTheme} />
      ))
    )}
  </tbody>
);

export { TableBody };
