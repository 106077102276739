import { FastField, Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { CurrentUserQuery } from '@scout/types';
import { Modal } from '../Modal';
import { MonthItem, MonthRangeSelect, SelectedItem } from '../MonthRangeSelect/MonthRangeSelect';
import { SingleDropdown } from '../SelectDropdown/Single';
import { ToastProps, useToast } from '../Toast/Toast';
import { Typography } from '../Typography';
import { downloadNetsim } from './downloadNetsim';
import { UniqueFieldDefinitionNamesRule } from 'graphql';
import { UserThemeContext } from '../../App';
import { MultiDropdownItem, MultiSelectDropdown } from '../SelectDropdown/Multi';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: -5px;
`;

const Label = styled(Typography).attrs(props => ({
  variant: 'body3',
  color: props.currentUserTheme ? 'bodyLight' : 'bodyDark',
}))<{ currentUserTheme?: boolean }>`
  margin: 0 0 5px;
`;

export const StyledTypeaheadDropdown = styled(SingleDropdown)`
  margin-bottom: 10px;
`;

const FieldContainer = styled.div`
  padding-bottom: 2rem;
`;

export const CLUSTER_FIELD = 'clusterId';
export const DATE_RANGE_FIELD = 'dateRange';

const NetsimModalSchema = Yup.object().shape({
  [CLUSTER_FIELD]: Yup.string()
    .nullable()
    .required('This is a required field'),
  [DATE_RANGE_FIELD]: Yup.string()
    .nullable()
    .required('This is a required field'),
});

export const getDates = (): MonthItem[] => {
  let startDate = moment(new Date())
    .startOf('year')
    .subtract(1, 'years');

  const endDate = moment(new Date())
    .endOf('year')
    .startOf('month')
    .add(1, 'years');

  const months: MonthItem[] = [];
  months.push({ date: startDate.toDate().toDateString(), readonly: false });
  while (startDate.isBefore(endDate)) {
    startDate = startDate.add(1, 'month');
    months.push({ date: startDate.toDate().toDateString(), readonly: false });
  }

  return months;
};

export const getSdfFields = () => {
  const options = [
    { value: 'All', label: 'Select All' },
    { value: 'contractId', label: 'Contract Id' },
    { value: 'owner', label: 'Owner' },
    { value: 'isBaseDemand', label: 'Base Demand' },
    { value: 'contractStatus', label: 'Contract Status' },
    { value: 'isPremium', label: 'Premium Plant' },
    { value: 'plantSelectionReason', label: 'Plant Selection Reason' },
  ];

  return options as MultiDropdownItem[];
};
export interface NetsimModalProps {
  currentUser: CurrentUserQuery['currentUser'];
  isOpen: boolean;
  onCancel: () => void;
}

export interface SelectedFields {
  value: string;
  label: string;
}

export interface NetsimFieldProps {
  clusterId: string | null;
  dateRange: SelectedItem | null;
  sdfFields: SelectedFields[] | null;
}

export const handleSubmit = async (
  values: FormikValues,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  pushToast: (toastProps: ToastProps) => void,
) => {
  try {
    setLoading(true);
    await downloadNetsim(values);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    pushToast({ type: 'ERROR', text: err.message });
  }
};

export const NetsimModal = ({ currentUser, isOpen, onCancel }: NetsimModalProps) => {
  const { push } = useToast();
  const [isLoading, setLoading] = React.useState(false);
  const { currentUserTheme } = React.useContext(UserThemeContext);
  const userCluster = [
    ...currentUser.clusters.map(({ name, id }) => ({
      label: name,
      value: JSON.stringify([id]),
    })),
    { label: 'All Clusters', value: JSON.stringify(currentUser.clusters.map(({ id }) => id)) },
  ];
  const [multiSdfSelectOptions, setMultiSdfSelectOptions] = React.useState<MultiDropdownItem[] | undefined>();

  return (
    <Formik
      validationSchema={NetsimModalSchema}
      initialValues={{
        [CLUSTER_FIELD]: null,
        [DATE_RANGE_FIELD]: null,
      }}
      onSubmit={values => handleSubmit(values, setLoading, push)}
    >
      {({ submitForm }) => {
        return (
          <Modal
            title="Export SDF"
            onConfirm={submitForm}
            onDismiss={onCancel}
            isOpen={isOpen}
            minWidth={450}
            confirmText="Export"
            isLoading={isLoading}
            currentUserTheme={currentUserTheme}
          >
            <StyledForm>
              <Field
                name={CLUSTER_FIELD}
                render={({ field, form }: FieldProps<{ shipTo: string | null }>) => (
                  <>
                    <Label currentUserTheme={currentUserTheme}>Cluster</Label>
                    <StyledTypeaheadDropdown
                      darkTheme={currentUserTheme ? false : true}
                      showClearIndicator={false}
                      name={field.name}
                      error={form.touched.shipTo ? form.errors.shipTo : undefined}
                      placeholder="Select a cluster to export"
                      onItemClick={item => form.setFieldValue(field.name, item.value)}
                      selected={field.value}
                      items={userCluster}
                    />
                  </>
                )}
              />
              <FieldContainer>
                <Field
                  name="sdfFields"
                  render={({ form }: FieldProps<NetsimFieldProps>) => (
                    <>
                      <Label currentUserTheme={currentUserTheme}>Custom Fields</Label>
                      <MultiSelectDropdown
                        allowSelectAll={true}
                        value={multiSdfSelectOptions}
                        placeholder="Select SDF Fields"
                        options={getSdfFields()}
                        name="sdfFields"
                        onChange={items => {
                          setMultiSdfSelectOptions(items);
                          form.setFieldValue('sdfFields', items);
                        }}
                        currentUserTheme={currentUserTheme}
                      />
                    </>
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Label currentUserTheme={currentUserTheme}>Forecast months</Label>
                <FastField
                  name={DATE_RANGE_FIELD}
                  render={({ field, form }: FieldProps<NetsimFieldProps>) => (
                    <MonthRangeSelect
                      monthItems={getDates()}
                      onItemClick={id => {
                        form.setFieldValue(field.name, id);
                      }}
                      selected={field.value}
                      error={form.touched[DATE_RANGE_FIELD] ? form.errors[DATE_RANGE_FIELD] : undefined}
                      limit={12}
                    />
                  )}
                />
              </FieldContainer>
            </StyledForm>
          </Modal>
        );
      }}
    </Formik>
  );
};
