import moment from 'moment';
import { VolumePricingItem } from '../../../../types';
import { SpotDemandForecastModel } from '@scout/types';

type CreateInitialVolumePricesItems = Array<
  Pick<SpotDemandForecastModel, 'date' | 'discount' | 'id' | 'incrementalVolume' | 'price' | 'volume'>
>;
export const createInitialVolumePrices = (
  year: number,
  items?: CreateInitialVolumePricesItems,
): VolumePricingItem[] => {
  const startOfYear = moment.utc(`${year}`, 'YYYY');

  return Array.from({ length: 12 }, (_, key: number) => {
    const dateValue = key === 0 ? startOfYear.format('YYYY-MM-DD') : startOfYear.add(1, 'month').format('YYYY-MM-DD');
    const isReadOnly = moment.utc(dateValue).isBefore(moment.utc(), 'month');

    if (items) {
      const item = items.find(item => {
        const itemDate = moment.utc(item.date).format('YYYY-MM-DD');
        return itemDate === dateValue;
      });

      if (item) {
        return {
          id: item.id,
          discount: item.discount?.toString() || '',
          incrementalVolume: item.incrementalVolume?.toString() || '',
          price: item.price?.toString() || '',
          readonly: isReadOnly,
          volume: item.volume?.toString() || '',
          date: dateValue,
        };
      }
    }

    return {
      discount: '',
      incrementalVolume: '',
      price: '',
      readonly: isReadOnly,
      volume: '',
      date: dateValue,
    };
  });
};
