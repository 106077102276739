// to add common styles to all east movement forms
import styled from 'styled-components';
import { theme } from '../../../../../theme';

export const Required = styled.span`
  color: ${props => props.theme.errors.red1};
`;

export const RequiredText = styled.span`
font: ${theme.typography.variants.labels2}
color: ${props => props.theme.errors.red1};  
font-style: italic;
`;

export const LiftingAndReplenishmentGradeContainer = styled.div`
  width: 100%;
`;

export const ProductionGradeContainer = styled.div`
  margin-top: -1px;
  padding: 12px;
`;

export const SaleGradeContainer = styled.div`
  margin-top: -6px;
  padding: 12px;
`;

export const CheckboxContainer = styled.div`
  margin-bottom: -60px;
  margin-top: -39px;
`;
