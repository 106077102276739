import React, { useEffect, useState } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { DefaultLayout } from '../../layouts';
import { Container, Row, Box } from '../Landing';
import { Typography } from '../../components/Typography';
import { Error } from '../../components/Loader/Error';
import { ReportAsiaEastIcon } from '../../components/Icons/ReportAsiaEastIcon';
import { ReportEuropeIcon } from '../../components/Icons/ReportEuropeIcon';
import { ScoutLogoIcon } from '../../components/Icons/ScoutLogoIcon';
import { SectionSpinner } from '../../components/Loader/SectionSpinner';
import { URLS } from '../../constants';
import { ReportView } from './ReportView';

export interface IPage {
  name: string;
  displayName: string;
  order: number;
}
export interface IReport {
  embedUrl: string;
  reportId: string;
  reportName: string;
  pages: IPage[];
}

export interface IReportConfig {
  accessToken: string;
  expiry: string;
  status: number;
  embedUrl: IReport[];
}

interface IRegionSelectionProps {
  error: boolean;
  reportConfig: IReportConfig;
}

export const PowerbiDashboard: React.FC<RouteComponentProps> = ({ location }) => {
  const reportsApi = '/api/powerbi/reports';
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<IReportConfig>({
    accessToken: '',
    expiry: '',
    status: 0,
    embedUrl: [],
  });
  useEffect(() => {
    const fetchReports = async () => {
      if (location.state?.refresh) {
        setIsLoading(true);
        await fetch('/refresh', {
          credentials: 'include',
          headers: {
            accept: 'application/json',
          },
          method: 'POST',
        });
      }

      const reportConfigResponse = await fetch(reportsApi);
      setIsLoading(false);

      if (!reportConfigResponse.ok) {
        console.error(
          `Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`,
        );
        setError(true);
        return;
      }

      const reportResponse = await reportConfigResponse.json();

      // Set the fetched embedUrl and embedToken in the report config
      setReportConfig(reportResponse);
    };

    fetchReports().catch(console.error);
  }, [location.state?.refresh]);

  return (
    <Switch>
      <Route
        exact={true}
        path={URLS.REPORTS_LANDING}
        render={() => <RegionSelection error={error} reportConfig={reportConfig} />}
      />
      <Route
        path={URLS.REPORTS_TYPE}
        render={props => <ReportView reportConfig={reportConfig} isLoading={isLoading} {...props} />}
      />
    </Switch>
  );
};

export const RegionSelection: React.FC<IRegionSelectionProps> = ({ error, reportConfig }) => {
  return (
    <DefaultLayout>
      <Container>
        <ScoutLogoIcon />
        <Typography variant="body1" color="bodyDark">
          Select the region
        </Typography>
        <Row>
          {!reportConfig.status && <SectionSpinner />}
          {(error || reportConfig.status === 500) && <Error message="Error retrieving PowerBI token" />}
          {reportConfig.embedUrl?.map(report => (
            <Box
              key={report.reportId}
              icon={/europe/gi.test(report.reportName) ? ReportEuropeIcon : ReportAsiaEastIcon}
              url={{
                pathname: `${URLS.REPORTS_LANDING}${report.reportId}/${report.pages[0]?.name}`,
                state: {
                  token: reportConfig.accessToken,
                  reports: reportConfig.embedUrl,
                  report,
                },
              }}
            >
              {report.reportName}
            </Box>
          ))}
        </Row>
      </Container>
    </DefaultLayout>
  );
};
