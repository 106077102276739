import React from 'react';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { LocationsQuery } from '@scout/types';
import { getEastLocationItems, getEastLocationsDepotUsers } from '../utils';
import { useSchedulerLocationId } from './hooks';
import { usePermission } from '../../../../hooks/usePermission';

export const EastLocationDropdown: React.FC<{ locations: LocationsQuery['locations']; currentUserTheme?: boolean }> = ({
  locations,
}) => {
  const [locationId, setLocationId] = useSchedulerLocationId();
  const { hasAnyOf } = usePermission();
  return (
    <SingleDropdown
      name="eastlocation"
      width="152px"
      placeholder="Location"
      selected={locationId || null}
      onItemClick={({ value }) => {
        if (value) {
          setLocationId(value);
        }
      }}
      items={hasAnyOf(['eastDepotUser']) ? getEastLocationsDepotUsers(locations) : getEastLocationItems(locations)}
      darkTheme={true}
      showValidation={false}
      showClearIndicator={false}
      eastLocationDropdown={true}
    />
  );
};
