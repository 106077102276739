import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import React from 'react';
import { components as SelectComponents } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import styled from 'styled-components';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { Typography } from '../../../../components/Typography';
import { SpotFormValuesProps, PlantDropdownValue } from '../Form';
import { PlantOptions } from '../types';
import { sortPlants } from '../GradePlantContainer';

const DropdownLabelRoot = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 20px;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
`;

const LabelStyled = styled.div`
  margin: 0 0 5px;
  display: block;
`;

const Label = ({ children }: { children: React.ReactNode }) => (
  <LabelStyled>
    <Typography variant="body4" tag="label">
      {children}
    </Typography>
  </LabelStyled>
);
interface PlantsSelectionProps {
  onChange: (name: string, value: PlantDropdownValue) => void;
  penPlants: PlantOptions[];
  premiumPlants: PlantOptions[];
  values: SpotFormValuesProps;
  disablePlants?: boolean;
  requiresPMB?: boolean;
  errors?: FormikErrors<SpotFormValuesProps>;
  touched?: FormikTouched<FormikValues>;
  shipmentType: string;
  onBlur: (name: string) => void;
  selectedGrades: string[];
}

const PlantDropdownOption = (props: OptionProps<{}>) => {
  return (
    <SelectComponents.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: ev => !props.isDisabled && props.innerProps.onClick(ev),
      }}
      isFocused={props.isDisabled ? false : props.isFocused}
    >
      <DropdownLabelRoot isDisabled={props.isDisabled}>
        <Typography color={props.isDisabled ? 'hintLight' : 'bodyLight'}>{props.data.label}</Typography>
        <Typography color={props.isDisabled ? 'hintLight' : 'bodyLight'}>{props.data.tag}</Typography>
      </DropdownLabelRoot>
    </SelectComponents.Option>
  );
};

interface PlantDropdownProps extends Pick<PlantsSelectionProps, 'onChange' | 'onBlur' | 'selectedGrades'> {
  plants: PlantOptions[];
  name: string;
  label: string;
  value: string;
  placeholder: string;
  error?: string;
  disabled?: boolean;
}

const PlantDropdown: React.FunctionComponent<PlantDropdownProps> = props => {
  const enabledPlants = props.plants.filter(plant => props.selectedGrades.every(value => plant.grades.includes(value)));
  const disabledPlants = props.plants
    .filter(plant => !props.selectedGrades.every(value => plant.grades.includes(value)))
    .map(plant => ({
      ...plant,
      tag: "Can't fulfill your grade split",
      disabled: true,
    }));

  return (
    <React.Fragment>
      <Label>{props.label}</Label>
      <SingleDropdown
        isClearable={true}
        error={props.error}
        name={props.name}
        selected={props.value}
        disabled={props.disabled}
        placeholder={props.placeholder}
        items={[...sortPlants(enabledPlants), ...sortPlants(disabledPlants)]}
        renderOption={renderProps => (
          <PlantDropdownOption {...renderProps} isDisabled={Boolean(renderProps.data.disabled)} />
        )}
        onItemClick={item => {
          if (!item.value) {
            props.onBlur(props.name);
            props.onChange(props.name, { plantId: '' });
          }
          const plant = props.plants.find(plant => plant.value === item.value);
          if (plant && props.selectedGrades.every(value => plant.grades.includes(value))) {
            props.onBlur(props.name);
            props.onChange(props.name, { plantId: plant.value });
          }
        }}
      />
    </React.Fragment>
  );
};

export { PlantDropdown };
