import React from 'react';
import moment, { Moment } from 'moment';
import { TableRow, TableColumn } from './TableElements';
import { isToday, prettyDate } from '../dates';

interface EmptyRowProps {
  date: string;
  region?: string;
}

export const EmptyRow: React.FunctionComponent<EmptyRowProps> = ({ date, region }) => {
  if (region === 'east') {
    return (
      <TableRow>
        <TableColumn isDefault={true} isToday={isToday(date)} stickyLeft={0}>
          {prettyDate(date)}
        </TableColumn>
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
        <TableColumn />
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableColumn isDefault={true} isToday={isToday(date)} stickyLeft={0}>
          {prettyDate(date)}
        </TableColumn>
        <TableColumn stickyLeft={100} />
        <TableColumn />
        <TableColumn />
        <TableColumn isDefault={true}>No event</TableColumn>
        <TableColumn />
        <TableColumn />
        <TableColumn />
      </TableRow>
    );
  }
};
