import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../Icons/CloseIcon';

const Root = styled.button<{ hasBorder?: boolean; currentUserTheme?: boolean }>`
  align-items: center;
  background: none;
  border-radius: ${({ hasBorder }) => (hasBorder ? '2px' : '0')};
  border: ${({ hasBorder, currentUserTheme, theme }) =>
    hasBorder
      ? currentUserTheme
        ? `1px solid ${theme.base.dark.a}`
        : `1px solid ${theme.secondary.light.c}`
      : 'none'};
  color: inherit;
  cursor: pointer;
  display: flex;
  height: ${({ hasBorder }) => (hasBorder ? '40px' : 'auto')};
  justify-content: center;
  padding: 0;
  width: ${({ hasBorder }) => (hasBorder ? '40px' : 'auto')};
`;

interface Props {
  hasBorder?: boolean;
  onClick?: () => void;
  currentUserTheme?: boolean;
}

const CloseButton: React.FunctionComponent<Props> = ({ hasBorder, onClick, currentUserTheme }) => (
  <Root hasBorder={hasBorder} onClick={onClick} currentUserTheme={currentUserTheme}>
    <CloseIcon currentUserTheme={currentUserTheme} />
  </Root>
);

export { CloseButton };
