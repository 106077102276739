import { GET_PRICING_ADJUSTMENT_DATA_AVAILABILITY, PricingAdjustmentDataAvailabilityQuery } from '@scout/types';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { Col, Row } from '../../components/Grid';
import { LabelValue } from '../../components/LabelValue';
import { Loader } from '../../components/Loader';
import { formatRoundedNumber } from '../../helpers';

const Root = styled.div`
  display: flex;
  flex: 40%;
`;

interface ClusterStatsProps {
  clusterId: string;
  clusterCurrency: string;
}

export const ClusterStats: React.FunctionComponent<ClusterStatsProps> = props => {
  const { loading, error, data } = useQuery<PricingAdjustmentDataAvailabilityQuery>(
    GET_PRICING_ADJUSTMENT_DATA_AVAILABILITY,
    {
      variables: { clusterId: props.clusterId },
    },
  );

  return (
    <Root>
      {!data || !data.pricingAdjustmentDataAvailability || loading || error ? (
        <Loader type="section" error={error && 'Something went wrong'} />
      ) : (
        <Row>
          <Col size={3}>
            <LabelValue label="Currency" value={props.clusterCurrency} />
          </Col>
          <Col size={3}>
            <LabelValue
              label="Exchange Rate"
              value={data.pricingAdjustmentDataAvailability.exchangeRateMonth || 'N/A'}
            />
          </Col>

          <Col size={3}>
            <LabelValue
              label="Spot Prices"
              value={data.pricingAdjustmentDataAvailability.pricingEnquiryMonth || 'N/A'}
            />
          </Col>

          <Col size={3}>
            <LabelValue
              label="Avg. FO"
              value={formatRoundedNumber(data.pricingAdjustmentDataAvailability.fuelOilQuote.avgPrice)}
              optionalText={`As of ${data.pricingAdjustmentDataAvailability.fuelOilQuote.date}`}
            />
          </Col>
        </Row>
      )}
    </Root>
  );
};
