import React from 'react';
import styled from 'styled-components';
import { TextInput, TextInputProps } from '../../../../components/TextInput';
import { VolumeTextInputIcon } from './VolumeTextInputIcon';

const Container = styled.div`
  position: relative;
`;

export const VolumeTextInput: React.FC<TextInputProps> = props => (
  <Container>
    <VolumeTextInputIcon disabled={props.disabled} value={props.value as string} />
    <TextInput suffix="t" {...props} />
  </Container>
);
