export const excelExtractFilter = [
  { value: 'Clear', label: 'Clear All' },
  { value: 'plannedLoadMonth', label: 'Planned Load Month' },
  { value: 'incoterm', label: 'Incoterm' },
  { value: 'loadPort', label: 'Load Port' },
  { value: 'loadPortLaycanStartDate', label: 'Loadport Start Laycan' },
  { value: 'loadPortLaycanEndDate', label: 'Loadport End Laycan' },
  { value: 'dischargePort', label: 'DisPort' },
  { value: 'country', label: 'Country' },
  { value: 'dischargePortLaycanStartDate', label: 'Disport Start Laycan' },
  { value: 'dischargePortLaycanEndDate', label: 'Disport End Laycan' },
  { value: 'BLDate', label: 'BL Date' },
  { value: 'coa', label: 'COA' },
  { value: 'buyerInfo', label: 'Buyer Info' },
  { value: 'purchaseContract', label: 'Purchase Contract' },
  { value: 'salesContract', label: 'Sales Contract' },
  { value: 'fobPriceInternal', label: 'FOB Price (Internal)' },
  { value: 'internalFreightPrice', label: 'Freight price (Internal)' },
  { value: 'fobPriceCustomer', label: 'FOB Price (Customer)' },
  { value: 'customerFreightActualPrice', label: 'Freight price (Customer)' },
  { value: 'planningNotes', label: 'Planning Notes' },
];
