import * as React from 'react';
import { PageSpinner } from './PageSpinner';
import { SectionSpinner } from './SectionSpinner';
import { Error } from './Error';

export interface LoaderProps {
  type?: 'page' | 'section';
  error?: string;
  text?: string;
}

const Loader = ({ type = 'page', error, text }: LoaderProps) => {
  if (error) {
    return <Error message={error} />;
  }

  return type === 'page' ? <PageSpinner /> : <SectionSpinner text={text} />;
};

export { Loader };
