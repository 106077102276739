import React from 'react';
import { withTheme } from 'styled-components';

export const InfoIcon = withTheme(({ theme }) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="26.5" stroke={theme.secondary.light.c} />
    <path
      d="M7.25805 19.9807C5.61212 24.228 5.252 29.0176 6.52125 33.7546C9.69978 45.617 21.8929 52.6567 33.7553 49.4782C38.9744 48.0797 43.2599 44.9363 46.1524 40.8438C49.8343 35.6345 51.259 28.8875 49.4789 22.2441C47.7799 15.9034 43.5053 10.9405 38.0824 8.17966C33.3599 5.7754 27.7665 5.04096 22.2449 6.5205C18.8396 7.43293 15.8318 9.08821 13.349 11.2653"
      stroke={theme.secondary.light.c}
      strokeLinecap="round"
    />
    <rect x="24.625" y="25.3008" width="6.075" height="16.2" rx="3.0375" fill={theme.greys.dark.disabled} />
    <rect x="24.625" y="16.5254" width="6.075" height="6.075" rx="3.0375" fill={theme.primary.b} />
  </svg>
));
