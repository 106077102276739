import React from 'react';
import { Marker } from 'react-mapbox-gl';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background: ${props => props.theme.secondary.light.c};
  box-shadow: 0px 0px 0px 5px rgba(40, 47, 57, 0.7);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir Next;
  font-size: 14px;
  color: ${props => props.theme.base.dark.d};
  padding-top: 1px;
  background-clip: padding-box;

  &:hover {
    background: ${props => props.theme.primary.b};
    color: ${props => props.theme.base.light.a};
  }
`;

export const ClusterMarker = (coordinates: number[], pointCount: number) => (
  <Marker key={`${coordinates[0]}-${coordinates[1]}`} coordinates={coordinates} anchor="center">
    <Container>
      <span>{pointCount}</span>
    </Container>
  </Marker>
);
