import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserThemeContext } from '../../App';
import { Tag } from '../Tag/Tag';
import { Typography } from '../Typography';

const TagWrapper = styled.div`
  align-items: baseline;
  display: flex;
`;

const SpacedTag = styled(Tag)`
  margin-left: 5px;
`;

interface Props {
  className?: string;
  tag: string;
}

const Tagged: React.FC<Props> = ({ children, className, tag }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  return (
    <TagWrapper className={className}>
      <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} inline={true} tag="span" variant="labels1">
        {children}
      </Typography>
      <SpacedTag color="highlight">{tag}</SpacedTag>
    </TagWrapper>
  );
};

export { Tagged };
