import React from 'react';
import styled from 'styled-components';
import { Total } from '../../../../../components/Total';
import { Typography } from '../../../../../components/Typography';
import { TextField } from '../../../SpotForm/TextField';
import { GradeSplitStepGrade } from '../types';
import { sumGradeSplit } from './validation';

const Root = styled.div`
  display: flex;
`;

const Panel = styled.div`
  background-color: ${({ theme }) => theme.base.light.c};
  display: flex;
  padding: 8px;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const Name = styled(Panel)`
  align-items: center;
  margin-right: auto;
  padding-left: 14px;
  width: 92px;
`;

const Grades = styled(Panel)`
  flex: 1;
  justify-content: flex-end;
`;

const GradeFieldContainer = styled.div`
  margin-right: 8px;
`;

const TotalContainer = styled.div`
  margin-left: 8px;
`;

interface Props {
  grades: GradeSplitStepGrade[];
  label: string;
  name: string;
}

// We don't want ellipsis to be used in this TextField because it actually hides a lot
// more of the name. So instead anything longer than 10 characters we'll just put a '.'
// at the end so the user will more likely know whatt it is.
const constrainGradeName = (name: string): string => (name.length > 10 ? `${name.slice(0, 9)}.` : name);

const GradeSplitGradeType = ({ grades, label, name }: Props) => (
  <Root>
    <Name>
      <Typography bold={true} variant="body4">
        {label}
      </Typography>
    </Name>
    <Grades>
      {grades.map((grade, index) => (
        <GradeFieldContainer key={grade.gradeId}>
          <TextField
            alignVertically={true}
            inputWidth="65px"
            label={constrainGradeName(grade.gradeName)}
            max={100}
            min={0}
            name={`${name}.${index}.percentage`}
            showErrorMessage={false}
          />
        </GradeFieldContainer>
      ))}
      <TotalContainer>
        <Total rangeTargetMax={100} rangeTargetMin={0} targetType="Range" total={sumGradeSplit(grades)} unit="%" />
      </TotalContainer>
    </Grades>
  </Root>
);

export { GradeSplitGradeType };
