import * as React from 'react';
import styled from 'styled-components';
import { InputBase, InputLightMixin, InputMixin, InputProps } from '../InputBase/InputBase';

type TextAreaProps = {
  error?: string;
  variant?: string;
  themeStyle?: string;
  showErrorMessage?: boolean;
  maxLength?: number;
  rows?: number;
} & InputProps;

const StyledTextArea = styled.textarea<TextAreaProps>`
  ${props => (props.themeStyle && props.themeStyle === 'light' ? InputLightMixin : InputMixin)}
  border-radius: 2px;
`;

export const TextArea: React.FunctionComponent<TextAreaProps> = props => (
  <InputBase error={props.error} label={props.label} showErrorMessage={props.showErrorMessage}>
    <StyledTextArea variant="body3" rows={props.rows ? props.rows : 6} {...props} />
  </InputBase>
);
