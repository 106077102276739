import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { useMutation } from 'react-apollo';
import { Field, FieldProps, getIn, FastField, Formik, Form } from 'formik';
import { TextInput } from '../../../../../components/TextInput';

import { Calendar } from '../../../../../components/Calendar';
import { Checkbox } from '../../../../../components/Checkbox';
import { Col, Row, Grid } from '../../../../../components/Grid';
import { SingleDropdown } from '../../../../../components/SelectDropdown/Single';

import { getEastGradeItems } from '../../utils';

import { useToast } from '../../../../../components/Toast/Toast';
import {
  CREATE_PRODUCTION_OR_SALE,
  CreateProductionOrSaleMutation,
  CreateProductionOrSaleMutationVariables,
  GradeModelFragmentFragment,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  UpdateScheduleManagerByIdMutation,
  UpdateScheduleManagerByIdMutationVariables,
  UPDATE_SCHEDULEMANAGER_BY_ID,
} from '@scout/types';
import { isInclusivelyBeforeDay } from 'react-dates';
import { Required, RequiredText, ProductionGradeContainer } from './EastMovementFormsHelper';
import {
  checkEventPresent,
  CopyContainer,
  InputContainer,
  InputContainerDays,
  Label,
  MovementValidation,
  NewButton,
  PlanningContainer,
  RefineryProductionSalesTabValues,
  Root,
  Spaceinbetween,
  StyledTextArea,
  EventValidationErrorMessage,
  validationSchema,
  today,
  InsideCalendar,
} from './EastProductionSalesMovementHelper';
import { getModifiedValue } from './EastMovementsHelper';

interface EastRefineryProductionContainerProps {
  grades: GradeModelFragmentFragment[];
  cancelModal: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  currentLocationName: string;
  selectedMovement: SchedulingManagerType | null;
  // tslint:disable-next-line
  productionSelectedValues: any;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

const VolumeStyleTextBox = styled.div`
  width: 75px;
`;

const DateContainer = styled.div`
  margin-top: 10px;
  margin-buttom: 10px;
`;

const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  float: right
  margin-right:30px
`;
const ProductionRequiredTextContainer = styled.div`
  margin: -47px;
  margin-left: 10px;
`;

const MovementWarningValidation = styled.div`
  position: absolute;
  width: 679px;
  height: 45px;
  left: 35px;
  top: 165px;
  background: #ff940033;
  border: 1px solid #ffb44c;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  padding: 9px;
`;
today.setHours(0, 0, 0, 0);

export const EastRefineryProductionContainer: React.FunctionComponent<EastRefineryProductionContainerProps> = ({
  grades,
  cancelModal,
  currentLocationName,
  SMdata,
  selectedMovement,
  productionSelectedValues,
  currentUserTheme,
  onClickSimulationEvent,
  dataFetch,
}) => {
  const [plannedStartDate, setStartDate] = useState<Moment | null>();
  const [plannedEndDate, setEndDate] = useState<Moment | null>();
  const [isChecked, setisChecked] = useState(productionSelectedValues ? productionSelectedValues.skipWeekends : false);
  const [buttonActive, setButtonActive] = useState(true);
  const [isEventPresent, setisEventPresent] = useState(false);
  const [isNegativeVolumeAdded, setIsNegativeVolumeAdded] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);
  const { push } = useToast();
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [createProductionOrSale, setCreateProductionOrSalet] = useMutation<
    CreateProductionOrSaleMutation,
    CreateProductionOrSaleMutationVariables
  >(CREATE_PRODUCTION_OR_SALE, {
    context: {
      headers,
    },
  });
  const [updateProductionOrSale, setUpdateProductionOrSale] = useMutation<
    UpdateScheduleManagerByIdMutation,
    UpdateScheduleManagerByIdMutationVariables
  >(UPDATE_SCHEDULEMANAGER_BY_ID, {
    context: {
      headers,
    },
  });

  const defaultValues = {
    port: currentLocationName,
    productType: 'Production',
    grade: 'Bitumen',
    plannedStartDate: '',
    plannedVolume: 0,
    plannedEndDate: '',
    planningNotes: '',
    skipWeekends: false,
  };

  // Detecting click outside component
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleClickOutside = (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (!myRef.current.contains(target)) {
      setClickedOutside(true);
    }
  };
  const handleClickInside = () => setClickedOutside(false);
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  });

  const handleOnSubmit = async (values: RefineryProductionSalesTabValues) => {
    if (selectedMovement !== null) {
      const input = {
        id: selectedMovement.id,
        ...values,
        productType: selectedMovement.productType,
      };
      try {
        await updateProductionOrSale({
          variables: {
            input,
          },
        });
        push({ type: 'SUCCESS', text: 'Movement Updated successfully' });
        setDisabledSaveButton(false);
        cancelModal();
        onClickSimulationEvent(!dataFetch);
      } catch (error) {
        // console.error('Failed  Lifting ', error);
        push({ type: 'ERROR', text: 'Movement Update Failed' });
      }
    } else {
      const momentOfPlannedStartDate = moment(values.plannedStartDate);
      const momentOfPlannedEndDate = moment(values.plannedEndDate);
      const prodCreationOnWeekend =
        values.skipWeekends === true &&
        (momentOfPlannedStartDate.isoWeekday() === 6 || momentOfPlannedStartDate.isoWeekday() === 7) &&
        (momentOfPlannedEndDate.isoWeekday() === 6 || momentOfPlannedEndDate.isoWeekday() === 7) &&
        (momentOfPlannedEndDate.diff(momentOfPlannedStartDate, 'days') === 1 ||
          momentOfPlannedEndDate.diff(momentOfPlannedStartDate, 'days') === 0)
          ? true
          : false;

      if (prodCreationOnWeekend === true) {
        push({ type: 'ERROR', text: 'Skip Weekends is checked.You cannot create movements on weekends...' });
        cancelModal();
      } else {
        try {
          await createProductionOrSale({
            variables: {
              input: values,
            },
          });
          push({ type: 'SUCCESS', text: 'Refinery Production created successfully' });
          setDisabledSaveButton(false);
          cancelModal();
          onClickSimulationEvent(!dataFetch);
        } catch (error) {
          push({ type: 'ERROR', text: 'Refinery Production Failed' });
        }
      }
    }
  };

  const NEGATIVE_VOLUME_WARNING = 'You have entered a Negative value as planned volume ';
  const RegexIsNegative = /^-\d*\.?\d+$/;

  return (
    <>
      <Formik
        initialValues={selectedMovement ? productionSelectedValues : defaultValues}
        validationSchema={validationSchema}
        validate={values => {
          const allRequiredFields =
            values.grade && values.plannedVolume && values.plannedStartDate && values.plannedEndDate;
          if (allRequiredFields) {
            setButtonActive(false);
          } else {
            setButtonActive(true);
          }
        }}
        onSubmit={async (values, formActions) => {
          const changedValue = getModifiedValue(values, productionSelectedValues);
          const eventvalidation = await checkEventPresent(values, SMdata, setisEventPresent);
          if (!eventvalidation) {
            if (values.plannedStartDate < moment().format('YYYY-MM-DD') && !selectedMovement) {
              if (confirm('Are you sure to create movement for past date?')) {
                setDisabledSaveButton(true);
                await handleOnSubmit(values);
                formActions.setSubmitting(false);
              } else {
                setButtonActive(false);
              }
            } else {
              setDisabledSaveButton(true);
              await handleOnSubmit(values);
              formActions.setSubmitting(false);
            }
          }
        }}
        render={formProps => {
          {
            const isSubmitted = formProps.submitCount > 0 ? true : false;

            return (
              <>
                <Form>
                  {isEventPresent && <MovementValidation>{EventValidationErrorMessage}</MovementValidation>}

                  {isNegativeVolumeAdded && (
                    <MovementWarningValidation>{NEGATIVE_VOLUME_WARNING}</MovementWarningValidation>
                  )}
                  <Root currentUserTheme={currentUserTheme}>
                    <InsideCalendar ref={myRef} onClick={handleClickInside}>
                      <Grid>
                        <Row>
                          <Col size={6}>
                            <ProductionGradeContainer>
                              <Row>
                                <InputContainer>
                                  <Label currentUserTheme={currentUserTheme}>
                                    Grade<Required>*</Required>
                                  </Label>
                                  <Field name="grade">
                                    {({
                                      field,
                                      form: { errors, setFieldValue },
                                    }: FieldProps<RefineryProductionSalesTabValues>) => (
                                      <SingleDropdown
                                        darkTheme={currentUserTheme ? false : true}
                                        width="200px"
                                        error={getIn(errors, field.name)}
                                        items={getEastGradeItems(grades)}
                                        name={field.name}
                                        onItemClick={item => {
                                          setFieldValue('grade', item.label);
                                        }}
                                        selected={field.value}
                                        showClearIndicator={false}
                                        isClearable={false}
                                        placeholder="Bitumen"
                                      />
                                    )}
                                  </Field>
                                </InputContainer>
                                <InputContainer>
                                  <Label currentUserTheme={currentUserTheme}>
                                    Volume (Kt)<Required>*</Required>
                                  </Label>
                                  <Field name="plannedVolume">
                                    {({
                                      field,
                                      form: { errors, touched, setFieldValue },
                                    }: FieldProps<RefineryProductionSalesTabValues>) => (
                                      <VolumeStyleTextBox>
                                        <TextInput
                                          type="number"
                                          align="right"
                                          error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                          name={field.name}
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const { value } = e.target;

                                            if (RegexIsNegative.test(value.toString())) {
                                              setIsNegativeVolumeAdded(true);
                                            } else {
                                              setIsNegativeVolumeAdded(false);
                                            }
                                            const newValue = e.target.value;
                                            if (newValue == null) {
                                              return;
                                            }
                                            field.onChange(e);
                                          }}
                                          placeholder="0"
                                          showErrorIcon={false}
                                          showErrorMessage={true}
                                          themeStyle={currentUserTheme ? 'light' : 'dark'}
                                          value={field.value != null ? field.value : ''}
                                        />
                                      </VolumeStyleTextBox>
                                    )}
                                  </Field>
                                </InputContainer>
                              </Row>
                            </ProductionGradeContainer>
                            <Row>
                              <PlanningContainer>
                                <InputContainer>
                                  <Label currentUserTheme={currentUserTheme}>Planning Note</Label>
                                  <FastField
                                    name="planningNotes"
                                    render={({ field }: FieldProps<RefineryProductionSalesTabValues>) => (
                                      <StyledTextArea
                                        {...field}
                                        maxLength={500}
                                        tabIndex={1000}
                                        data-testid="planning-notes"
                                        themeStyle={currentUserTheme ? 'light' : 'dark'}
                                        placeholder="Please leave your thoughts…"
                                        rows={1}
                                      />
                                    )}
                                  />
                                </InputContainer>
                              </PlanningContainer>
                            </Row>
                            <Row>
                              <ProductionRequiredTextContainer>
                                <RequiredText>* Required Fields</RequiredText>
                              </ProductionRequiredTextContainer>
                            </Row>
                          </Col>
                          <Spaceinbetween />

                          <Col size={6}>
                            <DateContainer>
                              <Row>
                                <InputContainer>
                                  <Label currentUserTheme={currentUserTheme}>
                                    Planned Start Date<Required>*</Required>
                                  </Label>
                                  <Field name="plannedStartDate">
                                    {({
                                      field,
                                      form: { errors, setFieldTouched, setFieldValue },
                                    }: FieldProps<RefineryProductionSalesTabValues>) => (
                                      <Calendar
                                        closeCalendar={clickedOutside}
                                        disabled={selectedMovement ? true : false}
                                        name={`plannedStartDate`}
                                        error={isSubmitted ? getIn(errors, 'plannedStartDate') : undefined}
                                        placeholder="Select Date"
                                        width="250px"
                                        align="left"
                                        value={
                                          field.value
                                            ? moment(field.value)
                                            : plannedStartDate
                                            ? moment(plannedStartDate, 'YYYY/MM/DD')
                                            : null
                                        }
                                        onChange={date => {
                                          setFieldValue(`plannedStartDate`, date.format());
                                          setFieldTouched(`plannedStartDate`, true);
                                          setStartDate(date);
                                        }}
                                        themeStyle={currentUserTheme ? 'light' : 'dark'}
                                        showErrorMessage={isSubmitted ? true : false}
                                        isOutsideRange={(day: Moment) => {
                                          if (plannedEndDate !== undefined) {
                                            return (
                                              !isInclusivelyBeforeDay(
                                                day,
                                                moment(plannedEndDate ? plannedEndDate : moment(), 'YYYY/MM/DD'),
                                              ) ||
                                              day.isBefore(
                                                moment()
                                                  .subtract(8, 'months')
                                                  .format('YYYY/MM/DD'),
                                              )
                                            );
                                          } else {
                                            return (
                                              day.isBefore(
                                                moment()
                                                  .subtract(8, 'months')
                                                  .format('YYYY/MM/DD'),
                                              ) ||
                                              day.isAfter(
                                                moment()
                                                  .add(10, 'months')
                                                  .format('YYYY/MM/DD'),
                                              )
                                            );
                                          }
                                        }}
                                        region="east"
                                        currentUserTheme={currentUserTheme}
                                      />
                                    )}
                                  </Field>
                                </InputContainer>
                              </Row>
                            </DateContainer>
                            <CopyContainer>
                              <Row>
                                <InputContainerDays>
                                  <Label currentUserTheme={currentUserTheme}>
                                    Planned End Date<Required>*</Required>
                                  </Label>
                                  <Field name="plannedEndDate">
                                    {({
                                      field,
                                      form: { errors, setFieldTouched, setFieldValue },
                                    }: FieldProps<RefineryProductionSalesTabValues>) => (
                                      <Calendar
                                        closeCalendar={clickedOutside}
                                        disabled={selectedMovement ? true : false}
                                        name={`plannedEndDate`}
                                        error={isSubmitted ? getIn(errors, 'plannedEndDate') : undefined}
                                        placeholder="Select Date"
                                        width="250px"
                                        align="left"
                                        value={
                                          field.value
                                            ? moment(field.value)
                                            : plannedEndDate
                                            ? moment(plannedEndDate, 'YYYY/MM/DD')
                                            : null
                                        }
                                        onChange={date => {
                                          setFieldValue(`plannedEndDate`, date.format());
                                          setFieldTouched(`plannedEndDate`, true);
                                          setEndDate(date);
                                        }}
                                        themeStyle={currentUserTheme ? 'light' : 'dark'}
                                        showErrorMessage={isSubmitted ? true : false}
                                        isOutsideRange={(day: Moment) => {
                                          if (plannedStartDate !== undefined) {
                                            return (
                                              day.isBefore(
                                                moment(
                                                  moment(plannedStartDate ? plannedStartDate : moment(), 'YYYY/MM/DD'),
                                                ).startOf('day'),
                                              ) ||
                                              day.isAfter(
                                                moment()
                                                  .add(10, 'months')
                                                  .format('YYYY/MM/DD'),
                                              )
                                            );
                                          } else {
                                            return (
                                              day.isBefore(
                                                moment()
                                                  .subtract(8, 'months')
                                                  .format('YYYY/MM/DD'),
                                              ) ||
                                              day.isAfter(
                                                moment()
                                                  .add(10, 'months')
                                                  .format('YYYY/MM/DD'),
                                              )
                                            );
                                          }
                                        }}
                                        region="east"
                                        currentUserTheme={currentUserTheme}
                                      />
                                    )}
                                  </Field>
                                </InputContainerDays>
                              </Row>
                            </CopyContainer>

                            <Row>
                              <Field name="skipWeekends">
                                {({ form: { setFieldValue } }: FieldProps<RefineryProductionSalesTabValues>) => (
                                  <Checkbox
                                    disabled={selectedMovement ? true : false}
                                    isEast={true}
                                    name={`skipWeekends`}
                                    label="Skip Weekends"
                                    checked={isChecked}
                                    onChange={checkedValue => {
                                      setisChecked(Boolean(checkedValue));
                                      setFieldValue(`skipWeekends`, Boolean(checkedValue));
                                    }}
                                    onBlur={checkedValue => {
                                      setFieldValue(`skipWeekends`, Boolean(checkedValue));
                                    }}
                                    currentUserTheme={currentUserTheme}
                                  />
                                )}
                              </Field>
                            </Row>
                            <BtnStyledRow>
                              <Row>
                                <NewButton
                                  variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                                  onClick={async () => {
                                    cancelModal();
                                  }}
                                >
                                  Cancel
                                </NewButton>

                                <NewButton
                                  variant="primary"
                                  type="submit"
                                  disabled={disabledSaveButton ? true : buttonActive}
                                  currentUserTheme={currentUserTheme}
                                >
                                  Save
                                </NewButton>
                              </Row>
                            </BtnStyledRow>
                          </Col>
                        </Row>
                      </Grid>
                    </InsideCalendar>
                  </Root>
                </Form>
              </>
            );
          }
        }}
      />
    </>
  );
};
