import { DemandLocation, Grade, GradeType, Plant } from '@scout/types';
import { ContractDemandState, Demand, DemandGradeSplit } from '../../types';
import { GradeSplitStepDemandRow, GradeSplitStepGrade, GradeSplitStepValues } from '../types';

interface CreateValueRowFromDemandInput {
  demand: Demand;
  demandLocation: DemandLocation;
  plants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>;
}

/**
 * Merges the grades that may exist in the ContractDemand already (in case we are
 * editing) and ones available from the plant. It's possible that a grade may have
 * been available at the time the ContractDemand was created but isn't available
 * now. However, we still want to show the grade in case the user selected it before.
 */
const mergeGrades = (demandGrades: DemandGradeSplit[], plantGrades: Grade[]): GradeSplitStepGrade[] => {
  // Take the demand grades as a base
  const convertedDemandGrades = demandGrades.map<GradeSplitStepGrade>(dg => ({
    id: dg.id,
    gradeId: dg.grade.id,
    gradeName: dg.grade.name,
    percentage: dg.percentage != null ? String(dg.percentage) : '',
  }));

  // Find any extra grades now available
  const extraPlantGrades = plantGrades
    .filter(pg => convertedDemandGrades.find(cdg => cdg.gradeId === pg.id) == null)
    .map<GradeSplitStepGrade>(pg => ({
      id: pg.id,
      gradeId: pg.id,
      gradeName: pg.name,
      percentage: '',
    }));

  // Merge and do another sort so everything is in name order again
  return [...convertedDemandGrades, ...extraPlantGrades].sort((a, b) => a.gradeName.localeCompare(b.gradeName));
};

const createValueRowFromDemand = ({
  demand,
  demandLocation,
  plants,
}: CreateValueRowFromDemandInput): GradeSplitStepDemandRow => {
  // The plant selected in Step 1 (during create) or already saved (edit)
  const plant = plants.find(p => p.id === demand.plant.id)!;

  // Any grades used in the demand already (these will be empty during the very first create
  // but contain values once the user leaves Step 2 and comes back again or does an edit)
  const demandHardGrades = demand.gradeSplit.filter(g => g.grade.type === GradeType.Hard);
  const demandSoftGrades = demand.gradeSplit.filter(g => g.grade.type === GradeType.Soft);
  const demandPremiumGrades = demand.gradeSplit.filter(g => g.grade.type === GradeType.Premium);
  const demandNonBitumenGrades = demand.gradeSplit.filter(g => g.grade.type === GradeType.NonBitumen);

  // All grades that exist for the plant selected in Step 1
  const plantHardGrades = plant.grades.filter(g => g.type === GradeType.Hard);
  const plantSoftGrades = plant.grades.filter(g => g.type === GradeType.Soft);
  const plantPremiumGrades = plant.grades.filter(g => g.type === GradeType.Premium);
  const plantNonBitumenGrades = plant.grades.filter(g => g.type === GradeType.NonBitumen);

  return {
    id: demand.id,
    locationName: (demandLocation === DemandLocation.Region ? demand.region?.name : demand.shipTo?.name) ?? '',
    plantName: demand.plant.shortName,
    hardGrades: mergeGrades(demandHardGrades, plantHardGrades),
    premiumGrades: mergeGrades(demandPremiumGrades, plantPremiumGrades),
    softGrades: mergeGrades(demandSoftGrades, plantSoftGrades),
    nonBitumenGrades: mergeGrades(demandNonBitumenGrades, plantNonBitumenGrades),
  };
};

/**
 * Turn the overall state into something we can use more easily
 * during Step 2.
 */
export const createValues = (
  state: ContractDemandState,
  penPlants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>,
  premiumPlants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>,
): GradeSplitStepValues => {
  const penDemands = state.demands.filter(d => !d.isPremium);
  const premiumDemands = state.demands.filter(d => d.isPremium);

  return {
    penRows: penDemands.map(d =>
      createValueRowFromDemand({
        demand: d,
        demandLocation: state.demandLocation,
        plants: penPlants,
      }),
    ),
    premiumRows: premiumDemands.map(d =>
      createValueRowFromDemand({
        demand: d,
        demandLocation: state.demandLocation,
        plants: premiumPlants,
      }),
    ),
  };
};
