import moment, { Moment } from 'moment';
import React, { useState, useEffect, useRef, DependencyList } from 'react';
import {
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  LocationsQuery,
  UPDATERECORDBYDATE,
  UpdateRecordByDateMutationVariables,
  UpdateRecordByDateMutation,
} from '@scout/types';
import { Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { EastSchedulingManager } from './Table/EastSchedulingManager/EastSchedulingManager';
import { useMutation } from 'react-apollo';
import { useToast } from '../../../../components/Toast/Toast';
import styled from 'styled-components';
import {
  getRefineryMovementsOnDate,
  getThirdpartyRefineryMovementsOnDate,
  getDepotMovementsOnDate,
  getThirdpartyCustomerMovementsOnDate,
  getEventsOndate,
} from './EastTableBodyHelper';
import { theme } from '../../../../theme';

const StyleForDroppable = styled.div`
  width: '160px';
`;
const selectedDragLoadPortStartDates: string[] = [];
const selectedDragDischargePortStartDates: string[] = [];
const selectedTaskIds: string[] = [];
const selectedTasksBLDate: string[] = [];
const cloneIds: string[] = [];
const loadPorts: string[] = [];
const dischargePorts: string[] = [];
const previousValues: Array<{
  dragLoadportstartdate: string;
  dragDischargePortStartDate: string;
  id: string;
  cloneId: string;
  currentPort: string;
}> = [];
let actualValues: SchedulingManagerType[] = [];
const color = true;
// FILTERfunction to get ALl event related to the port
export const EastTableBody: React.FunctionComponent<{
  isThirdParty?: boolean;
  dates: string[];
  currentlocationtype: string;
  data: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] | undefined;
  currentPort: string;
  loading: boolean;
  onEditMovementModal: (selectedMovementItem: SchedulingManagerType) => void;
  locations: LocationsQuery['locations'];
  onDeleteMovementModal: (selectedMovementItem: SchedulingManagerType) => void;
  onDragAndDropLoaderModal: (loadingValue: boolean) => void;
  movementMultipleDeletion: boolean;
  onUndoModalClick: (
    actualValues: Array<{
      dragLoadportstartdate: string;
      dragDischargePortStartDate: string;
      id: string;
      cloneId: string;
      currentPort: string;
    }>,
  ) => void;
  disableUndoButton: (value: boolean) => void;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}> = ({
  isThirdParty,
  dates,
  currentPort,
  currentlocationtype,
  data,
  loading,
  onEditMovementModal,
  locations,
  onDeleteMovementModal,
  onDragAndDropLoaderModal,
  movementMultipleDeletion,
  onUndoModalClick,
  disableUndoButton,
  currentUserTheme,
  onClickSimulationEvent,
  dataFetch,
}) => {
  const { push } = useToast();
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [updateRecordByDate, setUpdateRecordByDate] = useMutation<
    UpdateRecordByDateMutation,
    UpdateRecordByDateMutationVariables
  >(UPDATERECORDBYDATE, {
    context: {
      headers,
    },
  });
  let flagForDragAndDropLoader: boolean;
  const inputArray: Array<{ dragLoadportstartdate: string; dragDischargePortStartDate: string; id: string }> = [];
  const SINGLELOADERTIMECONSTANT = 3000;
  const MULTILOADERTIMECONSTANT = 4000;
  const [selected, setSelected] = useState(true);

  const undoHandler = () => {
    if (previousValues.length > 0) {
      disableUndoButton(false);
      onUndoModalClick(previousValues);
    } else {
      actualValues.length = 0;
      disableUndoButton(true);
    }
  };
  const updateNewDragDates = (
    newLoadPortLaycanStartDate: string,
    newDischargePortLaycanStartDate: string,
    draggingMovementId: string,
  ) => {
    const inputObject = {
      dragLoadportstartdate: newLoadPortLaycanStartDate,
      dragDischargePortStartDate: newDischargePortLaycanStartDate,
      id: draggingMovementId,
    };
    inputArray.push(inputObject);
  };

  const updatePreviousDragDates = (
    previousLoadPortLaycanStartDate: string,
    previousDischargePortLaycanStartDate: string,
    movementId: string,
    cloneId: string,
  ) => {
    const input = {
      dragLoadportstartdate: previousLoadPortLaycanStartDate,
      dragDischargePortStartDate: previousDischargePortLaycanStartDate,
      id: movementId,
      cloneId,
      currentPort,
    };
    previousValues.push(input);
  };

  const stopLoader = (timeInMilliSeconds: number) => {
    if (flagForDragAndDropLoader === true) {
      setTimeout(() => {
        onDragAndDropLoaderModal(false);
        undoHandler();
      }, timeInMilliSeconds);
    } else {
      onDragAndDropLoaderModal(true);
      push({ type: 'ERROR', text: 'Loading...' });
      setTimeout(() => {
        onDragAndDropLoaderModal(false);
        undoHandler();
      }, timeInMilliSeconds);
      return;
    }
  };

  const singleDragAndDrop = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const draggingRowDate: string = dates[Number(source.droppableId)];
    const droppingRowDate: string = dates[Number(destination.droppableId)];
    let dragMomentValueInRefinery: Moment | undefined;
    let dragMomentValueInDepot;
    let dragDateMomentValue: Moment | undefined;
    let dropDateMomentValue;
    let diffTimeBtwnDragAndDropDates;
    let diffDaysBtwnDragAndDropDates;
    let dragDateValue: string;
    let dropDateValue: string;
    let newDischargePortLaycanStartDate;
    let newLoadPortLaycanStartDate;
    let draggingMovementDragDischargePortLaycanStartDate: string;
    let draggingMovementDragLoadPortLaycanStartDate: string;
    let draggingMovementId: string;
    let draggingMovementBLDate;
    let movementsInRefineryOrDepot: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] = [];

    if (data) {
      if (currentlocationtype === 'refinery') {
        movementsInRefineryOrDepot = getRefineryMovementsOnDate(data, draggingRowDate, currentPort);
      } else if (currentlocationtype === 'ThirdPartyRef') {
        movementsInRefineryOrDepot = getThirdpartyRefineryMovementsOnDate(data, draggingRowDate, currentPort);
      } else if (currentlocationtype === 'depot') {
        movementsInRefineryOrDepot = getDepotMovementsOnDate(data, draggingRowDate, currentPort);
      } else if (currentlocationtype === 'ThirdPartyCus') {
        movementsInRefineryOrDepot = getThirdpartyCustomerMovementsOnDate(data, draggingRowDate, currentPort);
      }
    }
    movementsInRefineryOrDepot.forEach(movement => {
      if (movement.id === result.draggableId) {
        draggingMovementDragLoadPortLaycanStartDate = movement.dragLoadportstartdate!;
        draggingMovementDragDischargePortLaycanStartDate = movement.dragDischargePortStartDate!;
        draggingMovementId = movement.id!;
        draggingMovementBLDate = movement.BLDate;
        previousValues.length = 0;
        actualValues.length = 0;
        actualValues.push(movement);
        updatePreviousDragDates(
          draggingMovementDragLoadPortLaycanStartDate,
          draggingMovementDragDischargePortLaycanStartDate,
          draggingMovementId,
          movement.movementCloneId!,
        );
      }
    });

    dragMomentValueInRefinery = moment(draggingMovementDragLoadPortLaycanStartDate!, 'YYYY/MM/DD');
    dragMomentValueInDepot = moment(draggingMovementDragDischargePortLaycanStartDate!, 'YYYY/MM/DD');
    dropDateMomentValue = moment(droppingRowDate, 'YYYY/MM/DD');

    if (currentlocationtype === 'refinery' || currentlocationtype === 'ThirdPartyRef') {
      dragDateValue = dragMomentValueInRefinery.format('YYYY-MM-DD');
      dragDateMomentValue = dragMomentValueInRefinery;
    } else if (currentlocationtype === 'depot' || currentlocationtype === 'ThirdPartyCus') {
      dragDateValue = dragMomentValueInDepot.format('YYYY-MM-DD');
      dragDateMomentValue = dragMomentValueInDepot;
    }

    diffTimeBtwnDragAndDropDates = Math.abs(+dragDateMomentValue! - +dropDateMomentValue);

    diffDaysBtwnDragAndDropDates = diffTimeBtwnDragAndDropDates / (1000 * 60 * 60 * 24);
    dropDateValue = dropDateMomentValue.format('YYYY-MM-DD');

    if (dragDateMomentValue! > dropDateMomentValue) {
      newDischargePortLaycanStartDate = moment(
        draggingMovementDragDischargePortLaycanStartDate!,
        'YYYY/MM/DD',
      ).subtract(diffDaysBtwnDragAndDropDates, 'days');
      newLoadPortLaycanStartDate = moment(draggingMovementDragLoadPortLaycanStartDate!, 'YYYY/MM/DD').subtract(
        diffDaysBtwnDragAndDropDates,
        'days',
      );
    } else {
      newDischargePortLaycanStartDate = moment(draggingMovementDragDischargePortLaycanStartDate!, 'YYYY/MM/DD').add(
        diffDaysBtwnDragAndDropDates,
        'days',
      );
      newLoadPortLaycanStartDate = moment(draggingMovementDragLoadPortLaycanStartDate!, 'YYYY/MM/DD').add(
        diffDaysBtwnDragAndDropDates,
        'days',
      );
    }
    if (
      currentlocationtype === 'refinery' ||
      currentlocationtype === 'ThirdPartyRef' ||
      ((currentlocationtype === 'depot' || currentlocationtype === 'ThirdPartyCus') && draggingMovementBLDate === null)
    ) {
      updateNewDragDates(
        newLoadPortLaycanStartDate.format('YYYY-MM-DD'),
        newDischargePortLaycanStartDate.format('YYYY-MM-DD'),
        draggingMovementId!,
      );
    } else if (
      (currentlocationtype === 'depot' || currentlocationtype === 'ThirdPartyCus') &&
      draggingMovementBLDate !== null
    ) {
      updateNewDragDates(
        draggingMovementDragLoadPortLaycanStartDate!,
        newDischargePortLaycanStartDate.format('YYYY-MM-DD'),
        draggingMovementId!,
      );
    }

    onDragAndDropLoaderModal(true);
    if (!dragDateValue!.localeCompare(dropDateValue!)) {
      push({ type: 'ERROR', text: 'You have dragged and dropped into same date' });
      previousValues.length = 0;
      actualValues.length = 0;
      draggingMovementId = '';
      flagForDragAndDropLoader = true;
      stopLoader(SINGLELOADERTIMECONSTANT);
      return;
    }

    try {
      await updateRecordByDate({
        variables: {
          input: inputArray,
        },
      });
      onClickSimulationEvent(!dataFetch);
      flagForDragAndDropLoader = true;
    } catch (error) {
      console.error('Failed to fetch records for Lifting ', error);
      push({ type: 'ERROR', text: 'Movement updation failed' });
      draggingMovementId = '';
      flagForDragAndDropLoader = true;
      previousValues.length = 0;
      actualValues.length = 0;
      stopLoader(SINGLELOADERTIMECONSTANT);
      return;
    }
    stopLoader(SINGLELOADERTIMECONSTANT);
  };

  const multiDragAndDrop = async (
    selectedTaskIdList: string[],
    selectedDragLoadPortStartDates: string[],
    selectedDragDischargePortStartDates: string[],
    selectedTasksBLDate: string[],
    result: DropResult,
  ) => {
    const { source, destination } = result;
    let i;
    const destDateColumn: string = dates[Number(destination?.droppableId)];
    const DestinationDate = moment(destDateColumn, 'YYYY/MM/DD');
    const refineryMoments: Moment[] = [];
    const depotMoments: Moment[] = [];
    let smallestOfSelectedDates;
    let selectedMovementDate: string | undefined;
    let selectedMovementMomentValue;
    let smallestDateMomentValue: Moment | undefined;
    let diffTimeBtwnSmallestDateAndSelectedDate;
    let diffDaysBtwnSmallestDateAndSelectedDate;
    let newLoadOrDischargeDateValue;
    let diffTimeBtwnLoadOrDischargePortDates;
    let diffDaysBtwnLoadOrDischargePortDates;
    let selectedLoadOrDischargePortStartDates: string[] = [];
    const newLoadOrDischargePortStartDates: string[] = [];
    let newLoadOrDischargePortValue;

    if (currentlocationtype === 'refinery' || currentlocationtype === 'ThirdPartyRef') {
      selectedLoadOrDischargePortStartDates = selectedDragLoadPortStartDates;
      selectedLoadOrDischargePortStartDates.map(dateValue => refineryMoments.push(moment(dateValue)));
      smallestOfSelectedDates = moment.min(refineryMoments).format('YYYY/MM/DD');
      smallestDateMomentValue = moment(smallestOfSelectedDates, 'YYYY/MM/DD');
    }
    if (currentlocationtype === 'depot' || currentlocationtype === 'ThirdPartyCus') {
      selectedLoadOrDischargePortStartDates = selectedDragDischargePortStartDates;
      selectedLoadOrDischargePortStartDates.map(dateValue => depotMoments.push(moment(dateValue)));
      smallestOfSelectedDates = moment.min(depotMoments).format('YYYY/MM/DD');
      smallestDateMomentValue = moment(smallestOfSelectedDates, 'YYYY/MM/DD');
    }
    previousValues.length = 0;
    for (i = 0; i < selectedLoadOrDischargePortStartDates!.length; i++) {
      updatePreviousDragDates(
        selectedDragLoadPortStartDates[i],
        selectedDragDischargePortStartDates[i],
        selectedTaskIdList[i],
        cloneIds[i],
      );
      selectedMovementDate = selectedLoadOrDischargePortStartDates[i]!;
      selectedMovementMomentValue = moment(selectedMovementDate, 'YYYY/MM/DD');

      diffTimeBtwnSmallestDateAndSelectedDate = Math.abs(+smallestDateMomentValue! - +selectedMovementMomentValue);
      diffDaysBtwnSmallestDateAndSelectedDate = diffTimeBtwnSmallestDateAndSelectedDate / (1000 * 60 * 60 * 24);

      newLoadOrDischargeDateValue = moment(DestinationDate, 'YYYY/MM/DD').add(
        diffDaysBtwnSmallestDateAndSelectedDate,
        'days',
      );
      newLoadOrDischargePortStartDates.push(newLoadOrDischargeDateValue.format('YYYY-MM-DD'));

      diffTimeBtwnLoadOrDischargePortDates = Math.abs(
        +moment(selectedLoadOrDischargePortStartDates[i], 'YYYY/MM/DD') -
          +moment(newLoadOrDischargePortStartDates[i], 'YYYY/MM/DD'),
      );
      diffDaysBtwnLoadOrDischargePortDates = diffTimeBtwnLoadOrDischargePortDates / (1000 * 60 * 60 * 24);

      if (
        moment(newLoadOrDischargePortStartDates[i], 'YYYY/MM/DD') <
        moment(selectedLoadOrDischargePortStartDates[i], 'YYYY/MM/DD')
      ) {
        if (currentlocationtype === 'refinery' || currentlocationtype === 'ThirdPartyRef') {
          newLoadOrDischargePortValue = moment(selectedDragDischargePortStartDates[i], 'YYYY/MM/DD').subtract(
            diffDaysBtwnLoadOrDischargePortDates,
            'days',
          );
        } else {
          newLoadOrDischargePortValue = moment(selectedDragLoadPortStartDates[i], 'YYYY/MM/DD').subtract(
            diffDaysBtwnLoadOrDischargePortDates,
            'days',
          );
        }
      } else {
        if (currentlocationtype === 'refinery' || currentlocationtype === 'ThirdPartyRef') {
          newLoadOrDischargePortValue = moment(selectedDragDischargePortStartDates[i], 'YYYY/MM/DD').add(
            diffDaysBtwnLoadOrDischargePortDates,
            'days',
          );
        } else {
          newLoadOrDischargePortValue = moment(selectedDragLoadPortStartDates[i], 'YYYY/MM/DD').add(
            diffDaysBtwnLoadOrDischargePortDates,
            'days',
          );
        }
      }

      if (currentlocationtype === 'refinery' || currentlocationtype === 'ThirdPartyRef') {
        updateNewDragDates(
          newLoadOrDischargePortStartDates[i],
          newLoadOrDischargePortValue.format('YYYY-MM-DD'),
          selectedTaskIdList[i]!,
        );
      } else if (
        (currentlocationtype === 'depot' || currentlocationtype === 'ThirdPartyCus') &&
        selectedTasksBLDate[i] === null
      ) {
        updateNewDragDates(
          newLoadOrDischargePortValue.format('YYYY-MM-DD'),
          newLoadOrDischargePortStartDates[i],
          selectedTaskIdList[i]!,
        );
      } else if (
        (currentlocationtype === 'depot' || currentlocationtype === 'ThirdPartyCus') &&
        selectedTasksBLDate[i] !== null
      ) {
        {
          updateNewDragDates(
            selectedDragLoadPortStartDates[i],
            newLoadOrDischargePortStartDates[i],
            selectedTaskIdList[i]!,
          );
        }
      }
    }
    onDragAndDropLoaderModal(true);

    if (!smallestOfSelectedDates?.localeCompare(DestinationDate.format('YYYY/MM/DD'))) {
      push({ type: 'ERROR', text: 'You have dragged and dropped into same date' });
      previousValues.length = 0;
      flagForDragAndDropLoader = true;
      selectedTaskIdList = [];
      stopLoader(SINGLELOADERTIMECONSTANT);
      return;
    }
    try {
      await updateRecordByDate({
        variables: {
          input: inputArray,
        },
      });
      onClickSimulationEvent(!dataFetch);
      flagForDragAndDropLoader = true;
    } catch (error) {
      console.error('Failed to fetch records for Lifting ', error);
      push({ type: 'ERROR', text: 'Movement updation failed' });
      flagForDragAndDropLoader = true;
      selectedTaskIdList = [];
      previousValues.length = 0;
      stopLoader(MULTILOADERTIMECONSTANT);
      return;
    }
    stopLoader(MULTILOADERTIMECONSTANT);
  };

  const handleEnd = async (result: DropResult) => {
    if (selectedTaskIds.length === 0 || selectedTaskIds.length === 1) {
      if (selectedTaskIds.length === 0) {
        selectedTaskIds.push(result.draggableId);
      }
      singleDragAndDrop(result);
      resetLengthOfArraysForDragAndDrop();
    } else {
      if (selectedTaskIds.includes(result.draggableId)) {
        multiDragAndDrop(
          selectedTaskIds,
          selectedDragLoadPortStartDates,
          selectedDragDischargePortStartDates,
          selectedTasksBLDate,
          result,
        );
        resetLengthOfArraysForDragAndDrop();
      } else {
        selectedTaskIds.length = 0;
        selectedTaskIds.push(result.draggableId);
        singleDragAndDrop(result);
        resetLengthOfArraysForDragAndDrop();
      }
    }
  };

  const resetLengthOfArraysForDragAndDrop = () => {
    selectedTaskIds.length = 0;
    selectedDragLoadPortStartDates.length = 0;
    selectedDragDischargePortStartDates.length = 0;
    selectedTasksBLDate.length = 0;
  };
  const addSelectedTaskIdsToAnArray = (item: SchedulingManagerType, getSelectedRows: SchedulingManagerType[]) => {
    const index: number = selectedTaskIds.indexOf(item.id);
    const values = selectedTaskIds.filter(id => id === item.id).length;
    if (getSelectedRows.length > 0 && index !== -1 && values > 0) {
      selectedTaskIds.splice(index, 1);
      selectedDragLoadPortStartDates.splice(index, 1);
      selectedDragDischargePortStartDates.splice(index, 1);
      selectedTasksBLDate.splice(index, 1);
      cloneIds.splice(index, 1);
      loadPorts.splice(index, 1);
      dischargePorts.splice(index, 1);
    } else {
      selectedTaskIds.push(item.id);
      selectedDragLoadPortStartDates.push(item.dragLoadportstartdate!);
      selectedDragDischargePortStartDates.push(item.dragDischargePortStartDate!);
      selectedTasksBLDate.push(item.BLDate!);
      cloneIds.push(item.movementCloneId!);
      loadPorts.push(item.loadPort!);
      dischargePorts.push(item.dischargePort!);
    }
    actualValues = getSelectedRows;
  };

  const useOutsideAlerter = (ref: React.MutableRefObject<HTMLInputElement>) => {
    useEffect(() => {
      // tslint:disable-next-line
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target) && !(event.metaKey || event.ctrlKey)) {
          setSelected(false);
        }
      };
      const handleSelected = () => {
        setSelected(true);
      };
      document.addEventListener('dblclick', handleClickOutside);
      document.addEventListener('mousedown', handleSelected);
      return () => {
        document.removeEventListener('dblclick', handleClickOutside);
        document.removeEventListener('mousedown', handleSelected);
      };
    }, [ref]);
  };
  const wrapperRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  useOutsideAlerter(wrapperRef);

  const checkForUndo = (movementStages: GetScheduleManagerByPortQuery['getScheduleManagerByPort']) => {
    for (const value of actualValues) {
      const movement = movementStages.find(
        movement =>
          movement.movementCloneId === value.movementCloneId &&
          (movement.productType === 'Lifting' || movement.productType === 'Replenishment'),
      );
      if (
        movement &&
        (movement?.loadPort !== value.loadPort ||
          movement?.dischargePort !== value.dischargePort ||
          (movement?.BLDate !== null && movement?.BLDate !== value?.BLDate) ||
          (movement?.BLDate !== null && movement?.dischargeCompletionDate !== value?.dischargeCompletionDate) ||
          (movement?.loadPortLaycanStartDate &&
            value.loadPortLaycanStartDate &&
            movement?.loadPortLaycanStartDate?.toString() !== value.loadPortLaycanStartDate.toString()) ||
          (movement?.dischargePortLaycanStartDate &&
            value.dischargePortLaycanStartDate &&
            movement?.dischargePortLaycanStartDate.toString() !== value.dischargePortLaycanStartDate.toString()) ||
          (movement?.loadPortLaycanEndDate &&
            value.loadPortLaycanEndDate &&
            movement?.loadPortLaycanEndDate.toString() !== value.loadPortLaycanEndDate.toString()) ||
          (movement?.dischargePortLaycanEndDate &&
            value.dischargePortLaycanEndDate &&
            movement?.dischargePortLaycanEndDate.toString() !== value.dischargePortLaycanEndDate.toString()))
      ) {
        disableUndoButton(true);
        actualValues.length = 0;
        previousValues.length = 0;
      }
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleEnd}>
        {dates.map((date: string, index: number) => {
          let movementStages: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] = [];
          let eventNotes: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] = [];

          if (data) {
            if (currentlocationtype === 'refinery') {
              movementStages = getRefineryMovementsOnDate(data, date, currentPort);
              actualValues && checkForUndo(movementStages);
              eventNotes = getEventsOndate(data, date);
            }
            if (currentlocationtype === 'depot') {
              movementStages = getDepotMovementsOnDate(data, date, currentPort);
              actualValues && checkForUndo(movementStages);
              eventNotes = getEventsOndate(data, date);
            }
            if (currentlocationtype === 'ThirdPartyRef') {
              movementStages = getThirdpartyRefineryMovementsOnDate(data, date, currentPort);
              actualValues && checkForUndo(movementStages);
            }
            if (currentlocationtype === 'ThirdPartyCus') {
              movementStages = getThirdpartyCustomerMovementsOnDate(data, date, currentPort);
              actualValues && checkForUndo(movementStages);
            }
          }

          return (
            <>
              <div ref={wrapperRef}>
                <Droppable droppableId={index.toString()}>
                  {(provided, snapshot) => (
                    <StyleForDroppable
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? currentUserTheme
                            ? theme.secondary.light.c
                            : '#282F39'
                          : '',
                      }}
                    >
                      <EastSchedulingManager
                        eventNotes={eventNotes}
                        key={index}
                        loadingdata={loading}
                        movementsInRefineryAndDepot={data}
                        date={date}
                        movementStages={movementStages}
                        currentlocationtype={currentlocationtype}
                        onEditMovementModal={onEditMovementModal}
                        locations={locations}
                        onDeleteMovementModal={onDeleteMovementModal}
                        currentPort={currentPort}
                        addSelectedTaskIdsToAnArray={addSelectedTaskIdsToAnArray}
                        isThirdParty={isThirdParty}
                        color={selected}
                        movementMultipleDeletion={movementMultipleDeletion}
                        currentUserTheme={currentUserTheme}
                        onClickSimulationEvent={onClickSimulationEvent}
                        dataFetch={dataFetch}
                      />
                      {provided.placeholder}
                    </StyleForDroppable>
                  )}
                </Droppable>
              </div>
            </>
          );
        })}
      </DragDropContext>
    </>
  );
};
