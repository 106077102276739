import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { URLS } from '../../../constants';

export const CREATE_SPOT_SUCCESS_MESSAGE = 'New spot demand created.';
export const CREATE_SPOT_FAILURE_MESSAGE = 'Error creating spot demand.';

const Spacer = styled.div<{ size?: number }>`
  height: ${props => 20 * (props.size || 1)}px;
`;

export interface Props {
  showQuitModal: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  currentUserTheme?: boolean;
}

const ConfirmationModal: React.FunctionComponent<Props> = props => {
  return (
    <Modal
      onDismiss={props.onDismiss}
      onConfirm={props.onConfirm}
      isOpen={props.showQuitModal}
      minWidth={500}
      dismissText="No"
      confirmText="Yes"
      currentUserTheme={props.currentUserTheme}
    >
      <Spacer />
      <Typography variant="heading5" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center">
        Save changes?
      </Typography>
      <Spacer size={2} />

      <Typography variant="body3" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center">
        Do you want to save changes before returning to your dashboard?
      </Typography>
      <Spacer />
    </Modal>
  );
};

export { ConfirmationModal };
