// adding common functions and styled components used in  Production and Sales container forms
import styled from 'styled-components';
import { Typography } from '../../../../../components/Typography';
import { GetScheduleManagerByPortQuery } from '@scout/types';

export interface RefineryProductionSalesTabValues {
  port: string;
  productType: string;
  grade: string;
  plannedVolume: number;
  plannedStartDate: string;
  plannedEndDate: string;
  planningNotes: string;
  skipWeekends: boolean;
}
import * as Yup from 'yup';
import { Button } from '../../../../../components/Button';
import { TextArea } from '../../../../../components/TextArea';
import moment, { Moment } from 'moment';
export const REQUIRED_ERROR_MESSAGE = 'This is a required field';
export const PAST_DATE_MESSAGE = 'Start Date cannot be in the past';
export const EventValidationErrorMessage =
  'There is an event blocking this input. Please remove event before creating movement';
export const AdjustmentValidationMessage = `Adjustment Can't Be Modified`;
export const AdjustmentGrade = 'Adjustment';

export const Root = styled.div<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme === true ? props.theme.base.dark.d : props.theme.base.light.b)};
  background: ${props => (props.currentUserTheme === true ? props.theme.base.light.b : props.theme.base.dark.d)};
  margin-top: 50px;
  margin-bottom: 10px;
  overflow-y: auto;
`;
export const Label = styled(Typography).attrs({ variant: 'body3' })<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme === true ? props.theme.base.dark.d : props.theme.base.light.b)};
  margin-bottom: 5px;
`;

export const PlanningContainer = styled.div`
  margin-top: -35px;
  margin-bottom: 10px;
  padding: 12px;
  width: 307px;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`;
export const InputContainerDays = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`;
export const Spaceinbetween = styled.div`
  padding-left: 15px;
`;

export const VolumeStyleTextBox = styled.div`
  width: 75px;
`;
export const StyledTextArea = styled(TextArea)`
  padding: 8px;
`;

export const DateContainer = styled.div`
  margin-top: 10px;
  margin-buttom: 10px;
`;

export const CopyContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  float: right
`;

export const NewButton = styled(Button)`
  margin-left: 16px;
`;
export const MovementValidation = styled.div`
  position: absolute;
  width: 679px;
  height: 45px;
  left: 35px;
  top: 165px;
  background: rgba(215, 43, 63, 0.4);
  border: 1px solid #db4052;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  padding: 9px;
`;

export const InsideCalendar = styled.span`
  padding: 0.5px;
`;

export const checkEventPresent = async (
  values: RefineryProductionSalesTabValues,
  SMdata: GetScheduleManagerByPortQuery | undefined,
  setisEventPresent: (val: boolean) => void,
) => {
  const formPlannedStartDate = moment(values.plannedStartDate).format('YYYY-MM-DD');
  const formPlannedEndDate = moment(values.plannedEndDate).format('YYYY-MM-DD');
  const foundData = SMdata?.getScheduleManagerByPort.find(item => {
    return (
      item.productType === 'Event' &&
      item.port === values.port &&
      item.blockDates === true &&
      (item.eventStartDate
        ? moment(item.eventStartDate).isBetween(formPlannedStartDate, formPlannedEndDate, undefined, '[]')
        : '')
    );
  });
  if (foundData) {
    setisEventPresent(true);
    return true;
  } else {
    setisEventPresent(false);
    return false;
  }
};
export const today = new Date();
today.setHours(0, 0, 0, 0);

export const validationSchema = Yup.object().shape({
  grade: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  plannedStartDate: Yup.date().required(REQUIRED_ERROR_MESSAGE),
  plannedVolume: Yup.number()
    .required(REQUIRED_ERROR_MESSAGE)
    .typeError('plannedVolume should be a number'),
  planningNotes: Yup.string().max(500),
  plannedEndDate: Yup.date().required(REQUIRED_ERROR_MESSAGE),
  skipWeekends: Yup.boolean(),
});
