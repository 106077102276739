import { FormikHandlers } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';
import { config } from '../../config';
import { FormError } from '../FormError/FormError';
import { fontMixin } from '../Mixins';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';

export interface InputProps {
  onChange?: FormikHandlers['handleChange'];
  onBlur?: FormikHandlers['handleBlur'];
  value?: JSX.IntrinsicElements['input']['value'];
  name: string;
  label?: string;
  placeholder?: string;
  error?: string;
  themeStyle?: 'dark' | 'light';
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const InputBaseMixin = css<{ error?: string; variant?: string; showErrorIcon?: boolean; suffix?: string }>`
  ${fontMixin}
  padding: 0.5rem;
  ${({ error, showErrorIcon }) => error != null && showErrorIcon && 'padding-left: 2rem;'}
  ${({ suffix }) => suffix != null && 'padding-right: 1.25rem;'}
  width: 100%;
  border-radius: 2px;

  &:hover,
  &:focus {
    border-color: ${props => !props.error && props.theme.primary.b};
    outline: none;
  }

  &:disabled {
    background: ${props => props.theme.greys.light.disabled};
    border-color: ${props => props.theme.greys.light.disabled};
  }
`;

export const InputLightMixin = css<{ error?: string; variant?: string }>`
  ${InputBaseMixin}
  background: ${props => props.theme.base.light.a};
  border: 1px solid ${props => (props.error ? props.theme.secondary.light.a : props.theme.greys.light.border)};
  color: ${props => props.theme.base.dark.b};

  &::placeholder {
    color: ${props => props.theme.greys.light.border};
  }
`;

export const InputMixin = css<{ error?: string; variant?: string }>`
  ${InputBaseMixin}
  background: ${props => props.theme.base.dark.a};
  border: 1px solid ${props => (props.error ? props.theme.secondary.light.a : props.theme.greys.dark.border)};
  color: ${props => props.theme.secondary.light.c};

  &::placeholder {
    color: ${props => props.theme.greys.dark.border};
  }
`;

const Root = styled.div<{ showErrorMessage: boolean; disabled?: boolean }>`
  position: relative;
  ${p => p.showErrorMessage && 'padding-bottom: 2rem;'}

  ${p =>
    !p.disabled &&
    css`
      &:hover path {
        fill: ${props => props.theme.primary.b};
      }
    `}
`;

const ErrorContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 11px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const Label = styled(Typography)`
  margin-bottom: 5px;
`;

const Suffix = styled(Typography)`
  position: absolute;
  right: 10px;
  top: 11px;
`;

interface InputBaseProps {
  children: React.ReactNode;
  error?: string;
  label?: string;
  className?: string;
  showErrorMessage?: boolean;
  showErrorIcon?: boolean;
  renderIcon?: (focused: boolean) => React.ReactNode;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  suffix?: string;
  value?: JSX.IntrinsicElements['input']['value'];
  themeStyle?: 'light' | 'dark';
  disabled?: boolean;
}

const getSuffixColor = ({
  value,
  themeStyle,
}: {
  value?: InputBaseProps['value'];
  themeStyle: InputBaseProps['themeStyle'];
}) => {
  if (themeStyle === 'dark') {
    return value == null || value === '' ? 'hintDark' : 'bodyDark';
  }

  return value == null || value === '' ? 'hintLight' : 'bodyLight';
};

const InputBase: React.FC<InputBaseProps> = ({
  className,
  children,
  error,
  label,
  showErrorMessage = true,
  showErrorIcon = true,
  renderIcon,
  onFocus,
  onBlur,
  suffix,
  value,
  themeStyle,
  disabled,
}) => {
  const [focused, setFocused] = React.useState(false);
  return (
    <Root
      disabled={disabled}
      showErrorMessage={showErrorMessage}
      className={className}
      onFocus={e => {
        if (!disabled) {
          setFocused(true);
          onFocus && onFocus(e);
        }
      }}
      onBlur={e => {
        if (!disabled) {
          setFocused(false);
          onBlur && onBlur(e);
        }
      }}
    >
      {label && (
        <Label variant="body3" tag="label" color="bodyDark">
          {label}
        </Label>
      )}
      <>
        {children}
        {suffix != null && (
          <Suffix color={getSuffixColor({ value, themeStyle })} variant="body3" tag="span">
            {suffix}
          </Suffix>
        )}
        {error && (
          <>
            {showErrorIcon && (
              <ErrorContainer>
                <Tooltip body={error} active={!showErrorMessage} styles={{ marginLeft: 5, marginTop: -5 }}>
                  <img alt="error icon" src={`${config.PUBLIC_URL}/images/error-input.svg`} />
                </Tooltip>
              </ErrorContainer>
            )}
            {showErrorMessage && <FormError message={error} />}
          </>
        )}
        {renderIcon && <IconContainer>{renderIcon(focused)}</IconContainer>}
      </>
    </Root>
  );
};

export { InputBase };
