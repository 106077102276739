import React from 'react';
import { withTheme } from 'styled-components';

export const NoteWarningIcon = withTheme(({ theme, isCritical }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4166 12.25L6.99998 1.16669L0.583313 12.25H13.4166ZM6.41665 10.5V9.33335H7.58331V10.5H6.41665ZM6.41665 8.16669H7.58331V5.83335H6.41665V8.16669Z"
      fill={isCritical ? theme.secondary.light.a : theme.primary.a}
    />
  </svg>
));
