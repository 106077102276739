import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { DateDropdown } from './DateDropdown';
import { EastLocationDropdown } from './EastLocationDropdown';
import { LocationsQuery } from '@scout/types';

import { config } from '../../../../config';
import moment from 'moment';
import { Col, Grid, Row } from '../../../../components/Grid';
import { Typography } from '../../../../components/Typography';
import { useHistory } from 'react-router-dom';

export interface LastUpdatedUserObject {
  updatedBy: string | undefined | null;
  updatedAt: string | undefined | null;
}

export interface ProductStockInfo {
  date: string;
  location_id: string;
  stock: number;
  ullage: number;
  ullageFlag: number;
  id: string;
}

interface EastMenuProps {
  onShowEastNewMovementModal: () => void;
  onCloseRequest: () => void;
  onShowEastSpreadsheet: () => void;
  locations: LocationsQuery['locations'];
  toggleFullHalfView: (value: boolean) => void;
  fullViewActive: boolean | null;
  onShowEastUndoModal: () => void;
  onShowEastMoreActionsModal: () => void;
  disableUndoButton: boolean;
  currentUserTheme?: boolean;
  lastUpdatedUserLog: LastUpdatedUserObject | null;
  simulation?: boolean;
  onCloseSimulationModal: () => void;
}
const Container = styled.div`
  padding: 0 16px;
  background: ${props => props.theme.base.dark.c};
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled(Typography)`
  margin: 0 0 0;
`;
const SubContainer = styled.div`
  display: flex;

  & > * {
    margin-left: 8px;
  }

  & > *:first-child {
    margin-left: 0;
  }
`;

export const StockUllageTextColor = styled.span`
  color: #ff6060;
`;

const DownloadButton = styled(Button).attrs({ icon: 'MdFileDownload', variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
`;

const RefreshButton = styled(Button).attrs({ icon: 'MdRefresh', variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
`;
const UndoButton = styled(Button).attrs({ icon: 'MdUndo', variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
`;

const ViewFull = styled(Button).attrs({ variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
  margin:0px; !important;
  background-image:url(${config.PUBLIC_URL}/images/full-screen.svg)
`;

const DarkViewFull = styled(Button).attrs({ variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
  margin:0px; !important;
  background-image:url(${config.PUBLIC_URL}/images/dark-full-screen.svg)
`;

const ViewHalf = styled(Button).attrs({ variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
  margin:0px; !important;
  background-image:url(${config.PUBLIC_URL}/images/half-screen.svg)
`;

const LightViewHalf = styled(Button).attrs({ variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
  margin:0px; !important;
  background-image:url(${config.PUBLIC_URL}/images/light-half-screen.svg)
`;

const MoreActionsIcon = styled(Button).attrs({ variant: 'ghost-light' })`
border:none;
width: 22px;
justify-content: center;
margin:0px; !important;
height:22px;
padding:4px;
background-image:url(${config.PUBLIC_URL}/images/more_vert_white.svg);
top: 6px;
`;

const dateFormat = 'DD/MM/YYYY hh:mm A';
const localDateTime = (date: moment.MomentInput) => {
  return moment
    .utc(date)
    .local()
    .format(dateFormat);
};

export const EastMenu: React.FunctionComponent<EastMenuProps> = ({
  onCloseRequest,
  onShowEastNewMovementModal,
  onShowEastSpreadsheet,
  onShowEastUndoModal,
  onShowEastMoreActionsModal,
  locations,
  toggleFullHalfView,
  fullViewActive,
  disableUndoButton,
  currentUserTheme,
  lastUpdatedUserLog,
  simulation,
  onCloseSimulationModal,
}) => {
  return (
    <Container>
      <SubContainer>
        <EastLocationDropdown locations={locations} />
        <DateDropdown />
        <Button icon="MdAdd" onClick={onShowEastNewMovementModal}>
          Add New
        </Button>
        {!simulation && <DownloadButton onClick={onShowEastSpreadsheet} />}
        <UndoButton
          icon="MdUndo"
          onClick={onShowEastUndoModal}
          disabled={disableUndoButton}
          currentUserTheme={currentUserTheme}
        />
        {simulation && <RefreshButton variant="ghost-dark" icon="MdRefresh" onClick={() => history.go(0)} />}
      </SubContainer>
      {!simulation && lastUpdatedUserLog?.updatedBy && !fullViewActive && (
        <Col data-testid="header-region">
          <Label variant="labels1" color="hintLight">
            Last Updated On{' '}
            <span style={{ color: '#eceeee' }}>{localDateTime(lastUpdatedUserLog.updatedAt?.toString())}</span>
          </Label>
          <Typography variant="body4" color="bodyDark">
            {lastUpdatedUserLog.updatedBy}
          </Typography>
        </Col>
      )}
      {!simulation && (
        <SubContainer>
          {!fullViewActive && (
            <DarkViewFull
              onClick={async () => {
                toggleFullHalfView(false);
              }}
            />
          )}
          {fullViewActive && (
            <ViewFull
              onClick={async () => {
                toggleFullHalfView(false);
              }}
            />
          )}
          {fullViewActive && (
            <ViewHalf
              onClick={async () => {
                toggleFullHalfView(true);
              }}
            />
          )}
          {!fullViewActive && (
            <LightViewHalf
              onClick={async () => {
                toggleFullHalfView(true);
              }}
            />
          )}
          {fullViewActive && <Button icon="MdPlace" variant="secondary" onClick={onCloseRequest} />}
          {!fullViewActive && (
            <Button icon="MdPlace" variant="secondary" onClick={onCloseRequest}>
              Map View
            </Button>
          )}
          <MoreActionsIcon onClick={onShowEastMoreActionsModal} />
        </SubContainer>
      )}
      {simulation && (
        <SubContainer>
          <Button onClick={onCloseSimulationModal}>Close Simulation</Button>
        </SubContainer>
      )}
    </Container>
  );
};
