import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Field, FieldProps, getIn, FastField, Formik, Form } from 'formik';
import { SingleDropdown } from '../../../../../components/SelectDropdown/Single';
import {
  getEastGradeItems,
  getPriceBasisOptions,
  getTransportItems,
  getIncotermOptions,
  getDepotLoadPortOptions,
  getRefineryDischargePortOptions,
} from '../../utils';
import { Grid, Row, Col } from '../../../../../components/Grid';
import { TextInput } from '../../../../../components/TextInput';
import { filterByLabel } from '../../../../../utils';
import moment, { Moment } from 'moment';
import { Calendar } from '../../../../../components/Calendar';
import { useToast } from '../../../../../components/Toast/Toast';
import { useMutation } from 'react-apollo';
import {
  CREATE_LIFTING_OR_REPLENISHMENT,
  CreateLiftingOrReplenishmentMutation,
  CreateLiftingOrReplenishmentMutationVariables,
  GradeModelFragmentFragment,
  GetEditFormDataQuery,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  UpdateLiftingOrReplenishmentMovementMutation,
  UpdateLiftingOrReplenishmentMovementMutationVariables,
  UPDATE_LIFTING_OR_REPLENISHMENT_BY_MOVEMENT,
  LocationsQuery,
} from '@scout/types';
import { isInclusivelyBeforeDay } from 'react-dates';
import {
  Required,
  RequiredText,
  LiftingAndReplenishmentGradeContainer,
  CheckboxContainer,
} from './EastMovementFormsHelper';
import {
  BtnStyledRow,
  checkEventPresent,
  EventValidationErrorMessage,
  getAllRequiredValues,
  getDefaultValues,
  InputContainer,
  Label,
  MovementValidation,
  NewButton,
  RefineryLiftingReplenishmentValues,
  Root,
  RowStyle,
  ScrollRoot,
  StyledTextArea,
  today,
  validationSchema,
  WidthAdjustmentContainer,
  getCountry,
  getPlannedLoadMonth,
  InsideCalendar,
} from './EastLiftingReplenishmentHelper';
import { getModifiedValue } from './EastMovementsHelper';
import { Checkbox } from '../../../../../components/Checkbox';

interface Props {
  grades: GradeModelFragmentFragment[];
  vessels: GetEditFormDataQuery['transports'];
  currentLocationName: string;
  cancelModal: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  selectedMovement: SchedulingManagerType | null;
  // tslint:disable-next-line
  replenishmentSelectedValues: any;
  locations: LocationsQuery['locations'];
  isThirdParty?: boolean;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

today.setHours(0, 0, 0, 0);

export const EastDepotReplenishmentContainer: React.FunctionComponent<Props> = props => {
  const [loadPortLaycanstartDate, setLoadPortLaycanStartDate] = useState<Moment | null>();
  const [loadPortLaycanendDate, setLoadPortLaycanendDate] = useState<Moment | null>();
  const [blDatestartDate, setBlDateStartDate] = useState<Moment | null>();
  const [dischargePortLaycanstartDate, setDischargePortLaycanStartDate] = useState<Moment | null>();
  const [dischargePortLaycanendDate, setDischargePortLaycanendDate] = useState<Moment | null>();
  const [buttonActive, setButtonActive] = useState(true);
  const [plannedLoadMonthState, setPlannedLoadMonthState] = useState(
    props.replenishmentSelectedValues ? props.replenishmentSelectedValues.plannedLoadMonth : '',
  );
  const [vessel, setVessel] = useState(props.selectedMovement && props.selectedMovement.coa !== null ? false : true);
  const [isChecked, setisChecked] = useState(
    props.selectedMovement && props.selectedMovement.coa !== null ? props.selectedMovement.coa : false,
  );
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const { push } = useToast();
  const [movementValidation, setMovementValidation] = useState(false);
  const [countryCode, setCountryCode] = useState<string | undefined>(
    props.replenishmentSelectedValues ? props.replenishmentSelectedValues.country : '',
  );
  const [clickedOutside, setClickedOutside] = useState(false);
  const [internalFreightPrice, setInternalFreightPrice] = useState(
    props?.replenishmentSelectedValues?.internalFreightPrice !== null
      ? props.replenishmentSelectedValues.internalFreightPrice
      : null,
  );
  const [customerFreightActualPrice, setCustomerFreightActualPrice] = useState(
    props?.replenishmentSelectedValues?.customerFreightActualPrice !== null
      ? props.replenishmentSelectedValues.customerFreightActualPrice
      : null,
  );
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [createLiftingOrReplenishment, setCreateLiftingOrReplenishment] = useMutation<
    CreateLiftingOrReplenishmentMutation,
    CreateLiftingOrReplenishmentMutationVariables
  >(CREATE_LIFTING_OR_REPLENISHMENT, {
    context: {
      headers,
    },
  });
  const [updateLiftingOrReplenishmentMovement, setUpdateLiftingOrReplenishmentMovement] = useMutation<
    UpdateLiftingOrReplenishmentMovementMutation,
    UpdateLiftingOrReplenishmentMovementMutationVariables
  >(UPDATE_LIFTING_OR_REPLENISHMENT_BY_MOVEMENT, {
    context: {
      headers,
    },
  });

  // Detecting click outside component
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleClickOutside = (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (!myRef.current.contains(target)) {
      setClickedOutside(true);
    }
  };
  const handleClickInside = () => setClickedOutside(false);
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  });

  const handleOnSubmit = async (values: RefineryLiftingReplenishmentValues) => {
    if (props.selectedMovement !== null) {
      const input = {
        movementCloneId: props.selectedMovement?.movementCloneId || '',
        ...values,
      };
      try {
        await updateLiftingOrReplenishmentMovement({
          variables: {
            input,
          },
        });
        props.onClickSimulationEvent(!props.dataFetch);
        push({ type: 'SUCCESS', text: 'Depot Replenishment Updated successfully' });
        setDisabledSaveButton(false);
        props.cancelModal();
      } catch (error) {
        push({ type: 'ERROR', text: 'Depot Replenishment Update Failed' });
      }
    } else {
      try {
        await createLiftingOrReplenishment({
          variables: {
            input: values,
          },
        });
        props.onClickSimulationEvent(!props.dataFetch);
        push({ type: 'SUCCESS', text: 'Depot Replenishment created successfully' });
        setDisabledSaveButton(false);
        props.cancelModal();
      } catch (error) {
        push({ type: 'ERROR', text: 'Depot Replenishment Failed' });
      }
    }
  };

  useEffect(() => {
    let countryValue;
    props.selectedMovement === null
      ? props.locations.map(location => {
          if (location.displayName === props.currentLocationName) {
            countryValue = location.country !== null ? location.country : '';
            setCountryCode(countryValue);
          }
        })
      : '';
  }, []);

  return (
    <Root currentUserTheme={props.currentUserTheme}>
      <Formik
        validationSchema={validationSchema}
        initialValues={
          props.selectedMovement
            ? props.replenishmentSelectedValues
            : getDefaultValues(props.currentLocationName, 'Replenishment')
        }
        validate={values => {
          getAllRequiredValues(values, setButtonActive);
        }}
        onSubmit={async (values, formActions) => {
          // converting the below to number format as its been converted to string by using toFixed function
          values.coa = Boolean(isChecked);
          values.plannedVolume = +values.plannedVolume;
          values.actualVolume = +values.actualVolume;
          values.fobPriceInternal = +values.fobPriceInternal;
          values.fobPriceCustomer = +values.fobPriceCustomer;
          values.internalFreightPrice = internalFreightPrice === null ? null : +internalFreightPrice;
          values.customerFreightActualPrice = customerFreightActualPrice === null ? null : +customerFreightActualPrice;
          values.country = countryCode;

          if (props.selectedMovement === null) {
            values.dragDischargePortStartDate = values.dischargePortLaycanStartDate;
            values.dragLoadportstartdate = values.loadPortLaycanStartDate;
          } else {
            // checking if the laycan start dates / loadport are changed and resetting dragdate variables accordingly
            if (
              props.selectedMovement.loadPortLaycanStartDate !== values.loadPortLaycanStartDate ||
              props.selectedMovement.dischargePortLaycanStartDate !== values.dischargePortLaycanStartDate ||
              props.selectedMovement.loadPort !== values.loadPort
            ) {
              values.dragDischargePortStartDate = values.dischargePortLaycanStartDate;
              values.dragLoadportstartdate = values.loadPortLaycanStartDate;
            } else {
              values.dragDischargePortStartDate = props.selectedMovement.dragDischargePortStartDate;
              values.dragLoadportstartdate = props.selectedMovement.dragLoadportstartdate;
            }
          }

          const changedValue = getModifiedValue(values, props.replenishmentSelectedValues);
          const eventvalidation = await checkEventPresent(
            values,
            'Replenishment',
            props.currentLocationName,
            props.SMdata,
            setMovementValidation,
          );

          if (!eventvalidation) {
            if (values.loadPortLaycanStartDate < moment().format('YYYY-MM-DD') && !props.selectedMovement) {
              if (confirm('Are you sure to create movement for past date?')) {
                setDisabledSaveButton(true);
                await handleOnSubmit(values);
                formActions.setSubmitting(false);
              } else {
                setButtonActive(false);
              }
            } else {
              setDisabledSaveButton(true);
              await handleOnSubmit(values);
              formActions.setSubmitting(false);
            }
          }
        }}
        render={formProps => {
          const isSubmitted = formProps.submitCount > 0 ? true : false;

          return (
            <Form>
              {movementValidation && <MovementValidation>{EventValidationErrorMessage}</MovementValidation>}
              <ScrollRoot>
                <InsideCalendar ref={myRef} onClick={handleClickInside}>
                  <Grid>
                    <Row>
                      <Col size={6}>
                        <LiftingAndReplenishmentGradeContainer>
                          <Row>
                            <RowStyle>
                              <InputContainer>
                                <Label currentUserTheme={props.currentUserTheme}>Grade</Label>
                                <Field name="grade">
                                  {({
                                    field,
                                    form: { errors, setFieldValue },
                                  }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                    <SingleDropdown
                                      darkTheme={props.currentUserTheme ? false : true}
                                      width="315px"
                                      error={getIn(errors, field.name)}
                                      items={getEastGradeItems(props.grades)}
                                      name={field.name}
                                      onItemClick={item => {
                                        setFieldValue('grade', item.value);
                                      }}
                                      selected={field.value}
                                      showClearIndicator={false}
                                      isClearable={false}
                                      placeholder="Bitumen"
                                    />
                                  )}
                                </Field>
                              </InputContainer>
                            </RowStyle>
                          </Row>
                        </LiftingAndReplenishmentGradeContainer>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Planned Volume (kt)<Required>*</Required>
                            </Label>

                            <Field name="plannedVolume">
                              {({
                                field,
                                form: { errors, touched, setFieldValue, values },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={isSubmitted ? getIn(errors, 'plannedVolume') : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`plannedVolume`, e.target.value);
                                    }}
                                    placeholder="0.000"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null && field.value !== 0 ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Actual Volume (kt)</Label>

                            <Field name="actualVolume">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`actualVolume`, e.target.value);
                                    }}
                                    placeholder="0.000"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null && field.value !== 0 ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Price Basis</Label>
                            <Field name="basisPrice">
                              {({
                                field,
                                form: { errors, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <SingleDropdown
                                  darkTheme={props.currentUserTheme ? false : true}
                                  width="315px"
                                  error={getIn(errors, field.name)}
                                  items={getPriceBasisOptions()}
                                  name={field.name}
                                  onItemClick={item => {
                                    setFieldValue('basisPrice', item.value);
                                  }}
                                  selected={field.value}
                                  showClearIndicator={false}
                                  isClearable={false}
                                  placeholder="Select"
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>FOB price(Internal)</Label>

                            <Field name="fobPriceInternal">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`fobPriceInternal`, e.target.value);
                                    }}
                                    placeholder="$0.00"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null && field.value !== 0 ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>FOB price(Customer)</Label>

                            <Field name="fobPriceCustomer">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`fobPriceCustomer`, e.target.value);
                                    }}
                                    placeholder="$0.00"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null && field.value !== 0 ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Freight price (Internal)</Label>

                            <Field name="internalFreightPrice">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setInternalFreightPrice(e.target.value);
                                      setFieldValue(`internalFreightPrice`, e.target.value);
                                      if (e.target.value.trim().length === 0) {
                                        setInternalFreightPrice(null);
                                      }
                                    }}
                                    placeholder="$0.00"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Freight Price (Customer)</Label>

                            <Field name="customerFreightActualPrice">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setCustomerFreightActualPrice(e.target.value);
                                      setFieldValue(`customerFreightActualPrice`, e.target.value);
                                      if (e.target.value.trim().length === 0) {
                                        setCustomerFreightActualPrice(null);
                                      }
                                    }}
                                    placeholder="$0.00"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Purchase Contract</Label>

                            <Field name="purchaseContract">
                              {({
                                field,
                                form: { errors, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      const newValue = e.target.value;

                                      if (newValue == null) {
                                        return;
                                      }

                                      field.onChange(e);
                                    }}
                                    placeholder=""
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null ? field.value.trimLeft() : ''}
                                    autoComplete={'on'}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Sales Contract</Label>

                            <Field name="salesContract">
                              {({
                                field,
                                form: { errors, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      const newValue = e.target.value;

                                      if (newValue == null) {
                                        return;
                                      }

                                      field.onChange(e);
                                    }}
                                    placeholder=""
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null ? field.value : ''}
                                    autoComplete={'on'}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <WidthAdjustmentContainer widthToSet="315px">
                            <InputContainer>
                              <Label currentUserTheme={props.currentUserTheme}>Buyer Info</Label>

                              <Field name="buyerInfo">
                                {({
                                  field,
                                  form: { errors, touched },
                                }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      const newValue = e.target.value;

                                      if (newValue == null) {
                                        return;
                                      }

                                      field.onChange(e);
                                    }}
                                    placeholder=""
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null ? field.value : ''}
                                    autoComplete={'on'}
                                  />
                                )}
                              </Field>
                            </InputContainer>
                          </WidthAdjustmentContainer>
                        </Row>
                        <Row>
                          <WidthAdjustmentContainer widthToSet="315px">
                            <InputContainer>
                              <Label currentUserTheme={props.currentUserTheme}>Planning Note</Label>
                              <FastField
                                name="planningNotes"
                                render={({ field }: FieldProps<string>) => (
                                  <StyledTextArea
                                    {...field}
                                    maxLength={500}
                                    tabIndex={1000}
                                    data-testid="planning-notes"
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    placeholder="Please leave your thoughts…"
                                    rows={1}
                                  />
                                )}
                              />
                            </InputContainer>
                          </WidthAdjustmentContainer>
                        </Row>
                        <Row>
                          <RequiredText>* Required Fields</RequiredText>
                        </Row>
                      </Col>
                      <Col size={6}>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Vessel Name<Required>*</Required>
                            </Label>

                            <Field name="vesselName">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <SingleDropdown
                                  tabIndex={1}
                                  darkTheme={props.currentUserTheme ? false : true}
                                  width="315px"
                                  name="vesselName"
                                  error={isSubmitted ? getIn(errors, 'vesselName') : undefined}
                                  placeholder="Select vessel"
                                  showClearIndicator={false}
                                  items={getTransportItems(props.vessels, null)}
                                  onItemClick={item => {
                                    setFieldValue('vesselName', item.value);
                                    item.value ? setVessel(false) : setVessel(true);
                                  }}
                                  selected={field.value || null}
                                  filterOption={filterByLabel}
                                  disabled={
                                    props.selectedMovement === null ||
                                    (props.selectedMovement !== null && props.selectedMovement.BLDate === null)
                                      ? false
                                      : true
                                  }
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <CheckboxContainer>
                            <InputContainer>
                              <Field name="coa">
                                {({ form: { setFieldValue } }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                  <Checkbox
                                    disabled={vessel}
                                    isEast={true}
                                    name={`coa`}
                                    label="COA"
                                    checked={isChecked}
                                    onChange={checkedValue => {
                                      setisChecked(Boolean(checkedValue));
                                      setFieldValue(`coa`, Boolean(checkedValue));
                                    }}
                                    onBlur={checkedValue => {
                                      setFieldValue(`coa`, Boolean(checkedValue));
                                    }}
                                    currentUserTheme={props.currentUserTheme}
                                  />
                                )}
                              </Field>
                            </InputContainer>
                          </CheckboxContainer>
                        </Row>

                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Incoterm</Label>
                            <Field name="incoterm">
                              {({
                                field,
                                form: { errors, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <SingleDropdown
                                  darkTheme={props.currentUserTheme ? false : true}
                                  width="315px"
                                  error={getIn(errors, field.name)}
                                  items={getIncotermOptions()}
                                  name={field.name}
                                  onItemClick={item => {
                                    setFieldValue('incoterm', item.value);
                                  }}
                                  selected={field.value}
                                  showClearIndicator={false}
                                  isClearable={false}
                                  placeholder="Select"
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <WidthAdjustmentContainer widthToSet="315px">
                            <InputContainer>
                              <Label currentUserTheme={props.currentUserTheme}>
                                Load Port <Required>*</Required>
                              </Label>

                              <Field name="loadPort">
                                {({
                                  field,
                                  form: { errors, setFieldValue },
                                }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                  <SingleDropdown
                                    darkTheme={props.currentUserTheme ? false : true}
                                    width="315px"
                                    error={isSubmitted ? getIn(errors, 'loadPort') : undefined}
                                    items={getDepotLoadPortOptions()}
                                    name={field.name}
                                    onItemClick={item => {
                                      setFieldValue('loadPort', item.value);
                                    }}
                                    selected={field.value}
                                    showClearIndicator={false}
                                    isClearable={false}
                                    placeholder="Select"
                                    disabled={
                                      props.selectedMovement === null ||
                                      (props.selectedMovement !== null && props.selectedMovement.BLDate === null)
                                        ? false
                                        : true
                                    }
                                  />
                                )}
                              </Field>
                            </InputContainer>
                          </WidthAdjustmentContainer>
                        </Row>

                        <Row>
                          <Label currentUserTheme={props.currentUserTheme}>Load Port Laycan</Label>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Start Date <Required>*</Required>
                            </Label>
                            <Field name="loadPortLaycanStartDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  disabled={
                                    props.selectedMovement === null ||
                                    (props.selectedMovement !== null && props.selectedMovement.BLDate === null)
                                      ? false
                                      : true
                                  }
                                  name={`loadPortLaycanStartDate`}
                                  error={
                                    getIn(touched, field.name) ? getIn(errors, 'loadPortLaycanStartDate') : undefined
                                  }
                                  placeholder="Select start Date"
                                  width="155px"
                                  align="left"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : loadPortLaycanstartDate
                                      ? moment(loadPortLaycanstartDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`loadPortLaycanStartDate`, date.format());
                                    setFieldTouched(`loadPortLaycanStartDate`, true);
                                    setLoadPortLaycanStartDate(date);
                                    setPlannedLoadMonthState('');
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    if (loadPortLaycanendDate !== undefined) {
                                      return (
                                        !isInclusivelyBeforeDay(
                                          day,
                                          moment(
                                            loadPortLaycanendDate ? loadPortLaycanendDate : moment(),
                                            'YYYY/MM/DD',
                                          ),
                                        ) ||
                                        day.isBefore(
                                          moment()
                                            .subtract(8, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    } else {
                                      return (
                                        day.isBefore(
                                          moment()
                                            .subtract(8, 'months')
                                            .format('YYYY/MM/DD'),
                                        ) ||
                                        day.isAfter(
                                          moment()
                                            .add(10, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    }
                                  }}
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              End Date<Required>*</Required>
                            </Label>
                            <Field name="loadPortLaycanEndDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  disabled={
                                    props.selectedMovement === null ||
                                    (props.selectedMovement !== null && props.selectedMovement.BLDate === null)
                                      ? false
                                      : true
                                  }
                                  name={`loadPortLaycanEndDate`}
                                  error={
                                    getIn(touched, field.name) ? getIn(errors, 'loadPortLaycanEndDate') : undefined
                                  }
                                  placeholder="Select end Date"
                                  width="155px"
                                  align="right"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : loadPortLaycanendDate
                                      ? moment(loadPortLaycanendDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`loadPortLaycanEndDate`, date.format());
                                    setFieldTouched(`loadPortLaycanEndDate`, true);
                                    setLoadPortLaycanendDate(date);
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    if (loadPortLaycanstartDate !== undefined) {
                                      return (
                                        day.isBefore(
                                          moment(
                                            moment(
                                              loadPortLaycanstartDate ? loadPortLaycanstartDate : moment(),
                                              'YYYY/MM/DD',
                                            ),
                                          ).startOf('day'),
                                        ) ||
                                        day.isAfter(
                                          moment()
                                            .add(10, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    } else {
                                      return (
                                        day.isBefore(
                                          moment()
                                            .subtract(8, 'months')
                                            .format('YYYY/MM/DD'),
                                        ) ||
                                        day.isAfter(
                                          moment()
                                            .add(10, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    }
                                  }}
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Planned Load Month</Label>
                            <Field name="plannedLoadMonth">
                              {({
                                field,
                                form: { errors, setFieldValue },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <SingleDropdown
                                  disabled={
                                    loadPortLaycanstartDate || props.selectedMovement?.loadPortLaycanStartDate
                                      ? false
                                      : true
                                  }
                                  darkTheme={props.currentUserTheme ? false : true}
                                  width="155px"
                                  error={getIn(errors, field.name)}
                                  items={getPlannedLoadMonth(
                                    loadPortLaycanstartDate,
                                    props.replenishmentSelectedValues,
                                  )}
                                  name={field.name}
                                  onItemClick={item => {
                                    setFieldValue('plannedLoadMonth', item.value);
                                    setPlannedLoadMonthState('' + item.value);
                                  }}
                                  selected={plannedLoadMonthState}
                                  showClearIndicator={false}
                                  isClearable={false}
                                  placeholder="Select"
                                />
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>BL Date</Label>
                            <Field name="BLDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  name={`BLDate`}
                                  error={getIn(touched, field.name) ? getIn(errors, 'BLDate') : undefined}
                                  placeholder="Select Date"
                                  width="155px"
                                  align="right"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : blDatestartDate
                                      ? moment(blDatestartDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`BLDate`, date.format());
                                    setFieldTouched(`BLDate`, true);
                                    setBlDateStartDate(date);
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  goToCurrentBtnText="Clear"
                                  onGoToCurrent={() => {
                                    setFieldValue(`BLDate`, null);
                                    setBlDateStartDate(null);
                                  }}
                                  disabled={
                                    props.selectedMovement === null ||
                                    (props.selectedMovement !== null &&
                                      props.selectedMovement.dischargeCompletionDate === null)
                                      ? false
                                      : true
                                  }
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Discharge Port <Required>*</Required>
                            </Label>
                            {!props.isThirdParty && (
                              <Field name="dischargePort">
                                {({
                                  field,
                                  form: { errors, touched },
                                }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                  <WidthAdjustmentContainer widthToSet="250px">
                                    <TextInput
                                      disabled={props.currentUserTheme ? false : true}
                                      align="left"
                                      error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                      name={field.name}
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const newValue = e.target.value;

                                        if (newValue == null) {
                                          return;
                                        }

                                        field.onChange(e);
                                      }}
                                      placeholder=""
                                      showErrorIcon={false}
                                      showErrorMessage={true}
                                      themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                      value={props.currentLocationName}
                                      disabledTextColor={true}
                                    />
                                  </WidthAdjustmentContainer>
                                )}
                              </Field>
                            )}

                            {props.isThirdParty && (
                              <Field name="dischargePort">
                                {({
                                  field,
                                  form: { errors, setFieldValue },
                                }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                  <SingleDropdown
                                    darkTheme={props.currentUserTheme ? false : true}
                                    width="246px"
                                    error={isSubmitted ? getIn(errors, 'dischargePort') : undefined}
                                    items={getRefineryDischargePortOptions()}
                                    name={field.name}
                                    onItemClick={item => {
                                      getCountry(item.value, props.locations, setCountryCode);
                                      setFieldValue('dischargePort', item.value);
                                    }}
                                    selected={field.value}
                                    showClearIndicator={false}
                                    isClearable={false}
                                    placeholder="Select"
                                  />
                                )}
                              </Field>
                            )}
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Country</Label>
                            <Field name="country">
                              {({
                                field,
                                form: { errors, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <WidthAdjustmentContainer widthToSet="65px">
                                  <TextInput
                                    align="right"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      const newValue = e.target.value;
                                      if (newValue == null) {
                                        return;
                                      }
                                      setCountryCode(newValue);
                                      field.onChange(e);
                                    }}
                                    placeholder=""
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={countryCode}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <Row>
                          <Label currentUserTheme={props.currentUserTheme}>Discharge Port Laycan</Label>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Start Date<Required>*</Required>
                            </Label>
                            <Field name="dischargePortLaycanStartDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  disabled={
                                    props.selectedMovement === null ||
                                    (props.selectedMovement !== null && props.selectedMovement.BLDate === null)
                                      ? false
                                      : true
                                  }
                                  name={`dischargePortLaycanStartDate`}
                                  error={
                                    getIn(touched, field.name)
                                      ? getIn(errors, 'dischargePortLaycanStartDate')
                                      : undefined
                                  }
                                  placeholder="Select Date"
                                  width="155px"
                                  align="left"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : dischargePortLaycanstartDate
                                      ? moment(dischargePortLaycanstartDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`dischargePortLaycanStartDate`, date.format());
                                    setFieldTouched(`dischargePortLaycanStartDate`, true);
                                    setDischargePortLaycanStartDate(date);
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    if (loadPortLaycanstartDate !== undefined) {
                                      return (
                                        day.isBefore(
                                          moment(
                                            loadPortLaycanstartDate ? loadPortLaycanstartDate : moment(),
                                            'YYYY/MM/DD',
                                          ).startOf('day'),
                                        ) ||
                                        day.isBefore(
                                          moment()
                                            .subtract(8, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    } else {
                                      return (
                                        day.isBefore(
                                          moment()
                                            .subtract(8, 'months')
                                            .format('YYYY/MM/DD'),
                                        ) ||
                                        day.isAfter(
                                          moment()
                                            .add(10, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    }
                                  }}
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              End Date<Required>*</Required>
                            </Label>
                            <Field name="dischargePortLaycanEndDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<RefineryLiftingReplenishmentValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  disabled={
                                    props.selectedMovement === null ||
                                    (props.selectedMovement !== null && props.selectedMovement.BLDate === null)
                                      ? false
                                      : true
                                  }
                                  name={`dischargePortLaycanEndDate`}
                                  error={
                                    getIn(touched, field.name) ? getIn(errors, 'dischargePortLaycanEndDate') : undefined
                                  }
                                  placeholder="Select Date"
                                  width="155px"
                                  align="right"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : dischargePortLaycanendDate
                                      ? moment(dischargePortLaycanendDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`dischargePortLaycanEndDate`, date.format());
                                    setFieldTouched(`dischargePortLaycanEndDate`, true);
                                    setDischargePortLaycanendDate(date);
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    if (dischargePortLaycanstartDate !== undefined) {
                                      return (
                                        day.isBefore(
                                          moment(
                                            moment(
                                              dischargePortLaycanstartDate ? dischargePortLaycanstartDate : moment(),
                                              'YYYY/MM/DD',
                                            ),
                                          ).startOf('day'),
                                        ) ||
                                        day.isAfter(
                                          moment()
                                            .add(10, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    } else {
                                      return (
                                        day.isBefore(
                                          moment()
                                            .subtract(8, 'months')
                                            .format('YYYY/MM/DD'),
                                        ) ||
                                        day.isAfter(
                                          moment()
                                            .add(10, 'months')
                                            .format('YYYY/MM/DD'),
                                        )
                                      );
                                    }
                                  }}
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                        <BtnStyledRow>
                          <Row>
                            <NewButton
                              variant={props.currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                              onClick={async () => {
                                props.cancelModal();
                              }}
                            >
                              Cancel
                            </NewButton>

                            <NewButton
                              variant="primary"
                              type="submit"
                              disabled={disabledSaveButton ? true : buttonActive}
                              currentUserTheme={props.currentUserTheme}
                            >
                              Save
                            </NewButton>
                          </Row>
                        </BtnStyledRow>
                      </Col>
                    </Row>
                  </Grid>
                </InsideCalendar>
              </ScrollRoot>
            </Form>
          );
        }}
      />
    </Root>
  );
};
