import { Form, Formik, FormikActions } from 'formik';
import React, { Ref, useCallback } from 'react';
import styled from 'styled-components';
import { useToast } from '../../../../../components/Toast/Toast';
import { DemandLocation, Plant } from '@scout/types';
import { ContractDemandState } from '../../types';
import { StepTitle } from '../StepTitle';
import { GradeSplitStepValues } from '../types';
import { GradeSplitDemands } from './Demands';
import { createValues } from './formValues';
import { areGradeSplitsValid } from './validation';

const GradeSplitDemandsSpacer = styled.div`
  margin-bottom: 32px;
`;

interface Props {
  demandLocation: DemandLocation;
  formRef: Ref<Formik<GradeSplitStepValues>>;
  onSubmit: (values: GradeSplitStepValues) => void;
  penPlants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>;
  premiumPlants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>;
  state: ContractDemandState;
}

const GradeSplitStep: React.FC<Props> = ({ formRef, onSubmit, penPlants, premiumPlants, state }) => {
  const { push } = useToast();
  const handleOnSubmit = useCallback(
    (values, formikActions: FormikActions<GradeSplitStepValues>) => {
      const isValid = areGradeSplitsValid(values);

      if (!isValid) {
        push({
          text: 'Each demand total needs to be 100%',
          size: 'SMALL',
          type: 'ERROR',
        });
        formikActions.setSubmitting(false);
      } else {
        onSubmit(values);
      }
    },
    [push, onSubmit],
  );

  return (
    <Formik
      initialValues={createValues(state, penPlants, premiumPlants)}
      onSubmit={handleOnSubmit}
      ref={formRef}
      render={({ values }) => (
        <Form>
          <StepTitle
            description="Your grade split must equal 100% before you can continue"
            number={2}
            title="Grade split"
          />
          {values.penRows.length > 0 && (
            <GradeSplitDemands rows={values.penRows} heading="Pen demands" name="penRows" />
          )}
          <GradeSplitDemandsSpacer />
          {values.premiumRows.length > 0 && (
            <GradeSplitDemands rows={values.premiumRows} heading="Premium demands" name="premiumRows" />
          )}
        </Form>
      )}
    />
  );
};

export { GradeSplitStep };
