import React from 'react';
import styled from 'styled-components';
import { UIDrawerProps } from './IDrawer';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const DrawerContent = styled.div<{ isVisible: boolean }>`
  position: absolute;
  height: 100%;
  top: 0;
  display: block;
  z-index: 20;
  transition: all 0.5s ease;
`;
DrawerContent.displayName = 'DrawerContent';

const RightDrawer = styled(DrawerContent)`
  right: ${props => (props.isVisible ? '1px' : '-100%')};
`;

const LeftDrawer = styled(DrawerContent)`
  left: ${props => (props.isVisible ? '1px' : '-100%')};
`;

export const OverlayDrawer: React.FunctionComponent<UIDrawerProps> = ({
  mainElements,
  drawerElements,
  side,
  isVisible,
}) => {
  let DrawerContainer;

  switch (side) {
    case 'left':
      DrawerContainer = LeftDrawer;
      break;
    case 'right':
    default:
      DrawerContainer = RightDrawer;
  }

  return (
    <Container>
      {mainElements}
      <DrawerContainer isVisible={isVisible}>{drawerElements}</DrawerContainer>
    </Container>
  );
};
