import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { LabelValue } from '../../../components/LabelValue';
import { RequirePermission } from '../../../components/RequirePermission';
import { Typography } from '../../../components/Typography';
import { formatRoundedNumber } from '../../../helpers';

const SortBar = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${p => (p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.b)};
`;

const SortBarItem = styled.div`
  margin: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Tag = styled(Typography).attrs(props => ({ color: props.currentUserTheme ? 'bodyLight' : 'bodyDark' }))<{
  currentUserTheme?: boolean;
}>`
  margin-left: 10px;
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  border-radius: 100px;
  padding: 3px 10px;
`;

interface Props {
  fuelOilAverageDate: string;
  fuelOilAveragePrice: number;
  isNewDemandDisabled: boolean;
  itemCount: number;
  onNewSpotDemandClick: () => void;
  currentUserTheme?: boolean;
}

const DashboardSortBar: React.FC<Props> = ({
  fuelOilAverageDate,
  fuelOilAveragePrice,
  isNewDemandDisabled,
  itemCount,
  onNewSpotDemandClick,
  currentUserTheme,
}) => {
  return (
    <SortBar currentUserTheme={currentUserTheme}>
      <SortBarItem>
        <Typography variant="body2" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
          Showing
        </Typography>
        <Tag currentUserTheme={currentUserTheme}>{itemCount}</Tag>
      </SortBarItem>
      <SortBarItem>
        <LabelValue
          label="M-1 Avg. FO"
          value={formatRoundedNumber(fuelOilAveragePrice)}
          optionalText={`As of ${fuelOilAverageDate}`}
          darkTheme={currentUserTheme ? false : true}
          currentUserTheme={currentUserTheme}
        />
      </SortBarItem>
      <RequirePermission anyOf={['editDemandForecast']}>
        <Button disabled={isNewDemandDisabled} icon="MdAdd" onClick={onNewSpotDemandClick}>
          New spot demand
        </Button>
      </RequirePermission>
    </SortBar>
  );
};

export { DashboardSortBar };
