import * as React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Typography } from '../Typography';
import { useHover } from '../../hooks/useHover';

const ChildrenContainer = styled.div<{ active: boolean }>`
  ${p => p.active && 'cursor: pointer;'}
`;

const TooltipOverlay = styled.div<{ styles?: CSSObject }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  color: black;
  height: max-content;
  width: max-content;
  padding: 5px;

  border-radius: 2px;
  box-shadow: 0px 10px 38px 0px rgba(0, 0, 0, 0.75);
  z-index: 9999;

  &:after {
    left: 7.5px;
    bottom: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${p => p.theme.base.light.a};
    border-width: 7.5px;
  }

  ${p => p.styles}
`;

export interface TooltipProps {
  children: React.ReactNode;
  body: string | React.ReactNode;
  active?: boolean;
  styles?: CSSObject;
}

const Tooltip: React.FC<TooltipProps> = ({ children, body, active = true, styles }) => {
  const [hovered, hoverEvents] = useHover();
  const tooltipEventprops = active ? hoverEvents : {};

  return (
    <div>
      <ChildrenContainer {...tooltipEventprops} active={active}>
        {children}
      </ChildrenContainer>
      {hovered && (
        <TooltipOverlay styles={styles}>
          {typeof body === 'string' ? <Typography variant="body3">{body}</Typography> : body}
        </TooltipOverlay>
      )}
    </div>
  );
};

export { Tooltip };
