import { DemandLocation, GradeType, Plant, Region, ShippingLocation } from '@scout/types';
import {
  DemandsStepValues,
  DemandsStepVolumeRow,
  GradeSplitStepGrade,
  GradeSplitStepValues,
  Month,
  MonthlyVolumesStepValues,
} from './Steps/types';
import { ContractDemandState, Demand } from './types';

export interface UpdateStateFromDemandsInput {
  demandLocation: DemandLocation;
  existingState: ContractDemandState;
  penPlants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>;
  premiumPlants: Array<Pick<Plant, 'id' | 'shortName' | 'grades'>>;
  regions: Array<Pick<Region, 'id' | 'name'>>;
  shipTos: Array<Pick<ShippingLocation, 'id' | 'name' | 'code' | 'salesOrganisation'>>;
  values: DemandsStepValues;
  totalVolume?: number;
}

/**
 * With output from Step 1 update the Create state.
 */
export const updateStateFromDemands = ({
  demandLocation,
  existingState,
  penPlants,
  premiumPlants,
  regions,
  shipTos,
  values,
  totalVolume,
}: UpdateStateFromDemandsInput): ContractDemandState => {
  // TODO: Try to maintain existing values from next steps if user has gone
  // back to Step 1. For now we just wipe everything.
  const existingPenDemands = existingState.demands.filter(d => !d.isPremium);
  const existingPremiumDemands = existingState.demands.filter(d => d.isPremium);

  const newPenDemands = createDemandsFromDemandVolumeRows({
    demandLocation,
    regions,
    shipTos,
    existingDemands: existingPenDemands,
    isPremium: false,
    plants: penPlants,
    rows: values.penRows,
    totalVolume,
  });

  const newPremiumDemands = createDemandsFromDemandVolumeRows({
    demandLocation,
    regions,
    shipTos,
    existingDemands: existingPremiumDemands,
    isPremium: true,
    plants: premiumPlants,
    rows: values.premiumRows,
    totalVolume,
  });

  return {
    ...existingState,
    demands: [...newPenDemands, ...newPremiumDemands],
  };
};

interface CreateDemandsFromDemandVolumeRowsInput {
  demandLocation: DemandLocation;
  existingDemands: Demand[];
  isPremium: boolean;
  plants: UpdateStateFromDemandsInput['penPlants'];
  regions: UpdateStateFromDemandsInput['regions'];
  rows: DemandsStepVolumeRow[];
  shipTos: UpdateStateFromDemandsInput['shipTos'];
  // @ts-ignore
  totalVolume?: number;
}

const createDemandsFromDemandVolumeRows = ({
  demandLocation,
  existingDemands,
  isPremium,
  plants,
  regions,
  rows,
  shipTos,
  totalVolume,
}: CreateDemandsFromDemandVolumeRowsInput) =>
  rows.map<Demand>(row => {
    // See if this is a demand that exists because we're in "edit" mode or
    // the user has already gone on to Step 2 & 3 and come back in "create".
    // Changing the plant is a big change though (different grades) so we
    // don't care about preserving the state from it in that case.
    const existingDemand = existingDemands.find(d => d.id === row.id && d.plant.id === row.plant);
    const plant = plants.find(p => p.id === row.plant)!;
    const region = demandLocation === DemandLocation.Region ? regions.find(r => r.id === row.location) : undefined;
    const shipTo = demandLocation === DemandLocation.ShipTo ? shipTos.find(r => r.id === row.location) : undefined;
    const newTotalVolume = totalVolume ? totalVolume : Number(row.volume);

    if (existingDemand != null) {
      return {
        ...existingDemand,
        // If volume amount has changed then monthly volumes set previously are not
        // valid. These would be regenerated again in Step 3 but might as well make
        // sure.
        monthlyVolumes: newTotalVolume === existingDemand.totalVolume ? existingDemand.monthlyVolumes : {},
        plant,
        region,
        shipTo,
        totalVolume: newTotalVolume,
      };
    }

    return {
      isFixed: row.isFixed,
      isPremium,
      plant,
      region,
      shipTo,
      id: row.id,
      gradeSplit: [],
      monthlyVolumes: {},
      totalVolume: newTotalVolume,
    };
  });

interface UpdateStateFromGradeSplitInput {
  existingState: ContractDemandState;
  values: GradeSplitStepValues;
}
/**
 * With output from Step 2 update the state.
 */
export const updateStateFromGradeSplit = ({
  existingState,
  values,
}: UpdateStateFromGradeSplitInput): ContractDemandState => {
  const formatGrade = (grades: GradeSplitStepGrade[], type: GradeType) => {
    return grades.map(grade => ({
      id: grade.id,
      grade: {
        id: grade.gradeId,
        name: grade.gradeName,
        type,
      },
      percentage: grade.percentage ? Number(grade.percentage) : undefined,
    }));
  };
  const gradeSplit = [...values.penRows, ...values.premiumRows].map(value => ({
    id: value.id,
    gradeSplit: [
      ...formatGrade(value.hardGrades, GradeType.Hard),
      ...formatGrade(value.softGrades, GradeType.Soft),
      ...formatGrade(value.premiumGrades, GradeType.Premium),
      ...formatGrade(value.nonBitumenGrades, GradeType.NonBitumen),
    ],
  }));

  const demands: ContractDemandState['demands'] = existingState.demands.map(demand => {
    const value = gradeSplit.find(value => value.id === demand.id);

    if (value) {
      return {
        ...demand,
        gradeSplit: value.gradeSplit,
      };
    }

    return demand;
  });

  return {
    ...existingState,
    demands,
  };
};

interface UpdateStateFromMonthlyValuesInput {
  existingState: ContractDemandState;
  values: MonthlyVolumesStepValues;
}
/**
 * With output from Step 3 update the state.
 */
export const updateStateFromMonthlyValuesSplit = ({
  existingState,
  values,
}: UpdateStateFromMonthlyValuesInput): ContractDemandState => {
  const updatedDemands = existingState.demands.map(demand => {
    const value = values.rows.find(value => value.id === demand.id);
    if (value) {
      return {
        ...demand,
        monthlyVolumes: Object.values(Month).reduce<Demand['monthlyVolumes']>(
          (prev, curr) => ({
            ...prev,
            [curr]: Number(value.volumes[curr]),
          }),
          {},
        ),
      };
    }
    return demand;
  });

  return {
    ...existingState,
    demands: updatedDemands,
  };
};
