/**
 * Makes sure string input is between a `min` and a `max` if needed.
 *
 * @returns new value to be used or `undefined` if update should be halted.
 */
const restrictNumberValue = (
  value: string,
  min?: number,
  max?: number,
  allowNegative?: boolean,
): string | undefined => {
  if (value === '' || (allowNegative && value === '-')) {
    return value;
  }

  const parsedValue = Number(value);

  if (isNaN(parsedValue)) {
    return;
  }

  if (min != null) {
    if (parsedValue < min) {
      return min.toString();
    }
  }

  if (max != null) {
    if (parsedValue > max) {
      return max.toString();
    }
  }

  return value;
};

export { restrictNumberValue };
