import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Maybe } from '@scout/types/dist';

import { LOCATION_KEY_MAP } from './locationKeys';
import { useQuery } from 'react-apollo';
import {
  GetTodayDataForLocationQuery,
  GetTodayDataForLocationQueryVariables,
  GET_TODAY_DATA_FOR_LOCATION,
} from '@scout/types';
import { weatherIconData } from '../../constants';
import { config } from '../../config';
const Container = styled.div`
  position: relative;
`;

const Link = styled.a`
  display: flex;
  color: inherit;
`;

interface WeatherLinkProps {
  port?: Maybe<string>;
  date?: Maybe<string | Date>;
  currentUserTheme?: boolean;
}
const WeatherIconStyle = styled.div`
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  display: flex;
  flex-direction: row;
`;

interface DegStyleProps {
  currentUserTheme?: boolean;
}

const DegStyle = styled.span<DegStyleProps>`
  color: ${props => (props.currentUserTheme ? 'black' : 'white')};
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  line-height: 45px;
`;

const WeatherLink: React.FunctionComponent<WeatherLinkProps> = ({ port, date, currentUserTheme, children }) => {
  if (!port) {
    return <></>;
  }
  if (!date) {
    date = new Date();
  }

  const { data, error, loading } = useQuery<GetTodayDataForLocationQuery, GetTodayDataForLocationQueryVariables>(
    GET_TODAY_DATA_FOR_LOCATION,
    {
      variables: { location: port },
    },
  );
  // const weatherIcon = data && data.getTodayDataForLocation[0] && weatherIconData.find((item: any) => Object.keys(item)[0] === data.getTodayDataForLocation[0].weatherIcon.toString());

  const todaysDate = moment().startOf('day');
  const reqDate = moment(date).startOf('day');
  const days = Math.round(reqDate.diff(todaysDate) / (60 * 60 * 1000 * 24)) + 1;
  const locationKey = LOCATION_KEY_MAP[port];

  if (days < 1) {
    return <></>;
  }

  return (
    <Container>
      {data && data.getTodayDataForLocation[0] && data.getTodayDataForLocation[0].weatherIcon && (
        <WeatherIconStyle>
          <img
            alt="weather icon"
            style={{
              height: '45px',
              backgroundColor: currentUserTheme ? 'white' : '',
              padding: '5px',
            }}
            src={`${config.PUBLIC_URL}/images/weather_icons/${data.getTodayDataForLocation[0].weatherIcon}.svg`}
          />
          <DegStyle currentUserTheme={currentUserTheme}>
            {data.getTodayDataForLocation[0].temperatureMax}
            {<DegStyle currentUserTheme={currentUserTheme}>&deg;</DegStyle>}
          </DegStyle>
        </WeatherIconStyle>
      )}
      <Link
        href={`https://www.accuweather.com/en/us/%20/${locationKey}/daily-weather-forecast/${locationKey}?day=${days}`}
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        {children}
      </Link>
    </Container>
  );
};

export { WeatherLink };
