import React, { useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { makeClient } from './apollo-client';
import { ErrorBoundary } from './components/Error';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { Toaster } from './components/Toast/Toast';
import { URLS } from './constants';
import { GlobalStyle } from './GlobalStyle';
import { AdminPage } from './pages/Admin/Admin';
import { ErrorPage } from './pages/Error';
import { InMonthPage } from './pages/InMonth/InMonthPage';
import { Landing } from './pages/Landing';
import { PowerbiDashboard } from './pages/Reports';
import { MidTerm } from './pages/MidTerm';
import { CreateContractDemand, EditContractDemand } from './pages/MidTerm/ContractDemand';
import { Contracts } from './pages/MidTerm/Contracts';
import { Dashboard } from './pages/MidTerm/Dashboard';
import { CreateSpotForm } from './pages/MidTerm/SpotForm/CreateSpotForm';
import { EditSpotForm } from './pages/MidTerm/SpotForm/EditSpotForm';
import { Pricing } from './pages/Pricing';
import { StorybookPage } from './pages/Storybook/Storybook';
import { CurrentUserProvider } from './providers/currentUser';
import { theme } from './theme';
import { Simulation } from './pages/InMonth/Simulation';
import { HistoricalDataAnalysisPage } from './pages/HistoricalDataAnalysis';
import { VesselFlowAnalysis } from './pages/HistoricalDataAnalysis/VesselFlowAnalysis';

const apolloClient = makeClient();
interface UserThemeProps {
  currentUserTheme?: boolean;
  setCurrentUserTheme: React.Dispatch<React.SetStateAction<boolean>>;
}
const UserThemeContext = React.createContext<UserThemeProps>({} as UserThemeProps);
const App: React.FC = () => {
  const [currentUserTheme, setCurrentUserTheme] = useState(false);
  return (
    <ErrorBoundary>
      <GlobalStyle />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <UserThemeContext.Provider value={{ currentUserTheme, setCurrentUserTheme }}>
              <CurrentUserProvider>
                <Toaster>
                  <Switch>
                    {/* Switch looks top to bottom so put most specific routes at the top */}
                    <PrivateRoute path={URLS.MID_TERM_CREATE_CONTRACT_DEMAND} component={CreateContractDemand} />
                    <PrivateRoute path={URLS.MID_TERM_EDIT_CONTRACT_DEMAND} component={EditContractDemand} />
                    <PrivateRoute path={URLS.MID_TERM_CONTRACTS} component={Contracts} />
                    <PrivateRoute
                      path={URLS.MID_TERM_DASHBOARD}
                      component={Dashboard}
                      anyOfPermissions={['viewDemandForecast']}
                    />
                    <PrivateRoute
                      path={URLS.MID_TERM_EDIT_SPOT}
                      component={EditSpotForm}
                      anyOfPermissions={['editDemandForecast']}
                    />
                    <PrivateRoute
                      path={URLS.MID_TERM_CREATE_SPOT}
                      component={CreateSpotForm}
                      anyOfPermissions={['editDemandForecast']}
                    />
                    <PrivateRoute path={URLS.MID_TERM} component={MidTerm} />
                    <PrivateRoute path="/pricing" component={Pricing} anyOfPermissions={['viewPricing']} />
                    <PrivateRoute
                      path="/in-month"
                      component={InMonthPage}
                      anyOfPermissions={['viewFleetManagement', 'viewStockManagement']}
                    />
                    <PrivateRoute
                      path="/east/in-month"
                      component={InMonthPage}
                      anyOfPermissions={['viewEastSchedulingManager', 'eastDepotUser', 'editEastSchedulingManager']}
                    />
                    <PrivateRoute
                      path="/simulation"
                      component={Simulation}
                      anyOfPermissions={['viewEastSchedulingManager', 'eastDepotUser', 'editEastSchedulingManager']}
                    />
                    <PrivateRoute
                      path={URLS.HISTORICAL_DATA_ANALYSIS}
                      component={HistoricalDataAnalysisPage}
                      anyOfPermissions={['marineTrafficExportManagement']}
                    />
                    <PrivateRoute
                      path={URLS.VESSEL_FLOW_ANALYSIS}
                      component={VesselFlowAnalysis}
                      anyOfPermissions={['marineTrafficExportManagement']}
                    />
                    <Route path={URLS.REPORTS_LANDING} component={PowerbiDashboard} />
                    <Route path="/storybook" component={StorybookPage} />
                    <PrivateRoute path="/admin" component={AdminPage} />
                    <PrivateRoute exact={true} path="/" component={Landing} />
                    <Route component={ErrorPage} />
                  </Switch>
                </Toaster>
              </CurrentUserProvider>
            </UserThemeContext.Provider>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export { App, UserThemeContext };
