import { FastField, FieldArray, FieldProps, Formik, FormikActions } from 'formik';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { isInclusivelyBeforeDay } from 'react-dates';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Alert } from '../../../../components/Alert/Alert';
import { Button } from '../../../../components/Button';
import { Col, Row } from '../../../../components/Grid';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { TextArea } from '../../../../components/TextArea';
import { Typography } from '../../../../components/Typography';
import { filterByLabel } from '../../../../utils';
import { CancelMovementModal } from './CancelMovementModal';
import { MovementHeader } from './MovementHeader';
import { MovementRow } from './MovementRow';
import { RemoveMovementModal } from './RemoveMovementModal';
import { SaveChangesModal } from './SaveChangesModal';
import { TransportDropdownOption } from './TransportDropdownOption';
import {
  GradeModelFragmentFragment,
  LocationModelFragmentFragment,
  MovementGradeType,
  MovementLocationType,
  TransportType,
  GetEditFormDataQuery,
} from '@scout/types';
import {
  getGradeItems,
  getMovementStageError,
  getPortItems,
  getTransportItems,
  getUniqueErrorMessages,
} from '../utils';
import {
  FieldArrayRenderProps,
  FormAction,
  MovementFormValues,
  MovementStageFormValues,
  ProductTransferFormValues,
} from './types';

const VesselContainer = styled.div`
  width: 315px;
`;

const Label = styled(Typography).attrs({ variant: 'body3' })`
  margin-bottom: 5px;
`;

const PullRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  background-color: ${p => p.theme.base.dark.c};
  z-index: 1;
`;

const SaveButton = styled(Button)`
  margin-left: 16px;
`;

const Body = styled.div`
  background-color: ${p => p.theme.base.light.b};
  padding: 44px 0;
  height: 100vh;
  overflow-y: auto;
`;

const FormContainer = styled.div`
  width: 740px;
  margin: 0 auto;
  margin-bottom: 200px;
`;

const StyledTextArea = styled(TextArea)`
  padding: 8px;
`;

const DeleteButton = styled(Button).attrs({ icon: 'MdDelete', variant: 'ghost-light' })`
  width: 40px;
  justify-content: center;
  margin-right: 16px;
`;

const ErrorContainer = styled.div`
  margin-bottom: 28px;
`;

interface Props {
  loading: boolean;
  type: 'new' | 'edit';
  initialValues?: MovementFormValues;
  transports: GetEditFormDataQuery['transports'];
  grades: GradeModelFragmentFragment[];
  locations: LocationModelFragmentFragment[];
  onRemove?: () => void;
  onSubmit: (
    values: MovementFormValues,
    formActions: FormikActions<MovementFormValues>,
    action: FormAction,
  ) => Promise<void>;
  onCancel: () => void;
  onAddAnother: () => void;
}

const canShowCompletedCheckbox = ({
  type,
  movementStageIndex,
  previousStageCompleted,
  isCancelled,
}: {
  type: Props['type'];
  movementStageIndex: number;
  previousStageCompleted: boolean;
  isCancelled?: boolean;
}) => {
  if (isCancelled || type === 'new') {
    return false;
  }

  if (movementStageIndex === 0 || previousStageCompleted) {
    return true;
  }

  return false;
};

const defaultValues: MovementFormValues = {
  id: null,
  transportId: null,
  transportType: TransportType.None,
  planningNotes: '',
  cancelled: false,
  movementStages: [
    {
      id: null,
      movementId: null,
      index: 0,
      locationId: null,
      locationType: MovementLocationType.None,
      date: null,
      completed: false,
      productTransfers: [
        { movementStageId: null, movementStageIndex: 0, gradeId: null, gradeType: MovementGradeType.None, volume: '' },
      ],
    },
    {
      id: null,
      movementId: null,
      index: 1,
      locationId: null,
      locationType: MovementLocationType.None,
      date: null,
      completed: false,
      productTransfers: [
        { movementStageId: null, movementStageIndex: 1, gradeId: null, gradeType: MovementGradeType.None, volume: '' },
      ],
    },
  ],
};

export const GENERIC_ERROR_MESSAGE = 'Oops, can’t submit movement! Looks like there is an error on the form';
export const REQUIRED_ERROR_MESSAGE = 'Please complete all required fields (volumes cannot be zero)';
export const VOLUME_ERROR_MESSAGE = 'First port cannot have discharge operations';

const validationSchema = Yup.object().shape({
  movementStages: Yup.array().of(
    Yup.object({
      locationId: Yup.string()
        .nullable()
        .required(REQUIRED_ERROR_MESSAGE),
      date: Yup.string()
        .nullable()
        .required(REQUIRED_ERROR_MESSAGE),
      productTransfers: Yup.array().of(
        Yup.object({
          gradeId: Yup.string()
            .nullable()
            .required(REQUIRED_ERROR_MESSAGE),
          volume: Yup.string()
            .nullable()
            .required(REQUIRED_ERROR_MESSAGE)
            .test('valid-volume', VOLUME_ERROR_MESSAGE, function(item) {
              const productTransfer = this.parent as ProductTransferFormValues;

              if (productTransfer.movementStageIndex === 0) {
                const parsedVolume = parseFloat(item);

                if (isNaN(parsedVolume) || parsedVolume >= 0) {
                  return false;
                }
              }

              return true;
            }),
        }),
      ),
    }),
  ),
});

export const DropdownLabelRoot = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
`;

export const ScheduledLabel = styled(Typography)`
  white-space: nowrap;
`;

export const MovementForm: React.FC<Props> = ({
  loading,
  initialValues,
  type,
  transports,
  locations,
  grades,
  onSubmit,
  onCancel,
  onRemove,
  onAddAnother,
}) => {
  const [action, setAction] = React.useState<FormAction>('none');
  const [showSaveChangesModal, setShowSaveChangesModal] = React.useState(false);
  const [showRemoveMovementModal, setShowRemoveMovementModal] = React.useState(false);
  const [showCancelMovementModal, setShowCancelMovementModal] = React.useState(false);

  const movementCompleted = initialValues?.movementStages.every(movementStage => movementStage.completed);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={initialValues || defaultValues}
      onSubmit={async (values, formActions) => {
        await onSubmit(values, formActions, action);
      }}
      render={formProps => {
        const errorMessages = getUniqueErrorMessages(formProps.errors);
        const someStagesCompleted = formProps.values.movementStages.some(movementStage => movementStage.completed);
        const isCancelled = initialValues?.cancelled;

        return (
          <>
            <MovementHeader
              title={type === 'new' ? 'New Movement' : 'Edit Movement'}
              onClose={() => {
                if (formProps.dirty) {
                  setShowSaveChangesModal(true);
                } else {
                  onCancel();
                }
              }}
            />
            <Body>
              <FormContainer>
                {errorMessages.length > 0 && (
                  <ErrorContainer>
                    <Alert boxStyle="FLAT" type="ERROR" text={GENERIC_ERROR_MESSAGE} />
                    <Col>
                      {errorMessages.map(error => (
                        <Typography key={error} color="errorLight" variant="body3">
                          - {error}
                        </Typography>
                      ))}
                    </Col>
                  </ErrorContainer>
                )}
                {isCancelled && <Alert boxStyle="FLAT" type="ERROR" text="Movement Cancelled" />}
                {movementCompleted && <Alert boxStyle="FLAT" type="INFO" text="Movement Completed" />}
                <VesselContainer>
                  <Label>Transport</Label>
                  <SingleDropdown
                    disabled={isCancelled || someStagesCompleted}
                    tabIndex={1}
                    width="315px"
                    name="transport"
                    placeholder="Select vessel"
                    showClearIndicator={false}
                    renderOption={renderProps => <TransportDropdownOption {...renderProps} />}
                    items={getTransportItems(transports, initialValues?.transportId)}
                    onItemClick={item => {
                      formProps.setFieldValue('transportId', item.value);
                    }}
                    selected={formProps.values.transportId || null}
                    filterOption={filterByLabel}
                  />
                </VesselContainer>

                <FieldArray
                  name="movementStages"
                  validateOnChange={false}
                  render={({ form, replace, name }: FieldArrayRenderProps) => {
                    const selectedLocationIds = form.values.movementStages
                      .filter(stage => !!stage.locationId)
                      .map(stage => stage.locationId as string);

                    return form.values.movementStages
                      .sort((m1, m2) => m1.index - m2.index)
                      .map((movementStage: MovementStageFormValues, movementStageIndex: number) => {
                        const movementRowName = `movementStages.${movementStageIndex}`;
                        const previousStage = form.values.movementStages[movementStageIndex - 1];
                        return (
                          <MovementRow
                            key={movementRowName}
                            name={movementRowName}
                            showCompletedCheckbox={canShowCompletedCheckbox({
                              type,
                              movementStageIndex,
                              previousStageCompleted: previousStage?.completed ?? false,
                              isCancelled,
                            })}
                            movementStageHasBeenCompleted={initialValues?.movementStages[movementStageIndex].completed}
                            isFirstMovementStage={movementStageIndex === 0}
                            disabled={initialValues?.cancelled || movementStage.completed}
                            errors={getMovementStageError(form, movementStageIndex)}
                            isSubmitted={formProps.submitCount > 0}
                            index={movementStageIndex}
                            setFieldValue={form.setFieldValue}
                            setFieldTouched={form.setFieldTouched}
                            movementStage={movementStage}
                            ports={getPortItems(locations, selectedLocationIds, movementStage.locationId)}
                            grades={getGradeItems(grades)}
                            addProductTransfer={() => {
                              form.values.movementStages.forEach(
                                (movementStage: MovementStageFormValues, index: number) =>
                                  replace(index, {
                                    ...movementStage,
                                    productTransfers: [
                                      ...movementStage.productTransfers,
                                      {
                                        movementStageId: movementStage.id,
                                        movementStageIndex: index,
                                        gradeId: '',
                                        volume: '',
                                      },
                                    ],
                                  }),
                              );
                            }}
                            removeProductTransfer={(indexToRemove: number) => {
                              form.values.movementStages.forEach(
                                (movementStage: MovementStageFormValues, index: number) =>
                                  replace(index, {
                                    ...movementStage,
                                    productTransfers: movementStage.productTransfers.filter(
                                      (_, index) => index !== indexToRemove,
                                    ),
                                  }),
                              );
                            }}
                            isOutsideRange={(day: Moment) => {
                              const firstDate = form.values.movementStages[0].date;
                              if (movementStageIndex === 1 && firstDate) {
                                return day.isBefore(moment(firstDate, 'YYYY/MM/DD'));
                              }

                              const secondDate = form.values.movementStages[1].date;
                              if (movementStageIndex === 0 && secondDate) {
                                return !isInclusivelyBeforeDay(day, moment(secondDate, 'YYYY/MM/DD'));
                              }

                              return false;
                            }}
                            onUpdateGradeId={(productTransferIndex, value) => {
                              formProps.setFieldValue(
                                `${name}.${movementStageIndex + 1}.productTransfers.${productTransferIndex}.gradeId`,
                                value,
                              );
                            }}
                            onUpdateVolume={(productTransferIndex, value) => {
                              formProps.setFieldValue(
                                `${name}.${movementStageIndex + 1}.productTransfers.${productTransferIndex}.volume`,
                                value,
                              );
                            }}
                          />
                        );
                      });
                  }}
                />

                <Label>Planning note</Label>
                <FastField
                  name="planningNotes"
                  render={({ field }: FieldProps<string>) => (
                    <StyledTextArea
                      {...field}
                      maxLength={500}
                      tabIndex={1000}
                      data-testid="planning-notes"
                      themeStyle="light"
                      placeholder="Enter any additional instructions or notes for plan"
                    />
                  )}
                />

                {!initialValues?.cancelled && !someStagesCompleted && (
                  <Row>
                    {type === 'edit' && (
                      <DeleteButton data-testid="delete-button" onClick={() => setShowRemoveMovementModal(true)} />
                    )}
                    {type === 'edit' && (
                      <Button
                        data-testid="cancel-button"
                        variant="ghost-light"
                        onClick={() => {
                          setShowCancelMovementModal(true);
                        }}
                      >
                        Cancel plan
                      </Button>
                    )}
                  </Row>
                )}
              </FormContainer>
            </Body>
            <Footer>
              <Button
                variant="link-dark"
                onClick={() => {
                  if (formProps.dirty) {
                    setShowSaveChangesModal(true);
                  } else {
                    onCancel();
                  }
                }}
              >
                {type === 'new' ? 'Cancel' : 'Back to plan'}
              </Button>
              <PullRight>
                <Button
                  disabled={loading}
                  data-testid="add-another-button"
                  variant="ghost-dark"
                  onClick={() => {
                    setAction('addAnother');

                    if (formProps.dirty) {
                      formProps.submitForm();
                    } else {
                      onAddAnother();
                    }
                  }}
                >
                  Add another
                </Button>
                <SaveButton
                  disabled={loading}
                  data-testid="save-button"
                  variant="primary"
                  onClick={async () => {
                    setAction('saveAndClose');

                    await formProps.validateForm();

                    formProps.submitForm();
                  }}
                >
                  Save and close
                </SaveButton>
              </PullRight>
            </Footer>
            <SaveChangesModal
              onConfirm={() => {
                setAction('saveAndClose');

                if (formProps.dirty) {
                  formProps.submitForm();
                } else {
                  onCancel();
                }

                setShowSaveChangesModal(false);
              }}
              onDismiss={() => {
                onCancel();
                setShowSaveChangesModal(false);
              }}
              showModal={showSaveChangesModal}
            />
            <RemoveMovementModal
              onConfirm={() => {
                onRemove && onRemove();
                setShowRemoveMovementModal(false);
              }}
              onDismiss={() => {
                setShowRemoveMovementModal(false);
              }}
              showModal={showRemoveMovementModal}
            />
            <CancelMovementModal
              onConfirm={() => {
                setAction('cancel');
                formProps.submitForm();
                setShowCancelMovementModal(false);
              }}
              onDismiss={() => {
                setShowCancelMovementModal(false);
              }}
              showModal={showCancelMovementModal}
            />
          </>
        );
      }}
    />
  );
};
