import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../components/Typography';
import { Col, Grid } from '../../../components/Grid';
import { Moment } from 'moment';
import { LocationProductStockModel } from '@scout/types';
import { YYYYMMDD } from '../../../constants';

const MainProductGroupContainer = styled.div<{ currentUserTheme?: boolean }>`
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  margin-top: 10px;
  border-radius: 2px;
  justify-content: center;
`;

const SubProductGroupContainer = styled.div`
  border-top: 1px solid ${p => p.theme.secondary.light.c};
  justify-content: center;
  padding-top: 15px;
`;

const Inner = styled.div`
  padding: 10px;
`;
const BarWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
`;

// TODO: We should upgrade @types/styled-components and styled-components to 5.0.0: https://github.com/microsoft/TypeScript/issues/37597#issuecomment-628149946
// Temporarily added any as type to fix typescript issue.
// tslint:disable-next-line: no-any
const MainBarWrapper = styled(BarWrapper as any)`
  padding-top: 11%;
  padding-bottom: 2%;
`;

const Row = styled.div`
  padding:0px
  display: flex;
  flex-direction:row;
`;

const BarOuter = styled.div<{ isPiStock: boolean; currentUserTheme?: boolean }>`
  width: 100%;
  height: 15px;
  background: ${props => (props.currentUserTheme ? 'none' : props.theme.base.dark.d)};
  border-radius: 100px;
`;

const Spaceinbetween = styled.div`
  min-width: 15%;
  padding-left: 7px;
`;

const BarInner = styled.div<{ currentUserTheme?: boolean }>`
  overflow: hidden;
  margin: 0;
  height: 15px;
  border: 2px solid ${props => (props.currentUserTheme ? 'darkGrey' : props.theme.base.dark.b)};
  background: ${props => (props.currentUserTheme ? 'lightGrey' : props.theme.base.dark.d)};
  border-radius: 100px;
  position: relative;
  top: 2px;
  left: 2px;
`;

const NoInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NoInfoLightTheme = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.base.dark.d};
`;
const BarProgress = styled.div<{ percentage: number; redColorcoding: boolean }>`
  height: 100%;
  width: ${p => p.percentage}%;
  background: ${props => (props.redColorcoding ? 'red' : props.theme.primary.b)};
`;

interface ProductGroupProps {
  stockList: LocationProductStockModel[];
  filterDate?: Moment;
  currentUserTheme?: boolean;
}

const getColorCode = (stock: number, stockMin: number): boolean => {
  if (stock && stockMin && stock <= stockMin) {
    return true;
  }

  return false;
};

const getDDMMDate = (date: string) => {
  const dateParts: string[] = date.split('-');

  if (dateParts.length === 3) {
    return `${dateParts[2]}/${dateParts[1]}`;
  }

  return date;
};

const formatKt = (amount: number): string => (amount ? `${amount.toFixed(2)}Kt` : '0');

export const ProductGroup: React.FC<ProductGroupProps> = ({ stockList, filterDate, currentUserTheme }) => {
  const stockForSelectedDate = stockList.find(sm => sm.date === filterDate?.format(YYYYMMDD));
  let stock = 0;
  let ullage = 0;
  let stockMin = 0;
  let ullageMin = 0;
  let stockPercentage = 0;
  let isStockUllagePresent = false;

  if (stockForSelectedDate !== undefined) {
    ({ stock, ullage, stockMin, ullageMin, stockPercentage, isStockUllagePresent } = stockForSelectedDate);
  }

  return (
    <>
      <MainProductGroupContainer data-testid="MainProductGroupContainer" currentUserTheme={currentUserTheme}>
        <Grid>
          <Inner>
            <Row>
              <Col size={2}>
                <Row>
                  <Typography
                    data-testid="group-name"
                    color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                    variant="body4"
                    bold={true}
                  >
                    Bitumen
                  </Typography>
                </Row>
                <Row>
                  <Typography
                    data-testid="group-name"
                    color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                    variant="body4"
                  >
                    {filterDate != null ? filterDate.format('DD/MM') : ''}
                  </Typography>
                </Row>
              </Col>
              <Spaceinbetween>
                <Col size={2}>
                  <Row>
                    <Typography
                      data-testid="group-name"
                      color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                      variant="body3"
                    >
                      Stock
                    </Typography>
                  </Row>
                  <Row>
                    <Typography
                      data-testid="group-name"
                      color={stock <= stockMin ? 'errorLight' : 'textBlue'}
                      variant="body3"
                    >
                      {isStockUllagePresent ? (formatKt(stock) === '0' ? formatKt(stock) + 'Kt' : formatKt(stock)) : ''}
                    </Typography>
                  </Row>
                </Col>
              </Spaceinbetween>

              <Col size={6} data-testid="bar-column">
                <Row data-testid="barrow">
                  <MainBarWrapper data-testid="barwrapper">
                    {isStockUllagePresent ? (
                      <BarOuter isPiStock={true} currentUserTheme={currentUserTheme}>
                        <BarInner currentUserTheme={currentUserTheme}>
                          <BarProgress percentage={stockPercentage} redColorcoding={getColorCode(stock, stockMin)} />
                        </BarInner>
                      </BarOuter>
                    ) : (
                      <Col size={12}>
                        {currentUserTheme ? (
                          <NoInfoLightTheme style={{ marginLeft: '15px' }}>No Info</NoInfoLightTheme>
                        ) : (
                          <NoInfo style={{ marginLeft: '15px' }}>No Info</NoInfo>
                        )}
                      </Col>
                    )}
                  </MainBarWrapper>
                </Row>
              </Col>

              <Col size={2}>
                <Row>
                  <Typography
                    data-testid="group-name"
                    color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                    variant="body3"
                  >
                    Ullage
                  </Typography>
                </Row>
                <Row>
                  <Typography
                    data-testid="group-name"
                    color={ullage < ullageMin ? 'errorLight' : currentUserTheme ? 'bodyLight' : 'bodyDark'}
                    variant="body3"
                  >
                    {isStockUllagePresent ? formatKt(ullage) : ''}
                  </Typography>
                </Row>
              </Col>
            </Row>
          </Inner>
        </Grid>
      </MainProductGroupContainer>

      {stockList
        ?.filter(sm => sm.date !== filterDate?.format('YYYY-MM-DD'))
        .map((item: LocationProductStockModel, i: string | number | undefined) => {
          return (
            <div key={i}>
              <SubProductGroupContainer data-testid="SubProductGroupContainer">
                <Grid>
                  <Inner>
                    <Row>
                      <Col size={2}>
                        <Row>
                          <Typography
                            data-testid="group-name"
                            color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
                            variant="body4"
                          >
                            {getDDMMDate(item.date)}
                          </Typography>
                        </Row>
                      </Col>
                      {item.isStockUllagePresent ? (
                        <>
                          <Spaceinbetween>
                            <Col size={2}>
                              <Row>
                                <Typography
                                  data-testid="group-name"
                                  color={item.stock <= item.stockMin ? 'errorLight' : 'textBlue'}
                                  variant="body3"
                                >
                                  {formatKt(item.stock) === '0' ? formatKt(item.stock) + 'Kt' : formatKt(item.stock)}
                                </Typography>
                              </Row>
                            </Col>
                          </Spaceinbetween>
                          <Col size={6} data-testid="bar-column">
                            <Row data-testid="barrow">
                              <BarWrapper data-testid="barwrapper">
                                <BarOuter isPiStock={true} currentUserTheme={currentUserTheme}>
                                  <BarInner currentUserTheme={currentUserTheme}>
                                    <BarProgress
                                      percentage={item.stockPercentage}
                                      redColorcoding={getColorCode(item.stock, item.stockMin)}
                                    />
                                  </BarInner>
                                </BarOuter>
                              </BarWrapper>
                            </Row>
                          </Col>
                          <Col size={2}>
                            <Row>
                              <Typography
                                data-testid="group-name"
                                color={
                                  item.ullage < item.ullageMin
                                    ? 'errorLight'
                                    : currentUserTheme
                                    ? 'bodyLight'
                                    : 'bodyDark'
                                }
                                variant="body3"
                              >
                                {formatKt(item.ullage)}
                              </Typography>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        <Col size={10}>
                          {currentUserTheme ? <NoInfoLightTheme>No Info</NoInfoLightTheme> : <NoInfo>No Info</NoInfo>}
                        </Col>
                      )}
                    </Row>
                  </Inner>
                </Grid>
              </SubProductGroupContainer>
            </div>
          );
        })}
    </>
  );
};
