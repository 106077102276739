import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useRouteMatch } from 'react-router-dom';
import { URLS } from '../../constants';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { usePermission } from '../../hooks/usePermission';
import { Permission } from '../../types';
import { CurrentUserQuery } from '@scout/types';

interface CurrentUserProp {
  currentUser: CurrentUserQuery['currentUser'];
}

type RouteComponentPropsWithCurrentUser =
  // We need to `any` as Params will be different every route
  // tslint:disable-next-line
  React.ComponentType<RouteComponentProps<any> & CurrentUserProp> | React.ComponentType<CurrentUserProp>;

type Props = RouteProps & {
  component: RouteComponentPropsWithCurrentUser;
  anyOfPermissions?: Permission[];
};

const PrivateRoute: React.FC<Props> = ({ component: Component, anyOfPermissions, ...rest }) => {
  const currentUser = useCurrentUser();
  const { hasAnyOf } = usePermission();

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (anyOfPermissions != null) {
          if (!hasAnyOf(anyOfPermissions)) {
            return <Redirect to={URLS.ROOT} />;
          }
        }

        return <Component {...routeProps} currentUser={currentUser} />;
      }}
    />
  );
};

export { PrivateRoute };
