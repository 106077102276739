import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../components/Typography';

const Line = styled.div`
  margin-top: 5px;
  background-color: ${p => p.theme.primary.b};
  height: 3px;
  width: 35px;
`;

export const HeaderTitle: React.FC<{ text: string; currentUserTheme?: boolean }> = ({ text, currentUserTheme }) => (
  <Typography tag="div" variant="heading6" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
    {text}
    <Line />
  </Typography>
);
