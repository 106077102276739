import React from 'react';
import { withTheme } from 'styled-components';

export const DeleteIcon: React.FC = withTheme(({ theme }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 4.5H19V6.5H5V4.5H8.5L9.5 3.5H14.5L15.5 4.5ZM8 21.5C6.9 21.5 6 20.6 6 19.5V7.5H18V19.5C18 20.6 17.1 21.5 16 21.5H8Z"
      fill={theme.base.dark.c}
    />
  </svg>
));
