import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserThemeContext } from '../../../../App';
import { Modal } from '../../../../components/Modal';
import { Typography } from '../../../../components/Typography';

const Spacer = styled.div`
  margin: 10px 0;
`;

interface Props {
  onDismiss?: () => void;
  onConfirm?: () => void;
  isOpen: boolean;
}

const HeaderCloseModal: React.FunctionComponent<Props> = ({ isOpen, onDismiss, onConfirm }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  return (
    <Modal
      minWidth={320}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onConfirm={onConfirm}
      confirmText="Close"
      dismissText="Cancel"
      title="Are you sure you want to close this contract?"
      currentUserTheme={currentUserTheme}
    >
      <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center" variant="body3">
        You will lose all currently entered information and return to the New Contracts page.
      </Typography>

      <Spacer>
        <Typography bold={true} color={currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center" variant="body3">
          Your demands will not be saved.
        </Typography>
      </Spacer>
    </Modal>
  );
};

export { HeaderCloseModal };
