import { FormikProps } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { Col, Grid, Row } from '../../../../components/Grid';
import {
  GET_ALL_TRANSPORT_COSTS,
  GetAllTransportCostsQueryVariables,
  GetAllTransportCostsQuery,
  ShipmentType,
} from '@scout/types';
import { SpotFormValuesProps } from '../Form';
import { Cluster } from '../../../../types';
import { TransportCostsTable } from './TransportCostsTable';
import { Loader } from '../../../../components/Loader';
import { SingleDropdown, DropdownItemOptions } from '../../../../components/SelectDropdown/Single';
import { getMonths, RangeValues } from '../../../../components/MonthRangeSelect/MonthRangeSelect';
import { getDates } from '../../../../components/NetsimModal';
import { Typography } from '../../../../components/Typography';
import { filterByLabel } from '../../../../utils';
import { GradeValue } from '../types';

interface TransportCostsContainerProps {
  isActive: boolean;
  formProps: FormikProps<SpotFormValuesProps>;
  cluster: Cluster;
  initialValues?: SpotFormValuesProps;
  customerId?: string;
  shipmentType: ShipmentType;
  date: moment.Moment;
}

const Label = styled(Typography).attrs({ variant: 'body3' })`
  margin-bottom: 5px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FilterItem = styled.div`
  flex-basis: 20%;
`;

const Centered = styled.div`
  margin-top: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const TransportCostsContainer: React.FunctionComponent<TransportCostsContainerProps> = ({
  isActive,
  formProps,
  cluster,
  shipmentType,
  ...props
}) => {
  const [plants, setPlants] = useState<DropdownItemOptions[]>([]);
  const [plantFilter, setPlantFilter] = useState<DropdownItemOptions>();
  const [dateFilter, setDateFilter] = useState<DropdownItemOptions>({
    label: props.date.format('MMM YYYY'),
    value: props.date.startOf('month').format('ddd MMM DD YYYY'),
  });
  const TransportModeMap: { [key in ShipmentType]: string } = {
    [ShipmentType.Pickup]: '10',
    [ShipmentType.Delivery]: '11',
  };
  const { data, loading, error } = useQuery<GetAllTransportCostsQuery, GetAllTransportCostsQueryVariables>(
    GET_ALL_TRANSPORT_COSTS,
    {
      skip: !isActive,
      variables: {
        clusterId: cluster.id ?? '',
        customerId: props.customerId ?? '',
        dateFrom: moment
          .utc(dateFilter.value!)
          .startOf('month')
          .toISOString(),
        dateTo: moment
          .utc(dateFilter.value!)
          .endOf('month')
          .toISOString(),
      },
    },
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    const plantSet = new Set();
    const plantArr: DropdownItemOptions[] = [];
    data.getAllTransportCosts.forEach(transportCost => {
      if (!plantSet.has(transportCost.plantId)) {
        plantArr.push({
          label: transportCost.plantId,
          value: transportCost.plantId,
        });
        plantSet.add(transportCost.plantId);
      }
    });
    setPlants(plantArr);
  }, [data]);

  const gradesMap = useMemo(
    () =>
      Object.values(Object.assign({}, ...Object.values(formProps.values.grades)) as GradeValue[])
        .filter(grade => grade.percentage.length !== 0)
        .reduce((map, grade) => {
          map[grade.name] = true;
          return map;
        }, {} as { [key: string]: boolean }),
    [formProps.values.grades],
  );

  if (!isActive) {
    return null;
  }

  let filteredData = data?.getAllTransportCosts;
  if (filteredData) {
    filteredData = filteredData.filter(
      transportCost =>
        !!gradesMap[transportCost.stream] &&
        (plantFilter ? transportCost.plantId === plantFilter.value : true) &&
        transportCost.modeOfTransport === TransportModeMap[shipmentType],
    );
  }

  return (
    <Grid>
      <Row>
        <Col size={12}>
          <FilterContainer>
            <FilterItem>
              <Label>Date Range</Label>
              <SingleDropdown
                disabled={loading}
                darkTheme={false}
                showValidation={false}
                name="dateRange"
                placeholder={dateFilter.label || undefined}
                showClearIndicator={false}
                items={getMonths({
                  type: RangeValues.From,
                  selectedMonth: { value: RangeValues.From, label: RangeValues.From },
                  monthItems: getDates(),
                })}
                onItemClick={item => {
                  setDateFilter(item);
                }}
                selected={dateFilter.value}
                filterOption={filterByLabel}
              />
            </FilterItem>
            <FilterItem>
              <Label>Plant</Label>
              <SingleDropdown
                disabled={loading}
                darkTheme={false}
                showValidation={false}
                name="plant"
                placeholder="All"
                showClearIndicator={false}
                items={plants}
                onItemClick={item => {
                  setPlantFilter(item);
                }}
                selected={plantFilter?.value || null}
                filterOption={filterByLabel}
              />
            </FilterItem>
          </FilterContainer>
        </Col>
      </Row>
      <Row>
        <Col size={12}>
          {loading ? (
            <Centered>
              <Loader type="section" />
            </Centered>
          ) : filteredData?.length ? (
            <TransportCostsTable items={filteredData || []} />
          ) : (
            <Centered>
              <Typography variant="heading2" bold={true} color="hintLight">
                No Transport Options Available!
              </Typography>
            </Centered>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export { TransportCostsContainer };
