import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Row } from '../../../components/Grid';
import { TankMetaIcon } from '../../../components/Icons/TankMetaIcon';
import { Tooltip } from '../../../components/Tooltip';
import { Typography } from '../../../components/Typography';
import { TankModel, LocationProductStockModel } from '@scout/types';
import { GroupHeader } from './GroupHeader';
import { formatKt, getStockInfoGroups, stockInfoSorter, TankGroupOrder, PlantIds, PlantRegions } from './helpers';
import { NoInfoPanel } from './NoInfoPanel';
import { calculateUllage, TankInfo } from './TankInfo';
import { TankMetaData } from './TankMeta';
import { Tabs } from '../../../components/Tabs';
import { StockTabOptionId, StockTabOptionLabel } from './types';
import { ProductGroup } from './ProductGroup';
import { LocationTypes } from '../../../constants';

const Body = styled.div`
  overflow-y: auto;
  padding: 22px;
  height: calc(100vh - 135px);
`;

const Footer = styled.div`
  margin-bottom: 100px;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  justify-content: space-between;
`;

const TotalLabel = styled(Typography).attrs(props => ({
  variant: 'labels1',
  color: props.currentUserTheme ? 'bodyLight' : 'bodyDark',
}))<{ currentUserTheme?: boolean }>`
  margin-right: 8px;
  margin-bottom: 14px;
`;

const TooltipIcon = styled.div`
  position: relative;
`;

const StockGroup = styled.div`
  min-width: 360px;
  margin-left: auto;
  margin-right: auto;
`;

export interface StockInfoProps {
  isPiStock: boolean;
  maxVolume?: number | null;
  openingSpecification?: string | null;
  openingAmount?: number | null;
  meta?: TankMetaData[];
}

export interface StockInfoValue {
  code: string;
  data: StockInfoProps | null;
}

export interface StockInfoGroups {
  [group: string]: StockInfoValue[];
}

export const calculateTotalMaxCapacity = (stockInfo: StockInfoValue[]) =>
  stockInfo.reduce((sum, stockInfo) => {
    if (!stockInfo.data || !stockInfo.data.maxVolume) {
      return sum;
    }
    return sum + stockInfo.data.maxVolume;
  }, 0);

// need
export const calculateTotalStock = (stockInfo: StockInfoValue[]) =>
  stockInfo.reduce((sum, stockInfo) => {
    if (!stockInfo.data || !stockInfo.data.openingAmount) {
      return sum;
    }

    return sum + stockInfo.data.openingAmount;
  }, 0);
// need
export const calculateTotalUllage = (stockInfoValue: StockInfoValue[]) =>
  stockInfoValue.reduce((sum, stockInfo) => {
    if (!stockInfo.data) {
      return sum;
    }

    const ullage = calculateUllage({
      maxVolume: stockInfo.data.maxVolume,
      openingAmount: stockInfo.data.openingAmount,
    });

    if (!ullage) {
      return sum;
    }

    return sum + ullage;
  }, 0);

export interface StocksPanelProps {
  plantId: PlantIds;
  region: PlantRegions;
  plantType?: LocationTypes;
  tanks: TankModel[];
  stockList: LocationProductStockModel[];
  nearestStockDates: string[];
  onDateChange: (moment: Moment) => void;
  filterDate?: Moment;
  currentUserTheme?: boolean;
}

const tabs = [
  {
    id: StockTabOptionId.Tank,
    label: StockTabOptionLabel.TankLabel,
  },
  {
    id: StockTabOptionId.Product,
    label: StockTabOptionLabel.ProductLabel,
  },
];

const StocksPanel: React.FunctionComponent<StocksPanelProps> = ({
  plantId,
  region,
  plantType,
  tanks,
  stockList,
  nearestStockDates,
  onDateChange,
  filterDate,
  currentUserTheme,
}) => {
  const stockInfosGroups = getStockInfoGroups(tanks);
  const stockInfosGroupsKeys = Object.keys(stockInfosGroups).sort((g1, g2) => TankGroupOrder[g1] - TankGroupOrder[g2]);
  const stockInfosGroupsValues = Object.values(stockInfosGroups);

  const totalStock = stockInfosGroupsValues
    .map(stockInfoValue => calculateTotalStock(stockInfoValue))
    .reduce((sum, value) => sum + value, 0);

  const totalUllage = stockInfosGroupsValues
    .map(stockInfoValue => calculateTotalUllage(stockInfoValue))
    .reduce((sum, value) => sum + value, 0);

  const isEmptyPanel = stockInfosGroupsValues.every(group =>
    group.every(g => !g.data || !g.data.openingAmount || g.data === undefined),
  );
  const [activeTab, setActiveTab] = React.useState(
    plantType === LocationTypes.Refinery ? StockTabOptionId.Tank : StockTabOptionId.Product,
  );

  return (
    <Body data-testid="stock-container">
      {region === PlantRegions.EUROPE ? (
        <>
          <Row>
            <TotalLabel currentUserTheme={currentUserTheme}>Total capacity</TotalLabel>
            <Tooltip
              styles={{ marginTop: 10, marginLeft: -5 }}
              body={
                <TooltipContainer data-testid="total-tooltip">
                  <Typography variant="labels1">Total stock</Typography>
                  <Typography variant="labels1" bold={true} data-testid="total-stock">
                    {formatKt(totalStock)}
                  </Typography>
                  <Typography variant="labels1">Total Ullage</Typography>
                  <Typography variant="labels1" bold={true} data-testid="total-ullage">
                    {formatKt(totalUllage)}
                  </Typography>
                </TooltipContainer>
              }
            >
              <TooltipIcon data-testid="total-icon">
                <TankMetaIcon />
              </TooltipIcon>
            </Tooltip>
          </Row>
          {stockInfosGroupsKeys.map(key => {
            const isEmptyGroup = stockInfosGroups[key].every(group => !group.data);

            if (isEmptyGroup) {
              return null;
            }

            const totalStock = calculateTotalStock(stockInfosGroups[key]);
            const totalUllage = calculateTotalUllage(stockInfosGroups[key]);

            return (
              <StockGroup key={key} data-testid="stock-group">
                <GroupHeader
                  plantId={plantId}
                  text={key}
                  totalStock={totalStock}
                  totalUllage={totalUllage}
                  currentUserTheme={currentUserTheme}
                />
                {stockInfoSorter(stockInfosGroups[key]).map((stockInfo: StockInfoValue, index) => (
                  <TankInfo
                    key={index}
                    code={stockInfo.code}
                    data={stockInfo.data}
                    currentUserTheme={currentUserTheme}
                  />
                ))}
              </StockGroup>
            );
          })}
        </>
      ) : (
        <>
          {plantType === LocationTypes.Refinery ? (
            <Row>
              <Tabs
                items={tabs}
                selected={activeTab}
                onItemClick={(id: string) => {
                  setActiveTab(id as StockTabOptionId);
                }}
                isEast={true}
                children=""
                currentUserTheme={currentUserTheme}
              />
            </Row>
          ) : (
            <></>
          )}
          <Row>
            {activeTab === StockTabOptionId.Tank ? (
              isEmptyPanel ? (
                <NoInfoPanel
                  nearestStockDates={nearestStockDates}
                  onDateChange={date => onDateChange(moment(date))}
                  currentUserTheme={currentUserTheme}
                />
              ) : (
                stockInfosGroupsKeys.map(key => {
                  const isEmptyGroup = stockInfosGroups[key].every(group => !group.data);

                  if (isEmptyGroup) {
                    return null;
                  }

                  const totalStock = calculateTotalStock(stockInfosGroups[key]);
                  const totalUllage = calculateTotalUllage(stockInfosGroups[key]);

                  return (
                    <StockGroup key={key} data-testid="stock-group">
                      <GroupHeader
                        plantId={plantId}
                        text={key}
                        totalStock={totalStock}
                        totalUllage={totalUllage}
                        currentUserTheme={currentUserTheme}
                      />
                      {stockInfoSorter(stockInfosGroups[key]).map((stockInfo: StockInfoValue, index) => (
                        <TankInfo
                          key={index}
                          code={stockInfo.code}
                          data={stockInfo.data}
                          currentUserTheme={currentUserTheme}
                        />
                      ))}
                    </StockGroup>
                  );
                })
              )
            ) : (
              <StockGroup data-testid="stock-group">
                <ProductGroup stockList={stockList} filterDate={filterDate} currentUserTheme={currentUserTheme} />
              </StockGroup>
            )}
          </Row>
        </>
      )}

      <Footer />
    </Body>
  );
};

export { StocksPanel };
