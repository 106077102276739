import React from 'react';

const useHover = () => {
  const [hovered, set] = React.useState(false);
  const hoverEvents = {
    onMouseEnter: () => set(true),
    onMouseLeave: () => set(false),
  };
  return [hovered, hoverEvents];
};

export { useHover };
