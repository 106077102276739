import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from '../../../components/Loader';
import { useToast } from '../../../components/Toast/Toast';
import {
  DELETE_CONTRACT_FAILURE_MESSAGE,
  DELETE_CONTRACT_SUCCESS_MESSAGE,
  urlPartToContractDealType,
} from '../../../constants';
import {
  CREATE_CONTRACT_DEMAND_MUTATION,
  GET_CONTRACT_DEMAND_CREATE_DATA,
  Cluster,
  Contract,
  ContractDealType,
  CreateContractDemandMutation,
  CreateContractDemandMutationVariables,
  GetContractDemandCreateDataQuery,
  GetContractDemandCreateDataQueryVariables,
  DELETE_CONTRACT_DEMAND,
  DeleteContractDemandMutation,
  DeleteContractDemandMutationVariables,
  Plant,
} from '@scout/types';
import { createNewContractsLink } from '../../../utils/links';
import { ContractDemandSteps } from './Steps';
import { ContractDemandState, Demand } from './types';
import styled from 'styled-components';
import { MdWarning } from 'react-icons/md';
import { Typography } from '../../../components/Typography';
import { RequirePermission } from '../../../components/RequirePermission';
import { Container } from '../../../components/Container';
import { DeleteIcon } from '../../../components/Icons/DeleteIcon';
import { Modal } from '../../../components/Modal';
import { makeReturnURL } from '../SpotForm/url';
import { UserThemeContext } from '../../../App';

const Icon = MdWarning;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const DeleteRoot = styled.div`
  background: ${props => props.theme.base.light.b};
  margin-bottom: 70px;
`;

const DeleteLink = styled(Typography)<{ onClick: () => void }>`
  border-top: 1px solid ${props => props.theme.greys.light.border};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 18px 0;
  margin-bottom: 100px;
`;
const DeleteBorder = styled.div`
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 10px 6px;
`;
type Props = RouteComponentProps<{ clusterId: string; contractType: string; dealId: string }>;

interface CreateInitialStateInput {
  demandLocation: Cluster['demandLocation'];
  contract: Pick<Contract, 'shipmentType' | 'hasPremiumPlant' | 'totalVolume'> & {
    plant: Pick<Plant, 'id' | 'shortName'>;
  };
}

const createInitialState = ({ demandLocation, contract }: CreateInitialStateInput): ContractDemandState => {
  const baseDemand: Demand = {
    id: uuidv4(),
    gradeSplit: [],
    isFixed: true,
    isPremium: contract.hasPremiumPlant,
    monthlyVolumes: {},
    plant: contract.plant,
    totalVolume: 0,
  };

  return {
    demands: [baseDemand],
    shipmentType: contract.shipmentType,
    demandLocation,
  };
};
const CreateContractDemand: React.FC<Props> = ({ match, history }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const [createDemand] = useMutation<CreateContractDemandMutation, CreateContractDemandMutationVariables>(
    CREATE_CONTRACT_DEMAND_MUTATION,
  );
  const { push } = useToast();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { clusterId, dealId } = match.params;
  const contractType = urlPartToContractDealType(match.params.contractType);

  const searchParams = new URLSearchParams(history.location.search);
  const from = searchParams.get('from');
  const [deleteDemand] = useMutation<DeleteContractDemandMutation, DeleteContractDemandMutationVariables>(
    DELETE_CONTRACT_DEMAND,
  );
  const { data, error, loading } = useQuery<
    GetContractDemandCreateDataQuery,
    GetContractDemandCreateDataQueryVariables
  >(GET_CONTRACT_DEMAND_CREATE_DATA, {
    fetchPolicy: 'no-cache',
    skip: !contractType,
    variables: {
      clusterId,
      dealId,
      type: contractType as ContractDealType,
    },
  });

  // We use this when the user cancels and also after a successful submit
  const redirectUrl =
    from ??
    createNewContractsLink({
      clusterId,
      viewerId: clusterId,
      viewerType: 'Cluster',
    });

  const handleCancel = useCallback(() => history.push(redirectUrl), [history, redirectUrl]);

  const handleSubmit = useCallback(
    async (values: ContractDemandState) => {
      if (contractType == null || data == null || data.contract == null) {
        push({
          text: 'Something went wrong, try again',
          size: 'SMALL',
          type: 'ERROR',
        });
        return;
      }

      const payload = {
        shipmentType: values.shipmentType,
        contractId: data.contract.id,
        contractType,
        clusterId,
        ownedById: data.contract.ownedBy.id,
        demands: values.demands.map(demand => ({
          gradeSplit: demand.gradeSplit.map(split => ({
            gradeId: split.grade.id,
            percentage: split.percentage,
          })),
          plantId: demand.plant.id,
          isPremium: !!demand.isPremium,
          isBaseDemand: demand.isFixed,
          regionId: demand.region ? demand.region.id : undefined,
          shipToId: demand.shipTo ? demand.shipTo.id : undefined,
          ...demand.monthlyVolumes,
        })),
      };

      try {
        await createDemand({
          variables: {
            input: payload,
          },
        });

        history.push(redirectUrl);
      } catch (error) {
        push({
          text: 'Something went wrong, try again',
          size: 'SMALL',
          type: 'ERROR',
        });
      }
    },
    [data, contractType, clusterId, createDemand, history, redirectUrl, push],
  );

  const onDelete = async () => {
    if (data?.cluster?.id === null) {
      return;
    }
    const input = {
      contractDemandID: null,
      contractDemandGroupID: null,
      fixedContractID: data?.contract?.stascoId,
      formulaContractID: data?.contract?.dealId,
    };
    try {
      await deleteDemand({
        variables: {
          input,
        },
      });

      push({
        text: DELETE_CONTRACT_SUCCESS_MESSAGE,
      });
      if (data?.cluster?.id) {
        const url = makeReturnURL({ from, currentClusterId: data?.cluster?.id });
        history.push(url);
      }
    } catch (error) {
      push({
        text: DELETE_CONTRACT_FAILURE_MESSAGE,
        type: 'ERROR',
      });
    }
  };

  if (loading || error != null) {
    return <Loader error={error != null ? 'Error retrieving contract' : undefined} />;
  }

  if (contractType == null || data == null || data.contract == null || data.cluster == null) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <>
      <Modal
        title="Delete demand"
        onConfirm={onDelete}
        confirmBtnVariant="danger"
        onDismiss={() => setDeleteModal(false)}
        isOpen={deleteModal}
        minWidth={550}
        confirmText="Delete"
        currentUserTheme={currentUserTheme}
      >
        <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="heading6" align="center">
          Are you sure you want to delete this demand?
        </Typography>
        <br />
        <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="heading6" align="center">
          Reminder! Ensure the same demand is deleted at Jupiter.
        </Typography>
      </Modal>
      <ContractDemandSteps
        ownerName={data.contract.ownedBy.emailAddress}
        contract={data.contract}
        currency={data.cluster.currency}
        demandLocation={data.cluster.demandLocation}
        initialValues={createInitialState({ demandLocation: data.cluster.demandLocation, contract: data.contract })}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        penPlants={data.cluster.penPlants}
        premiumPlants={data.cluster.premiumPlants}
        regions={data.cluster.regions}
        clusterId={clusterId}
      />
      <RequirePermission anyOf={['deleteDemandForecast']}>
        <DeleteRoot>
          <Container>
            <DeleteLink tag="a" onClick={() => setDeleteModal(true)}>
              <DeleteBorder>
                <DeleteIcon />
                <Typography tag="span" inline={true}>
                  Delete Demand
                </Typography>
              </DeleteBorder>
            </DeleteLink>
          </Container>
        </DeleteRoot>
      </RequirePermission>
    </>
  );
};

export { CreateContractDemand };
