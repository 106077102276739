import styled, { CSSProperties } from 'styled-components';

const Separator = styled.hr<{
  type?: CSSProperties['borderStyle'];
}>`
  border: none;
  border-top: 1px ${({ type }) => type ?? 'solid'} ${({ theme }) => theme.greys.light.disabled};
  width: 100%;
`;

export { Separator };
