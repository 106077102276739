import { FastField, FieldProps, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { UserThemeContext } from '../../../../App';
import { Modal } from '../../../../components/Modal';
import { MonthItem, MonthRangeSelect, SelectedItem } from '../../../../components/MonthRangeSelect/MonthRangeSelect';
import { Select } from '../../../../components/Select/Select';
import { Typography } from '../../../../components/Typography';
import { Cluster, VolumePricingItem } from '../../../../types';
import { TextField } from '../TextField';
import { Distribution } from '../types';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const VolumeTextField = styled(TextField)`
  width: 200px;
  margin-bottom: 30px;
`;

const Label = styled(Typography).attrs(props => ({
  variant: 'body3',
  color: props.currentUserTheme ? 'bodyLight' : 'bodyDark',
}))<{
  currentUserTheme?: boolean;
}>`
  margin: 0 0 5px;
`;

const FieldContainer = styled.div`
  padding-bottom: 2rem;
`;

export const INCOMPLETE_DATE_RANGE_ERROR = 'Please enter a complete date range to distribute volume.';
const REQUIRED_FIELD_ERROR = 'This is a required field';

const getValidationSchema = () => ({
  isNotUKCluster: Yup.boolean(),
  volume: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD_ERROR),
  dateRange: Yup.object().shape({
    from: Yup.object()
      .nullable()
      .required(INCOMPLETE_DATE_RANGE_ERROR),
    to: Yup.object()
      .nullable()
      .required(INCOMPLETE_DATE_RANGE_ERROR),
  }),
  distribution: Yup.string()
    .nullable()
    .when('isNotUKCluster', {
      is: true,
      then: Yup.string()
        .nullable()
        .required(REQUIRED_FIELD_ERROR),
    }),
});

const distributions = [
  { value: Distribution.Seasonal, label: Distribution.Seasonal },
  { value: Distribution.Even, label: Distribution.Even },
];

export interface DistributeVolumeFieldProps {
  volume: number | '';
  distribution: string | null;
  dateRange: SelectedItem;
  isNotUKCluster: boolean;
}

export interface Props {
  cluster: Cluster;
  isOpen: boolean;
  volumeItems: VolumePricingItem[];
  onSubmit: (values: DistributeVolumeFieldProps) => void;
  onCancel: () => void;
}

export const DistributeVolumeModal: React.FunctionComponent<Props> = ({
  isOpen,
  onSubmit,
  onCancel,
  volumeItems,
  cluster,
}) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const initialValues: DistributeVolumeFieldProps = {
    volume: '',
    distribution: Distribution.Even,
    dateRange: { from: null, to: null },
    isNotUKCluster: !cluster.isUKIrelandCluster,
  };

  const monthItems = useMemo(
    () =>
      volumeItems
        .filter(item =>
          moment(item.date)
            .startOf('month')
            .isSameOrAfter(new Date(), 'month'),
        )
        .map<MonthItem>(i => ({
          date: i.date,
          readonly: i.readonly,
        })),
    [volumeItems],
  );

  return (
    <Formik
      validationSchema={Yup.object().shape(getValidationSchema())}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ submitForm, values }) => {
        return (
          <Modal
            data-testid="distribute-modal"
            title="Distribute volume"
            onConfirm={submitForm}
            onDismiss={onCancel}
            isOpen={isOpen}
            minWidth={500}
            confirmText="Distribute volume"
            currentUserTheme={currentUserTheme}
          >
            <StyledForm>
              <VolumeTextField
                themeStyle={currentUserTheme ? 'light' : 'dark'}
                alignVertically={true}
                name="volume"
                label="Total volume"
                placeholder="0"
                suffix="t"
              />
              <FieldContainer>
                <Label currentUserTheme={currentUserTheme}>Forecast months</Label>
                <FastField
                  name="dateRange"
                  render={({ field, form }: FieldProps<DistributeVolumeFieldProps>) => {
                    return (
                      <MonthRangeSelect
                        monthItems={monthItems}
                        onItemClick={id => {
                          form.setFieldValue(field.name, id);
                        }}
                        selected={field.value}
                        error={
                          form.touched.dateRange &&
                          form.errors.dateRange &&
                          (form.errors.dateRange.from || form.errors.dateRange.to)
                        }
                      />
                    );
                  }}
                />
              </FieldContainer>
              {values.isNotUKCluster && (
                <>
                  <Label currentUserTheme={currentUserTheme}>Distribution method</Label>
                  <FastField
                    name="distribution"
                    render={({ field, form }: FieldProps<DistributeVolumeFieldProps>) => (
                      <Select
                        onItemClick={id => form.setFieldValue(field.name, id)}
                        selected={field.value}
                        items={distributions}
                        error={form.touched.distribution ? form.errors.distribution : undefined}
                        themeStyle={currentUserTheme ? 'light' : 'dark'}
                      />
                    )}
                  />
                </>
              )}
            </StyledForm>
          </Modal>
        );
      }}
    </Formik>
  );
};
