import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '../../components/Typography';
import { URLS } from '../../constants';
import { DemandForecastingIcon } from '../../components/Icons/DemandForecastingIcon';
import { PriceForecastingIcon } from '../../components/Icons/PriceForecastingIcon';
import { InMonthPlanningIcon } from '../../components/Icons/InMonthPlanningIcon';
import { ReportLandingIcon } from '../../components/Icons/ReportLandingIcon';
import { HistoricalDataAnalysisIcon } from '../../components/Icons/HistoricalDataAnalysisIcon';
import { Arrow } from '../../components/Icons/Arrow';
import { RequirePermission } from '../../components/RequirePermission';
import { DefaultLayout } from '../../layouts';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 82px);
  background-color: ${p => p.theme.base.dark.a};
`;

const BoxContainer = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(307.9deg, ${p => p.theme.base.dark.a} 19.6%, ${p => p.theme.base.dark.b} 89.96%);
  border: 1px solid ${p => p.theme.base.dark.a};
  width: 300px;
  height: 200px;
  cursor: pointer;
  margin: 11px 11px;
  border-radius: 2px;
  box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.15);
  text-decoration: none;

  :hover {
    border: 1px solid ${p => p.theme.primary.b};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  background: ${p => p.theme.base.dark.c};
  width: 100%;
  padding: 0 18px;

  ${BoxContainer}:hover & {
    background: ${p => p.theme.primary.b};
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 152px;
`;

interface BoxProps {
  children: React.ReactNode;
  icon: React.ComponentType;
  url: LinkProps['to'];
}

export const Box: React.FC<BoxProps> = ({ children, icon: Icon, url }) => (
  <BoxContainer to={url}>
    <IconContainer>
      <Icon />
    </IconContainer>
    <LabelContainer>
      <Typography bold={true} color="bodyDark">
        {children}
      </Typography>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    </LabelContainer>
  </BoxContainer>
);

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
  padding: 0 15px;
`;

export const Landing: React.FC = () => {
  const currentUser = useCurrentUser();

  return (
    <DefaultLayout>
      <Container>
        <Typography variant="body1" color="bodyDark">
          Select application:
        </Typography>
        <Row>
          {currentUser.clusters.length > 0 && (
            <RequirePermission anyOf={['viewDemandForecast']}>
              <Box icon={DemandForecastingIcon} url={URLS.MID_TERM}>
                Demand forecasting
              </Box>
            </RequirePermission>
          )}
          <RequirePermission anyOf={['viewPricing']}>
            <Box icon={PriceForecastingIcon} url={URLS.PRICING}>
              Price forecasting
            </Box>
          </RequirePermission>
          <RequirePermission anyOf={['viewFleetManagement', 'viewStockManagement']}>
            <Box icon={InMonthPlanningIcon} url={URLS.IN_MONTH}>
              Europe In-month planning
            </Box>
          </RequirePermission>
          <RequirePermission anyOf={['viewEastSchedulingManager', 'eastDepotUser']}>
            <Box icon={InMonthPlanningIcon} url={URLS.EAST_IN_MONTH}>
              East In-month Planning
            </Box>
          </RequirePermission>
          <Box icon={ReportLandingIcon} url={URLS.REPORTS_LANDING}>
            Scout Dashboard - Power BI
          </Box>
          <RequirePermission anyOf={['marineTrafficExportManagement']}>
            <Box icon={HistoricalDataAnalysisIcon} url={URLS.HISTORICAL_DATA_ANALYSIS}>
              Historical Data Analysis
            </Box>
          </RequirePermission>
        </Row>
      </Container>
    </DefaultLayout>
  );
};
