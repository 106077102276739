import { ApolloError } from 'apollo-boost';
import React from 'react';
import { Loader } from '../../../../components/Loader';
import { LocationViewDataQuery } from '@scout/types';
import { LoaderContainer } from './Table';

export const TableLoader: React.FC<{
  data?: LocationViewDataQuery;
  loading: boolean;
  error?: ApolloError;
}> = ({ data, loading, error }) => {
  if (loading || error || !data) {
    return (
      <LoaderContainer>
        <Loader type="section" error={error ? 'Something went wrong' : undefined} />
      </LoaderContainer>
    );
  }
  return null;
};
