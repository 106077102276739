import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const DemandForecastingIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M40 15L45.725 20.725L33.525 32.925L23.525 22.925L5 41.475L8.525 45L23.525 30L33.525 40L49.275 24.275L55 30V15H40Z"
        fill={theme.secondary.light.c}
      />
    </g>
  </svg>
));
