import { Field, FieldProps, FormikValues } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { PlantItemFormValuse } from '.';
import { Typography } from '../../components/Typography';
import { InputBox } from './InputBox';
import { InputTable } from './InputTable';

const Root = styled.div`
  background: ${props => props.theme.base.light.a};
  margin: 5px 0;
  padding: 24px 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div``;

const Line = styled.span`
  display: block;
  width: 32px;
  height: 1px;
  border-bottom: 4px solid ${p => p.theme.secondary.light.b};
  margin-top: 6px;
  margin-bottom: 15px;
`;

const HeaderInput = styled.div`
  display: flex;
  align-items: baseline;
`;

export interface CardProps {
  title: string;
  subtitle: string;
  prefixName: string;
  plantValues: PlantItemFormValuse[];
  onChange: (name: string, value: string) => void;
}

export const allowInputUpdate = (value: string): string | null => {
  if (value === '') {
    return value;
  }

  let result = ['-', '+'].find(symbol => value.startsWith(symbol)) ?? '';

  const formattedValue = result !== '' ? value.substr(0) : value;

  if (isNaN(Number(formattedValue))) {
    return result;
  }

  const splitValue = formattedValue.replace('-', '').split('.');
  const decimals = splitValue[1];

  if (splitValue[0].length > 5) {
    result += splitValue[0].substring(0, 5);
  } else {
    result += splitValue[0];
  }

  if (value.includes('.')) {
    result += '.';
  }

  if (decimals && decimals.length > 2) {
    result += decimals.substring(0, 2);
  } else if (splitValue[1]) {
    result += splitValue[1];
  }

  return result;

  return null;
};

const Card: React.FunctionComponent<CardProps> = props => {
  return (
    <Root data-testid="pricing-card">
      <Header>
        <Title>
          <Typography variant="heading5">
            <strong>{props.title}</strong>
          </Typography>
          <Typography variant="body2">{props.subtitle}</Typography>
          <Line />
        </Title>
        <HeaderInput>
          <Typography variant="body4">
            <strong>vs FO:</strong>
          </Typography>
          <div>
            <Field
              name={`${props.prefixName}.minVSFO`}
              render={({ field, form }: FieldProps<FormikValues>) => (
                <InputBox
                  label="Min"
                  value={field.value}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={(e: React.ChangeEvent<{ value: string }>) => {
                    const value = allowInputUpdate(e.target.value);
                    if (value !== null) {
                      props.onChange('minVSFO', value);
                      form.setFieldValue(`${props.prefixName}.minVSFO`, value);
                    }
                  }}
                />
              )}
            />

            <Field
              name={`${props.prefixName}.maxVSFO`}
              render={({ field, form }: FieldProps<FormikValues>) => {
                return (
                  <InputBox
                    label="Max"
                    value={field.value}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      const value = allowInputUpdate(e.target.value);
                      if (value !== null) {
                        props.onChange('maxVSFO', value);
                        form.setFieldValue(`${props.prefixName}.maxVSFO`, value);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        </HeaderInput>
      </Header>

      <InputTable months={props.plantValues} prefixName={props.prefixName} />
    </Root>
  );
};

export { Card };
