import { FieldArray } from 'formik';
import React, { useEffect, useRef } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../../../components/Button';
import { DropdownItemOptions } from '../../../../../components/SelectDropdown/Single';
import { Total } from '../../../../../components/Total';
import { Typography } from '../../../../../components/Typography';
import { DemandLocation, ShipmentType } from '@scout/types';
import { DemandsStepVolumeRow, PlantItem, VolumeRowName } from '../types';
import { DemandsStepPlantRow } from './PlantRow';

const HeaderTotal = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Table = styled.table`
  width: 100%;
`;

const StyledTh = styled.th<Pick<CSSProperties, 'width'>>`
  padding: 16px 10px;
  width: ${({ width }) => width};
`;

const StyledTd = styled.td`
  padding: 8px;
`;

const AddNewTextTableCell = styled(StyledTd)`
  padding-left: 16px;
`;

const EmptyRow = styled.td`
  border: 1px solid ${({ theme }) => theme.greys.light.disabled};
  padding: 16px 0 16px 0;
  text-align: center;
  vertical-align: middle;
`;

/**
 * Removes a set of locations from a base array of locations.
 * @param base The original array of locations
 * @param otherIds The set of location IDs that need to be removed
 */
const excludeLocations = (base: DropdownItemOptions[], otherIds: string[]): DropdownItemOptions[] =>
  base.reduce<DropdownItemOptions[]>((prev, curr) => {
    if (otherIds.find(o => o === curr.value) != null) {
      return prev;
    }

    return [...prev, curr];
  }, []);

/**
 * Goes through supplied rows and returns any others that match the plant
 * used by the row with `baseIndex`.
 *
 * @param rows
 * @param plantId The plant to check for
 * @param baseIndex The index of the row that contains the plantId
 */
const getOtherRowsWithSamePlant = (
  rows: DemandsStepVolumeRow[],
  plant: string,
  baseIndex: number,
): DemandsStepVolumeRow[] =>
  rows.reduce<DemandsStepVolumeRow[]>((prev, curr, otherIndex) => {
    if (baseIndex === otherIndex || curr.plant !== plant) {
      return prev;
    }

    return [...prev, curr];
  }, []);

const groupLocationsByRow = (
  rows: DemandsStepVolumeRow[],
  locations: DropdownItemOptions[],
): { [rowIndex: number]: DropdownItemOptions[] } =>
  rows.reduce<{ [rowIndex: number]: DropdownItemOptions[] }>((prev, curr, index) => {
    let allowedLocations = locations;

    // Only restrict if the row has a plant assigned
    if (curr.plant != null) {
      const otherRowsWithPlant = getOtherRowsWithSamePlant(rows, curr.plant, index);

      // Only restrict locations if another row uses the same plant
      if (otherRowsWithPlant.length > 0) {
        const usedLocations = otherRowsWithPlant.map(other => other.location).filter((x): x is string => x != null);
        allowedLocations = excludeLocations(locations, usedLocations);
      }
    }

    // For each row if it has a plant then assign locations
    return {
      ...prev,
      [index]: allowedLocations,
    };
  }, {});

interface Props {
  addNewButtonText: string;
  canDeleteFirstRow?: boolean;
  emptyMessage?: string;
  locations: DropdownItemOptions[];
  locationsType: DemandLocation;
  maxTotal: number;
  name: VolumeRowName;
  plantHeaderText: string;
  plants: PlantItem[];
  rows: DemandsStepVolumeRow[];
  shipmentType: ShipmentType;
  title: string;
  total: number;
}

const DemandsStepPlants: React.FC<Props> = ({
  addNewButtonText,
  emptyMessage,
  locations,
  locationsType,
  maxTotal,
  name,
  plantHeaderText,
  plants,
  rows,
  shipmentType,
  title,
  total,
}) => {
  // Keep track of amount of rows between renders
  const rowCount = useRef(rows.length);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  // We want to make sure that when a user creates a new row that
  // it scrolls into view if necessary. However, if they remove
  // one the scroll position should stay as it is.
  useEffect(() => {
    if (tableBodyRef.current && rows.length > rowCount.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: tableBodyRef.current.offsetTop + tableBodyRef.current.offsetHeight,
      });
    }

    rowCount.current = rows.length;
  }, [rowCount, tableBodyRef, rows.length]);

  const rowLocations = groupLocationsByRow(rows, locations);
  let locationHeader = locationsType === DemandLocation.Region ? 'Region(s)' : 'Ship-to(s)';
  if (shipmentType === ShipmentType.Pickup) {
    locationHeader += ' (Optional)';
  }

  return (
    <div>
      <HeaderTotal>
        <Typography bold={true} variant="heading6">
          {title}
        </Typography>
        <Total targetType="Fixed" total={total} totalText="Total" unit="t" />
      </HeaderTotal>
      <Table>
        <thead>
          <tr>
            <StyledTh width="56px" />
            <StyledTh width="249px">
              <Typography variant="labels1">{plantHeaderText}</Typography>
            </StyledTh>
            <StyledTh width="242px">
              <Typography variant="labels1">{locationHeader}</Typography>
            </StyledTh>
            <StyledTh width="95px">
              <Typography align="right" variant="labels1">
                Volume
              </Typography>
            </StyledTh>
          </tr>
        </thead>
        <tbody ref={tableBodyRef}>
          <FieldArray
            name={name}
            render={arrayProps => (
              <>
                {rows.length === 0 ? (
                  <tr>
                    <EmptyRow colSpan={4}>
                      <Typography color="disabledBgDark" inline={true}>
                        {emptyMessage}
                      </Typography>
                    </EmptyRow>
                  </tr>
                ) : (
                  rows.map((row, index) => (
                    <DemandsStepPlantRow
                      {...row}
                      key={index}
                      name={`${name}.${index}`}
                      locations={rowLocations[index]}
                      locationsPlaceholder={
                        locationsType === DemandLocation.Region ? 'Select a region' : 'Select a ship-to'
                      }
                      locationsLineNumber={locationsType === DemandLocation.Region ? 1 : 2}
                      maxTotal={maxTotal}
                      onRemoveRowClick={() => arrayProps.remove(index)}
                      plants={plants}
                    />
                  ))
                )}
                <tr>
                  <StyledTd>
                    <Button
                      onClick={() => {
                        const newRow: DemandsStepVolumeRow = {
                          id: uuidv4(),
                          location: undefined,
                          plant: rows.length > 0 ? rows[rows.length - 1].plant : undefined,
                          volume: undefined,
                        };

                        arrayProps.push(newRow);
                      }}
                      icon="MdAdd"
                      type="button"
                      variant="icon-light"
                    />
                  </StyledTd>
                  <AddNewTextTableCell>
                    <Typography bold={true}>{addNewButtonText}</Typography>
                  </AddNewTextTableCell>
                </tr>
              </>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export { DemandsStepPlants };
