import { AllSpotPricesQueryVariables, DashboardDataQuery, Facet } from '@scout/types';

export const createPricingVariables = ({
  clusterId,
  spotDemands,
  spotPricingMonths,
  year,
}: {
  clusterId: string;
  dateFacet: Facet;
  spotDemands: DashboardDataQuery['spotDemands']['results'];
  spotPricingMonths: Array<{ month: string; number: string }>;
  year: number;
}): AllSpotPricesQueryVariables => ({
  dates: spotPricingMonths.map(monthNumber => `${year}-${monthNumber.number}-01`),
  clusterId,
  spotPriceInputs: spotDemands.map(spotDemand => {
    const penPlantPrimary = spotDemand.plants.find(plant => !plant.isPremium && plant.isPrimary);
    const premiumPlantPrimary = spotDemand.plants.find(plant => plant.isPremium && plant.isPrimary);
    const plantId = penPlantPrimary
      ? penPlantPrimary.plant.id
      : premiumPlantPrimary
      ? premiumPlantPrimary.plant.id
      : undefined;

    return {
      spotDemandId: spotDemand.id,
      customerCode: spotDemand.customer.code,
      shipmentType: spotDemand.shipmentType,
      countryCode: spotDemand.customer.countryCode,
      regionId: spotDemand.region ? spotDemand.region.id : undefined,
      plantId,
    };
  }),
});
