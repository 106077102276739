import { FastField, Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import React, { Ref, useContext } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Customer, DemandLocation, Region, ShipmentType, ShippingLocation } from '@scout/types';
import { Select } from '../Select/Select';
import { SingleDropdown } from '../SelectDropdown/Single';
import { Typography } from '../Typography';
import { UserThemeContext } from '../../App';

const Label = styled(Typography).attrs(props => ({
  variant: 'body3',
  color: props.currentUserTheme ? 'bodyLight' : 'bodyDark',
}))<{ currentUserTheme?: boolean }>`
  margin: 0 0 5px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: -5px;
`;

const StyledTypeaheadDropdown = styled(SingleDropdown)`
  margin-bottom: 10px;
`;

export const SHIP_TO_FIELD = 'shipTo';
export const CUSTOMER_TO_FIELD = 'customer';
export const REGION_FIELD = 'region';
export const SHIPMENT_TYPE_FIELD = 'shipmentType';

export interface FormValues {
  customerCode: string | null;
  customer: string | null;
  shipmentType: string | null;
  region?: string | null;
  shipTo?: string | null;
}

const createDropdownItems = (customers: Array<Pick<Customer, 'id' | 'name' | 'code' | 'salesOrganisation'>>) =>
  customers.map(({ id, name, code, salesOrganisation }) => ({
    label: name,
    value: id,
    tag: salesOrganisation,
    id: code,
  }));

const createValidationSchema = (demandLocation: DemandLocation) =>
  Yup.object({
    [CUSTOMER_TO_FIELD]: Yup.string()
      .nullable()
      .required('This is a required field'),
    [SHIPMENT_TYPE_FIELD]: Yup.string()
      .nullable()
      .required('This is a required field'),
    ...(((demandLocation === DemandLocation.Region
      ? {
          [REGION_FIELD]: Yup.string()
            .nullable()
            .when([SHIPMENT_TYPE_FIELD], {
              is: shipmentType => shipmentType === ShipmentType.Delivery,
              then: Yup.string()
                .nullable()
                .required('This is a required field'),
            }),
        }
      : {
          [SHIP_TO_FIELD]: Yup.string()
            .nullable()
            .required('This is a required field'),
        }) as unknown) as Yup.AnySchemaConstructor),
  });

interface Props {
  customers: Array<
    Pick<Customer, 'id' | 'name' | 'code' | 'salesOrganisation'> & {
      shippingLocations: Array<Pick<ShippingLocation, 'id' | 'name' | 'code' | 'salesOrganisation'>>;
    }
  >;
  demandLocation: DemandLocation;
  formRef: Ref<Formik<FormValues>>;
  formValues?: Partial<FormValues>;
  regions: Array<Pick<Region, 'id' | 'name'>>;
  onSubmit: (values: FormikValues) => void;
}

const DemandModalForm = ({ customers, demandLocation, formRef, formValues, regions, onSubmit }: Props) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  return (
    <Formik
      ref={formRef}
      validationSchema={createValidationSchema(demandLocation)}
      initialValues={{
        customerCode: null,
        customer: null,
        shipmentType: null,
        region: null,
        shipTo: null,
        ...formValues,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <StyledForm>
          <>
            <Label currentUserTheme={currentUserTheme}>Shipment type</Label>
            <FastField
              name={SHIPMENT_TYPE_FIELD}
              render={({ field, form }: FieldProps<{ shipmentType: string | null }>) => (
                <Select
                  data-testid="header-shipment-type"
                  onItemClick={id => form.setFieldValue(field.name, id)}
                  selected={field.value}
                  items={[
                    { value: ShipmentType.Pickup, label: 'Pick up' },
                    { value: ShipmentType.Delivery, label: 'Delivered' },
                  ]}
                  error={form.touched.shipmentType ? form.errors.shipmentType : undefined}
                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                />
              )}
            />
            <Label currentUserTheme={currentUserTheme}>Sold-to</Label>
            <Field
              name={CUSTOMER_TO_FIELD}
              render={({ field, form }: FieldProps<{ customer: string | null }>) => (
                <StyledTypeaheadDropdown
                  isClearable={true}
                  lineNumber={2}
                  darkTheme={currentUserTheme ? false : true}
                  showClearIndicator={false}
                  name={field.name}
                  placeholder="Select a Sold-to"
                  onItemClick={item => {
                    form.setFieldValue(field.name, item.value);
                    form.setFieldValue(SHIP_TO_FIELD, null);
                  }}
                  selected={field.value}
                  items={createDropdownItems(customers)}
                  error={form.touched.customer ? form.errors.customer : undefined}
                />
              )}
            />
            {demandLocation === DemandLocation.ShipTo && (
              <Field
                name={SHIP_TO_FIELD}
                render={({ field, form }: FieldProps<{ shipTo: string | null }>) => (
                  <>
                    <Label>Ship-to</Label>
                    <StyledTypeaheadDropdown
                      isClearable={true}
                      lineNumber={2}
                      darkTheme={currentUserTheme ? false : true}
                      showClearIndicator={false}
                      name={field.name}
                      error={form.touched.shipTo ? form.errors.shipTo : undefined}
                      placeholder="Select a Ship-to"
                      onItemClick={item => {
                        form.setFieldValue(field.name, item.value);
                      }}
                      selected={field.value}
                      items={createDropdownItems(
                        values.customer != null
                          ? customers.find(({ id }) => id === values.customer)!.shippingLocations
                          : [],
                      )}
                    />
                  </>
                )}
              />
            )}

            {demandLocation === DemandLocation.Region && (
              <>
                <Label currentUserTheme={currentUserTheme}>
                  Region {values.shipmentType === ShipmentType.Pickup ? '(Optional)' : ''}
                </Label>
                <FastField
                  name={REGION_FIELD}
                  render={({ field, form }: FieldProps<{ region: string | null }>) => (
                    <StyledTypeaheadDropdown
                      isClearable={true}
                      darkTheme={currentUserTheme ? false : true}
                      showClearIndicator={false}
                      name={field.name}
                      placeholder="Select a region"
                      onItemClick={item => form.setFieldValue(field.name, item.value ? item.value : '')}
                      selected={field.value}
                      items={regions.map(region => ({
                        label: region.name,
                        value: region.id,
                      }))}
                      error={form.touched.region ? form.errors.region : undefined}
                    />
                  )}
                />
              </>
            )}
          </>
        </StyledForm>
      )}
    </Formik>
  );
};

export { DemandModalForm };
