import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../../../hooks/usePermission';
import { DashboardRowType, MonthlyVolumeType } from '../types';
import { TableFooter } from './TableFooter';
import { DashboardTableRow } from './TableRow';

interface Props {
  clusterId: string;
  year: number;
  emptyComponent: React.ReactElement;
  isUKIrelandCluster: boolean;
  volumeTotal: number;
  monthlyVolumeTotals: MonthlyVolumeType[];
  tableRows: DashboardRowType[];
  currentUserTheme?: boolean;
}

export const TableBody: React.FunctionComponent<Props> = ({
  isUKIrelandCluster,
  year,
  emptyComponent,
  volumeTotal,
  tableRows,
  monthlyVolumeTotals,
  currentUserTheme,
}) => {
  const { hasAnyOf } = usePermission();
  const history = useHistory();

  const from = window.location.pathname + window.location.search;

  const handleTableRowClick = useCallback(
    (link: string) => {
      const searchParams = new URLSearchParams({
        from,
      });

      history.push({
        pathname: link,
        search: `?${searchParams.toString()}`,
      });
    },
    [from, history],
  );

  return (
    <tbody data-testid="am-dashboard-body">
      {tableRows.length === 0 ? (
        <tr>{emptyComponent}</tr>
      ) : (
        tableRows.map(item => (
          <DashboardTableRow
            key={item.id}
            item={item}
            isUKIrelandCluster={isUKIrelandCluster}
            onClick={hasAnyOf(['editDemandForecast']) ? handleTableRowClick : undefined}
            currentUserTheme={currentUserTheme}
          />
        ))
      )}
      <TableFooter
        isVisible={tableRows.length > 0}
        monthlyVolumeTotals={monthlyVolumeTotals}
        volumeTotal={volumeTotal}
        year={year}
        currentUserTheme={currentUserTheme}
      />
    </tbody>
  );
};
