import * as React from 'react';
import styled from 'styled-components';
import { InputBase, InputLightMixin, InputMixin, InputProps } from '../InputBase/InputBase';

export type TextInputProps = InputProps &
  JSX.IntrinsicElements['input'] & {
    align?: 'right' | 'center' | 'left';
    showErrorMessage?: boolean;
    showErrorIcon?: boolean;
    renderIcon?: (focused: boolean) => React.ReactNode;
    onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
    ref?: React.RefObject<HTMLInputElement> | null;
    active?: boolean;
    suffix?: string;
    disabledTextColor?: boolean;
    currentUserTheme?: boolean;
  };

const StyledInput = styled.input<{
  error?: string;
  variant: string;
  themeStyle?: string;
  align: string;
  suffix?: string;
  active?: boolean;
  disabledTextColor?: boolean;
  currentUserTheme?: boolean;
}>`
  ${props => (props.themeStyle && props.themeStyle === 'light' ? InputLightMixin : InputMixin)}
  text-align: ${props => props.align};
  margin: 0;
  height: 40px;

  &::placeholder {
    color: ${props => props.theme.greys.light.border};
  }

  &:focus {
    border-color: ${props => props.theme.primary.b};
  }

  &:disabled{
    color:${props => (props.disabledTextColor ? props.theme.greys.dark.disabled : '')};
    opacity:${props => (props.currentUserTheme ? '0.4' : '1')}
  }

  ${props => props.active && `border-color: ${props.theme.primary.b};`}
`;

const TextInput: React.FunctionComponent<TextInputProps> = React.forwardRef(
  (
    {
      className,
      align = 'left',
      label,
      themeStyle,
      showErrorMessage,
      showErrorIcon,
      renderIcon,
      onFocus,
      onBlur,
      suffix,
      value,
      disabledTextColor,
      currentUserTheme,
      ...rest
    },
    ref,
  ) => (
    <InputBase
      className={className}
      error={rest.error}
      label={label}
      showErrorMessage={showErrorMessage}
      showErrorIcon={showErrorIcon}
      renderIcon={renderIcon}
      onFocus={onFocus}
      onBlur={onBlur}
      suffix={suffix}
      value={value}
      themeStyle={themeStyle}
      disabled={rest.disabled}
    >
      <StyledInput
        align={align}
        variant="body3"
        aria-label={rest.name}
        themeStyle={themeStyle}
        autoComplete="off"
        ref={ref}
        suffix={suffix}
        value={value}
        disabledTextColor={disabledTextColor}
        currentUserTheme={currentUserTheme}
        {...rest}
      />
    </InputBase>
  ),
);

export { TextInput };
