import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import { useMutation } from 'react-apollo';
import { useSchedulerLocationId } from './hooks';
import { Tabs } from '../../../../components/Tabs';
import { Typography } from '../../../../components/Typography';
import { RefineryMovementTabOptionsProps, DepotsMovementTabOptionsProps } from '../../../MidTerm/SpotForm/types';
import { EastRefineryMovementForm } from './EastMovement/EastRefineryMovementForm';
import { EastDepotMovementForm } from './EastMovement/EastDepotMovementForm';
import {
  LocationsQuery,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  DeleteRecordFromSchedulingManagerMutation,
  DeleteRecordFromSchedulingManagerMutationVariables,
  DELETE_RECORD_FROM_SCHEDULINGMANAGER,
  DeleteMultipleSmRecordsMutation,
  DeleteMultipleSmRecordsMutationVariables,
  DELETE_MULTI_SM_RECORDS,
  UNDOFORDRAGANDDROP,
  UndoForDragAndDropMutation,
  UndoForDragAndDropMutationVariables,
  DELETE_SIMULATION_DATA,
  DeleteSimulationDataMutation,
  DeleteSimulationDataMutationVariables,
} from '@scout/types';
import { Button } from '../../../../components/Button';
import { Row } from '../../../../components/Grid';
import { useToast } from '../../../../components/Toast/Toast';
import { usePermission } from '../../../../hooks/usePermission';
import { EastMoreActionsModal } from '../LocationView/EastMoreActionsModal';
import { URLS } from '../../../../constants';

const Spacer = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${p => p.size || '17px'};
`;

const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  float: right
`;

const NewButton = styled(Button)`
  margin-left: 16px;
`;

export interface EastNewMovementModalProps {
  selectedMovement: SchedulingManagerType | null;
  showModal: boolean;
  locations: LocationsQuery['locations'];
  onDismiss: () => void;
  onConfirm: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  showModalValue?: string;
  isThirdParty?: boolean;
  currentLocation: string;
  currentUserTheme?: boolean;
  multiDeleteMovement: (item: boolean) => void;
  undoMovement: (item: boolean) => void;
  previousValues: Array<{
    dragLoadportstartdate: string;
    dragDischargePortStartDate: string;
    id: string;
    currentPort: string;
    cloneId: string;
  }>;
  disableUndoButton: (value: boolean) => void;
  toggleTheme: () => void;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

const EastNewMovementModal: React.FC<EastNewMovementModalProps> = props => {
  const [locationId, setLocationId] = useSchedulerLocationId();
  const [currentLocation, setCurrentLocation] = useState('');
  const [currentLocationName, setCurrentLocationName] = useState('');
  const [refineryActiveTab, refinerySetActiveTab] = useState(RefineryMovementTabOptionsProps.Production);
  const [depotActiveTab, depotSetActiveTab] = useState(DepotsMovementTabOptionsProps.Sale);
  const [thirdPartyActiveTab, thirdPartySetActiveTab] = useState(
    props.currentLocation === 'ThirdPartyRef'
      ? RefineryMovementTabOptionsProps.Lifting
      : props.currentLocation === 'ThirdPartyCus'
      ? DepotsMovementTabOptionsProps.Replenishment
      : '',
  );
  const [toBeDeletedId, setToBeDeletedId] = useState();
  const [multiSelectId, setMultiSelectId] = useState((props.selectedMovement as unknown) as string[]);
  const { push } = useToast();
  const { hasAnyOf } = usePermission();
  const [disabledYesButton, setDisabledYesButton] = useState(false);
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [removeMovement, removeMovementResult] = useMutation<
    DeleteRecordFromSchedulingManagerMutation,
    DeleteRecordFromSchedulingManagerMutationVariables
  >(DELETE_RECORD_FROM_SCHEDULINGMANAGER, {
    variables: toBeDeletedId,
    context: {
      headers,
    },
  });

  const [deleteMultipleSMRecords, deleteMultipleSMRecordsResult] = useMutation<
    DeleteMultipleSmRecordsMutation,
    DeleteMultipleSmRecordsMutationVariables
  >(DELETE_MULTI_SM_RECORDS, {
    variables: toBeDeletedId,
    context: {
      headers,
    },
  });

  const [deleteSimulation, deleteSimulationResult] = useMutation<
    DeleteSimulationDataMutation,
    DeleteSimulationDataMutationVariables
  >(DELETE_SIMULATION_DATA, {
    context: {
      headers,
    },
  });

  const [updateRecordForUndo, setUpdateRecordForUndo] = useMutation<
    UndoForDragAndDropMutation,
    UndoForDragAndDropMutationVariables
  >(UNDOFORDRAGANDDROP, {
    context: {
      headers,
    },
  });

  const undoHandler = async (
    previousValues: Array<{
      dragLoadportstartdate: string;
      dragDischargePortStartDate: string;
      id: string;
      cloneId?: string;
      currentPort: string;
    }>,
  ) => {
    const SUCCESS_MESSAGE = 'Your Changes were reverted';
    previousValues.forEach(value => {
      delete value.cloneId;
    });
    try {
      const data = await updateRecordForUndo({
        variables: {
          input: previousValues,
        },
      });
      push({ type: 'SUCCESS', text: SUCCESS_MESSAGE });
      props.undoMovement(false);
      props.disableUndoButton(true);
      props.previousValues.length = 0;
      props.onDismiss();
      props.onClickSimulationEvent(!props.dataFetch);
    } catch (error) {
      const indexOfError = error.message
        .toString()
        .split('Error', 2)
        .join('Error').length;
      const ERRORMESSAGE = error.message
        .toString()
        .slice(indexOfError)
        .slice(7);
      push({ type: 'ERROR', text: ERRORMESSAGE });
      props.undoMovement(false);
      props.onDismiss();
      props.disableUndoButton(true);
      props.previousValues.length = 0;
      return;
    }
  };

  const getRefineryStartingTab = (movementType: SchedulingManagerType) => {
    switch (movementType.productType) {
      case 'Event':
        return 'Event';
      case 'Production':
        return 'Production';
      case 'Lifting':
        return 'Lifting';
      default:
        return refineryActiveTab;
    }
  };
  const getDepotStartingTab = (movementType: SchedulingManagerType) => {
    switch (movementType.productType) {
      case 'Event':
        return 'Event';

      case 'Sale':
        return 'Sale';
      case 'Replenishment':
        return 'Replenishment';
      case 'Movement Details':
        return 'Movement Details';
      default:
        return depotActiveTab;
    }
  };
  const getThirdPartyStartingTab = (movementType: SchedulingManagerType) => {
    switch (movementType.productType) {
      case 'Lifting':
        return 'Lifting';
      case 'Replenishment':
        return 'Replenishment';
      default:
        return thirdPartyActiveTab;
    }
  };
  useEffect(() => {
    const presentLocation = props.locations?.find(location => location.id === locationId);

    if (presentLocation) {
      setCurrentLocation(presentLocation.type);
      setCurrentLocationName(presentLocation.name);
    }
  }, [props.locations]);

  // tslint:disable-next-line
  const handleOnClickDelete = async (id: any) => {
    setToBeDeletedId(id);
    try {
      if (Array.isArray(id) === true) {
        props.multiDeleteMovement(true);
        await deleteMultipleSMRecords({ variables: { input: { idList: id } } });
        props.multiDeleteMovement(false);
      } else {
        await removeMovement({ variables: { id } });
        if (props.previousValues.length > 0) {
          if (
            props.previousValues.find(
              pv => (props.selectedMovement && pv.cloneId === props.selectedMovement.movementCloneId) || pv.id === id,
            )
          ) {
            props.disableUndoButton(true);
            props.previousValues.length = 0;
          }
        }
      }
      if (typeof id === 'string') {
        push({ type: 'SUCCESS', text: 'Movement removed' });
      } else if (typeof id !== 'string' && id.length === 1) {
        push({ type: 'SUCCESS', text: 'Movement removed' });
      } else {
        push({ type: 'SUCCESS', text: 'Movements removed' });
      }
      props.onClickSimulationEvent(!props.dataFetch);
      props.onDismiss();
    } catch (error) {
      props.multiDeleteMovement(false);
      push({ type: 'ERROR', text: 'Something went wrong. Movement could not be deleted. Please try again' });
      if (props.previousValues.length > 0) {
        if (
          props.previousValues.find(
            pv => (props.selectedMovement && pv.cloneId === props.selectedMovement.movementCloneId) || pv.id === id,
          )
        ) {
          props.disableUndoButton(true);
          props.previousValues.length = 0;
        }
      }
    }
  };

  const getItemsBasedOnUserPermission = () => {
    const itemForDepotUser = [
      {
        id: 'Sale',
        label: 'Sale',
      },

      {
        id: 'Event',
        label: 'Event',
      },
    ];
    const itemForSupplyUser = [
      {
        id: 'Sale',
        label: 'Sale',
      },
      {
        id: 'Replenishment',
        label: hasAnyOf(['eastDepotUser']) && props.selectedMovement !== null ? 'Movement Details' : 'Replenishment',
      },
      {
        id: 'Event',
        label: 'Event',
      },
    ];
    return hasAnyOf(['eastDepotUser']) && props.selectedMovement === null ? itemForDepotUser : itemForSupplyUser;
  };

  const getTitle = () => {
    return props.selectedMovement === null
      ? currentLocation === 'refinery'
        ? 'Add New Movement'
        : 'Add New'
      : 'Edit Movement';
  };

  const destroySimulation = async () => {
    try {
      await deleteSimulation({
        variables: {
          input: 'simulation',
        },
      });
      window.close();
    } catch (error) {
      console.error('Error in deletion');
    }
  };

  return (
    <>
      <Modal
        data-testid="create-east-movement"
        confirmBtnVariant="primary"
        isOpen={props.showModal}
        minWidth={
          props.showModalValue === 'deleteMovement' ||
          props.showModalValue === 'undoMovement' ||
          props.showModalValue === 'closeSimulation'
            ? 500
            : props.showModalValue === 'moreActions'
            ? 370
            : 750
        }
        onClose={props.onDismiss}
        currentUserTheme={props.currentUserTheme}
        height={props.showModalValue === 'moreActions' ? '97%' : 'none'}
        moreActions={props.showModalValue === 'moreActions' ? true : false}
      >
        {props.showModalValue !== 'deleteMovement' &&
          props.showModalValue !== 'undoMovement' &&
          props.showModalValue !== 'moreActions' &&
          props.showModalValue !== 'closeSimulation' && (
            <>
              <Typography
                variant="heading5"
                color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}
                align="center"
                bold={true}
                currentUserTheme={props.currentUserTheme}
              >
                {getTitle()}
              </Typography>
              <Spacer />
              {currentLocation && currentLocation === 'refinery' && props.isThirdParty === false ? (
                <Tabs
                  currentUserTheme={props.currentUserTheme}
                  items={[
                    {
                      id: 'Production',
                      label: 'Production',
                    },
                    {
                      id: 'Lifting',
                      label: 'Lifting',
                    },
                    {
                      id: 'Event',
                      label: 'Event',
                    },
                  ]}
                  selected={
                    props.selectedMovement === null ? refineryActiveTab : getRefineryStartingTab(props.selectedMovement)
                  }
                  onItemClick={(label: string) => {
                    refinerySetActiveTab(label as RefineryMovementTabOptionsProps);
                  }}
                  isEast={true}
                >
                  <EastRefineryMovementForm
                    selectedMovement={props.selectedMovement}
                    currentTab={
                      props.selectedMovement === null
                        ? refineryActiveTab
                        : getRefineryStartingTab(props.selectedMovement)
                    }
                    currentLocationName={currentLocationName}
                    onDismiss={props.onDismiss}
                    SMdata={props.SMdata}
                    locations={props.locations}
                    isThirdParty={props.isThirdParty}
                    previousValues={props.previousValues}
                    disableUndoButton={props.disableUndoButton}
                    currentUserTheme={props.currentUserTheme}
                    onClickSimulationEvent={props.onClickSimulationEvent}
                    dataFetch={props.dataFetch}
                  />
                </Tabs>
              ) : props.currentLocation === 'ThirdPartyRef' && props.isThirdParty === true ? (
                <Tabs
                  items={[
                    {
                      id: 'Lifting',
                      label: 'Lifting',
                    },
                  ]}
                  selected={
                    props.selectedMovement === null
                      ? thirdPartyActiveTab
                      : getThirdPartyStartingTab(props.selectedMovement)
                  }
                  onItemClick={(label: string) => {
                    thirdPartySetActiveTab(label as RefineryMovementTabOptionsProps);
                  }}
                  isEast={true}
                  currentUserTheme={props.currentUserTheme}
                >
                  <EastRefineryMovementForm
                    selectedMovement={props.selectedMovement}
                    currentTab={
                      props.selectedMovement === null
                        ? thirdPartyActiveTab
                        : getThirdPartyStartingTab(props.selectedMovement)
                    }
                    currentLocationName={currentLocationName}
                    onDismiss={props.onDismiss}
                    SMdata={props.SMdata}
                    locations={props.locations}
                    isThirdParty={props.isThirdParty}
                    previousValues={props.previousValues}
                    disableUndoButton={props.disableUndoButton}
                    currentUserTheme={props.currentUserTheme}
                    onClickSimulationEvent={props.onClickSimulationEvent}
                    dataFetch={props.dataFetch}
                  />
                </Tabs>
              ) : props.currentLocation === 'ThirdPartyCus' && props.isThirdParty === true ? (
                <Tabs
                  items={[
                    {
                      id: 'Replenishment',
                      label:
                        hasAnyOf(['eastDepotUser']) && props.selectedMovement !== null
                          ? 'Movement Details'
                          : 'Replenishment',
                    },
                  ]}
                  selected={
                    props.selectedMovement === null
                      ? thirdPartyActiveTab
                      : getThirdPartyStartingTab(props.selectedMovement)
                  }
                  onItemClick={(label: string) => {
                    thirdPartySetActiveTab(label as DepotsMovementTabOptionsProps);
                  }}
                  isEast={true}
                  currentUserTheme={props.currentUserTheme}
                >
                  <EastDepotMovementForm
                    selectedMovement={props.selectedMovement}
                    currentLocationName={currentLocationName}
                    currentTab={
                      props.selectedMovement === null
                        ? thirdPartyActiveTab
                        : getThirdPartyStartingTab(props.selectedMovement)
                    }
                    onDismiss={props.onDismiss}
                    SMdata={props.SMdata}
                    locations={props.locations}
                    isThirdParty={props.isThirdParty}
                    currentUserTheme={props.currentUserTheme}
                    onClickSimulationEvent={props.onClickSimulationEvent}
                    dataFetch={props.dataFetch}
                  />
                </Tabs>
              ) : (
                <Tabs
                  items={getItemsBasedOnUserPermission()}
                  selected={
                    props.selectedMovement === null ? depotActiveTab : getDepotStartingTab(props.selectedMovement)
                  }
                  onItemClick={(label: string) => {
                    depotSetActiveTab(label as DepotsMovementTabOptionsProps);
                  }}
                  isEast={true}
                  currentUserTheme={props.currentUserTheme}
                >
                  <EastDepotMovementForm
                    selectedMovement={props.selectedMovement}
                    currentLocationName={currentLocationName}
                    currentTab={
                      props.selectedMovement === null ? depotActiveTab : getDepotStartingTab(props.selectedMovement)
                    }
                    onDismiss={props.onDismiss}
                    SMdata={props.SMdata}
                    locations={props.locations}
                    currentUserTheme={props.currentUserTheme}
                    onClickSimulationEvent={props.onClickSimulationEvent}
                    dataFetch={props.dataFetch}
                  />
                </Tabs>
              )}
            </>
          )}
        {props.showModalValue === 'deleteMovement' && (
          <>
            <Typography
              variant="heading5"
              color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}
              align="center"
              bold={true}
            >
              {'Are you sure you want to permanently remove ' +
                `${multiSelectId.length > 1 ? multiSelectId.length + ' items' : 'this item'}`}
            </Typography>
            <Spacer /> <Spacer />
            <BtnStyledRow>
              <Row>
                <NewButton
                  variant={props.currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                  onClick={async () => {
                    props.onDismiss();
                  }}
                >
                  Cancel
                </NewButton>

                <NewButton
                  variant="primary"
                  onClick={() => {
                    handleOnClickDelete(
                      props.selectedMovement !== null && props.selectedMovement.id
                        ? props.selectedMovement?.id
                        : props.selectedMovement,
                    );
                    setDisabledYesButton(true);
                  }}
                  disabled={disabledYesButton}
                >
                  Yes
                </NewButton>
              </Row>
            </BtnStyledRow>
          </>
        )}

        {props.showModalValue === 'undoMovement' && (
          <>
            <Typography
              variant="heading5"
              color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}
              align="center"
              bold={true}
              currentUserTheme={props.currentUserTheme}
            >
              {'Confirm To Revert Of Drag And Drop Changes?'}
            </Typography>
            <Spacer /> <Spacer />
            <BtnStyledRow>
              <Row>
                <NewButton
                  variant={props.currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                  onClick={async () => {
                    props.undoMovement(false);
                    props.onDismiss();
                  }}
                  currentUserTheme={props.currentUserTheme}
                >
                  Cancel
                </NewButton>

                <NewButton
                  variant="primary"
                  onClick={() => {
                    props.undoMovement(true);
                    undoHandler(props.previousValues);
                    setDisabledYesButton(true);
                  }}
                  disabled={disabledYesButton}
                  currentUserTheme={props.currentUserTheme}
                >
                  Yes
                </NewButton>
              </Row>
            </BtnStyledRow>
          </>
        )}
        {props.showModalValue === 'moreActions' && (
          <>
            <EastMoreActionsModal toggleTheme={props.toggleTheme} currentUserTheme={props.currentUserTheme} />
          </>
        )}
        {props.showModalValue === 'closeSimulation' && (
          <>
            <Typography
              variant="heading5"
              color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}
              align="center"
              bold={true}
              currentUserTheme={props.currentUserTheme}
            >
              {'Are you sure to close Simulation?'}
            </Typography>
            <Spacer /> <Spacer />
            <BtnStyledRow>
              <Row>
                <NewButton
                  variant={props.currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                  onClick={async () => {
                    props.onDismiss();
                  }}
                  currentUserTheme={props.currentUserTheme}
                >
                  Cancel
                </NewButton>
                <NewButton variant="primary" onClick={destroySimulation} currentUserTheme={props.currentUserTheme}>
                  Close
                </NewButton>
              </Row>
            </BtnStyledRow>
          </>
        )}
      </Modal>
    </>
  );
};
export { EastNewMovementModal };
