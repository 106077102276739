import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { ScoutLogoIcon } from '../../components/Icons/ScoutLogoIcon';
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';
import { URLS } from '../../constants';
import { IPage } from '.';

export interface IReport {
  embedUrl: string;
  reportId: string;
  reportName: string;
  pages: IPage[];
}
interface IRouteState {
  token: string;
  report: IReport;
  reports: IReport[];
}
interface ISidebarProps {
  currentPage: IPage;
  state: IRouteState;
}
interface IPagesListDropdownProps {
  title: string;
}

const Container = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;

  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: #121720;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 5px;
    border: 2px solid #121720;
  }
`;
const SidebarHead = styled.div`
  height: 97px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PagesListItem = styled(Link)<{ active?: number }>`
  color: inherit;
  text-decoration: inherit;
  height: 58px;
  padding-left: 45px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  border-left: 6px solid ${({ theme, active }) => (active ? theme.primary.a : 'transparent')};
  background: ${({ theme, active }) => (active ? theme.base.dark.d : 'transparent')};

  &:hover {
    background: ${({ theme, active }) => (!active ? theme.base.dark.c : '')};
  }
`;
const DropDownTitle = styled.a`
  display: block;
  padding: 15px;
  color: inherit;
  text-decoration: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;
const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  overflow-y: scroll;
`;

const PagesListDropDown: React.FC<IPagesListDropdownProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleDropdown = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <DropDownTitle href="#" onClick={toggleDropdown}>
        {title}
        {isOpen ? <MdArrowDropUp size={30} /> : <MdArrowDropDown size={30} />}
      </DropDownTitle>
      <DropdownContent isOpen={isOpen}>{children}</DropdownContent>
    </>
  );
};

export const Sidebar: React.FC<ISidebarProps> = ({ currentPage, state }) => {
  const { reportId } = useParams();
  return (
    <Container>
      <SidebarHead>
        <ScoutLogoIcon />
      </SidebarHead>
      <SidebarBody>
        {state?.reports.map(report => (
          <PagesListDropDown key={report.reportId} title={report.reportName}>
            {report.pages.map(page => (
              <PagesListItem
                replace={true}
                key={page.name}
                active={currentPage?.name === page.name && report.reportId === reportId ? 1 : 0}
                to={{
                  pathname: `${URLS.REPORTS_LANDING}${report.reportId}/${page?.name}`,
                  state,
                }}
              >
                <span>{page.displayName}</span>
              </PagesListItem>
            ))}
          </PagesListDropDown>
        ))}
      </SidebarBody>
    </Container>
  );
};
