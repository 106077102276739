import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Field, Formik, FieldProps } from 'formik';
import * as yup from 'yup';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { makeURL, URLS } from '../../constants';
import { Modal } from '../../components/Modal';
import { Typography } from '../../components/Typography';
import { SingleDropdown, DropdownItemOptions } from '../../components/SelectDropdown/Single';
import { MidTermLayout } from '../../layouts';
import { UserThemeContext } from '../../App';

const ClusterModalSchema = yup.object().shape({
  cluster: yup
    .string()
    .nullable()
    .required('This is a required field'),
});

interface FormValues {
  cluster: string | null;
}

const MidTerm: React.FC = () => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const currentUser = useCurrentUser();
  const history = useHistory();

  if (currentUser.clusters.length === 1) {
    return <Redirect to={makeURL(URLS.MID_TERM_DASHBOARD, { clusterId: currentUser.clusters[0].id })} />;
  }

  const clusterItems: DropdownItemOptions[] = currentUser.clusters
    .map(cluster => ({
      label: cluster.name,
      value: cluster.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const onCancel = () => {
    history.replace(URLS.ROOT);
  };

  const onSubmit = (values: FormValues) => {
    history.push(
      makeURL(URLS.MID_TERM_DASHBOARD, {
        clusterId: values.cluster as string,
      }),
    );
  };

  return (
    <MidTermLayout>
      <Formik
        validationSchema={ClusterModalSchema}
        initialValues={{
          cluster: null,
        }}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <Modal
            confirmText="Continue"
            dismissText="Return home"
            isOpen={true}
            minWidth={434}
            onConfirm={submitForm}
            onDismiss={onCancel}
            title="Select a cluster"
            currentUserTheme={currentUserTheme}
          >
            <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="body3">
              {'You have access to multiple clusters, which cluster would you like to view first?'}
              <br />
              <br />
              {'You will be able to switch between Cluster, CBM and AM views at any time.'}
              <br />
              <br />
              <br />
            </Typography>

            <Field name="cluster">
              {({ field, form }: FieldProps<FormValues>) => (
                <SingleDropdown
                  darkTheme={currentUserTheme ? false : true}
                  error={form.errors['cluster']}
                  items={clusterItems}
                  name={field.name}
                  onItemClick={item => {
                    form.setFieldValue('cluster', item.value);
                  }}
                  placeholder="Select a cluster"
                  selected={field.value}
                />
              )}
            </Field>
          </Modal>
        )}
      </Formik>
    </MidTermLayout>
  );
};

export { MidTerm };
