import { FormikValues } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { DemandModal } from '../../../components/DemandModal';
import { Loader } from '../../../components/Loader';
import { useToast } from '../../../components/Toast/Toast';
import { makeURL, URLS } from '../../../constants';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { MidTermLayout } from '../../../layouts';
import {
  CREATE_SPOT_DEMAND,
  GET_CREATE_SPOT_DEMAND_DATA,
  CreateSpotDemandInput,
  CreateSpotDemandMutation,
  CreateSpotDemandMutationVariables,
  ShipmentType,
  SpotDemandFormDataQuery,
  SpotDemandFormDataQueryVariables,
} from '@scout/types';
import { Form } from './Form';
import { formatGradeItems, formatPlantsDropdown } from './GradePlantContainer/utils';
import { Header } from './Header';
import { GradeTypes } from './types';
import { makeReturnURL } from './url';
import { createInitialVolumePrices } from './VolumePricingContainer/createInitialVolumePrices';

export const CREATE_SPOT_SUCCESS_MESSAGE = 'New spot demand created.';
export const CREATE_SPOT_FAILURE_MESSAGE = 'Error creating spot demand.';

type Props = RouteComponentProps<{ clusterId: string }>;

const CreateSpotForm: React.FunctionComponent<Props> = ({ history, match }) => {
  const currentUser = useCurrentUser();
  const currentCluster = currentUser.clusters.find(cluster => cluster.id === match.params.clusterId);

  const { push } = useToast();
  const [showDemandModal, setShowDemandModal] = useState(false);
  const [setSpotCreation] = useMutation<CreateSpotDemandMutation, CreateSpotDemandMutationVariables>(
    CREATE_SPOT_DEMAND,
  );
  const { data, loading, error } = useQuery<SpotDemandFormDataQuery, SpotDemandFormDataQueryVariables>(
    GET_CREATE_SPOT_DEMAND_DATA,
    {
      skip: currentCluster == null,
      variables: {
        clusterCurrency: currentCluster?.currency ?? '',
        clusterId: currentCluster?.id ?? '',
      },
    },
  );

  const urlParams = new URLSearchParams(history.location.search);
  const customerId = urlParams.get('customerId');
  const customerCode = urlParams.get('customerCode') || undefined;
  const from = urlParams.get('from');
  const ownerId = urlParams.get('ownerId');
  const regionId = urlParams.get('regionId') || undefined;
  const shipToId = urlParams.get('shipToId') || undefined;
  const shipmentType = urlParams.get('shipmentType') as ShipmentType;
  const yearParam = urlParams.get('year');
  const currentYear = new Date().getFullYear();
  let year = currentYear;

  if (yearParam != null) {
    const yearParamAsNumber = Number(yearParam);

    if (!isNaN(yearParamAsNumber)) {
      year = yearParamAsNumber;
    }
  }

  const grades = useMemo(() => {
    if (!data || !data.spotDemandFormData) {
      return {
        hard: {},
        soft: {},
        premium: {},
      };
    }

    return {
      hard: formatGradeItems(data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.HARD)),
      soft: formatGradeItems(data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.SOFT)),
      premium: formatGradeItems(data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.PREMIUM)),
      non_bitumen: formatGradeItems(
        data.spotDemandFormData.grades.filter(grade => grade.type === GradeTypes.NON_BITUMEN),
      ),
    };
  }, [data]);

  const [premiumPlants, penPlants] = useMemo(() => {
    if (!data || !data.spotDemandFormData) {
      return [[], []];
    }

    const premiumPlants = formatPlantsDropdown(
      data.spotDemandFormData.plants.filter(plant => plant.grades.find(grade => grade.type === GradeTypes.PREMIUM)),
    );

    const penPlants = formatPlantsDropdown(
      data.spotDemandFormData.plants.filter(plant => plant.grades.find(grade => grade.type !== GradeTypes.PREMIUM)),
    );

    return [premiumPlants, penPlants];
  }, [data]);

  const handleSubmitDemandModel = useCallback(
    (values: FormikValues) => {
      if (currentCluster == null) {
        return;
      }

      setShowDemandModal(false);
      const searchParams: Record<string, string> = {
        ownerId: values.ownerId || ownerId,
        customerCode: values.customerCode || customerCode,
        customerId: values.customer || customerId,
        shipmentType: values.shipmentType || shipmentType,
      };

      if (values.shipTo) {
        searchParams.shipToId = values.shipTo;
      }

      if (values.region) {
        searchParams.regionId = values.region;
      }

      history.push(
        makeURL(URLS.MID_TERM_CREATE_SPOT, {
          clusterId: currentCluster.id,
        }) +
          '?' +
          new URLSearchParams(searchParams).toString(),
      );
    },
    [currentCluster, ownerId, customerCode, customerId, shipmentType, history],
  );

  const onCancel = useCallback(() => {
    if (currentCluster == null) {
      return;
    }

    const url = makeReturnURL({ from, currentClusterId: currentCluster.id });

    history.push(url);
  }, [currentCluster, from, history]);

  if (currentCluster == null) {
    return <Redirect to={URLS.MID_TERM} />;
  }

  if (!customerId || !ownerId || !customerCode || !shipmentType) {
    return <div>Incorrect page data supplied</div>;
  }

  if (loading || error || !data) {
    return <Loader error={error && 'Something went wrong'} />;
  }

  const onSubmit = async (payload: Omit<CreateSpotDemandInput, 'ownerId'>) => {
    try {
      await setSpotCreation({
        variables: {
          input: {
            ...payload,
            ownerId,
          },
        },
      });

      push({
        text: CREATE_SPOT_SUCCESS_MESSAGE,
      });

      const url = makeReturnURL({ from, currentClusterId: currentCluster.id });

      history.push(url);
    } catch (error) {
      console.error('Failed mutation at CreateSpotForm ', error);
      push({
        text: CREATE_SPOT_FAILURE_MESSAGE,
        type: 'ERROR',
      });
    }
  };

  return (
    <MidTermLayout>
      <DemandModal
        clusterId={match.params.clusterId}
        isOpen={showDemandModal}
        onSubmit={handleSubmitDemandModel}
        onCancel={() => setShowDemandModal(false)}
        ownerId={ownerId}
        type="new"
        formValues={{
          customerCode,
          customer: customerId,
          shipmentType,
          region: regionId,
          shipTo: shipToId,
        }}
      />

      <Header
        customerId={customerId}
        regionId={regionId}
        shipToId={shipToId}
        shipmentType={shipmentType}
        onEdit={() => setShowDemandModal(true)}
      />
      <Form
        initialValues={{
          totalGrades: 0,
          grades,
          penPlantPrimary: { plantId: '' },
          penPlantSecondary: { plantId: '' },
          premiumPlantPrimary: { plantId: '' },
          premiumPlantSecondary: { plantId: '' },
          plantSelectionReasonTechnical: false,
          plantSelectionReasonOperational: false,
          plantSelectionReasonContractual: false,
          plantSelectionNote: '',
          volumePrices: createInitialVolumePrices(year),
        }}
        onCancel={onCancel}
        onSubmit={onSubmit}
        premiumPlants={premiumPlants}
        penPlants={penPlants}
        shipmentType={shipmentType}
        regionId={regionId}
        customerCode={customerCode}
        customerId={customerId}
        shipToId={shipToId}
        cluster={currentCluster}
        fuelOilQuote={data.fuelOilQuote}
        year={year}
      />
    </MidTermLayout>
  );
};

export { CreateSpotForm };
