import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../components/Typography';

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 42px 0;
`;

const Line = styled.div`
  border-bottom: 4px solid ${p => p.theme.primary.b};
  height: 1px;
  margin-top: 17px;
  width: 32px;
`;

interface Props {
  description?: string;
  number: number;
  title: string;
}

// tslint:disable-next-line
const StepTitle: React.FC<Props> = ({ description, number, title }) => (
  <Root>
    <Typography bold={true} variant="heading5">
      {`${number}. ${title}`}
    </Typography>
    {description != null && <Typography variant="body3">{description}</Typography>}
    <Line />
  </Root>
);

export { StepTitle };
