import {
  GET_PRICING_ADJUSTMENT_DATA,
  UPDATE_PRICING_ADJUSTMENT,
  PricingAdjustmentFormDataQuery,
  PricingAdjustmentFormDataQueryVariables,
  PricingAdjustmentPlantInput,
  UpdatePricingAdjustmentMutation,
  UpdatePricingAdjustmentMutationVariables,
} from '@scout/types';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { MdClose, MdSearch } from 'react-icons/md';
import { RouteComponentProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ConfirmationPrompt } from '../../components/ConfirmationPrompt';
import { Grid } from '../../components/Grid';
import { Loader } from '../../components/Loader';
import { Modal } from '../../components/Modal';
import { SingleDropdown } from '../../components/SelectDropdown/Single';
import { Tabs } from '../../components/Tabs';
import { TextInput } from '../../components/TextInput';
import { useToast } from '../../components/Toast/Toast';
import { Typography } from '../../components/Typography';
import { URLS } from '../../constants';
import { PricingLayout } from '../../layouts';
import { Accordion } from './Accordion';
import { Card } from './Card';
import { ClusterStats } from './ClusterStats';

const Header = styled.header`
  background: ${props => props.theme.base.dark.c};
  padding: 2rem 0 4rem;
`;

const Main = styled.main`
  background: ${props => props.theme.base.light.a};
`;

const Banner = styled.div`
  max-width: ${props => props.theme.containerWidth};
  margin: 0 auto;
  display: flex;
  padding-top: 2rem;
  z-index: 100;
`;

const Wrapper = styled.div`
  min-height: 90vh;
  padding-bottom: 100px;
`;

const SearchWrapper = styled.div`
  flex: 20%;
  display: flex;
  align-items: top;
`;

const SearchInput = styled.div`
  position: relative;
  width: 60%;
  margin-right: 0.5rem;
`;

const IconMixin = css`
  height: 40px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 0;
`;

const SearchIcon = styled(MdSearch)`
  ${IconMixin}
`;

const CloseIcon = styled(MdClose)`
  ${IconMixin}
  position: absolute;
  color: ${props => props.theme.primary.b};
`;

const Line = styled.span`
  display: block;
  width: 32px;
  height: 1px;
  border-bottom: 4px solid ${p => p.theme.primary.b};
  margin-top: 6px;
  margin-bottom: 15px;
`;

const Spacer = styled.div`
  margin: 20px 0 40px;
`;

export const UPDATE_PRICING_ADJUSTMENT_SUCCESS_MESSAGE = 'Prices updated.';
export const UPDATE_PRICING_ADJUSTMENT_FAILURE_MESSAGE = 'Error updating prices.';
type PricingProps = RouteComponentProps;

export interface PlantItemFormValuse {
  id: string;
  date: string;
  delta?: string;
  minVSFO?: string;
  maxVSFO?: string;
}

interface PlantFormValues {
  id: string;
  plantId: string;
  name: string;
  minVSFO?: string;
  maxVSFO?: string;
  items: PlantItemFormValuse[];
}

interface FormValues {
  regions: Array<{
    id: string;
    name: string;
    clusterId: string;
    plants: PlantFormValues[];
  }>;
}

const hasUpdated = (currentValues: object, newValues: object) => {
  for (const key in newValues) {
    if (newValues.hasOwnProperty(key) && currentValues.hasOwnProperty(key)) {
      // @ts-ignore
      const newValue = newValues[key] ?? null;
      // @ts-ignore
      const currentValue = currentValues[key] ?? null;

      if ((currentValue && currentValue !== Number(newValue)) || Boolean(newValue)) {
        return true;
      }
    }
  }

  return false;
};

const createInitialValues = (values: PricingAdjustmentFormDataQuery['pricingAdjustmentFormData']): FormValues => {
  const regions = values.reduce<FormValues['regions']>((results, value) => {
    const plantData = {
      id: value.id,
      plantId: value.plant.plantId,
      name: value.plant.name,
      minVSFO: value.plant.minVSFO ? String(value.plant.minVSFO) : undefined,
      maxVSFO: value.plant.maxVSFO ? String(value.plant.maxVSFO) : undefined,
      items: value.plant.items.map(item => ({
        id: item.id,
        date: item.date,
        delta: item.delta ? String(item.delta) : undefined,
        minVSFO: item.minVSFO ? String(item.minVSFO) : undefined,
        maxVSFO: item.maxVSFO ? String(item.maxVSFO) : undefined,
      })),
    };

    const regionExists = results.find(region => region.id === value.region.id);
    if (!regionExists) {
      return [
        ...results,
        {
          clusterId: value.cluster.id,
          id: value.region.id,
          name: value.region.name,
          plants: [plantData],
        },
      ];
    }

    regionExists.plants.push(plantData);

    return results;
  }, []);

  return { regions };
};

interface ClusterProps {
  id: string;
  label: string;
  currency: string;
}

const Pricing: React.FunctionComponent<PricingProps> = props => {
  const { push } = useToast();
  const [showModal, setShowModal] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [activeTab, setActiveTab] = React.useState('');
  const [clusters, setClusters] = React.useState<ClusterProps[]>([]);
  const [filteredYear, setFilteredYear] = React.useState(moment().year());

  const { loading, error, data } = useQuery<PricingAdjustmentFormDataQuery, PricingAdjustmentFormDataQueryVariables>(
    GET_PRICING_ADJUSTMENT_DATA,
    {
      fetchPolicy: 'no-cache',
      variables: {
        yearFilter: filteredYear,
      },
    },
  );
  const [setPricingAdjustmentUpdate] = useMutation<
    UpdatePricingAdjustmentMutation,
    UpdatePricingAdjustmentMutationVariables
  >(UPDATE_PRICING_ADJUSTMENT);

  React.useEffect(() => {
    if (clusters.length === 0 && data && data.pricingAdjustmentFormData) {
      if (!activeTab) {
        setActiveTab(data.pricingAdjustmentFormData[0].cluster.id);
      }
      const formattedClusters = data.pricingAdjustmentFormData.reduce<ClusterProps[]>((clusters, data) => {
        const exists = clusters.find(cluster => cluster.id === data.cluster.id);
        if (!exists) {
          return [
            ...clusters,
            {
              id: data.cluster.id,
              label: data.cluster.name,
              currency: data.cluster.currency,
            },
          ];
        }
        return clusters;
      }, []);

      setClusters(formattedClusters);
    }
  }, [JSON.stringify(data)]);

  const activeCluster = React.useMemo(() => {
    const cluster = clusters.find(item => item.id === activeTab);
    if (activeTab && !cluster) {
      console.error(`Cluster with id ${activeTab} not found`);
    }
    // @TODO handle the below better
    return cluster || clusters[0];
  }, [activeTab, clusters]);

  const matchPlantNameBySearch = (plantName: string) => plantName.toLowerCase().includes(search.toLowerCase());

  const getSearchResultsLength = () => {
    if (search && data && data.pricingAdjustmentFormData) {
      const formData = data.pricingAdjustmentFormData;
      const filteredPlants = formData.filter(item => matchPlantNameBySearch(item.plant.name));
      return `${filteredPlants.length} results across all clusters`;
    }

    return '';
  };

  const onSubmit = async (values: FormValues) => {
    if (!data) {
      return null;
    }

    const payload: PricingAdjustmentPlantInput[] = [];

    const plants = values.regions.reduce<PlantFormValues[]>((plants, region) => [...plants, ...region.plants], []);

    for (const plant of plants) {
      const currentPlant = data.pricingAdjustmentFormData.find(data => data.id === plant.id)?.plant ?? undefined;

      if (currentPlant) {
        const updatedPlant = hasUpdated(currentPlant, { minVSFO: plant.minVSFO, maxVSFO: plant.maxVSFO });
        const updatedPlantItems = plant.items.filter(item => {
          if (moment(item.date, 'YYYY-MM-DD').isBefore(moment(), 'month')) {
            return false;
          }

          const currentPlantItem = currentPlant.items.find(data => data.date === item.date);
          return (
            currentPlantItem &&
            hasUpdated(currentPlantItem, { delta: item.delta, minVSFO: item.minVSFO, maxVSFO: item.maxVSFO })
          );
        });

        if (updatedPlant || updatedPlantItems.length > 0) {
          payload.push({
            id: plant.id,
            minVSFO: updatedPlant ? Number(plant.minVSFO) : undefined,
            maxVSFO: updatedPlant ? Number(plant.maxVSFO) : undefined,
            items: updatedPlantItems.map(item => ({
              id: item.id,
              date: item.date,
              delta: Number(item.delta) ?? null,
              minVSFO: Number(item.minVSFO) ?? null,
              maxVSFO: Number(item.maxVSFO) ?? null,
            })),
          });
        }
      }
    }

    try {
      await setPricingAdjustmentUpdate({
        variables: { yearFilter: filteredYear, input: { payload } },
      });
      push({
        text: UPDATE_PRICING_ADJUSTMENT_SUCCESS_MESSAGE,
      });
    } catch (error) {
      console.error('Failed mutation at ', error);
      push({
        text: UPDATE_PRICING_ADJUSTMENT_FAILURE_MESSAGE,
        type: 'ERROR',
      });
    }
  };

  if (!data || !data.pricingAdjustmentFormData || loading || error) {
    return <Loader type="section" error={error && 'Something went wrong'} />;
  }

  return (
    <PricingLayout>
      <Header>
        <Grid>
          <Typography variant="heading4" color="bodyDark" bold={true}>
            Edit prices
          </Typography>
          <Line />
        </Grid>
      </Header>
      <Main>
        <Tabs items={clusters} selected={activeTab} onItemClick={id => setActiveTab(id)} pricing={true}>
          <Banner>
            <SearchWrapper>
              <SearchInput>
                <TextInput
                  themeStyle="light"
                  name="search"
                  placeholder="Search by plant name"
                  onChange={(e: React.ChangeEvent<{ value: string }>) => setSearch(e.target.value)}
                  value={search}
                />
                {search ? <CloseIcon onClick={() => setSearch('')} /> : <SearchIcon />}
              </SearchInput>

              <Typography tag="span" variant="body3">
                {getSearchResultsLength()}
              </Typography>
            </SearchWrapper>

            <SingleDropdown
              width="150px"
              name="date"
              placeholder=""
              onItemClick={item => setFilteredYear(item && item.value ? Number(item.value) : moment().year())}
              selected={String(filteredYear)}
              items={[
                moment()
                  .subtract(1, 'year')
                  .year(),
                moment().year(),
                moment()
                  .add(1, 'year')
                  .year(),
              ].map(item => ({
                label: String(item),
                value: String(item),
              }))}
              showClearIndicator={false}
              isClearable={false}
            />

            {activeCluster && <ClusterStats clusterId={activeCluster.id} clusterCurrency={activeCluster.currency} />}
          </Banner>

          <Formik
            initialValues={createInitialValues(data.pricingAdjustmentFormData)}
            onSubmit={async (values, formActions) => {
              await onSubmit(values);
              formActions.setSubmitting(false);
            }}
            render={formProps => {
              return (
                <Form>
                  <Modal
                    disabled={formProps.isSubmitting}
                    onConfirm={async () => {
                      formProps.setSubmitting(true);
                      await onSubmit(formProps.values);
                      props.history.push(URLS.ROOT);
                    }}
                    minWidth={450}
                    onDismiss={() => props.history.push(URLS.ROOT)}
                    isOpen={showModal}
                    confirmText="Yes"
                    dismissText="No"
                    confirmBtnVariant="primary"
                  >
                    <Spacer data-testid="cancel-modal">
                      <Typography variant="heading5" color="bodyDark" align="center" bold={true}>
                        Save changes?
                      </Typography>
                    </Spacer>
                    <Spacer>
                      <Typography variant="body3" color="bodyDark" align="center">
                        Do you want to save your changes before returning home?
                      </Typography>
                    </Spacer>
                  </Modal>

                  <Grid>
                    <Wrapper>
                      {formProps.values.regions.map((region, regionIndex) => {
                        return (
                          <div
                            key={`region-${region.id}`}
                            style={{
                              display: region.clusterId === activeTab ? 'block' : 'none',
                            }}
                          >
                            <Accordion
                              key={`accordion-${region.id}`}
                              title={`${region.name} (${
                                region.plants.filter(plant => matchPlantNameBySearch(plant.name)).length
                              })`}
                              regionId={region.id}
                              value={region.name}
                              onSubmit={value => formProps.setFieldValue(`regions.${regionIndex}.name`, value)}
                            >
                              {region.plants.map((plant, plantIndex) => {
                                const prefixName = `regions.${regionIndex}.plants.${plantIndex}`;
                                if (!search || matchPlantNameBySearch(plant.name)) {
                                  return (
                                    <Card
                                      key={`accordion-card-${prefixName}`}
                                      title={plant.name}
                                      subtitle={plant.plantId}
                                      prefixName={prefixName}
                                      plantValues={plant.items}
                                      onChange={(name, value) => {
                                        formProps.values.regions[regionIndex].plants[plantIndex].items.forEach(
                                          (item, itemIndex) => {
                                            if (moment(item.date).isSameOrAfter(moment(), 'month')) {
                                              formProps.setFieldValue(
                                                `regions.${regionIndex}.plants.${plantIndex}.items.${itemIndex}.${name}`,
                                                value ? Number(value) : undefined,
                                              );
                                            }
                                          },
                                        );
                                      }}
                                    />
                                  );
                                }
                              })}
                            </Accordion>
                          </div>
                        );
                      })}
                    </Wrapper>
                  </Grid>
                  <ConfirmationPrompt
                    disabled={formProps.isSubmitting}
                    primaryText="Save changes"
                    secondaryText="Return home"
                    onConfirmPrimary={formProps.submitForm}
                    onConfirmSecondary={() => {
                      if (Object.keys(formProps.touched).length > 0) {
                        setShowModal(true);
                      } else {
                        props.history.push(URLS.ROOT);
                      }
                    }}
                  />
                </Form>
              );
            }}
          />
        </Tabs>
      </Main>
    </PricingLayout>
  );
};

export { Pricing };
