import { contractDealTypeToUrlPart, makeURL, URLS } from '../constants';
import { ContractDealType } from '@scout/types';
import { VIEW_CLUSTER_PREFIX, VIEW_USER_PREFIX } from './facets';

const createContractDemandCreateLink = ({
  clusterId,
  contractType,
  dealId,
  from,
}: {
  clusterId: string;
  contractType: ContractDealType;
  dealId: string;
  from?: string;
}): string => {
  return makeURL(
    URLS.MID_TERM_CREATE_CONTRACT_DEMAND,
    {
      clusterId,
      dealId,
      contractType: contractDealTypeToUrlPart(contractType),
    },
    from != null ? { from } : undefined,
  );
};

const createForecastsLink = ({
  clusterId,
  dateId,
  viewerId,
  viewerType,
  yearId,
}: {
  clusterId: string;
  dateId?: string | null;
  viewerId?: string | null;
  viewerType?: 'Cluster' | 'User' | null;
  yearId?: string | null;
}): string => {
  const searchParams: Record<string, string> = {};

  if (dateId != null) {
    searchParams.date = dateId;
  }

  if (viewerId != null && viewerType != null) {
    const prefix = viewerType === 'Cluster' ? VIEW_CLUSTER_PREFIX : VIEW_USER_PREFIX;
    searchParams.view = `${prefix}${viewerId}`;
  }

  if (yearId != null) {
    searchParams.year = yearId;
  }

  return makeURL(
    URLS.MID_TERM_DASHBOARD,
    {
      clusterId,
    },
    searchParams,
  );
};

const createNewContractsLink = ({
  clusterId,
  viewerId,
  viewerType,
}: {
  clusterId: string;
  viewerId?: string | null;
  viewerType?: 'Cluster' | 'User' | null;
}): string => {
  const searchParams: Record<string, string> = {};

  if (viewerId != null && viewerType != null) {
    const prefix = viewerType === 'Cluster' ? VIEW_CLUSTER_PREFIX : VIEW_USER_PREFIX;
    searchParams.view = `${prefix}${viewerId}`;
  }

  return makeURL(
    URLS.MID_TERM_CONTRACTS,
    {
      clusterId,
    },
    searchParams,
  );
};

export { createContractDemandCreateLink, createForecastsLink, createNewContractsLink };
