import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '../../components/Typography';
import { URLS } from '../../constants';
import { VesselFlowAnalysisIcon } from '../../components/Icons/VesselFlowAnalysisIcon';
import { PowerBIIcon } from '../../components/Icons/PowerBIIcon';
import { Arrow } from '../../components/Icons/Arrow';
import { RequirePermission } from '../../components/RequirePermission';
import { DefaultLayout } from '../../layouts';
import { ScoutLogoIcon } from '../../components/Icons/ScoutLogoIcon';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 82px);
  background-color: ${p => p.theme.base.dark.a};
`;

const BoxContainer = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(307.9deg, ${p => p.theme.base.dark.a} 19.6%, ${p => p.theme.base.dark.b} 89.96%);
  border: 1px solid ${p => p.theme.base.dark.a};
  width: 300px;
  height: 200px;
  cursor: pointer;
  margin: 0 11px;
  border-radius: 2px;
  box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.15);
  text-decoration: none;

  :hover {
    border: 1px solid ${p => p.theme.primary.b};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  background: ${p => p.theme.base.dark.c};
  width: 100%;
  padding: 0 18px;

  ${BoxContainer}:hover & {
    background: ${p => p.theme.primary.b};
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 152px;
`;

interface BoxProps {
  children: React.ReactNode;
  icon: React.ComponentType;
  url: string;
}

const Box: React.FC<BoxProps> = ({ children, icon: Icon, url }) => (
  <BoxContainer to={url}>
    <IconContainer>
      <Icon />
    </IconContainer>
    <LabelContainer>
      <Typography bold={true} color="bodyDark">
        {children}
      </Typography>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    </LabelContainer>
  </BoxContainer>
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`;

export const HistoricalDataAnalysisPage: React.FC = () => {
  return (
    <DefaultLayout>
      <Container>
        <ScoutLogoIcon />
        <Typography variant="body1" color="bodyDark">
          Select application:
        </Typography>
        <Row>
          <RequirePermission anyOf={['marineTrafficExportManagement']}>
            <Box icon={PowerBIIcon} url={URLS.ROOT}>
              Vessel Flow Analysis Dashboard
            </Box>
          </RequirePermission>
          <RequirePermission anyOf={['marineTrafficExportManagement']}>
            <Box icon={VesselFlowAnalysisIcon} url={URLS.VESSEL_FLOW_ANALYSIS}>
              Vessel Flow Extraction
            </Box>
          </RequirePermission>
        </Row>
      </Container>
    </DefaultLayout>
  );
};
