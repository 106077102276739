import React from 'react';
import styled, { withTheme } from 'styled-components';

const IconContainer = styled.span`
  top: 0.125em;
  position: relative;
  margin-right: 4px;
`;

export const CircleTickIcon = withTheme(({ theme }) => (
  <IconContainer>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16675 6.99984C1.16675 3.77984 3.78008 1.1665 7.00008 1.1665C10.2201 1.1665 12.8334 3.77984 12.8334 6.99984C12.8334 10.2198 10.2201 12.8332 7.00008 12.8332C3.78008 12.8332 1.16675 10.2198 1.16675 6.99984ZM2.91675 6.99984L5.83342 9.9165L11.0834 4.6665L10.2609 3.83817L5.83342 8.26567L3.73925 6.17734L2.91675 6.99984Z"
        fill={theme.greys.light.border}
      />
    </svg>
  </IconContainer>
));
