import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

const Root = styled.div`
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9999;
`;

interface PlanningButtonProps {
  onClick: () => void;
  user?: string;
}

export const PlanningButton: React.FunctionComponent<PlanningButtonProps> = ({ onClick, user }) => {
  if (user === 'east') {
    return (
      <Root>
        <Button data-testid="planning-button" onClick={onClick} variant="secondary" icon="MdPlaylistAdd">
          Planning
        </Button>
      </Root>
    );
  } else {
    return (
      <Root>
        <Button data-testid="planning-button" onClick={onClick} variant="ghost-dark">
          Planning
        </Button>
      </Root>
    );
  }
};
