import React from 'react';
import styled from 'styled-components';
import { UIDrawerProps } from './IDrawer';

const Container = styled.div<{ side: string }>`
  display: flex;
  height: 100%;
  flex-direction: ${props => (props.side === 'left' ? 'row-reverse' : 'row')};
`;

const MainElementsWrapper = styled.div`
  flex: 4;
`;

const DrawerElementsWrapper = styled.div`
  width: 814px;
  height: 100%;
  top: 0;
  z-index: 20;
  transition: all 0.5s ease;
  display: flex;
`;
DrawerElementsWrapper.displayName = 'DrawerContent';

const DrawerElementsWrapperFullScreen = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 20;
  transition: all 0.5s ease;
  display: flex;
`;
DrawerElementsWrapper.displayName = 'DrawerContentFullScreen';

export const PushDrawer: React.FunctionComponent<UIDrawerProps> = ({
  mainElements,
  drawerElements,
  side,
  isVisible,
  isFullScreen,
}) => {
  return (
    <Container side={side}>
      <MainElementsWrapper>{mainElements}</MainElementsWrapper>
      {isVisible && !isFullScreen && (
        <DrawerElementsWrapperFullScreen>{drawerElements}</DrawerElementsWrapperFullScreen>
      )}
      {isFullScreen && isVisible && <DrawerElementsWrapper>{drawerElements}</DrawerElementsWrapper>}
    </Container>
  );
};
