import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../Typography';

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  white-space: nowrap;
`;

const Row = styled.tr<{ currentUserTheme?: boolean }>`
  cursor: pointer;

  &:hover > td {
    background-color: ${p => (p.currentUserTheme ? p.theme.base.light.b : p.theme.base.dark.c)};
  }
`;

interface BorderMixinProps {
  border?: 'large' | 'small';
  currentUserTheme?: boolean;
}

type CellProps = BorderMixinProps & {
  rowSpan?: number;
  colSpan?: number;
  justify?: 'flex-start' | 'center' | 'flex-end';
  sticky?: 'left' | 'top' | 'topleft' | 'bottom' | 'bottomLeft';
  text?: string;
  left?: number;
  rightBorderShadow?: boolean;
};

const borderMixin = (props: BorderMixinProps) => {
  if (props.border === 'large') {
    if (props.currentUserTheme) {
      return css`
        border-right: 1px solid ${p => p.theme.base.light.d};
        height: 100%;
      `;
    } else {
      return css`
        border-right: 1px solid ${p => p.theme.base.dark.d};
        height: 100%;
      `;
    }
  }
  if (props.border === 'small') {
    if (props.currentUserTheme) {
      return css`
        border-right: 1px solid ${p => p.theme.base.light.d};
      `;
    } else {
      return css`
        border-right: 1px solid ${p => p.theme.greys.dark.border};
      `;
    }
  }
  return null;
};

const stickyMixin = (props: CellProps) => {
  if (props.sticky === 'left') {
    return css`
      position: sticky;
      left: ${props.left || 0}px;
      z-index: 1;
    `;
  }

  if (props.sticky === 'top') {
    return css`
      position: sticky;
      top: ${props.rowSpan || props.colSpan ? '0' : '31px'};
    `;
  }

  if (props.sticky === 'topleft') {
    return css`
      position: sticky;
      top: ${props.rowSpan || props.colSpan ? '0' : '31px'};
      left: ${props.left || 0}px;
      z-index: 2;
    `;
  }

  if (props.sticky === 'bottom') {
    return css`
      position: sticky;
      bottom: 0;
      z-index: 2;
    `;
  }

  if (props.sticky === 'bottomLeft') {
    return css`
      position: sticky;
      bottom: 0;
      z-index: 3;
      left: ${props.left || 0}px;
    `;
  }

  return null;
};

const Th = styled.th<{ currentUserTheme?: boolean }>`
  background-color: ${p => (p.currentUserTheme ? p.theme.base.light.b : p.theme.base.dark.a)};
  font-weight: ${p => (p.currentUserTheme ? 'bold' : 'normal')};
  padding: 0;
  margin: 0;
  height: ${p => (p.rowSpan ? '62px' : '0')};
  ${stickyMixin}
`;

const cellMixin = css<CellProps>`
  display: flex;
  justify-content: ${p => p.justify};
  align-items: center;
  padding: 0 15px;
`;

const Inner = styled.div<CellProps>`
  ${cellMixin}
  ${borderMixin}

  ${p =>
    p.rightBorderShadow &&
    (p.currentUserTheme
      ? 'box-shadow:9px 2px 17px -4px rgba(0, 0, 0, 0);'
      : 'box-shadow:9px 2px 17px -4px rgba(0, 0, 0, 0.5);')}
`;

const HeaderCell: React.FC<CellProps> = ({
  children,
  text,
  border,
  rightBorderShadow,
  justify = 'center',
  currentUserTheme,
  ...rest
}) => (
  <Th currentUserTheme={currentUserTheme} {...rest}>
    <Inner justify={justify} border={border} rightBorderShadow={rightBorderShadow} currentUserTheme={currentUserTheme}>
      {children ? (
        children
      ) : (
        <Typography variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
          {text}
        </Typography>
      )}
    </Inner>
  </Th>
);

const CellWrapper = styled.td<CellProps>`
  padding: 0;
  height: 62px;

  ${stickyMixin}
  background-color: ${p => {
    if (p.sticky === 'bottomLeft') {
      return p.currentUserTheme ? p.theme.base.light.b : p.theme.base.dark.c;
    }
    if (p.sticky === 'bottom') {
      return p.currentUserTheme ? p.theme.base.light.b : p.theme.base.dark.d;
    }
    return p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.b;
  }};
`;

const Cell: React.FC<CellProps> = ({
  children,
  justify,
  border,
  rightBorderShadow,
  sticky,
  text,
  currentUserTheme,
  ...rest
}) => (
  <CellWrapper {...rest} sticky={sticky} currentUserTheme={currentUserTheme}>
    <Inner justify={justify} border={border} rightBorderShadow={rightBorderShadow} currentUserTheme={currentUserTheme}>
      {children != null ? (
        children
      ) : (
        <Typography variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
          {text}
        </Typography>
      )}
    </Inner>
  </CellWrapper>
);

export { Cell, HeaderCell, Row, Table };
