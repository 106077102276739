import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../../../components/Typography';
import { Separator } from '../Separator';
import { GradeSplitStepDemandRow } from '../types';
import { GradeSplitDemand } from './Demand';

const DemandsHeadingSeparator = styled(Separator)`
  margin-bottom: 17px;
`;

const DemandsSeparator = styled(Separator)`
  margin: 17px 0;
`;

interface Props {
  rows: GradeSplitStepDemandRow[];
  heading: string;
  name: string;
}

const GradeSplitDemands = ({ heading, name, rows }: Props) => (
  <div>
    <Typography bold={true} variant="heading5">
      {heading}
    </Typography>
    <DemandsHeadingSeparator />

    {rows.map((row, index) => (
      <React.Fragment key={row.id}>
        <GradeSplitDemand key={row.id} name={`${name}.${index}`} row={row} />
        {index !== rows.length - 1 && <DemandsSeparator type="dashed" />}
      </React.Fragment>
    ))}
  </div>
);

export { GradeSplitDemands };
