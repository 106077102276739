import * as React from 'react';
import styled from 'styled-components';

const Divider = styled.hr`
  border: 1px solid ${props => props.theme.greys.light.border};
  margin: 15px 0;
`;

const HorizontalDivider: React.FunctionComponent = () => {
  return <Divider />;
};

export { HorizontalDivider };
