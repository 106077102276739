import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { FontColors } from '../../theme';

const Container = styled.div<{ fixedWidth: boolean; color: FontColors }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  height: 24px;
  padding: 0 6px;
  border-radius: 2px;
  border: 1px solid ${p => p.theme.typography.colors[p.color || 'highlight']};
  ${p => p.fixedWidth && 'width: 42px'}
  height: 18px;
`;

const Tag = ({
  children,
  className,
  color = 'highlight',
  fixedWidth = true,
  'data-testid': dataTestId,
}: {
  children: React.ReactNode;
  className?: string;
  color?: FontColors;
  fixedWidth?: boolean;
  'data-testid'?: string;
}) => (
  <Container className={className} color={color} fixedWidth={fixedWidth} data-testid={dataTestId}>
    <Typography variant="labels1" color={color}>
      {children}
    </Typography>
  </Container>
);

export { Tag };
