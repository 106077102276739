import React from 'react';
import styled from 'styled-components';

const Main = styled.main`
  background: ${props => props.theme.base.light.b};
  min-height: 100%;
`;

const ContractDemandLayout: React.FC = ({ children }) => {
  return <Main>{children}</Main>;
};

export { ContractDemandLayout };
