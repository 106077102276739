import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import { Typography } from '../../../../components/Typography';

const Spacer = styled.div<{ size?: number }>`
  height: ${props => 20 * (props.size || 1)}px;
`;

export interface Props {
  showModal: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

const SaveChangesModal: React.FC<Props> = props => {
  return (
    <Modal
      data-testid="save-changes-modal"
      onConfirm={props.onConfirm}
      isOpen={props.showModal}
      confirmText="Save and close"
      confirmBtnVariant="primary"
      dismissText="Discard changes"
      onDismiss={props.onDismiss}
    >
      <Spacer />
      <Typography variant="heading5" color="bodyDark" align="center" bold={true}>
        Would you like to save your changes before closing?
      </Typography>
      <Spacer />
    </Modal>
  );
};

export { SaveChangesModal };
