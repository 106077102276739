import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserThemeContext } from '../../../App';
import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';

const Root = styled.div<{ currentUserTheme?: boolean }>`
  background: ${props => (props.currentUserTheme ? props.theme.secondary.light.c : props.theme.base.dark.c)};
  bottom: 0;
  height: 4.125rem;
  position: fixed;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

const LeftAlignedButton = styled(Button)`
  margin-right: auto;
`;
const RightAlignedButton = styled(Button)`
  margin-left: auto;
`;

interface Props {
  nextButtonText?: string;
  onBack?: () => void;
  onNext: () => void;
}

const Footer = ({ nextButtonText = 'Next', onBack, onNext }: Props) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  return (
    <Root currentUserTheme={currentUserTheme}>
      <Container>
        <Flex>
          {onBack != null && (
            <LeftAlignedButton onClick={onBack} variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}>
              Back
            </LeftAlignedButton>
          )}
          <RightAlignedButton onClick={onNext} variant="primary" data-testid="footer-next-button">
            {nextButtonText}
          </RightAlignedButton>
        </Flex>
      </Container>
    </Root>
  );
};

export { Footer };
