import * as React from 'react';
import { Loader } from '../../components/Loader';
import { DefaultLayout } from '../../layouts';

const ErrorPage = () => (
  <DefaultLayout>
    <Loader error="Something went wrong." />
  </DefaultLayout>
);

export { ErrorPage };
