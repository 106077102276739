import React, { useState } from 'react';
import { DrawerProps } from './IDrawer';
import { OverlayDrawer } from './OverlayDrawer';
import { PushDrawer } from './PushDrawer';

export const Drawer: React.FunctionComponent<DrawerProps> = ({
  children,
  drawerContent,
  isOverlay = true,
  side = 'right',
  isFullScreen,
}) => {
  const [isVisible, setVisibility] = useState<boolean>(false);
  const Renderer = isOverlay ? OverlayDrawer : PushDrawer;

  return (
    <Renderer
      mainElements={children(() => setVisibility(true), isVisible)}
      drawerElements={drawerContent(() => setVisibility(false))}
      side={side}
      isVisible={isVisible}
      isFullScreen={isFullScreen}
    />
  );
};
