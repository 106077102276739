import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { useToast } from '../../../../components/Toast/Toast';
import { URLS } from '../../../../constants';
import { getPreviousStateParams } from '../../../../utils';
import { DateDropdown } from './DateDropdown';
import { useSchedulerDate, useSchedulerLocationId } from './hooks';
import { LocationDropdown } from './LocationDropdown';
import { LocationsQuery } from '@scout/types';
import { createDownloadFn } from './download';
import { createDownloadInMonthMultiFn } from './downloadInMonthMulti';

interface MenuProps {
  onShowNotesModal: () => void;
  onCloseRequest: () => void;
  locations: LocationsQuery['locations'];
}

const Container = styled.div`
  padding: 0 16px;
  background: ${props => props.theme.base.dark.c};
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;

  & > * {
    margin-left: 8px;
  }

  & > *:first-child {
    margin-left: 0;
  }
`;

const DownloadButton = styled(Button).attrs({ icon: 'MdFileDownload', variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
`;

const NotesButton = styled(Button).attrs({ icon: 'MdNoteAdd', variant: 'ghost-dark' })`
  width: 40px;
  justify-content: center;
`;

export const Menu: React.FunctionComponent<MenuProps> = ({ onCloseRequest, onShowNotesModal, locations }) => {
  const history = useHistory();
  const location = useLocation();
  const downloadReport = createDownloadFn(useSchedulerDate()[0], useSchedulerLocationId()[0], useToast());
  const downloadInMonthMultiReport = createDownloadInMonthMultiFn(useSchedulerDate()[0], useToast());

  return (
    <Container>
      <SubContainer>
        <LocationDropdown locations={locations} />
        <DateDropdown />
      </SubContainer>
      <SubContainer>
        <NotesButton onClick={onShowNotesModal} />
        <DownloadButton
          onClick={async () => {
            await downloadReport();
          }}
        />
        <DownloadButton
          onClick={async () => {
            await downloadInMonthMultiReport();
          }}
        />
        <Button icon="MdAdd" onClick={() => history.push(getPreviousStateParams(`${URLS.IN_MONTH}/new`, location))}>
          New Movement
        </Button>
        <Button icon="MdChevronLeft" variant="ghost-dark" onClick={onCloseRequest} />
      </SubContainer>
    </Container>
  );
};
