import { DashboardRowType, DemandType } from './types';
import { SecondaryFilterOptionType } from '../../../components/SecondaryFilter';

const DEMAND_TYPE_SCORE = {
  [DemandType.Fixed]: 1,
  [DemandType.Rolling]: 2,
  [DemandType.Formula]: 3,
  [DemandType.Spot]: 4,
};

interface FilterAndSortDemandsInput {
  demands: DashboardRowType[];
  filters: {
    plant?: string[];
    soldTo?: string[];
    demandType?: string[];
  };
}

export const filterAndSortDemands = ({ demands, filters }: FilterAndSortDemandsInput): DashboardRowType[] => {
  return demands
    .filter(({ primaryPenPlant, primaryPremiumPlant, customer, demandType }) => {
      const hasPlantMatch =
        !filters.plant ||
        (primaryPenPlant && filters.plant.includes(primaryPenPlant.shortName)) ||
        (primaryPremiumPlant && filters.plant.includes(primaryPremiumPlant.shortName));
      const hasCustomerMatch = !filters.soldTo || filters.soldTo.includes(customer.id);
      const hasDemandTypeMatch = !filters.demandType || filters.demandType.includes(demandType);

      return hasPlantMatch && hasCustomerMatch && hasDemandTypeMatch;
    })
    .sort((a, b) => {
      if (a.customer.name > b.customer.name) {
        return 1;
      } else if (a.customer.name < b.customer.name) {
        return -1;
      } else {
        const aDemandScore = DEMAND_TYPE_SCORE[a.demandType];
        const bDemandScore = DEMAND_TYPE_SCORE[b.demandType];
        return aDemandScore - bDemandScore;
      }
    });
};
