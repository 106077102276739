import React from 'react';
import styled, { keyframes, withTheme } from 'styled-components';
import { Typography } from '../Typography';
import { IThemeType } from '../../theme';

const rotateMove = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Spinner = styled.svg`
  animation: ${rotateMove} 0.5s ease-in-out infinite;
  margin-bottom: 8px;
`;

export const SectionSpinner: React.FC<{ theme?: IThemeType; text?: string }> = withTheme(({ theme, text }) => (
  <Container data-testid="section-spinner">
    <Spinner width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="42">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38Z"
          fill={theme.base.dark.a}
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38Z"
          fill={theme.base.dark.a}
        />
        <path d="M21.5 21.5V-5.5H61V21.5H21.5Z" fill={theme.primary.b} />
      </g>
    </Spinner>
    {text && (
      <Typography variant="labels1" color="bodyDark">
        {text}
      </Typography>
    )}
  </Container>
));
