import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const PriceForecastingIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M35.4009 32.7C28.5909 30.93 26.4009 29.1 26.4009 26.25C26.4009 22.98 29.4309 20.7 34.5009 20.7C39.8409 20.7 41.8209 23.25 42.0009 27H48.6309C48.4209 21.84 45.2709 17.1 39.0009 15.57V9H30.0009V15.48C24.1809 16.74 19.5009 20.52 19.5009 26.31C19.5009 33.24 25.2309 36.69 33.6009 38.7C41.1009 40.5 42.6009 43.14 42.6009 45.93C42.6009 48 41.1309 51.3 34.5009 51.3C28.3209 51.3 25.8909 48.54 25.5609 45H18.9609C19.3209 51.57 24.2409 55.26 30.0009 56.49V63H39.0009V56.55C44.8509 55.44 49.5009 52.05 49.5009 45.9C49.5009 37.38 42.2109 34.47 35.4009 32.7Z"
        fill={theme.secondary.light.c}
      />
    </g>
  </svg>
));
