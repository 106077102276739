import * as React from 'react';
import { LabelValue } from '../../../../components/LabelValue';
import { VolumePricingItem } from '../../../../types';
import { Row, Col } from '../../../../components/Grid';
import styled from 'styled-components';

export type CalculateProps = { currentYearNumber: number } & Pick<TotalRowProps, 'values'>;

const calculateVolumeTotal = ({ values, currentYearNumber }: CalculateProps) => {
  return values.reduce((sum, current) => {
    const valueYear = new Date(current.date).getFullYear();

    if (currentYearNumber === valueYear) {
      return sum + Number(current.volume);
    }

    return sum;
  }, 0);
};

const TotalLabelValue = styled(LabelValue)`
  margin-top: 15px;
  margin-right: 50px;
`;

interface TotalRowProps {
  index: number;
  values: VolumePricingItem[];
}

const TotalRow = ({ index, values }: TotalRowProps) => {
  const totalValues = values.length;
  const currentValues = values[index];
  const isLast = totalValues - 1 === index;
  const currentMonth = new Date(currentValues.date).getMonth();
  const currentYearNumber = new Date(currentValues.date).getFullYear();
  const isDec = currentMonth === 11;

  if (!isLast && !isDec) {
    return null;
  }

  const value = calculateVolumeTotal({ values, currentYearNumber });

  return (
    <Row>
      <Col size={3}>
        <TotalLabelValue label="Total volume:" value={`${Math.round(value)}t`} />
      </Col>
    </Row>
  );
};

export { TotalRow, calculateVolumeTotal };
