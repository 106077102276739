import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelValueColumn = styled(Column)`
  margin: 5px;
  align-items: flex-end;
`;

export const LabelValue: React.FunctionComponent<{
  className?: string;
  label: string;
  value: string;
  optionalText?: string;
  darkTheme?: boolean;
  currentUserTheme?: boolean;
}> = ({ className, label, value, optionalText, darkTheme, currentUserTheme }) => (
  <LabelValueColumn className={className}>
    <Typography variant="body3" align="right" color={darkTheme ? 'bodyDark' : 'bodyLight'}>
      {label}
    </Typography>
    <Row>
      <Typography variant="body1" bold={true} align="right" color={darkTheme ? 'bodyDark' : 'bodyLight'}>
        {value}
      </Typography>
    </Row>
    {optionalText && (
      <Typography variant="labels1" align="right" color={currentUserTheme ? 'hintDark' : 'hintLight'}>
        {optionalText}
      </Typography>
    )}
  </LabelValueColumn>
);
