import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const FilterIcon: React.FC<{ theme?: IThemeType; currentUserTheme?: boolean }> = withTheme(
  ({ theme, currentUserTheme }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6V8H21V6H3ZM10 18H14V16H10V18ZM18 13H6V11H18V13Z"
        fill={currentUserTheme ? theme.base.dark.a : theme.secondary.light.c}
      />
    </svg>
  ),
);
