import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { Calendar } from '../../../components/Calendar';
import { CloseButton } from '../../../components/CloseButton';
import { Loader } from '../../../components/Loader';
import { MarkerType } from '../../../components/Map/Mapbox';
import { GET_LATEST_STOCK_DATES, LatestStockDatesQuery } from '@scout/types';
import { HeaderTitle } from '../HeaderTitle';
import { StockContainer } from './StocksContainer';
import { LocationTypes, YYYYMMDD } from '../../../constants';
import { WeatherLink } from '../../../components/WeatherLink';

export interface SidebarProps {
  onCloseRequest: () => void;
  plant: MarkerType | null;
  currentUserTheme?: boolean;
}

const Container = styled.div<{ currentUserTheme?: boolean }>`
  background: ${props => (props.currentUserTheme ? props.theme.base.light.a : props.theme.base.dark.b)};
  position: relative;
  width: 408px;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

const PullRight = styled.div`
  margin-left: auto;
`;

const CloseIconContainer = styled.div`
  margin-right: 10px;
`;

const Header = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.a)};
  padding: 22px;
  height: 66px;
`;

const SubHeader = styled.div<{ currentUserTheme?: boolean }>`
  padding: 0px 27px;
  margin-top: 15px;
  color: ${p => (p.currentUserTheme ? p.theme.base.dark.a : p.theme.secondary.light.c)};
`;

const weatherLinkText = 'Weather Info';

const StocksSidebar: React.FunctionComponent<SidebarProps> = ({ plant, onCloseRequest, currentUserTheme }) => {
  const plantId = plant ? plant.id : null;
  const plantType = plant ? plant.type : null;
  const [filterDate, setFilterDate] = useState<Moment>(moment());
  const { data, loading, error } = useQuery<LatestStockDatesQuery>(GET_LATEST_STOCK_DATES, {
    skip: plantType !== LocationTypes.Refinery || !plantId,
    variables: { plantId, filterDate: moment().format(YYYYMMDD) },
  });

  let latestStockDates = [];
  let initialDate = moment().startOf('day');

  if (data !== undefined && data.latestStockDates.length > 0) {
    latestStockDates = data.latestStockDates;
    initialDate = moment(latestStockDates[0], YYYYMMDD);
  }
  // reset the calendar when changing plant
  useEffect(() => {
    setFilterDate(initialDate);
  }, [plantId, initialDate.format(YYYYMMDD)]);

  if (!plant) {
    return null;
  }

  return (
    <Container currentUserTheme={currentUserTheme}>
      {plantType === LocationTypes.Refinery && (loading || error || !data || !data.latestStockDates) ? (
        <>
          <Header currentUserTheme={currentUserTheme}>
            <CloseIconContainer>
              <CloseButton onClick={onCloseRequest} currentUserTheme={currentUserTheme} />
            </CloseIconContainer>

            <HeaderTitle text={plant.name} currentUserTheme={currentUserTheme} />
          </Header>
          <SubHeader currentUserTheme={currentUserTheme}>
            <WeatherLink port={plant.id} currentUserTheme={currentUserTheme}>
              {weatherLinkText}
            </WeatherLink>
          </SubHeader>
          <LoadingContainer>
            <Loader type="section" error={error && 'Something went wrong'} />
          </LoadingContainer>
        </>
      ) : (
        <>
          <Header currentUserTheme={currentUserTheme}>
            <CloseIconContainer>
              <CloseButton onClick={onCloseRequest} currentUserTheme={currentUserTheme} />
            </CloseIconContainer>

            <HeaderTitle text={plant.name} currentUserTheme={currentUserTheme} />
            <PullRight>
              <Calendar
                goToCurrentBtnText="Go to current stock"
                align="right"
                themeStyle={currentUserTheme ? 'light' : 'dark'}
                value={filterDate}
                onChange={date => {
                  setFilterDate(date);
                }}
                onGoToCurrent={() => {
                  setFilterDate(initialDate);
                }}
                currentUserTheme={currentUserTheme}
              />
            </PullRight>
          </Header>
          <SubHeader currentUserTheme={currentUserTheme}>
            <WeatherLink port={plant.id} currentUserTheme={currentUserTheme}>
              {weatherLinkText}
            </WeatherLink>
          </SubHeader>
          <StockContainer
            plant={plant}
            filterDate={filterDate}
            onDateChange={date => setFilterDate(date)}
            currentUserTheme={currentUserTheme}
          />
        </>
      )}
    </Container>
  );
};

export { StocksSidebar };
