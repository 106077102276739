import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { RequirePermission } from '../../../components/RequirePermission';
import { TITLE_SUSHI, URLS } from '../../../constants';
import { LocationView } from './LocationView/LocationView';
import { EditMovementView } from './MovementView/EditMovementView';
import { NewMovementView } from './MovementView/NewMovementView';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;
const EastContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-bottom: 25px;
  position: relative;
`;

export interface SchedulerProps {
  onCloseRequest: () => void;
  toggleFullHalfView: (value: boolean) => void;
  fullViewActive: boolean;
  currentUserTheme?: boolean;
  toggleTheme: () => void;
}

export const Scheduler: React.FunctionComponent<SchedulerProps> = ({
  onCloseRequest,
  toggleFullHalfView,
  fullViewActive,
  currentUserTheme,
  toggleTheme,
}) => {
  const match = useRouteMatch();

  if (!match) {
    return null;
  }
  useEffect(() => {
    document.title = TITLE_SUSHI;
  }, []);
  return (
    <>
      <RequirePermission anyOf={['editFleetManagement']}>
        <Container data-testid="scheduler">
          <Switch>
            <Route exact={true} path={`${URLS.IN_MONTH}/new`}>
              <NewMovementView />
            </Route>
            <Route exact={true} path={`${URLS.IN_MONTH}/edit/:movementId`}>
              <EditMovementView />
            </Route>
            <Route path={`${URLS.IN_MONTH}/:locationId?`}>
              <LocationView
                onCloseRequest={onCloseRequest}
                toggleFullHalfView={toggleFullHalfView}
                fullViewActive={fullViewActive}
                toggleTheme={toggleTheme}
              />
            </Route>
          </Switch>
        </Container>
      </RequirePermission>
      <RequirePermission anyOf={['editEastSchedulingManager', 'eastDepotUser']}>
        <EastContainer data-testid="scheduler">
          <Switch>
            <Route path={`${URLS.EAST_IN_MONTH}/:locationId?`}>
              <LocationView
                onCloseRequest={onCloseRequest}
                toggleFullHalfView={toggleFullHalfView}
                fullViewActive={fullViewActive}
                currentUserTheme={currentUserTheme}
                toggleTheme={toggleTheme}
              />
            </Route>
          </Switch>
        </EastContainer>
      </RequirePermission>
    </>
  );
};
