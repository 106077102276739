import * as React from 'react';
import styled from 'styled-components';
import { LocationTypes } from '../../constants';

import { config } from '../../config';
import { MarkerType } from './Mapbox';

export interface LocationMarkerProps {
  marker: MarkerType;
  isSelected: boolean;
}

const Container = styled.div<{ marker: MarkerType }>`
  position: relative;
  cursor: ${({ marker }) => (marker.active ? 'pointer' : 'auto')};
`;

const Label = styled.span`
  position: absolute;
  margin-left: 5px;
  white-space: nowrap;
  text-shadow: 1px 1px 2px ${p => p.theme.base.dark.b}, 1px -1px 2px ${p => p.theme.base.dark.b},
    -1px 1px 2px ${p => p.theme.base.dark.b}, -1px -1px 2px ${p => p.theme.base.dark.b};
`;

const getMarkerSize = ({ markerType }: { markerType: LocationTypes }) => {
  if (markerType === LocationTypes.Customer) {
    return `width: 17px; height: 17px;`;
  }

  return `width: 24px; height: 32px;`;
};

const Icon = styled.img<{ markerType: LocationTypes }>`
  ${getMarkerSize}
`;

const getMarkerName = (isActive: boolean, isSelected: boolean) => {
  if (isActive && isSelected) {
    return '-selected';
  } else if (!isActive) {
    return '-disabled';
  } else {
    return '';
  }
};

export const LocationMarker = ({ marker, isSelected }: LocationMarkerProps) => (
  <Container marker={marker}>
    <Icon
      data-testid="location-marker-icon"
      markerType={marker.type}
      src={`${config.PUBLIC_URL}/images/marker-${marker.type}${getMarkerName(marker.active, isSelected)}.svg`}
      alt="marker"
    />
    <Label data-testid="location-marker-label">{marker.name}</Label>
  </Container>
);
