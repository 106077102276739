import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { URLS } from '../../../../constants';
import * as Dates from './dates';
import { InMonth } from '@scout/domains';
import {
  CREATE_LOCATION_NOTE,
  GET_NEW_LOCATION_NOTES_FORM_DATA,
  REMOVE_LOCATION_NOTE,
  UPDATE_LOCATION_NOTE,
  GetNewLocationNotesFormDataQuery,
  CreateLocationNoteMutationVariables,
  CreateLocationNoteMutation,
  LocationNotesQueryResult,
  UpdateLocationNoteMutation,
  UpdateLocationNoteMutationVariables,
  MutationRemoveLocationNoteArgs,
  RemoveLocationNoteMutation,
} from '@scout/types';
import { LocationNoteFormValues } from './LocationNoteModal';
import { useToast } from '../../../../components/Toast/Toast';
import { parseUpdateLocationNoteInput, parseCreateLocationNoteInput } from '../utils';
import { usePermission } from '../../../../hooks/usePermission';

export const useUpsertLocationNote = (
  formData: LocationNoteFormValues,
  selectedLocationNote: LocationNotesQueryResult[0] | null,
  locations: GetNewLocationNotesFormDataQuery['locations'],
) => {
  const [createLocationNote, createLocationNoteResult] = useMutation<
    CreateLocationNoteMutation,
    CreateLocationNoteMutationVariables
  >(CREATE_LOCATION_NOTE);
  const [updateLocationNote, updateLocationNoteResult] = useMutation<
    UpdateLocationNoteMutation,
    UpdateLocationNoteMutationVariables
  >(UPDATE_LOCATION_NOTE);

  const { push } = useToast();

  const editing = selectedLocationNote !== null;

  const upsertLocationNote = async () => {
    try {
      if (editing) {
        const input = parseUpdateLocationNoteInput({
          selectedLocationNoteId: selectedLocationNote?.id,
          formData,
        });

        if (!input || !selectedLocationNote) {
          push({
            type: 'ERROR',
            text: 'Error could not update location note. Missing required fields.',
          });
        } else {
          await updateLocationNote({
            variables: {
              input,
            },
          });

          push({ type: 'SUCCESS', text: 'Location note saved successfully' });
          return selectedLocationNote.locationId || selectedLocationNote.locationType;
        }
      } else {
        const input = parseCreateLocationNoteInput(formData, locations);

        if (!input) {
          push({
            type: 'ERROR',
            text: 'Error could not create location note. Missing required fields.',
          });
        } else {
          await createLocationNote({ variables: { input } });
          push({ type: 'SUCCESS', text: 'Location note created successfully' });
          return input.locationId || input.locationType;
        }
      }
    } catch (error) {
      push({
        type: 'ERROR',
        text: 'Error could not save location note. Please try again.',
      });
      return null;
    }
  };

  return { upsertLocationNote, isUpsertLoading: createLocationNoteResult.loading || updateLocationNoteResult.loading };
};

export const useDeleteLocationNote = (selectedLocationNote: LocationNotesQueryResult[0] | null) => {
  const { push } = useToast();
  const [removeLocationNote, removeLocationNoteResult] = useMutation<
    RemoveLocationNoteMutation,
    MutationRemoveLocationNoteArgs
  >(REMOVE_LOCATION_NOTE);

  const deleteLocationNote = async () => {
    const errorMessage = 'Error could not delete location note. Please try again.';
    try {
      if (!selectedLocationNote) {
        push({
          type: 'ERROR',
          text: errorMessage,
        });
      } else {
        await removeLocationNote({ variables: { locationNoteId: selectedLocationNote.id } });
        push({ type: 'SUCCESS', text: 'Location note deleted successfully' });

        return selectedLocationNote.locationId || selectedLocationNote.locationType;
      }
    } catch (error) {
      push({
        type: 'ERROR',
        text: errorMessage,
      });

      return null;
    }
  };
  return { deleteLocationNote, isDeleteLoading: removeLocationNoteResult.loading };
};

export const useLoadLocationData = () => {
  const getFormDataResult = useQuery<GetNewLocationNotesFormDataQuery, GetNewLocationNotesFormDataQuery>(
    GET_NEW_LOCATION_NOTES_FORM_DATA,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return {
    isDataloading: getFormDataResult.loading,
    isDataError: Boolean(getFormDataResult.error),
    data: getFormDataResult.data,
  };
};

export const useSchedulerLocationId = (): [string | undefined, (newLocation: string) => void] => {
  const { locationId } = useParams();
  const history = useHistory();
  const { hasAnyOf } = usePermission();

  if (location.pathname.includes(URLS.SIMULATION)) {
    const setLocationId = (newLocation: string): void => {
      history.push({
        pathname: `${URLS.SIMULATION}/${newLocation}`,
        search: history.location.search,
      });
    };
    return [locationId, setLocationId];
  } else if (hasAnyOf(['editEastSchedulingManager', 'viewEastSchedulingManager', 'eastDepotUser'])) {
    const setLocationId = (newLocation: string): void => {
      history.push({
        pathname: `${URLS.EAST_IN_MONTH}/${newLocation}`,
        search: history.location.search,
      });
    };
    return [locationId, setLocationId];
  } else {
    const setLocationId = (newLocation: string): void => {
      history.push({
        pathname: `${URLS.IN_MONTH}/${newLocation}`,
        search: history.location.search,
      });
    };
    return [locationId, setLocationId];
  }
};

export const useSchedulerDate = (): [string, (newDate: string) => void] => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const setDate = (newDate: string): void => {
    history.push({
      search: `?date=${newDate}`,
    });
  };

  return [query.get('date') || InMonth.DEFAULT_DATE, setDate];
};
