import React from 'react';
import { usePermission } from '../../hooks/usePermission';
import { Permission } from '../../types';

interface Props {
  anyOf: Permission[];
}

const RequirePermission: React.FC<Props> = ({ anyOf, children }) => {
  const { hasAnyOf } = usePermission();

  if (!hasAnyOf(anyOf)) {
    return null;
  }

  return <>{children}</>;
};

export { RequirePermission };
