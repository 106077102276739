import React, { useEffect, useState } from 'react';
import {
  GetEditFormDataQuery,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  LocationsQuery,
  UpdateLiftingOrReplenishmentMovementMutation,
  UpdateLiftingOrReplenishmentMovementMutationVariables,
  UPDATE_LIFTING_OR_REPLENISHMENT_BY_MOVEMENT,
  UpdateScheduleManagerByIdMutation,
  UPDATE_SCHEDULEMANAGER_BY_ID,
  UpdateScheduleManagerByIdMutationVariables,
  UpdateDepotActualVolumeMutationVariables,
  UpdateDepotActualVolumeMutation,
  UPDATE_DEPOT_ACTUAL_VOLUME,
  UpdateProductionActualVolumeByIdMutation,
  UpdateProductionActualVolumeByIdMutationVariables,
  UPDATE_PRODUCTION_ACTUALVOLUME_BY_ID,
} from '@scout/types';
import { Draggable } from 'react-beautiful-dnd';
import styled, { createGlobalStyle } from 'styled-components';
import { TableColumn, TableRow } from './EastTableElements';
import moment from 'moment-timezone';
import { Button } from '../../../../../../components/Button';
import { config } from '../../../../../../config';
import { useMutation } from 'react-apollo';
import { useToast } from '../../../../../../components/Toast/Toast';
import {
  getBuyerInfo,
  getVolume,
  displayTypeofMovement,
  getPlanningNotes,
  getloadAnddischargeLaycan,
  getUllage,
  getStock,
  WarningStockAndUllage,
  BoldText,
  getMTinfo,
  getSmartNotification,
  getActualVolume,
  getVesselName,
  getPurchaseContract,
  getSalesContract,
  getInternalFreightPrice,
  getCustomerFreightPrice,
} from './EastRowParentHelper';
import { useSchedulerDate, useSchedulerLocationId } from '../../hooks';
import EasyEdit, { Types } from 'react-easy-edit';
import { RequirePermission } from '../../../../../../components/RequirePermission';
import { usePermission } from '../../../../../../hooks/usePermission';
import { EastTooltip } from './EastTooltip';
import {
  MsgTypes,
  SALE_ACTUAL_VOLUME_UPDATE_ERR_MSG,
  SALE_ACTUAL_VOLUME_UPDATE_MSG,
} from '../../../../../../constants';
import { theme } from '../../../../../../theme';

const DeleteMovementButtonLighterTheme = styled(Button).attrs({ variant: 'icon-dark' })`
border:none;
width: 22px;
justify-content: center;
margin:-5px; !important;
height:22px;
padding:4px;
margin-right:10px
background-image:url(${config.PUBLIC_URL}/images/Delete.svg)
`;

const EditMovementButtonLighterTheme = styled(Button).attrs({ variant: 'ghost-light' })`
border:none;
width: 22px;
justify-content: center;
margin:0px; !important;
height:22px;
padding:4px;
background-image:url(${config.PUBLIC_URL}/images/Edit.svg)`;

const DeleteMovementButton = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 16px;
justify-content: center;
margin:0px; !important;
height:17px;
padding:4px;
margin-right:10px
background-image:url(${config.PUBLIC_URL}/images/DeleteMovement.svg)
`;

const EditMovementButton = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 16px;
justify-content: center;
margin:0px; !important;
height:16px;
padding:4px;
background-image:url(${config.PUBLIC_URL}/images/editmovement.svg)`;

const CenteredTextField = styled.span<{ currentUserTheme?: boolean }>`
  min-width: 80px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  text-align: center;
  color: ${p => (p.currentUserTheme === true ? p.theme.base.dark.c : p.theme.secondary.light.c)};
`;

const LongTextStyle = styled.span`
  min-width: 50px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
`;

const StyleUnderline = styled.span`
  text-decoration: underline;
  padding-left: 4px;
  cursor: pointer;
`;

const StyleNonUnderline = styled.span`
  padding-left: 4px;
`;

const StickyOne = styled.span`
  display: inline-block;
  position: sticky;
  left: 0;
  z-index: 1;
`;

const Sticky = styled.span<{ currentUserTheme?: boolean }>`
  display: inline-block;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: ${p => (p.currentUserTheme === true ? 'white' : p.theme.base.dark.b)};
`;

const DarkStyle = styled.span`
  color: ${props => props.theme.greys.dark.disabled};
`;
const TodayDateFormat = styled.span`
  text-decoration: underline;
  font-weight: 1000 !important;
`;

const SpanStyle = styled.span`
  display: inline-flex;
  padding: 2px;
`;

const Input = styled.input`
  background: yellow;
`;
const Label = styled.label`
  display: inline-flex;
`;

const DummyPadding = styled.span`
  height: 17px;
  padding: 4px;
  margin-right: 10px;
  width: 16px;
`;

const WeatherIconStyle = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

const DegStyle = styled.span`
  color: white;
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
`;

const PaddingStyle = styled.div`
  padding-left: 35px;
`;
const StlyedOverflowText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;
const GlobalBodyStyle = createGlobalStyle<{ currentUserTheme?: boolean }>`body {
  background-color: ${p => (p.currentUserTheme === true ? theme.base.light.a : theme.base.dark.b)}
  `;

const getSelectedRows: SchedulingManagerType[] = [];
let getDeletionRecords: string[] = [];

interface EastSchedulingManagerProps {
  date?: string;
  region?: string;
  movementStages: GetScheduleManagerByPortQuery['getScheduleManagerByPort'];
  eventNotes?: GetScheduleManagerByPortQuery['getScheduleManagerByPort'];
  loading: boolean;
  currentlocationtype: string;
  vessels: GetEditFormDataQuery['transports'];
  movementsInRefineryAndDepot: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] | undefined;
  onEditMovementModal: (selectedMovementItem: SchedulingManagerType) => void;
  locations: LocationsQuery['locations'];
  // tslint:disable-next-line
  onDeleteMovementModal: (selectedMovementItem: any) => void;
  currentPort: string;
  addSelectedTaskIdsToAnArray: (item: SchedulingManagerType, getSelectedRows: SchedulingManagerType[]) => void;
  isThirdParty?: boolean;
  color: boolean;
  movementMultipleDeletion: boolean;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

const SaleActualVolume: React.FunctionComponent<{ value?: string | number }> = ({ value }) => {
  return <div>{value}&nbsp;Kt</div>;
};

export const EastRowParent: React.FunctionComponent<EastSchedulingManagerProps> = ({
  eventNotes,
  loading,
  date,
  movementStages,
  vessels,
  onEditMovementModal,
  locations,
  onDeleteMovementModal,
  addSelectedTaskIdsToAnArray,
  isThirdParty,
  color,
  movementMultipleDeletion,
  currentUserTheme,
  onClickSimulationEvent,
  dataFetch,
}) => {
  const [locationId, setLocationId] = useSchedulerLocationId();
  const [rowSelected, setRowSelected] = useState<string[]>([]);
  const { push } = useToast();
  const { hasAnyOf } = usePermission();
  const [dates] = useSchedulerDate();

  if (!color) {
    getDeletionRecords = [];
  }

  useEffect(() => {
    if (color) {
      getDeletionRecords = [];
    }
  }, [locationId, dates, movementMultipleDeletion === true]);

  const redirectPort = (loadOrdischargePort: string | undefined | null) => {
    const foundLocation = locations.find(location => location.displayName === loadOrdischargePort);
    if (foundLocation && !foundLocation.isThirdParty) {
      setLocationId(foundLocation.id);
    } else {
      if (foundLocation && foundLocation.type === 'customer') {
        setLocationId('ThirdPartyCustomer');
      } else if (foundLocation && foundLocation.type === 'refinery') {
        setLocationId('ThirdPartyRefinery');
      }
    }
  };
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [updateLiftingOrReplenishmentMovement, setUpdateLiftingOrReplenishmentMovement] = useMutation<
    UpdateLiftingOrReplenishmentMovementMutation,
    UpdateLiftingOrReplenishmentMovementMutationVariables
  >(UPDATE_LIFTING_OR_REPLENISHMENT_BY_MOVEMENT, {
    context: {
      headers,
    },
  });

  const [updateProductionOrSale, setUpdateProductionOrSale] = useMutation<
    UpdateScheduleManagerByIdMutation,
    UpdateScheduleManagerByIdMutationVariables
  >(UPDATE_SCHEDULEMANAGER_BY_ID, {
    context: {
      headers,
    },
  });

  const [updateDepotActualVolume, setUpdateDepotActualVolume] = useMutation<
    UpdateDepotActualVolumeMutation,
    UpdateDepotActualVolumeMutationVariables
  >(UPDATE_DEPOT_ACTUAL_VOLUME, {
    context: {
      headers,
    },
  });

  const [updateProductionActualVolumeById] = useMutation<
    UpdateProductionActualVolumeByIdMutation,
    UpdateProductionActualVolumeByIdMutationVariables
  >(UPDATE_PRODUCTION_ACTUALVOLUME_BY_ID, {
    context: {
      headers,
    },
  });

  const save = async (value: number, item: SchedulingManagerType, volumeName: string) => {
    try {
      if (item.productType === 'Lifting') {
        {
          volumeName === 'plannedVolume'
            ? await updateLiftingOrReplenishmentMovement({
                variables: {
                  input: {
                    movementCloneId: item.movementCloneId || '',
                    plannedVolume: +value,
                    actualVolume: item.actualVolume || 0,
                    productType: item.productType,
                  },
                },
              })
            : await updateLiftingOrReplenishmentMovement({
                variables: {
                  input: {
                    movementCloneId: item.movementCloneId || '',
                    actualVolume: +value,
                    plannedVolume: item.plannedVolume || 0,
                    productType: item.productType,
                  },
                },
              });
        }
      } else if (item.productType === 'Replenishment') {
        {
          volumeName === 'plannedVolume'
            ? await updateLiftingOrReplenishmentMovement({
                variables: {
                  input: {
                    movementCloneId: item.movementCloneId || '',
                    plannedVolume: +value,
                    actualVolume: item.actualVolume || 0,
                    productType: item.productType,
                  },
                },
              })
            : await updateDepotActualVolume({
                variables: {
                  input: {
                    id: item.id,
                    depotActualVolume: +value,
                  },
                },
              });
        }
      } else if (item.productType === 'Production' || item.productType === 'Sale') {
        await updateProductionActualVolumeById({
          variables: {
            input: {
              id: item.id,
              actualVolume: +value,
            },
          },
        });
      }
      onClickSimulationEvent(!dataFetch);
      push({ type: 'SUCCESS', text: 'Movement Updated successfully' });
    } catch (error) {
      push({ type: 'ERROR', text: 'Movement Update Failed' });
    }
  };

  const updateSaleActualVolume = async (saleId: string, actualVolume: number | null) => {
    try {
      await updateProductionActualVolumeById({
        variables: {
          input: {
            id: saleId,
            actualVolume,
          },
        },
      });
      onClickSimulationEvent(!dataFetch);
      push({ type: MsgTypes.SUCCESS, text: SALE_ACTUAL_VOLUME_UPDATE_MSG });
    } catch (error) {
      push({ type: MsgTypes.ERROR, text: SALE_ACTUAL_VOLUME_UPDATE_ERR_MSG });
    }
  };

  const productionInlineSave = async (value: number, item: SchedulingManagerType, volumeName: string) => {
    const input = {
      id: item.id,
      productType: item.productType,
      plannedVolume: +value,
    };
    try {
      const record = await updateProductionOrSale({
        variables: {
          input: {
            id: item.id || '',
            productType: item.productType,
            plannedVolume: +value,
          },
        },
      });
      onClickSimulationEvent(!dataFetch);
      push({ type: 'SUCCESS', text: 'Movement Updated successfully' });
    } catch (error) {
      // console.error('Failed ', error);
      push({ type: 'ERROR', text: 'Movement Update Failed' });
    }
  };

  const getTransportInfo = (item: SchedulingManagerType) => {
    switch (item.productType) {
      case 'Lifting':
        return (
          <>
            <LongTextStyle>
              <DarkStyle>{item.loadPort}</DarkStyle>
            </LongTextStyle>
            &nbsp;to
            <LongTextStyle>
              <StyleUnderline
                onClick={() => {
                  redirectPort(item.dischargePort);
                }}
              >
                {item?.dischargePort?.toUpperCase()}
              </StyleUnderline>
            </LongTextStyle>
          </>
        );
      case 'Sale':
        return 'Sales';
      case 'Production':
        return 'Production';
      case 'Replenishment':
        return (
          <>
            <LongTextStyle>
              <DarkStyle>{item.dischargePort}</DarkStyle>
            </LongTextStyle>
            &nbsp; from
            <LongTextStyle>
              <RequirePermission anyOf={['editEastSchedulingManager', 'viewEastSchedulingManager']}>
                <StyleUnderline
                  onClick={() => {
                    hasAnyOf(['editEastSchedulingManager', 'viewEastSchedulingManager'])
                      ? redirectPort(item.loadPort)
                      : '';
                  }}
                >
                  {item?.loadPort?.toUpperCase()}
                </StyleUnderline>
              </RequirePermission>
              <RequirePermission anyOf={['eastDepotUser']}>
                <StyleNonUnderline>{item?.loadPort?.toUpperCase()}</StyleNonUnderline>
              </RequirePermission>
            </LongTextStyle>
          </>
        );
      default:
        return '';
    }
  };
  const getEventTableContent = (item: SchedulingManagerType) => {
    return (
      <>
        <TableColumn width="60px" stickyLeft={0} currentUserTheme={currentUserTheme}>
          {currentUserTheme ? (
            <DeleteMovementButtonLighterTheme
              onClick={() => {
                onDeleteMovementModal(item);
                getDeletionRecords = [];
              }}
            />
          ) : (
            <DeleteMovementButton
              onClick={() => {
                onDeleteMovementModal(item);
                getDeletionRecords = [];
              }}
            />
          )}
          {currentUserTheme ? (
            <EditMovementButtonLighterTheme onClick={() => onEditMovementModal(item)} />
          ) : (
            <EditMovementButton onClick={() => onEditMovementModal(item)} />
          )}
        </TableColumn>
        <EastTooltip body={item?.eventType} styles={{ maxWidth: '1050px', margin: '-24px', marginLeft: '68px' }}>
          <TableColumn width="125px" currentUserTheme={currentUserTheme}>
            {item ? displayTypeofMovement(item) : ''}
            <StlyedOverflowText> {item?.eventType}</StlyedOverflowText>
          </TableColumn>
        </EastTooltip>
        <TableColumn width="100px" currentUserTheme={currentUserTheme} />
        <TableColumn width="90px" currentUserTheme={currentUserTheme} />
        <TableColumn width="90px" currentUserTheme={currentUserTheme} />
        <TableColumn width="90px" currentUserTheme={currentUserTheme} />

        <EastTooltip body={getPlanningNotes(item)} styles={{ maxWidth: '900px' }}>
          <TableColumn width="165px" currentUserTheme={currentUserTheme}>
            <StlyedOverflowText>{item ? 'Notes : ' + getPlanningNotes(item) : ''}</StlyedOverflowText>
          </TableColumn>
        </EastTooltip>

        <TableColumn width="120px" currentUserTheme={currentUserTheme} />
        <TableColumn width="200px" currentUserTheme={currentUserTheme} />
        <TableColumn width="155px" currentUserTheme={currentUserTheme} />
        <TableColumn width="275px" currentUserTheme={currentUserTheme} />
        <TableColumn width="240px" currentUserTheme={currentUserTheme} />
        <TableColumn width="200px" currentUserTheme={currentUserTheme} />
        <TableColumn width="200px" currentUserTheme={currentUserTheme} />
        <TableColumn width="200px" currentUserTheme={currentUserTheme} />
        <TableColumn width="200px" currentUserTheme={currentUserTheme} />
        <TableColumn width="200px" currentUserTheme={currentUserTheme} />
      </>
    );
  };

  const handleChkBoxChange = (item: SchedulingManagerType, e: React.ChangeEvent<HTMLInputElement>) => {
    const index = getSelectedRows.findIndex(data => data.id === item.id);
    if (index === -1 && e.target.checked) {
      getSelectedRows.push(item);
    } else {
      getSelectedRows.splice(index, 1);
    }
    addSelectedTaskIdsToAnArray(item, getSelectedRows);
  };

  const getChkBoxComponent = (item: SchedulingManagerType) => {
    return (
      <>
        <Label>
          <Input type="checkbox" onChange={e => handleChkBoxChange(item, e)} />

          {item ? displayTypeofMovement(item, currentUserTheme) : ''}
          {item?.grade}
        </Label>
      </>
    );
  };
  const getNonChkBoxComponent = (item: SchedulingManagerType) => {
    if (hasAnyOf(['eastDepotUser'])) {
      if (
        item.productType === 'Lifting' ||
        item.productType === 'Production' ||
        item.productType === 'Sale' ||
        item.productType === 'Replenishment'
      ) {
        return (
          <>
            <Label>
              <Input type="checkbox" hidden={true} />
              {item ? displayTypeofMovement(item, currentUserTheme) : ''}
              {item?.grade}
            </Label>
          </>
        );
      }
      if (item.productType === 'Event') {
        return (
          <>
            <Label>
              <Input type="checkbox" hidden={true} />
              {item ? displayTypeofMovement(item) : ''}
              {item?.eventType}
            </Label>
          </>
        );
      }
    } else {
      if (
        item.productType === 'Lifting' ||
        item.productType === 'Production' ||
        item.productType === 'Sale' ||
        item.productType === 'Replenishment'
      ) {
        return (
          <>
            <Label>
              <Input type="checkbox" hidden={true} />
              {item ? displayTypeofMovement(item, currentUserTheme) : ''}
              {item?.grade}
            </Label>
          </>
        );
      }
      if (item.productType === 'Event') {
        return (
          <>
            <Label>
              <Input type="checkbox" hidden={true} />
              {item ? displayTypeofMovement(item) : ''}
              {item?.eventType}
            </Label>
          </>
        );
      }
    }
  };
  const renderInlineEditComponent = (item: SchedulingManagerType, isProductionSaleMovement: boolean) => {
    return (
      <EasyEdit
        type={Types.NUMBER}
        onSave={(value: number) => {
          isProductionSaleMovement
            ? productionInlineSave(value, item, 'plannedVolume')
            : save(value, item, 'plannedVolume');
        }}
        value={item ? getVolume(item) : ''}
        saveOnBlur={true}
        hideSaveButton={true}
        hideCancelButton={true}
        placeholder={'0.00'}
      />
    );
  };

  const getInlineEditComponents = (item: SchedulingManagerType) => {
    const isProductionSaleMovement: boolean =
      item.productType === 'Production' || item.productType === 'Sale' ? true : false;
    const isLiftingReplenishmentMovement: boolean =
      item.productType === 'Lifting' || item.productType === 'Replenishment' ? true : false;
    if (isLiftingReplenishmentMovement) {
      return (
        <>
          {renderInlineEditComponent(item, isProductionSaleMovement)}
          &nbsp;{'Kt'}
        </>
      );
    } else {
      if (item.plannedVolume || item.plannedVolume === 0) {
        if (
          (item.productType === 'Production' && Math.sign(item.plannedVolume) === -1) ||
          (item.productType === 'Sale' && Math.sign(item.plannedVolume) === 1) ||
          (item.productType === 'Sale' && item.grade === 'Adjustment')
        ) {
          return (
            <>
              <BoldText>
                <WarningStockAndUllage>
                  {renderInlineEditComponent(item, isProductionSaleMovement)}
                </WarningStockAndUllage>
              </BoldText>
              <BoldText>
                {' '}
                <WarningStockAndUllage> &nbsp;{'Kt'} </WarningStockAndUllage>
              </BoldText>
            </>
          );
        } else {
          return (
            <>
              {renderInlineEditComponent(item, isProductionSaleMovement)}
              &nbsp;{'Kt'}
            </>
          );
        }
      }
    }
  };
  const getSupplyUsersProductNameAndGrade = (item: SchedulingManagerType) => {
    if (
      (item.productType === 'Lifting' && item.BLDate !== null) ||
      item.productType === 'Production' ||
      item.productType === 'Sale' ||
      (item.productType === 'Replenishment' && item.dischargeCompletionDate !== null)
    ) {
      return getNonChkBoxComponent(item);
    } else {
      return getChkBoxComponent(item);
    }
  };
  const getDepotUsersProductNameAndGrade = (item: SchedulingManagerType) => {
    if (item.productType === 'Replenishment' && item.BLDate !== null && item.dischargeCompletionDate === null) {
      return getChkBoxComponent(item);
    } else {
      return getNonChkBoxComponent(item);
    }
  };

  const getDepotUsersMovementStages = (movementStages: GetScheduleManagerByPortQuery['getScheduleManagerByPort']) => {
    return movementStages
      ? [].slice
          .call(movementStages)
          .sort((a: { productType: number }, b: { productType: number }) => (a.productType < b.productType ? 1 : -1))
          .map((item: SchedulingManagerType, index: number) => {
            if (
              item?.productType === 'Replenishment' &&
              item?.BLDate !== null &&
              item.dischargeCompletionDate === null
            ) {
              return getDraggableComponent(item, index);
            }

            if (
              item?.productType === 'Lifting' ||
              (item?.productType === 'Replenishment' && item?.BLDate === null) ||
              (item?.productType === 'Replenishment' && item?.dischargeCompletionDate !== null)
            ) {
              return getNonDraggableComponent(item, index);
            } else if (item?.productType === 'Production' || item?.productType === 'Sale') {
              return getProductionSaleComponent(item, index);
            }
          })
      : '';
  };

  const getSupplyUsersMovementStages = (movementStages: GetScheduleManagerByPortQuery['getScheduleManagerByPort']) => {
    return movementStages
      ? [].slice
          .call(movementStages)
          .sort((a: { productType: number }, b: { productType: number }) => (a.productType < b.productType ? 1 : -1))
          .map((item: SchedulingManagerType, index: number) => {
            if (
              (item?.productType === 'Lifting' && item?.BLDate !== null) ||
              (item?.productType === 'Replenishment' && item?.dischargeCompletionDate !== null)
            ) {
              return getNonDraggableComponent(item, index);
            }
            if (item?.productType === 'Production' || item?.productType === 'Sale') {
              return getProductionSaleComponent(item, index);
            }
            if (item?.productType === 'Replenishment') {
              {
                return getDraggableComponent(item, index);
              }
            }
            if (item?.productType === 'Lifting' && item?.BLDate === null) {
              return getDraggableComponent(item, index);
            }
          })
      : '';
  };

  const getSaleActualVolume = (actualVolume: number | undefined | null) => {
    if (actualVolume === 0) {
      return actualVolume + '.00';
    }

    if (actualVolume) {
      return actualVolume.toFixed(2);
    }

    return actualVolume;
  };

  const getTableContent = (item: SchedulingManagerType, index: number) => {
    return (
      <>
        <TableColumn width="60px" stickyLeft={0} currentUserTheme={currentUserTheme}>
          {item.productType === 'Lifting' || item.productType === 'Replenishment' ? (
            <>
              <RequirePermission anyOf={['editEastSchedulingManager']}>
                {currentUserTheme ? (
                  <DeleteMovementButtonLighterTheme
                    onClick={() => {
                      onDeleteMovementModal(item);
                      getDeletionRecords = [];
                    }}
                  />
                ) : (
                  <DeleteMovementButton
                    onClick={() => {
                      onDeleteMovementModal(item);
                      getDeletionRecords = [];
                    }}
                  />
                )}
              </RequirePermission>
              <RequirePermission anyOf={['eastDepotUser']}>
                <DummyPadding />
              </RequirePermission>
            </>
          ) : currentUserTheme ? (
            <DeleteMovementButtonLighterTheme
              onClick={() => {
                onDeleteMovementModal(getDeletionRecords.length > 0 ? getDeletionRecords : item);
              }}
            />
          ) : (
            <DeleteMovementButton
              onClick={() => {
                onDeleteMovementModal(getDeletionRecords.length > 0 ? getDeletionRecords : item);
              }}
            />
          )}
          {currentUserTheme ? (
            <EditMovementButtonLighterTheme onClick={() => onEditMovementModal(item)} />
          ) : (
            <EditMovementButton onClick={() => onEditMovementModal(item)} />
          )}
        </TableColumn>
        <TableColumn width="125px" currentUserTheme={currentUserTheme}>
          {hasAnyOf(['eastDepotUser'])
            ? getDepotUsersProductNameAndGrade(item)
            : getSupplyUsersProductNameAndGrade(item)}
        </TableColumn>
        <TableColumn width="100px" currentUserTheme={currentUserTheme}>
          <SpanStyle>{getInlineEditComponents(item)}</SpanStyle>
        </TableColumn>
        <TableColumn width="90px" currentUserTheme={currentUserTheme}>
          <SpanStyle>
            {(item.productType === 'Lifting' ||
              item.productType === 'Replenishment' ||
              item.productType === 'Production') && (
              <EasyEdit
                type={Types.NUMBER}
                onSave={(value: number) => save(value, item, 'actualVolume')}
                value={item ? getActualVolume(item) : 0}
                saveOnBlur={true}
                hideSaveButton={true}
                hideCancelButton={true}
                displayComponent={<SaleActualVolume />}
                placeholder={'0.00 Kt'}
              />
            )}
            {item.productType === 'Sale' && (
              <EasyEdit
                type={Types.NUMBER}
                onSave={(value: number) => {
                  updateSaleActualVolume(item.id, value ? +value : null);
                }}
                value={getSaleActualVolume(item.actualVolume)}
                saveOnBlur={true}
                hideSaveButton={true}
                hideCancelButton={true}
                displayComponent={<SaleActualVolume />}
                placeholder={'N/A'}
              />
            )}
          </SpanStyle>
        </TableColumn>
        {!isThirdParty && <TableColumn width="90px" currentUserTheme={currentUserTheme} />}
        {!isThirdParty && <TableColumn width="90px" currentUserTheme={currentUserTheme} />}

        {isThirdParty && (
          <EastTooltip body={getTransportInfo(item)}>
            <TableColumn width="165px" currentUserTheme={currentUserTheme}>
              {item ? getTransportInfo(item) : ''}
            </TableColumn>
          </EastTooltip>
        )}
        {!isThirdParty && (
          <EastTooltip body={getTransportInfo(item)}>
            <TableColumn width="165px" currentUserTheme={currentUserTheme}>
              {item ? getTransportInfo(item) : ''}
            </TableColumn>
          </EastTooltip>
        )}

        <TableColumn width="120px" currentUserTheme={currentUserTheme}>
          <CenteredTextField currentUserTheme={currentUserTheme}>
            {item ? getloadAnddischargeLaycan(item) : ''}
          </CenteredTextField>
        </TableColumn>
        <TableColumn width="200px" currentUserTheme={currentUserTheme}>
          <StlyedOverflowText>{item ? getVesselName(item, vessels) : ''}</StlyedOverflowText>
        </TableColumn>
        <EastTooltip body={getBuyerInfo(item)} styles={{ maxWidth: '660px', margin: '-4px', marginLeft: '-30px' }}>
          <TableColumn width="155px" currentUserTheme={currentUserTheme}>
            <StlyedOverflowText>{item ? getBuyerInfo(item) : ''}</StlyedOverflowText>
          </TableColumn>
        </EastTooltip>

        <TableColumn width="275px" currentUserTheme={currentUserTheme}>
          {item ? getMTinfo(item, vessels, currentUserTheme) : ''}
        </TableColumn>
        <TableColumn width="240px" currentUserTheme={currentUserTheme}>
          {item ? getSmartNotification(item) : ''}
        </TableColumn>
        <TableColumn width="200px" currentUserTheme={currentUserTheme}>
          <PaddingStyle>
            <StlyedOverflowText>{item ? getInternalFreightPrice(item) : ''}</StlyedOverflowText>
          </PaddingStyle>
        </TableColumn>
        <TableColumn width="200px" currentUserTheme={currentUserTheme}>
          <PaddingStyle>
            <StlyedOverflowText>{item ? getCustomerFreightPrice(item) : ''}</StlyedOverflowText>
          </PaddingStyle>
        </TableColumn>
        <TableColumn width="200px" currentUserTheme={currentUserTheme}>
          <StlyedOverflowText>{item ? getPurchaseContract(item) : ''}</StlyedOverflowText>
        </TableColumn>
        <TableColumn width="200px" currentUserTheme={currentUserTheme}>
          <StlyedOverflowText>{item ? getSalesContract(item) : ''}</StlyedOverflowText>
        </TableColumn>
        <EastTooltip body={getPlanningNotes(item)} styles={{ maxWidth: '300px', marginLeft: '-100px' }}>
          <TableColumn width="200px" currentUserTheme={currentUserTheme}>
            <StlyedOverflowText>{item ? getPlanningNotes(item) : ''}</StlyedOverflowText>
          </TableColumn>
        </EastTooltip>
      </>
    );
  };

  const giveConditionForStyledRendering = (item: SchedulingManagerType) => {
    if (
      (item.productType === 'Lifting' && item.BLDate !== null) ||
      (item.productType === 'Replenishment' && item.BLDate !== null && item.dischargeCompletionDate !== null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const movementSelected = (value: string, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const index: number = getDeletionRecords.indexOf(value);
    if (e.metaKey || e.ctrlKey) {
      if (index === -1) {
        getDeletionRecords.push(value);
        setRowSelected(rowSelected => [...rowSelected, value]);
      } else {
        getDeletionRecords.splice(index, 1);
        setRowSelected(currentRows => currentRows.filter((rowSelected, i) => i !== index));
      }
    } else {
      setRowSelected([]);
    }
  };

  const getColor = (item: SchedulingManagerType) => {
    if (getDeletionRecords.length > 0) {
      const getId = getDeletionRecords?.find(row => {
        return row === item?.id;
      });
      if (getId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getNonDraggableComponent = (item: SchedulingManagerType, index: number) => {
    return (
      <TableRow key={item.id} condition={giveConditionForStyledRendering(item)} currentUserTheme={currentUserTheme}>
        {getTableContent(item, index)}
      </TableRow>
    );
  };

  const getProductionSaleComponent = (item: SchedulingManagerType, index: number) => {
    return (
      <>
        {color ? (
          <TableRow
            key={item.id}
            condition={giveConditionForStyledRendering(item)}
            onClick={e => movementSelected(item.id, e)}
            selectedColor={getColor(item)}
            currentUserTheme={currentUserTheme}
          >
            {getTableContent(item, index)}
          </TableRow>
        ) : (
          <TableRow
            key={item.id}
            condition={giveConditionForStyledRendering(item)}
            onClick={e => movementSelected(item.id, e)}
            selectedColor={color}
            currentUserTheme={currentUserTheme}
          >
            {getTableContent(item, index)}
          </TableRow>
        )}
      </>
    );
  };

  const getDraggableComponent = (item: SchedulingManagerType, index: number) => {
    return (
      <>
        <Draggable draggableId={item.id} key={item.id} index={index}>
          {(provided, snapshot) => (
            <TableRow
              key={index}
              ref={provided.innerRef}
              style={{
                userSelect: 'none',
              }}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              condition={giveConditionForStyledRendering(item)}
            >
              {getTableContent(item, index)}
            </TableRow>
          )}
        </Draggable>
      </>
    );
  };
  if (!loading) {
    return (
      <>
        <StickyOne>
          <GlobalBodyStyle currentUserTheme={currentUserTheme} />
          <TableRow currentUserTheme={currentUserTheme}>
            <TableRow currentUserTheme={currentUserTheme}>
              <Sticky currentUserTheme={currentUserTheme}>
                <CenteredTextField currentUserTheme={currentUserTheme}>
                  {moment().format('DD/MM (ddd)') === moment(date).format('DD/MM (ddd)') ? (
                    <TodayDateFormat>{moment(date).format('DD/MM (ddd)')}</TodayDateFormat>
                  ) : (
                    moment(date).format('DD/MM (ddd)')
                  )}
                </CenteredTextField>
              </Sticky>
              <TableColumn width="125px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="100px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="90px" height="10px" currentUserTheme={currentUserTheme} />
              {!isThirdParty && (
                <TableColumn width="90px" height="10px" currentUserTheme={currentUserTheme}>
                  {movementStages ? getStock(movementStages, date, currentUserTheme) : ''}
                </TableColumn>
              )}
              {!isThirdParty && (
                <TableColumn width="90px" height="10px" currentUserTheme={currentUserTheme}>
                  {movementStages ? getUllage(movementStages, date, currentUserTheme) : ''}
                </TableColumn>
              )}
              <TableColumn width="165px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="120px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="200px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="155px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="275px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="240px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="200px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="200px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="200px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="200px" height="10px" currentUserTheme={currentUserTheme} />
              <TableColumn width="200px" height="10px" currentUserTheme={currentUserTheme} />
            </TableRow>

            {hasAnyOf(['eastDepotUser'])
              ? getDepotUsersMovementStages(movementStages)
              : getSupplyUsersMovementStages(movementStages)}
            {eventNotes
              ? eventNotes.map((item: SchedulingManagerType, index: number) => {
                  return (
                    <>
                      {item?.blockDates && (
                        <TableRow key={index} blockDates={true} currentUserTheme={currentUserTheme}>
                          {getEventTableContent(item)}
                        </TableRow>
                      )}

                      {!item?.blockDates && (
                        <TableRow
                          key={index}
                          currentUserTheme={currentUserTheme}
                          condition={giveConditionForStyledRendering(item)}
                        >
                          {item ? getEventTableContent(item) : ''}
                        </TableRow>
                      )}
                    </>
                  );
                })
              : ''}
          </TableRow>
        </StickyOne>
      </>
    );
  }
  return <p>loading</p>;
};
