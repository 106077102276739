import { formatKt, getStockInfoGroups, groupBy, mergeTwoArrays } from '../../../../pages/InMonth/Stocks/helpers';
import { calculateUllage } from '../../../../pages/InMonth/Stocks/TankInfo';
import { TankMetaData } from '../../../../pages/InMonth/Stocks/TankMeta';

export interface StockInfoProps {
  isPiStock: boolean;
  maxVolume?: number | null;
  openingSpecification?: string | null;
  openingAmount?: number | null;
  meta?: TankMetaData[];
}
export interface EuropeLocationPros {
  plantId: string;
  name: string;
}

export interface ProductStockInfo {
  date: string;
  location_id: string;
  stock: number;
  ullage: number;
  ullageFlag: number;
  id: string;
}

export interface EuropeProductStockInfo {
  name: string;
  Ullage: number;
  Stock: number;
  id: string;
}

export interface StockInfoValue {
  code: string;
  data: StockInfoProps | null;
}

// need
export const calculateTotalStock = (stockInfo: StockInfoValue[]) =>
  stockInfo.reduce((sum, stockInfo) => {
    if (!stockInfo.data || !stockInfo.data.openingAmount) {
      return sum;
    }

    return sum + stockInfo.data.openingAmount;
  }, 0);
// need
export const calculateTotalUllage = (stockInfoValue: StockInfoValue[]) =>
  stockInfoValue.reduce((sum, stockInfo) => {
    if (!stockInfo.data) {
      return sum;
    }

    const ullage = calculateUllage({
      maxVolume: stockInfo.data.maxVolume,
      openingAmount: stockInfo.data.openingAmount,
    });

    if (!ullage) {
      return sum;
    }

    return sum + ullage;
  }, 0);

/* tslint:disable */
export const getEastProductInformation = (arrayObj: any, locations: any) => {
  const eastLocations: { location_id: string }[] = [];
  locations.filter((item: { region: string; isThirdParty: boolean; plantCode: any; name: any }) => {
    if (item.region === 'East' && item.isThirdParty === false) {
      eastLocations.push({ location_id: item.name });
    }
  });
  const productInformationEast = arrayObj.data ? JSON.parse(JSON.stringify(arrayObj.data)) : null;
  const productInformationObj = productInformationEast?.getAllPlantStockInformation
    ? productInformationEast?.getAllPlantStockInformation
    : [];
  const locationIds = new Set(productInformationObj.map((item: { location_id: string }) => item.location_id));
  const getAllPlantStockInformation = [
    ...productInformationObj,
    ...eastLocations.filter((loc: { location_id: string }) => !locationIds.has(loc.location_id)),
  ];
  return getAllPlantStockInformation;
};
/* tslint:disable */
export const getEuropeProductInformation = (tanksObj: any, locations: any) => {
  const europeFinalResult = [];
  const europeLocations: { plantId: string; name: string }[] | any = [];
  locations.filter((item: { region: string; isThirdParty: boolean; plantCode: any; name: any }) => {
    if (item.region !== 'East' && item.isThirdParty === false) {
      europeLocations.push({ plantId: item.plantCode, name: item.name });
    }
  });
  if (tanksObj?.data) {
    const grpdata = groupBy(tanksObj?.data.allTanksByDate, 'plantId');
    const plantKeys: string[] = Object.keys(grpdata);
    /* tslint:disable */
    for (let i = 0; i < plantKeys.length; i++) {
      const stockInfosGroups = getStockInfoGroups(grpdata[plantKeys[i]]);
      const stockInfosGroupsValues = Object.values(stockInfosGroups);

      const totalStock = stockInfosGroupsValues
        .map(stockInfoValue => calculateTotalStock(stockInfoValue))
        .reduce((sum, value) => sum + value, 0);

      const totalUllage = stockInfosGroupsValues
        .map(stockInfoValue => calculateTotalUllage(stockInfoValue))
        .reduce((sum, value) => sum + value, 0);
      europeFinalResult.push({ plantId: plantKeys[i], Stock: formatKt(totalStock), Ullage: formatKt(totalUllage) });
    }
  }
  const result = mergeTwoArrays(europeLocations, europeFinalResult);
  return result;
};
