import { GradeSplitStepDemandRow, GradeSplitStepGrade, GradeSplitStepValues } from '../types';

export const sumGradeSplit = (grades: GradeSplitStepGrade[]): number =>
  grades.reduce<number>((prev, curr) => (prev += Number(curr.percentage)), 0);

export const sumGradeSplitRow = (demandRow: GradeSplitStepDemandRow): number =>
  sumGradeSplit(demandRow.hardGrades) + sumGradeSplit(demandRow.premiumGrades) + sumGradeSplit(demandRow.softGrades);

const isDemandTotalValid = (demandRow: GradeSplitStepDemandRow): boolean => sumGradeSplitRow(demandRow) === 100;

export const areGradeSplitsValid = (values: GradeSplitStepValues): boolean =>
  [...values.penRows, ...values.premiumRows].every(row => isDemandTotalValid(row));
