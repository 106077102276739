import { FastField, Field, FieldProps, FormikValues, getIn } from 'formik';
import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { TextInput } from '../../../components/TextInput';
import { Typography } from '../../../components/Typography';
import { restrictNumberValue } from '../../../utils/validation';

const InputWrapper = styled.div<{ alignVertically: boolean }>`
  display: flex;
  align-items: baseline;
  flex-direction: ${p => (p.alignVertically ? 'column' : 'row')};
`;

const Input = styled(TextInput)`
  width: ${props => props.width};

  /* 'padding-right' is needed because the "%" is quite a wide suffix 🍰 */
  /* ">" is needed because the "input" is wrapped with a "div"  */
  & > input {
    padding-right: 1.75rem;
  }
`;

const Label = styled(Typography)<{ alignVertically?: boolean; width?: CSSProperties['width'] }>`
  margin-right: ${p => (p.alignVertically ? '0' : '20px')};
  ${p =>
    p.alignVertically &&
    css<{ width?: CSSProperties['width'] }>`
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: ${({ width }) => width};
    `}
`;

interface TextFieldProps {
  tabIndex?: number;
  name: string;
  label?: string;
  suffix?: string;
  onChange?: () => void;
  onValidateBlur?: (value: string) => number | '';
  inputWidth?: CSSProperties['width'];
  disabled?: boolean;
  placeholder?: string;
  themeStyle?: 'dark' | 'light';
  alignVertically?: boolean;
  className?: string;
  useFastField?: boolean;
  showErrorMessage?: boolean;
  max?: number;
  min?: number;
  allowNegative?: boolean;
}

const TextField: React.FunctionComponent<TextFieldProps> = ({
  tabIndex,
  label,
  name,
  onChange,
  onValidateBlur,
  suffix = '%',
  inputWidth = '100%',
  disabled,
  placeholder,
  themeStyle = 'light',
  alignVertically = false,
  className,
  useFastField = true,
  showErrorMessage,
  allowNegative = false,
  max,
  min,
}) => {
  const FieldComponent = useFastField ? FastField : Field;

  return (
    <InputWrapper className={className} alignVertically={alignVertically}>
      <FieldComponent
        name={name}
        render={({ field, form }: FieldProps<FormikValues>) => {
          const error: string | undefined = getIn(form.errors, name);
          const touched: string | undefined = getIn(form.touched, name);
          const errorMessage = touched && form.submitCount ? error : undefined;
          const labelId = `${field.name}-label`;

          return (
            <>
              {label && (
                <Label
                  align={alignVertically ? 'right' : undefined}
                  alignVertically={alignVertically}
                  color={themeStyle === 'light' ? 'bodyLight' : 'bodyDark'}
                  id={labelId}
                  tag="label"
                  variant="body3"
                  width={inputWidth}
                >
                  {label}
                </Label>
              )}
              <Input
                {...field}
                data-testid={field.name}
                aria-labelledby={labelId}
                tabIndex={tabIndex}
                suffix={suffix}
                width={inputWidth}
                disabled={disabled}
                placeholder={placeholder}
                align="right"
                onChange={(e: React.ChangeEvent<{ value: string }>) => {
                  const newValue = restrictNumberValue(e.target.value, min, max, allowNegative);

                  if (newValue == null) {
                    return;
                  }

                  onChange && onChange();
                  form.setFieldValue(field.name, newValue);
                  form.setFieldTouched(field.name, false);
                }}
                onBlur={(e: React.ChangeEvent<{ value: string }>) => {
                  if (onValidateBlur) {
                    form.setFieldValue(field.name, onValidateBlur(e.target.value));
                  }

                  field.onBlur(e);
                  form.setFieldTouched(field.name, false);
                }}
                type="text"
                themeStyle={themeStyle}
                error={errorMessage}
                showErrorMessage={showErrorMessage}
              />
            </>
          );
        }}
      />
    </InputWrapper>
  );
};

export { TextField };
