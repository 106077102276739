import React from 'react';
import styled, { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

const Container = styled.td`
  width: 100%;
  height: 350px;
  padding: 15px;
  position: absolute;
`;

const Box = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid ${p => (p.currentUserTheme ? p.theme.greys.light.border : p.theme.greys.dark.border)};
  border-radius: 2px;
  height: 100%;
  padding: 20px;
`;

const Icon: React.FC<{ theme?: IThemeType; currentUserTheme?: boolean }> = withTheme(({ theme, currentUserTheme }) => (
  <svg width="68" height="88" viewBox="0 0 68 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.3371 10.4542L54.3371 10.4542L54.3302 10.4485L43.5144 1.67061L42.8857 2.44526L43.5144 1.67061C42.9798 1.23678 42.3123 1 41.6239 1L33.7145 1H3C1.34314 1 0 2.34315 0 4V43V53.25V63.5V82C0 83.6568 1.34315 85 3 85H40.4081C40.2189 84.673 40.0398 84.3395 39.871 84H3C1.89543 84 1 83.1046 1 82V63.5V53.25V43V4C1 2.89543 1.89543 2 3 2H33.7145L41.6239 2C42.0828 2 42.5278 2.15785 42.8842 2.44708L53.7 11.225L62.2742 18.3126C62.7339 18.6925 63 19.2578 63 19.8541V25.5951V59.4118C63.3388 59.5549 63.6722 59.7081 64 59.871V25.5951V19.8541C64 18.9596 63.6008 18.1117 62.9114 17.5418L54.3371 10.4542Z"
      fill={currentUserTheme ? theme.base.dark.b : theme.secondary.light.c}
    />
    <rect x="12" y="12" width="17" height="17" rx="2" fill={theme.greys.dark.border} />
    <rect x="12" y="35" width="39" height="11" rx="2" fill={theme.primary.b} />
    <path
      d="M12.8045 53.5H49.8106"
      stroke={currentUserTheme ? theme.base.dark.b : theme.secondary.light.c}
      strokeLinecap="round"
    />
    <path
      d="M12.6563 62.5H42.8455"
      stroke={currentUserTheme ? theme.base.dark.b : theme.secondary.light.c}
      strokeLinecap="round"
    />
    <path
      d="M12.5293 71.5H36.8754"
      stroke={currentUserTheme ? theme.base.dark.b : theme.secondary.light.c}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56 88C62.6274 88 68 82.6274 68 76C68 69.3726 62.6274 64 56 64C49.3726 64 44 69.3726 44 76C44 82.6274 49.3726 88 56 88Z"
      fill={theme.secondary.light.a}
    />
    <path d="M62.1148 69.7019L50.4931 81.3236" stroke={theme.base.dark.b} strokeLinecap="square" />
    <path d="M50.4175 69.7019L62.0391 81.3236" stroke={theme.base.dark.b} strokeLinecap="square" />
    <path
      d="M41.5 2V17.5C41.5 18.6046 42.3954 19.5 43.5 19.5H63"
      stroke={currentUserTheme ? theme.base.dark.a : theme.secondary.light.c}
    />
  </svg>
));

const Empty: React.FC<{ currentUserTheme?: boolean }> = ({ children, currentUserTheme }) => (
  <Container>
    <Box currentUserTheme={currentUserTheme}>
      <Icon currentUserTheme={currentUserTheme} />
      {children}
    </Box>
  </Container>
);

export { Empty };
