import { UPDATEUSERTHEME, UpdateUserThemeMutation, UpdateUserThemeMutationVariables } from '@scout/types/dist';
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import { UserThemeContext } from '../../App';
import { SERVICE_NOW_URL } from '../../constants';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Typography } from '../Typography';

const Container = styled.div<{ currentUserTheme?: boolean }>`
  position: absolute;
  box-shadow: 0 6px 12px rgb(0, 0, 0, 0.175);
  border: 1px solid ${p => (p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.d)};
  background: ${p => (p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.d)};
  cursor: pointer;
  margin-left: -180px;
  border-radius: 3px;
  margin-top: 13px;
  padding: 8px;
`;
const ContentWrapper = styled.div<{ currentUserTheme?: boolean }>`
  padding: 6px;
  &:hover {
    background-color: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.greys.dark.disabled)};
  }
`;
const ServiceNowWrapper = styled.a<{ currentUserTheme?: boolean }>`
color: ${p => (p.currentUserTheme ? p.theme.base.dark.d : p.theme.base.light.a)}
text-decoration: none;
font-size: 14px;
lineHeight: 14.06px;
`;
export const MoreActionsModal = () => {
  const { currentUserTheme, setCurrentUserTheme } = useContext(UserThemeContext);
  const [updateUserTheme, setUpdateUserTheme] = useMutation<UpdateUserThemeMutation, UpdateUserThemeMutationVariables>(
    UPDATEUSERTHEME,
  );
  const data = useCurrentUser();
  const handleClickMoreActions = async () => {
    const userEmail = data.emailAddress;
    try {
      await updateUserTheme({
        variables: {
          input: userEmail,
        },
      });
      setCurrentUserTheme(!currentUserTheme);
    } catch (error) {
      console.error('Error in updateUserTheme');
    }
  };
  return (
    <Container currentUserTheme={currentUserTheme}>
      <ContentWrapper currentUserTheme={currentUserTheme}>
        <Typography
          color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
          onClick={handleClickMoreActions}
          variant="body4"
        >
          Change Background Color
        </Typography>
      </ContentWrapper>
      <ContentWrapper currentUserTheme={currentUserTheme}>
        <ServiceNowWrapper href={SERVICE_NOW_URL} target="_blank" currentUserTheme={currentUserTheme}>
          Service Now{' '}
        </ServiceNowWrapper>
      </ContentWrapper>
    </Container>
  );
};
