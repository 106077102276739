import * as React from 'react';
import styled from 'styled-components';
import { components as SelectComponents } from 'react-select';

import { Typography } from '../../Typography';
import { OptionProps } from 'react-select/src/components/Option';
import { InputCheckbox } from '../../Checkbox';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  margin-left: 15px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 15px;
  height: 24px;
  padding: 0 6px;
  border-radius: 2px;
  background-color: ${p => p.theme.primary.b};
`;

const Option = (props: OptionProps<{}> & { onBlur: () => void }) => {
  return (
    <SelectComponents.Option {...props}>
      <Container>
        <InputCheckbox
          name="option-checkbox"
          checked={props.isSelected}
          onChange={() => {
            return;
          }}
          onBlur={props.onBlur}
        />
        <Label>
          <Typography variant="body3" color="bodyDark">
            {props.label}
          </Typography>
        </Label>
        {props.data.tag && (
          <Tag>
            <Typography variant="labels1" color="bodyDark" bold={true}>
              {props.data.tag}
            </Typography>
          </Tag>
        )}
      </Container>
    </SelectComponents.Option>
  );
};

export { Option };
