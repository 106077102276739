import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import { Typography } from '../../../../components/Typography';

const Spacer = styled.div<{ size?: number }>`
  height: ${props => 20 * (props.size || 1)}px;
`;

export interface Props {
  showModal: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

const RemoveMovementModal: React.FC<Props> = props => {
  return (
    <Modal
      data-testid="remove-movement-modal"
      onConfirm={props.onConfirm}
      isOpen={props.showModal}
      confirmText="Yes"
      confirmBtnVariant="primary"
      dismissText="No"
      onDismiss={props.onDismiss}
    >
      <Spacer />
      <Typography variant="heading5" color="bodyDark" align="center" bold={true}>
        Are you sure you want to remove this movement?
      </Typography>
      <Spacer />
    </Modal>
  );
};

export { RemoveMovementModal };
