import { Field, FieldProps, getIn } from 'formik';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../components/Button';
import { DropdownItemOptions, SingleDropdown } from '../../../../../components/SelectDropdown/Single';
import { TextInput } from '../../../../../components/TextInput';
import { Typography } from '../../../../../components/Typography';
import { restrictNumberValue } from '../../../../../utils/validation';
import { DemandsStepValues, DemandsStepVolumeRow, PlantItem } from '../types';

const MandatoryRow = styled.tr`
  background-color: ${({ theme }) => theme.base.light.c};
  padding: 8px;
  vertical-align: middle;
`;

const StyledTd = styled.td<{ width: string }>`
  padding: 8px;
  ${({ width }) => `width: ${width};`}
`;

const BasePlant = styled(Typography)`
  margin-left: 11px;
  width: 219px;
`;

type Props = DemandsStepVolumeRow & {
  locations: DropdownItemOptions[];
  locationsLineNumber: number;
  locationsPlaceholder: string;
  maxTotal: number;
  name: string;
  onRemoveRowClick: () => void;
  plants: PlantItem[];
};

const DemandsStepPlantRow: React.FC<Props> = ({
  isFixed,
  locations,
  locationsLineNumber,
  locationsPlaceholder,
  maxTotal,
  name,
  onRemoveRowClick,
  plant,
  plants,
}) => {
  const Row = isFixed ? MandatoryRow : 'tr';

  return (
    <Row>
      <StyledTd width="40px">
        {!isFixed && <Button icon="MdRemove" onClick={onRemoveRowClick} variant="icon-light" />}
      </StyledTd>
      <StyledTd width="246px">
        {isFixed ? (
          <BasePlant bold={true} variant="body4">
            {plants.find(p => p.id === plant)?.name ?? '-'}
          </BasePlant>
        ) : (
          <Field
            name={`${name}.plant`}
            render={({
              field,
              form: { errors, touched, setFieldTouched, setFieldValue },
            }: FieldProps<DemandsStepValues>) => (
              <SingleDropdown
                error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                isClearable={false}
                items={plants.map(r => ({
                  label: r.name,
                  value: r.id,
                }))}
                name={field.name}
                onBlur={() => {
                  setFieldTouched(field.name, true);
                }}
                onItemClick={item => {
                  setFieldValue(field.name, item.value);
                  setFieldValue(`${name}.location`, null);
                }}
                placeholder="Select a plant"
                selected={field.value}
                showValidation={false}
                width="230px"
              />
            )}
          />
        )}
      </StyledTd>
      <StyledTd width="224px">
        <Field
          name={`${name}.location`}
          render={({
            field,
            form: { errors, touched, setFieldTouched, setFieldValue },
          }: FieldProps<DemandsStepValues>) => (
            <SingleDropdown
              error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
              isClearable={false}
              items={locations}
              lineNumber={locationsLineNumber}
              name={field.name}
              onBlur={() => {
                setFieldTouched(field.name, true);
              }}
              onItemClick={item => {
                setFieldValue(field.name, item.value);
              }}
              placeholder={locationsPlaceholder}
              selected={field.value}
              showValidation={false}
              width="224px"
            />
          )}
        />
      </StyledTd>
      <StyledTd width="94px">
        <Field
          name={`${name}.volume`}
          render={({ field, form: { errors, touched } }: FieldProps<DemandsStepValues>) => (
            <TextInput
              align="right"
              error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
              name={field.name}
              onBlur={field.onBlur}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newValue = restrictNumberValue(e.target.value, 0, maxTotal);

                if (newValue == null) {
                  return;
                }

                e.target.value = newValue;

                field.onChange(e);
              }}
              placeholder="0"
              showErrorIcon={false}
              showErrorMessage={false}
              suffix="t"
              themeStyle="light"
              value={field.value != null ? field.value : ''}
            />
          )}
        />
      </StyledTd>
    </Row>
  );
};

export { DemandsStepPlantRow };
