import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { TransportQueryResult, TransportsQueryResult } from '@scout/types';
import { VesselSelectIcon } from '../Icons/MyLocationIcon';
import { Typography } from '../Typography';
import { VesselSelectList } from './VesselSelectList';

const FixedContainer = styled.div`
  position: fixed;
  right: 18px;
  bottom: 48px;
  z-index: 19;
`;

const selectedHoverMixin = ({ selected }: { selected: boolean }) =>
  !selected &&
  css`
    &:hover,
    &:hover svg,
    &:hover p {
      border-color: ${p => p.theme.primary.b};
      color: ${p => p.theme.primary.b};
      path {
        fill: ${p => p.theme.primary.b};
      }
    }
  `;

const VesselSelectContainer = styled.div<{
  selected: boolean;
  currentUserTheme?: boolean;
}>`
  width: 158px;
  height: 40px;
  background: ${p => (p.selected ? p.theme.primary.b : p.currentUserTheme ? p.theme.base.dark.a : 'transparent')};
  border: solid 1px ${p => (p.selected ? p.theme.primary.b : p.theme.secondary.light.c)};
  border-radius: 2px;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  ${selectedHoverMixin}
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const PopupListContainer = styled.div<{ currentUserTheme?: boolean }>`
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  border-radius: 2px;
  width: 269px;
  height: 50vh;
  position: absolute;
  right: 0px;
  bottom: 50px;
  z-index: 20;
  margin-bottom: -14px;

  :after {
    top: 100%;
    right: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: ${p => p.theme.base.dark.c};
    border-width: 7.5px;
    margin-left: -7.5px;
  }
`;

export const VesselSelect: React.FC<{
  popupSelectedVessel: TransportQueryResult | null;
  vessels: TransportsQueryResult;
  onSelectVessel: (vesselId: string) => void;
  currentUserTheme?: boolean;
}> = ({ popupSelectedVessel, vessels, onSelectVessel, currentUserTheme }) => {
  const popupSelectedVesselId = popupSelectedVessel ? popupSelectedVessel.id : null;
  const [open, setOpen] = useState(false);
  const [selectedVesselId, setSelectedVesselId] = useState<string | null>(popupSelectedVesselId);

  useEffect(() => {
    setSelectedVesselId(popupSelectedVesselId);
  }, [popupSelectedVesselId]);

  return (
    <FixedContainer>
      <VesselSelectContainer
        data-testid="vessel-select"
        selected={open}
        currentUserTheme={currentUserTheme}
        onClick={() => setOpen(!open)}
      >
        <Typography color="bodyDark" userSelect={false} bold={true}>
          Vessel select
        </Typography>
        <VesselSelectIcon />
      </VesselSelectContainer>
      <RelativeContainer>
        {open && (
          <PopupListContainer data-testid="vessel-select-list" currentUserTheme={currentUserTheme}>
            <VesselSelectList
              selectedVesselId={selectedVesselId}
              vessels={vessels}
              onSelectVessel={vesselId => {
                onSelectVessel(vesselId);
                setSelectedVesselId(vesselId);
              }}
              onCloseList={() => {
                setOpen(false);
              }}
              currentUserTheme={currentUserTheme}
            />
          </PopupListContainer>
        )}
      </RelativeContainer>
    </FixedContainer>
  );
};
