import { Contract, ContractDealType } from '@scout/types';
import { ContractDemandState } from '../../types';
import { Month, MonthlyVolumesStepValues, MonthlyVolumesStepValuesRow } from '../types';

interface CreateValuesInput {
  contract: Pick<
    Contract,
    | 'end'
    | 'fuelOil'
    | 'start'
    | 'totalVolume'
    | 'type'
    | 'jan'
    | 'feb'
    | 'mar'
    | 'apr'
    | 'may'
    | 'jun'
    | 'jul'
    | 'aug'
    | 'sep'
    | 'oct'
    | 'nov'
    | 'dec'
  >;
  demands: ContractDemandState['demands'];
}

export const createValues = ({ contract, demands }: CreateValuesInput): MonthlyVolumesStepValues => ({
  rows: demands.reduce<MonthlyVolumesStepValuesRow[]>((results, row) => {
    const ratio = row.totalVolume / contract.totalVolume;

    const newRow = {
      isEditable: contract.type !== ContractDealType.Fixed,
      id: row.id,
      plantName: row.plant.shortName || row.plant.id,
      location: row.region?.name || row.shipTo?.name || '-',
      targetTotal: row.totalVolume,
      volumes: Object.values(Month).reduce<MonthlyVolumesStepValuesRow['volumes']>((prev, curr) => {
        const contractMonthVolume = contract[curr];

        return {
          ...prev,
          [curr]: `${row.monthlyVolumes[curr] ?? calculateVolume({ ratio, volume: contractMonthVolume })}`,
        };
      }, {} as MonthlyVolumesStepValuesRow['volumes']),
    };

    const rowTotalVolume = sumVolumes(newRow.volumes);
    const delta = row.totalVolume - rowTotalVolume;

    if (delta !== 0) {
      // @NOTE we pick the first month of the contract and add/subtract to make up the difference
      const monthToUpdate: [string, string] | undefined = Object.entries(newRow.volumes).find(
        ([, volume]) => volume != null && volume !== '0',
      );

      if (monthToUpdate) {
        newRow.volumes[monthToUpdate[0] as Month] = `${Number(monthToUpdate[1]) + delta}`;
      }
    }

    return [...results, newRow];
  }, []),
});

export const sumVolumes = (volumes: MonthlyVolumesStepValuesRow['volumes']): number =>
  Object.values(volumes).reduce<number>((prev, curr) => (prev += Number(curr)), 0);

const calculateVolume = ({ ratio, volume }: { ratio: number; volume?: number | null }): number =>
  volume ? Math.round(volume * ratio) : 0;
