import moment, { Moment } from 'moment';
import React from 'react';
import { LocationNotesQueryResult, LocationViewDataQuery, TransportQueryResult } from '@scout/types';
import { EmptyRow } from './Table/EmptyRow';
import { MovementRows } from './Table/MovementRows';

const getMovementsOnDate = (movementList: LocationViewDataQuery['movementStages'], queryDate: string) =>
  movementList.filter(movementStage => {
    if (movementStage.date !== queryDate) {
      return false;
    }

    return true;
  });

const dateFormat = 'YYYY-MM-DD';

export const getLocationNotesOnDate = (locationNotes: LocationViewDataQuery['locationNotes'], queryDate: string) =>
  locationNotes.filter(locationNote =>
    moment(queryDate, dateFormat).isBetween(
      moment(locationNote.fromDate, dateFormat),
      moment(locationNote.toDate, dateFormat),
      'days',
      '[]',
    ),
  );

export const TableBody: React.FunctionComponent<{
  isThirdParty?: boolean;
  dates: string[];
  data: LocationViewDataQuery;
  onEditLocationNote: (locationNote: LocationNotesQueryResult[0]) => void;
  onClickTransport: (transport: TransportQueryResult) => void;
}> = ({ isThirdParty, dates, data, onEditLocationNote, onClickTransport }) => {
  return (
    <>
      {dates.map((date: string) => {
        const movementStages = getMovementsOnDate(data.movementStages, date);
        const locationNotes = getLocationNotesOnDate(data.locationNotes, date);

        if (!movementStages.length && !locationNotes.length) {
          return <EmptyRow key={date.toString()} date={date} />;
        }

        return (
          <MovementRows
            isThirdParty={isThirdParty}
            key={date.toString()}
            date={date}
            movementStages={movementStages}
            locationNotes={locationNotes}
            onEditLocationNote={onEditLocationNote}
            onClickTransport={onClickTransport}
          />
        );
      })}
    </>
  );
};
