import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PowerBIEmbed, EventHandler } from 'powerbi-client-react';
import { models, Report, Embed } from 'powerbi-client';
import { DefaultLayout } from '../../layouts';
import { URLS } from '../../constants';
import { Sidebar, IReport } from './Sidebar';
import { IReportConfig, IPage } from './';
import { Arrow } from '../../components/Icons/Arrow';
import { MenuBarsIcon } from '../../components/Icons/MenuBarsIcon';
import { SectionSpinner } from '../../components/Loader/SectionSpinner';

interface LocationState {
  token: string;
  report: IReport;
  reports: IReport[];
  refresh: boolean;
}
interface IReportViewProps extends RouteComponentProps<{}, {}, LocationState> {
  reportConfig: IReportConfig;
  isLoading: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .report-style-class {
    height: 100%;

    iframe {
      border: 0;
    }
  }
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;

const BackButton = styled(HeaderButton)`
  transform: rotate(180deg);
`;

const PageTitle = styled.div`
  text-align: center;
  flex: 1;
  align-self: center;
  font-weight: 600;
`;

const LeftPane = styled.div`
  display: flex;
`;
const RightPane = styled.div`
  flex: 1;
`;
const ReportContainer = styled.div`
  display: flex;
  flex: 1;
`;
const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const ReportView: React.FC<IReportViewProps> = props => {
  const { state } = props.location;
  const { pageName, reportId } = useParams();
  const [reportComp, setReportComp] = useState<Report>();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [currentPage, setCurrentPage] = useState<IPage>(state?.report.pages[0]);

  if (!state) {
    props.history.replace(URLS.REPORTS_LANDING);
  }

  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    tokenType: models.TokenType.Embed,
    embedUrl: state?.report.embedUrl,
    accessToken: props.reportConfig?.accessToken ? props.reportConfig.accessToken : state?.token,
    pageName,
  });

  const errorHandler: EventHandler = event => {
    props.history.replace({
      state: {
        ...state,
        refresh: state.refresh !== undefined ? !state.refresh : true,
      },
    });
  };

  useEffect(() => {
    const report = state?.reports.find(report => report.reportId === reportId);
    const page = report?.pages.find(page => page.name === pageName);
    if (page) {
      setCurrentPage(page);
      setReportConfig({
        ...reportConfig,
        embedUrl: report?.embedUrl,
        pageName: page.name,
      });
    }
  }, [pageName, reportId]);

  useEffect(() => {
    if (props.reportConfig.accessToken) {
      setReportConfig({
        ...reportConfig,
        accessToken: props.reportConfig.accessToken,
      });
    }
  }, [props.reportConfig.accessToken]);

  return (
    <Container>
      <DefaultLayout
        headerContent={
          <>
            <HeaderButton onClick={() => setSidebarVisible(!sidebarVisible)}>
              <MenuBarsIcon />
            </HeaderButton>
            <BackButton onClick={() => props.history.goBack()}>
              <Arrow />
            </BackButton>
            <PageTitle>{currentPage?.displayName}</PageTitle>
          </>
        }
      >
        <ReportContainer>
          {sidebarVisible && (
            <LeftPane>
              <Sidebar currentPage={currentPage} state={{ ...state, token: reportConfig.accessToken! }} />
            </LeftPane>
          )}
          <RightPane>
            {props.isLoading ? (
              <SpinnerContainer>
                <SectionSpinner />
              </SpinnerContainer>
            ) : (
              <PowerBIEmbed
                key={reportConfig.accessToken}
                embedConfig={{
                  ...reportConfig,
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                    },
                    background: models.BackgroundType.Default,
                  },
                }}
                eventHandlers={new Map([['error', errorHandler]])}
                cssClassName="report-style-class"
                getEmbeddedComponent={(embeddedReport: Embed) => {
                  setReportComp(embeddedReport as Report);
                }}
              />
            )}
          </RightPane>
        </ReportContainer>
      </DefaultLayout>
    </Container>
  );
};
