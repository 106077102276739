export const getConfigEnv = () => {
  switch (window.location.host) {
    case 'dev.scout.shell.io':
      return 'dev';
    case 'staging.scout.shell.io':
      return 'staging';
    case 'uat.scout.shell.io':
      return 'preprod';
    case 'scout.shell.com':
      return 'prod';
    default:
      return 'local';
  }
};
