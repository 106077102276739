import React, { createContext, useContext, useState } from 'react';
import { markers } from './markers';
import { TransportQueryResult } from '@scout/types';
import { usePermission } from '../../hooks/usePermission';
import { MarkerType } from '../../components/Map/Mapbox';

interface PopupState {
  vessel: TransportQueryResult | null;
  marker?: MarkerType;
}

interface ViewportState {
  center: [number, number];
  zoom: number;
}

interface ContextState {
  state: {
    popup: PopupState;
    viewport: ViewportState;
  };
  action: {
    togglePopup: React.Dispatch<React.SetStateAction<PopupState>>;
    setViewport: React.Dispatch<React.SetStateAction<ViewportState>>;
  };
}

const ZOOM_LEVEL = 4;

const MapContext = createContext<ContextState>({
  state: {
    popup: { vessel: null },
    viewport: {
      center: [markers[0].lng, markers[0].lat],
      zoom: ZOOM_LEVEL,
    },
  },
  action: {
    togglePopup: () => undefined,
    setViewport: () => undefined,
  },
});

export const useMapContext = () => {
  const context = useContext(MapContext);

  return context;
};

export const MapProvider: React.FC = ({ children }) => {
  const { hasAnyOf } = usePermission();
  if (hasAnyOf(['editEastSchedulingManager', 'viewEastSchedulingManager', 'eastDepotUser'])) {
    const [popup, togglePopup] = useState<PopupState>({ vessel: null });
    const [viewport, setViewport] = useState<ViewportState>({
      zoom: ZOOM_LEVEL,
      center: [markers[1].lng, markers[1].lat],
    });
    return (
      <MapContext.Provider
        value={{
          state: {
            popup,
            viewport,
          },
          action: {
            togglePopup,
            setViewport,
          },
        }}
      >
        {children}
      </MapContext.Provider>
    );
  } else {
    const [popup, togglePopup] = useState<PopupState>({ vessel: null });
    const [viewport, setViewport] = useState<ViewportState>({
      zoom: ZOOM_LEVEL,
      center: [markers[0].lng, markers[0].lat],
    });
    return (
      <MapContext.Provider
        value={{
          state: {
            popup,
            viewport,
          },
          action: {
            togglePopup,
            setViewport,
          },
        }}
      >
        {children}
      </MapContext.Provider>
    );
  }
};
