import moment, { Moment } from 'moment';
import { InMonth } from '@scout/domains';

const DEFAULT_DATE_ITEM = {
  label: 'Next 30 days',
  value: InMonth.DEFAULT_DATE,
};
const DEFAULT_DATE_ITEM2 = {
  label: 'Next 45 days',
  value: InMonth.NEXT_45_DAYS,
};
const DEFAULT_DATE_ITEM3 = {
  label: 'Next 60 days',
  value: InMonth.NEXT_60_DAYS,
};
const DEFAULT_DATE_ITEM4 = {
  label: 'Rolling 30 days',
  value: InMonth.ROLLING_DATE,
};
const DEFAULT_DATE_ITEM5 = {
  label: 'Rolling 45 days',
  value: InMonth.ROLLING_45_DAYS,
};
const DEFAULT_DATE_ITEM6 = {
  label: 'Rolling 60 days',
  value: InMonth.ROLLING_60_DAYS,
};

const getDateItem = (date: moment.Moment) => ({
  label: date.format('MMM YYYY'),
  value: date.format('MM-YYYY'),
});

export const getDates = () => {
  // start at novemeber previous year
  const currentYearDate = moment()
    .utc()
    .startOf('year')
    .add(-2, 'months');

  return [
    DEFAULT_DATE_ITEM, // next 30 days
    ...Array.from({ length: 13 }).map(() => getDateItem(currentYearDate.add(1, 'month'))), // dates from dec previous year to dev this year
  ];
};

export const getEastDates = () => {
  // start for East
  const startMonth = moment()
    .utc()
    .startOf('month')
    .add(-9, 'months');

  return [
    DEFAULT_DATE_ITEM, // next 30 days
    DEFAULT_DATE_ITEM2, // next 45 days
    DEFAULT_DATE_ITEM3, // next 60 days
    DEFAULT_DATE_ITEM4, // rolling 30 days
    DEFAULT_DATE_ITEM5, // rolling 45 days
    DEFAULT_DATE_ITEM6, // rolling 60 days
    ...Array.from({ length: 19 }).map(() => getDateItem(startMonth.add(1, 'month'))), // dates from previous 3 months to future 10 months
  ];
};

export const DATE_FORMAT = 'DD/MM (ddd)';

export const isToday = (date: string) =>
  date ===
  moment()
    .utc()
    .format('YYYY-MM-DD');

export const prettyDate = (date: string) => (isToday(date) ? 'Today' : moment(date).format(DATE_FORMAT));
