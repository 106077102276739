import { FastField, FastFieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import { Select } from '../../../../components/Select/Select';
import { Tagged } from '../../../../components/Tagged';
import { Typography } from '../../../../components/Typography';
import { mapContractTypeToString } from '../../../../helpers';
import { ContractDealType, ShipmentType } from '@scout/types';

const Section = styled.div`
  margin: 30px 0;
`;

const Label = styled(Typography)`
  margin-bottom: 8px;
`;

const TaggedContent = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
`;

interface HeaderModalProps {
  contractId: string;
  contractIdLabel: string;
  contractType: ContractDealType;
  customerCode: string;
  customerName: string;
  customerSalesOrganisation: string;
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  shipmentType: ShipmentType;
  currentUserTheme?: boolean;
}

const HeaderEditModal: React.FC<HeaderModalProps> = props => (
  <Modal
    minWidth={400}
    onConfirm={props.onConfirm}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    confirmText="Done"
    confirmBtnVariant="primary"
    dismissText="Cancel"
    currentUserTheme={props.currentUserTheme}
  >
    <Typography variant="heading5" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} align="center" bold={true}>
      Contracted demands
    </Typography>

    <Section>
      <Label variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
        Contract type:
      </Label>
      <Typography variant="body3" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}>
        {mapContractTypeToString(props.contractType)}
      </Typography>
    </Section>

    <Section>
      <Label variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
        Sold-to:
      </Label>
      <Typography variant="body3" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}>
        {props.customerName}
      </Typography>
      <TaggedContent>
        <Typography variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} inline={true}>
          {props.customerCode}
        </Typography>
        <Tagged tag={props.customerSalesOrganisation} />
      </TaggedContent>
    </Section>

    <Section>
      <Label variant="labels1" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
        {props.contractIdLabel}
      </Label>
      <Typography variant="body3" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}>
        {props.contractId}
      </Typography>
    </Section>

    <Label variant="body3" color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}>
      Shipment type
    </Label>

    <FastField
      name="shipmentType"
      render={({ field, form }: FastFieldProps) => {
        return (
          <Select
            onItemClick={id => form.setFieldValue(field.name, id)}
            selected={field.value}
            items={[
              { value: ShipmentType.Pickup, label: 'Pick up' },
              { value: ShipmentType.Delivery, label: 'Delivered' },
            ]}
            themeStyle={props.currentUserTheme ? 'light' : 'dark'}
          />
        );
      }}
    />
  </Modal>
);

export { HeaderEditModal };
