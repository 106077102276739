import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { Button } from '../Button';

const Root = styled.div<{ currentUserTheme?: boolean }>`
  position: fixed;
  left: 0;
  background-color: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  height: 66px;
  width: 100%;
  bottom: 0;
  transition: all 0.3s ease;
`;

const Inner = styled.div`
  margin-top: -3px;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const SaveButton = styled(Button)`
  margin-left: auto;
`;

interface Props {
  primaryText: string;
  secondaryText?: string;
  onConfirmPrimary: () => void;
  onConfirmSecondary?: () => void;
  disabled?: boolean;
  currentUserTheme?: boolean;
}

const ConfirmationPrompt = ({
  primaryText,
  secondaryText,
  onConfirmPrimary,
  onConfirmSecondary: onConfirmSeconary,
  disabled = false,
  currentUserTheme,
}: Props) => (
  <Root currentUserTheme={currentUserTheme}>
    <Container>
      <Inner>
        {secondaryText && (
          <Button
            disabled={disabled}
            data-testid="confirm-secondary-button"
            onClick={onConfirmSeconary}
            variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}
            type="button"
          >
            {secondaryText}
          </Button>
        )}
        <SaveButton disabled={disabled} data-testid="confirm-primary-button" onClick={onConfirmPrimary}>
          {primaryText}
        </SaveButton>
      </Inner>
    </Container>
  </Root>
);

export { ConfirmationPrompt };
