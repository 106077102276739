import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Pin } from '../../../../components/Pin/Pin';
import { Cell, Row } from '../../../../components/Table';
import { Tag } from '../../../../components/Tag/Tag';
import { Typography } from '../../../../components/Typography';
import { mapContractTypeToString } from '../../../../helpers';
import { ContractDealType } from '@scout/types';
import { TableDataItem } from '../createTableData';

const StyledTag = styled(Tag)`
  margin-left: 5px;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 2px;
`;

const CustomerName = styled.div`
  width: 286px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TaggedContent: React.FunctionComponent<{ title: string; tag: string; currentUserTheme?: boolean }> = props => {
  return (
    <TagWrapper>
      <Typography variant="labels1" color={props.currentUserTheme ? 'disabledBgDark' : 'hintLight'}>
        {props.title}
      </Typography>
      <StyledTag>{props.tag}</StyledTag>
    </TagWrapper>
  );
};

interface Props {
  item: TableDataItem;
  onRowClick: (dealId: string, contractType: ContractDealType) => void;
  currentUserTheme?: boolean;
}

const TableRow: React.FC<Props> = ({ item, onRowClick, currentUserTheme }) => {
  const { customer, dealId, stascoId, type } = item;
  const handleRowClick = useCallback(() => {
    onRowClick(dealId, type);
  }, [dealId, onRowClick, type]);

  return (
    <Row data-testid={dealId} onClick={handleRowClick} currentUserTheme={currentUserTheme}>
      <Cell
        data-testid="sold-to-column"
        justify="flex-start"
        sticky="left"
        border="large"
        rightBorderShadow={true}
        currentUserTheme={currentUserTheme}
      >
        <Pin currentUserTheme={currentUserTheme} />
        <CustomerName>
          <Typography
            bold={true}
            color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
            overflowHidden={true}
            title={customer.name}
            variant="body3"
          >
            {customer.name}
          </Typography>
          <TaggedContent tag={customer.salesOrganisation} title={customer.code} currentUserTheme={currentUserTheme} />
        </CustomerName>
      </Cell>
      <Cell
        data-testid="type-column"
        text={mapContractTypeToString(type)}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="stasco-id-column"
        text={stascoId}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="base-plant-column"
        text={item.basePlantName}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="total-volume-column"
        text={item.totalVolume}
        justify="flex-end"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="shipment-column"
        text={item.shipmentType}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="start-column"
        text={item.start}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="end-column"
        text={item.end}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="deal-id-column"
        text={dealId}
        justify="flex-start"
        border="small"
        currentUserTheme={currentUserTheme}
      />
      <Cell
        data-testid="loaded-column"
        text={item.loadedDate}
        justify="flex-start"
        currentUserTheme={currentUserTheme}
      />
    </Row>
  );
};

export { TableRow };
