import { SecondaryFilterOptionType } from '../../../components/SecondaryFilter';
import { DemandNames } from './constants';
import { DashboardRowType } from './types';

export const createCustomerDropdownItems = (demands: DashboardRowType[]) =>
  demands
    .reduce((groups: SecondaryFilterOptionType[], demand) => {
      if (groups.find(customer => customer.value === demand.customer.id)) {
        return groups;
      }
      return [
        ...groups,
        {
          label: demand.customer.name,
          value: demand.customer.id,
          tag: demand.customer.salesOrganisation,
          id: demand.customer.code,
        },
      ];
    }, [])
    .sort((v1, v2) => {
      if (!v1.label || !v2.label) {
        return -1;
      }

      return v1.label > v2.label ? 1 : -1;
    });

export const createPlantDropdownItems = (demands: DashboardRowType[]) =>
  demands
    .reduce<SecondaryFilterOptionType[]>((groups, demand) => {
      const plants: SecondaryFilterOptionType[] = [];
      const penPlantName = demand.primaryPenPlant?.shortName;
      const premPlantName = demand.primaryPremiumPlant?.shortName;
      if (penPlantName && !groups.find(plant => plant.value === penPlantName)) {
        plants.push({
          label: penPlantName,
          value: penPlantName,
        });
      }
      if (premPlantName && !groups.find(plant => plant.value === premPlantName)) {
        plants.push({
          label: premPlantName,
          value: premPlantName,
        });
      }

      return [...groups, ...plants];
    }, [])
    .sort((v1, v2) => {
      if (!v1.label || !v2.label) {
        return -1;
      }

      return v1.label > v2.label ? 1 : -1;
    });

export const createDemandTypeDropdownItems = (): SecondaryFilterOptionType[] =>
  Object.entries(DemandNames).map(([key, value]) => ({ label: value, value: key }));
