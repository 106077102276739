import React, { useContext, useState } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../components/Button';
import { Header } from '../components/Header';
import { NavLink } from '../components/NavLink';
import { NetsimModal } from '../components/NetsimModal';
import { RequirePermission } from '../components/RequirePermission';
import { makeURL, URLS } from '../constants';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { theme } from '../theme';
import { createNewContractsLink } from '../utils/links';
import { UserThemeContext } from '../App';

const AlignRight = styled.div`
  display: flex;
  align-items: center;
`;

const AlignLeft = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
`;
const GlobalBodyStyle = createGlobalStyle<{ currentUserTheme?: boolean }>`body {
  background-color: ${p => (p.currentUserTheme ? theme.base.light.a : theme.base.dark.b)}
  `;

const MidTermLayout: React.FC = ({ children }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const currentUser = useCurrentUser();
  const [showNetsimModel, toggleNetsimModel] = useState(false);

  // This will match the current URL and return its params
  const routeMatch = useRouteMatch<{ clusterId: string }>();

  // There should always be a `:clusterId` in the URL _unless_ we're
  // on /mid-term as we are selecting the cluster to use. In that case
  // we can just set the NavLinks to `/mid-term` again since they're
  // hidden behind an overlay anyway and cannot be clicked 🤓
  const clusterId = routeMatch != null ? routeMatch.params.clusterId : undefined;

  return (
    <>
      <Header>
        <GlobalBodyStyle currentUserTheme={currentUserTheme} />
        <AlignLeft>
          <NetsimModal currentUser={currentUser} isOpen={showNetsimModel} onCancel={() => toggleNetsimModel(false)} />
          <NavLink
            activePaths={[URLS.MID_TERM_CREATE_SPOT, URLS.MID_TERM_DASHBOARD, URLS.MID_TERM_EDIT_SPOT]}
            to={
              clusterId
                ? makeURL(URLS.MID_TERM_DASHBOARD, {
                    clusterId,
                  })
                : URLS.MID_TERM
            }
          >
            Forecasts
          </NavLink>
          <NavLink to={clusterId ? createNewContractsLink({ clusterId }) : makeURL(URLS.MID_TERM)}>
            New contracts
          </NavLink>
        </AlignLeft>
        <AlignRight>
          <Route
            path={URLS.MID_TERM_DASHBOARD}
            render={() => (
              <RequirePermission anyOf={['downloadNetsim']}>
                <Button variant="ghost-dark" icon="MdFileDownload" onClick={() => toggleNetsimModel(true)}>
                  Export SDF
                </Button>
              </RequirePermission>
            )}
          />
        </AlignRight>
      </Header>

      <main>{children}</main>
    </>
  );
};

export { MidTermLayout };
