import * as React from 'react';
import { components as SelectComponents } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import styled from 'styled-components';
import { SingleDropdownProps } from '.';
import { Row } from '../../Grid';
import { Tag } from '../../Tag/Tag';
import { Typography } from '../../Typography';

const Title = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Id = styled(Typography)`
  margin-right: 4px;
`;

const Inner = styled.div`
  cursor: pointer;
  width: 100%;
`;

const BottomRow = styled(Row)`
  padding-bottom: 0;
`;

const Option = (props: OptionProps<SingleDropdownProps> & { onBlur: () => void }) => {
  const { darkTheme } = props.selectProps;
  return (
    <SelectComponents.Option {...props}>
      <Inner>
        <Title variant="body3" title={props.label} color={darkTheme ? 'bodyDark' : 'bodyLight'}>
          {props.label}
        </Title>
        <BottomRow>
          {props.data.id && (
            <Id variant="labels1" color="hintLight">
              {props.data.id}
            </Id>
          )}
          {props.data.tag && <Tag color={darkTheme ? 'bodyDark' : 'highlight'}>{props.data.tag}</Tag>}
        </BottomRow>
      </Inner>
    </SelectComponents.Option>
  );
};

export { Option };
