import { Formik, FormikActions, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import * as Yup from 'yup';
import { SpotForm } from '.';
import { Cluster, VolumePricingItem } from '../../../types';
import { CreateSpotDemandInput, FuelOilQuoteQuery, GradeSplitInput, ShipmentType } from '@scout/types';
import { Footer } from './Footer';
import { GradePlantContainer } from './GradePlantContainer';
import { GradeOptions, GradeValues, PlantOptions, TabsOptionsProps } from './types';
import { VolumePricingContainer } from './VolumePricingContainer';
import { TransportCostsContainer } from './TransportCostsContainer';

const Root = styled.div`
  padding: 40px 0 100px;
`;

export interface PlantDropdownValue {
  id?: string;
  plantId: string;
}

export interface SpotFormValuesProps {
  plantSelectionReasonTechnical: boolean;
  plantSelectionReasonOperational: boolean;
  plantSelectionReasonContractual: boolean;
  totalGrades: number;
  grades: GradeOptions;
  penPlantPrimary: PlantDropdownValue;
  penPlantSecondary: PlantDropdownValue;
  premiumPlantPrimary: PlantDropdownValue;
  premiumPlantSecondary: PlantDropdownValue;
  plantSelectionNote: string;
  volumePrices: VolumePricingItem[];
}

export interface FormProps {
  spotDemandId?: string;
  cluster: Cluster;
  shipmentType: ShipmentType;
  customerCode: string;
  customerId: string;
  regionId?: string;
  shipToId?: string;
  initialValues: SpotFormValuesProps;
  penPlants: PlantOptions[];
  premiumPlants: PlantOptions[];
  isEditing?: boolean;
  onCancel: () => void;
  onSubmit: (values: Omit<CreateSpotDemandInput, 'ownerId'>) => Promise<void>;
  fuelOilQuote: FuelOilQuoteQuery['fuelOilQuote'];
  countryCode?: string;
  year: number;
}

const validatePlantReason = (penPlant: string, premiumPlant: string, reasons: boolean[]) => {
  if ((penPlant || premiumPlant) && reasons.every(reason => !reason)) {
    return 'You must select at least one reason';
  }

  return false;
};

const validatePlantDropdowns = (
  plant: PlantDropdownValue,
  totalGrades: number,
  grades: GradeValues,
  shipmentType: ShipmentType,
) => {
  if (
    !plant.plantId &&
    shipmentType === ShipmentType.Pickup &&
    totalGrades === 100 &&
    Object.keys(grades).find((key: string) => Number(grades[key].percentage) > 0)
  ) {
    return 'You must select at least one preferred plant';
  }

  return undefined;
};

export const getValidationSchema = () => {
  const validation = {
    totalGrades: Yup.mixed().oneOf([0, 100], 'Your grade split must add up to 100%'),
    plantSelectionReasonTechnical: Yup.mixed().when(
      [
        'penPlantPrimary.plantId',
        'premiumPlantPrimary.plantId',
        'plantSelectionReasonOperational',
        'plantSelectionReasonContractual',
      ],
      {
        is: (penPlantPrimary, premiumPlantPrimary, reason1, reason2) =>
          validatePlantReason(penPlantPrimary, premiumPlantPrimary, [reason1, reason2]),
        then: Yup.boolean().required('You must select at least one reason'),
      },
    ),
    volumePrices: Yup.array().of(
      Yup.object({
        price: Yup.mixed().test({
          name: 'priceNumber',
          message: 'Invalid number',
          test: value => isNaN(Number(value)) !== true,
        }),
        incrementalVolume: Yup.mixed().when('discount', {
          is: val => val !== '',
          then: Yup.mixed().notOneOf([''], 'This is a required field'),
        }),
        // Fixes Cyclic dependency error with incrementalVolume dependency on discount
        discount: Yup.lazy(value =>
          value === ''
            ? Yup.mixed().when('incrementalVolume', {
                is: val => val,
                then: Yup.mixed().notOneOf([''], 'This is a required field'),
              })
            : Yup.mixed().test({
                name: 'discountNumber',
                message: 'Invalid number',
                test: value => isNaN(Number(value)) !== true,
              }),
        ),
      }),
    ),
  };

  return validation;
};

const parsePricingItem = (value: string) => {
  return value === '' ? null : Number(value);
};

const Form: React.FunctionComponent<FormProps> = props => {
  const location = useLocation();
  const searchParams = decodeURIComponent(location.search)
    .split('?')
    .pop();
  const urlParams = new URLSearchParams(searchParams);
  const spotMonth = parseInt(
    urlParams
      .get('date')
      ?.split('.')
      .pop() || new Date().getUTCMonth().toString(),
    10,
  );
  const spotYear = parseInt(
    urlParams
      .get('year')
      ?.split('.')
      .pop() || new Date().getUTCFullYear().toString(),
    10,
  );
  const date = moment(`${spotMonth + 1}-${spotYear}`, 'M-YYYY');
  const [isSaving, setIsSaving] = useState(false);

  const resetPlantValidation = (formProps: FormikProps<SpotFormValuesProps>) => {
    if (
      props.shipmentType === ShipmentType.Delivery &&
      formProps.errors &&
      (formProps.errors.penPlantPrimary || formProps.errors.premiumPlantPrimary)
    ) {
      formProps.setErrors({ ...formProps.errors, penPlantPrimary: undefined, premiumPlantPrimary: undefined });
    }
  };

  const validateGradeTab = (values: SpotFormValuesProps) => {
    const invalidatePenPlantSelection = validatePlantDropdowns(
      values.penPlantPrimary,
      values.totalGrades,
      { ...values.grades.hard, ...values.grades.soft },
      props.shipmentType,
    );
    const invalidatePremiumPlantSelection = validatePlantDropdowns(
      values.premiumPlantPrimary,
      values.totalGrades,
      values.grades.premium,
      props.shipmentType,
    );
    const invalidPlantReason = validatePlantReason(values.penPlantPrimary.plantId, values.premiumPlantPrimary.plantId, [
      values.plantSelectionReasonContractual,
      values.plantSelectionReasonOperational,
      values.plantSelectionReasonTechnical,
    ]);

    if (invalidatePenPlantSelection || invalidatePremiumPlantSelection) {
      return {
        penPlantPrimary: { plantId: invalidatePenPlantSelection },
        premiumPlantPrimary: { plantId: invalidatePremiumPlantSelection },
      };
    }

    if (invalidPlantReason) {
      return { plantSelectionReasonTechnical: invalidPlantReason };
    }

    return null;
  };

  const onSubmit = async (values: SpotFormValuesProps, formProps: FormikActions<SpotFormValuesProps>) => {
    setIsSaving(true);
    const formErrors = validateGradeTab(values);
    if (formErrors !== null) {
      formProps.setErrors(formErrors);
    } else {
      const selectedGrades: GradeSplitInput[] = [];
      const availableGrades = { ...values.grades.hard, ...values.grades.soft, ...values.grades.premium };

      for (const key in availableGrades) {
        if (availableGrades.hasOwnProperty(key)) {
          selectedGrades.push({
            gradeId: key,
            type: availableGrades[key].type,
            percentage: Number(availableGrades[key].percentage) || 0,
          });
        }
      }

      const plants = [];
      if (values.penPlantPrimary.plantId) {
        plants.push({
          ...values.penPlantPrimary,
          isPremium: false,
          isPrimary: true,
        });
      }

      if (values.penPlantSecondary.plantId) {
        plants.push({
          ...values.penPlantSecondary,
          isPremium: false,
          isPrimary: false,
        });
      }

      if (values.premiumPlantPrimary.plantId) {
        plants.push({
          ...values.premiumPlantPrimary,
          isPremium: true,
          isPrimary: true,
        });
      }

      if (values.premiumPlantSecondary.plantId) {
        plants.push({
          ...values.premiumPlantSecondary,
          plantId: values.premiumPlantSecondary.plantId,
          isPremium: true,
          isPrimary: false,
        });
      }

      const payload = {
        shipmentType: props.shipmentType,
        clusterId: props.cluster.id,
        shipToId: props.shipToId,
        regionId: props.regionId,
        customerId: props.customerId,
        plantSelectionNote: values.plantSelectionNote,
        plantSelectionReasonOperational: Boolean(values.plantSelectionReasonOperational),
        plantSelectionReasonTechnical: Boolean(values.plantSelectionReasonTechnical),
        plantSelectionReasonContractual: Boolean(values.plantSelectionReasonContractual),
        plants,
        grades: selectedGrades,
        forecasts: values.volumePrices.map((value: VolumePricingItem) => ({
          id: value.id || undefined,
          date: value.date,
          discount: parsePricingItem(value.discount),
          price: parsePricingItem(value.price),
          incrementalVolume: parsePricingItem(value.incrementalVolume),
          volume: parsePricingItem(value.volume),
        })),
      };

      await props.onSubmit(payload);
    }
    setIsSaving(false);
  };

  return (
    <Formik
      initialValues={{
        ...props.initialValues,
        shipmentType: props.shipmentType,
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape(getValidationSchema())}
      render={(formProps: FormikProps<SpotFormValuesProps>) => {
        const selectedPlant = formProps.values.penPlantPrimary || formProps.values.premiumPlantPrimary;
        resetPlantValidation(formProps);

        return (
          <SpotForm
            renderForm={(activeTab, setActiveTab) => (
              <Root>
                <GradePlantContainer
                  initialValues={props.initialValues}
                  isActive={activeTab === TabsOptionsProps.Grade}
                  formProps={formProps}
                  penPlants={props.penPlants}
                  premiumPlants={props.premiumPlants}
                  shipmentType={props.shipmentType}
                  cluster={props.cluster}
                  regionId={props.regionId}
                  customerId={props.customerId}
                  shipToId={props.shipToId}
                />
                <VolumePricingContainer
                  spotDemandId={props.spotDemandId || 'unavailable'}
                  isEditing={props.isEditing}
                  isActive={activeTab === TabsOptionsProps.Volume}
                  volumeItems={formProps.values.volumePrices}
                  formProps={formProps}
                  cluster={props.cluster}
                  fuelOilQuote={props.fuelOilQuote}
                  shipmentType={props.shipmentType}
                  customerCode={props.customerCode}
                  countryCode={props.countryCode}
                  regionId={props.regionId}
                  plantId={selectedPlant.plantId}
                  year={props.year}
                />
                <TransportCostsContainer
                  cluster={props.cluster}
                  formProps={formProps}
                  isActive={activeTab === TabsOptionsProps.TransportCost}
                  customerId={props.customerId}
                  shipmentType={props.shipmentType}
                  date={date}
                />
                <Footer
                  values={formProps.values}
                  onConfirmSecondary={props.onCancel}
                  onSubmit={() => {
                    formProps.submitForm();
                    if (formProps.errors && formProps.errors.volumePrices) {
                      setActiveTab(TabsOptionsProps.Volume);
                    } else if (formProps.errors && Object.keys(formProps.errors).length > 0) {
                      setActiveTab(TabsOptionsProps.Grade);
                    }
                  }}
                  isSaving={isSaving}
                  isEditing={props.isEditing}
                  isDirty={formProps.dirty && Object.keys(formProps.touched).length > 0}
                />
              </Root>
            )}
          />
        );
      }}
    />
  );
};

export { Form };
