import React from 'react';
import { components as SelectComponents, OptionProps } from 'react-select';
import { DropdownItemOptions } from '../../../../components/SelectDropdown/Single';
import { Typography } from '../../../../components/Typography';
import { DropdownLabelRoot, ScheduledLabel } from './MovementForm';

export interface TransportDropdownItems extends DropdownItemOptions {
  active: boolean;
}
export const TransportDropdownOption = (props: OptionProps<{}>) => {
  return (
    <SelectComponents.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: ev => {
          if (!props.data.active) {
            props.innerProps.onClick(ev);
          }
        },
      }}
      isFocused={props.data.active ? false : props.isFocused}
    >
      <DropdownLabelRoot isDisabled={props.data.active}>
        <Typography variant="body3" color={props.data.active ? 'hintLight' : 'bodyLight'} overflowHidden={true}>
          {props.label}
        </Typography>
        <ScheduledLabel variant="body4" color={props.data.active ? 'hintLight' : 'bodyLight'}>
          {props.data.active ? 'Already scheduled' : ''}
        </ScheduledLabel>
      </DropdownLabelRoot>
    </SelectComponents.Option>
  );
};
