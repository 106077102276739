import React from 'react';
import { HeaderCell } from '../../../../components/Table';

interface Props {
  currentYear: number;
  currentUserTheme?: boolean;
}

export const TableHeader = ({ currentYear, currentUserTheme }: Props) => (
  <thead data-testid="am-dashboard-header">
    <tr>
      <HeaderCell
        rowSpan={2}
        text="Sold to"
        border="large"
        justify="flex-start"
        sticky="topleft"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Type"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Stasco ID"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Base plant"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text={`${currentYear} Total vol`}
        border="small"
        justify="flex-end"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Shipment type"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Contract start"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Contract end"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Deal ID"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Loaded date"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
    </tr>
  </thead>
);
