import { DashboardDataQuery } from '@scout/types';

export const filterByCustomerId = (spotDemands: DashboardDataQuery['spotDemands']['results'], soldToId: string) => {
  if (soldToId) {
    return spotDemands.filter(spotDemand => spotDemand.customer.id === soldToId);
  }

  return spotDemands;
};

export const filterByPrimaryPenPlant = (spotDemands: DashboardDataQuery['spotDemands']['results'], plant: string) => {
  if (plant) {
    return spotDemands.filter(spotDemand => {
      const primaryPen = spotDemand.plants.find(plant => plant.isPrimary && !plant.isPremium) || null;

      return primaryPen && primaryPen.plant.shortName === plant;
    });
  }

  return spotDemands;
};

const months = [
  { month: 'January', number: '01' },
  { month: 'February', number: '02' },
  { month: 'March', number: '03' },
  { month: 'April', number: '04' },
  { month: 'May', number: '05' },
  { month: 'June', number: '06' },
  { month: 'July', number: '07' },
  { month: 'August', number: '08' },
  { month: 'September', number: '09' },
  { month: 'October', number: '10' },
  { month: 'November', number: '11' },
  { month: 'December', number: '12' },
];

export const filterDates = (startMonth: string) => {
  const startMonthIndex = months.findIndex(m => m.month.toLocaleLowerCase() === startMonth.toLocaleLowerCase())!;

  return months.slice(startMonthIndex, startMonthIndex + 4);
};
