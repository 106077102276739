import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { URLS } from '../../constants';
import { Button } from '../Button';
import { Typography } from '../Typography';

export const ErrorIcon = withTheme(({ theme }) => (
  <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.271 43.1206C21.8326 49.4129 21.2991 56.5087 23.1795 63.5263C27.8884 81.1003 45.9523 91.5295 63.5262 86.8205C71.2582 84.7488 77.6071 80.0919 81.8923 74.029C87.3469 66.3114 89.4576 56.3158 86.8205 46.4738C84.3034 37.0801 77.9707 29.7278 69.9367 25.6376C62.9404 22.0757 54.654 20.9876 46.4737 23.1795C41.4289 24.5313 36.9728 26.9836 33.2946 30.2088"
      stroke={theme.secondary.light.c}
      strokeLinecap="round"
    />
    <path
      d="M94.2459 59.2191C95.1166 51.181 93.5337 42.8038 89.1814 35.2654C78.2823 16.3876 54.1433 9.91959 35.2655 20.8187C26.9599 25.614 21.0565 32.972 17.9774 41.3173C14.058 51.9401 14.7149 64.1624 20.8188 74.7346C26.6446 84.8253 36.2533 91.3702 46.8192 93.6125C56.0204 95.565 65.9475 94.2547 74.7347 89.1814C80.1538 86.0527 84.5503 81.8329 87.8069 76.9598"
      stroke={theme.secondary.light.c}
      strokeLinecap="round"
    />
    <rect
      x="59"
      y="62"
      width="9"
      height="24"
      rx="4.5"
      transform="rotate(-180 59 62)"
      fill={theme.greys.dark.disabled}
    />
    <rect x="59" y="75" width="9" height="9" rx="4.5" transform="rotate(-180 59 75)" fill={theme.secondary.light.a} />
  </svg>
));

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding-top: 82px;
`;

const ButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 350px;
`;

const ErrorMessage = styled(Typography)`
  margin-bottom: 8px;
`;

interface Props {
  message: string;
}

export const Error: React.FC<Props> = ({ message }) => {
  const history = useHistory();

  return (
    <Container>
      <ErrorIcon />
      <ErrorMessage bold={true} color="bodyDark" variant="heading6">
        {message}
      </ErrorMessage>
      <Typography color="bodyDark" variant="body1">
        Please try <strong>refreshing</strong> the page or coming back later.
      </Typography>
      <ButtonContainer>
        <Button variant="ghost-dark" onClick={() => history.push(URLS.ROOT)}>
          Return to home
        </Button>
        <Button variant="ghost-dark" icon="MdRefresh" onClick={() => history.goBack()}>
          Refresh page
        </Button>
      </ButtonContainer>
    </Container>
  );
};
