import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import {
  GET_LOCATION_VIEW_DATA,
  LocationNotesQueryResult,
  LocationViewDataQuery,
  LocationViewDataQueryVariables,
  MovementLocationType,
} from '@scout/types';
import { useMapContext } from '../../MapContext';
import { useSchedulerDate, useSchedulerLocationId } from './hooks';
import { LocationNotesModal } from './LocationNoteModal';
import { TableWrapper } from './Table/TableWrapper';
import { TableBody } from './TableBody';
import { TableLoader } from './TableLoader';
import { InMonth } from '@scout/domains';

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const dateFormat = 'YYYY-MM-DD';

export const getLocationNotesOnDate = (locationNotes: LocationViewDataQuery['locationNotes'], queryDate: string) =>
  locationNotes.filter(locationNote =>
    moment(queryDate, dateFormat).isBetween(
      moment(locationNote.fromDate, dateFormat),
      moment(locationNote.toDate, dateFormat),
      'days',
      '[]',
    ),
  );

export interface TableProps {
  onEditLocationNote: () => void;
  isNotesModalVisible: boolean;
  onHideNotesModal: () => void;
  selectedLocationNote: LocationNotesQueryResult[0] | null;
  setSelectedLocationNote: (value: LocationNotesQueryResult[0]) => void;
}

export const Table: React.FunctionComponent<TableProps> = ({
  onEditLocationNote,
  isNotesModalVisible,
  onHideNotesModal,
  selectedLocationNote,
  setSelectedLocationNote,
}) => {
  const [date] = useSchedulerDate();
  const [location, setLocationId] = useSchedulerLocationId();
  const dates = InMonth.toMonthDaysOrNext30Days(date);
  const locationViewDataQueryVariables: LocationViewDataQueryVariables = {
    fromDate: dates[0],
    toDate: dates[dates.length - 1],
    location: location || '',
  };

  const { error, loading, data, refetch } = useQuery<LocationViewDataQuery, LocationViewDataQueryVariables>(
    GET_LOCATION_VIEW_DATA,
    {
      skip: !location,
      fetchPolicy: 'no-cache',
      variables: locationViewDataQueryVariables,
    },
  );
  const {
    action: { togglePopup, setViewport },
    state,
  } = useMapContext();

  const isThirdParty = location === MovementLocationType.ThirdParty;

  return (
    <>
      {isNotesModalVisible && (
        <LocationNotesModal
          showModal={isNotesModalVisible}
          selectedLocationNote={selectedLocationNote}
          onConfirm={async locationId => {
            onHideNotesModal();
            await refetch(locationViewDataQueryVariables);
            setLocationId(locationId);
          }}
          onDismiss={() => {
            onHideNotesModal();
          }}
        />
      )}
      {loading || error || !data ? (
        <TableLoader data={data} loading={loading} error={error} />
      ) : (
        <TableWrapper isThirdParty={isThirdParty}>
          <TableBody
            isThirdParty={isThirdParty}
            dates={dates}
            data={data}
            onEditLocationNote={locationNote => {
              setSelectedLocationNote(locationNote);
              onEditLocationNote();
            }}
            onClickTransport={transport => {
              togglePopup({ vessel: transport });

              if (transport && state.popup.vessel?.id !== transport.id) {
                setViewport({
                  center: [transport.lon, transport.lat],
                  zoom: 9,
                });
              }
            }}
          />
        </TableWrapper>
      )}
    </>
  );
};
