export enum GradeSplit {
  PMB = 'PMB',
}

export enum TabsOptionsProps {
  Grade = 'grade',
  Volume = 'volume',
  TransportCost = 'transportCosts',
}

export enum GradeTypes {
  HARD = 'hard',
  SOFT = 'soft',
  PREMIUM = 'premium',
  NON_BITUMEN = 'non_bitumen',
}

export interface GradeValue {
  name: string;
  type: string;
  percentage: string;
}

export interface GradeValues {
  [gradeId: string]: GradeValue;
}

export interface GradeOptions {
  hard: GradeValues;
  soft: GradeValues;
  premium: GradeValues;
}

export interface PlantOptions {
  value: string;
  label: string;
  grades: string[];
  disabled?: boolean;
  tag?: string;
}

export enum Distribution {
  Seasonal = 'Seasonal',
  Even = 'Even',
}

export enum RefineryMovementTabOptionsProps {
  Production = 'Production',
  Lifting = 'Lifting',
  Event = 'Event',
}
export enum DepotsMovementTabOptionsProps {
  Sale = 'Sale',
  Replenishment = 'Replenishment',
  Event = 'Event',
}
