import * as React from 'react';

import { Route } from 'react-router-dom';
import { UserPage } from './User/User';

const AdminPage: React.SFC<{}> = () => {
  return <Route path="/admin/users" component={UserPage} />;
};

export { AdminPage };
