import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ChildMovementStageQueryResult, MovementLocationType, MovementStageQueryResult } from '@scout/types';
import { getLocationFromStage } from '../../utils';
import { Typography } from '../../../../../components/Typography';
import { FontColors } from '../../../../../theme';
import { colorMixin } from '../../../../../components/Mixins';

interface MovementDescriptionProps {
  isThirdParty?: boolean;
  movementStage: MovementStageQueryResult;
  color: FontColors;
}

const PortLinkStyle = styled(Link)`
  ${colorMixin}
`;

interface PortLinkProps {
  current: string;
  port: string;
  portId: string;
  isSelectedThirdParty?: boolean;
  isThirdPartyLocation?: boolean;
  color: FontColors;
}

const PortLink: React.FunctionComponent<PortLinkProps> = ({
  current,
  port,
  portId,
  isSelectedThirdParty,
  isThirdPartyLocation,
  color,
}) => {
  const location = useLocation();
  const isOtherType = Object.keys(MovementLocationType).includes(portId) && !isThirdPartyLocation;
  if (isOtherType || (isThirdPartyLocation && isSelectedThirdParty) || current === port) {
    return (
      <Typography tag="span" inline={true} color={color} variant="labels1">
        {port}
      </Typography>
    );
  }

  return (
    <PortLinkStyle
      color={color}
      to={{
        pathname: `/in-month/${isThirdPartyLocation ? MovementLocationType.ThirdParty : portId}`,
        search: location.search,
      }}
    >
      {port}
    </PortLinkStyle>
  );
};

/**
 * @todo The core logic of this function has been extracted to the @scout/domains package, so at
 * some point it would be good to use the shared function here.
 */
export const MovementDescription: React.FunctionComponent<MovementDescriptionProps> = ({
  movementStage,
  isThirdParty,
  color,
}) => {
  return (
    <span data-testid="movement-description">
      {movementStage.movement.movementStages
        .sort((stage1, stage2) => stage1.index - stage2.index)
        .reduce((acc: React.ReactElement[], iter: ChildMovementStageQueryResult) => {
          const port = getLocationFromStage(iter);
          const id = iter.location ? iter.location.id : iter.locationType;
          const current = getLocationFromStage(movementStage);
          const isThirdPartyLocation = iter.location?.isThirdParty || id === MovementLocationType.OutOfRegion;

          if (acc.length === 0) {
            return [
              <PortLink
                key={port}
                color={color}
                current={current}
                port={port}
                portId={id}
                isSelectedThirdParty={isThirdParty}
                isThirdPartyLocation={isThirdPartyLocation}
              />,
            ];
          }

          return [
            ...acc,
            <Typography tag="span" inline={true} key={`to-${port}`} color={color} variant="labels1">
              &nbsp;to&nbsp;
            </Typography>,
            <PortLink
              key={port}
              color={color}
              current={current}
              port={port}
              portId={id}
              isSelectedThirdParty={isThirdParty}
              isThirdPartyLocation={isThirdPartyLocation}
            />,
          ];
        }, [])}
    </span>
  );
};
