import { FormikHandlers } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TextInput } from '../../../../../components/TextInput';
import { Total } from '../../../../../components/Total';
import { Typography } from '../../../../../components/Typography';
import { restrictNumberValue } from '../../../../../utils/validation';
import { MonthlyVolumesStepValuesRow } from '../types';
import { sumVolumes } from './calculate';

const Cell = styled.td`
  background-color: ${({ theme }) => theme.base.light.c};
  border-bottom: 3px solid ${({ theme }) => theme.base.light.b};
  border-radius: 2px;
`;

const HeaderCell = styled(Cell)<{ hasRightBorderSpace?: boolean }>`
  padding: 23px 8px 17px 8px;
  max-width: 108px;
  width: 108px;
  ${({ hasRightBorderSpace, theme }) => hasRightBorderSpace && `border-right: 3px solid ${theme.base.light.b};`}
`;

const BodyCell = styled(Cell)`
  padding: 15px 4px 14px 14px;

  & ~ & {
    padding-left: 4px;
  }
`;

const TotalCell = styled(Cell)`
  padding: 6px 6px 0 8px;
`;

const HeaderCellContent = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const BodyCellInput = styled(TextInput)`
  & > input {
    padding-left: 0.3125rem;
    padding-right: 1.1rem;
  }
  width: 57px;
`;

type Props = MonthlyVolumesStepValuesRow & {
  name: string;
  onChange: FormikHandlers['handleChange'];
};

const EditableRow = ({ id, plantName, location, name, onChange, targetTotal, volumes }: Props) => {
  const monthEntries = Object.entries(volumes);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const newValue = restrictNumberValue(value, 0, targetTotal);

      if (newValue == null) {
        return;
      }

      e.target.value = newValue;

      onChange(e);
    },
    [onChange, targetTotal],
  );

  return (
    <tr>
      <HeaderCell>
        <HeaderCellContent bold={true} variant="body3" inline={true} title={plantName}>
          {plantName}
        </HeaderCellContent>
      </HeaderCell>
      <HeaderCell hasRightBorderSpace={true}>
        <HeaderCellContent bold={true} variant="body3" inline={true} title={location}>
          {location}
        </HeaderCellContent>
      </HeaderCell>
      {monthEntries.map(([monthName, monthValue]) => (
        <BodyCell key={`${id}-${monthName}`}>
          <BodyCellInput
            align="right"
            name={`${name}.volumes.${monthName}`}
            onChange={handleChange}
            showErrorMessage={false}
            suffix="t"
            themeStyle="light"
            value={monthValue}
          />
        </BodyCell>
      ))}

      <TotalCell>
        <Total
          fixedTarget={targetTotal}
          showFixedTarget={true}
          targetType="Fixed"
          total={sumVolumes(volumes)}
          unit="t"
        />
      </TotalCell>
    </tr>
  );
};

export { EditableRow };
