import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '../Typography';

const NavLinkContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  padding: 0 40px;
  position: relative;
  text-decoration: none;
`;

const NavIndicator = styled.div`
  background-color: ${p => p.theme.primary.a};
  bottom: 0;
  height: 4px;
  position: absolute;
  width: 100%;
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

interface Props {
  activePaths?: string | string[];
  to: string;
}

const NavLink: React.FC<Props> = ({ activePaths, children, to }) => {
  const isActive =
    useRouteMatch({
      path: activePaths != null ? activePaths : to,
    }) != null;

  return (
    <NavLinkContainer to={to}>
      <Inner>
        <Typography variant="body1" color={'bodyDark'} align="center" bold={isActive}>
          {children}
        </Typography>
      </Inner>
      {isActive && <NavIndicator data-testid="nav-indicator" />}
    </NavLinkContainer>
  );
};

export { NavLink };
