import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const ScoutLogoIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="139" height="60" viewBox="0 0 139 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4924 21.511C16.9438 20.7937 16.162 20.2005 15.1471 19.7314C14.1597 19.2348 13.1585 18.9865 12.1436 18.9865C11.5401 18.9865 10.9367 19.0693 10.3332 19.2348C9.72976 19.3728 9.18116 19.6073 8.68743 19.9384C8.19369 20.2419 7.78224 20.6557 7.45309 21.1799C7.15136 21.6765 7.0005 22.2835 7.0005 23.0008C7.0005 23.663 7.13764 24.2286 7.41194 24.6976C7.68624 25.1666 8.05654 25.5667 8.52285 25.8978C9.01658 26.2289 9.60632 26.5186 10.2921 26.7669C10.9778 27.0152 11.7321 27.2635 12.555 27.5118C13.4876 27.8153 14.4477 28.1602 15.4351 28.5464C16.4501 28.9327 17.3689 29.4431 18.1918 30.0776C19.0422 30.7122 19.7279 31.5123 20.2491 32.478C20.7977 33.4436 21.072 34.6438 21.072 36.0784C21.072 37.6511 20.784 39.0306 20.2079 40.2169C19.6319 41.3757 18.8639 42.3413 17.9038 43.1139C16.9438 43.8864 15.8192 44.4658 14.53 44.852C13.2408 45.2383 11.883 45.4314 10.4566 45.4314C8.56399 45.4314 6.71248 45.0865 4.90212 44.3968C3.09175 43.6795 1.61055 42.631 0.458496 41.2515L4.16151 37.7752C4.87469 38.7685 5.82102 39.5548 7.0005 40.1342C8.20741 40.7135 9.38689 41.0032 10.5389 41.0032C11.1424 41.0032 11.7596 40.9343 12.3904 40.7963C13.0213 40.6308 13.5836 40.3687 14.0774 40.01C14.5985 39.6513 15.01 39.2099 15.3117 38.6857C15.6409 38.1339 15.8055 37.4579 15.8055 36.6578C15.8055 35.8853 15.6272 35.2507 15.2706 34.7541C14.914 34.2299 14.434 33.7885 13.8305 33.4298C13.2271 33.0436 12.5139 32.7125 11.691 32.4366C10.8681 32.1607 10.0041 31.871 9.09887 31.5675C8.22112 31.2916 7.34337 30.9605 6.46562 30.5743C5.58786 30.188 4.7924 29.6776 4.07923 29.043C3.39348 28.4085 2.83117 27.6359 2.39229 26.7255C1.95342 25.7874 1.73398 24.6286 1.73398 23.2492C1.73398 21.7593 2.03571 20.4764 2.63916 19.4004C3.27005 18.3244 4.07923 17.4415 5.0667 16.7517C6.0816 16.0344 7.21993 15.5102 8.4817 15.1791C9.7709 14.8204 11.0738 14.6411 12.3904 14.6411C13.8717 14.6411 15.3666 14.9032 16.8752 15.4274C18.4113 15.9516 19.7416 16.7379 20.8662 17.7864L17.4924 21.511Z"
      fill={theme.base.light.a}
    />
    <path
      d="M47.2901 22.2559C46.5221 21.2075 45.5209 20.4488 44.2866 19.9798C43.0522 19.4831 41.8453 19.2348 40.6658 19.2348C39.1572 19.2348 37.7857 19.5107 36.5514 20.0625C35.317 20.6143 34.2473 21.373 33.3421 22.3387C32.4643 23.3043 31.7786 24.4355 31.2848 25.7322C30.8185 27.029 30.5854 28.4361 30.5854 29.9535C30.5854 31.5537 30.8185 33.016 31.2848 34.3403C31.7511 35.6646 32.4095 36.8096 33.2598 37.7752C34.1375 38.7133 35.1799 39.4444 36.3868 39.9686C37.5937 40.4928 38.9515 40.7549 40.4601 40.7549C42.0236 40.7549 43.4088 40.4514 44.6157 39.8445C45.8226 39.2099 46.7964 38.3822 47.537 37.3614L51.6926 40.2997C50.4034 41.9275 48.8262 43.1966 46.961 44.1071C45.0957 44.99 42.9151 45.4314 40.419 45.4314C38.1423 45.4314 36.0439 45.0589 34.1238 44.314C32.2312 43.5415 30.5991 42.4793 29.2276 41.1274C27.8561 39.7479 26.7864 38.1201 26.0183 36.244C25.2503 34.3403 24.8663 32.2435 24.8663 29.9535C24.8663 27.6084 25.264 25.4977 26.0595 23.6216C26.8824 21.7179 27.9933 20.1039 29.3922 18.7796C30.8185 17.4553 32.4917 16.4345 34.4118 15.7171C36.3319 14.9998 38.4166 14.6411 40.6658 14.6411C41.5984 14.6411 42.5722 14.7377 43.5871 14.9308C44.602 15.0963 45.5757 15.3722 46.5084 15.7585C47.441 16.1172 48.3187 16.5724 49.1416 17.1242C49.9645 17.676 50.664 18.3382 51.24 19.1107L47.2901 22.2559Z"
      fill={theme.base.light.a}
    />
    <path
      d="M112.554 33.9264C112.554 35.6922 112.252 37.2924 111.649 38.7271C111.045 40.1342 110.222 41.3343 109.18 42.3276C108.138 43.3208 106.903 44.0933 105.477 44.6451C104.051 45.1693 102.501 45.4314 100.828 45.4314C99.1544 45.4314 97.6047 45.1693 96.1783 44.6451C94.752 44.0933 93.5039 43.3208 92.4342 42.3276C91.3918 41.3343 90.5689 40.1342 89.9655 38.7271C89.3894 37.2924 89.1014 35.6922 89.1014 33.9264V15.386H94.2857V33.7195C94.2857 34.5748 94.3954 35.4301 94.6148 36.2854C94.8343 37.1131 95.1908 37.858 95.6846 38.5201C96.2057 39.1823 96.8778 39.7203 97.7007 40.1342C98.551 40.548 99.5933 40.7549 100.828 40.7549C102.062 40.7549 103.091 40.548 103.914 40.1342C104.764 39.7203 105.436 39.1823 105.93 38.5201C106.451 37.858 106.821 37.1131 107.04 36.2854C107.26 35.4301 107.37 34.5748 107.37 33.7195V15.386H112.554V33.9264Z"
      fill={theme.base.light.a}
    />
    <path d="M124.604 19.9384H115.675V15.386H138.716V19.9384H129.788V44.6865H124.604V19.9384Z" fill="white" />
    <path
      d="M70.8872 14.8135C64.8288 13.884 58.5497 16.6874 55.3007 22.3476C52.0662 27.9827 52.7712 34.8182 56.5563 39.6275L57.2402 36.4975C55.0531 32.597 54.9136 27.6681 57.3009 23.509C59.7051 19.3205 64.063 16.9739 68.5484 16.9613L70.8872 14.8135Z"
      fill={theme.base.light.a}
    />
    <path
      d="M66.5961 45.3436C72.5484 46.1194 78.6548 43.3159 81.8432 37.7613C85.0462 32.1812 84.386 25.4238 80.6974 20.6226L80.0045 23.7934C82.0953 27.6645 82.1941 32.5054 79.8438 36.6001C77.5109 40.6644 73.3385 42.9945 68.9951 43.1406L66.5961 45.3436Z"
      fill={theme.base.light.a}
    />
    <path d="M79.2781 11.4033L73.4806 32.9054L63.6636 27.2044L79.2781 11.4033Z" fill="#0089FF" />
    <path d="M71.3069 33.195L60.4853 44.1459L64.5032 29.2439L71.3069 33.195Z" stroke="white" strokeWidth="2.68419" />
  </svg>
));
