import {
  CurrentUserQuery,
  GET_TRANSPORTS,
  GetTransportsQuery,
  CreateSimulationDataMutation,
  CreateSimulationDataMutationVariables,
  CREATE_SIMULATION_DATA,
} from '@scout/types';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { usePermission } from '../../hooks/usePermission';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TITLE_SIMULATION, URLS } from '../../constants';
import { LocationView } from './Scheduler/LocationView/LocationView';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { InMonthLayout } from '../../layouts';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { useToast } from '../../components/Toast/Toast';
import { Loader } from '../../components/Loader';

export interface SimulationProps {
  currentUser: CurrentUserQuery['currentUser'];
}

const SimulationBorder = styled.div`
margin-top:2px
border:2.5px solid red
height:100%
`;
export const Simulation: React.FC<SimulationProps> = () => {
  const { hasAnyOf } = usePermission();
  const { data, loading, error, refetch } = useQuery<GetTransportsQuery>(GET_TRANSPORTS, {
    skip: !hasAnyOf(['viewFleetManagement', 'editEastSchedulingManager', 'eastDepotUser']),
    fetchPolicy: 'no-cache',
  });
  const { listen } = useHistory();
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);
  const [fullViewActive, setFullViewActive] = useState(null);
  const [simulation, setSimulation] = useState(false);
  const toggleFullHalfView = (value: boolean) => {
    setFullViewActive(null);
  };
  const onCloseRequest = () => {
    return null;
  };
  const { push } = useToast();
  const [createSimulation, setCreateSimulation] = useMutation<
    CreateSimulationDataMutation,
    CreateSimulationDataMutationVariables
  >(CREATE_SIMULATION_DATA);

  const currentUser = useCurrentUser();
  const [currentUserTheme, setCurrentUserTheme] = useState(currentUser.userTheme ? true : false);
  const toggleTheme = () => {
    setCurrentUserTheme(!currentUserTheme);
  };

  useEffect(() => {
    return listen(location => {
      if (previousUrl?.includes(`${URLS.SIMULATION}/edit`) || previousUrl?.includes(`${URLS.SIMULATION}/new`)) {
        refetch();
      }

      setPreviousUrl(location.pathname);
    });
  }, [previousUrl]);

  let sessionId: string | null;
  sessionId = sessionStorage.getItem('sessionId');
  const [loader, setLoader] = useState(sessionId ? true : false);
  const create = async () => {
    if (sessionId) {
      try {
        const session = await createSimulation({
          variables: {
            input: sessionId,
          },
        });
        session && setSimulation(true);
        session && setLoader(true);
      } catch (error) {
        const ERRORMESSAGE = 'Error in creating simulation';
        push({ type: 'ERROR', text: ERRORMESSAGE });
      }
    }
  };

  useEffect(() => {
    document.title = TITLE_SIMULATION;
    if (!sessionId) {
      sessionId = uuidv4();
      sessionStorage.setItem('sessionId', sessionId);
      create();
    }
    sessionId && setSimulation(true);
  }, []);

  return (
    <InMonthLayout>
      <Switch>
        <Route path={`${URLS.SIMULATION}/:locationId?`}>
          <SimulationBorder>
            {loader ? (
              <LocationView
                onCloseRequest={onCloseRequest}
                toggleFullHalfView={toggleFullHalfView}
                fullViewActive={fullViewActive}
                toggleTheme={toggleTheme}
                currentUserTheme={currentUserTheme}
                simulation={simulation}
              />
            ) : (
              <Loader />
            )}
          </SimulationBorder>
        </Route>
      </Switch>
    </InMonthLayout>
  );
};
