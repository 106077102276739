import React from 'react';
import styled from 'styled-components';
import { GetAllTransportCostsQuery } from '@scout/types';
import { theme } from '../../../../theme';

interface TransportCostsTableProps {
  items: GetAllTransportCostsQuery['getAllTransportCosts'];
}

const TableContainer = styled.div`
  margin-top: 20px;
`;
const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;
const THead = styled.thead`
  background-color: ${theme.base.light.f};
`;
const TRow = styled.tr`
  th,
  td {
    text-align: left;
    padding: 8px 18px;
    border: 1px solid ${theme.base.light.f};
    padding-top: 12px;
  }
  td {
    color: ${theme.greys.dark.disabled};
  }
`;

const TransportCostsTable: React.FunctionComponent<TransportCostsTableProps> = ({ items, ...props }) => {
  return (
    <TableContainer>
      <Table>
        <THead>
          <TRow>
            <th>Plant</th>
            <th>Grade</th>
            <th>Mode of Transport</th>
            <th>Min Transport</th>
            <th>Max Transport</th>
            <th>Transport Cost</th>
          </TRow>
        </THead>
        <tbody>
          {items.map(item => (
            <TRow key={`${item.vendor}.${item.stream}`}>
              <td>{item.plantId}</td>
              <td>{item.stream}</td>
              <td>{item.modeOfTransport}</td>
              <td>{item.minTransport}</td>
              <td>{item.maxTransport}</td>
              <td>{item.cost}</td>
            </TRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export { TransportCostsTable };
