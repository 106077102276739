import { FormikErrors, FormikState } from 'formik';
import moment from 'moment';
import { DropdownItemOptions } from '../../../components/SelectDropdown/Single';
import { URLS } from '../../../constants';
import { sortDropdownItemsAlphabetically } from '../../../utils';
import { LocationNoteFormValues } from './LocationView/LocationNoteModal';
import { LocationNoteTypeNames } from './LocationView/LocationNoteTypeNames';
import { TransportDropdownItems } from './MovementView/TransportDropdownOption';
import { FormAction, MovementFormValues, ProductTransferFormValues } from './MovementView/types';
import {
  GetEditFormDataQuery,
  GradeModelFragmentFragment,
  LocationModelFragmentFragment,
  MovementGradeType,
  MovementInput,
  TransportType,
  UpdateMovementInput,
  MovementLocationType,
  MovementStageModel,
  ProductTransferQueryResult,
  LocationNoteInput,
  LocationNoteType,
  UpdateLocationNoteInput,
  CountryModel,
} from '@scout/types';
import { LOCATIONS, EASTLOCATIONS, DEPOTUSERSEASTLOCATIONS, CUSTOMREPORTOPTIONS } from '@scout/domains';
import {
  PriceBasisDropDownOptions,
  IncotermDropDownOptions,
  nextOneYearDropDownOptions,
  depotLoadPortOptions,
  RefineryDischargePortOptions,
} from './types';
import { MultiDropdownItem, allOption } from '../../../components/SelectDropdown/Multi';

export const parseVolume = (previousValue: string, value: string, type: 'LOAD' | 'DISCHARGE'): string => {
  if (value.length === 1 && previousValue.length > 1) {
    return '';
  }

  let parsedNumber = Math.floor(Number(value));

  if (isNaN(parsedNumber) && !previousValue) {
    return '';
  }

  if (isNaN(parsedNumber) && previousValue) {
    parsedNumber = Number(previousValue);
  }

  if (parsedNumber >= 0 && type === 'DISCHARGE') {
    return `+${parsedNumber}`;
  }

  if (parsedNumber >= 0 && type === 'LOAD') {
    return `-${parsedNumber}`;
  }

  if (parsedNumber < 0 && type === 'DISCHARGE') {
    return `+${parsedNumber * -1}`;
  }

  return parsedNumber.toString();
};

const parseTransportValues = (input: MovementFormValues) => {
  if (Object.values(TransportType).includes(input.transportId as TransportType)) {
    return {
      transportId: null,
      transportType: input.transportId as TransportType,
    };
  }

  if (!input.transportId) {
    return {
      transportId: null,
      transportType: TransportType.None,
    };
  }

  return {
    transportId: input.transportId,
    transportType: TransportType.Vessel,
  };
};

const parseLocationValues = (
  input: {
    locationId?: string | null;
  },
  locations: LocationModelFragmentFragment[],
): { locationId: string | null; locationType: MovementLocationType } => {
  if (!input.locationId) {
    return {
      locationId: null,
      locationType: MovementLocationType.None,
    };
  }

  if (locations.find(location => location.isThirdParty && location.id === input.locationId)) {
    return {
      locationId: input.locationId,
      locationType: MovementLocationType.ThirdParty,
    };
  }

  if (Object.values(MovementLocationType).includes(input.locationId as MovementLocationType)) {
    return {
      locationId: null,
      locationType: input.locationId as MovementLocationType,
    };
  }

  return {
    locationId: input.locationId,
    locationType: MovementLocationType.Port,
  };
};

const parseGradeValues = (input: ProductTransferFormValues) => {
  if (!input.gradeId) {
    return {
      gradeId: null,
      gradeType: MovementGradeType.None,
    };
  }

  if (Object.values(MovementGradeType).includes(input.gradeId as MovementGradeType)) {
    return {
      gradeId: null,
      gradeType: input.gradeId as MovementGradeType,
    };
  }

  return {
    gradeId: input.gradeId,
    gradeType: MovementGradeType.Selected,
  };
};

export const parseQueryDataToFormValues = (
  input: NonNullable<GetEditFormDataQuery['movementById']>,
): MovementFormValues => ({
  ...input,
  transportId: input.transportId || input.transportType,
  movementStages: input.movementStages.map(movementStage => ({
    ...movementStage,
    locationId: movementStage.locationId || movementStage.locationType,
    date: moment(movementStage.date, 'YYYY-MM-DD').format('YYYY/MM/DD'),
    productTransfers: movementStage.productTransfers.map(productTransfer => {
      const symbol = productTransfer.volume >= 0 ? '+' : '';
      const volume = `${symbol}${productTransfer.volume}`;

      return {
        ...productTransfer,
        movementStageIndex: movementStage.index,
        gradeId: productTransfer.gradeId || productTransfer.gradeType,
        movementStageId: productTransfer.movementStageId,
        volume,
      };
    }),
  })),
});

export const parseUpdateMovementInput = (
  input: MovementFormValues,
  locations: LocationModelFragmentFragment[],
  action: FormAction,
): UpdateMovementInput => {
  if (!input.id) {
    throw new Error('Missing movement id');
  }

  delete input.__typename;

  return {
    ...input,
    id: input.id,
    ...parseTransportValues(input),
    cancelled: action === 'cancel' ? true : input.cancelled,
    movementStages: input.movementStages.map(movementStage => {
      if (!movementStage.movementId) {
        throw new Error('Missing movementId');
      }

      if (!movementStage.date) {
        throw new Error('Missing date');
      }

      delete movementStage.__typename;

      return {
        ...movementStage,
        ...parseLocationValues(movementStage, locations),
        movementId: movementStage.movementId,
        date: moment(movementStage.date, 'YYYY/MM/DD').format('YYYY-MM-DD'),
        productTransfers: movementStage.productTransfers.map(productTransfer => {
          if (!productTransfer.movementStageId) {
            throw new Error('Missing movementStageId');
          }

          if (!productTransfer.gradeId) {
            throw new Error('Missing gradeId');
          }

          delete productTransfer.__typename;
          delete productTransfer.movementStageIndex;

          return {
            ...productTransfer,
            ...parseGradeValues(productTransfer),
            movementStageId: productTransfer.movementStageId,

            volume: parseFloat(productTransfer.volume),
          };
        }),
      };
    }),
  };
};

export const parseMovementInput = (
  input: MovementFormValues,
  locations: LocationModelFragmentFragment[],
): MovementInput => {
  delete input.id;

  return {
    ...input,
    ...parseTransportValues(input),
    movementStages: input.movementStages.map(movementStage => {
      if (!movementStage.date) {
        throw new Error('Missing date');
      }

      delete movementStage.id;
      delete movementStage.movementId;

      return {
        ...movementStage,
        ...parseLocationValues(movementStage, locations),
        date: moment(movementStage.date, 'YYYY/MM/DD').format('YYYY-MM-DD'),
        productTransfers: movementStage.productTransfers.map(productTransfer => {
          if (!productTransfer.gradeId) {
            throw new Error('Missing grade id');
          }

          delete productTransfer.movementStageId;
          delete productTransfer.movementStageIndex;

          return {
            ...productTransfer,
            ...parseGradeValues(productTransfer),
            volume: parseFloat(productTransfer.volume),
          };
        }),
      };
    }),
  };
};

export const getTransportItems = (
  vessels: GetEditFormDataQuery['transports'],
  initialTransportId?: string | null,
): TransportDropdownItems[] => {
  const transportItems = vessels.reduce(
    (prev: TransportDropdownItems[], vessel: GetEditFormDataQuery['transports'][0]) => {
      if (vessel.shipName) {
        return [
          ...prev,
          {
            label: vessel.shipName,
            value: vessel.id,
            active: initialTransportId === vessel.id ? false : Boolean(vessel.active),
          },
        ];
      }

      return prev;
    },
    [],
  );

  return sortDropdownItemsAlphabetically([
    ...transportItems,
    { label: TransportType.Rail, value: TransportType.Rail, active: false },
    { label: TransportType.Truck, value: TransportType.Truck, active: false },
    { label: TransportType.Other, value: TransportType.Other, active: false },
    { label: TransportType.Tbc, value: TransportType.Tbc, active: true },
  ]) as TransportDropdownItems[];
};

export const defaultDropdownOptions = [
  { label: 'Out of region', value: MovementLocationType.OutOfRegion },
  { label: MovementLocationType.Unknown, value: MovementLocationType.Unknown },
];

export const getPortItems = (
  locations: LocationModelFragmentFragment[],
  selectedLocationIds: string[],
  currentLocationId: MovementStageModel['locationId'],
): DropdownItemOptions[] => {
  const portItems = locations
    .filter(location => location.isMovementLocation)
    .map(location => ({ label: location.displayName, value: location.id }));

  return sortDropdownItemsAlphabetically([...portItems, ...defaultDropdownOptions]).filter(
    location =>
      location.value && (!selectedLocationIds.includes(location.value) || location.value === currentLocationId),
  ) as DropdownItemOptions[];
};

export const getGradeItems = (grades: GradeModelFragmentFragment[]): DropdownItemOptions[] => {
  const gradeItems = grades.map(grade => ({ label: grade.name, value: grade.id }));

  return sortDropdownItemsAlphabetically([
    ...gradeItems,
    { label: MovementGradeType.Other, value: MovementGradeType.Other },
    { label: MovementGradeType.Unknown, value: MovementGradeType.Unknown },
  ]);
};
export const getEastGradeItems = (grades: GradeModelFragmentFragment[]): DropdownItemOptions[] => {
  const gradeItems = grades.map(grade => ({ label: grade.name, value: grade.name }));

  return sortDropdownItemsAlphabetically([...gradeItems]);
};
export const getLocationFromStage = (stage: { location?: { name: string }; locationType: MovementLocationType }) => {
  const location = stage.location ? stage.location.name : stage.locationType.toString();

  if (location === MovementLocationType.OutOfRegion) {
    return 'Out of region';
  }

  return location;
};

export const getGradeFromTransfer = (transfer: ProductTransferQueryResult) =>
  transfer.grade ? transfer.grade.name : transfer.gradeType.toString();

export const getUrlFromMovementInput = (input: MovementInput) => {
  const startingLocation = input.movementStages.find(stage => stage.index === 0);

  if (!startingLocation) {
    return URLS.IN_MONTH;
  }

  if (
    startingLocation.locationType === MovementLocationType.ThirdParty ||
    startingLocation.locationType === MovementLocationType.OutOfRegion
  ) {
    return `${URLS.IN_MONTH}/${MovementLocationType.ThirdParty}`;
  }

  if (!startingLocation.locationId) {
    return URLS.IN_MONTH;
  }

  return `${URLS.IN_MONTH}/${startingLocation.locationId}`;
};

export const getUniqueErrorMessages = (errors: FormikErrors<MovementFormValues>): string[] => {
  if (!errors.movementStages) {
    return [];
  }

  const errorMessages: string[] = [];

  for (const movementStage of errors.movementStages) {
    if (movementStage && movementStage.locationId) {
      errorMessages.push(movementStage.locationId);
    }

    if (movementStage && movementStage.date) {
      errorMessages.push(movementStage.date);
    }

    if (movementStage && movementStage.productTransfers) {
      for (const productTransfer of movementStage.productTransfers) {
        if (productTransfer && productTransfer.gradeId) {
          errorMessages.push(productTransfer.gradeId);
        }

        if (productTransfer && productTransfer.volume) {
          errorMessages.push(productTransfer.volume);
        }
      }
    }
  }

  return errorMessages.filter((errorMessage, index) => errorMessages.indexOf(errorMessage) === index);
};

export const getMovementStageError = (state: FormikState<MovementFormValues>, index: number) => {
  const movementStageErrors = state.errors['movementStages'];

  if (!movementStageErrors) {
    return {};
  }

  return movementStageErrors[index] || {};
};

export const parseCreateLocationNoteInput = (
  input: LocationNoteFormValues,
  locations: LocationModelFragmentFragment[],
): LocationNoteInput | null => {
  if (!input.fromDate || !input.toDate || !input.noteType || !input.note) {
    return null;
  }

  return {
    ...input,
    ...parseLocationValues(input, locations),
    fromDate: input.fromDate.format('YYYY-MM-DD'),
    toDate: input.toDate.format('YYYY-MM-DD'),
    noteType: input.noteType,
  };
};

export const parseUpdateLocationNoteInput = ({
  selectedLocationNoteId,
  formData,
}: {
  selectedLocationNoteId?: string;
  formData: LocationNoteFormValues;
}): UpdateLocationNoteInput | null => {
  if (!selectedLocationNoteId || !formData.fromDate || !formData.toDate || !formData.noteType || !formData.note) {
    return null;
  }

  return {
    id: selectedLocationNoteId,
    fromDate: formData.fromDate.format('YYYY-MM-DD'),
    toDate: formData.toDate.format('YYYY-MM-DD'),
    note: formData.note,
  };
};

export const getLocationItems = (locationModels: LocationModelFragmentFragment[]): DropdownItemOptions[] =>
  LOCATIONS.map(location => {
    const foundLocation = locationModels.find(locationModel => locationModel.displayName === location.label);

    if (foundLocation) {
      return {
        label: foundLocation.displayName,
        value: foundLocation.id,
      };
    }

    return location;
  }) as DropdownItemOptions[];

export const getPriceBasisOptions = (): DropdownItemOptions[] => {
  const optionItems = PriceBasisDropDownOptions.map(option => ({ label: option.label, value: option.value }));
  return optionItems as DropdownItemOptions[];
};

export const getIncotermOptions = (): DropdownItemOptions[] => {
  const optionItems = IncotermDropDownOptions.map(option => ({ label: option.label, value: option.value }));
  return optionItems as DropdownItemOptions[];
};
export const getDepotLoadPortOptions = (): DropdownItemOptions[] => {
  const optionItems = depotLoadPortOptions.map(option => ({ label: option.label, value: option.value }));
  return optionItems as DropdownItemOptions[];
};
export const getRefineryDischargePortOptions = (): DropdownItemOptions[] => {
  const optionItems = RefineryDischargePortOptions.map(option => ({ label: option.label, value: option.value }));
  return optionItems as DropdownItemOptions[];
};

export const getMonths = (): DropdownItemOptions[] => {
  const optionItems = nextOneYearDropDownOptions.map(option => ({ label: option.label, value: option.value }));
  return optionItems as DropdownItemOptions[];
};

export const getEastLocationItems = (locationModels: LocationModelFragmentFragment[]): DropdownItemOptions[] => {
  const getLocation = EASTLOCATIONS.filter(item => item.label !== 'Select All');
  return getLocation.map(location => {
    const foundLocation = locationModels.find(locationModel => locationModel.displayName === location.label);

    if (foundLocation) {
      return {
        label: foundLocation.displayName,
        value: foundLocation.id,
      };
    }

    return location;
  }) as DropdownItemOptions[];
};

EASTLOCATIONS.unshift(allOption);

export const getEastLocationItemsMultipleSelect = (
  locationModels: LocationModelFragmentFragment[],
): MultiDropdownItem[] =>
  EASTLOCATIONS.map(location => {
    const foundLocation = locationModels.find(locationModel => locationModel.displayName === location.label);

    if (foundLocation) {
      return {
        label: foundLocation.displayName,
        value: foundLocation.id,
      };
    }

    return location;
  }) as MultiDropdownItem[];

export const getCustomiseReportItems = (): DropdownItemOptions[] =>
  CUSTOMREPORTOPTIONS.map(option => {
    if (location) {
      return {
        label: option.label,
        value: option.value,
      };
    }
    return option;
  }) as DropdownItemOptions[];

export const getEastLocationsDepotUsers = (locationModels: LocationModelFragmentFragment[]): DropdownItemOptions[] =>
  DEPOTUSERSEASTLOCATIONS.map(location => {
    const foundLocation = locationModels.find(locationModel => locationModel.displayName === location.label);
    if (foundLocation) {
      return {
        label: foundLocation.displayName,
        value: foundLocation.id,
      };
    }
    return location;
  }) as DropdownItemOptions[];

export const getNoteTypeItems = (): DropdownItemOptions[] =>
  Object.values(LocationNoteType).map(type => ({
    label: LocationNoteTypeNames[type],
    value: type,
  }));

export const getCountryOptions = (data: CountryModel[]): MultiDropdownItem[] => {
  const options = [
    {
      value: 'All',
      label: 'Select All',
    },
  ];

  data.forEach(option => {
    options.push({ label: option.name, value: option.code });
  });

  return options as MultiDropdownItem[];
};
