import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '../../Typography';

const ListItem = styled.div`
  padding: 8px 12px;
`;

const GroupHeading = ({ children }: { children: string }) => {
  return (
    <ListItem>
      <Typography variant="body3" bold={true}>
        {children}
      </Typography>
    </ListItem>
  );
};

export { GroupHeading };
