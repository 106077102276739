import moment, { Moment } from 'moment';
import * as React from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';
import { Button } from '../Button';
import { CalendarIcon } from '../Icons/CalendarIcon';
import { TextInput } from '../TextInput';
import { SingleDatePickerStyles } from './SingleDatePickerStyles';

const Container = styled.div`
  position: relative;
`;

const CalendarContainer = styled.div<{ align: 'left' | 'right' }>`
  position: absolute;
  top: 55px;
  ${p => (p.align === 'left' ? 'left: 0;' : 'right: 0')}
  z-index: 9999;
`;

const CalendarInfo = styled.div`
  padding: 0 24px 14px;
`;

const CalendarInput = styled(TextInput)<{ disabled?: boolean }>`
  width: ${p => p.width || '120px'};
  padding-bottom: initial;

  ${p =>
    !p.disabled &&
    css`
      cursor: pointer;

      input {
        cursor: pointer;
      }
    `}
`;

const StyledButton = styled(Button).attrs({ variant: 'ghost-dark' })`
  width: 100%;
`;

const StyledButtonLightTheme = styled(Button).attrs({ variant: 'ghost-light' })`
  width: 100%;
`;
const Arrow = styled.div<{ align: 'left' | 'right'; themeStyle: 'dark' | 'light' }>`
  position: relative;
  background: ${p => p.theme.base.dark.c};
  top: 8px;

  &:after {
    bottom: 100%;
    ${p => (p.align === 'left' ? 'left: 10%;' : 'right: 5%;')}
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${p => (p.themeStyle === 'dark' ? p.theme.base.dark.c : p.theme.base.light.a)};
    border-width: 20px;
    margin-left: -20px;
  }
`;

interface DateRangePickerProps {
  error?: string;
  tabIndex?: number;
  onChange: (date: Moment) => void;
  onGoToCurrent?: () => void;
  value: Moment | null;
  themeStyle: 'dark' | 'light';
  align: 'left' | 'right';
  goToCurrentBtnText?: string;
  width?: string;
  placeholder?: string;
  name?: string;
  isOutsideRange?: (day: Moment) => boolean;
  disabled?: boolean;
  showErrorMessage?: boolean;
  closeCalendar?: boolean;
  region?: string;
  modal?: string;
  currentUserTheme?: boolean;
  enableMonthYearSelect?: boolean;
}

const Calendar: React.FunctionComponent<DateRangePickerProps> = ({
  disabled,
  tabIndex,
  onChange,
  onGoToCurrent,
  value,
  themeStyle,
  align,
  goToCurrentBtnText,
  width,
  placeholder,
  name = 'Calendar-input',
  isOutsideRange,
  error,
  showErrorMessage,
  closeCalendar,
  region,
  modal,
  currentUserTheme,
  enableMonthYearSelect,
}) => {
  const [focusedInput, setFocused] = React.useState(false);
  const [selectedDate, setDate] = React.useState<Moment | null>(value);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const minNavigationDate = region === 'east' ? moment().subtract(8, 'months') : moment().startOf('year');
  const maxNavigationDate =
    region === 'east'
      ? moment().add(9, 'months')
      : moment()
          .add(1, 'year')
          .endOf('year')
          .startOf('month');

  const selectedOrDefaultDate = selectedDate || value;

  const disablePrevNavigation =
    modal === 'excel'
      ? false
      : Boolean(selectedOrDefaultDate && selectedOrDefaultDate.isSameOrBefore(minNavigationDate));
  const disableNextNavigation =
    modal === 'excel'
      ? false
      : Boolean(selectedOrDefaultDate && selectedOrDefaultDate.isSameOrAfter(maxNavigationDate));
  const notFocusedInput = focusedInput === false;
  React.useEffect(() => {
    setDate(null);
  }, [notFocusedInput]);

  const renderMonthElement = ({
    month,
    onMonthSelect,
    onYearSelect,
  }: {
    month: Moment;
    onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    onYearSelect: (currentMonth: moment.Moment, newYearVal: string) => void;
  }) => {
    let i;
    const years = [];
    const numberOfYearsBack = 100;
    for (i = moment().year(); i >= Math.max(0, moment().year() - numberOfYearsBack); i--) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>,
      );
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <select
            style={!currentUserTheme ? { backgroundColor: theme.base.dark.d, color: 'white' } : {}}
            value={month.month()}
            onChange={e => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            style={!currentUserTheme ? { backgroundColor: theme.base.dark.d, color: 'white' } : {}}
            value={month.year()}
            onChange={e => onYearSelect(month, e.target.value)}
          >
            {years}
          </select>
        </div>
      </div>
    );
  };

  let customProps = {};

  if (enableMonthYearSelect) {
    customProps = { renderMonthElement };
  }

  return (
    <Container>
      <CalendarInput
        disabled={disabled}
        showErrorIcon={false}
        showErrorMessage={showErrorMessage ? showErrorMessage : false}
        error={error}
        tabIndex={tabIndex}
        placeholder={placeholder}
        width={width}
        themeStyle={themeStyle}
        ref={inputRef}
        data-testid={name}
        name={name}
        onChange={() => {
          return;
        }}
        active={focusedInput}
        onFocus={() => setFocused(true)}
        onKeyDown={e => e.keyCode === 9 && setFocused(false)}
        value={value ? value.format('D/M/YYYY') : ''}
        renderIcon={focused => (
          <CalendarIcon
            themeStyle={themeStyle}
            focused={!disabled && (focused || focusedInput)}
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.focus();
              }
            }}
            currentUserTheme={currentUserTheme}
            disabled={disabled}
          />
        )}
        currentUserTheme={currentUserTheme}
      />
      <SingleDatePickerStyles
        disablePrevNavigation={disablePrevNavigation}
        disableNextNavigation={disableNextNavigation}
        themeStyle={themeStyle}
      />
      <CalendarContainer align={align}>
        {focusedInput && (
          <>
            <Arrow align={align} themeStyle={themeStyle} />
            <DayPickerSingleDateController
              {...customProps}
              isOutsideRange={isOutsideRange}
              firstDayOfWeek={1}
              transitionDuration={0}
              numberOfMonths={1}
              date={value}
              focused={focusedInput}
              onNextMonthClick={month => setDate(month.startOf('month'))}
              onPrevMonthClick={month => setDate(month.startOf('month'))}
              onDateChange={date => {
                setFocused(false);
                date && onChange(date);
              }}
              onFocusChange={({ focused }) => {
                focused && setFocused(focused);
              }}
              onOutsideClick={() => (closeCalendar ? setFocused(true) : setFocused(false))}
              renderCalendarInfo={() => {
                if (!goToCurrentBtnText) {
                  return <div />;
                }

                return (
                  <CalendarInfo>
                    {currentUserTheme ? (
                      <StyledButtonLightTheme
                        onClick={() => {
                          setFocused(false);
                          onGoToCurrent && onGoToCurrent();
                        }}
                      >
                        {goToCurrentBtnText}
                      </StyledButtonLightTheme>
                    ) : (
                      <StyledButton
                        onClick={() => {
                          setFocused(false);
                          onGoToCurrent && onGoToCurrent();
                        }}
                      >
                        {goToCurrentBtnText}
                      </StyledButton>
                    )}
                  </CalendarInfo>
                );
              }}
            />
          </>
        )}
      </CalendarContainer>
    </Container>
  );
};

export { Calendar };
