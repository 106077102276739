import { NetworkStatus } from 'apollo-boost';
import React, { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../components/Loader';
import { SingleDropdown } from '../../../components/SelectDropdown/Single';
import { Table } from '../../../components/Table';
import { Typography } from '../../../components/Typography';
import { MidTermLayout } from '../../../layouts';
import { GET_ALL_CONTRACTS, ContractDealType, GetContractsQuery, GetContractsQueryVariables } from '@scout/types';
import { createContractDemandCreateLink } from '../../../utils/links';
import { createTableData } from './createTableData';
import { NoResultsPlaceholder } from './NoResultsPlaceholder';
import { TableBody } from './Table/TableBody';
import { TableHeader } from './Table/TableHeader';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { UserThemeContext } from '../../../App';

const TableContainer = styled.div`
  overflow: auto;
  position: absolute;
  top: 181px;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FilterBar = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  padding: 25px 0;
  height: 66px;
  border-bottom: 1px solid ${p => p => (p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.d)};
`;

const FilterLabel = styled(Typography).attrs(props => ({ color: props.currentUserTheme ? 'bodyLight' : 'bodyDark' }))<{
  currentUserTheme?: boolean;
}>`
  margin-left: 20px;
  margin-right: 10px;
  white-space: nowrap;
`;

const SortBar = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px;
  background: ${p => (p.currentUserTheme ? p.theme.base.light.a : p.theme.base.dark.b)};
  border-bottom: 1px solid ${p => (p.currentUserTheme ? p.theme.base.light.d : p.theme.base.dark.d)};
`;

const SortBarItem = styled.div`
  margin: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Tag = styled(Typography).attrs(props => ({ color: props.currentUserTheme ? 'bodyLight' : 'bodyDark' }))<{
  currentUserTheme?: boolean;
}>`
  margin-left: 10px;
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.c)};
  border-radius: 100px;
  padding: 3px 10px;
`;

type Props = RouteComponentProps<{ clusterId: string }>;

const Contracts: React.FunctionComponent<Props> = ({ match }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const viewFilter = searchParams.get('view');
  const yearFilter = searchParams.get('year');

  const { data, error, loading, networkStatus } = useQuery<GetContractsQuery, GetContractsQueryVariables>(
    GET_ALL_CONTRACTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clusterId: match.params.clusterId,
        filters: [viewFilter, yearFilter].filter((x): x is string => x != null),
      },
    },
  );

  const handleRowClick = useCallback(
    (dealId: string, type: ContractDealType) => {
      if (data == null || data.contracts == null) {
        // Data is loading so do not do anything
        return;
      }

      history.push(
        createContractDemandCreateLink({
          dealId,
          clusterId: match.params.clusterId,
          contractType: type,
          from: `${history.location.pathname}${history.location.search}`,
        }),
      );
    },
    [data, match.params.clusterId, history],
  );

  if ((loading && networkStatus === NetworkStatus.loading) || error || !data) {
    return <Loader error={error && 'Something went wrong'} />;
  }

  const tableData = createTableData(data.contracts.results);
  const currentYear = new Date().getFullYear();

  const selectedViewItem = data.contracts.facets.view.find(vf => vf.selected) || data.contracts.facets.view[0];
  const selectedYearItem = data.contracts.facets.years.find(year => year.selected) || data.contracts.facets.years[0];

  return (
    <MidTermLayout>
      <FilterBar data-testid="filter-bar" currentUserTheme={currentUserTheme}>
        <FilterLabel currentUserTheme={currentUserTheme}>Viewing as:</FilterLabel>
        <SingleDropdown
          isClearable={false}
          width="300px"
          name="view"
          placeholder=""
          onItemClick={item => {
            item.value &&
              history.push({
                ...history.location,
                search: new URLSearchParams({
                  view: item.value,
                  year: selectedYearItem.id,
                }).toString(),
              });
          }}
          selected={selectedViewItem.id}
          items={data.contracts.facets.view.map(vf => ({
            label: vf.name,
            value: vf.id,
          }))}
          darkTheme={currentUserTheme ? false : true}
          showValidation={false}
          showClearIndicator={false}
        />
        <FilterLabel currentUserTheme={currentUserTheme}>Year:</FilterLabel>
        <SingleDropdown
          isClearable={false}
          width="100px"
          name="year"
          placeholder=""
          onItemClick={item => {
            item.value &&
              history.push({
                ...history.location,
                search: new URLSearchParams({
                  view: selectedViewItem.id,
                  year: item.value,
                }).toString(),
              });
          }}
          selected={selectedYearItem.id}
          items={data.contracts.facets.years.map(y => ({
            label: y.name,
            value: y.id,
          }))}
          darkTheme={currentUserTheme ? false : true}
          showValidation={false}
          showClearIndicator={false}
        />
      </FilterBar>
      <SortBar currentUserTheme={currentUserTheme}>
        <SortBarItem>
          <Typography variant="body2" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
            Showing
          </Typography>
          <Tag currentUserTheme={currentUserTheme}>{data.contracts.count}</Tag>
        </SortBarItem>
      </SortBar>
      {networkStatus === NetworkStatus.setVariables ? (
        <Loader />
      ) : (
        <TableContainer>
          <Table data-testid="contracts-table">
            <TableHeader currentYear={Number(selectedYearItem.name)} currentUserTheme={currentUserTheme} />
            <TableBody
              emptyComponent={<NoResultsPlaceholder />}
              onRowClick={handleRowClick}
              rowItems={tableData.rows}
              currentUserTheme={currentUserTheme}
            />
          </Table>
        </TableContainer>
      )}
    </MidTermLayout>
  );
};

export { Contracts };
