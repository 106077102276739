import React from 'react';
import { MdEdit } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { MovementStageQueryResult, ProductTransferQueryResult } from '@scout/types';
import styled from 'styled-components';

enum RowTransferType {
  MULTI = 'M',
  LOAD = 'L',
  DELOAD = 'D',
}

const getRowType = (incomingTransfer: object[], outgoingTransfer: object[]): RowTransferType => {
  if (!incomingTransfer.length && outgoingTransfer.length) {
    return RowTransferType.LOAD;
  }
  if (incomingTransfer.length && !outgoingTransfer.length) {
    return RowTransferType.DELOAD;
  }
  return RowTransferType.MULTI;
};

const Badge = styled.span<{ rowTransferType: RowTransferType }>`
  width: 14px;
  display: inline-block;
  height: 14px;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;

  color: #000;

  background-color: ${props => {
    switch (props.rowTransferType) {
      case RowTransferType.LOAD:
        return props.theme.tertiary.chart6;
      case RowTransferType.DELOAD:
        return props.theme.tertiary.chart3;
      case RowTransferType.MULTI:
      default:
        return '#fff';
    }
  }};
`;

const EditLink = styled(({ rowTransferType, ...props }) => <Link {...props} />)<{ rowTransferType: RowTransferType }>`
  height: 14px;
  width: 14px;
  font-size: 14px;
  vertical-align: middle;

  color: ${props => {
    switch (props.rowTransferType) {
      case RowTransferType.LOAD:
        return props.theme.tertiary.chart6;
      case RowTransferType.DELOAD:
        return props.theme.tertiary.chart3;
      case RowTransferType.MULTI:
      default:
        return '#fff';
    }
  }};
`;

const Wrapper = styled.div`
  display: inline-block;

  ${EditLink} {
    display: none;
  }

  &:hover ${Badge} {
    display: none;
  }

  &:hover ${EditLink} {
    display: inline-block;
  }
`;

interface TransferTypeIconProps {
  movementStage: MovementStageQueryResult;
  incomingTransfer: ProductTransferQueryResult[];
  outgoingTransfer: ProductTransferQueryResult[];
}

const TransferTypeIcon: React.FC<TransferTypeIconProps> = ({ movementStage, incomingTransfer, outgoingTransfer }) => {
  const location = useLocation();
  const rowTransferType = getRowType(incomingTransfer, outgoingTransfer);
  return (
    <Wrapper>
      <Badge rowTransferType={rowTransferType}>{rowTransferType}</Badge>
      <EditLink
        to={{
          pathname: `/in-month/edit/${movementStage.movement.id}`,
          state: { previousPathname: location.pathname, previousSearch: location.search },
        }}
        rowTransferType={rowTransferType}
      >
        <MdEdit />
      </EditLink>
    </Wrapper>
  );
};

export { TransferTypeIcon };
