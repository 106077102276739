import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const ReportFormIcon: React.FC<{
  currentUserTheme?: boolean;
  theme?: IThemeType;
}> = withTheme(({ theme, currentUserTheme }) => (
  <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 14.25V0.25H11V10.75H0.5V31.75H35.5V14.25H25ZM14.5 3.75H21.5V28.25H14.5V3.75ZM4 14.25H11V28.25H4V14.25ZM32 28.25H25V17.75H32V28.25Z"
      fill={currentUserTheme ? '#42424A' : '#FFFFFF'}
    />
  </svg>
));
