import React from 'react';
import styled from 'styled-components';
import { fontMixin } from '../../components/Mixins';
import { FormikHandlers } from 'formik';

const Input = styled.input<{ variant: string; isText?: boolean; disabled?: boolean; fixedWidth?: boolean }>`
  ${fontMixin}
  border: ${props => (props.isText || props.disabled ? 'none' : `1px solid ${props.theme.greys.light.border}`)};
  border-radius: 2px;
  padding: 5px;
  width: ${props => (props.fixedWidth ? '60px' : 'auto')};
  height: ${props => (props.fixedWidth ? '45px' : 'auto')};
  text-align: center;
  display: inline-block;
  background: ${props => (props.isText ? 'transparent' : props.disabled && props.theme.greys.light.disabled)};
`;

interface TextFieldProps {
  value?: string;
  name: string;
  onChange: FormikHandlers['handleChange'];
  onBlur?: FormikHandlers['handleBlur'];
  disabled?: boolean;
  autoFocus?: boolean;
  isText?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextField: React.FunctionComponent<TextFieldProps> = props => {
  return (
    <Input
      variant="body3"
      type="text"
      placeholder="0"
      fixedWidth={true}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      aria-label={props.name}
      {...props}
    />
  );
};

export { TextField, Input };
