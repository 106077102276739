import {
  GetSpotDemandsForFormulaDemandsQuery,
  GetSpotDemandsForFormulaDemandsQueryVariables,
  GET_SPOT_DEMANDS_FOR_FORMULA_DEMANDS,
  UpdateSpotDemandMutation,
  UpdateSpotDemandMutationVariables,
  UPDATE_SPOT_DEMAND,
} from '@scout/types/dist';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import styled from 'styled-components';
import { ContractDemandLayout } from '../../../../layouts';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { updateStateFromDemands, updateStateFromGradeSplit, updateStateFromMonthlyValuesSplit } from '../state';
import { ContractDemandProps, ContractDemandState } from '../types';
import { DemandsStep } from './Demands';
import { GradeSplitStep } from './GradeSplit';
import { MonthlyVolumesStep } from './MonthlyVolumes';

import {
  CreateContractDemandStep,
  DemandsStepValues,
  GradeSplitStepValues,
  HeaderValues,
  MonthlyVolumesStepValues,
} from './types';

const Content = styled.div`
  max-width: 655px;
  margin: 0 auto;
  padding: 66px 0;
`;

const ContractDemandSteps: React.FC<ContractDemandProps> = props => {
  const { onSubmit } = props;

  const [step, updateStep] = useState(CreateContractDemandStep.Demands);
  const [contractDemand, updateContractDemand] = useState<ContractDemandState>(props.initialValues);
  const [contract, setContract] = useState(props.contract);
  // const[demandValues,setDemandValues]=useState<DemandsStepValues>();
  // Our submit button in the Footer is outside the Form.
  // We can get the instance of Formik used in a Step and tell it to submit
  // when we need it to. It will then call our `onSubmit` if validation
  // has passed and it's ready to move on.
  // tslint:disable-next-line: no-any
  const formRef = useRef<Formik<any>>(null);
  const [setSpotUpdate] = useMutation<UpdateSpotDemandMutation, UpdateSpotDemandMutationVariables>(UPDATE_SPOT_DEMAND);
  const { data, loading, error } = useQuery<
    GetSpotDemandsForFormulaDemandsQuery,
    GetSpotDemandsForFormulaDemandsQueryVariables
  >(GET_SPOT_DEMANDS_FOR_FORMULA_DEMANDS, {
    fetchPolicy: 'no-cache',
    variables: {
      clusterId: props.clusterId,
      customerId: props.contract.customer.id,
      year: props.contract.dealYear,
      plantId: props.contract.plant.id,
      shipmentType: props.contract.shipmentType,
    },
  });
  // tslint:disable-next-line
  const [spotDemands, setSpotDemands] = useState<any>();
  // tslint:disable-next-line
  const [spots, setSpots] = useState<any>(data && data.getSpotDemandsForFormulaDemands);
  const [radioButtonValue, setRadioButtonValue] = useState<string>('');
  // tslint:disable-next-line
  const [updateSpots, setUpdatedSpots] = useState<any>(null);
  const [disabledOffsetButton, setDisabledOffsetButton] = useState<boolean>(false);
  const handleOnBack = useCallback(() => {
    // We save the state in here for better UX. The user might be filling out
    // Step 2 with a lot of demands when they realise they want an extra one.
    // Pressing "Back" will then preserve the state they have so far so that when
    // they move on again from Step 1 they will still see the grade splits they
    // put in previously ✊
    switch (step) {
      case CreateContractDemandStep.Demands: {
        // Should never happen as button not visible
        return;
      }
      case CreateContractDemandStep.GradeSplit: {
        const values = formRef.current?.getFormikContext().values as GradeSplitStepValues;

        const newState = updateStateFromGradeSplit({
          values,
          existingState: contractDemand,
        });

        updateContractDemand(newState);
        updateStep(CreateContractDemandStep.Demands);
        return;
      }
      case CreateContractDemandStep.MonthlyVolumes: {
        const values = formRef.current?.getFormikContext().values as MonthlyVolumesStepValues;

        const newState = updateStateFromMonthlyValuesSplit({
          values,
          existingState: contractDemand,
        });

        updateContractDemand(newState);
        updateStep(CreateContractDemandStep.GradeSplit);
        return;
      }
    }
  }, [contractDemand, formRef, step]);

  const handleOnNext = useCallback(() => {
    if (formRef.current == null || formRef.current.getFormikContext().isSubmitting) {
      return;
    }

    formRef.current.submitForm();
  }, [formRef]);

  const handleHeaderSubmit = useCallback(
    (values: HeaderValues) => {
      updateContractDemand({
        ...contractDemand,
        shipmentType: values.shipmentType,
      });
      updateStep(CreateContractDemandStep.Demands);
    },
    [contractDemand],
  );

  const handleDemandsSubmit = useCallback(
    (values: DemandsStepValues) => {
      const newState = updateStateFromDemands({
        values,
        demandLocation: props.demandLocation,
        existingState: contractDemand,
        penPlants: props.penPlants,
        premiumPlants: props.premiumPlants,
        regions: props.regions,
        shipTos: props.contract.customer.shippingLocations,
      });
      updateContractDemand(newState);
      updateStep(CreateContractDemandStep.GradeSplit);
    },
    [
      contractDemand,
      props.contract.customer.shippingLocations,
      props.demandLocation,
      props.penPlants,
      props.premiumPlants,
      props.regions,
    ],
  );

  const handleGradeSplitSubmit = useCallback(
    (values: GradeSplitStepValues) => {
      const newState = updateStateFromGradeSplit({
        values,
        existingState: contractDemand,
      });
      updateContractDemand(newState);
      updateStep(CreateContractDemandStep.MonthlyVolumes);
    },
    [contractDemand],
  );

  const handleMonthlyVolumesSubmit = useCallback(
    async (values: MonthlyVolumesStepValues) => {
      const newState = updateStateFromMonthlyValuesSplit({
        values,
        existingState: contractDemand,
      });
      updateContractDemand(newState);
      updateSpot();
      onSubmit(newState);
    },
    [contractDemand, onSubmit, updateSpots],
  );

  const updateSpot = async () => {
    if (updateSpots !== null) {
      try {
        await setSpotUpdate({
          variables: {
            input: updateSpots,
          },
        });
      } catch (error) {
        console.error('Failed mutation at ', error);
      }
    }
  };
  useEffect(() => {
    if (data && data.getSpotDemandsForFormulaDemands) {
      // tslint:disable-next-line
      const spotDemandValues = data.getSpotDemandsForFormulaDemands.map((spotDemand: any) => {
        const forecasts = spotDemand.forecasts.flat();
        // tslint:disable-next-line
        const volumes = {} as any;
        // tslint:disable-next-line
        forecasts.sort((a: any, b: any) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA.getTime() - dateB.getTime();
        });
        let totalVolume = 0;
        // tslint:disable-next-line
        forecasts.forEach((forecast: any) => {
          const date = new Date(forecast.date);
          const month = date
            .toLocaleString('default', { month: 'short' })
            .slice(0, 3)
            .toLowerCase();
          totalVolume += forecast.volume;
          volumes[month] = forecast.volume ? forecast.volume.toString() : '0';
        });
        const obj = {
          isEditable: false,
          id: spotDemand.id,
          plantName: spotDemand.plants[0].shortName,
          location: spotDemand.region?.name || spotDemand.shipTo?.name || '-',
          targetTotal: totalVolume,
          volumes,
        };
        return obj;
      });

      setSpotDemands(spotDemandValues);
      setSpots(data?.getSpotDemandsForFormulaDemands);
    }
  }, [data]);

  // tslint:disable-next-line
  const updateVolumes = (obj1: any, obj2: any, spotDemand: any) => {
    for (const month in obj2) {
      if (obj2.hasOwnProperty(month)) {
        const obj1Volume = parseInt(obj1[month], 10);
        const obj2Volume = parseInt(obj2[month], 10);
        if (obj1Volume > obj2Volume) {
          obj2[month] = '0';
        } else if (obj1Volume === obj2Volume) {
          obj2[month] = '0';
        } else {
          obj2[month] = Math.abs(obj1Volume - obj2Volume).toString();
        }
      }
    }
    // tslint:disable-next-line
    const spotDemandObj = spotDemands.find((spot: any) => spot.id === spotDemand.id);
    // tslint:disable-next-line
    const index = spotDemands.findIndex((obj: any) => obj.id === spotDemandObj.id);
    if (index !== -1) {
      const updatedSpotDemands = [...spotDemands];
      const updatedSpotDemandObj = {
        ...spotDemands[index],
        volumes: spotDemandObj.volumes,
      };
      updatedSpotDemands[index] = updatedSpotDemandObj;
      setSpotDemands(updatedSpotDemands);
    }
    // tslint:disable-next-line
    const spotUpdated = spots.find((obj: any) => obj.id === spotDemand.id);
    const {
      id,
      shipmentType,
      customerId,
      forecasts,
      grades,
      plants,
      plantSelectionNote,
      plantSelectionReasonContractual,
      plantSelectionReasonOperational,
      plantSelectionReasonTechnical,
      regionId,
    } = spotUpdated;
    // tslint:disable-next-line
    forecasts.forEach((forecast: any) => {
      const date = new Date(forecast.date);
      const month = date
        .toLocaleString('default', { month: 'short' })
        .slice(0, 3)
        .toLowerCase();
      forecast.volume = parseInt(obj2[month], 10);
    });
    // tslint:disable-next-line
    const updatedGrades = grades.map((grade: any) => ({
      gradeId: grade.id,
      type: grade.type,
      percentage: grade.percentage,
    }));
    // tslint:disable-next-line
    const updatedPlants = plants.map((plant: any) => ({
      id: plant.id,
      isPrimary: plant.isPrimary,
      plantId: plant.plantId,
      isPremium: plant.isPremium,
    }));
    // tslint:disable-next-line
    const updatedForecasts = forecasts.map((forecast: any) => ({
      id: forecast.id,
      date: forecast.date,
      discount: forecast.discount,
      incrementalVolume: forecast.incrementalVolume,
      price: forecast.price,
      volume: forecast.volume,
    }));

    const convertedObj = {
      id,
      shipmentType,
      clusterId: props.clusterId,
      shipToId: undefined,
      customerId,
      forecasts: updatedForecasts,
      grades: updatedGrades,
      plants: updatedPlants,
      regionId,
      plantSelectionNote,
      plantSelectionReasonContractual,
      plantSelectionReasonOperational,
      plantSelectionReasonTechnical,
    };
    setUpdatedSpots(convertedObj);
  };

  const handleOffset = () => {
    if (radioButtonValue.length > 0) {
      const values = formRef.current?.getFormikContext().values as MonthlyVolumesStepValues;
      const rec = values.rows.find(row => row.plantName === spotDemands[0].plantName);
      // tslint:disable-next-line
      const spot = spotDemands.find((spot: any) => spot.id === radioButtonValue);
      rec && updateVolumes(rec.volumes, spot.volumes, spot);
    }
  };
  const handleRowSelection = (value: string) => {
    setRadioButtonValue(value);
    setDisabledOffsetButton(true);
  };

  return (
    <ContractDemandLayout>
      <Header
        ownerName={props.ownerName}
        creatorName={props.creatorName}
        updaterName={props.updaterName}
        auditDate={props.auditDate}
        contractType={props.contract.type}
        customerCode={props.contract.customer.code}
        customerName={props.contract.customer.name}
        customerSalesOrganisation={props.contract.customer.salesOrganisation}
        dealId={props.contract.dealId}
        onCancel={props.onCancel}
        shipmentType={contractDemand.shipmentType}
        stascoId={props.contract.stascoId}
        onSubmit={handleHeaderSubmit}
      />
      {step === CreateContractDemandStep.Demands ? (
        <Content>
          <DemandsStep
            contract={contract}
            demandLocation={props.demandLocation}
            formRef={formRef}
            onSubmit={handleDemandsSubmit}
            penPlants={props.penPlants}
            premiumPlants={props.premiumPlants}
            regions={props.regions}
            shipmentType={contractDemand.shipmentType}
            shipTos={props.contract.customer.shippingLocations}
            state={contractDemand}
          />
        </Content>
      ) : step === CreateContractDemandStep.GradeSplit ? (
        <Content>
          <GradeSplitStep
            demandLocation={props.demandLocation}
            formRef={formRef}
            onSubmit={handleGradeSplitSubmit}
            penPlants={props.penPlants}
            premiumPlants={props.premiumPlants}
            state={contractDemand}
          />
        </Content>
      ) : (
        <MonthlyVolumesStep
          contract={contract}
          clusterCurrency={props.currency}
          formRef={formRef}
          onSubmit={handleMonthlyVolumesSubmit}
          showSellingPrice={true}
          state={contractDemand}
          spotDemands={spotDemands}
          handleOffset={handleOffset}
          onSelectRow={handleRowSelection}
          setSelectedRow={radioButtonValue}
          disabled={disabledOffsetButton}
        />
      )}
      <Footer
        nextButtonText={step === CreateContractDemandStep.MonthlyVolumes ? 'Save & close' : 'Next'}
        onBack={step !== CreateContractDemandStep.Demands ? handleOnBack : undefined}
        onNext={handleOnNext}
      />
    </ContractDemandLayout>
  );
};

export { ContractDemandSteps };
