import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import { tankGroupNames, formatKt, TankGroupLabels, PlantIds } from './helpers';

const GroupHeaderContainer = styled.div<{ currentUserTheme?: boolean }>`
  background: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.a)};
  border-radius: 2px;
  height: 58px;
  margin-bottom: 15px;
  justify-content: center;
`;

const PullRight = styled.div`
  margin-left: auto;
`;

const LabelValue: React.FC<{ label: string; value: string; currentUserTheme?: boolean }> = ({
  label,
  value,
  currentUserTheme,
  ...rest
}) => (
  <Col>
    <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="labels1">
      {label}
    </Typography>
    <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="labels1" {...rest}>
      {value}
    </Typography>
  </Col>
);

const Line = styled.div`
  height: 12px;
  border-right: 1px solid ${p => p.theme.greys.dark.border};
  padding-left: 12px;
  margin-top: auto;
  margin-right: 12px;
  margin-bottom: 4px;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 14px;
`;

const getGroupName = (plantId: PlantIds, text: TankGroupLabels) => {
  if (plantId === PlantIds.ERL && text === TankGroupLabels.Other) {
    return TankGroupLabels.NonBitumen;
  }
  if (plantId === PlantIds.BUKOM && text === TankGroupLabels.Other) {
    return TankGroupLabels.Bitumen;
  }

  return text;
};

export const GroupHeader: React.FC<{
  plantId: PlantIds;
  text: string;
  totalStock: number;
  totalUllage: number;
  currentUserTheme?: boolean;
}> = ({ plantId, text, totalStock, totalUllage, currentUserTheme }) => (
  <GroupHeaderContainer currentUserTheme={currentUserTheme}>
    <Row>
      <Inner>
        <Typography
          data-testid="group-name"
          color={currentUserTheme ? 'bodyLight' : 'bodyDark'}
          variant="body4"
          bold={true}
        >
          {getGroupName(plantId, tankGroupNames[text])}
        </Typography>
        <PullRight>
          <Row>
            <LabelValue
              label="Stock"
              value={formatKt(totalStock)}
              data-testid="group-total-stock"
              currentUserTheme={currentUserTheme}
            />
            <Line />
            <LabelValue
              label="Ullage"
              value={formatKt(totalUllage)}
              data-testid="group-total-ullage"
              currentUserTheme={currentUserTheme}
            />
          </Row>
        </PullRight>
      </Inner>
    </Row>
  </GroupHeaderContainer>
);
