import React from 'react';
import styled from 'styled-components';
import { TableHead, TableRow, TableHeadColumn, TableContainer, TableBody } from './TableElements';

const Container = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  font-size: 12px;
  margin-bottom: 3.9%;
`;

const CenteredTextField = styled.span`
  min-width: 85px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
`;

export const TableWrapper: React.FC<{ isThirdParty?: boolean }> = ({ isThirdParty, children }) => (
  <Container>
    <TableContainer>
      <TableHead>
        <TableRow>
          <TableHeadColumn width="100px" stickyLeft={0}>
            {isThirdParty ? '3rd party ETA' : 'Planned ETA'}
          </TableHeadColumn>
          <TableHeadColumn stickyLeft={100} width="30px" />
          <TableHeadColumn width="150px">Incoming</TableHeadColumn>
          <TableHeadColumn width="150px">Outgoing</TableHeadColumn>
          <TableHeadColumn>Description</TableHeadColumn>
          <TableHeadColumn width="100px">
            <CenteredTextField>Transport</CenteredTextField>
          </TableHeadColumn>
          <TableHeadColumn width="100px">
            <CenteredTextField>Current ETA</CenteredTextField>
          </TableHeadColumn>
          <TableHeadColumn>Notes</TableHeadColumn>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </TableContainer>
  </Container>
);
