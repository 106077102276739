import { Form, Formik, FormikActions, isInteger } from 'formik';
import React, { Ref, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { DropdownItemOptions } from '../../../../../components/SelectDropdown/Single';
import { useToast } from '../../../../../components/Toast/Toast';
import { Contract, DemandLocation, Plant, ShipmentType } from '@scout/types';
import { ContractDemandState } from '../../types';
import { Separator } from '../Separator';
import { CustomerItem, DemandsStepValues, DemandsStepVolumeRow, PlantItem, RegionItem } from '../types';
import { DemandsStepPlants } from './Plants';
import { TitleSection } from './TitleSection';

interface Props {
  contract: Pick<Contract, 'hasPremiumPlant' | 'totalVolume'> & {
    plant: Pick<Plant, 'id' | 'shortName'>;
  };
  demandLocation: DemandLocation;
  formRef: Ref<Formik<DemandsStepValues>>;
  onSubmit: (values: DemandsStepValues) => void;
  penPlants: Array<Pick<Plant, 'id' | 'shortName'>>;
  premiumPlants: Array<Pick<Plant, 'id' | 'shortName'>>;
  regions: RegionItem[];
  shipmentType: ShipmentType;
  shipTos: CustomerItem[];
  state: ContractDemandState;
}

const DemandsStepPlantsSpacer = styled.div`
  margin-bottom: 20px;
`;

/**
 * Will test that the `location` of a row is not the same as other
 * rows with the same `plant`.
 *
 * Please note that is only necessary when in "Pick up" mode as a
 * `location` is not required then but it's possible for a user to try
 * and create two demands for the same `plant` and _no_ `location`.
 */
function uniqueLocationTest(this: yup.TestContext, value: DemandsStepVolumeRow) {
  const valueIndex = (this.parent as DemandsStepVolumeRow[]).indexOf(value);

  const isInvalid = (this.parent as DemandsStepVolumeRow[]).some(
    (row, index) =>
      // This check ensures that the first row to have the plant->location
      // combination is the valid one and the ones after it are invalid
      valueIndex > index && row.plant === value.plant && row.location === value.location,
  );

  return isInvalid
    ? this.createError({
        message: `${this.path} has an already selected location for this plant`,
        path: `${this.path}.location`,
      })
    : true;
}

const createValidationSchema = (shipmentType: ShipmentType): yup.ObjectSchema => {
  let location = yup.string().nullable();

  if (shipmentType === ShipmentType.Delivery) {
    location = location.required();
  }

  const volumeRowSchema = yup
    .object()
    .shape({
      location,
      plant: yup
        .string()
        .required()
        .nullable(),
      volume: yup
        .number()
        .required()
        .nullable()
        .positive(),
    })
    .test({
      test: uniqueLocationTest,
    });

  return yup.object().shape({
    penRows: yup.array().of(volumeRowSchema),
    premiumRows: yup.array().of(volumeRowSchema),
  });
};

const createValues = (state: Props['state']): DemandsStepValues => {
  const penRows: DemandsStepVolumeRow[] = [];
  const premiumRows: DemandsStepVolumeRow[] = [];

  state.demands.forEach(demand => {
    const row: DemandsStepVolumeRow = {
      id: demand.id,
      isFixed: demand.isFixed,
      location: state.demandLocation === DemandLocation.Region ? demand.region?.id : demand.shipTo?.id,
      plant: demand.plant?.id,
      volume: demand.totalVolume > 0 ? demand.totalVolume.toString() : '',
    };

    demand.isPremium ? premiumRows.push(row) : penRows.push(row);
  });

  return {
    penRows,
    premiumRows,
  };
};

const sumDemandRows = (demandRows: DemandsStepVolumeRow[]): number =>
  demandRows.reduce<number>((prev, curr) => {
    if (curr.volume == null || !isInteger(curr.volume)) {
      return prev;
    }

    const value = parseInt(curr.volume, 10);

    return isNaN(value) ? prev : prev + value;
  }, 0);

interface SumTotalsResult {
  penTotal: number;
  premiumTotal: number;
  total: number;
}

const sumTotals = (values: DemandsStepValues): SumTotalsResult => {
  const penTotal = sumDemandRows(values.penRows);
  const premiumTotal = sumDemandRows(values.premiumRows);

  return {
    penTotal,
    premiumTotal,
    total: penTotal + premiumTotal,
  };
};

const DemandsStep: React.FC<Props> = ({
  contract,
  demandLocation,
  formRef,
  onSubmit,
  penPlants,
  premiumPlants,
  regions,
  shipmentType,
  shipTos,
  state,
}) => {
  const { push } = useToast();
  const handleSubmit = useCallback(
    (values, formikActions: FormikActions<DemandsStepValues>) => {
      const { total } = sumTotals(values);

      if (total !== contract.totalVolume) {
        push({
          size: 'LARGE',
          text: 'Your Grand Total needs to equal your Contract volume before continuing',
          type: 'ERROR',
        });
        formikActions.setSubmitting(false);
      } else {
        onSubmit(values);
      }
    },
    [contract.totalVolume, push, onSubmit],
  );

  const [locationItems, penPlantItems, premiumPlantItems] = useMemo(() => {
    const locationItems: DropdownItemOptions[] =
      demandLocation === DemandLocation.Region
        ? regions.map<DropdownItemOptions>(region => ({
            label: region.name,
            value: region.id,
          }))
        : shipTos.map<DropdownItemOptions>(shipTo => ({
            label: shipTo.name,
            value: shipTo.id,
            tag: shipTo.salesOrganisation,
            id: shipTo.code,
          }));

    return [
      locationItems,
      penPlants.map<PlantItem>(({ id, shortName }) => ({ id, name: shortName })),
      premiumPlants.map<PlantItem>(({ id, shortName }) => ({ id, name: shortName })),
    ];
  }, [demandLocation, penPlants, premiumPlants, regions, shipTos]);

  return (
    <Formik
      initialValues={createValues(state)}
      onSubmit={handleSubmit}
      ref={formRef}
      render={props => {
        const { penTotal, premiumTotal, total } = sumTotals(props.values);
        return (
          <Form noValidate={true}>
            <TitleSection targetTotal={contract.totalVolume} currentTotal={total} />
            <Separator />
            <DemandsStepPlants
              addNewButtonText="Add a pen demand"
              canDeleteFirstRow={contract.hasPremiumPlant}
              emptyMessage="No pen demands added."
              maxTotal={contract.totalVolume}
              locations={locationItems}
              locationsType={demandLocation}
              name="penRows"
              plantHeaderText="Pen plant(s)"
              plants={penPlantItems}
              rows={props.values.penRows}
              shipmentType={shipmentType}
              title="Pen volume"
              total={penTotal}
            />
            <DemandsStepPlantsSpacer />
            <DemandsStepPlants
              addNewButtonText="Add a premium demand"
              canDeleteFirstRow={!contract.hasPremiumPlant}
              emptyMessage="No premium demands added."
              maxTotal={contract.totalVolume}
              locations={locationItems}
              locationsType={demandLocation}
              name="premiumRows"
              plantHeaderText="Premium plant(s)"
              plants={premiumPlantItems}
              rows={props.values.premiumRows}
              shipmentType={shipmentType}
              title="Premium volume"
              total={premiumTotal}
            />
          </Form>
        );
      }}
      validationSchema={createValidationSchema(shipmentType)}
    />
  );
};

export { DemandsStep };
