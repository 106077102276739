import * as React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Typography } from '../../../../../../components/Typography';
import { useHover } from '../../../../../../hooks/useHover';

const ChildrenContainer = styled.div<{ active: boolean }>`
  ${p => p.active && 'cursor: pointer;'}
`;

const TooltipOverlayCont = styled.div<{ styles?: CSSObject }>`
  position: absolute;
  background-color: white;
  color: black;
  overflow-wrap: break-word;
  z-index: 99999;
  ${p => p.styles}
`;

export interface TooltipProps {
  children: React.ReactNode;
  body: string | React.ReactNode;
  active?: boolean;
  styles?: CSSObject;
}

const EastTooltip: React.FC<TooltipProps> = ({ children, body, active = true, styles }) => {
  const [hovered, hoverEvents] = useHover();
  const tooltipEventprops = active ? hoverEvents : {};

  return (
    <div>
      <ChildrenContainer {...tooltipEventprops} active={active}>
        {children}
      </ChildrenContainer>
      {hovered && (
        <TooltipOverlayCont styles={styles}>
          {typeof body === 'string' ? <Typography variant="body3">{body}</Typography> : body}
        </TooltipOverlayCont>
      )}
    </div>
  );
};

export { EastTooltip };
