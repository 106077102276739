import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import { Typography } from '../../../../components/Typography';

const Spacer = styled.div<{ size?: number }>`
  height: ${props => 20 * (props.size || 1)}px;
`;

const Heading = styled(Typography)`
  padding: 0 18px;
`;

export interface Props {
  showModal: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

const CancelMovementModal: React.FC<Props> = props => {
  return (
    <Modal
      data-testid="cancel-movement-modal"
      onConfirm={props.onConfirm}
      isOpen={props.showModal}
      confirmText="Yes"
      confirmBtnVariant="primary"
      dismissText="No"
      onDismiss={props.onDismiss}
    >
      <Spacer />
      <Heading variant="heading5" color="bodyDark" align="center" bold={true}>
        Are you sure you want to change movement status to ‘cancelled’?
      </Heading>
      <br />
      <Typography variant="body3" color="bodyDark" align="center">
        This will mark this movement as cancelled and return you to the planning page.
      </Typography>
      <Spacer />
    </Modal>
  );
};

export { CancelMovementModal };
