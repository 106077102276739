import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { Typography } from '../../../../components/Typography';
import { config } from '../../../../config';
import Switch from 'react-switch';
import { UpdateUserThemeMutation, UpdateUserThemeMutationVariables, UPDATEUSERTHEME } from '@scout/types';
import { useMutation } from 'react-apollo';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { SERVICE_NOW_URL, URLS } from '../../../../constants';
import { useToast } from '../../../../components/Toast/Toast';

const Spacer = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${p => p.size || '17px'};
`;
const MoreActionsContainer = styled.div<{ currentUserTheme?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${p => (p.currentUserTheme ? p.theme.secondary.light.c : p.theme.base.dark.a)};
  padding: 37px;
  height: 66px;
  margin: -34px;
  font-size: 18px;
`;
const Line = styled.div`
  margin-top: 5px;
  background-color: ${p => p.theme.primary.b};
  height: 3px;
  width: 35px;
`;

const MoreSettingsTextContainer = styled.div`
  font-size: 16px;
  color: ${p => p.theme.greys.dark.border};
  margin-left: -18px;
`;

const Border = styled.hr<{ currentUserTheme?: boolean }>`
  border: ${props => (props.currentUserTheme === true ? '1px solid #EBEBEB' : '1px solid #303641')};
  margin-left: -17px;
  width: 325px;
`;

const MoreActionsTextContainer = styled.div`
  margin-left: 25px;
`;

const LineContainer = styled.div`
  margin-left: 28px;
`;

const SimulationIcon = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 16px;
justify-content: center;
margin-right:10px; !important;
height:17px;
padding:4px;
background-image:url(${config.PUBLIC_URL}/images/content_copy_white.svg)`;

const SimulationIconLightTheme = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 16px;
justify-content: center;
margin-right:10px; !important;
height:17px;
padding:4px;
background-image:url(${config.PUBLIC_URL}/images/content_copy_black.svg)`;

const ThemeContainer = styled.div`
  display: flex;
  margin-top: 23px;
  margin-left: -16px;
`;

const ToggleSwitchContainer = styled.div`
  margin-left: 269px;
  margin-top: -18px;
`;

const SimulationButton = styled(Button).attrs({ icon: 'MdAdd', variant: 'secondary' })`
  margin-left: 105px;
  margin-top: -10px;
`;

const ServiceNowIcon = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 25px;
justify-content: center;
margin-right:10px; !important;
height:23px;
padding:4px;
margin-left: 282px;
margin-top: -25px;
background-image:url(${config.PUBLIC_URL}/images/support_agent.svg)`;

const ServiceNowIconLightTheme = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 25px;
justify-content: center;
margin-right:10px; !important;
height:23px;
padding:4px;
margin-left: 282px;
margin-top: -25px;
background-image:url(${config.PUBLIC_URL}/images/support_agent_black.svg)`;

const UserManualIcon = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 18px;
justify-content: center;
margin-right:10px; !important;
height:21px;
padding:4px;
margin-left: 282px;
margin-top: -25px;
background-image:url(${config.PUBLIC_URL}/images/menu_book.svg)`;

const UserManualIconLightTheme = styled(Button).attrs({ variant: 'ghost-dark' })`
border:none;
width: 18px;
justify-content: center;
margin-right:10px; !important;
height:21px;
padding:4px;
margin-left: 282px;
margin-top: -25px;
background-image:url(${config.PUBLIC_URL}/images/menu_book_black.svg)`;

const LinkToServiceNow = styled.a`
  justify-content: center;
`;

export interface EastMoreActionsModalProps {
  toggleTheme: () => void;
  currentUserTheme?: boolean;
}
const EastMoreActionsModal: React.FC<EastMoreActionsModalProps> = ({ toggleTheme, currentUserTheme }) => {
  const [updateUserTheme, setUpdateUserTheme] = useMutation<UpdateUserThemeMutation, UpdateUserThemeMutationVariables>(
    UPDATEUSERTHEME,
  );
  const data = useCurrentUser();
  const [checked, setChecked] = useState(currentUserTheme ? true : false);
  const USER_MANUAL_REPORT_URL = `/api/reports/userManual`;

  const { push } = useToast();
  const handleToggleButton = async () => {
    const userEmail = data.emailAddress;
    try {
      await updateUserTheme({
        variables: {
          input: userEmail,
        },
      });
      toggleTheme();
      setChecked(!checked);
    } catch (error) {
      console.error('Error in updateUserTheme');
    }
  };

  const getUserManual = async () => {
    try {
      const response = await fetch(USER_MANUAL_REPORT_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const fileBuffer = await response.arrayBuffer();

      const blob = new Blob([fileBuffer], {
        type: 'application/pdf',
      });
      saveAs(blob, `user_manual.pdf`);
    } catch (err) {
      console.error(err);
      push({ type: 'ERROR', text: 'Error while trying to download user manual' });
    }
  };

  const simulationWindow = async () => {
    window.open(URLS.SIMULATION, '_blank');
  };
  return (
    <>
      <MoreActionsContainer currentUserTheme={currentUserTheme}>
        <Typography variant="heading6" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
          <MoreActionsTextContainer> {'More Actions'}</MoreActionsTextContainer>
        </Typography>
      </MoreActionsContainer>
      <Spacer />
      <LineContainer>
        <Line />
      </LineContainer>
      <Spacer size="34px" />
      <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
        <MoreSettingsTextContainer> {'Theme Settings'}</MoreSettingsTextContainer>
      </Typography>
      <ThemeContainer>
        <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
          {'Change Background Color'}
        </Typography>
      </ThemeContainer>
      <ToggleSwitchContainer>
        <Switch
          checked={checked}
          onChange={handleToggleButton}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={17}
          uncheckedIcon={false}
          checkedIcon={false}
          height={13}
          width={30}
          className="react-switch"
        />
      </ToggleSwitchContainer>

      <Spacer />
      <Border currentUserTheme={currentUserTheme} />
      <Spacer />
      <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
        <MoreSettingsTextContainer>{'Actions'}</MoreSettingsTextContainer>
      </Typography>
      <Spacer />
      <ThemeContainer>
        {currentUserTheme ? <SimulationIconLightTheme /> : <SimulationIcon />}
        <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
          {'Simulation'}
        </Typography>
        <SimulationButton variant="secondary" icon="MdAdd" onClick={simulationWindow}>
          Create
        </SimulationButton>
      </ThemeContainer>
      <Spacer size="34px" />
      <Border currentUserTheme={currentUserTheme} />
      <Spacer />

      <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
        <MoreSettingsTextContainer>{'IT Support'}</MoreSettingsTextContainer>
      </Typography>
      <ThemeContainer>
        <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
          {'Service Now'}
        </Typography>
      </ThemeContainer>
      <LinkToServiceNow href={SERVICE_NOW_URL} target="_blank">
        {currentUserTheme ? <ServiceNowIconLightTheme /> : <ServiceNowIcon />}
      </LinkToServiceNow>
      <Spacer size="34px" />
      <Border currentUserTheme={currentUserTheme} />
      <Spacer />
      <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
        <MoreSettingsTextContainer>{'Documents'}</MoreSettingsTextContainer>
      </Typography>
      <ThemeContainer>
        <Typography variant="heading7" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={false}>
          {'User Manual'}
        </Typography>
      </ThemeContainer>
      {currentUserTheme ? (
        <UserManualIconLightTheme onClick={getUserManual} />
      ) : (
        <UserManualIcon onClick={getUserManual} />
      )}
    </>
  );
};
export { EastMoreActionsModal };
