import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const VesselFlowAnalysisIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="62" height="57" viewBox="0 0 62 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: 'luminosity' }}>
      <path
        d="M24.798 0L23.6101 4.00928L20.3433 12.3248H42.3201L38.9048 4.00928L37.5683 0"
        fill="url(#paint0_linear_6614_42853)"
      />
      <path
        d="M51.5262 24.0549L57.9113 12.7695H4.75122L11.4334 24.0549H51.5262Z"
        fill="url(#paint1_linear_6614_42853)"
      />
      <path
        d="M32.371 16.78C32.371 16.78 31.3315 16.3345 30.2921 16.78C20.6401 20.7893 0.742188 24.9471 0.742188 24.9471C11.5821 32.3717 12.9185 55.8334 12.9185 55.8334C17.3733 56.7244 31.3315 56.4274 31.3315 56.4274L32.371 16.78Z"
        fill="url(#paint2_linear_6614_42853)"
      />
      <path
        d="M32.3706 16.78C31.3312 16.3345 30.2917 16.78 30.2917 16.78L31.3312 56.4274C31.3312 56.4274 45.2894 56.7244 49.7442 55.8334C49.7442 55.8334 50.9321 32.2232 61.772 24.9471C61.772 24.7986 43.0621 21.0863 32.3706 16.78Z"
        fill="url(#paint3_linear_6614_42853)"
      />
      <path
        d="M23.9071 28.5108C24.0556 27.7683 23.3132 26.8774 21.9768 26.4319C20.7888 26.1349 19.6009 26.5804 19.3039 27.3228C19.1554 28.0653 19.8979 28.9563 21.2343 29.4017C22.5707 29.6987 23.7587 29.2532 23.9071 28.5108Z"
        fill="white"
      />
      <path
        d="M40.0931 26.4307C38.9051 26.7277 38.0142 27.6186 38.1627 28.5096C38.3112 29.252 39.4991 29.6975 40.8355 29.4005C42.0235 29.1036 42.9144 28.2126 42.7659 27.3217C42.6174 26.4307 41.4295 26.1337 40.0931 26.4307Z"
        fill="white"
      />
      <path
        d="M4.75157 12.9188H57.9117L58.2087 12.3248H42.32H31.6286V0H31.1831V4.60325L24.0555 12.3248H20.4917H4.45459L4.75157 12.9188ZM31.0347 12.3248H30.1437L31.0347 11.2854V12.3248ZM31.0347 10.9884L29.8467 12.1763H28.6588L31.0347 9.65198V10.9884V10.9884ZM31.0347 9.50349L28.3618 12.3248H27.1739L31.0347 8.16706V9.50349V9.50349ZM31.0347 7.87007L26.8769 12.3248H25.6889L31.0347 6.53365V7.87007V7.87007ZM31.0347 4.90023V6.23666L25.392 12.3248H24.204L31.0347 4.90023Z"
        fill="white"
      />
      <path
        d="M52.4177 21.9762C44.8446 20.1943 34.0047 16.9274 32.8167 15.888C31.6288 14.8485 31.0348 14.8485 29.8469 15.888C28.659 16.9274 17.6705 20.3427 10.2459 21.9762C4.60325 23.3126 0 24.2035 0 24.2035C0.296984 24.352 1.03944 24.7975 1.03944 24.7975C1.7819 24.649 15.5917 22.2731 30.1439 17.0759C30.1439 17.2244 30.7378 41.1316 30.7378 41.1316L16.7796 56.1293H17.2251L30.7378 41.5771V44.1015L19.4524 56.2778H19.8979L30.5894 44.8439V47.3683L22.2738 56.2778H22.7193L30.5894 47.8138V50.3381L25.0951 56.2778H25.2436H25.5406L30.4409 50.9321V53.4564L27.9165 56.1293H28.065H28.362L30.4409 53.9019V56.1293H30.5894H31.1833H31.3318V53.9019V53.308V50.7836V50.1896V47.6653V47.0713V44.5469V43.953V41.4286V40.8346C31.3318 40.8346 31.9258 16.9274 31.9258 16.7789C46.3295 21.9762 60.2877 24.352 61.0302 24.5005C61.0302 24.5005 61.6242 24.055 61.9212 23.9065C62.6636 24.2035 58.0604 23.3126 52.4177 21.9762Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6614_42853"
        x1="25.2557"
        y1="-0.330692"
        x2="34.9847"
        y2="16.5203"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="6.18796e-07" stopColor="#00BBE7" />
        <stop offset="1" stopColor="#00A9E6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6614_42853"
        x1="31.3365"
        y1="24.1186"
        x2="31.3365"
        y2="12.7995"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="6.18796e-07" stopColor="#00BBE7" />
        <stop offset="0.5021" stopColor="#00B8E7" />
        <stop offset="0.8628" stopColor="#00AFE6" />
        <stop offset="1" stopColor="#00A9E6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6614_42853"
        x1="16.6527"
        y1="16.5463"
        x2="16.6527"
        y2="56.4125"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="6.18796e-07" stopColor="#00BBE7" />
        <stop offset="1" stopColor="#00A9E6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6614_42853"
        x1="61.7864"
        y1="36.4813"
        x2="30.3782"
        y2="36.4813"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="6.18796e-07" stopColor="#00BBE7" />
        <stop offset="0.4881" stopColor="#00AFE6" />
        <stop offset="1" stopColor="#00A9E6" />
      </linearGradient>
    </defs>
  </svg>
));
