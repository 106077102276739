import { ClusterModel, PermissionModel } from '@scout/types';

export type Cluster = Pick<ClusterModel, 'id' | 'name' | 'isUKIrelandCluster' | 'currency'>;

export enum ClusterNames {
  DACH = 'DACH/CEE',
  UK = 'UK/Ireland',
  Benefrux = 'Benefrux',
  East = 'East',
}

export interface VolumePricingItem {
  id?: string;
  readonly: boolean;
  volume: string;
  price: string;
  incrementalVolume: string;
  discount: string;
  date: string;
}

export type Permission = keyof Omit<PermissionModel, 'id' | '__typename' | 'roles'>;
