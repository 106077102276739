import React from 'react';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { LocationsQuery } from '@scout/types';
import { getLocationItems } from '../utils';
import { useSchedulerLocationId } from './hooks';

export const LocationDropdown: React.FC<{ locations: LocationsQuery['locations'] }> = ({ locations }) => {
  const [locationId, setLocationId] = useSchedulerLocationId();

  return (
    <SingleDropdown
      name="location"
      width="150px"
      placeholder="Location"
      selected={locationId || null}
      onItemClick={({ value }) => {
        if (value) {
          setLocationId(value);
        }
      }}
      items={getLocationItems(locations)}
      darkTheme={true}
      showValidation={false}
      showClearIndicator={false}
    />
  );
};
