import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import {
  MovementLocationType,
  LocationsQuery,
  GET_SHCEDULE_MANAGER,
  GetScheduleManagerByPortQuery,
  GetScheduleManagerByPortQueryVariables,
  SchedulingManagerType,
  LocationModelFragmentFragment,
  Maybe,
} from '@scout/types';
import { useSchedulerDate, useSchedulerLocationId } from './hooks';
import { EastNewMovementModal } from './EastNewMovementModal';
import { EastTableWrapper } from './Table/EastTableWrapper';
import { EastTableBody } from './EastTableBody';
import { EastTableLoader } from './EastTableLoader';
import { InMonth } from '@scout/domains';
import { EastDownloadExcelModal } from '../LocationView/EastDownloadExcelModal';
import { SectionSpinner } from '../../../../components/Loader/SectionSpinner';
import { Loader } from '../../../../components/Loader';

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
export const DragDropSpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const SMOpacity = styled.div`
  opacity: 0.3;
`;
export interface LastUpdatedUserObject {
  updatedBy: string | undefined | null;
  updatedAt: string | undefined | null;
}

export interface EastTableProps {
  isNewMovementModalVisible: boolean;
  isSpreedsheetDownloadModalVisible: boolean;
  locations: LocationsQuery['locations'];
  onHideEastNewMovementModal: () => void;
  onEditMovementModal: () => void;
  onDeleteMovementModal: () => void;
  selectedMovement: SchedulingManagerType | null;
  setSelectedMovement: (value: SchedulingManagerType) => void;
  onHideDownloadExcelModal: () => void;
  isDeleteNewMovementModalVisible: boolean;
  onHideEastDeleteNewMovementModal: () => void;
  isUndoModalVisible: boolean;
  onShowEastUndoModal: () => void;
  onHideEastUndoModal: () => void;
  onUndoModal: () => void;
  isEastMoreActionsModalVisible: boolean;
  onShowEastMoreActionsModal: () => void;
  onHideEastMoreActionsModal: () => void;
  onEastMoreActionsModal: () => void;
  disableUndoButton: (value: boolean) => void;
  currentUserTheme?: boolean;
  toggleTheme: () => void;
  lastUpdatedUserLog: (arg: LastUpdatedUserObject) => void;
  simulation?: boolean;
  isSimulationCloseModalVisible: boolean;
  onCloseSimulationModal: () => void;
  onCancelSimulationModal: () => void;
}

export const EastTable: React.FunctionComponent<EastTableProps> = ({
  isNewMovementModalVisible,
  locations,
  onHideEastNewMovementModal,
  onEditMovementModal,
  selectedMovement,
  setSelectedMovement,
  isSpreedsheetDownloadModalVisible,
  onHideDownloadExcelModal,
  onDeleteMovementModal,
  isDeleteNewMovementModalVisible,
  onHideEastDeleteNewMovementModal,
  isUndoModalVisible,
  onShowEastUndoModal,
  onHideEastUndoModal,
  onUndoModal,
  disableUndoButton,
  currentUserTheme,
  isEastMoreActionsModalVisible,
  onShowEastMoreActionsModal,
  onHideEastMoreActionsModal,
  onEastMoreActionsModal,
  toggleTheme,
  lastUpdatedUserLog,
  simulation,
  isSimulationCloseModalVisible,
  onCloseSimulationModal,
  onCancelSimulationModal,
}) => {
  const [date] = useSchedulerDate();
  const [location, setLocationId] = useSchedulerLocationId();
  const [currentLocation, setCurrentLocation] = useState('');
  const [currentPort, setCurrentPort] = useState('');
  const dates = InMonth.toNextOrRollingDays(date);
  const [loaderForDragAndDrop, setloaderForDragAndDrop] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [undo, setUndo] = useState(false);
  const [actualValues, setActualValues] = useState<
    Array<{
      dragLoadportstartdate: string;
      dragDischargePortStartDate: string;
      id: string;
      cloneId: string;
      currentPort: string;
    }>
  >([]);
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];
  const [dataFetch, setDataFetch] = useState(true);

  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }

  const { data, error, loading, refetch } = !simulation
    ? useQuery<GetScheduleManagerByPortQuery, GetScheduleManagerByPortQueryVariables>(GET_SHCEDULE_MANAGER, {
        variables: { startDate, endDate, currentPort },
        pollInterval: 1000,
      })
    : useQuery<GetScheduleManagerByPortQuery, GetScheduleManagerByPortQueryVariables>(GET_SHCEDULE_MANAGER, {
        variables: {
          startDate,
          endDate,
          currentPort,
        },
        context: {
          headers,
        },
      });

  useEffect(() => {
    refetch();
    lastUpdatedUserLog({ updatedBy: lastUpdateRecord?.updatedBy, updatedAt: lastUpdateRecord?.updatedAt });
  }, [date, currentPort]);

  const lastUpdateRecord = data?.getScheduleManagerByPort.find(e => e.updatedBy);
  useEffect(() => {
    lastUpdatedUserLog({ updatedBy: lastUpdateRecord?.updatedBy, updatedAt: lastUpdateRecord?.updatedAt });
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dataFetch]);

  const isthirdPartyRefinery = location === MovementLocationType.ThirdPartyRefinery;
  const isthirdPartyCustomer = location === MovementLocationType.ThirdPartyCustomer;
  useEffect(() => {
    const presentLocation = locations?.find(locationindex => locationindex.id === location);
    if (presentLocation) {
      setCurrentPort(presentLocation.name);
      setCurrentLocation(presentLocation.type);
    } else {
      if (isthirdPartyRefinery) {
        setCurrentPort(MovementLocationType.ThirdPartyRefinery);
        setCurrentLocation('ThirdPartyRef');
      } else if (isthirdPartyCustomer) {
        setCurrentPort(MovementLocationType.ThirdPartyCustomer);
        setCurrentLocation('ThirdPartyCus');
      }
    }
  });

  const getFilterDataByPort = (
    currentLocation: string,
    data: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  ) => {
    if (data) {
      if (location === 'ThirdPartyRefinery' && currentLocation === 'ThirdPartyRef') {
        return data.filter(item => item.port === checkIfThirdParty(locations, item?.port));
      } else if (location === 'ThirdPartyCustomer' && currentLocation === 'ThirdPartyCus') {
        return data.filter(item => item.port === checkIfThirdParty(locations, item?.port));
      } else if (currentLocation === 'refinery') {
        return data.filter(item => item.port === currentPort || item.loadPort === currentPort);
      } else if (currentLocation === 'depot') {
        return data.filter(item => item.port === currentPort || item.dischargePort === currentPort);
      }
    }
  };

  const checkIfThirdParty = (locationModels: LocationModelFragmentFragment[], loc: Maybe<string> | undefined) => {
    const foundLocation = locationModels.find(locationModel => locationModel.displayName === loc);
    if (foundLocation && foundLocation.isThirdParty && foundLocation.region === 'East') {
      return foundLocation.displayName;
    }
  };

  const movementMultipleDeletion = (item: boolean) => {
    return setDeleted(item);
  };

  const undoDragAndDrop = (item: boolean) => {
    return setUndo(item);
  };
  const displaySMEastTableBody = () => {
    return (
      <>
        {data && (
          <EastTableBody
            locations={locations}
            data={getFilterDataByPort(currentLocation, data.getScheduleManagerByPort)}
            currentPort={currentPort}
            currentlocationtype={currentLocation}
            isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
            dates={dates}
            loading={loading}
            onEditMovementModal={selectedMovementItem => {
              setSelectedMovement(selectedMovementItem);
              onEditMovementModal();
            }}
            onDeleteMovementModal={selectedMovementItem => {
              setSelectedMovement(selectedMovementItem);
              onDeleteMovementModal();
            }}
            onDragAndDropLoaderModal={loadingValue => {
              setloaderForDragAndDrop(loadingValue);
            }}
            movementMultipleDeletion={deleted}
            onUndoModalClick={previousValues => {
              setActualValues(previousValues);
            }}
            disableUndoButton={disableUndoButton}
            currentUserTheme={currentUserTheme}
            onClickSimulationEvent={value => setDataFetch(value)}
            dataFetch={dataFetch}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isSpreedsheetDownloadModalVisible && (
        <EastDownloadExcelModal
          isOpen={isSpreedsheetDownloadModalVisible}
          onCancel={() => {
            onHideDownloadExcelModal();
          }}
          locations={locations}
          // onConfirm={() => {
          //   onHideDownloadExcelModal();
          // }}
          currentUserTheme={currentUserTheme}
        />
      )}
      {isNewMovementModalVisible && (
        <EastNewMovementModal
          SMdata={data}
          locations={locations}
          selectedMovement={selectedMovement}
          showModal={isNewMovementModalVisible}
          onDismiss={() => {
            onHideEastNewMovementModal();
          }}
          onConfirm={async () => {
            onHideEastNewMovementModal();
          }}
          isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
          currentLocation={currentLocation}
          multiDeleteMovement={movementMultipleDeletion}
          undoMovement={undoDragAndDrop}
          previousValues={actualValues}
          disableUndoButton={disableUndoButton}
          currentUserTheme={currentUserTheme}
          toggleTheme={toggleTheme}
          onClickSimulationEvent={value => setDataFetch(value)}
          dataFetch={dataFetch}
        />
      )}

      {isDeleteNewMovementModalVisible && (
        <EastNewMovementModal
          SMdata={data}
          locations={locations}
          selectedMovement={selectedMovement}
          showModal={isDeleteNewMovementModalVisible}
          showModalValue={'deleteMovement'}
          onDismiss={() => {
            onHideEastDeleteNewMovementModal();
          }}
          onConfirm={async () => {
            onHideEastDeleteNewMovementModal();
          }}
          isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
          currentLocation={currentLocation}
          multiDeleteMovement={movementMultipleDeletion}
          undoMovement={undoDragAndDrop}
          previousValues={actualValues}
          disableUndoButton={disableUndoButton}
          currentUserTheme={currentUserTheme}
          toggleTheme={toggleTheme}
          onClickSimulationEvent={value => setDataFetch(value)}
          dataFetch={dataFetch}
        />
      )}

      {isUndoModalVisible && (
        <EastNewMovementModal
          SMdata={data}
          locations={locations}
          selectedMovement={selectedMovement}
          showModal={isUndoModalVisible}
          showModalValue={'undoMovement'}
          onDismiss={() => {
            onHideEastUndoModal();
          }}
          onConfirm={async () => {
            onShowEastUndoModal();
          }}
          isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
          currentLocation={currentLocation}
          multiDeleteMovement={movementMultipleDeletion}
          undoMovement={undoDragAndDrop}
          previousValues={actualValues}
          disableUndoButton={disableUndoButton}
          currentUserTheme={currentUserTheme}
          toggleTheme={toggleTheme}
          onClickSimulationEvent={value => setDataFetch(value)}
          dataFetch={dataFetch}
        />
      )}

      {isEastMoreActionsModalVisible && (
        <EastNewMovementModal
          SMdata={data}
          locations={locations}
          selectedMovement={selectedMovement}
          showModal={isEastMoreActionsModalVisible}
          showModalValue={'moreActions'}
          onDismiss={() => {
            onHideEastMoreActionsModal();
          }}
          onConfirm={async () => {
            onShowEastMoreActionsModal();
          }}
          isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
          currentLocation={currentLocation}
          multiDeleteMovement={movementMultipleDeletion}
          undoMovement={undoDragAndDrop}
          previousValues={actualValues}
          disableUndoButton={disableUndoButton}
          currentUserTheme={currentUserTheme}
          toggleTheme={toggleTheme}
          onClickSimulationEvent={value => setDataFetch(value)}
          dataFetch={dataFetch}
        />
      )}
      {isSimulationCloseModalVisible && (
        <EastNewMovementModal
          SMdata={data}
          locations={locations}
          selectedMovement={selectedMovement}
          showModal={isSimulationCloseModalVisible}
          showModalValue={'closeSimulation'}
          onDismiss={() => {
            onCancelSimulationModal();
          }}
          onConfirm={async () => {
            onCloseSimulationModal();
          }}
          isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
          currentLocation={currentLocation}
          multiDeleteMovement={movementMultipleDeletion}
          undoMovement={undoDragAndDrop}
          previousValues={actualValues}
          disableUndoButton={disableUndoButton}
          currentUserTheme={currentUserTheme}
          toggleTheme={toggleTheme}
          onClickSimulationEvent={value => setDataFetch(value)}
          dataFetch={dataFetch}
        />
      )}
      {loaderForDragAndDrop && (
        <DragDropSpinnerContainer>
          <SectionSpinner />
        </DragDropSpinnerContainer>
      )}
      {loading || error || !data ? (
        <EastTableLoader data={data} loading={loading} error={error} />
      ) : (
        <EastTableWrapper
          isThirdParty={isthirdPartyRefinery || isthirdPartyCustomer}
          currentLocation={currentLocation}
          currentUserTheme={currentUserTheme}
        >
          {loaderForDragAndDrop ? <SMOpacity>{displaySMEastTableBody()}</SMOpacity> : displaySMEastTableBody()}
        </EastTableWrapper>
      )}
    </>
  );
};
