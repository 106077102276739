import moment from 'moment';

import { DashboardRowType, DemandType, MonthlyForecast } from './types';
import { DashboardDataQuery, AllSpotPricesQuery, SpotDemandPlant, Plant, SpotDemandForecast } from '@scout/types';
import { getCurrencySymbol } from '../../../helpers';
import { makeURL, URLS } from '../../../constants';

const getPrimaryPlant = (
  plants: Array<Pick<SpotDemandPlant, 'id' | 'isPremium' | 'isPrimary'> & { plant: Pick<Plant, 'id' | 'shortName'> }>,
  isPremium: boolean,
) => {
  const spotDemandPlant = plants.find(e => e.isPrimary && e.isPremium === isPremium);

  if (!spotDemandPlant) {
    return;
  }

  return spotDemandPlant.plant;
};

const getForecastPricing = ({
  clusterCurrency,
  forecastDate,
  pricingData,
  spotDemandId,
  year,
}: {
  clusterCurrency: string;
  forecastDate: Date;
  pricingData?: AllSpotPricesQuery['allSpotPrices'];
  spotDemandId: string;
  year: number;
}) => {
  let formattedPrice = 'Fetching...';
  if (pricingData) {
    const currencySymbol = getCurrencySymbol(clusterCurrency);
    const pricingForSpotDemand = pricingData.filter(pricing => pricing.spotDemandId === spotDemandId);
    const pricingForMonth = pricingForSpotDemand.find(
      pricing => pricing.date === `${year}-${moment(forecastDate).format('MM')}-01`,
    );

    formattedPrice = pricingForMonth ? `${currencySymbol}${Math.round(pricingForMonth.price)}` : 'N/A';
  }

  return formattedPrice;
};

const getMonthlyForecasts = ({
  clusterCurrency,
  forecasts,
  pricingData,
  spotDemandId,
  year,
  dates,
}: {
  clusterCurrency: string;
  forecasts: SpotDemandForecast[];
  pricingData?: AllSpotPricesQuery['allSpotPrices'];
  spotDemandId: string;
  year: number;
  dates: Array<{
    month: string;
    number: string;
  }>;
}): MonthlyForecast[] => {
  return dates.map(date => {
    const forecast = forecasts.find(e =>
      moment(e.date).isSame(
        moment()
          .year(year)
          .month(date.month),
        'month',
      ),
    );

    const forecastDate = forecast?.date
      ? new Date(forecast.date)
      : moment()
          .month(date.month)
          .toDate();
    const volume = forecast?.volume ?? 0;
    const price = getForecastPricing({ clusterCurrency, forecastDate, pricingData, spotDemandId, year });

    return {
      date: forecastDate,
      volume,
      price,
    };
  });
};

export const normaliseSpotDemands = ({
  currentClusterId,
  clusterCurrency,
  dates,
  demands,
  pricingData,
  year,
}: {
  currentClusterId: string;
  dates: Array<{
    month: string;
    number: string;
  }>;
  demands: DashboardDataQuery['spotDemands']['results'];
  year: number;
  clusterCurrency: string;
  pricingData?: AllSpotPricesQuery['allSpotPrices'];
}): DashboardRowType[] => {
  if (!demands) {
    return [];
  }

  return demands.map(demand => ({
    id: demand.id,
    contractId: '-',
    demandType: DemandType.Spot,
    shipmentType: demand.shipmentType,
    complete: demand.complete ?? false,
    customer: demand.customer,
    shipTo: demand.shipTo,
    region: demand.region,
    primaryPenPlant: getPrimaryPlant(demand.plants, false),
    primaryPremiumPlant: getPrimaryPlant(demand.plants, true),
    totalVolume: Math.round(demand.totalYearlyVolume * 10) / 10,
    monthlyForecasts: getMonthlyForecasts({
      pricingData,
      year,
      clusterCurrency,
      spotDemandId: demand.id,
      forecasts: demand.forecasts.map(forecast => ({
        ...forecast,
        volume: Math.round((forecast.volume ?? 0) * 10) / 10,
      })),
      dates,
    }),
    editLink: makeURL(URLS.MID_TERM_EDIT_SPOT, { clusterId: currentClusterId, id: demand.id }),
  }));
};
