import React from 'react';
import styled, { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

const PinInner = styled.div`
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Pin: React.FC<{ theme?: IThemeType; currentUserTheme?: boolean }> = withTheme(
  ({ theme, currentUserTheme }) => (
    <PinInner>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 9C21 13.9706 12 24 12 24C12 24 3 13.9706 3 9C3 4.02944 7.02944 0 12 0C16.9706 0 21 4.02944 21 9ZM12 14C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14Z"
          fill={currentUserTheme ? theme.secondary.light.c : theme.base.dark.d}
        />
      </svg>
    </PinInner>
  ),
);
