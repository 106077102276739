import React from 'react';
import { Header } from '../components/Header';
import { NavLink } from '../components/NavLink';
import { URLS } from '../constants';

const PricingLayout: React.FC = ({ children }) => (
  <>
    <Header>
      <NavLink to={URLS.PRICING}>Pricing</NavLink>
    </Header>
    <main>{children}</main>
  </>
);

export { PricingLayout };
