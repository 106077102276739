import { generatePath } from 'react-router-dom';
import { ContractDealType } from '@scout/types';

export const YYYYMMDD: string = 'YYYY-MM-DD';
export const BUKOM: string = 'Bukom';
export const SERVICE_NOW_URL: string = 'https://servicenow.shell.com/sp?id=index';
export const PORT_CALLS_EXCEL_DOWNLOAD_URL: string = '/api/reports/portCall';
export const PORT_CALLS_EXCEL_FILE_NAME: string = 'VESSEL_FLOW_EXTRACT';
export const PORT_CALLS_API_ERROR_MSG: string = 'Error while trying to download port calls';
export const PORT_CALLS_EXCEL_FILE_TYPE: string = 'text/csv;charset=utf-8';
export enum URLS {
  ROOT = '/',
  IN_MONTH = '/in-month',
  EAST_IN_MONTH = '/east/in-month',
  MID_TERM = '/mid-term',
  MID_TERM_CONTRACTS = '/mid-term/:clusterId/contracts',
  MID_TERM_CREATE_CONTRACT_DEMAND = '/mid-term/:clusterId/contracts/:contractType(fpd|r-fpd|formula)/:dealId',
  MID_TERM_CREATE_SPOT = '/mid-term/:clusterId/spot',
  MID_TERM_DASHBOARD = '/mid-term/:clusterId/dashboard',
  MID_TERM_EDIT_CONTRACT_DEMAND = '/mid-term/:clusterId/contracts/:contractDemandId',
  MID_TERM_EDIT_SPOT = '/mid-term/:clusterId/spot/:id',
  PRICING = '/pricing',
  SETUP = '/setup',
  SIMULATION = '/simulation',
  REPORTS_LANDING = '/powerbi-reports/',
  REPORTS_TYPE = '/powerbi-reports/:reportId/:pageName',
  HISTORICAL_DATA_ANALYSIS = '/historical_data_analysis',
  VESSEL_FLOW_ANALYSIS = '/vessel_flow_analysis',
}
export const TITLE_SUSHI: string = 'Sushi';
export const TITLE_SIMULATION: string = 'Simulation';

const makeURL = (url: URLS, params?: Parameters<typeof generatePath>[1], query?: Record<string, string>): string => {
  const pathname = generatePath(url, params);

  if (query != null && Object.keys(query).length > 0) {
    return `${pathname}?${new URLSearchParams(query)}`;
  }

  return pathname;
};

const contractDealTypeToUrlPart = (type: ContractDealType): string => {
  switch (type) {
    case ContractDealType.Fixed: {
      return 'fpd';
    }
    case ContractDealType.Formula: {
      return 'formula';
    }
    case ContractDealType.Rolling: {
      return 'r-fpd';
    }
  }
};

const urlPartToContractDealType = (contractType: string): ContractDealType | undefined => {
  switch (contractType) {
    case 'fpd': {
      return ContractDealType.Fixed;
    }
    case 'r-fpd': {
      return ContractDealType.Rolling;
    }
    case 'formula': {
      return ContractDealType.Formula;
    }
  }
};

export enum LocationTypes {
  Refinery = 'refinery',
  Depot = 'depot',
  Customer = 'customer',
}

export enum MsgTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const SALE_ACTUAL_VOLUME_UPDATE_MSG = 'Sale updated successfully';
export const SALE_ACTUAL_VOLUME_UPDATE_ERR_MSG = 'Sale update failed';
export const DELETE_CONTRACT_FAILURE_MESSAGE = 'Error deleting contract demand.';
export const DELETE_CONTRACT_SUCCESS_MESSAGE = 'Contract Demand deleted successfully.';

export const weatherIconData = [
  { 0: 'A weather code could not be determined' },
  { 1: 'Clear sky' },
  { 2: 'Light clouds' },
  { 3: 'Partly cloudy' },
  { 4: 'Cloudy' },
  { 5: 'Rain' },
  { 6: 'Rain and snow / sleet' },
  { 7: 'Snow' },
  { 8: 'Rain shower' },
  { 9: 'Snow shower' },
  { 10: 'Sleet shower' },
  { 11: 'Light Fog' },
  { 12: 'Dense fog' },
  { 13: 'Freezing rain' },
  { 14: 'Thunderstorms' },
  { 15: 'Drizzle' },
  { 16: 'Sandstorm' },
  { 101: 'Clear sky' },
  { 102: 'Light clouds' },
  { 103: 'Partly cloudy' },
  { 104: 'Cloudy' },
  { 105: 'Rain' },
  { 106: 'Rain and snow / sleet' },
  { 107: 'Snow' },
  { 108: 'Rain shower' },
  { 109: 'Snow shower' },
  { 110: 'Sleet shower' },
  { 111: 'Light Fog' },
  { 112: 'Dense fog' },
  { 113: 'Freezing rain' },
  { 114: 'Thunderstorms' },
  { 115: 'Drizzle' },
  { 116: 'Sandstorm' },
];

export { contractDealTypeToUrlPart, makeURL, urlPartToContractDealType };
