import React, { useContext, useState } from 'react';
import { UserThemeContext } from '../../../App';
import { Container } from '../../../components/Container';
import { Tabs } from '../../../components/Tabs';
import { TabsOptionsProps } from './types';

interface SpotFormProps {
  renderForm: (activeTab: TabsOptionsProps, setActiveTab: (name: TabsOptionsProps) => void) => React.ReactNode;
}

const SpotForm: React.FunctionComponent<SpotFormProps> = props => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const [activeTab, setActiveTab] = useState(TabsOptionsProps.Grade);

  return (
    <Tabs
      items={[
        {
          id: 'grade',
          label: 'Grades & Plants',
        },
        {
          id: 'volume',
          label: 'Volumes & Pricing',
        },
        {
          id: 'transportCosts',
          label: 'Transport Options',
        },
      ]}
      selected={activeTab}
      onItemClick={(id: string) => {
        setActiveTab(id as TabsOptionsProps);
      }}
      spotForm={true}
      currentUserTheme={currentUserTheme}
    >
      <Container>{props.renderForm(activeTab, setActiveTab)}</Container>
    </Tabs>
  );
};

export { SpotForm };
