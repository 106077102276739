import { Facet } from '@scout/types';

const DATE_PREFIX = 'date.';
const VIEW_CLUSTER_PREFIX = 'view.cluster.';
const VIEW_USER_PREFIX = 'view.user.';
const YEAR_PREFIX = 'year.';

const getSelected = (facets: Facet[]): Facet | undefined => facets.find(vf => vf.selected);

const getSelectedOrFirst = (facets: Facet[]): Facet => getSelected(facets) || facets[0];

const isClusterFacet = (facet: Facet): boolean => facet.id.startsWith(VIEW_CLUSTER_PREFIX);

const removeViewPrefix = (facetId: string): string => {
  if (facetId.startsWith(VIEW_CLUSTER_PREFIX)) {
    return facetId.replace(VIEW_CLUSTER_PREFIX, '');
  }

  if (facetId.startsWith(VIEW_USER_PREFIX)) {
    return facetId.replace(VIEW_USER_PREFIX, '');
  }

  return facetId;
};

export {
  DATE_PREFIX,
  getSelected,
  getSelectedOrFirst,
  isClusterFacet,
  removeViewPrefix,
  VIEW_CLUSTER_PREFIX,
  VIEW_USER_PREFIX,
  YEAR_PREFIX,
};
