import React from 'react';
import styled from 'styled-components';
import { getGradeFromTransfer } from '../../utils';
import { ProductTransferQueryResult } from '@scout/types';

interface TransferSummaryProps {
  transfers: ProductTransferQueryResult[];
}

const TransferContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const TransferWrapper = styled.div`
  display: flex;
`;

const GradeElement = styled.div`
  flex: 1;
  border: 1px solid ${props => props.theme.greys.dark.disabled};
  border-radius: 2px 0 0 2px;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 4px;
`;

const QuantityElement = styled.div`
  flex: 1;
  background-color: ${props => props.theme.greys.dark.disabled};
  border: 1px solid ${props => props.theme.greys.dark.disabled};
  border-radius: 0 2px 2px 0;
  text-align: center;
  padding: 0 4px;
`;

const TransferSummary: React.FunctionComponent<TransferSummaryProps> = ({ transfers }) => {
  const { quantity, grade } = transfers.reduce(
    (agg: { quantity: number; grade: string }, transfer: ProductTransferQueryResult) => ({
      grade: !agg.grade ? getGradeFromTransfer(transfer) : 'Multi',
      quantity: agg.quantity + transfer.volume,
    }),
    { quantity: 0, grade: '' },
  );
  return (
    <TransferContainer>
      <TransferWrapper>
        <GradeElement>{grade}</GradeElement>
        <QuantityElement>
          {quantity > 0 ? '+' : ''}
          {Math.round(quantity / 10) / 100}Kt
        </QuantityElement>
      </TransferWrapper>
    </TransferContainer>
  );
};

export { TransferSummary };
