import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const HistoricalDataAnalysisIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="50" height="46" viewBox="0 0 50 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35 20.5V0.5H15V15.5H0V45.5H50V20.5H35ZM20 5.5H30V40.5H20V5.5ZM5 20.5H15V40.5H5V20.5ZM45 40.5H35V25.5H45V40.5Z"
      fill="white"
    />
  </svg>
));
