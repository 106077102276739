import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { theme } from '../../theme';
import { Modal } from '../Modal';
import { UserThemeContext } from '../../App';

const Container = styled.div<{ align?: string; onClick: () => void }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: ${props => (props.align === 'right' ? 'flex-end' : 'flex-start')};
`;

const IconContainer = styled.div`
  margin-top: -2px;
`;

const Icon = () => (
  <IconContainer>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87109 12C2.87109 6.48 7.35109 2 12.8711 2C18.3911 2 22.8711 6.48 22.8711 12C22.8711 17.52 18.3911 22 12.8711 22C7.35109 22 2.87109 17.52 2.87109 12ZM13.8711 16V18H11.8711V16H13.8711ZM12.8711 20C8.46109 20 4.87109 16.41 4.87109 12C4.87109 7.59 8.46109 4 12.8711 4C17.2811 4 20.8711 7.59 20.8711 12C20.8711 16.41 17.2811 20 12.8711 20ZM8.87109 10C8.87109 7.79 10.6611 6 12.8711 6C15.0811 6 16.8711 7.79 16.8711 10C16.8711 11.2829 16.0811 11.9733 15.3119 12.6455C14.5821 13.2833 13.8711 13.9046 13.8711 15H11.8711C11.8711 13.1787 12.8132 12.4566 13.6415 11.8217C14.2913 11.3236 14.8711 10.8792 14.8711 10C14.8711 8.9 13.9711 8 12.8711 8C11.7711 8 10.8711 8.9 10.8711 10H8.87109Z"
        fill={theme.typography.colors.hintLight}
      />
    </svg>
  </IconContainer>
);

const Text = styled(Typography)`
  margin-right: 5px;
  white-space: nowrap;
`;

interface Props {
  text: string;
  children: React.ReactNode;
  align?: 'right';
}

export const HelpText: React.FunctionComponent<Props> = props => {
  const { currentUserTheme } = React.useContext(UserThemeContext);
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <Container onClick={() => setOpen(true)} align={props.align}>
        <Text variant="body3" align="right">
          {props.text}
        </Text>
        <Icon />
      </Container>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)} currentUserTheme={currentUserTheme}>
        {props.children}
      </Modal>
    </>
  );
};
