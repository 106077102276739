import { FieldProps, Field, FormikValues } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../components/Typography';
import { CardProps } from './Card';
import { TextField } from './TextField';

const gutterWidth = '5px';
const Root = styled.div`
  display: flex;
`;

const LabelColumn = styled.div`
  margin-top: 33px;
  min-width: 130px;
`;

const LabelCell = styled.div`
  background: ${props => props.theme.base.light.b};
  padding: 25px 10px;
  margin: ${gutterWidth} ${gutterWidth} ${gutterWidth} 0;
`;

const InputRow = styled.div<{ isActive: boolean }>`
  background: ${props => (props.isActive ? `${props.theme.primary.b}${props.theme.opacity[0.2]}` : 'transparent')};
  border: ${props => props.isActive && `1px solid ${props.theme.primary.b}`};
  border-radius: 2px;
`;

const InputCell = styled.div<{ dense?: boolean; isActive: boolean }>`
  background: ${props => (props.isActive ? 'transparent' : props.theme.base.light.b)};
  padding: ${props => (props.dense ? '12px' : '25px')} 9px;
  margin-top: ${props => (props.dense ? '0' : gutterWidth)};
`;

const MonthLabel = styled.div`
  width: 75px;
  margin: 10px 0;
`;

interface InputTableProps {
  months: CardProps['plantValues'];
  prefixName: string;
}

const InputTable: React.FunctionComponent<InputTableProps> = props => {
  const calculateAmount = (amount?: string | null, delta?: string | null): string => {
    const parsedAmount = amount ? parseFloat(Number(amount).toFixed(2)) : 0;
    const parsedDelta = delta ? parseFloat(Number(delta).toFixed(2)) : 0;

    const total = parsedAmount + parsedDelta;

    if (isNaN(total)) {
      return Number(0).toFixed(2);
    }

    return total.toFixed(2);
  };

  return (
    <div>
      <Root>
        <LabelColumn>
          <LabelCell>
            <Typography tag="span" align="right" variant="body3">
              Delta
            </Typography>
          </LabelCell>
          <LabelCell>
            <Typography tag="span" align="right" variant="body3">
              Min Price vs FO
            </Typography>
          </LabelCell>
          <LabelCell>
            <Typography tag="span" align="right" variant="body3">
              Max Price vs FO
            </Typography>
          </LabelCell>
        </LabelColumn>

        {props.months.map((month, index) => {
          const isPastMonth = moment(month.date, 'YYYY-MM-DD').isBefore(moment(), 'month');
          const isCurrentMonth = moment(month.date, 'YYYY-MM-DD').isSame(moment(), 'month');
          return (
            <div key={`accordion-card-date-${month.date}-${index}`}>
              <MonthLabel>
                <Typography tag="span" align="center" variant="body3">
                  {moment(new Date(month.date)).format('MMM')}
                </Typography>
              </MonthLabel>

              <InputRow isActive={isCurrentMonth}>
                <InputCell isActive={isCurrentMonth} dense={true}>
                  <Field
                    name={`${props.prefixName}.items.${index}.delta`}
                    render={({ field }: FieldProps<FormikValues>) => (
                      <TextField {...field} disabled={isPastMonth} isText={isCurrentMonth} />
                    )}
                  />
                </InputCell>
                <InputCell isActive={isCurrentMonth}>
                  <Typography
                    tag="span"
                    align="center"
                    variant="body3"
                    data-testid={`${props.prefixName}.minValue.${index}`}
                  >
                    {calculateAmount(month.minVSFO, month.delta)}
                  </Typography>
                </InputCell>
                <InputCell isActive={isCurrentMonth}>
                  <Typography
                    tag="span"
                    align="center"
                    variant="body3"
                    data-testid={`${props.prefixName}.maxValue.${index}`}
                  >
                    {calculateAmount(month.maxVSFO, month.delta)}
                  </Typography>
                </InputCell>
              </InputRow>
            </div>
          );
        })}
      </Root>
    </div>
  );
};

export { InputTable };
