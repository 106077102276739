import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '../../Typography';
import { OptionProps } from 'react-select/src/components/Option';
import { SingleDropdownProps } from '../Single';

const Container = styled.div`
  cursor: pointer;
`;

const ClearIndicator = ({ innerProps, selectProps }: OptionProps<SingleDropdownProps>) => {
  if (!selectProps.showClearIndicator) {
    return null;
  }

  return (
    <Container {...innerProps}>
      <Typography variant="labels1" color={selectProps.darkTheme ? 'bodyDark' : 'bodyLight'}>
        Clear
      </Typography>
    </Container>
  );
};

export { ClearIndicator };
