import React from 'react';
import { EastDepotSalesContainer } from './EastDepotSalesContainer';
import { EastDepotReplenishmentContainer } from './EastDepotReplenishmentContainer';
import { EastDepotMovementDetails } from './EastDepotMovementDetails';
import { useQuery } from 'react-apollo';
import { EastModalLoader } from './EastModalLoader';
import {
  GET_NEW_MOVEMENT_FORM_DATA,
  GetNewFormDataQuery,
  GradeModelFragmentFragment,
  GetEditFormDataQuery,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  LocationsQuery,
} from '@scout/types';
import { EastDepotEventContainer } from './EastDepotEventContainer';
import { RequirePermission } from '../../../../../components/RequirePermission';
import {
  getEventsSelectedValues,
  getLiftingReplenishmentSelectedValues,
  getProductionSalesSelectedValues,
} from './EastMovementsHelper';

const grades: GradeModelFragmentFragment[] = [
  {
    __typename: 'GradeModel',
    id: '1',
    name: 'Bitumen',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '2',
    name: '71114S',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '3',
    name: '71114PH',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '4',
    name: '71114IND',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '5',
    name: '71114CHO',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '6',
    name: '71114HKV',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '7',
    name: '71114AR',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '8',
    name: '71114JSU',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '9',
    name: 'Adjustment',
    type: 'Adjustment',
  },
];

export interface EastDepotMovementFormProps {
  currentTab: string;
  currentLocationName: string;
  onDismiss: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  selectedMovement: SchedulingManagerType | null;
  locations: LocationsQuery['locations'];
  isThirdParty?: boolean;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

export const EastDepotMovementForm: React.FunctionComponent<EastDepotMovementFormProps> = props => {
  switch (props.currentTab) {
    case 'Sale':
      return (
        <EastDepotSalesContainer
          currentLocationName={props.currentLocationName}
          grades={grades}
          cancelModal={props.onDismiss}
          SMdata={props.SMdata}
          salesSelectedValues={props.selectedMovement ? getProductionSalesSelectedValues(props.selectedMovement) : {}}
          selectedMovement={props.selectedMovement}
          currentUserTheme={props.currentUserTheme}
          onClickSimulationEvent={props.onClickSimulationEvent}
          dataFetch={props.dataFetch}
        />
      );
    case 'Replenishment':
      const { data, loading, error } = useQuery<GetNewFormDataQuery>(GET_NEW_MOVEMENT_FORM_DATA, {
        fetchPolicy: 'no-cache',
      });
      if (loading || error || !data || !data.transports) {
        return (
          <EastModalLoader
            error={error ? 'Something went wrong' : undefined}
            currentUserTheme={props.currentUserTheme}
          />
        );
      }

      return (
        <>
          <RequirePermission anyOf={['eastDepotUser']}>
            {props.selectedMovement !== null && (
              <EastDepotMovementDetails
                replenishmentSelectedValues={
                  props.selectedMovement ? getLiftingReplenishmentSelectedValues(props.selectedMovement) : {}
                }
                selectedMovement={props.selectedMovement}
                currentLocationName={props.currentLocationName}
                vessels={data.transports}
                cancelModal={props.onDismiss}
                SMdata={props.SMdata}
                locations={props.locations}
                currentUserTheme={props.currentUserTheme}
                onClickSimulationEvent={props.onClickSimulationEvent}
                dataFetch={props.dataFetch}
              />
            )}
          </RequirePermission>

          <RequirePermission anyOf={['editEastSchedulingManager', 'viewEastSchedulingManager']}>
            <EastDepotReplenishmentContainer
              replenishmentSelectedValues={
                props.selectedMovement ? getLiftingReplenishmentSelectedValues(props.selectedMovement) : {}
              }
              selectedMovement={props.selectedMovement}
              currentLocationName={props.currentLocationName}
              grades={grades}
              vessels={data.transports}
              cancelModal={props.onDismiss}
              SMdata={props.SMdata}
              locations={props.locations}
              isThirdParty={props.isThirdParty}
              currentUserTheme={props.currentUserTheme}
              onClickSimulationEvent={props.onClickSimulationEvent}
              dataFetch={props.dataFetch}
            />
          </RequirePermission>
        </>
      );
    case 'Event':
      return (
        <>
          <EastDepotEventContainer
            currentLocationName={props.currentLocationName}
            cancelModal={props.onDismiss}
            SMdata={props.SMdata}
            eventDepotSelectedValues={props.selectedMovement ? getEventsSelectedValues(props.selectedMovement) : {}}
            selectedMovement={props.selectedMovement}
            currentUserTheme={props.currentUserTheme}
            onClickSimulationEvent={props.onClickSimulationEvent}
            dataFetch={props.dataFetch}
          />
        </>
      );
    default:
      return null;
  }
};
