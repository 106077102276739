import * as React from 'react';
import styled from 'styled-components';

const Root = styled.div<{ hasVerticalPadding?: boolean }>`
  max-width: 1124px;
  margin: 0 auto;
  padding: ${({ hasVerticalPadding }) => `${hasVerticalPadding ? '1rem' : '0 1rem'}`};
  height: 100%;
`;

interface Props {
  hasVerticalPadding?: boolean;
}

const Container: React.FC<Props> = ({ children, hasVerticalPadding = true }) => (
  <Root hasVerticalPadding={hasVerticalPadding}>{children}</Root>
);

export { Container };
