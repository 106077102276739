import React from 'react';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { useSchedulerDate } from './hooks';
import * as Dates from './dates';

export const DateDropdown: React.FunctionComponent<{ currentUserTheme?: boolean }> = ({ currentUserTheme }) => {
  const [date, setDate] = useSchedulerDate();
  return (
    <SingleDropdown
      name="date"
      width="150px"
      placeholder="Date"
      onItemClick={({ value }) => {
        if (value) {
          setDate(value);
        }
      }}
      selected={date}
      items={Dates.getEastDates()}
      darkTheme={true}
      showValidation={false}
      showClearIndicator={false}
    />
  );
};
