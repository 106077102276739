import moment from 'moment';

import { DashboardDataQuery, ContractDealType, ContractDemand, Contract, Cluster } from '@scout/types';
import { DemandType, DashboardRowType } from './types';
import { getCurrencySymbol } from '../../../helpers';
import { makeURL, URLS } from '../../../constants';

type ContractMonthsType = keyof Pick<
  ContractDemand,
  'jan' | 'feb' | 'mar' | 'apr' | 'may' | 'jun' | 'jul' | 'aug' | 'sep' | 'oct' | 'nov' | 'dec'
>;

const getContractDemandType = (contractType: ContractDealType): DemandType => {
  switch (contractType) {
    case ContractDealType.Fixed:
      return DemandType.Fixed;
    case ContractDealType.Formula:
      return DemandType.Formula;
    default:
      return DemandType.Rolling;
  }
};

const getContractId = (contractType: ContractDealType, dealId: string, stascoId: string): string => {
  switch (contractType) {
    case ContractDealType.Fixed:
      return stascoId;
    case ContractDealType.Rolling:
      return stascoId;
    case ContractDealType.Formula:
      return dealId;
    default:
      return '-';
  }
};

const formatPrice = ({
  price,
  clusterCurrency,
}: {
  price: Contract['sellingPrice'];
  clusterCurrency: Cluster['currency'];
}): string => {
  if (!price) {
    return 'N/A';
  }
  const currencySymbol = getCurrencySymbol(clusterCurrency);
  return `${currencySymbol}${Math.round(price)}`;
};

export const normaliseContractDemands = ({
  cluster,
  currentClusterId,
  clusterCurrency,
  dates,
}: {
  cluster: DashboardDataQuery['cluster'] | undefined;
  currentClusterId: string;
  clusterCurrency: Cluster['currency'];
  dates: Array<{
    month: string;
    number: string;
  }>;
}): DashboardRowType[] => {
  if (!cluster?.contractDemands?.results) {
    return [];
  }

  return cluster.contractDemands.results.reduce<DashboardRowType[]>((acc, group) => {
    const currDemands: DashboardRowType[] = group.demands.map(
      ({ id, region, isPremium, shipTo, plant, totalVolume, ...rest }) => ({
        id,
        contractId: getContractId(group.contractType, group.contract.dealId || '-', group.contract.stascoId || '-'),
        complete: true,
        demandType: getContractDemandType(group.contractType),
        shipmentType: group.shipmentType,
        customer: group.customer,
        shipTo,
        region,
        primaryPenPlant: !isPremium
          ? {
              id: plant.id,
              shortName: plant.shortName,
            }
          : undefined,
        primaryPremiumPlant: isPremium
          ? {
              id: plant.id,
              shortName: plant.shortName,
            }
          : undefined,
        totalVolume: Math.round(totalVolume * 10) / 10,
        editLink: makeURL(URLS.MID_TERM_EDIT_CONTRACT_DEMAND, {
          clusterId: currentClusterId,
          contractDemandId: group.id,
        }),
        // @TODO :: need to update this for formula contracts as well
        monthlyForecasts: dates.map(date => ({
          date: moment()
            .month(date.month)
            .toDate(),
          volume:
            rest[
              moment()
                .month(date.month)
                .format('MMM')
                .toLocaleLowerCase() as ContractMonthsType
            ] ?? 0,
          price: formatPrice({ price: group.contract.sellingPrice, clusterCurrency }),
        })),
      }),
    );

    return [...acc, ...currDemands];
  }, []);
};
