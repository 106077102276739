import React, { useContext } from 'react';
import { Typography } from '../../../components/Typography';
import { Empty } from '../../../components/Empty';
import { UserThemeContext } from '../../../App';

const NoResultsPlaceholder: React.FC = () => {
  const { currentUserTheme } = useContext(UserThemeContext);
  return (
    <Empty currentUserTheme={currentUserTheme}>
      <Typography variant="body1" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
        You have <strong>no new contracts</strong>
      </Typography>
    </Empty>
  );
};

export { NoResultsPlaceholder };
