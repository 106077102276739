import React from 'react';
import { Button } from '../../../components/Button';
import { Empty } from '../../../components/Empty';
import { RequirePermission } from '../../../components/RequirePermission';
import { Typography } from '../../../components/Typography';

interface Props {
  isNewDemandDisabled: boolean;
  onShowDemandModal: () => void;
  currentUserTheme?: boolean;
}

const NoResultsPlaceholder: React.FC<Props> = ({ isNewDemandDisabled, onShowDemandModal, currentUserTheme }: Props) => (
  <Empty currentUserTheme={currentUserTheme}>
    <Typography data-testid="no-results-message" variant="body1" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
      You have <strong>no demands</strong> that match your filter criteria
    </Typography>
    <RequirePermission anyOf={['editDemandForecast']}>
      <Button
        disabled={isNewDemandDisabled}
        icon="MdAdd"
        variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}
        onClick={onShowDemandModal}
      >
        New spot demand
      </Button>
    </RequirePermission>
  </Empty>
);

export { NoResultsPlaceholder };
