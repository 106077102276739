import React from 'react';
import styled from 'styled-components';
import { fontMixin } from '../../components/Mixins';
import { FormikHandlers } from 'formik';
import { TextField } from './TextField';

const InputWrapper = styled.div`
  background: ${p => `${p.theme.primary.b}${p.theme.opacity[0.2]}`};
  display: inline-block;
  padding: 10px;
  margin-left: 10px;
`;

const Label = styled.label<{ variant: string }>`
  ${fontMixin}
  font-size: 0.875rem;
  display: inline-block;
  margin-right: 10px;
`;

interface InputBoxProps {
  label: string;
  value: string;
  name: string;
  onChange: FormikHandlers['handleChange'];
  onBlur?: FormikHandlers['handleBlur'];
  disabled?: boolean;
}

const InputBox: React.FunctionComponent<InputBoxProps> = ({ label, ...props }) => {
  return (
    <InputWrapper>
      <Label variant="body4">{label}</Label>
      <TextField {...props} />
    </InputWrapper>
  );
};

export { InputBox };
