import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../components/Typography';
import { TankMetaIcon } from '../../../components/Icons/TankMetaIcon';
import { formatMetaValue } from './helpers';
import { useHover } from '../../../hooks/useHover';
import { URLS } from '../../../constants';

import { usePermission } from '../../../hooks/usePermission';
import moment, { Moment } from 'moment';
const TankMetaWrapper = styled.div`
  position: relative;
  overflow: visible;
`;

const TankMetaIconWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  margin-left: 5px;
`;

const TankMetaOverlay = styled.div`
  right: -10px;
  top: 25px;
  align-items: center;
  position: absolute;
  background-color: white;
  color: black;
  min-height: 40px;
  width: max-content;
  padding: 12px;

  border-radius: 2px;
  box-shadow: 0px 10px 38px 0px rgba(0, 0, 0, 0.75);
  z-index: 9999;

  &:after {
    right: 8px;
    bottom: calc(100% - 2px);
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${p => p.theme.base.light.a};
    border-width: 10px;
  }
`;

export enum TankMetaDataLabel {
  PRODUCT = 'Product',
  PEN = 'Pen',
  SOFTENING_POINT = 'Softening Point',
  TEMPERATURE = 'Temperature',
  VISCOSITY = 'Viscosity',
}

export interface TankMetaData {
  label: TankMetaDataLabel;
  value: string | number;
  eventtime: Date;
}

export interface TankMetaProps {
  meta: TankMetaData[];
}

export const TankMeta: React.FunctionComponent<TankMetaProps> = ({ meta }) => {
  const [hovered, hoverEvents] = useHover();
  return (
    <TankMetaWrapper data-testid="tank-meta">
      <TankMetaIconWrapper data-testid="tank-meta-icon" {...hoverEvents}>
        <TankMetaIcon />
      </TankMetaIconWrapper>
      {hovered && (
        <TankMetaOverlay>
          {meta.map(({ label, value, eventtime }) => (
            <div key={label}>
              <Typography variant="labels1">
                <span data-testid="tank-meta-label">{label}</span>
              </Typography>
              <Typography variant="labels1" bold={true}>
                <span data-testid="tank-meta-value">{formatMetaValue({ label, value, eventtime })}</span>
              </Typography>
              {label === 'Pen' && window.location.pathname === URLS.EAST_IN_MONTH ? (
                <>
                  <Typography variant="labels1">
                    <span>Last Updated:</span>
                  </Typography>
                  <Typography variant="labels1" bold={true}>
                    <span data-testid="tank-meta-value-update">{moment(eventtime).format('DD-MM-YYYY HH:mm:ss')}</span>
                  </Typography>
                </>
              ) : (
                ''
              )}
            </div>
          ))}
        </TankMetaOverlay>
      )}
    </TankMetaWrapper>
  );
};
