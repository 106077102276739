import * as Sentry from '@sentry/browser';
import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher, Observable } from 'apollo-boost';
import { config } from './config';
import { IntrospectionResultData } from '@scout/types';

const refreshRetry = async () => {
  const response = await fetch('/refresh', {
    credentials: 'include',
    headers: {
      accept: 'application/json',
    },
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error('Can not refresh session');
  }
};

const makeClient = () => {
  // Since Apollo Client can be sending multiple requests at once
  // it's possible that, for example, three requests could be sent
  // and then all fail because the session has expired. `tokenRefreshing`
  // is used as a guard so that only one of those failed requests will
  // try to refresh the token. This is used to control if the client is currently trying to refresh
  let tokenRefreshing: Promise<void> | undefined;

  return new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: IntrospectionResultData,
      }),
    }),

    name: config.ENV,
    uri: '/api/graphql',
    headers: {
      accept: 'application/json',
    },
    fetchOptions: {
      credentials: 'include',
    },
    onError: ({ forward, graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(graphQLError => Sentry.captureMessage(graphQLError.message, Sentry.Severity.Warning));
      }

      // Determine if this was a ServerError by seeing if there is a `statusCode`
      if (networkError && 'statusCode' in networkError) {
        // Something else happened here so just forward to Sentry
        if (networkError.statusCode !== 401) {
          Sentry.captureException(networkError);
          return;
        }

        // @ts-ignore
        return new Observable(async observer => {
          // If another GraphQL request has failed and is trying to refresh the
          // token then we wait for it to finish. It will either successfully
          // refresh or end up redirecting the browser to /login.
          if (tokenRefreshing != null) {
            // Wait for other token refresh to finish
            await tokenRefreshing;

            // We've been allowed to continue so the other request must have
            // successfully refreshed the token. Let's try the GraphQL request
            // again shall we?
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
            return;
          }

          // This is a way of creating a Promise that can be triggered at a specific time.
          // We can use this halt the other requests until we're ready
          let tokenRefreshingSignal: () => void;
          tokenRefreshing = new Promise(resolve => {
            tokenRefreshingSignal = resolve;
          });

          try {
            await refreshRetry();

            // The cookies have been replaced and we can replay the request that
            // failed initially.
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);

            // Calling the signal means the other halted requests will now continue.
            // @ts-ignore TypeScript thinks this is being used before being defined
            tokenRefreshingSignal();

            // Reset this so future requests won't resolve an already resolved promise
            tokenRefreshing = undefined;
          } catch (err) {
            // If for some reason the tokens can't be refreshed then we'll
            // have to redirect the use to the login endpoint to go through
            // Port again.
            window.location.replace('/login');
          }
        });
      }
    },
  });
};

export { makeClient };
