import React, { useContext } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import { useToast } from '../../../components/Toast/Toast';
import { MdWarning } from 'react-icons/md';
import { DELETE_CONTRACT_SUCCESS_MESSAGE, DELETE_CONTRACT_FAILURE_MESSAGE, makeURL, URLS } from '../../../constants';
import { ContractDemandLayout } from '../../../layouts/ContractDemand';
import {
  GET_CONTRACT_DEMAND_EDIT_DATA,
  UPDATE_CONTRACT_DEMAND_MUTATION,
  Cluster,
  ContractDemand,
  GetContractDemandEditDataQuery,
  GetContractDemandEditDataQueryVariables,
  ShipmentType,
  UpdateContractDemandMutation,
  UpdateContractDemandMutationVariables,
  DELETE_CONTRACT_DEMAND,
  DeleteContractDemandMutation,
  DeleteContractDemandMutationVariables,
} from '@scout/types';
import { ContractDemandSteps } from './Steps';
import { ContractDemandState, Demand } from './types';
import styled from 'styled-components';
import { Typography } from '../../../components/Typography';
import { Container } from '../../../components/Container';
import { DeleteIcon } from '../../../components/Icons/DeleteIcon';
import { Modal } from '../../../components/Modal';
import { RequirePermission } from '../../../components/RequirePermission';
import { makeReturnURL } from '../SpotForm/url';
import { UserThemeContext } from '../../../App';

const Icon = MdWarning;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;
const DeleteRoot = styled.div`
  background: ${props => props.theme.base.light.b};
  margin-bottom: 70px;
`;

const DeleteLink = styled(Typography)<{ onClick: () => void }>`
  border-top: 1px solid ${props => props.theme.greys.light.border};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 18px 0;
  margin-bottom: 100px;
`;
const DeleteBorder = styled.div`
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 10px 6px;
`;
type Props = RouteComponentProps<{ clusterId: string; contractDemandId: string }>;

interface CreateInitialStateInput {
  demandLocation: Cluster['demandLocation'];
  demands: ContractDemand[];
  shipmentType: ShipmentType;
}

const createInitialState = ({
  demandLocation,
  demands,
  shipmentType,
}: CreateInitialStateInput): ContractDemandState => {
  const formattedDemands: Demand[] = demands.map(demand => ({
    id: demand.id,
    isFixed: demand.isBaseDemand,
    region: demand.region as Demand['region'],
    shipTo: demand.shipTo as Demand['shipTo'],
    isPremium: demand.isPremium,
    monthlyVolumes: {
      jan: demand.jan ?? undefined,
      feb: demand.feb ?? undefined,
      mar: demand.mar ?? undefined,
      apr: demand.apr ?? undefined,
      may: demand.may ?? undefined,
      jun: demand.jun ?? undefined,
      jul: demand.jul ?? undefined,
      aug: demand.aug ?? undefined,
      sep: demand.sep ?? undefined,
      oct: demand.oct ?? undefined,
      nov: demand.nov ?? undefined,
      dec: demand.dec ?? undefined,
    },
    plant: demand.plant,
    totalVolume: demand.totalVolume,
    gradeSplit: demand.gradeSplit.map(gradeSplit => ({
      id: gradeSplit.id,
      grade: gradeSplit.grade,
      percentage: gradeSplit.percentage || undefined,
    })),
  }));

  formattedDemands.sort((a, b) => {
    if (a.isFixed && b.isFixed) {
      return 0;
    }

    return a.isFixed ? -1 : 1;
  });

  return {
    demands: formattedDemands,
    shipmentType,
    demandLocation,
  };
};
const EditContractDemand: React.FC<Props> = ({ history, match }) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const { push } = useToast();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { data, error, loading } = useQuery<GetContractDemandEditDataQuery, GetContractDemandEditDataQueryVariables>(
    GET_CONTRACT_DEMAND_EDIT_DATA,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clusterId: match.params.clusterId,
        contractDemandGroupId: match.params.contractDemandId,
      },
    },
  );

  const [updateDemand] = useMutation<UpdateContractDemandMutation, UpdateContractDemandMutationVariables>(
    UPDATE_CONTRACT_DEMAND_MUTATION,
  );

  const [deleteDemand] = useMutation<DeleteContractDemandMutation, DeleteContractDemandMutationVariables>(
    DELETE_CONTRACT_DEMAND,
  );

  if (loading || error != null) {
    return <Loader error={error != null ? 'Error retrieving contract demand' : undefined} />;
  }

  if (data == null || data.cluster == null || data.cluster.contractDemand == null) {
    return <Redirect to={makeURL(URLS.MID_TERM_CONTRACTS, { clusterId: match.params.clusterId })} />;
  }

  const searchParams = new URLSearchParams(history.location.search);
  const from = searchParams.get('from');

  // We use this when the user cancels and also after a successful submit
  const redirectUrl = from ?? makeURL(URLS.MID_TERM_DASHBOARD, { clusterId: match.params.clusterId });

  const handleSubmit = async (values: ContractDemandState) => {
    const currentDemandGroup = data.cluster?.contractDemand;
    if (!data.cluster || !currentDemandGroup) {
      push({
        text: 'Something went wrong, try again',
        size: 'SMALL',
        type: 'ERROR',
      });
      return;
    }

    const payload: UpdateContractDemandMutationVariables['input'] = {
      shipmentType: values.shipmentType,
      contractId: currentDemandGroup.contract.id,
      contractType: currentDemandGroup.contractType,
      clusterId: data.cluster.id,
      ownedById: currentDemandGroup.ownedBy.id,
      demands: values.demands.map(demand => ({
        id: demand.id,
        gradeSplit: demand.gradeSplit.map(split => ({
          id: split.id,
          gradeId: split.grade.id,
          percentage: split.percentage,
        })),
        plantId: demand.plant.id,
        isPremium: !!demand.isPremium,
        isBaseDemand: demand.isFixed,
        regionId: demand.region ? demand.region.id : undefined,
        shipToId: demand.shipTo ? demand.shipTo.id : undefined,
        ...demand.monthlyVolumes,
      })),
    };

    try {
      await updateDemand({
        variables: {
          id: currentDemandGroup.id,
          input: payload,
        },
      });

      push({
        text: 'Contract demand updated.',
        size: 'SMALL',
        type: 'SUCCESS',
      });

      history.push(redirectUrl);
    } catch (error) {
      push({
        text: 'Something went wrong, try again',
        size: 'SMALL',
        type: 'ERROR',
      });
    }
  };

  const onDelete = async () => {
    const currentDemandGroup = data.cluster?.contractDemand;
    if (data.cluster === null) {
      return;
    }
    if (currentDemandGroup?.demands[0].id && currentDemandGroup?.id) {
      const input = {
        contractDemandID: currentDemandGroup?.demands[0].id,
        contractDemandGroupID: currentDemandGroup?.id,
        fixedContractID: currentDemandGroup?.contract?.stascoId,
        formulaContractID: currentDemandGroup?.contract?.dealId,
      };
      try {
        await deleteDemand({
          variables: {
            input,
          },
        });

        push({
          text: DELETE_CONTRACT_SUCCESS_MESSAGE,
        });
        const url = makeReturnURL({ from, currentClusterId: data.cluster.id });
        history.push(url);
      } catch (error) {
        push({
          text: DELETE_CONTRACT_FAILURE_MESSAGE,
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <ContractDemandLayout>
      <Modal
        title="Delete demand"
        onConfirm={onDelete}
        confirmBtnVariant="danger"
        onDismiss={() => setDeleteModal(false)}
        isOpen={deleteModal}
        minWidth={550}
        confirmText="Delete"
        currentUserTheme={currentUserTheme}
      >
        <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="heading6" align="center">
          Are you sure you want to delete this demand?
        </Typography>
        <br />
        <Typography color={currentUserTheme ? 'bodyLight' : 'bodyDark'} variant="heading6" align="center">
          Reminder! Ensure the same demand is deleted at Jupiter.
        </Typography>
      </Modal>
      <ContractDemandSteps
        ownerName={data.cluster.contractDemand.ownedBy.emailAddress}
        auditDate={data.cluster.contractDemand.updatedAt ?? data.cluster.contractDemand.createdAt}
        creatorName={data.cluster.contractDemand.createdBy?.emailAddress}
        updaterName={data.cluster.contractDemand.updatedBy?.emailAddress}
        onSubmit={handleSubmit}
        onCancel={() => history.push(redirectUrl)}
        initialValues={createInitialState({
          demands: data.cluster.contractDemand.demands as ContractDemand[],
          shipmentType: data.cluster.contractDemand.shipmentType,
          demandLocation: data.cluster.demandLocation,
        })}
        currency={data.cluster.currency}
        demandLocation={data.cluster.demandLocation}
        penPlants={data.cluster.penPlants}
        premiumPlants={data.cluster.premiumPlants}
        regions={data.cluster.regions}
        contract={data.cluster.contractDemand.contract}
        clusterId={data.cluster.id}
      />
      <RequirePermission anyOf={['deleteDemandForecast']}>
        <DeleteRoot>
          <Container>
            <DeleteLink tag="a" onClick={() => setDeleteModal(true)}>
              <DeleteBorder>
                <DeleteIcon />
                <Typography tag="span" inline={true}>
                  Delete Demand
                </Typography>
              </DeleteBorder>
            </DeleteLink>
          </Container>
        </DeleteRoot>
      </RequirePermission>
    </ContractDemandLayout>
  );
};

export { EditContractDemand };
