// adding common functions and styling of lifting and replenishment movement forms
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { GetScheduleManagerByPortQuery, LocationsQuery } from '@scout/types';
import { Typography } from '../../../../../components/Typography';
import { TextArea } from '../../../../../components/TextArea';
import { Button } from '../../../../../components/Button';
import styled from 'styled-components';

export const Root = styled.div<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme ? props.theme.base.dark.d : props.theme.base.light.b)};
  background: ${props => (props.currentUserTheme ? props.theme.base.light.b : props.theme.base.dark.d)};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Label = styled(Typography).attrs({ variant: 'body3', tag: 'div' })<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme ? props.theme.base.dark.d : props.theme.base.light.b)};
  margin-bottom: 5px;
`;

export const RowStyle = styled.div`
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  height: 100px;

  &:last-child {
    margin-right: 0;
  }
`;
export const WidthAdjustmentContainer = styled.div<{ widthToSet?: string }>`
  width: ${props => props.widthToSet};
`;

export const StyledTextArea = styled(TextArea)`
  padding: 8px;
`;

export const ScrollRoot = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
margin-top: 75px;
margin-bottom: 25px;
margin-right:15px
display: flex;
flex-direction: row;
${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
float: right
`;

export const NewButton = styled(Button)`
  margin-left: 16px;
`;

export const MovementValidation = styled.div`
  position: relative;
  width: 657px;
  height: 45px;
  background: rgba(215, 43, 63, 0.4);
  /* $secondary/A-coral-dark */
  border: 1px solid #db4052;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
`;

export const InsideCalendar = styled.span`
  padding: 0.5px;
`;

export interface RefineryLiftingReplenishmentValues {
  productType: string;
  grade: string;
  plannedVolume: number;
  actualVolume: number;
  basisPrice: string;
  fobPriceInternal: number;
  fobPriceCustomer: number;
  internalFreightPrice: number;
  customerFreightActualPrice: number;
  purchaseContract: string;
  salesContract: string;
  buyerInfo: string;
  planningNotes: string;
  vesselName: string;
  incoterm: string;
  loadPort: string;
  loadPortLaycanStartDate: string;
  loadPortLaycanEndDate: string;
  plannedLoadMonth: string;
  BLDate: string;
  dischargePort: string;
  country: string;
  dischargePortLaycanStartDate: string;
  dischargePortLaycanEndDate: string;
  autoETA: boolean;
  dragDischargePortStartDate: string;
  dragLoadportstartdate: string;
  coa: boolean;
}
export const REQUIRED_ERROR_MESSAGE = 'This is a required field';
export const FUTURE_DATE_ERROR_MESSAGE = 'Date Should be in the Future';
export const NUMBER_TYPE_ERROR_MESSAGE = 'Please enter a number';
export const EventValidationErrorMessage =
  'There is an event blocking this input. Please remove event before creating movement';
export const today = new Date();

export const validationSchema = Yup.object().shape({
  grade: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  plannedVolume: Yup.number()
    .typeError(NUMBER_TYPE_ERROR_MESSAGE)
    .required(REQUIRED_ERROR_MESSAGE),

  actualVolume: Yup.number().nullable(),

  basisPrice: Yup.string().nullable(),
  fobPriceInternal: Yup.number()
    .nullable()
    .typeError(NUMBER_TYPE_ERROR_MESSAGE),

  fobPriceCustomer: Yup.number()
    .nullable()
    .typeError(NUMBER_TYPE_ERROR_MESSAGE),

  internalFreightPrice: Yup.number()
    .nullable()
    .typeError(NUMBER_TYPE_ERROR_MESSAGE),

  customerFreightActualPrice: Yup.number()
    .nullable()
    .typeError(NUMBER_TYPE_ERROR_MESSAGE),

  purchaseContract: Yup.string()
    .nullable()
    .max(500),
  salesContract: Yup.string()
    .nullable()
    .max(500),
  buyerInfo: Yup.string()
    .nullable()
    .max(500),
  planningNotes: Yup.string()
    .max(500)
    .nullable(),
  vesselName: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  incoterm: Yup.string().nullable(),
  loadPort: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  loadPortLaycanStartDate: Yup.date().required(REQUIRED_ERROR_MESSAGE),
  // .min(today, FUTURE_DATE_ERROR_MESSAGE),
  loadPortLaycanEndDate: Yup.date()
    .required(REQUIRED_ERROR_MESSAGE)
    .min(today, FUTURE_DATE_ERROR_MESSAGE)
    .min(Yup.ref('loadPortLaycanStartDate'), 'end date must be after start date'),

  plannedLoadMonth: Yup.string().nullable(),
  BLDate: Yup.date().nullable(),
  dischargePort: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  country: Yup.string()
    .max(2)
    .nullable(),
  dischargePortLaycanStartDate: Yup.date()
    .required(REQUIRED_ERROR_MESSAGE)
    .min(today, FUTURE_DATE_ERROR_MESSAGE)
    .min(Yup.ref('loadPortLaycanStartDate'), 'discharge date must be after load date'),

  dischargePortLaycanEndDate: Yup.date()
    .required(REQUIRED_ERROR_MESSAGE)
    .min(today, FUTURE_DATE_ERROR_MESSAGE)
    .min(Yup.ref('dischargePortLaycanStartDate'), 'end date must be after start date'),
});

export const checkEventPresent = async (
  values: RefineryLiftingReplenishmentValues,
  movementType: string,
  currentLocationName: string,
  SMdata: GetScheduleManagerByPortQuery | undefined,
  setMovementValidation: (val: boolean) => void,
) => {
  const productionDate = moment(values.loadPortLaycanStartDate).format('YYYY-MM-DD');
  const dischargedates = moment(values.dischargePortLaycanStartDate).format('YYYY-MM-DD');

  const foundData = SMdata?.getScheduleManagerByPort.find(item => {
    if (movementType === 'Lifting') {
      return (
        // if blocked event is present on loadPortLaycanStartDate at loadport i.e currentLocationName
        (item.productType === 'Event' &&
          item.port === currentLocationName &&
          item.blockDates === true &&
          (item.eventStartDate && item.eventEndDate
            ? moment(productionDate).isBetween(item.eventStartDate, item.eventEndDate, undefined, '[]')
            : false)) ||
        // if blocked event is present on dischargePortLaycanStartDate at dischargePort
        (item.productType === 'Event' &&
          item.port === values.dischargePort &&
          item.blockDates === true &&
          (item.eventStartDate && item.eventEndDate
            ? moment(dischargedates).isBetween(item.eventStartDate, item.eventEndDate, undefined, '[]')
            : false))
      );
    } else {
      return (
        // if blocked event is present on loadPortLaycanStartDate at loadport
        (item.productType === 'Event' &&
          item.blockDates === true &&
          item.port === values.loadPort &&
          (item.eventStartDate && item.eventEndDate
            ? moment(productionDate).isBetween(item.eventStartDate, item.eventEndDate, undefined, '[]')
            : false)) ||
        // if blocked event is present on dischargePortLaycanStartDate at dischargeport i.e currentLocationName
        (item.productType === 'Event' &&
          item.port === currentLocationName &&
          item.blockDates === true &&
          (item.eventStartDate && item.eventEndDate
            ? moment(dischargedates).isBetween(item.eventStartDate, item.eventEndDate, undefined, '[]')
            : false))
      );
    }
  });
  if (foundData) {
    setMovementValidation(true);
    return true;
  } else {
    setMovementValidation(false);
    return false;
  }
};

export const getCountry = (
  dischargePort: string | null,
  locations: LocationsQuery['locations'],
  setCountryCode: (val: string | undefined) => void,
) => {
  let countryValue;
  return locations.map(location => {
    if (location.displayName === dischargePort) {
      countryValue = location.country !== null ? location.country : '';
      setCountryCode(countryValue);
    }
  });
};

export const getPlannedLoadMonth = (
  loadPortLaycanstartDate: moment.Moment | null | undefined,
  values: { loadPortLaycanStartDate: string | number | Date } | undefined,
) => {
  if (loadPortLaycanstartDate) {
    const cloneLoadPortStartDate = loadPortLaycanstartDate.clone();
    const cloneLoadPortStartDateValue = loadPortLaycanstartDate.clone();
    const monthsRequired = 12;
    const getMonthsYear = [
      { label: cloneLoadPortStartDate.format('YYYY-MM'), value: cloneLoadPortStartDateValue.format('YYYY-MM') },
    ];
    for (let i = 1; i <= monthsRequired; i++) {
      getMonthsYear.push({
        label: cloneLoadPortStartDate.add(1, 'months').format('YYYY-MM'),
        value: cloneLoadPortStartDateValue.add(1, 'months').format('YYYY-MM'),
      });
    }
    return getMonthsYear;
  } else if (values) {
    const getLoadPortDate = moment(new Date(values.loadPortLaycanStartDate));
    const getCurrentMonth = getLoadPortDate;
    const monthsRequired = 12;
    const getMonthsYear = [{ label: getCurrentMonth.format('YYYY-MM'), value: getCurrentMonth.format('YYYY-MM') }];
    for (let i = 1; i <= monthsRequired; i++) {
      const monthYear = getCurrentMonth.add(1, 'months').format('YYYY-MM');
      getMonthsYear.push({
        label: monthYear,
        value: monthYear,
      });
    }
    return getMonthsYear;
  } else {
    const getCurrentMonth = moment();
    const getCurrentMonthLoadPortStartDateValue = moment();
    const monthsRequired = 12;
    const getMonthsYear = [
      { label: getCurrentMonth.format('YYYY-MM'), value: getCurrentMonthLoadPortStartDateValue.format('YYYY-MM') },
    ];
    for (let i = 1; i <= monthsRequired; i++) {
      getMonthsYear.push({
        label: getCurrentMonth.add(1, 'months').format('YYYY-MM'),
        value: getCurrentMonthLoadPortStartDateValue.add(1, 'months').format('YYYY-MM'),
      });
    }
    return getMonthsYear;
  }
};

export const getAllRequiredValues = (
  values: RefineryLiftingReplenishmentValues,
  setButtonActive: (val: boolean) => void,
) => {
  const allRequiredFields =
    values.grade &&
    values.plannedVolume &&
    values.vesselName &&
    values.loadPort &&
    values.loadPortLaycanStartDate &&
    values.loadPortLaycanEndDate &&
    values.dischargePort &&
    values.dischargePortLaycanEndDate &&
    values.dischargePortLaycanStartDate;
  const volume = Math.abs(values.plannedVolume);
  if (allRequiredFields && volume > 0) {
    setButtonActive(false);
  } else {
    setButtonActive(true);
  }
};

export const getDefaultValues = (currentLocationName: string, movementType: string) => {
  const defaultValues = {
    productType: movementType,
    grade: 'Bitumen',
    plannedVolume: 0,
    actualVolume: 0,
    basisPrice: '',
    fobPriceInternal: 0,
    fobPriceCustomer: 0,
    internalFreightPrice: null,
    customerFreightActualPrice: null,
    purchaseContract: '',
    salesContract: '',
    buyerInfo: '',
    planningNotes: '',
    vesselName: '',
    incoterm: '',
    loadPort: movementType === 'Lifting' ? currentLocationName : '',
    loadPortLaycanStartDate: '',
    loadPortLaycanEndDate: '',
    plannedLoadMonth: '',
    BLDate: null,
    dischargePort: movementType === 'Replenishment' ? currentLocationName : '',
    country: '',
    dischargePortLaycanStartDate: '',
    dischargePortLaycanEndDate: '',
    autoETA: false,
    dragDischargePortStartDate: '',
    dragLoadportstartdate: '',
    coa: 0,
  };
  return defaultValues;
};
