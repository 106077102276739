import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { MdInfo } from 'react-icons/md';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { Checkbox } from '../../../../components/Checkbox';
import { FormError } from '../../../../components/FormError/FormError';
import { HelpText } from '../../../../components/HelpText';
import { SelectOptionProps } from '../../../../components/Select/Select';
import { TextArea } from '../../../../components/TextArea';
import { Typography } from '../../../../components/Typography';
import { ShipmentType, GET_TRANSPORT_COST, GetTransportCostQueryVariables, GetTransportCostQuery } from '@scout/types';
import { Cluster } from '../../../../types';
import { PlantDropdownValue, SpotFormValuesProps } from '../Form';
import { PlantOptions } from '../types';
import { PlantDropdown } from './PlantDropdown';
import { UserThemeContext } from '../../../../App';
import { Tooltip } from '../../../../components/Tooltip';
import { Loader } from '../../../../components/Loader';
import { theme } from '../../../../theme';

const Title = styled.div`
  margin-bottom: 30px;
`;

const Heading = styled(Typography)`
  margin-bottom: 8px;
`;

const Spacer = styled.div<{ size?: 'small' }>`
  margin-top: ${props => (props.size === 'small' ? '10px' : '25px')};
`;

const Subheading = styled(Typography)`
  margin: 8px 0;
`;

const TooltipContainer = styled.div`
  display: inline-block;
  width: 30px;
  position: absolute;
  right: -35px;
  top: 32px;
`;

const PlantDropdownContainer = styled.div`
  position: relative;
`;

interface PlantsSelectionProps {
  onChange: (name: string, value: string | PlantDropdownValue | SelectOptionProps) => void;
  penPlants: PlantOptions[];
  premiumPlants: PlantOptions[];
  values: SpotFormValuesProps;
  disablePlants?: boolean;
  requiresPMB?: boolean;
  errors?: FormikErrors<SpotFormValuesProps>;
  touched?: FormikTouched<FormikValues>;
  shipmentType: string;
  onBlur: (name: string) => void;
  selectedPenGrades: string[];
  selectedPremiumGrades: string[];
  cluster: Cluster;
  regionId?: string;
  customerId?: string;
  shipToId?: string;
}

interface PlantToolTipProps {
  text: string;
}

const PlantToolTip: React.FunctionComponent<PlantToolTipProps> = ({ text }) => (
  <TooltipContainer>
    <Tooltip
      styles={{
        marginTop: 7,
        maxWidth: '13rem',
        right: 0,
        padding: '9px 13px',
        boxShadow: '0px 5px 10px 0px rgb(0 0 0 / 25%)',
        '&:after': {
          right: 11,
          left: 'auto',
        },
      }}
      body={<Typography variant="labels1">{text}</Typography>}
    >
      <MdInfo size={24} color={theme.typography.colors.warning} />
    </Tooltip>
  </TooltipContainer>
);

const PlantsSelection: React.FunctionComponent<PlantsSelectionProps> = ({
  errors,
  touched,
  premiumPlants,
  penPlants,
  selectedPenGrades,
  selectedPremiumGrades,
  shipmentType,
  cluster,
  ...props
}) => {
  const { currentUserTheme } = useContext(UserThemeContext);
  const [transportCostMap, setTransportCostMap] = useState<{ [key: string]: string }>({});
  const { data, loading, error } = useQuery<GetTransportCostQuery, GetTransportCostQueryVariables>(GET_TRANSPORT_COST, {
    skip: shipmentType !== ShipmentType.Delivery,
    variables: {
      clusterId: cluster.id ?? '',
      customerId: props.customerId ?? '',
      shipToId: props.shipToId ?? '',
      regionId: props.regionId ?? '',
      plantIds: Array.from(new Set([...premiumPlants, ...penPlants].map(plant => plant.value))),
    },
  });

  const hasTransportCost = useCallback(
    plant => {
      const transportCost = transportCostMap[plant.value];
      if (transportCost && Number.isNaN(Number(transportCost))) {
        plant.tag = 'No Transport Cost';
      }
      return plant;
    },
    [data, transportCostMap],
  );

  useEffect(() => {
    if (!props.values.penPlantPrimary && !props.values.premiumPlantPrimary) {
      props.onChange('plantSelectionReasonTechnical', '');
      props.onChange('plantSelectionReasonOperational', '');
      props.onChange('plantSelectionReasonContractual', '');
      props.onChange('plantSelectionNote', '');
    }
  }, [props.values.penPlantPrimary, props.values.premiumPlantPrimary]);

  useEffect(() => {
    if (data && !Object.keys(transportCostMap).length) {
      const map: { [key: string]: string } = {};
      data.getTransportCost.forEach(plant => {
        map[plant.plantId] = plant.cost;
      });
      setTransportCostMap(map);
    }
  }, [transportCostMap, data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Title>
        <Heading bold={true} variant="heading5">
          Step 2. Select your preferred plant(s)
        </Heading>
        <Typography variant="body3">Your grade split selection will determine the list of available plants.</Typography>
        <Spacer size="small">
          <HelpText text="Learn more about selecting a plant for your grade split">
            <Typography variant="heading5" bold={true} color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
              Plant selection and optimisation
            </Typography>
            <Spacer>
              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                Based on your grade split, you can select your 'primary' plant for your Pen and Premium grades. Choosing
                a 'secondary' plant is optional and allows you to include a 2nd supply option in the optimisation
                process. If you do so, please note both plants are evaluated equally and no preference is given to your
                primary plant.
              </Typography>
            </Spacer>
            <Spacer>
              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                Plant selection is only mandatory for pickup demands. The available plants have been limited to ensure
                100% of your grade split can be supplied from your selected locations.
              </Typography>
            </Spacer>

            <Spacer>
              <Typography align="justify" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                The 'reasons for plant selection' is mandatory to support the supply team in ongoing analysis of supply
                optimisation decisions.
              </Typography>
            </Spacer>
          </HelpText>
        </Spacer>
      </Title>

      <Spacer>
        <Subheading variant="body1" bold={true}>
          Pen plant(s) {shipmentType === ShipmentType.Delivery && '(Optional)'}
        </Subheading>
        <PlantDropdownContainer>
          <PlantDropdown
            name="penPlantPrimary"
            value={props.values.penPlantPrimary.plantId}
            label="Primary plant"
            placeholder="Select a primary plant"
            selectedGrades={selectedPenGrades}
            plants={penPlants
              .map(hasTransportCost)
              .filter(plant => plant.value !== props.values.penPlantSecondary.plantId)}
            onBlur={props.onBlur}
            onChange={(name, value) => {
              props.onChange(name, value);
              if (!value) {
                props.onChange('penPlantSecondary', { plantId: '' });
                return;
              }
            }}
            error={errors && errors.penPlantPrimary && errors.penPlantPrimary.plantId}
            disabled={
              props.disablePlants ||
              (Object.keys(props.values.grades.hard).every(
                key => Number(props.values.grades.hard[key].percentage) === 0,
              ) &&
                Object.keys(props.values.grades.soft).every(
                  key => Number(props.values.grades.soft[key].percentage) === 0,
                ))
            }
          />
          {props.values.penPlantPrimary.plantId &&
            data &&
            Number.isNaN(Number(transportCostMap[props.values.penPlantPrimary.plantId])) && (
              <PlantToolTip text={transportCostMap[props.values.penPlantPrimary.plantId]} />
            )}
        </PlantDropdownContainer>

        <PlantDropdownContainer>
          <PlantDropdown
            label="Secondary plant"
            name="penPlantSecondary"
            value={props.values.penPlantSecondary.plantId}
            placeholder="Select a secondary plant"
            selectedGrades={selectedPenGrades}
            onBlur={props.onBlur}
            onChange={props.onChange}
            plants={penPlants
              .map(hasTransportCost)
              .filter(plant => plant.value !== props.values.penPlantPrimary.plantId)}
            disabled={props.disablePlants || !Boolean(props.values.penPlantPrimary.plantId)}
          />
          {props.values.penPlantSecondary.plantId &&
            data &&
            Number.isNaN(Number(transportCostMap[props.values.penPlantSecondary.plantId])) && (
              <PlantToolTip text={transportCostMap[props.values.penPlantSecondary.plantId]} />
            )}
        </PlantDropdownContainer>
      </Spacer>

      <Spacer>
        <Subheading variant="body1" bold={true}>
          Premium plant(s) {shipmentType === ShipmentType.Delivery && '(Optional)'}
        </Subheading>

        <PlantDropdownContainer>
          <PlantDropdown
            label="Primary plant"
            name="premiumPlantPrimary"
            value={props.values.premiumPlantPrimary.plantId}
            placeholder="Select a primary plant"
            selectedGrades={selectedPremiumGrades}
            plants={premiumPlants
              .map(hasTransportCost)
              .filter(plant => plant.value !== props.values.premiumPlantSecondary.plantId)}
            error={errors && errors.premiumPlantPrimary && errors.premiumPlantPrimary.plantId}
            onChange={(name, value) => {
              props.onChange(name, value);
              if (!value) {
                props.onChange('premiumPlantSecondary', { plantId: '' });
              }
            }}
            onBlur={props.onBlur}
            disabled={
              props.disablePlants ||
              Object.keys(props.values.grades.premium).every(
                key => Number(props.values.grades.premium[key].percentage) === 0,
              )
            }
          />
          {props.values.premiumPlantPrimary.plantId &&
            data &&
            Number.isNaN(Number(transportCostMap[props.values.premiumPlantPrimary.plantId])) && (
              <PlantToolTip text={transportCostMap[props.values.premiumPlantPrimary.plantId]} />
            )}
        </PlantDropdownContainer>
        <PlantDropdownContainer>
          <PlantDropdown
            label="Secondary plant"
            name="premiumPlantSecondary"
            value={props.values.premiumPlantSecondary.plantId}
            placeholder="Select a secondary plant"
            selectedGrades={selectedPremiumGrades}
            onChange={props.onChange}
            onBlur={props.onBlur}
            plants={premiumPlants
              .map(hasTransportCost)
              .filter(plant => plant.value !== props.values.premiumPlantPrimary.plantId)}
            disabled={props.disablePlants || !Boolean(props.values.premiumPlantPrimary.plantId)}
          />
          {props.values.premiumPlantSecondary.plantId &&
            data &&
            Number.isNaN(Number(transportCostMap[props.values.premiumPlantSecondary.plantId])) && (
              <PlantToolTip text={transportCostMap[props.values.premiumPlantSecondary.plantId]} />
            )}
        </PlantDropdownContainer>
      </Spacer>

      <Spacer>
        <Subheading variant="body1" bold={true}>
          Reason for preferred plant selection
        </Subheading>
        <Checkbox
          disabled={props.disablePlants || (!props.values.penPlantPrimary && !props.values.premiumPlantPrimary)}
          name="plantSelectionReasonOperational"
          label="Operational (e.g. Geographical constraints)"
          checked={Boolean(props.values.plantSelectionReasonOperational)}
          onChange={checkedValue => {
            props.onChange('plantSelectionReasonOperational', checkedValue);
          }}
          error={errors && errors.plantSelectionReasonTechnical}
          onBlur={() => props.onBlur('plantSelectionReasonOperational')}
        />
        <Checkbox
          disabled={props.disablePlants || (!props.values.penPlantPrimary && !props.values.premiumPlantPrimary)}
          name="plantSelectionReasonTechnical"
          label="Technical (e.g. Grade specific constraints)"
          checked={Boolean(props.values.plantSelectionReasonTechnical)}
          onChange={checkedValue => {
            props.onChange('plantSelectionReasonTechnical', checkedValue);
          }}
          onBlur={() => props.onBlur('plantSelectionReasonTechnical')}
          error={errors && errors.plantSelectionReasonTechnical}
        />
        <Checkbox
          disabled={props.disablePlants || (!props.values.penPlantPrimary && !props.values.premiumPlantPrimary)}
          name="plantSelectionReasonContractual"
          label="Contractual (e.g. Agreed with customer)"
          checked={Boolean(props.values.plantSelectionReasonContractual)}
          onChange={checkedValue => {
            props.onChange('plantSelectionReasonContractual', checkedValue);
          }}
          onBlur={() => props.onBlur('plantSelectionReasonContractual')}
          error={errors && errors.plantSelectionReasonTechnical}
        />

        <FormError message={errors && errors.plantSelectionReasonTechnical} withSpaceHolder={true} />
      </Spacer>
      <Spacer>
        <Subheading variant="body1" bold={true}>
          Notes for supply optimisation (optional)
        </Subheading>

        <TextArea
          disabled={!props.values.penPlantPrimary && !props.values.premiumPlantPrimary}
          name="plantSelectionNote"
          value={props.values.plantSelectionNote}
          onChange={(e: React.ChangeEvent<{ value: string }>) => props.onChange('plantSelectionNote', e.target.value)}
          themeStyle="light"
          placeholder="Notes for supply optimisation (optional)"
        />
      </Spacer>
    </>
  );
};

export { PlantsSelection };
