import React from 'react';
import styled from 'styled-components';
import { LocationsQuery } from '@scout/types';
import { Modal } from '../../../../components/Modal';
import { SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { EastExcelDownloadContainer } from './EastMovement/EastExcelDownloadContainer';

export const StyledTypeaheadDropdown = styled(SingleDropdown)`
  margin-bottom: 10px;
`;

// overflow-y: scroll,
// max-height: 700px

const ModalScroll = styled.div`
overflow-y: scroll,
 max-height: 700px
`;

export const PORT_FIELD = 'portId';
export const DATE_RANGE_FIELD = 'dateRange';

export interface ScheduleManagerModalProps {
  locations: LocationsQuery['locations'];
  isOpen: boolean;
  onCancel: () => void;
  currentUserTheme?: boolean;
}

export const EastDownloadExcelModal = ({
  locations,
  isOpen,
  onCancel,
  currentUserTheme,
}: ScheduleManagerModalProps) => {
  return (
    <Modal
      maxHeight={'100%'}
      overflowY={'scroll'}
      title="Export Spreadsheet"
      isOpen={isOpen}
      minWidth={550}
      confirmText="Export"
      onClose={onCancel}
      currentUserTheme={currentUserTheme}
    >
      <EastExcelDownloadContainer locations={locations} cancelModal={onCancel} currentUserTheme={currentUserTheme} />
    </Modal>
  );
};
