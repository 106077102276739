import React from 'react';
import { withTheme } from 'styled-components';

export const NoteInfoIcon = withTheme(({ theme }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00002 1.16669C3.78002 1.16669 1.16669 3.78002 1.16669 7.00002C1.16669 10.22 3.78002 12.8334 7.00002 12.8334C10.22 12.8334 12.8334 10.22 12.8334 7.00002C12.8334 3.78002 10.22 1.16669 7.00002 1.16669ZM6.41669 9.91669V6.41669H7.58335V9.91669H6.41669ZM6.41669 4.08335V5.25002H7.58335V4.08335H6.41669Z"
      fill={theme.greys.light.border}
    />
  </svg>
));
