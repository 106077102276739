import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Field, Formik, FieldProps, getIn, Form } from 'formik';
import { Col, Row, Grid } from '../../../../../components/Grid';
import { Typography } from '../../../../../components/Typography';
import { getEastLocationItemsMultipleSelect, getCustomiseReportItems } from '../../utils';
import { Calendar } from '../../../../../components/Calendar';
import moment, { Moment } from 'moment';
import { Button } from '../../../../../components/Button';
import { LocationsQuery } from '@scout/types/dist';
import { MultiSelectDropdown, MultiDropdownItem, allOption } from '../../../../../components/SelectDropdown/Multi';
import { InsideCalendar } from './EastProductionSalesMovementHelper';
import { excelExtractFilter } from './constants';
import { isInclusivelyBeforeDay } from 'react-dates';
import { SingleDropdown } from '../../../../../components/SelectDropdown/Single';
import { Tabs } from '../../../../../components/Tabs';
import { useToast } from '../../../../../components/Toast/Toast';

const Root = styled.div<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme ? props.theme.base.dark.d : props.theme.base.light.b)};
  background: ${props => (props.currentUserTheme ? props.theme.base.light.b : props.theme.base.dark.d)};
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
  margin: -8px;
`;
const Label = styled(Typography).attrs({ variant: 'body3' })<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme ? props.theme.base.dark.d : 'white')};
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-right: 0;
  }
`;

const Spaceinbetween = styled.div`
  margin-top: 15px;
`;
const RadioButton = styled.div`
  height: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
`;

const NewButton = styled(Button)`
  margin-left: 16px;
`;

const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  float: right;
  margin-right: 22px;
`;

interface EastExcelDownloadContainerProps {
  locations: LocationsQuery['locations'];
  cancelModal: () => void;
  currentUserTheme?: boolean;
}

export interface EastExcelDownloadContainerValues {
  port: MultiDropdownItem[] | null;
  startDate: Moment | Date | null;
  endDate: Moment | Date | null;
  range: string;
  attributes: MultiDropdownItem[] | null;
  reportType: string;
}

export const SCHEDULE_MANAGER_REPORT_URL = '/api/reports/schedule-manager';

export const EastExcelDownloadContainer: React.FunctionComponent<EastExcelDownloadContainerProps> = ({
  locations,
  cancelModal,
  currentUserTheme,
}) => {
  const [excelStartDate, setExcelStartDate] = useState<Moment | Date | null>();
  const [excelEndDate, setExcelEndDate] = useState<Moment | Date | null>();
  const [buttonActive, setButtonActive] = useState(true);
  const [selectedOption, setSelectedOption] = useState<MultiDropdownItem[] | null>();
  const [selectedOptions, setSelectedOptions] = useState<MultiDropdownItem[] | null>();
  const [clickedOutside, setClickedOutside] = useState(false);
  const [customizeReport, setCustomizeReport] = useState(false);
  const [customizeReportOptions, setCustomizeReportOptions] = useState<string | null>();
  const [reportTab, setReportTab] = useState('Custom Report');
  const [reports, setReports] = useState(false);
  const { push } = useToast();

  const handleOnSubmit = async (values: EastExcelDownloadContainerValues) => {
    try {
      const response = await fetch(SCHEDULE_MANAGER_REPORT_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // TBD, TODO: As per standard practice, we should pass database genarated location/port id which present under "port.value", instead of the label/name,
          // to the backend excel export API.
          port: values.port?.filter(port => port.value !== allOption.value).map(port => port.label),
          dateFrom: values.startDate,
          dateTo: values.endDate,
          attributes: values.attributes?.filter(port => port.value !== allOption.value),
          reportType: values.reportType,
        }),
      });
      if (response.status === 200) {
        const fileBuffer = await response.arrayBuffer();
        const blob = new Blob([fileBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        saveAs(blob, `${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
        cancelModal();
      } else {
        const statusText: string = response.statusText ? response.statusText : 'Records are not found';
        alert(statusText);
      }
    } catch (err) {
      console.error(err);
      push({ type: 'ERROR', text: 'Error while trying to download export report.' });
    }
  };

  // Detecting click outside component
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleClickOutside = (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (!myRef.current.contains(target)) {
      setClickedOutside(true);
    }
  };
  const handleClickInside = () => setClickedOutside(false);
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <>
      <Tabs
        currentUserTheme={currentUserTheme}
        items={[
          {
            id: 'Custom Report',
            label: 'Custom Report',
          },
          {
            id: 'Standard Report',
            label: 'Standard Report',
          },
        ]}
        selected={reportTab}
        onItemClick={(label: string) => {
          setReportTab(label);
          setExcelStartDate(null);
          setExcelEndDate(null);
          setSelectedOptions(undefined);
          setSelectedOption(undefined);
          setButtonActive(true);
          setCustomizeReportOptions(null);
          setReports(!reports);
        }}
        isEast={true}
      >
        <></>
      </Tabs>

      <Formik
        initialValues={{
          port: [],
          startDate: excelStartDate ? excelStartDate : null,
          endDate: excelEndDate ? excelEndDate : null,
          range: '0',
          attributes: [],
          reportType: '',
          customStartDate: excelStartDate ? excelStartDate : null,
          customEndDate: excelEndDate ? excelEndDate : null,
          customRange: '0',
        }}
        validate={values => {
          if (customizeReport) {
            values.port = [];
            values.attributes = [];
            values.customEndDate = null;
            values.customStartDate = null;
            values.customRange = '0';
          } else {
            values.reportType = '';
            values.startDate = null;
            values.endDate = null;
            values.range = '0';
          }
          const allRequiredFields = !customizeReport
            ? values.customRange !== '0'
              ? values.port && values.port.length > 0
              : values.port &&
                values.port.length > 0 &&
                values.customEndDate !== null &&
                values.customStartDate !== null
            : values.range !== '0'
            ? values.reportType && values.reportType.length > 0
            : values.reportType && values.reportType.length > 0 && values.endDate !== null && values.startDate !== null;
          if (allRequiredFields) {
            setButtonActive(false);
          } else {
            setButtonActive(true);
          }
        }}
        onSubmit={async (values: EastExcelDownloadContainerValues) => {
          values.startDate = excelStartDate ? excelStartDate : null;
          values.endDate = excelEndDate ? excelEndDate : null;
          await handleOnSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Root currentUserTheme={currentUserTheme}>
              <InsideCalendar ref={myRef} onClick={handleClickInside}>
                <Grid>
                  {reportTab === 'Custom Report' && (
                    <>
                      <Row>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>Select Port</Label>
                          <Field name="port">
                            {({
                              field,
                              form: { errors, setFieldValue },
                            }: FieldProps<EastExcelDownloadContainerValues>) => (
                              <MultiSelectDropdown
                                allowSelectAll={true}
                                value={reports ? field.value : selectedOption}
                                placeholder="Select Port"
                                options={getEastLocationItemsMultipleSelect(locations)}
                                name={field.name}
                                onChange={items => {
                                  setSelectedOption(items);
                                  setCustomizeReport(false);
                                  setFieldValue('port', items);
                                }}
                                width="480px !important"
                                color="FFFF !important"
                                modal="eastExcelExtractMultiSelect"
                                currentUserTheme={currentUserTheme}
                              />
                            )}
                          </Field>
                        </InputContainer>
                      </Row>
                      <Row>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>Select Fields</Label>
                          <Field name="attributes">
                            {({
                              field,
                              form: { errors, setFieldValue },
                            }: FieldProps<EastExcelDownloadContainerValues>) => (
                              <MultiSelectDropdown
                                allowSelectAll={true}
                                value={reports ? field.value : selectedOptions}
                                placeholder="Download All Fields(Default)"
                                options={excelExtractFilter}
                                name={field.name}
                                onChange={items => {
                                  setSelectedOptions(items);
                                  setCustomizeReport(false);
                                  setFieldValue('attributes', items);
                                }}
                                width="480px !important"
                                color="FFFF !important"
                                modal="eastExcelExtractMultiSelect"
                                currentUserTheme={currentUserTheme}
                              />
                            )}
                          </Field>
                        </InputContainer>
                      </Row>

                      <Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={currentUserTheme}>Start Date</Label>
                            <Field name="customStartDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<EastExcelDownloadContainerValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  name={`customStartDate`}
                                  error={getIn(touched, field.name) ? getIn(errors, 'customStartDate') : undefined}
                                  placeholder="Select start Date"
                                  width="235px"
                                  align="left"
                                  value={excelStartDate ? moment(excelStartDate, 'YYYY/MM/DD') : null}
                                  onChange={date => {
                                    setFieldValue(`customStartDate`, date.format());
                                    setFieldTouched(`customStartDate`, true);
                                    setExcelStartDate(date.toDate());
                                  }}
                                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  modal="excel"
                                  isOutsideRange={(day: Moment) => {
                                    if (excelEndDate !== undefined) {
                                      return !isInclusivelyBeforeDay(
                                        day,
                                        moment(excelEndDate ? excelEndDate : moment(), 'YYYY/MM/DD'),
                                      );
                                    } else {
                                      return day.isBefore(moment(excelEndDate, 'YYYY/MM/DD'));
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={currentUserTheme}>End Date</Label>
                            <Field name="customEndDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<EastExcelDownloadContainerValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  name={`customEndDate`}
                                  error={getIn(touched, field.name) ? getIn(errors, 'customEndDate') : undefined}
                                  placeholder="Select end Date"
                                  width="235px"
                                  align="right"
                                  value={excelEndDate ? moment(excelEndDate, 'YYYY/MM/DD') : null}
                                  onChange={date => {
                                    setFieldValue(`customEndDate`, date.format());
                                    setFieldTouched(`customEndDate`, true);
                                    setExcelEndDate(date.toDate());
                                  }}
                                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    return day.isBefore(
                                      moment(moment(excelStartDate ? excelStartDate : moment(), 'YYYY/MM/DD')).startOf(
                                        'day',
                                      ),
                                    );
                                  }}
                                  modal="excel"
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                      </Row>
                      <Row>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>Select Date Range</Label>
                          <RadioButton>
                            <Field name="customRange">
                              {({
                                field,
                                form: { errors, setFieldValue },
                              }: FieldProps<EastExcelDownloadContainerValues>) => (
                                <div role="group" aria-labelledby="my-radio-group">
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="1"
                                      onChange={(e: { target: { value: string } }) => {
                                        const oneStart = e.target.value === '1' ? moment().subtract(1, 'months') : null;
                                        const oneEnd = e.target.value === '1' ? moment() : null;
                                        setExcelStartDate(oneStart);
                                        setExcelEndDate(oneEnd);
                                        setFieldValue('customRange', '1');
                                      }}
                                    />
                                    Last 1 Month
                                  </label>
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="6"
                                      onChange={(e: { target: { value: string } }) => {
                                        const oneStart = e.target.value === '6' ? moment().subtract(6, 'months') : null;
                                        const oneEnd = e.target.value === '6' ? moment() : null;
                                        setExcelStartDate(oneStart);
                                        setExcelEndDate(oneEnd);
                                        setFieldValue('customRange', '6');
                                      }}
                                    />
                                    Last 6 Months
                                  </label>
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="12"
                                      onChange={(e: { target: { value: string } }) => {
                                        const oneStart =
                                          e.target.value === '12' ? moment().subtract(12, 'months') : null;
                                        const oneEnd = e.target.value === '12' ? moment() : null;
                                        setExcelStartDate(oneStart);
                                        setExcelEndDate(oneEnd);
                                        setFieldValue('customRange', '12');
                                      }}
                                    />
                                    Last 1 Year
                                  </label>
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="0"
                                      onChange={() => {
                                        setExcelStartDate(null);
                                        setExcelEndDate(null);
                                        setFieldValue('customRange', '0');
                                        setFieldValue('startDate', null);
                                        setFieldValue('endDate', null);
                                      }}
                                      defaultChecked={true}
                                    />
                                    Others
                                  </label>
                                </div>
                              )}
                            </Field>
                          </RadioButton>
                        </InputContainer>
                      </Row>
                    </>
                  )}
                  {reportTab === 'Standard Report' && (
                    <>
                      <Row>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>Report Type</Label>
                          <Field name="reportType">
                            {({
                              field,
                              form: { errors, setFieldValue },
                            }: FieldProps<EastExcelDownloadContainerValues>) => (
                              <SingleDropdown
                                isClearable={true}
                                name={field.name}
                                width="480px !important"
                                placeholder="Select Report"
                                selected={reports ? customizeReportOptions : field.value}
                                onItemClick={({ value }) => {
                                  setCustomizeReportOptions(value);
                                  setCustomizeReport(true);
                                  setFieldValue('reportType', value);
                                }}
                                items={getCustomiseReportItems()}
                                darkTheme={currentUserTheme ? false : true}
                                showValidation={false}
                              />
                            )}
                          </Field>
                        </InputContainer>
                      </Row>
                      <Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={currentUserTheme}>Start Date</Label>
                            <Field name="startDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<EastExcelDownloadContainerValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  name={`startDate`}
                                  error={getIn(touched, field.name) ? getIn(errors, 'startDate') : undefined}
                                  placeholder="Select start Date"
                                  width="235px"
                                  align="left"
                                  value={excelStartDate ? moment(excelStartDate, 'YYYY/MM/DD') : null}
                                  onChange={date => {
                                    setFieldValue(`startDate`, date.format());
                                    setFieldTouched(`startDate`, true);
                                    setExcelStartDate(date.toDate());
                                  }}
                                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  modal="excel"
                                  isOutsideRange={(day: Moment) => {
                                    if (excelEndDate !== undefined) {
                                      return !isInclusivelyBeforeDay(
                                        day,
                                        moment(excelEndDate ? excelEndDate : moment(), 'YYYY/MM/DD'),
                                      );
                                    } else {
                                      return day.isBefore(moment(excelEndDate, 'YYYY/MM/DD'));
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={currentUserTheme}>End Date</Label>
                            <Field name="endDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<EastExcelDownloadContainerValues>) => (
                                <Calendar
                                  closeCalendar={clickedOutside}
                                  name={`endDate`}
                                  error={getIn(touched, field.name) ? getIn(errors, 'endDate') : undefined}
                                  placeholder="Select end Date"
                                  width="235px"
                                  align="right"
                                  value={excelEndDate ? moment(excelEndDate, 'YYYY/MM/DD') : null}
                                  onChange={date => {
                                    setFieldValue(`endDate`, date.format());
                                    setFieldTouched(`endDate`, true);
                                    setExcelEndDate(date.toDate());
                                  }}
                                  themeStyle={currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    return day.isBefore(
                                      moment(moment(excelStartDate ? excelStartDate : moment(), 'YYYY/MM/DD')).startOf(
                                        'day',
                                      ),
                                    );
                                  }}
                                  modal="excel"
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>
                      </Row>
                      <Row>
                        <InputContainer>
                          <Label currentUserTheme={currentUserTheme}>Select Date Range</Label>
                          <RadioButton>
                            <Field name="range">
                              {({
                                field,
                                form: { errors, setFieldValue },
                              }: FieldProps<EastExcelDownloadContainerValues>) => (
                                <div role="group" aria-labelledby="my-radio-group">
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="1"
                                      onChange={(e: { target: { value: string } }) => {
                                        const oneStart = e.target.value === '1' ? moment().subtract(1, 'months') : null;
                                        const oneEnd = e.target.value === '1' ? moment() : null;
                                        setExcelStartDate(oneStart);
                                        setExcelEndDate(oneEnd);
                                        setFieldValue('range', '1');
                                      }}
                                    />
                                    Last 1 Month
                                  </label>
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="6"
                                      onChange={(e: { target: { value: string } }) => {
                                        const oneStart = e.target.value === '6' ? moment().subtract(6, 'months') : null;
                                        const oneEnd = e.target.value === '6' ? moment() : null;
                                        setExcelStartDate(oneStart);
                                        setExcelEndDate(oneEnd);
                                        setFieldValue('range', '6');
                                      }}
                                    />
                                    Last 6 Months
                                  </label>
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="12"
                                      onChange={(e: { target: { value: string } }) => {
                                        const oneStart =
                                          e.target.value === '12' ? moment().subtract(12, 'months') : null;
                                        const oneEnd = e.target.value === '12' ? moment() : null;
                                        setExcelStartDate(oneStart);
                                        setExcelEndDate(oneEnd);
                                        setFieldValue('range', '12');
                                      }}
                                    />
                                    Last 1 Year
                                  </label>
                                  <label style={{ display: 'inline-flex' }}>
                                    <Field
                                      style={{ width: '17px', height: '17px', marginRight: '5px' }}
                                      type="radio"
                                      name={field.name}
                                      value="0"
                                      onChange={() => {
                                        setExcelStartDate(null);
                                        setExcelEndDate(null);
                                        setFieldValue('range', '0');
                                        setFieldValue('startDate', null);
                                        setFieldValue('endDate', null);
                                      }}
                                      defaultChecked={true}
                                    />
                                    Others
                                  </label>
                                </div>
                              )}
                            </Field>
                          </RadioButton>
                        </InputContainer>
                      </Row>
                    </>
                  )}

                  <Spaceinbetween />

                  <Col size={6}>
                    <BtnStyledRow>
                      <NewButton
                        variant={currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                        onClick={async () => {
                          cancelModal();
                        }}
                      >
                        Cancel
                      </NewButton>

                      <NewButton
                        variant="primary"
                        type="submit"
                        disabled={buttonActive}
                        currentUserTheme={currentUserTheme}
                      >
                        Export
                      </NewButton>
                    </BtnStyledRow>
                  </Col>
                </Grid>
              </InsideCalendar>
            </Root>
          </Form>
        )}
      </Formik>
    </>
  );
};
