import React from 'react';
import { EastRefineryProductionContainer } from './EastRefineryProductionContainer';
import { useQuery } from 'react-apollo';
import {
  GET_NEW_MOVEMENT_FORM_DATA,
  GetNewFormDataQuery,
  GradeModelFragmentFragment,
  GetEditFormDataQuery,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  LocationsQuery,
} from '@scout/types';
import { EastRefineryLiftingContainer } from './EastRefineryLiftingContainer';
import { EastRefineryEventContainer } from './EastRefineryEventContainer';
import {
  getEventsSelectedValues,
  getLiftingReplenishmentSelectedValues,
  getProductionSalesSelectedValues,
} from './EastMovementsHelper';
import { EastModalLoader } from './EastModalLoader';

// setting mock data till api for east is not defined
const grades: GradeModelFragmentFragment[] = [
  {
    __typename: 'GradeModel',
    id: '1',
    name: 'Bitumen',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '2',
    name: '71114S',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '3',
    name: '71114PH',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '4',
    name: '71114IND',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '5',
    name: '71114CHO',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '6',
    name: '71114HKV',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '7',
    name: '71114AR',
    type: 'soft',
  },
  {
    __typename: 'GradeModel',
    id: '8',
    name: '71114JSU',
    type: 'soft',
  },
];

export interface EastRefineryMovementFormProps {
  currentTab: string;
  currentLocationName: string;
  onDismiss: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  selectedMovement: SchedulingManagerType | null;
  locations: LocationsQuery['locations'];
  isThirdParty?: boolean;
  currentUserTheme?: boolean;
  previousValues: Array<{
    dragLoadportstartdate: string;
    dragDischargePortStartDate: string;
    id: string;
    currentPort: string;
    cloneId: string;
  }>;
  disableUndoButton: (value: boolean) => void;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

export const EastRefineryMovementForm: React.FunctionComponent<EastRefineryMovementFormProps> = props => {
  switch (props.currentTab) {
    case 'Production':
      return (
        <EastRefineryProductionContainer
          grades={grades}
          cancelModal={props.onDismiss}
          currentLocationName={props.currentLocationName}
          SMdata={props.SMdata}
          selectedMovement={props.selectedMovement}
          productionSelectedValues={
            props.selectedMovement ? getProductionSalesSelectedValues(props.selectedMovement) : {}
          }
          currentUserTheme={props.currentUserTheme}
          onClickSimulationEvent={props.onClickSimulationEvent}
          dataFetch={props.dataFetch}
        />
      );
    case 'Lifting':
      const { data, loading, error } = useQuery<GetNewFormDataQuery>(GET_NEW_MOVEMENT_FORM_DATA, {
        fetchPolicy: 'no-cache',
      });
      if (loading || error || !data || !data.transports) {
        return (
          <EastModalLoader
            error={error ? 'Something went wrong' : undefined}
            currentUserTheme={props.currentUserTheme}
          />
        );
      }

      return (
        <EastRefineryLiftingContainer
          currentLocationName={props.currentLocationName}
          grades={grades}
          vessels={data.transports}
          cancelModal={props.onDismiss}
          SMdata={props.SMdata}
          selectedMovement={props.selectedMovement}
          liftingSelectedValues={
            props.selectedMovement ? getLiftingReplenishmentSelectedValues(props.selectedMovement) : {}
          }
          locations={props.locations}
          isThirdParty={props.isThirdParty}
          previousValues={props.previousValues}
          disableUndoButton={props.disableUndoButton}
          currentUserTheme={props.currentUserTheme}
          onClickSimulationEvent={props.onClickSimulationEvent}
          dataFetch={props.dataFetch}
        />
      );
    case 'Event':
      return (
        <EastRefineryEventContainer
          currentLocationName={props.currentLocationName}
          cancelModal={props.onDismiss}
          SMdata={props.SMdata}
          selectedMovement={props.selectedMovement}
          eventRefinerySelectedValues={props.selectedMovement ? getEventsSelectedValues(props.selectedMovement) : {}}
          currentUserTheme={props.currentUserTheme}
          onClickSimulationEvent={props.onClickSimulationEvent}
          dataFetch={props.dataFetch}
        />
      );
    default:
      return null;
  }
};
