import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeType } from '../../theme';

export const InMonthPlanningIcon: React.FC = withTheme<
  React.ComponentType<{
    theme: IThemeType;
  }>
>(({ theme }) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 0C16.1977 0 15.75 0.447715 15.75 1V3.5C15.75 4.05228 16.1977 4.5 16.75 4.5H20.25V6.75H33.75V4.5H37.25C37.8023 4.5 38.25 4.05228 38.25 3.5V1C38.25 0.447715 37.8023 0 37.25 0H16.75ZM9 9H19V17.096L9 21.596V9ZM26.9912 13.5L33 16.204V9H21V16.196L26.9912 13.5ZM35 17.104L45 21.604V9H35V17.104ZM4.67563 26.6637C5.25055 29.1721 7.12369 37.1306 8.99683 42.75C14.6218 42.75 17.9968 38.25 17.9968 38.25C17.9968 38.25 19.8333 41.9229 25.1654 42.6322L26.9956 22.5L28.8258 42.6326C34.1598 41.9241 35.9968 38.25 35.9968 38.25C35.9968 38.25 39.3718 42.75 44.9968 42.75C46.8442 36.2843 48.6915 29.0603 49.2935 26.6817C49.4123 26.2125 49.1756 25.7305 48.7342 25.5318L26.9968 15.75L5.23927 25.5409C4.8069 25.7355 4.5697 26.2016 4.67563 26.6637ZM45 47.2493C41.8725 47.2493 38.745 46.1918 36 44.2793C30.51 48.1268 23.49 48.1268 18 44.2793C15.255 46.1918 12.1275 47.2493 9 47.2493H4.5V51.7493H9C12.105 51.7493 15.165 50.9618 18 49.5218C23.67 52.4243 30.33 52.4243 36 49.5218C38.835 50.9843 41.895 51.7493 45 51.7493H49.5V47.2493H45Z"
        fill={theme.secondary.light.c}
      />
    </g>
  </svg>
));
