import { FormikErrors, getIn } from 'formik';
import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Calendar } from '../../../../components/Calendar';
import { Col, Row } from '../../../../components/Grid';
import { DropdownItemOptions, SingleDropdown } from '../../../../components/SelectDropdown/Single';
import { Typography } from '../../../../components/Typography';
import { filterByLabel } from '../../../../utils';
import { parseVolume } from '../utils';
import { AddRemoveButton } from './AddRemoveButton';
import { MovementFormValues, MovementStageFormValues, ProductTransferFormValues } from './types';
import { VolumeTextInput } from './VolumeTextInput';
import { Checkbox } from '../../../../components/Checkbox';

const Label = styled(Typography).attrs({ variant: 'body3' })`
  margin-bottom: 5px;
`;

const ButtonText = styled(Typography).attrs({ variant: 'button', bold: true })`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const AddButtonContainer = styled.div<{ topRow?: boolean }>`
  width: 40px;
  height: 40px;
  margin: 24px 8px 0 28px;
  ${p => !p.topRow && 'margin-top: 0;'}
`;

const MovementRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

const AddGradeRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

interface MovementRowProps {
  name: string;
  index: number;
  setFieldValue: (name: string, value: string | boolean | null) => void;
  ports: DropdownItemOptions[];
  grades: DropdownItemOptions[];
  addProductTransfer: () => void;
  removeProductTransfer: (indexToRemove: number) => void;
  isOutsideRange: ((day: Moment) => boolean) | undefined;
  errors: NonNullable<FormikErrors<MovementFormValues>['movementStages']>[0];
  isSubmitted: boolean;
  setFieldTouched: (name: string, value: boolean) => void;
  disabled?: boolean;
  onUpdateGradeId: (productTransferIndex: number, value: string | null) => void;
  onUpdateVolume: (productTransferIndex: number, value: string | null) => void;
  showCompletedCheckbox: boolean;
  isFirstMovementStage: boolean;
  movementStage: MovementStageFormValues;
  movementStageHasBeenCompleted?: boolean;
}

const getMovementFormTabIndex = (index: number, incrementor: number) => index * 100 + incrementor;

export const MovementRow: React.FC<MovementRowProps> = ({
  name,
  index,
  setFieldValue,
  ports,
  grades,
  addProductTransfer,
  removeProductTransfer,
  isOutsideRange,
  errors,
  isSubmitted,
  setFieldTouched,
  disabled,
  onUpdateGradeId,
  onUpdateVolume,
  showCompletedCheckbox,
  isFirstMovementStage,
  movementStage,
  movementStageHasBeenCompleted,
}) => {
  return (
    <MovementRowContainer>
      <Col>
        <Row>
          <InputContainer>
            <Label>Port {index + 1}</Label>
            <SingleDropdown
              disabled={disabled}
              showValidation={false}
              error={isSubmitted ? getIn(errors, 'locationId') : undefined}
              tabIndex={getMovementFormTabIndex(index, 1)}
              width="160px"
              name={`${name}.locationId`}
              placeholder="Select"
              showClearIndicator={false}
              items={ports}
              onItemClick={item => {
                setFieldValue(`${name}.locationId`, item.value);
                setFieldTouched(`${name}.locationId`, true);
              }}
              selected={movementStage.locationId === undefined ? movementStage.locationType : movementStage.locationId}
              filterOption={filterByLabel}
            />
          </InputContainer>
          <InputContainer>
            <Label>ETA</Label>
            <Calendar
              disabled={disabled}
              isOutsideRange={isOutsideRange}
              error={isSubmitted ? getIn(errors, 'date') : undefined}
              tabIndex={getMovementFormTabIndex(index, 2)}
              name={`${name}.date`}
              placeholder="DD/MM/YYYY"
              width="160px"
              align="left"
              value={movementStage.date ? moment(movementStage.date, 'YYYY/MM/DD') : null}
              onChange={date => {
                setFieldValue(`${name}.date`, date.format('YYYY/MM/DD'));
                setFieldTouched(`${name}.date`, true);
              }}
              themeStyle="light"
            />
          </InputContainer>
        </Row>
        {showCompletedCheckbox && (
          <Row>
            <Checkbox
              disabled={movementStageHasBeenCompleted}
              name={`${name}.completed`}
              label="Completed"
              checked={movementStage.completed}
              onChange={checkedValue => {
                setFieldValue(`${name}.completed`, Boolean(checkedValue));
              }}
              onBlur={checkedValue => {
                setFieldValue(`${name}.completed`, Boolean(checkedValue));
              }}
            />
          </Row>
        )}
      </Col>

      <Col>
        {movementStage.productTransfers.map(
          (productTransfer: ProductTransferFormValues, productTransferIndex: number) => {
            const isFirstProductTransfer = productTransferIndex === 0;

            return (
              <Row key={`productTransfer-${productTransferIndex}`}>
                <AddButtonContainer topRow={isFirstProductTransfer}>
                  {isFirstMovementStage && (
                    <AddRemoveButton
                      type="remove"
                      enabled={movementStage.productTransfers.length > 1 && !disabled}
                      onClick={() => removeProductTransfer(productTransferIndex)}
                    />
                  )}
                </AddButtonContainer>
                <InputContainer>
                  {isFirstProductTransfer && <Label>Grade</Label>}

                  <SingleDropdown
                    error={isSubmitted ? getIn(errors, `productTransfers.${productTransferIndex}.gradeId`) : undefined}
                    tabIndex={getMovementFormTabIndex(index, 3 + productTransferIndex + 1)}
                    disabled={!isFirstMovementStage || disabled}
                    showValidation={false}
                    showClearIndicator={false}
                    width="160px"
                    name={`${name}.productTransfers.${productTransferIndex}.gradeId`}
                    placeholder="Select"
                    items={grades}
                    onItemClick={item => {
                      setFieldValue(`${name}.productTransfers.${productTransferIndex}.gradeId`, item.value);
                      setFieldTouched(`${name}.productTransfers.${productTransferIndex}.gradeId`, true);

                      if (index === 0) {
                        onUpdateGradeId(productTransferIndex, item.value);
                      }
                    }}
                    selected={
                      productTransfer.gradeId === undefined ? productTransfer.gradeType : productTransfer.gradeId
                    }
                    filterOption={filterByLabel}
                  />
                </InputContainer>
                <InputContainer>
                  {isFirstProductTransfer && <Label>Volume</Label>}
                  <VolumeTextInput
                    name={`${name}.productTransfers.${productTransferIndex}.volume`}
                    error={isSubmitted ? getIn(errors, `productTransfers.${productTransferIndex}.volume`) : undefined}
                    tabIndex={getMovementFormTabIndex(index, 4 + productTransferIndex + 1)}
                    data-testid={`${name}.productTransfers.${productTransferIndex}.volume`}
                    type="text"
                    disabled={!isFirstMovementStage || disabled}
                    showErrorMessage={false}
                    showErrorIcon={false}
                    themeStyle="light"
                    align="right"
                    onChange={(ev: React.ChangeEvent<{ value: string }>) => {
                      setFieldValue(
                        `${name}.productTransfers.${productTransferIndex}.volume`,
                        parseVolume(productTransfer.volume, ev.target.value, 'LOAD'),
                      );

                      if (index === 0) {
                        onUpdateVolume(
                          productTransferIndex,
                          parseVolume(productTransfer.volume, ev.target.value, 'DISCHARGE'),
                        );
                      }
                    }}
                    value={productTransfer.volume}
                  />
                </InputContainer>
              </Row>
            );
          },
        )}
        {isFirstMovementStage && movementStage.productTransfers.length < 3 && (
          <Row>
            <AddGradeRow>
              <AddButtonContainer>
                <AddRemoveButton type="add" enabled={!disabled} onClick={() => addProductTransfer()} />
              </AddButtonContainer>
              <ButtonText>Add grade</ButtonText>
            </AddGradeRow>
          </Row>
        )}
      </Col>
    </MovementRowContainer>
  );
};
