// common functions and styles to refinery and depot events movement form
import styled from 'styled-components';
import { TextArea } from '../../../../../components/TextArea';
import { Typography } from '../../../../../components/Typography';
import * as Yup from 'yup';
import { Button } from '../../../../../components/Button';

export const Root = styled.div<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme ? props.theme.base.dark.d : props.theme.base.light.b)};
  background: ${props => (props.currentUserTheme ? props.theme.base.light.b : props.theme.base.dark.d)};
  margin-top: 50px;
  margin-bottom: 10px;
`;
export const Label = styled(Typography).attrs({ variant: 'body3' })<{ currentUserTheme?: boolean }>`
  color: ${props => (props.currentUserTheme ? props.theme.base.dark.d : 'white')};
  margin-bottom: 5px;
`;

export const PlanningContainer = styled.div`
  padding: 12px;
  width: 300px;
`;

export const PlanningNotesContainer = styled.div`
  padding: 12px;
  width: 91%;
  margin-top: -35px;
`;

export const CheckboxContainer = styled.div`
  padding: 12px;
  width: 91%;
  margin-top: -65px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Spaceinbetween = styled.div`
  padding-left: 15px;
`;

export const StyledTextArea = styled(TextArea)`
  padding: 8px;
`;
export const NewButton = styled(Button)`
  margin-left: 16px;
`;

export const BtnStyledRow = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ isFullHeight }) => isFullHeight && 'height: 100%;'}
  float: right
`;

export const MovementValidation = styled.div`
  position: absolute;
  width: 679px;
  height: 45px;
  left: 35px;
  top: 165px;
  background: rgba(215, 43, 63, 0.4);
  border: 1px solid #db4052;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  padding: 9px;
  font-size: 15px;
`;
export const WidthAdjustmentContainer = styled.div<{ widthToSet?: string }>`
  width: ${props => props.widthToSet};
`;
export const EventValidationErrorMessage =
  'There is an movement blocking this input. Please remove movement before creating the Event';
const REQUIRED_ERROR_MESSAGE = 'This is a required field';
const END_DATE_MESSAGE = 'End Date must be later than Start Date';
export const today = new Date();
export const validationSchema = Yup.object().shape({
  eventStartDate: Yup.date().required(REQUIRED_ERROR_MESSAGE),
  eventEndDate: Yup.date()
    .required(REQUIRED_ERROR_MESSAGE)
    .min(Yup.ref('eventStartDate'), END_DATE_MESSAGE),
  eventType: Yup.string().required('Please enter the event type'),
  planningNote: Yup.string().max(500),
  blockDates: Yup.boolean(),
});

export interface RefineryDepotEventValues {
  productType: string;
  eventStartDate: string;
  eventEndDate: string;
  eventType: string;
  port: string;
  planningNotes: string;
  blockDates: boolean;
}

export const getDefaultEventValues = (currentLocationName: string) => {
  const defaultValues = {
    productType: 'Event',
    eventStartDate: '',
    eventEndDate: '',
    eventType: '',
    port: currentLocationName,
    planningNotes: '',
    blockDates: false,
  };
  return defaultValues;
};
