import * as React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { smBreakPoint, theme } from '../../theme';
import { Button, ButtonVariants } from '../Button';
import { CloseButton } from '../CloseButton';
import { Typography } from '../Typography';

interface ModalProps extends ReactModal.Props {
  minWidth?: number;
  title?: string;
  dismissText?: string | null;
  confirmText?: string | null;
  confirmBtnVariant?: ButtonVariants;
  onDismiss?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  confirmDisabled?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  renderLeftButton?: () => React.ReactNode;
  renderRightButton?: () => React.ReactNode;
  justifyButtons?: string;
  maxHeight?: string;
  overflowY?: string;
  currentUserTheme?: boolean;
  height?: string;
  moreActions?: boolean;
  moreActionsEU?: boolean;
}

const Title = styled.div`
  margin-bottom: 36px;
`;

const BottomLine = styled.div<{ currentUserTheme?: boolean }>`
  opacity: 0.2;
  height: 1px;
  width: 100%;
  background: ${props => (props.currentUserTheme ? props.theme.greys.dark.border : props.theme.greys.light.border)};
  margin: 18px 0 36px;
  margin: 18px 0 0;
`;

const ActionsContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${p => p.justifyContent || 'flex-end'};
  margin-top: 25px;
`;

const Spacer = styled.div`
  margin-left: 8px;
`;

const CloseButtonContainer = styled.div`
  margin-left: auto;
  width: 20px;
`;
const CloseButtonContainerMoreActions = styled.div`
position:absolute
margin:-20px;
width:20px;
height:73px;
margin-left: -18px;
margin-top:-6px;
`;
const CloseButtonContainerMoreActionsEU = styled.div`
  position: relative;
  top: -10px;
  z-index: 999;
  right: 4px;
`;

export const DEFAULT_LOADING_TEXT = 'Saving...';
const DEFAULT_CONFIRM_TEXT = 'Save';
const DEFAULT_DISMISS_TEXT = 'Cancel';

const Modal: React.FunctionComponent<ModalProps> = props => {
  const styles: ReactModal.Styles = {
    overlay: {
      backgroundColor: 'rgb(0, 0, 0, .5)',
    },
    content: {
      backgroundColor: props.currentUserTheme
        ? theme.base.light.b
        : props.moreActions
        ? theme.base.dark.b
        : theme.base.dark.d,
      border: 'none',
      maxWidth: smBreakPoint,
      minWidth: props.minWidth || 300,
      top: '50%',
      left: props.moreActions ? '85%' : '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: props.moreActions ? 'none' : '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 34,
      overflow: 'none',
      borderRadius: 2,
      maxHeight: props.maxHeight || 'none',
      overflowY: props.overflowY || 'auto',
      height: props.height || 'none',
      marginLeft: 'none',
    },
  };
  ReactModal.setAppElement('body');

  return (
    <ReactModal
      {...props}
      closeTimeoutMS={250}
      onRequestClose={props.onClose || props.onDismiss}
      shouldCloseOnOverlayClick={false}
      style={styles}
    >
      {props.onClose &&
        (props.moreActionsEU ? (
          <CloseButtonContainerMoreActionsEU>
            <CloseButtonContainer>
              <CloseButton onClick={props.onClose} currentUserTheme={props.currentUserTheme} />
            </CloseButtonContainer>
          </CloseButtonContainerMoreActionsEU>
        ) : props.moreActions ? (
          <CloseButtonContainerMoreActions>
            <CloseButtonContainer>
              <CloseButton onClick={props.onClose} currentUserTheme={props.currentUserTheme} />
            </CloseButtonContainer>
          </CloseButtonContainerMoreActions>
        ) : (
          <CloseButtonContainer>
            <CloseButton onClick={props.onClose} currentUserTheme={props.currentUserTheme} />
          </CloseButtonContainer>
        ))}

      {Boolean(props.title) && (
        <Title>
          <Typography
            variant="heading5"
            color={props.currentUserTheme ? 'bodyLight' : 'bodyDark'}
            bold={true}
            align="center"
          >
            {props.title || ''}
          </Typography>
        </Title>
      )}
      {props.children}
      {(props.onDismiss || props.onConfirm) && <BottomLine currentUserTheme={props.currentUserTheme} />}

      <ActionsContainer justifyContent={props.justifyButtons}>
        {props.renderLeftButton
          ? props.renderLeftButton()
          : props.onDismiss && (
              <Button
                disabled={props.disabled}
                // NOTE :: enzyme and testing-library render differently
                // using dataTestId to pass down data-testid to the relevant component
                data-testid="modal-cancel-button"
                onClick={props.onDismiss}
                variant={props.currentUserTheme ? 'ghost-light' : 'ghost-dark'}
              >
                {props.dismissText || DEFAULT_DISMISS_TEXT}
              </Button>
            )}

        {props.renderRightButton
          ? props.renderRightButton()
          : props.onConfirm && (
              <>
                <Spacer />
                <Button
                  disabled={props.disabled || props.confirmDisabled || props.isLoading}
                  // NOTE :: enzyme and testing-library render differently
                  // using dataTestId to pass down data-testid to the relevant component
                  data-testid="modal-confirm-button"
                  onClick={props.onConfirm}
                  variant={props.confirmBtnVariant}
                >
                  {props.isLoading
                    ? props.loadingText || DEFAULT_LOADING_TEXT
                    : props.confirmText || DEFAULT_CONFIRM_TEXT}
                </Button>
              </>
            )}
      </ActionsContainer>
    </ReactModal>
  );
};

export { Modal };
