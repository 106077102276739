import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Field, FieldProps, getIn, FastField, Formik, Form } from 'formik';
import { SingleDropdown } from '../../../../../components/SelectDropdown/Single';
import { getTransportItems } from '../../utils';
import { Grid, Row, Col } from '../../../../../components/Grid';
import { TextInput } from '../../../../../components/TextInput';
import { filterByLabel } from '../../../../../utils';
import moment, { Moment } from 'moment';
import { Calendar } from '../../../../../components/Calendar';
import * as Yup from 'yup';
import { useToast } from '../../../../../components/Toast/Toast';
import {
  GetEditFormDataQuery,
  GetScheduleManagerByPortQuery,
  SchedulingManagerType,
  LocationsQuery,
  UpdateLiftingOrReplenishmentMovementMutation,
  UpdateLiftingOrReplenishmentMovementMutationVariables,
  UPDATE_LIFTING_OR_REPLENISHMENT_BY_MOVEMENT,
} from '@scout/types';
import { useMutation } from 'react-apollo';
import { Required, RequiredText } from './EastMovementFormsHelper';
import {
  BtnStyledRow,
  FUTURE_DATE_ERROR_MESSAGE,
  InputContainer,
  Label,
  NewButton,
  NUMBER_TYPE_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  Root,
  ScrollRoot,
  today,
  WidthAdjustmentContainer,
} from './EastLiftingReplenishmentHelper';
import { getModifiedValue } from './EastMovementsHelper';
const DepotMovementScreenStyle = styled.div`
  margin-top: 50px;
  margin-right: 15px;
`;

const PaddingAdjustment = styled.div`
  margin-top: 26px;
`;

interface Props {
  vessels: GetEditFormDataQuery['transports'];
  currentLocationName: string;
  cancelModal: () => void;
  SMdata: GetScheduleManagerByPortQuery | undefined;
  selectedMovement: SchedulingManagerType | null;
  // tslint:disable-next-line
  replenishmentSelectedValues: any;
  locations: LocationsQuery['locations'];
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}
export interface DepotMovementDetails {
  plannedVolume: number;
  depotActualVolume: number;
  vesselName: string;
  dischargeCompletionDate: string;
  dischargePortLaycanStartDate: string;
  dischargePortLaycanEndDate: string;
  dragDischargePortStartDate: string;
  dragLoadportstartdate: string;
}

today.setHours(0, 0, 0, 0);
const validationSchema = Yup.object().shape({
  plannedVolume: Yup.number()
    .required(REQUIRED_ERROR_MESSAGE)
    .typeError(NUMBER_TYPE_ERROR_MESSAGE),
  depotActualVolume: Yup.number().nullable(),

  vesselName: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  plannedLoadMonth: Yup.string().nullable(),
  dischargePortLaycanStartDate: Yup.date().required(REQUIRED_ERROR_MESSAGE),
  // .min(today, FUTURE_DATE_ERROR_MESSAGE),

  dischargePortLaycanEndDate: Yup.date().required(REQUIRED_ERROR_MESSAGE),
  // .min(today, FUTURE_DATE_ERROR_MESSAGE)
  // .min(Yup.ref('dischargePortLaycanStartDate'), 'end date must be after start date'),
});

export const EastDepotMovementDetails: React.FunctionComponent<Props> = props => {
  const [dischargeCompletionDate, setDischargeCompletionDate] = useState<Moment | null>();
  const [dischargePortLaycanstartDate, setDischargePortLaycanStartDate] = useState<Moment | null>();
  const [dischargePortLaycanendDate, setDischargePortLaycanendDate] = useState<Moment | null>();
  const [buttonActive, setButtonActive] = useState(true);

  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const { push } = useToast();
  const [movementValidation, setMovementValidation] = useState(false);
  let headers = {};
  if (sessionStorage.getItem('sessionId')) {
    headers = {
      sessionId: sessionStorage.getItem('sessionId'),
    };
  }
  const [updateLiftingOrReplenishmentMovement, setUpdateLiftingOrReplenishmentMovement] = useMutation<
    UpdateLiftingOrReplenishmentMovementMutation,
    UpdateLiftingOrReplenishmentMovementMutationVariables
  >(UPDATE_LIFTING_OR_REPLENISHMENT_BY_MOVEMENT, {
    context: {
      headers,
    },
  });

  const defaultValues = {
    productType: 'Replenishment',
    grade: 'Bitumen',
    plannedVolume: 0,
    depotActualVolume: 0,
    vesselName: props.selectedMovement?.vesselName,
    dischargeCompletionDate: '',
    dischargePortLaycanStartDate: '',
    dischargePortLaycanEndDate: '',
    dragDischargePortStartDate: '',
    dragLoadportstartdate: '',
  };
  const handleOnSubmit = async (values: DepotMovementDetails) => {
    if (props.selectedMovement !== null) {
      const input = {
        movementCloneId: props.selectedMovement?.movementCloneId || '',
        ...values,
      };
      try {
        await updateLiftingOrReplenishmentMovement({
          variables: {
            input,
          },
        });
        push({ type: 'SUCCESS', text: 'Depot Replenishment Updated successfully' });
        setDisabledSaveButton(false);
        props.cancelModal();
        props.onClickSimulationEvent(!props.dataFetch);
      } catch (error) {
        push({ type: 'ERROR', text: 'Depot Replenishment Update Failed' });
      }
    }
  };

  return (
    <Root currentUserTheme={props.currentUserTheme}>
      <Formik
        validationSchema={validationSchema}
        initialValues={props.selectedMovement ? props.replenishmentSelectedValues : defaultValues}
        validate={values => {
          const allRequiredFields =
            values.plannedVolume &&
            values.vesselName &&
            values.dischargePortLaycanEndDate &&
            values.dischargePortLaycanStartDate;

          if (allRequiredFields) {
            setButtonActive(false);
          } else {
            setButtonActive(true);
          }
        }}
        onSubmit={async (values, formActions) => {
          values.plannedVolume = +values.plannedVolume;
          values.depotActualVolume = +values.depotActualVolume;

          if (props.selectedMovement === null) {
            values.dragDischargePortStartDate = values.dischargePortLaycanStartDate;
            values.dragLoadportstartdate = values.loadPortLaycanStartDate;
          } else {
            // checking if the laycan start dates are changed and resetting dragdate variables accordingly
            if (
              props.selectedMovement.loadPortLaycanStartDate !== values.loadPortLaycanStartDate ||
              props.selectedMovement.dischargePortLaycanStartDate !== values.dischargePortLaycanStartDate
            ) {
              values.dragDischargePortStartDate = values.dischargePortLaycanStartDate;
              values.dragLoadportstartdate = values.loadPortLaycanStartDate;
            } else {
              values.dragDischargePortStartDate = props.selectedMovement.dragDischargePortStartDate;
              values.dragLoadportstartdate = props.selectedMovement.dragLoadportstartdate;
            }
          }
          const changedValue = getModifiedValue(values, props.replenishmentSelectedValues);
          handleOnSubmit(values);
        }}
        render={formProps => {
          const isSubmitted = formProps.submitCount > 0 ? true : false;

          return (
            <Form>
              {movementValidation}
              <ScrollRoot>
                <Grid>
                  <Row>
                    <DepotMovementScreenStyle>
                      <Col size={6}>
                        <PaddingAdjustment>
                          <Row>
                            <InputContainer>
                              <Label currentUserTheme={props.currentUserTheme}>
                                Vessel Name<Required>*</Required>
                              </Label>

                              <Field name="vesselName">
                                {({
                                  field,
                                  form: { errors, touched, setFieldValue },
                                }: FieldProps<DepotMovementDetails>) => (
                                  <SingleDropdown
                                    tabIndex={1}
                                    darkTheme={props.currentUserTheme ? false : true}
                                    width="315px"
                                    name="vesselName"
                                    error={isSubmitted ? getIn(errors, 'vesselName') : undefined}
                                    placeholder="Select vessel"
                                    showClearIndicator={false}
                                    items={getTransportItems(props.vessels, null)}
                                    onItemClick={item => {
                                      setFieldValue('vesselName', item.value);
                                    }}
                                    selected={field.value || null}
                                    filterOption={filterByLabel}
                                    disabled={true}
                                  />
                                )}
                              </Field>
                            </InputContainer>
                          </Row>
                        </PaddingAdjustment>

                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Planned Volume (kt)<Required>*</Required>
                            </Label>

                            <Field name="plannedVolume">
                              {({
                                field,
                                form: { errors, touched, setFieldValue, values },
                              }: FieldProps<DepotMovementDetails>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={isSubmitted ? getIn(errors, 'plannedVolume') : undefined}
                                    name={field.name}
                                    disabled={true}
                                    placeholder="0.000"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null && field.value !== 0 ? field.value : ''}
                                    currentUserTheme={props.currentUserTheme}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>Actual Volume (kt)</Label>

                            <Field name="depotActualVolume">
                              {({
                                field,
                                form: { errors, touched, setFieldValue },
                              }: FieldProps<DepotMovementDetails>) => (
                                <WidthAdjustmentContainer widthToSet="155px">
                                  <TextInput
                                    align="left"
                                    error={getIn(touched, field.name) ? getIn(errors, field.name) : undefined}
                                    name={field.name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`depotActualVolume`, e.target.value);
                                    }}
                                    placeholder="0.000"
                                    showErrorIcon={false}
                                    showErrorMessage={true}
                                    themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                    value={field.value !== null && field.value !== 0 ? field.value : ''}
                                  />
                                </WidthAdjustmentContainer>
                              )}
                            </Field>
                          </InputContainer>
                        </Row>

                        <Row>
                          <RequiredText>* Required Fields</RequiredText>
                        </Row>
                      </Col>
                    </DepotMovementScreenStyle>
                    <DepotMovementScreenStyle>
                      <Col size={6}>
                        <Row>
                          <Label currentUserTheme={props.currentUserTheme}>Discharge Port Laycan</Label>
                        </Row>
                        <Row>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              Start Date<Required>*</Required>
                            </Label>
                            <Field name="dischargePortLaycanStartDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<DepotMovementDetails>) => (
                                <Calendar
                                  disabled={true}
                                  name={`dischargePortLaycanStartDate`}
                                  placeholder="Select Date"
                                  width="155px"
                                  align="left"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : dischargePortLaycanstartDate
                                      ? moment(dischargePortLaycanstartDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`dischargePortLaycanStartDate`, date.format());
                                    setFieldTouched(`dischargePortLaycanStartDate`, true);
                                    setDischargePortLaycanStartDate(date);
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                          <InputContainer>
                            <Label currentUserTheme={props.currentUserTheme}>
                              End Date<Required>*</Required>
                            </Label>
                            <Field name="dischargePortLaycanEndDate">
                              {({
                                field,
                                form: { errors, setFieldTouched, setFieldValue, touched },
                              }: FieldProps<DepotMovementDetails>) => (
                                <Calendar
                                  disabled={true}
                                  name={`dischargePortLaycanEndDate`}
                                  placeholder="Select Date"
                                  width="155px"
                                  align="right"
                                  value={
                                    field.value
                                      ? moment(field.value)
                                      : dischargePortLaycanendDate
                                      ? moment(dischargePortLaycanendDate, 'YYYY/MM/DD')
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(`dischargePortLaycanEndDate`, date.format());
                                    setFieldTouched(`dischargePortLaycanEndDate`, true);
                                    setDischargePortLaycanendDate(date);
                                  }}
                                  themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                  showErrorMessage={getIn(touched, field.name) ? true : false}
                                  isOutsideRange={(day: Moment) => {
                                    return day.isBefore(
                                      moment(
                                        dischargePortLaycanstartDate ? dischargePortLaycanstartDate : moment(),
                                        'YYYY/MM/DD',
                                      ),
                                    );
                                  }}
                                  region="east"
                                  currentUserTheme={props.currentUserTheme}
                                />
                              )}
                            </Field>
                          </InputContainer>
                        </Row>

                        <InputContainer>
                          <Label currentUserTheme={props.currentUserTheme}>Discharge Completion Date</Label>
                          <Field name="dischargeCompletionDate">
                            {({
                              field,
                              form: { errors, setFieldTouched, setFieldValue, touched },
                            }: FieldProps<DepotMovementDetails>) => (
                              <Calendar
                                name={`dischargeCompletionDate`}
                                error={
                                  getIn(touched, field.name) ? getIn(errors, 'dischargeCompletionDate') : undefined
                                }
                                placeholder="Select Date"
                                width="155px"
                                align="right"
                                value={
                                  field.value
                                    ? moment(field.value)
                                    : dischargeCompletionDate
                                    ? moment(dischargeCompletionDate, 'YYYY/MM/DD')
                                    : null
                                }
                                disabled={props.selectedMovement?.BLDate === null ? true : false}
                                onChange={date => {
                                  setFieldValue(`dischargeCompletionDate`, date.format());
                                  setFieldTouched(`dischargeCompletionDate`, true);
                                  setDischargeCompletionDate(date);
                                }}
                                themeStyle={props.currentUserTheme ? 'light' : 'dark'}
                                showErrorMessage={getIn(touched, field.name) ? true : false}
                                goToCurrentBtnText="Clear"
                                onGoToCurrent={() => {
                                  setFieldValue(`dischargeCompletionDate`, null);
                                  setDischargeCompletionDate(null);
                                }}
                                region="east"
                                currentUserTheme={props.currentUserTheme}
                              />
                            )}
                          </Field>
                        </InputContainer>

                        <BtnStyledRow>
                          <Row>
                            <NewButton
                              variant={props.currentUserTheme ? 'ghost-light' : 'ghost-dark'}
                              onClick={async () => {
                                props.cancelModal();
                              }}
                            >
                              Cancel
                            </NewButton>

                            <NewButton
                              variant="primary"
                              type="submit"
                              disabled={disabledSaveButton ? true : buttonActive}
                              currentUserTheme={props.currentUserTheme}
                            >
                              Save
                            </NewButton>
                          </Row>
                        </BtnStyledRow>
                      </Col>
                    </DepotMovementScreenStyle>
                  </Row>
                </Grid>
              </ScrollRoot>
            </Form>
          );
        }}
      />
    </Root>
  );
};
