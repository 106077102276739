import React, { ReactElement } from 'react';
import { FixedSizeList as List } from 'react-window';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import { OptionProps } from 'react-select/src/components/Option';

const lineHeight1 = 40;
const lineHeight2 = 64;

const MenuList = (props: MenuListComponentProps<{}>) => {
  const { options, children, selectProps } = props;
  const height = selectProps.lineNumber === 1 ? lineHeight1 : lineHeight2;
  const value = selectProps.value;
  const initialOffset = value ? options.indexOf(value) * height : 0;

  const childrenArray = React.Children.toArray(children) as Array<ReactElement<OptionProps<{}>>>;
  const focusedChildIndex = childrenArray.findIndex(child => child.props.isFocused);
  const maxHeight = height * childrenArray.length;

  const listRef = React.useRef<List>(null);

  React.useLayoutEffect(() => {
    listRef.current && listRef.current.scrollToItem(focusedChildIndex);
  }, [focusedChildIndex, listRef]);

  return (
    <div data-testid={`menu-list-${selectProps.name}`}>
      <List
        width="100%"
        height={maxHeight > 300 ? height * 6 : maxHeight}
        itemCount={React.Children.count(children)}
        itemSize={height}
        initialScrollOffset={initialOffset}
        ref={listRef}
      >
        {({ index, style }) => {
          return (
            <div data-testid={`menu-item-${selectProps.name}-${index}`} style={style}>
              {childrenArray[index]}
            </div>
          );
        }}
      </List>
    </div>
  );
};

export { MenuList };
