import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from '../../../../components/Tooltip';

const ValueIconContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 1;
`;

const LoadIcon = withTheme(({ theme }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H2ZM5 4V9.664H8.568V8.472H6.376V4H5Z"
      fill={theme.tertiary.chart6}
    />
  </svg>
));

const DepositIcon = withTheme(({ theme }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H2ZM9.59394 8.43994C9.7806 8.07727 9.87394 7.64794 9.87394 7.15194C9.87394 6.6186 9.7806 6.1706 9.59394 5.80794C9.40727 5.44527 9.16194 5.15727 8.85794 4.94394C8.55394 4.72527 8.20727 4.5706 7.81794 4.47994C7.4286 4.38394 7.03394 4.33594 6.63394 4.33594H4.58594V9.99994H6.69794C7.08727 9.99994 7.47127 9.9466 7.84994 9.83994C8.2286 9.72794 8.56727 9.55727 8.86594 9.32794C9.16994 9.09327 9.4126 8.79727 9.59394 8.43994ZM8.28994 6.35994C8.3966 6.56794 8.44994 6.83194 8.44994 7.15194C8.44994 7.4666 8.3966 7.7306 8.28994 7.94394C8.1886 8.15727 8.04994 8.32794 7.87394 8.45594C7.70327 8.58394 7.50327 8.67727 7.27394 8.73594C7.04994 8.78927 6.81527 8.81594 6.56994 8.81594H5.92194V5.50394H6.60194C6.8366 5.50394 7.06327 5.5306 7.28194 5.58394C7.50594 5.63727 7.70327 5.72794 7.87394 5.85594C8.04994 5.9786 8.1886 6.1466 8.28994 6.35994Z"
      fill={theme.tertiary.chart3}
    />
  </svg>
));

export const VolumeTextInputIcon: React.FC<{
  value: string;
  disabled?: boolean;
}> = ({ value, disabled }) => {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue) || parsedValue === 0) {
    return null;
  }
  if (parsedValue > 0) {
    return (
      <Tooltip active={!disabled} body="Discharge" styles={{ marginLeft: 3, marginTop: 40 }}>
        <ValueIconContainer>
          <DepositIcon />
        </ValueIconContainer>
      </Tooltip>
    );
  }
  return (
    <Tooltip active={!disabled} body="Loading" styles={{ marginLeft: 3, marginTop: 40 }}>
      <ValueIconContainer>
        <LoadIcon />
      </ValueIconContainer>
    </Tooltip>
  );
};
