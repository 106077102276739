import React from 'react';
import { EastRowParent } from './EastRowParent';
import { useQuery } from 'react-apollo';
import {
  GetScheduleManagerByPortQuery,
  GET_TRANSPORTS,
  GetTransportsQuery,
  SchedulingManagerType,
  LocationsQuery,
} from '@scout/types';
import { usePermission } from '../../../../../../hooks/usePermission';
import styled from 'styled-components';

const Border = styled.hr<{ currentUserTheme?: boolean }>`
  border: ${props => (props.currentUserTheme === true ? '1px solid #EBEBEB' : '1px solid #303641')};
`;

interface EastSchedulingManagerProps {
  date?: string;
  region?: string;
  eventNotes: GetScheduleManagerByPortQuery['getScheduleManagerByPort'];
  movementStages: GetScheduleManagerByPortQuery['getScheduleManagerByPort'];
  loadingdata: boolean;
  currentlocationtype: string;
  movementsInRefineryAndDepot: GetScheduleManagerByPortQuery['getScheduleManagerByPort'] | undefined;
  onEditMovementModal: (selectedMovementItem: SchedulingManagerType) => void;
  locations: LocationsQuery['locations'];
  onDeleteMovementModal: (selectedMovementItem: SchedulingManagerType) => void;
  currentPort: string;
  addSelectedTaskIdsToAnArray: (item: SchedulingManagerType, getSelectedRows: SchedulingManagerType[]) => void;
  isThirdParty?: boolean;
  color: boolean;
  movementMultipleDeletion: boolean;
  currentUserTheme?: boolean;
  onClickSimulationEvent: (value: boolean) => void;
  dataFetch?: boolean;
}

export const EastSchedulingManager: React.FunctionComponent<EastSchedulingManagerProps> = ({
  currentlocationtype,
  loadingdata,
  date,
  eventNotes,
  movementsInRefineryAndDepot,
  movementStages,
  onEditMovementModal,
  locations,
  onDeleteMovementModal,
  currentPort,
  addSelectedTaskIdsToAnArray,
  isThirdParty,
  color,
  movementMultipleDeletion,
  currentUserTheme,
  onClickSimulationEvent,
  dataFetch,
}) => {
  const { hasAnyOf } = usePermission();

  const { data } = useQuery<GetTransportsQuery>(GET_TRANSPORTS, {
    skip: !hasAnyOf(['viewEastSchedulingManager', 'editEastSchedulingManager', 'eastDepotUser']),
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <EastRowParent
        eventNotes={eventNotes}
        date={date}
        movementStages={movementStages}
        loading={loadingdata}
        vessels={data ? data.transports || [] : []}
        currentlocationtype={currentlocationtype}
        onEditMovementModal={onEditMovementModal}
        movementsInRefineryAndDepot={movementsInRefineryAndDepot}
        locations={locations}
        onDeleteMovementModal={onDeleteMovementModal}
        currentPort={currentPort}
        addSelectedTaskIdsToAnArray={addSelectedTaskIdsToAnArray}
        isThirdParty={isThirdParty}
        color={color}
        movementMultipleDeletion={movementMultipleDeletion}
        currentUserTheme={currentUserTheme}
        onClickSimulationEvent={onClickSimulationEvent}
        dataFetch={dataFetch}
      />
      <Border currentUserTheme={currentUserTheme} />
    </>
  );
};
