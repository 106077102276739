import { LocationTypes } from '../../constants';

import { MarkerType } from '../../components/Map/Mapbox';

export const DEFAULT_ID = '-';

const markers: MarkerType[] = [
  {
    id: 'G075',
    type: LocationTypes.Customer,
    lat: 51.4454985,
    lng: 0.356656,
    name: 'FM Conway',
    active: false,
    region: 'Europe',
  },
  {
    id: 'S858',
    type: LocationTypes.Refinery,
    lat: 1.234952,
    lng: 103.762453,
    name: 'Bukom',
    active: true,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 53.3503499,
    lng: -6.2096712,
    name: 'Dublin - Colas/ Rodstone',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 51.1989185,
    lng: 4.3563498,
    name: 'IKO',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 53.2692569,
    lng: -9.0464163,
    name: 'Galway - Colas/ Rodstone',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 54.6105979,
    lng: -5.8901747,
    name: 'Belfast - Colas/ Rodstone',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 48.535861,
    lng: 7.7857353,
    name: 'Soprema',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 50.110472,
    lng: 8.727639,
    name: 'Bitumina',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 47.5816196,
    lng: 7.5851461,
    name: 'Grisard',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 40.4790875,
    lng: 17.2039903,
    name: 'ENI Taranto',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 40.7550215,
    lng: 29.7586718,
    name: 'Tupras Izmit',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 37.9509405,
    lng: -8.8869144,
    name: 'Petrogal',
    active: false,
    region: 'Europe',
  },
  {
    id: 'D137',
    type: LocationTypes.Refinery,
    lat: 54.1558252,
    lng: 9.0759516,
    name: 'Heide',
    active: false,
    region: 'Europe',
  },
  {
    id: 'D229',
    type: LocationTypes.Refinery,
    lat: 50.8551548,
    lng: 6.9767675,
    name: 'Godorf',
    active: true,
    isPiStock: true,
    region: 'Europe',
  },
  {
    id: 'D025',
    type: LocationTypes.Refinery,
    lat: 50.8126518,
    lng: 7.0003001,
    name: 'Wesseling',
    active: true,
    isPiStock: true,
    region: 'Europe',
  },
  {
    id: 'D028',
    type: LocationTypes.Refinery,
    lat: 53.0936903,
    lng: 14.2350497,
    name: 'PCK',
    active: true,
    region: 'Europe',
  },
  {
    id: 'D029',
    type: LocationTypes.Refinery,
    lat: 49.05311,
    lng: 8.3342744,
    name: 'MIRO',
    active: true,
    region: 'Europe',
  },
  {
    id: 'D061',
    type: LocationTypes.Depot,
    lat: 49.4253111,
    lng: 8.5233345,
    name: 'TIB Mannheim',
    active: true,
    region: 'Europe',
  },

  {
    id: 'F465',
    type: LocationTypes.Depot,
    lat: 43.5296163,
    lng: -1.506758,
    name: 'Bayonne',
    active: true,
    region: 'Europe',
  },
  {
    id: 'F466',
    type: LocationTypes.Depot,
    lat: 47.1948267,
    lng: -1.6340852,
    name: 'Nantes',
    active: true,
    region: 'Europe',
  },
  {
    id: 'G116',
    type: LocationTypes.Depot,
    lat: 53.3132154,
    lng: -2.9459026,
    name: 'ERL',
    active: true,
    region: 'Europe',
  },
  {
    id: 'N489',
    type: LocationTypes.Refinery,
    lat: 51.8760756,
    lng: 4.344426,
    name: 'PERNIS',
    active: true,
    isPiStock: true,
    region: 'Europe',
  },
  {
    id: 'N572',
    type: LocationTypes.Depot,
    lat: 53.2930112,
    lng: 6.9731908,
    name: 'JPB',
    active: true,
    region: 'Europe',
  },
  {
    id: 'N575',
    type: LocationTypes.Depot,
    lat: 51.8906882,
    lng: 4.2639387,
    name: 'BTTB',
    active: true,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 43.58592,
    lng: 10.342947,
    name: 'ENI Livorno',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 38.809498,
    lng: 26.948354,
    name: 'Tupras Izmir',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 41.17617,
    lng: 1.227986,
    name: 'Cepsa Tarragona',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 37.92111,
    lng: 23.076841,
    name: 'MOH Agioi',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 51.25167,
    lng: 4.36768,
    name: 'Vitol Antwerp',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 36.521638,
    lng: -6.253923,
    name: 'Puma Cadiz',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 44.429137,
    lng: 12.205328,
    name: 'Alma Ravenna',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 27.8589,
    lng: -15.394313,
    name: 'Arinaga',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 58.342889,
    lng: 6.277269,
    name: 'NCC - Rekefjord',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 59.668386,
    lng: 5.933977,
    name: 'NCC - Etne',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 62.485348,
    lng: 6.397684,
    name: 'NCC - Ålesund',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 61.019224,
    lng: 5.461661,
    name: 'NCC - Breidvik',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 60.702311,
    lng: 5.548364,
    name: 'Swerock Eikefet',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 59.380233,
    lng: 5.288894,
    name: 'Swerock Karmøy',
    active: false,
    region: 'Europe',
  },
  {
    id: 'P020',
    type: LocationTypes.Depot,
    lat: 13.72077,
    lng: 121.067241,
    name: 'Tabangao',
    active: true,
    region: 'East',
  },
  {
    id: 'M713',
    type: LocationTypes.Depot,
    lat: 3.277101,
    lng: 113.081365,
    name: 'Bintulu',
    active: true,
    region: 'East',
  },
  {
    id: 'M609',
    type: LocationTypes.Depot,
    lat: 6.081756,
    lng: 116.130252,
    name: 'Sapangar Bay',
    active: true,
    region: 'East',
  },
  {
    id: 'M603',
    type: LocationTypes.Depot,
    lat: 5.811943,
    lng: 118.076931,
    name: 'Sandakan',
    active: true,
    region: 'East',
  },
  {
    id: 'M626',
    type: LocationTypes.Depot,
    lat: 1.615466,
    lng: 110.447103,
    name: 'Kuching',
    active: true,
    region: 'East',
  },
  {
    id: 'M313',
    type: LocationTypes.Depot,
    lat: 2.970921,
    lng: 101.321801,
    name: 'Westport',
    active: true,
    region: 'East',
  },
  {
    id: 'T048/ T074 (bitumen)',
    type: LocationTypes.Depot,
    lat: 13.708286,
    lng: 100.558928,
    name: 'Chongnonsi',
    active: true,
    region: 'East',
  },
  {
    id: 'T075',
    type: LocationTypes.Depot,
    lat: 9.158002,
    lng: 99.350261,
    name: 'Bandon',
    active: true,
    region: 'East',
  },
  {
    id: 'S804',
    type: LocationTypes.Depot,
    lat: 1.299605,
    lng: 103.745718,
    name: 'Pandan',
    active: true,
    region: 'East',
  },
  {
    id: 'H101',
    type: LocationTypes.Depot,
    lat: 22.344962,
    lng: 114.085946,
    name: 'Tsing Yi,Hong Kong',
    active: true,
    region: 'East',
  },
  {
    id: 'P022',
    type: LocationTypes.Depot,
    lat: 8.49522,
    lng: 124.660513,
    name: 'Tagoloan',
    active: true,
    region: 'East',
  },
  {
    id: 'C693',
    type: LocationTypes.Depot,
    lat: 21.726791,
    lng: 108.59066,
    name: 'Qingzhou',
    active: true,
    region: 'East',
  },
  {
    id: 'C634',
    type: LocationTypes.Depot,
    lat: 21.606868,
    lng: 108.354535,
    name: 'Fang Cheng',
    active: true,
    region: 'East',
  },
  {
    id: 'C694',
    type: LocationTypes.Depot,
    lat: 32.187069,
    lng: 119.425819,
    name: 'Zhen Jiang',
    active: true,
    region: 'East',
  },

  {
    id: 'C859',
    type: LocationTypes.Customer,
    lat: 38.981302,
    lng: 117.746052,
    name: 'Tian Jin Zhong Wu Zheng Hua',
    active: false,
    region: 'East',
  },
  {
    id: 'C852',
    type: LocationTypes.Customer,
    lat: 39.231138,
    lng: 118.990351,
    name: 'Tang Shan',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 32.170379,
    lng: 118.922825,
    name: 'Nanjing',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 29.918803,
    lng: 121.903321,
    name: 'Ningbo',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 22.899097,
    lng: 113.591452,
    name: 'Dongguan',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 22.583504,
    lng: 113.172506,
    name: 'Jiangmen',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 21.474614,
    lng: 111.075611,
    name: 'Maoming',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 20.972193,
    lng: 107.056011,
    name: 'Cai Lan',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 15.414064,
    lng: 108.79935,
    name: 'Quang Nam',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 13.781245,
    lng: 109.244475,
    name: 'Quynhon',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 14.231864,
    lng: 109.192923,
    name: 'My Tho',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 5.56898,
    lng: 95.30748,
    name: 'Aceh',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: -2.980283,
    lng: 104.777364,
    name: 'Palembang/ Perawang',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: -5.868915,
    lng: 105.755451,
    name: 'Lampung',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: -5.927953,
    lng: 105.995369,
    name: 'Merak',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: -6.944539,
    lng: 110.425885,
    name: 'Semarang/ Badas',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: -8.195975,
    lng: 114.853129,
    name: 'Celukan Bawang',
    active: false,
    region: 'East',
  },
  {
    id: 'I089',
    type: LocationTypes.Depot,
    lat: -6.087963,
    lng: 106.974038,
    name: 'Marunda/ Tanjung Priok',
    active: true,
    region: 'East',
  },
  {
    id: 'E114',
    type: LocationTypes.Depot,
    lat: 24.997827,
    lng: 55.057902,
    name: 'Jebel Ali',
    active: true,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 23.787967,
    lng: 120.185779,
    name: 'Mai liao, Taiwan',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 34.856818,
    lng: 127.721173,
    name: 'Yosu, Korea',
    active: false,
    region: 'East',
  },
  {
    id: '24882',
    type: LocationTypes.Refinery,
    lat: 26.13658,
    lng: 50.628073,
    name: 'BAPCO',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 1.2903,
    lng: 103.711472,
    name: 'SRC',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 1.284522,
    lng: 103.699996,
    name: 'Exxon',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -33.94813,
    lng: 151.2135,
    name: 'Syndey',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -31.91067,
    lng: 115.95604,
    name: 'Perth',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -34.92091,
    lng: 138.52379,
    name: 'Adelaide',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -37.82954,
    lng: 144.88899,
    name: 'Melbourne',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -27.40761,
    lng: 153.12251,
    name: 'Brisbane',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -19.24616,
    lng: 146.83146,
    name: 'Townsville',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 4.997237,
    lng: 118.3753,
    name: 'Lahad Datu',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 4.249694,
    lng: 117.9051,
    name: 'Tawau',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 6.87621,
    lng: 116.8515,
    name: 'Kudat',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: 4.689693,
    lng: 118.252,
    name: 'Kunat',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Refinery,
    lat: 51.4492,
    lng: 3.7271,
    name: 'Zeeland',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Customer,
    lat: 1.67981,
    lng: 101.46473,
    name: 'Dumai',
    active: false,
    region: 'East',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -29.97792694,
    lng: 30.95767267,
    name: 'BlackRhino',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -29.87599628,
    lng: 31.00684547,
    name: 'Durban',
    active: false,
    region: 'Europe',
  },
  {
    id: DEFAULT_ID,
    type: LocationTypes.Depot,
    lat: -33.90623245,
    lng: 18.43146639,
    name: 'Cape Town',
    active: false,
    region: 'Europe',
  },
];

export { markers };
