import React from 'react';
import { HeaderCell } from '../../../../components/Table';
import { Typography } from '../../../../components/Typography';

interface Props {
  isUKIrelandCluster: boolean;
  filteredDates: Array<{ month: string }>;
  year: number;
  currentUserTheme?: boolean;
}

export const TableHeader = ({ isUKIrelandCluster, filteredDates, year, currentUserTheme }: Props) => (
  <thead data-testid="am-dashboard-header">
    <tr>
      <HeaderCell
        rowSpan={2}
        text="Owner"
        border="small"
        justify="flex-start"
        sticky="topleft"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Sold-to / Status"
        border="small"
        justify="flex-start"
        sticky="topleft"
        left={75}
        rightBorderShadow={true}
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Type"
        border="large"
        justify="flex-start"
        sticky="topleft"
        left={392}
        rightBorderShadow={true}
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="ID"
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        data-testid="dashboard-header-location-column"
        rowSpan={2}
        text={isUKIrelandCluster ? 'Ship-to' : 'Region'}
        border="small"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell
        rowSpan={2}
        text="Shipment type"
        border="large"
        justify="flex-start"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      <HeaderCell border="large" colSpan={2} sticky="top" currentUserTheme={currentUserTheme}>
        <Typography tag="span" variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
          Primary plant
        </Typography>
      </HeaderCell>
      <HeaderCell
        rowSpan={2}
        text="Total vol"
        border="large"
        justify="flex-end"
        sticky="top"
        currentUserTheme={currentUserTheme}
      />
      {filteredDates.map(monthItem => (
        <HeaderCell
          key={`header-top-${monthItem.month}-${year}`}
          colSpan={2}
          sticky="top"
          border="large"
          currentUserTheme={currentUserTheme}
        >
          <Typography tag="span" variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'} bold={true}>
            {`${monthItem.month} -`}&nbsp;
          </Typography>
          <Typography tag="span" variant="body3" color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
            {year}
          </Typography>
        </HeaderCell>
      ))}
    </tr>
    <tr>
      <HeaderCell text="Pen" border="small" justify="center" sticky="top" currentUserTheme={currentUserTheme} />
      <HeaderCell text="Premium" border="large" justify="center" sticky="top" currentUserTheme={currentUserTheme} />
      {filteredDates.map(monthItem => (
        <React.Fragment key={`header-bottom-${monthItem.month}-${year}`}>
          <HeaderCell text="Vol" border="small" justify="flex-end" sticky="top" currentUserTheme={currentUserTheme} />
          <HeaderCell
            text="Price vs FO"
            border="large"
            justify="flex-end"
            sticky="top"
            currentUserTheme={currentUserTheme}
          />
        </React.Fragment>
      ))}
    </tr>
  </thead>
);
