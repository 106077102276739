import React from 'react';
import { GetEditFormDataQuery, GetScheduleManagerByPortQuery, SchedulingManagerType } from '@scout/types';
import styled from 'styled-components';
import { config } from '../../../../../../config';
import moment, { Moment } from 'moment-timezone';
import { YYYYMMDD } from '../../../../../../constants';
import { useCurrentUser } from '../../../../../../hooks/useCurrentUser';

const MTStyleContainer = styled.span<{ currentUserTheme?: boolean }>`
background:${props => (props.currentUserTheme ? props.theme.greys.light.disabled : props.theme.greys.dark.disabled)};
width:100%
display:flex; 
border-radius:5px;

`;

const CompletedStyleContainer = styled.span<{ currentUserTheme?: boolean }>`
background:${props => (props.currentUserTheme ? props.theme.greys.light.disabled : props.theme.base.dark.d)};
color:${props => props.theme.greys.dark.border};
width:100%
display:flex; 
border-radius:5px;
justify-content: center;
text-align: center;
`;
const DateLabel = styled.span`
  padding: 4px;
`;
const NextPortLabel = styled.span`
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ArrowForNextPort = styled.div`
  width: 40px;
  justify-content: center;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  padding: 2px;
  border: none;

  width: 14px;
  background-image: url(${config.PUBLIC_URL}/images/arrow-nextPort.svg);
`;

const MovementLabel = styled.div<{ background: string; currentUserTheme?: boolean }>`
  text-align: center;
  position: static;
  width: 15px;
  height: 15px;
  left: 0px;
  top: 0px;
  /* $grey/border-dark */
  background: ${props => (props.currentUserTheme ? 'lightgrey' : props.background)};
  border-radius: 3px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  color: ${props => (props.currentUserTheme ? 'black' : '#000000')};
  margin-right: 5px;
  font-family: Avenir Next;
  font-size: 12px;
`;

const RedNotificationDot = styled.div`
  margin-right: 5px;
  height: 15px;
  padding: 2px;
  border: none;
  width: 14px;
  background-image: url(${config.PUBLIC_URL}/images/red-notification-dot.svg);
`;

const SNStyleContainer = styled.span`
width:100%
display:flex; 
border-radius:5px;
`;

const WarningNotificationDot = styled.div`
  margin-right: 5px;
  height: 15px;
  padding: 2px;
  border: none;
  width: 14px;
  background-image: url(${config.PUBLIC_URL}/images/yellow-notification-dot.svg);
`;

const StockAndUllageOut = styled.span`
  color: red;
`;

export const StockUllageTextColor = styled.span<{ type: string; currentUserTheme?: boolean }>`
  color: ${props =>
    props.type === 'warning'
      ? props.currentUserTheme
        ? '#F47F24'
        : '#f4b02d'
      : props.currentUserTheme
      ? '#DD1D21'
      : props.theme.errors.red2};
`;

export const WarningStockAndUllage = styled.span`
  color: #f4b02d;
`;

export const BoldText = styled.span`
  font-weight: 1000 !important;
`;

export const getVesselName = (item: SchedulingManagerType, vessels: GetEditFormDataQuery['transports']) => {
  if (item.vesselName === 'TBC') {
    return item.vesselName.toUpperCase();
  } else {
    return vessels.map((vessel, index) => {
      if (vessel && vessel.id === item.vesselName) {
        switch (item.productType) {
          case 'Lifting':
            return vessel.shipName?.toUpperCase();
          case 'Replenishment':
            return vessel.shipName?.toUpperCase();
          default:
            return '';
        }
      }
    });
  }
};

export const getBuyerInfo = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
      return item?.buyerInfo?.toUpperCase();
    case 'Replenishment':
      return item?.buyerInfo?.toUpperCase();
    default:
      return '';
  }
};

const MTdisplay = (
  vessels: GetEditFormDataQuery['transports'],
  item: SchedulingManagerType,
  currentUserTheme?: boolean,
) => {
  if (item.vesselName === 'TBC') {
    return (
      <>
        <MTStyleContainer currentUserTheme={currentUserTheme}>
          <DateLabel>{'no info'}</DateLabel>
          <ArrowForNextPort />
          <NextPortLabel> {'no info'}</NextPortLabel>
        </MTStyleContainer>
      </>
    );
  } else {
    return vessels.map((vessel, index) => {
      if (vessel && vessel.id === item.vesselName) {
        const vesselETACalc = vessel.eta;
        return (
          <>
            <MTStyleContainer key={index} currentUserTheme={currentUserTheme}>
              <DateLabel>
                {vesselETACalc !== null ? moment.tz(vesselETACalc, 'GMT').format('DD/MM/YY - hh:mm a') : 'no info'}
              </DateLabel>
              <ArrowForNextPort />
              <NextPortLabel> {vessel.nextPortName !== null ? vessel.nextPortName : 'no info'}</NextPortLabel>
            </MTStyleContainer>
          </>
        );
      }
    });
  }
};

export const getMTinfo = (
  item: SchedulingManagerType,
  vessels: GetEditFormDataQuery['transports'],
  currentUserTheme?: boolean,
) => {
  switch (item.productType) {
    case 'Lifting':
      return item.BLDate === null ? (
        MTdisplay(vessels, item, currentUserTheme)
      ) : (
        <CompletedStyleContainer currentUserTheme={currentUserTheme}>
          <DateLabel> Completed </DateLabel>{' '}
        </CompletedStyleContainer>
      );

    case 'Replenishment':
      return item.dischargeCompletionDate === null ? (
        MTdisplay(vessels, item, currentUserTheme)
      ) : (
        <CompletedStyleContainer currentUserTheme={currentUserTheme}>
          <DateLabel> Discharge Complete </DateLabel>{' '}
        </CompletedStyleContainer>
      );

    default:
      return '';
  }
};

export const getVolume = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
      return Number(item.plannedVolume).toFixed(2);
    case 'Sale':
      return Number(item.plannedVolume).toFixed(2);
    case 'Production':
      return Number(item.plannedVolume).toFixed(2);
    case 'Replenishment':
      return Number(item.plannedVolume).toFixed(2);

    default:
      return '';
  }
};

export const getActualVolume = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
      return item.actualVolume ? item.actualVolume.toFixed(2) : 0.0;
    case 'Replenishment':
      return item.depotActualVolume ? item.depotActualVolume.toFixed(2) : 0.0;
    case 'Sale':
      return item.actualVolume ? item.actualVolume.toFixed(2) : 0.0;
    case 'Production':
      return item.actualVolume ? item.actualVolume.toFixed(2) : 0.0;
    default:
      return '';
  }
};

export const displayTypeofMovement = (item: SchedulingManagerType, currentUserTheme?: boolean) => {
  switch (item.productType) {
    case 'Lifting':
      return <MovementLabel background="#9b51e0">L</MovementLabel>;

    case 'Replenishment':
      return <MovementLabel background="#9b51e0">R</MovementLabel>;

    case 'Production':
      return (
        <MovementLabel background="#76808f" currentUserTheme={currentUserTheme}>
          P
        </MovementLabel>
      );

    case 'Sale':
      return <MovementLabel background="#4f86c6">S</MovementLabel>;

    case 'Event':
      return <MovementLabel background="#4fb0c6">E</MovementLabel>;

    default:
      return '';
  }
};

export const getloadAnddischargeLaycan = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
      return item && item.dischargePortLaycanStartDate ? moment(item.dischargePortLaycanStartDate).format('DD/MM') : '';

    case 'Replenishment':
      if (item && item.BLDate) {
        return 'B/L Date ' + moment(item.BLDate).format('DD/MM');
      } else {
        return item && item.loadPortLaycanStartDate ? moment(item.loadPortLaycanStartDate).format('DD/MM') : '';
      }
    default:
      return '';
  }
};

export const getPlanningNotes = (item: SchedulingManagerType) => {
  if (item.planningNotes) {
    return item.planningNotes;
  }
  return ' ';
};
export const getSmartNotification = (item: SchedulingManagerType) => {
  const StockSign = item.stock ? Math.sign(item.stock) : 0;
  const UllageSign = item.ullage ? Math.sign(item.ullage) : 0;
  // let StockUllageWarningValue: number = stockValue < 7.4 ? 1 : stockValue > 18 ? 2 : 0;
  const StockUllageWarningValue: number = item.statusUllageStock ? item.statusUllageStock : 9;
  if (item.productType === 'Lifting') {
    if (item.BLDate !== null) {
      return `B/L Date ${moment(item.BLDate).format('DD/MM/YY')}`;
    } else {
      return item.loadPortLaycanStartDate ? `Laycan ${moment(item.loadPortLaycanStartDate).format('DD/MM/YY')}` : ``;
    }
  }
  if (item.productType === 'Replenishment') {
    if (item.dischargeCompletionDate !== null) {
      return `Discharge Date  ${moment(item.dischargeCompletionDate).format('DD/MM/YY')}`;
    } else {
      return item.dischargePortLaycanStartDate
        ? `Laycan ${moment(item.dischargePortLaycanStartDate).format('DD/MM/YY')}`
        : ``;
    }
  }

  const renderSmartNotification = (type: string, text: {} | null | undefined, userTheme?: boolean) => {
    return (
      <SNStyleContainer>
        {type === 'warning' ? <WarningNotificationDot /> : <RedNotificationDot />}
        <StockUllageTextColor type={type} currentUserTheme={userTheme}>
          {text}
        </StockUllageTextColor>
      </SNStyleContainer>
    );
  };
  const currentUser = useCurrentUser();
  const userTheme = currentUser.userTheme ? true : false;

  // Warning Notification
  if (StockUllageWarningValue === 2 && StockSign === 1) {
    return renderSmartNotification('warning', 'Risk of Stock out', userTheme);
  }

  if (StockUllageWarningValue === 1 && UllageSign === 1) {
    return renderSmartNotification('warning', 'Risk of Ullage out', userTheme);
  }

  // Negative Values Notification
  if (StockSign === -1 || StockSign === 0) {
    return renderSmartNotification('error', 'Stock out', userTheme);
  } else if (UllageSign === -1 || UllageSign === 0) {
    return renderSmartNotification('error', 'Ullage out', userTheme);
  } else {
    return '';
  }
};
export const getPurchaseContract = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
    case 'Replenishment':
      return item?.purchaseContract;
    default:
      return '';
  }
};
export const getSalesContract = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
    case 'Replenishment':
      return item?.salesContract;
    default:
      return '';
  }
};
export const getInternalFreightPrice = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
    case 'Replenishment':
      return item?.internalFreightPrice;
    default:
      return '';
  }
};
export const getCustomerFreightPrice = (item: SchedulingManagerType) => {
  switch (item.productType) {
    case 'Lifting':
    case 'Replenishment':
      return item?.customerFreightActualPrice;
    default:
      return '';
  }
};
const todayDate = moment().format(YYYYMMDD);

export const getStock = (
  data: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  date?: string,
  currentUserTheme?: boolean,
) => {
  const movement = data.find(item => item.movementDate?.toString() === date && item.stock !== null);
  const stockValue = movement && movement.stock;
  const StockSign = stockValue ? Math.sign(stockValue) : 0;
  const StockUllageWarningValue: number = movement && movement.statusUllageStock ? movement.statusUllageStock : 9;
  if (StockUllageWarningValue === 2 && Math.sign(StockSign) === 1) {
    return (
      <StockUllageTextColor type="warning" currentUserTheme={currentUserTheme}>
        {stockValue?.toFixed(2) + ' Kt'}
      </StockUllageTextColor>
    );
  } else if (StockUllageWarningValue === 2) {
    return (
      <StockUllageTextColor type="error" currentUserTheme={currentUserTheme}>
        {stockValue?.toFixed(2) + ' Kt'}
      </StockUllageTextColor>
    );
  } else if (stockValue === null) {
    return date && date <= todayDate ? '' : 'N/A';
  } else if (stockValue === undefined) {
    return date && date <= todayDate ? '' : 'N/A';
  } else {
    return stockValue?.toFixed(2) + ' Kt';
  }
};
export const getUllage = (
  data: GetScheduleManagerByPortQuery['getScheduleManagerByPort'],
  date?: string,
  currentUserTheme?: boolean,
) => {
  const movement = data.find(item => item.movementDate?.toString() === date && item.ullage !== null);
  const ullageValue = movement && movement.ullage;
  const UllageSign = ullageValue ? Math.sign(ullageValue) : 0;
  const StockUllageWarningValue: number = movement && movement.statusUllageStock ? movement.statusUllageStock : 9;
  if (StockUllageWarningValue === 1 && (Math.sign(UllageSign) === -1 || Math.sign(UllageSign) === 0)) {
    return (
      <StockUllageTextColor type="error" currentUserTheme={currentUserTheme}>
        {ullageValue?.toFixed(2) + ' Kt'}
      </StockUllageTextColor>
    );
  } else if (StockUllageWarningValue === 1) {
    return (
      <StockUllageTextColor type="warning" currentUserTheme={currentUserTheme}>
        {ullageValue?.toFixed(2) + ' Kt'}
      </StockUllageTextColor>
    );
  } else if (ullageValue === null) {
    return date && date <= todayDate ? '' : 'N/A';
  } else if (ullageValue === undefined) {
    return date && date <= todayDate ? '' : 'N/A';
  } else {
    return ullageValue?.toFixed(2) + ' Kt';
  }
};
