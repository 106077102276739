import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useToast } from '../../../../components/Toast/Toast';
import { URLS } from '../../../../constants';
import { getPreviousUrl, getUrlWithPreviousSearch } from '../../../../utils';
import { getUrlFromMovementInput, parseQueryDataToFormValues, parseUpdateMovementInput } from '../utils';
import { MovementForm } from './MovementForm';
import { SectionLoader } from './SectionLoader';
import {
  GET_EDIT_MOVEMENT_FORM_DATA,
  UPDATE_MOVEMENT,
  REMOVE_MOVEMENT,
  UpdateMovementMutation,
  UpdateMovementMutationVariables,
  GetEditFormDataQuery,
  GetEditFormDataQueryVariables,
  RemoveMovementMutation,
  RemoveMovementMutationVariables,
} from '@scout/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditMovementView: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { push } = useToast();
  const { movementId } = useParams();

  const { data, loading, error } = useQuery<GetEditFormDataQuery, GetEditFormDataQueryVariables>(
    GET_EDIT_MOVEMENT_FORM_DATA,
    {
      skip: !movementId,
      fetchPolicy: 'no-cache',
      variables: { movementId: movementId || '' },
    },
  );

  const [editMovement, editMovementResult] = useMutation<UpdateMovementMutation, UpdateMovementMutationVariables>(
    UPDATE_MOVEMENT,
  );

  const [removeMovement, removeMovementResult] = useMutation<RemoveMovementMutation, RemoveMovementMutationVariables>(
    REMOVE_MOVEMENT,
    {
      variables: { movementId: movementId || '' },
    },
  );

  useEffect(() => {
    if (!movementId) {
      history.push(URLS.IN_MONTH);
    }
  }, [movementId, history]);

  if (loading || error || !data || !data.locations || !data.grades || !data.transports) {
    return <SectionLoader title="Edit Movement" error={error ? 'Something went wrong' : undefined} />;
  }

  if (!movementId || !data.movementById) {
    return <SectionLoader title="Edit Movement" error="Could not find movement" />;
  }

  return (
    <Container>
      <MovementForm
        loading={editMovementResult.loading || removeMovementResult.loading}
        type="edit"
        initialValues={parseQueryDataToFormValues(data.movementById)}
        locations={data.locations}
        grades={data.grades}
        transports={data.transports}
        onAddAnother={() => {
          history.push(`${URLS.IN_MONTH}/new`);
        }}
        onCancel={() => {
          history.push(getPreviousUrl(location, URLS.IN_MONTH));
        }}
        onRemove={async () => {
          try {
            await removeMovement({ variables: { movementId } });
            history.push(getPreviousUrl(location, URLS.IN_MONTH));
            push({ type: 'SUCCESS', text: 'Movement deleted successfully' });
          } catch (error) {
            push({ type: 'ERROR', text: 'Something went wrong. Movement could not be deleted. Please try again' });
          }
        }}
        onSubmit={async (input, formActions, action) => {
          try {
            const parsedInput = parseUpdateMovementInput(input, data.locations, action);
            await editMovement({ variables: { input: parsedInput } });

            if (action === 'addAnother') {
              formActions.resetForm();
              history.push(`${URLS.IN_MONTH}/new`);
            } else {
              history.push(getUrlWithPreviousSearch(getUrlFromMovementInput(parsedInput), location));
            }

            push({ type: 'SUCCESS', text: 'Movement updated successfully' });
          } catch (error) {
            push({ type: 'ERROR', text: 'Something went wrong. Movement could not be saved. Please try again' });
          }
        }}
      />
    </Container>
  );
};
