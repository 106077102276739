import * as React from 'react';
import styled from 'styled-components';

import { OptionProps } from 'react-select/src/components/Option';
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';

const Container = styled.div<{ isDisabled: boolean; menuIsOpen: boolean }>`
  margin: 5px 10px 0;
  color: ${p => {
    if (p.isDisabled) {
      return p.theme.greys.light.border;
    } else if (p.menuIsOpen) {
      return p.theme.primary.b;
    } else {
      'inherit';
    }
  }};
`;

const DropdownIndicator = ({ selectProps }: OptionProps<{}>) => {
  return (
    <Container isDisabled={selectProps.isDisabled || false} menuIsOpen={selectProps.menuIsOpen || false}>
      {selectProps.menuIsOpen ? <MdArrowDropUp size={30} /> : <MdArrowDropDown size={30} />}
    </Container>
  );
};

export { DropdownIndicator };
