import { css } from 'styled-components';
import { FontColors, FontVariants, IThemeType } from '../../theme';

export interface ColorProps {
  theme: IThemeType;
  color?: FontColors;
}

export const colorMixin = css<ColorProps>`
  color: ${p => p.theme.typography.colors[p.color || 'bodyLight']};
`;

export interface FontProps {
  theme: IThemeType;
  variant?: FontVariants;
  bold?: boolean;
  align?: 'left' | 'center' | 'right' | 'justify';
  overflowHidden?: boolean;
}

// TODO: in the future this should be removed.
// Some designs have inconsistent weights which
// don't follow the variants in the theme
const createFontFamily = (p: FontProps) => {
  return p.theme.typography.variants[p.variant || 'body2'].family;
};

export const fontMixin = css<FontProps>`
  margin: 0;
  font-family: ${createFontFamily};
  font-weight: ${p => (p.bold ? '600' : 'inherit')};
  font-size: ${p => p.theme.typography.variants[p.variant || 'body2'].size}px;
  line-height: ${p => p.theme.typography.variants[p.variant || 'body2'].lineHeight};
  text-align: ${p => p.align || 'left'};
  ${p =>
    p.overflowHidden &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;
