import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getCountryOptions } from '../../InMonth/Scheduler/utils';
import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { Row } from '../../../components/Grid';
import {
  PORT_CALLS_API_ERROR_MSG,
  PORT_CALLS_EXCEL_DOWNLOAD_URL,
  PORT_CALLS_EXCEL_FILE_NAME,
  PORT_CALLS_EXCEL_FILE_TYPE,
} from '../../../constants';
import { useToast } from '../../../components/Toast/Toast';
import qs from 'qs';
import { ReportFormIcon } from '../../../components/Icons/ReportFormIcon';
import * as Yup from 'yup';
import { Calendar } from '../../../components/Calendar';
import moment, { Moment } from 'moment';
import { MultiDropdownItem, MultiSelectDropdown } from '../../../components/SelectDropdown/Multi';
import { CountryModel } from '@scout/types/dist';

interface Props {
  currentUserTheme?: boolean;
  countryList: CountryModel[];
}

const FormContainer = styled.div<{ currentUserTheme?: boolean }>`
  background-color: ${p => (p.currentUserTheme ? p.theme.base.light.b : p.theme.base.dark.b)};
  overflow-y: auto;
  width: 35%;
  height: 85%;
  border-radius: 10px;
  padding: 1% 2%;
`;

const CountryContainer = styled.div`
  margin-top: 7%;
  width: 100%;
`;

const DateRangeContainer = styled.div`
  margin-top: 7%;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StartDateCalendarInputContainer = styled.div`
  width: 49%;
`;

const EndDateCalendarInputContainer = styled.div`
  width: 49%;
`;

const SpaceBetween = styled.div`
  width: 2%;
`;

const Label = styled(Typography).attrs({ variant: 'body3' })`
  margin-bottom: 5px;
`;

const DownloadReportButtonContainer = styled.div`
  margin: 7% 0%;
  display: flex;
  justify-content: flex-end;
  align-items: right;
`;

const DownloadReportButton = styled(Button)`
  border-radius: 2px;
  margin: 4% 0% 0% 0%;
`;

interface InitialValues {
  country: MultiDropdownItem[];
  momentDateRange: {
    startDate: Moment | null;
    endDate: Moment | null;
  };
}

const initialValues: InitialValues = {
  country: [],
  momentDateRange: {
    startDate: null,
    endDate: null,
  },
};

const DownloadExportSchema = Yup.object().shape({
  country: Yup.string().required('Required'),
  momentDateRange: Yup.object().shape({
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
  }),
});

export const VesselFlowAnalysisForm: React.FC<Props> = ({ currentUserTheme, countryList }) => {
  const { push } = useToast();

  const getPortCall = async (values: InitialValues) => {
    try {
      const reqValues = {
        country: values.country.map(country => country.value),
        dateRange: {
          startDate: values.momentDateRange.startDate?.format('YYYY-MM-DDTHH:MM:SS.SSSZ'),
          endDate: values.momentDateRange.endDate?.format('YYYY-MM-DDTHH:MM:SS.SSSZ'),
        },
      };

      const response = await fetch(PORT_CALLS_EXCEL_DOWNLOAD_URL + '?' + qs.stringify(reqValues));

      if (!response.ok) {
        const errMsg = await response.text();
        throw new Error(errMsg);
      }

      const fileBuffer = await response.arrayBuffer();
      const blob = new Blob([fileBuffer], { type: PORT_CALLS_EXCEL_FILE_TYPE });
      saveAs(blob, `${PORT_CALLS_EXCEL_FILE_NAME}_${moment().format('YYYY-MM-DDTHH:MM:SS')}.csv`);
    } catch (err) {
      let errText = PORT_CALLS_API_ERROR_MSG;

      if (err instanceof Error) {
        errText = err.message;
      }

      push({ type: 'ERROR', text: errText });
    }
  };

  const [momentStartDate, setMomentStartDate] = useState<Moment | null>(null);
  const [momentEndDate, setMomentEndDate] = useState<Moment | null>(null);
  const [multiCountrySelectOptions, setMultiCountrySelectOptions] = useState<MultiDropdownItem[] | undefined>();
  const [isDownloadReportButtonActive, setIsDownloadReportButtonActive] = useState<boolean>(!false);

  return (
    <Formik
      validate={values => {
        if (
          values.country &&
          values.country.length &&
          values.momentDateRange.startDate &&
          values.momentDateRange.endDate
        ) {
          setIsDownloadReportButtonActive(false);
        } else {
          setIsDownloadReportButtonActive(true);
        }
      }}
      validationSchema={DownloadExportSchema}
      initialValues={initialValues}
      onSubmit={getPortCall}
      render={formProps => {
        return (
          <FormContainer currentUserTheme={currentUserTheme}>
            <IconContainer>
              <ReportFormIcon currentUserTheme={currentUserTheme} />
            </IconContainer>
            <LabelContainer>
              <Typography bold={true} color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                Histroical Data Report
              </Typography>
            </LabelContainer>
            <CountryContainer>
              <Label bold={true} color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                Country:
              </Label>
              <MultiSelectDropdown
                allowSelectAll={true}
                currentUserTheme={currentUserTheme}
                name="country"
                onChange={items => {
                  setMultiCountrySelectOptions(items);
                  formProps.setFieldValue('country', items);
                }}
                options={getCountryOptions(countryList)}
                placeholder="Select Country"
                value={multiCountrySelectOptions}
              />
            </CountryContainer>
            <DateRangeContainer>
              <Label bold={true} color={currentUserTheme ? 'bodyLight' : 'bodyDark'}>
                Date Range:
              </Label>
              <Row>
                <StartDateCalendarInputContainer>
                  <Calendar
                    align="left"
                    currentUserTheme={currentUserTheme}
                    enableMonthYearSelect={true}
                    modal="excel"
                    name="startDate"
                    onChange={date => {
                      setMomentStartDate(date);
                      formProps.setFieldValue('momentDateRange', {
                        ...formProps.values.momentDateRange,
                        startDate: date,
                      });
                    }}
                    placeholder="Select start date"
                    themeStyle={currentUserTheme ? 'light' : 'dark'}
                    value={momentStartDate}
                    width="100%"
                  />
                </StartDateCalendarInputContainer>
                <SpaceBetween />
                <EndDateCalendarInputContainer>
                  <Calendar
                    align="right"
                    currentUserTheme={currentUserTheme}
                    enableMonthYearSelect={true}
                    isOutsideRange={(day: Moment) => {
                      if (momentStartDate !== undefined && momentStartDate !== null) {
                        return day.isBefore(moment(momentStartDate, 'YYYY/MM/DD').startOf('day'));
                      }

                      return false;
                    }}
                    modal="excel"
                    name="endDate"
                    onChange={date => {
                      setMomentEndDate(date);
                      formProps.setFieldValue('momentDateRange', {
                        ...formProps.values.momentDateRange,
                        endDate: date,
                      });
                    }}
                    placeholder="Select end date"
                    themeStyle={currentUserTheme ? 'light' : 'dark'}
                    value={momentEndDate}
                    width="100%"
                  />
                </EndDateCalendarInputContainer>
              </Row>
            </DateRangeContainer>
            <DownloadReportButtonContainer>
              <DownloadReportButton
                variant="primary"
                disabled={isDownloadReportButtonActive}
                onClick={async () => {
                  await formProps.validateForm();

                  formProps.submitForm();
                }}
              >
                Download Report
              </DownloadReportButton>
            </DownloadReportButtonContainer>
          </FormContainer>
        );
      }}
    />
  );
};
