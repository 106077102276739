import { useEffect } from 'react';

const StorybookPage = () => {
  useEffect(() => {
    window.location.replace(`/api/storybook`);
  }, []);

  return null;
};

export { StorybookPage };
