export const LOCATION_KEY_MAP: { [key: string]: number } = {
  G075: 328973,
  S858: 6193,
  D137: 176310,
  D229: 1024103,
  D025: 988254,
  D028: 173216,
  D029: 1013494,
  D061: 2602810,
  F465: 131944,
  F466: 136000,
  G116: 325598,
  N489: 251531,
  N572: 248338,
  N575: 251566,
  P020: 3408398,
  M713: 230208,
  M609: 230080,
  M603: 229993,
  M626: 230255,
  M313: 230429,
  'T048/ T074 (bitumen)': 468570,
  T075: 437522,
  S804: 6192,
  H101: 2333526,
  P022: 763368,
  C630: 58185,
  C693: 2332692,
  C634: 2332660,
  C694: 2333073,
  C859: 61280,
  C852: 58722,
  I089: 3453784,
  E114: 323087,
  24882: 31870,
  'Dublin - Colas/ Rodstone': 3531462,
  IKO: 963863,
  'Galway - Colas/ Rodstone': 3532066,
  'Belfast - Colas/ Rodstone': 326934,
  Soprema: 131836,
  Bitumina: 2601825,
  Grisard: 147418,
  'ENI Taranto': 214970,
  'Tupras Izmit': 3588493,
  Petrogal: 6119,
  'ENI Livorno': 1545180,
  'Tupras Izmir': 1288453,
  'Cepsa Tarragona': 2325575,
  'MOH Agioi': 2285105,
  'Vitol Antwerp': 963863,
  'Puma Cadiz': 1454107,
  'Alma Ravenna': 212988,
  Arinaga: 2311586,
  'NCC - Rekefjord': 255150,
  'NCC - Etne': 253114,
  'NCC - Ålesund': 254009,
  'NCC - Breidvik': 255301,
  'Swerock Eikefet': 253316,
  'Swerock Karmøy': 255259,
  Nanjing: 976851,
  Ningbo: 65564,
  Dongguan: 58185,
  Jiangmen: 1944325,
  Maoming: 2332608,
  'Cai Lan': 355736,
  'Quang Nam': 355730,
  Quynhon: 171,
  'My Tho': 352241,
  Aceh: 3435178,
  'Palembang/ Perawang': 3445555,
  Lampung: 3447759,
  Merak: 677656,
  'Semarang/ Badas': 3460041,
  'Celukan Bawang': 687236,
  'Mai liao, Taiwan': 316331,
  'Yosu, Korea': 3430584,
  SRC: 6192,
  Exxon: 6192,
  Syndey: 12378,
  Perth: 16818,
  Adelaide: 14702,
  Melbourne: 15822,
  Brisbane: 3495276,
  Townsville: 3494655,
  'Lahad Datu': 5885,
  Tawau: 229994,
  Kudat: 230102,
  Kunat: 230103,
  Zeeland: 251235,
  Dumai: 3441471,
  Heide: 176310,
};
