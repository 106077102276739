import { ContractDealType } from '@scout/types';

export function getCurrencySymbol(clusterCurrency: string) {
  switch (clusterCurrency) {
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'ZAR':
      return 'R';
    default:
      return null;
  }
}

export function formatRoundedNumber(amount: number) {
  return amount.toLocaleString('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const mapContractTypeToString = (type: ContractDealType): string => {
  switch (type) {
    case ContractDealType.Fixed: {
      return 'FPD';
    }
    case ContractDealType.Formula: {
      return 'Formula';
    }
    case ContractDealType.Rolling: {
      return 'R-FPD';
    }
  }
};
