import * as React from 'react';
import styled from 'styled-components';
import { config } from '../../../config';
import { theme } from '../../../theme';
import { fontMixin, FontProps } from '../../Mixins';

export interface SelectOptions {
  label: string | null;
  value: string | null;
}

interface SelectDropdownProps {
  className?: string;
  selected?: string | null;
  onItemClick: (value: string, label: string) => void;
  items: Array<{ value: string; label: string }>;
  showMenu?: boolean;
  placeholder: string;
  error?: string;
  disabled?: boolean;
}

const SelectListContainer = styled.ul`
  width: 100%;
  position: absolute;
  border: 1px solid white;
  background: ${theme.base.light.a};
  color: ${theme.base.dark.d};
  border-radius: 2px;
  list-style: none;
  padding: 7px;
  margin: 10px 0 0;
  max-height: 300px;
  z-index: 10;
  overflow-x: auto;
  margin-top: -20px;
`;

const SelectListItem = styled.li<{ selected: boolean }>`
  background: ${props => (props.selected ? theme.primary.b : theme.base.light.a)};
  border-radius: 2px;
  margin-top: 2px;
  cursor: pointer;
  &:hover {
    background: ${props => (props.selected ? theme.primary.b : theme.base.light.b)};
  }
`;

const ItemButton = styled.div<{ selected: boolean } & FontProps>`
  ${fontMixin}
  padding: 5px 15px;
  display: flex;
  align-items: center;
  flex: 1;
  background: transparent;
  color: ${props => (props.selected ? theme.base.light.a : theme.base.dark.d)};
  border: transparent;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;

const UpArrow = styled.img`
  position: absolute;
  top: -8px;
  right: 5px;
`;

interface SelectListProps {
  selectedItem?: SelectOptions;
  toggleMenu: (value: boolean) => void;
  className?: string;
}

const SelectList: React.SFC<SelectDropdownProps & SelectListProps> = ({
  items,
  onItemClick,
  toggleMenu,
  selectedItem,
  className,
}) => (
  <SelectListContainer className={className}>
    <UpArrow src={`${config.PUBLIC_URL}/images/arrow-up-white.svg`} />
    {items.map(({ value, label }) => {
      const selected = selectedItem ? value === selectedItem.value : false;
      return (
        <SelectListItem selected={selected} key={value}>
          <ItemButton
            data-testid={value}
            variant={selected ? 'body4' : 'body3'}
            selected={selected}
            onClick={() => {
              onItemClick(value, label);
              toggleMenu(false);
            }}
          >
            {label}
          </ItemButton>
        </SelectListItem>
      );
    })}
  </SelectListContainer>
);

export { SelectList };
