import * as React from 'react';
import { ClearIndicator } from '../Common/ClearIndicator';
import { DropdownIndicator } from '../Common/DropdownIndicator';
import { StyledSelect } from '../Common/StyledSelect';
import { GroupHeading } from './GroupHeading';
import { Option } from './Option';

const EAST_EXCEL_EXTRACT_MULTISELECT_MODAL = 'eastExcelExtractMultiSelect';
enum action {
  select = 'select-option',
  deselect = 'deselect-option',
  remove = 'remove-value',
}

export interface MultiDropdownItem {
  label: string;
  options?: MultiDropdownItem[];
  value?: string;
  tag?: string;
}

interface Props {
  allowSelectAll?: boolean;
  placeholder: string;
  name?: string;
  options: MultiDropdownItem[];
  value?: MultiDropdownItem[];
  onChange?: (items: MultiDropdownItem[]) => void;
  disabled?: boolean;
  onBlur?: () => void;
  width?: string;
  height?: string;
  color?: string;
  clearAll?: boolean;
  modal?: string;
  currentUserTheme?: boolean;
}

export const allOption = { label: 'Select All', value: 'All' };
export const clearAllOption = { label: 'Clear All', value: 'Clear' };

const MultiSelectDropdown = ({
  allowSelectAll,
  placeholder,
  clearAll,
  name = 'multi-select-dropdown',
  options,
  onChange,
  value,
  disabled,
  width,
  height,
  color,
  modal,
  onBlur,
  currentUserTheme,
}: Props) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const handleOnMenuOpen = React.useCallback(() => setMenuIsOpen(true), []);
  const handleOnMenuClose = React.useCallback(() => setMenuIsOpen(false), []);
  const MAX_MENU_HEIGHT = modal === EAST_EXCEL_EXTRACT_MULTISELECT_MODAL ? '190px !important' : '170px !important';

  return (
    <StyledSelect
      components={{ ClearIndicator, DropdownIndicator, GroupHeading, Option }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={handleOnMenuOpen}
      onMenuClose={handleOnMenuClose}
      styles={{
        control: (base: object) => ({
          ...base,
          boxShadow: 'none',
          width,
          height,
          color: 'FFF',
          background: currentUserTheme ? '#F8F8F8' : '#09101B',
        }),
        menuList: (base: object) => ({
          ...base,
          maxHeight: MAX_MENU_HEIGHT,
        }),
      }}
      darkTheme={!currentUserTheme}
      isDisabled={disabled}
      value={value}
      placeholder={placeholder}
      variant="body3"
      name={name}
      classNamePrefix="multi-dropdown"
      options={options}
      isMulti={true}
      isClearable={false}
      isSearchable={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      // tslint:disable:no-any
      onChange={(selected: MultiDropdownItem[], event: any) => {
        if (onChange !== undefined) {
          if (allowSelectAll && selected !== null && selected.length > 0) {
            if (event.action === action.select) {
              if (event.option.value === allOption.value) {
                return onChange(options);
              }
              if (event.option.value === clearAllOption.value) {
                return onChange([]);
              }
              if (selected.length === options.length - 1) {
                return onChange(options);
              }
            } else if (event.action === action.deselect) {
              if (event.option.value === allOption.value) {
                return onChange([]);
              }
              return onChange(selected.filter((item: MultiDropdownItem) => item.value !== allOption.value));
            } else if (event.action === action.remove) {
              if (event.removedValue.value === allOption.value) {
                return onChange([]);
              }
              return onChange(selected.filter((item: MultiDropdownItem) => item.value !== allOption.value));
            }
          }
          return onChange(selected);
        }
      }}
      onBlur={onBlur}
    />
  );
};

export { MultiSelectDropdown };
