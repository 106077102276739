import React from 'react';
import styled from 'styled-components';
import { TextField } from '../TextField';

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-bottom: 5px;
`;

interface InputProps {
  item: {
    name: string;
    label: string;
  };
  onChange?: () => void;
}

const GradeInput: React.FunctionComponent<InputProps> = ({ item, onChange }) => {
  return (
    <InputWrapper>
      <TextField
        inputWidth="90px"
        name={`grades.${item.name}.percentage`}
        label={item.label}
        placeholder="0"
        onChange={onChange}
        min={0}
        max={100}
        showErrorMessage={false}
      />
    </InputWrapper>
  );
};

export { GradeInput };
